import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Button, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import PurchaseDrawer from './purchaseDrawer/index'
import SaledProgressModal from './saledProgressModal/index'
import LogisticsModal from '@/components/logisticsModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSaledTypeLabel, renderSaledStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, saledTypeMaps, saledStatusMaps } = filter

class PurchaseSaled extends Component {
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {}
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'id',
        formType: 'Input',
        itemParams: {
          label: '售后单号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'afterSaleOrderType',
        formType: 'Select',
        itemParams: {
          label: '售后类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...saledTypeMaps
        ]
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'afterSaleOrderStatus',
        formType: 'Select',
        itemParams: {
          label: '售后状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...saledStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {},
    rowCourierNums: [],
    detailsModal: false,
    logisticsModal: false,
    progressModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'purchaseSaledCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '申请时间',
      key: 'purchaseSaledCol-1',
      width: 140,
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '商户名称',
      key: 'purchaseSaledCol-2',
      dataIndex: 'distributorName'
    },
    {
      title: '售后单号',
      key: 'purchaseSaledCol-3',
      dataIndex: 'id'
    },
    {
      title: '售后类型',
      key: 'purchaseSaledCol-4',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ renderSaledTypeLabel(record.type) }</>
      )
    },
    {
      title: '订单编号',
      key: 'purchaseSaledCol-5',
      dataIndex: 'orderId'
    },
    {
      title: '售后进度',
      key: 'purchaseSaledCol-6',
      dataIndex: 'id',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return buttonMaps && buttonMaps.SALEDPROGRESS && +buttonMaps.SALEDPROGRESS.auth === 1 ? (
          <a onClick={() => this.showProgressModal(record)}>点击查看</a>
        )
          : null
      }
    },
    {
      title: '售后状态',
      key: 'purchaseSaledCol-7',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ renderSaledStatusLabel(record.status) }</>
      )
    },
    {
      title: '操作',
      key: 'purchaseSaledCol-8',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <>
            {
              buttonMaps && buttonMaps.DETAILS && +buttonMaps.DETAILS.auth === 1
                ? <Button type='link' size='small' onClick={() => this.showDetailsModal(record)}>查看详情</Button>
                : null
            }

            {
              buttonMaps && buttonMaps.LOGISTICS && +buttonMaps.LOGISTICS.auth === 1 &&
              ['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(record.type) &&
              record.status !== 'WAITING' && record.status !== 'REJECT'
                ? <Button type='link' size='small' onClick={() => this.showLogisticsModal(record)}>查看物流</Button>
                : null
            }
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderSaledList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[1].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取云采购订单售后申请列表
  getPurchaseOrderSaledList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getPurchaseOrderSaledList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 云采购售后同意申请
  savePurchaseOrderSaledApplyApproval = async (params) => {
    const res = await Api.savePurchaseOrderSaledApplyApproval(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledList()
    }
  }

  // 云采购售后拒绝售后申请
  savePurchaseOrderSaledApplyRefuse = async (params) => {
    const res = await Api.savePurchaseOrderSaledApplyRefuse(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledList()
    }
  }

  // 云采购售后审核通过
  savePurchaseOrderSaledToExamineAudit = async (params) => {
    const res = await Api.savePurchaseOrderSaledToExamineAudit(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledList()
    }
  }

  // 云采购售后审批驳回
  savePurchaseOrderSaledToExamineFail = async (params) => {
    const res = await Api.savePurchaseOrderSaledToExamineFail(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledList()
    }
  }

  // 云采购售后订单上门取件
  savePurchaseOrderSaledPickUp = async (params) => {
    const res = await Api.savePurchaseOrderSaledPickUp(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledList()
    }
  }

  // 云采购售后换货、补寄发货
  savePurchaseOrderSaledSendingDeliver = async (params) => {
    const res = await Api.savePurchaseOrderSaledSendingDeliver(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledList()
    }
  }

  // 云采购售后退款
  savePurchaseOrderSaledRefund = async (params) => {
    const res = await Api.savePurchaseOrderSaledRefund(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search.beginDate = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search.endDate = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getPurchaseOrderSaledList()
    })
  }

  // 点击查看详情
  showDetailsModal = (record) => {
    this.setState({
      rowDetail: record,
      detailsModal: true
    })
  }

  // 点击查看物流
  showLogisticsModal = (record) => {
    const { backCourierCode, backCourierNumber, deliverCourierCode, deliverCourierNumber } = record
    let courierNumberList = []

    if (backCourierNumber && backCourierNumber.length > 0) {
      const backnums = backCourierNumber.split(',')

      $lodash.forEach(backnums, (o) => {
        courierNumberList.push({
          courierCode: backCourierCode,
          courierNumber: o
        })
      })
    }

    if (deliverCourierNumber && deliverCourierNumber.length > 0) {
      const nums = deliverCourierNumber.split(',')

      $lodash.forEach(nums, (o) => {
        courierNumberList.push({
          courierCode: deliverCourierCode,
          courierNumber: o
        })
      })
    }

    this.setState({
      rowCourierNums: courierNumberList,
      logisticsModal: true
    })
  }

  // 点击查看进度
  showProgressModal = (record) => {
    this.setState({
      rowDetail: record,
      progressModal: true
    })
  }

  // 确定审核状态
  handleConfirmAudit = (params, status) => {
    if (status === 'WAITING') { // 待受理
      this.savePurchaseOrderSaledApplyApproval(params)
    }

    if (status === 'WAITING_AUDIT') { // 待审核
      this.savePurchaseOrderSaledToExamineAudit(params)
    }

    if (status === 'WAITING_BACK') { // 待退货
      this.savePurchaseOrderSaledPickUp(params)
    }

    if (status === 'WAITING_DELIVER') { // 待发货
      this.savePurchaseOrderSaledSendingDeliver(params)
    }

    if (status === 'WAITING_REFUND') { // 待退款
      this.savePurchaseOrderSaledRefund(params)
    }
  }

  // 拒绝审核
  handleRefuseAudit = (params, status) => {
    if (status === 'WAITING') {
      this.savePurchaseOrderSaledApplyRefuse(params)
    }

    if (status === 'WAITING_AUDIT') {
      this.savePurchaseOrderSaledToExamineFail(params)
    }
  }

  closeModal = () => {
    this.setState({
      detailsModal: false,
      logisticsModal: false,
      progressModal: false,
      rowDetail: {},
      rowCourierNums: []
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchaseOrderSaledList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchaseOrderSaledList()
    })
  }

  render () {
    const { buttonMaps } = this.props
    const { searchData, pages, tableData = [], rowDetail = {}, rowCourierNums = [], detailsModal = false, logisticsModal = false, progressModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        <PurchaseDrawer visible={detailsModal}
          buttonMaps={buttonMaps}
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
          onConfirm={(values, status) => this.handleConfirmAudit(values, status)}
          onRefuse={(values, status) => this.handleRefuseAudit(values, status)}
        />

        { // 物流轨迹弹窗
          logisticsModal
            ? <LogisticsModal
              courierNumberList={rowCourierNums}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          progressModal
            ? <SaledProgressModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(PurchaseSaled)
