/** 协作报价 **/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Select } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const FormItem = Form.Item
const Option = Select.Option
const { MAX_PAGESIZE } = consts

class CoordinationForm extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.string,
    coordinationDetails: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    list: [],
    customerList: []
  }

  componentDidMount () {
    this.getMemberList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        this.setState({
          customerList: data
        })
      }
    })
  }

  // 获取全部成员
  getMemberList = async () => {
    const res = await Api.getMemberList({ pageNo: 1, pageSize: MAX_PAGESIZE })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        list: data.list || []
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { platformUserId, distributorId } = values
        const { type = 'HELP_QUOTATION', coordinationDetails = [], onConfirm } = this.props

        let params = {
          enquiryOrderDetails: coordinationDetails,
          platformUserIdList: type === 'HELP_QUOTATION' ? [platformUserId] : platformUserId
        }

        if (type === 'HELP_QUOTATION') {
          params.distributorId = distributorId
        }

        onConfirm(params)
      }
    })
  }

  // 选择商户
  handleChangeDistributor = (distributorId) => {
    const { customerList = [] } = this.state
    const obj = customerList.find((item) => { return item.distributorId === distributorId })

    if (obj !== undefined && obj.platformUserId) {
      setTimeout(() => {
        this.props.form.setFieldsValue({ platformUserId: obj.platformUserId })
      }, 20)
    }
  }

  render () {
    const { form, type } = this.props
    const { getFieldDecorator } = form
    const { list = [], customerList = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <Modal title='协作报价'
        visible
        maskClosable={false}
        onCancel={() => this.props.onCancel()}
        onOk={() => this.handleConfirm()}
      >
        <Form>
          {
            type === 'HELP_QUOTATION'
              ? <FormItem label='询价商户' {...formItemLayout}>
                {
                  getFieldDecorator('distributorId', {
                    rules: [
                      { required: true, message: '请选择询价商户' }
                    ]
                  })(
                    <Select showSearch filterOption={false} onSearch={(val) => this.getMerchantListByKeyword(val)}
                      onChange={(value) => this.handleChangeDistributor(value)}
                    >
                      {
                        customerList.map((ctem) => (
                          <Option value={ctem.distributorId} key={ctem.distributorId}>{ ctem.distributorName }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
              : null
          }

          <FormItem label={type === 'HELP_QUOTATION' ? '协作人' : '协作人(可多选)'} {...formItemLayout}>
            {
              getFieldDecorator('platformUserId', {
                rules: [
                  { required: true, message: '请选择协作人' }
                ]
              })(
                <Select mode={type === 'HELP_QUOTATION' ? null : 'multiple'} showSearch optionFilterProp='children'>
                  {
                    list.map((item) => (
                      <Option value={item.id} key={item.id}>{ item.userName }</Option>
                    ))
                  }
                </Select>
              )
            }
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const CoordinationModal = Form.create()(CoordinationForm)

export default CoordinationModal
