import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Button, Table, Icon, Badge, Modal, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import NoticeModal from './noticeModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const sendStatusOptions = [
  { type: 'ALL', label: '全部' },
  { type: 'SENT', label: '已发送', color: '#52c41a' },
  { type: 'UNSENT', label: '未发送', color: '#1890ff' },
  { type: 'UNKNOWN', label: '草稿', color: '#faad14' }
]
const msgTypeSearchMaps = [
  { id: 'PLATFORM_ANNOUNCEMENT', name: 'IIASaaS公告' }
]
const { confirm } = Modal
const { pageSizeOptions } = filter

class InformationManage extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'msgType',
        formType: 'Select',
        itemParams: {
          label: '信息类型'
        },
        cptParams: {

        },
        options: msgTypeSearchMaps,
        rules: {
          initialValue: 'PLATFORM_ANNOUNCEMENT'
        }
      },
      {
        name: 'sendTime',
        formType: 'RangePicker',
        itemParams: {
          label: '发布时间'
        },
        cptParams: {

        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '发送状态'
        },
        cptParams: {

        },
        options: sendStatusOptions,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: 'ALL'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      msgType: 'PLATFORM_ANNOUNCEMENT',
      status: 'ALL'
    },
    noticeModal: false,
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'informationManage-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '信息类型',
      key: 'informationManage-1',
      render: (text, record, index) => (
        <div>{ this.renderNotifyTypeLabel(record.msgType) }</div>
      )
    },
    {
      title: '标题',
      key: 'informationManage-2',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '内容',
      key: 'informationManage-3',
      render: (text, record, index) => (
        <a onClick={() => this.showNoticeDetailModal(record)}>点击查看内容</a>
      )
    },
    {
      title: '发布时间',
      key: 'informationManage-4',
      width: 110,
      render: (text, record, index) => (
        <div>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '发布人',
      key: 'informationManage-5',
      dataIndex: 'sendName'
    },
    {
      title: '发送状态',
      key: 'informationManage-6',
      width: 80,
      render: (text, record, index) => (
        <div>
          { this.renderNotifySendStatusLabel(record.status) }
        </div>
      )
    },
    {
      title: '发送对象',
      key: 'informationManage-7',
      width: 120,
      render: (text, record, index) => (
        <div>全体商户</div>
      )
    },
    {
      title: '操作',
      key: 'informationManage-8',
      width: 160,
      render: (text, record, index) => (
        <div>
          {
            record.status === 'SENT'
              ? null
              : <div>
                <Button type='primary' size='small' onClick={() => this.handleLinkToEditsysNotice(record, 2)}>编辑</Button>
                <Button type='primary' size='small' style={{ marginLeft: '6px' }}
                  onClick={() => this.handleDeleteSysNotice(record)}
                >删除</Button>
              </div>
          }

        </div>
      )
    }
  ]

  componentDidMount () {
    this.getIIASaaSSystemNoticeList()
  }

  // 获取IIASaaS系统公告列表
  getIIASaaSSystemNoticeList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getIIASaaSSystemNoticeList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 删除消息任务
  deleteIIASaaSSystemNotice = (params) => {
    Api.deleteIIASaaSSystemNotice(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getIIASaaSSystemNoticeList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.sendTime && search.sendTime.length === 2) {
      search['beginDate'] = moment(search.sendTime[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.sendTime[1]).format('YYYY-MM-DD')

      delete search.sendTime
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getIIASaaSSystemNoticeList()
    })
  }

  // 点击新增/编辑消息
  handleLinkToEditsysNotice = (record, type) => {
    this.props.history.push({ pathname: '/admin/editsysNotice', state: { detailInfo: record, type: type } })
  }

  // 点击查看IIASaaS公告详情
  showNoticeDetailModal = (record) => {
    this.setState({
      noticeModal: true,
      rowDetail: record
    })
  }

  // 点击删除
  handleDeleteSysNotice = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.deleteIIASaaSSystemNotice({ id: record.id })
      }
    })
  }

  // 渲染信息类型
  renderNotifyTypeLabel = (status) => {
    let _str = ''
    let obj = msgTypeSearchMaps.find(item => {
      return item.id === status
    })

    if (obj) {
      _str = obj.name
    }

    return _str
  }

  // 渲染发送状态
  renderNotifySendStatusLabel = (status) => {
    let _badge = {}
    let obj = sendStatusOptions.find(item => {
      return item.type === status
    })

    if (obj) {
      _badge = obj
    }

    return <Badge color={_badge.color} text={<span style={{ fontSize: '12px' }}>{_badge.label}</span>} />
  }

  closeModal = () => {
    this.setState({
      noticeModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getIIASaaSSystemNoticeList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getIIASaaSSystemNoticeList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], noticeModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Button type='primary' onClick={() => this.handleLinkToEditsysNotice({}, 1)}>
                <Icon type='plus' />新增公告
              </Button>
            </Row>

            <Row>
              <Table rowKey='id' bordered
                dataSource={tableData}
                columns={this.columns}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          noticeModal
            ? <NoticeModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}

export default InformationManage
