/** 客户模块 **/
import CustomerList from '@/pages/customer/customer-list/index'
import CustomerAdd from '@/pages/customer/customer-add/index'
import CustomerAddressList from '@/pages/customer/customerAddressList'
import SetPay from '@/pages/customer/set-pay/index' // 支付方式配置
import SetPayMethod from '@/pages/customer/set-paymethod/index' // 编辑支付方式

import EucustomerList from '@/pages/customer/eucustomer-list/index' // EU客户列表
import CustomerService from '@/pages/customer/customer-service/index' // 客户服务配置
import CuscloudSet from '@/pages/customer/cloudsupply-set/index' // 商户云供应设置
import Cusservicerecord from '@/pages/customer/customer-servicerecord/index' // 商户服务配置记录
import SupplierList from '@/pages/customer/supplier-list/index' // 供应商列表
import SupplierAdd from '@/pages/customer/supplier-add/index' // 供应商添加/修改
import PurchaserList from '@/pages/customer/purchaserList/index' // 采购商列表
import PurchaserEdit from '@/pages/customer/purchaserEdit/index.jsx'
import TBCustomerList from '@/pages/customer/toolboxCustomerList/index'
import TBCustomerEdit from '@/pages/customer/toolboxCustomerEdit/index'

import EnquireProductSet from '@/pages/customer/enquireProductSet/index' // 特价产品设置

export default [
  {
    path: '/admin/customerList',
    name: '商户列表',
    component: CustomerList
  },
  {
    path: '/admin/editCustomer',
    name: '编辑商户',
    component: CustomerAdd
  },
  {
    path: '/admin/addCustomer',
    name: '添加商户',
    component: CustomerAdd
  },
  {
    path: '/admin/paySet',
    name: '支付设置',
    component: SetPay
  },
  {
    path: '/admin/set-paymethod',
    name: '编辑支付方式',
    component: SetPayMethod
  },
  {
    path: '/admin/euCustomer',
    name: 'EU客户列表',
    component: EucustomerList
  },
  {
    path: '/admin/customerService',
    name: '商户服务配置',
    component: CustomerService
  },
  {
    path: '/admin/cuscloudSet',
    name: '商户云供应设置',
    component: CuscloudSet
  }, {
    path: '/admin/cusServiceRecord',
    name: '商户服务配置记录',
    component: Cusservicerecord
  },
  {
    path: '/admin/supplierList',
    name: '供应商列表',
    component: SupplierList
  },
  {
    path: '/admin/supplierOperate',
    name: '供应商信息',
    component: SupplierAdd
  },
  {
    path: '/admin/purchaserList',
    name: '采购商列表',
    component: PurchaserList
  },
  {
    path: '/admin/purchaserEdit',
    name: '采购商信息',
    component: PurchaserEdit
  },
  {
    path: '/admin/toolboxCustomerList',
    name: '工聚宝客户列表',
    component: TBCustomerList
  },
  {
    path: '/admin/toolboxCustomerEdit',
    name: '工聚宝客户信息',
    component: TBCustomerEdit
  },
  {
    path: '/admin/customerAddressList',
    name: '地址详情',
    component: CustomerAddressList
  },
  {
    path: '/admin/enquireProductSet',
    name: '特价产品设置',
    component: EnquireProductSet
  }
]
