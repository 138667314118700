import React, { Component } from 'react'
import moment from 'moment'
import { Card, Row, Button, Divider } from 'antd'
import $lodash from 'lodash'
import * as echarts from 'echarts'

import SearchForm from '@/components/search-form/index'
import CustomerTable from './customerTable'

import Request from '@/common/api/dashboardApi'
import filter from '@/common/utils/filter'
import { getYearOptions } from '@/common/utils/mUtils'
import { merchantTableRows, quarterOptions } from './localdatas'
import styles from './index.module.scss'
import { Icon } from './../js/Icon' // 伸缩展开icon

const yearOptions = getYearOptions(moment().get('year') - 2019 + 1)
const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')
const yLen = YEAR - 2019 + 1
const colLen = (YEAR - 2019 + 1) * 17 + 1

export default class CustomerReport extends Component {
  static propTypes = {}
  state = {
    faly: 0,
    searchData: [
      {
        name: 'year',
        formType: 'Select',
        itemParams: {
          label: '查询年份'
        },
        cptParams: {
          placeholder: '请选择查询年份...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { id: 0, name: '全部' },
          ...yearOptions
        ]
      },
      {
        name: 'quarter',
        formType: 'Select',
        itemParams: {
          label: '查询季度'
        },
        cptParams: {
          placeholder: '请选择查询季度...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { id: 0, name: '全部' },
          ...quarterOptions
        ]
      },
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月份'
        },
        cptParams: {
          placeholder: '请选择查询月份...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { month: 0, monthLabel: '全部' },
          ...fullsMonthOptions
        ],
        optionValue: ['month', 'monthLabel']
      }
    ],
    query: {},
    tableData: []
  }

  componentDidMount () {
    this.getDashboardAnalyse()
  }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const res = await Request.getDashboardAnalyse({ type: 'big_table' })
    let resObj = this.setCamelCaseKey(res)
    let result = {}

    $lodash.forEach(resObj, (values, key) => {
      let arr = $lodash.map(values, (row) => {
        let len = row.length
        let _month = $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? +row[0].split('-')[1] : null
        let obj = {
          year: $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? row[0].split('-')[0] : `${row[0]}`,
          quarter: len === 3 ? row[1] : _month ? this.setQuarterByMonth(_month) : null,
          month: _month,
          value: len === 3 ? row[2] : row[1],
          keyword: key
        }
        return obj
      })

      result[key] = $lodash.groupBy($lodash.orderBy(arr, ['month'], 'asc'), 'year')
    })

    const { gmvAmountCloud, gmvAmountPrivateDomains, increasedOrderNum } = result

    // 总计GMV(gmvAmountTotal) = 云仓GMV(gmvAmountCloud) + 私域GMV(gmvAmountPrivateDomains)
    result['gmvAmountTotal'] = this.setNeedSumOriginKey(gmvAmountCloud, gmvAmountPrivateDomains, 'gmvAmountTotal')

    // 平均客单价
    result['averagePerTicketSales'] = this.setAveragePerTicketOriginKey(gmvAmountCloud, gmvAmountPrivateDomains, increasedOrderNum, 'averagePerTicketSales')

    this.setState({
      originData: result
    }, () => {
      this.initECharts()
      this.setTableData()
    })
  }

  // 初始化图表
  initECharts = () => {
    let myChart = echarts.init(document.getElementById('serviceNestedPiesChart'))
    let series = this.setNestedPiesData()

    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `全部年份趋势图`
      },
      tooltip: {
        trigger: 'item',
        borderColor: '#fff',
        formatter: (e) => {
          return this.gettitle(e)
        }
      },
      legend: {
        data: [
          '云仓平台订单金额',
          '私域商城订单金额',
          '服务商',
          '经销商',
          '最终厂商'
        ]
      },
      series: [
        {
          name: '',
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '30%'],
          label: {
            position: 'inner',
            fontSize: 14
          },
          labelLine: {
            show: false
          },
          data: series['peiSeries']
        },
        {
          name: '',
          type: 'pie',
          radius: ['45%', '60%'],
          labelLine: {
            length: 30
          },
          label: {
            show: true
          },
          data: series['annularSeries']
        }
      ]
    })
  }

  // 设置图形数据
  setNestedPiesData = () => {
    const { originData = {} } = this.state
    const { gmvAmountCloud = {}, gmvAmountPrivateDomains = {}, accumulativeAgentNum = {}, accumulativeServiceProviderNum = {} } = originData
    let result = {}
    let gmvPrivateValues = gmvAmountPrivateDomains['2021'] || []
    let gmvCloudValues = gmvAmountCloud['2021'] || []
    let accAgentValues = accumulativeAgentNum['2021'] || [] // 累计经销商
    let accServiceProviderValues = accumulativeServiceProviderNum['2021'] || [] // 累计服务商

    let gmvArr = [
      { name: '私域商城订单金额', value: gmvPrivateValues.length > 0 ? $lodash.sumBy(gmvPrivateValues, 'value') : 0 },
      { name: '云仓平台订单金额', value: gmvCloudValues.length > 0 ? $lodash.sumBy(gmvCloudValues, 'value') : 0 }
    ]

    let peiSeriesArr = [
      { name: '最终厂商', value: 0 },
      { name: '服务商', value: accServiceProviderValues.length > 0 ? accServiceProviderValues[accServiceProviderValues.length - 1].value : 0 },
      { name: '经销商', value: accAgentValues.length > 0 ? accAgentValues[accAgentValues.length - 1].value : 0 }
    ]

    result['annularSeries'] = gmvArr
    result['peiSeries'] = peiSeriesArr

    return result
  }

  // 设置表格数据
  setTableData = () => {
    const { originData = {} } = this.state
    let tableData = []

    merchantTableRows.map((row) => {
      let obj = $lodash.assign({}, row)

      for (let i = 0; i < colLen; i++) {
        for (let n = 0; n < yLen; n++) {
          let _word = YEAR - n
          const { gmvAmountPrivateDomains, gmvAmountCloud, increasedPrivateDomainOrderNum, increasedCloudOrderNum, increasedOrderNum,
            activatedEuNumMonth, activatedEuNumQuarter, activatedEuNumYear, accumulativeEuNum, accumulativeServiceProviderNum } = originData
          const dValues = gmvAmountPrivateDomains[`${_word}`]
          const cValues = gmvAmountCloud[`${_word}`]
          const cloudTotalOrderValues = increasedCloudOrderNum[`${_word}`] // 云仓订单数量
          const orderTotalValues = increasedOrderNum[`${_word}`] // 订单总数
          const serviceValues = accumulativeServiceProviderNum[`${_word}`] || []

          switch (row.key) {
            /** 发生【私域商城】采购的服务商VS服务商总数%
             *  privateDomainActivatedMerchantsNum / accumulativeServiceProviderNum
             */
            case 'privateDomainActivatedServiceProviderNumRate':
              const { privateDomainActivatedServiceProviderNumYear, privateDomainActivatedServiceProviderNumQuarter, privateDomainActivatedServiceProviderNumMonth } = originData
              let privateYearValues = privateDomainActivatedServiceProviderNumYear[`${_word}`] || []
              let privateQuarValues = privateDomainActivatedServiceProviderNumQuarter[`${_word}`] || []
              let privateMonthValues = privateDomainActivatedServiceProviderNumMonth[`${_word}`] || []

              let yearServiceVal = serviceValues.length > 0 ? +serviceValues[serviceValues.length - 1].value : 0
              let yearPrivateVal = privateYearValues.length > 0 ? $lodash.sumBy(privateYearValues, 'value') : 0

              obj[`value-${_word}`] = yearServiceVal > 0 ? `${(yearPrivateVal / yearServiceVal * 100).toFixed(2)}%` : '0.00%'

              for (let q = 0; q < 4; q++) {
                let mx = $lodash.findIndex(privateQuarValues, (o) => { return o.quarter === q + 1 })
                let quarArr = $lodash.filter(serviceValues, (o) => { return o.quarter === q + 1 })
                let mval = mx > -1 ? +privateQuarValues[mx].value : 0
                let dval = quarArr.length > 0 ? +$lodash.sumBy(quarArr, 'value') : 0

                obj[`value-${_word}-Q${q + 1}`] = dval > 0 ? `${(mval / dval * 100).toFixed(2)}%` : '0.00%'
              }

              for (let m = 0; m < 12; m++) {
                let mx = $lodash.findIndex(privateMonthValues, (o) => { return o.month === m + 1 })
                let dx = $lodash.findIndex(serviceValues, (o) => { return o.month === m + 1 })
                let mval = mx > -1 ? +privateMonthValues[mx].value : 0
                let dval = dx > -1 ? +serviceValues[dx].value : 0

                obj[`value-${_word}-${m + 1}`] = dval > 0 ? `${(mval / dval * 100).toFixed(2)}%` : '0.00%'
              }

              break

            /** 发生【云仓平台】采购的服务商VS服务商总数%
             *  cloudActivatedMerchantsNum / accumulativeServiceProviderNum
             */
            case 'cloudActivatedServiceProviderNumRate':
              const { cloudActivatedServiceProviderNumYear, cloudActivatedServiceProviderNumQuarter, cloudActivatedServiceProviderNumMonth } = originData
              const cloudActYearValues = cloudActivatedServiceProviderNumYear[`${_word}`] || []
              const cloudActQuarValues = cloudActivatedServiceProviderNumQuarter[`${_word}`] || []
              const cloudActMonthValues = cloudActivatedServiceProviderNumMonth[`${_word}`] || []

              let yearCloudActVal = +$lodash.sumBy(cloudActYearValues, 'value')
              let yearSerVal = serviceValues.length > 0 ? +serviceValues[serviceValues.length - 1].value : 0

              obj[`value-${_word}`] = yearSerVal > 0 ? `${(yearCloudActVal / yearSerVal * 100).toFixed(2)}%` : '0.00%'

              for (let q = 0; q < 4; q++) {
                let mx = $lodash.findIndex(cloudActQuarValues, (o) => { return o.quarter === q + 1 })
                let quarArr = $lodash.filter(serviceValues, (o) => { return o.quarter === q + 1 })
                let mval = mx > -1 ? +cloudActQuarValues[mx].value : 0
                let dval = quarArr.length > 0 ? +$lodash.sumBy(quarArr, 'value') : 0

                obj[`value-${_word}-Q${q + 1}`] = dval > 0 ? `${(mval / dval * 100).toFixed(2)}%` : '0.00%'
              }

              for (let m = 0; m < 12; m++) {
                let mx = $lodash.findIndex(cloudActMonthValues, (o) => { return o.month === m + 1 })
                let dx = $lodash.findIndex(serviceValues, (o) => { return o.month === m + 1 })
                let mval = mx > -1 ? +cloudActMonthValues[mx].value : 0
                let dval = dx > -1 ? +serviceValues[dx].value : 0

                obj[`value-${_word}-${m + 1}`] = dval > 0 ? `${(mval / dval * 100).toFixed(2)}%` : '0.00%'
              }

              break

            /** 发生采购的最终用户VS最终用户总数%
             * 发生采购的最终用户(activatedEuNum) / 最终用户总数(accumulativeEuNum)
             */
            case 'activatedEuNumRate':
              let actValues = activatedEuNumYear[`${_word}`]
              let accValues = accumulativeEuNum[`${_word}`]
              let actMonthValues = activatedEuNumMonth[`${_word}`]
              let actQuarterValues = activatedEuNumQuarter[`${_word}`]
              let yearActVal = $lodash.sumBy(actValues, 'value')
              let yearAccVal = accValues[accValues.length - 1].value || 0

              obj[`value-${_word}`] = yearAccVal > 0 ? `${((+yearActVal / +yearAccVal) * 100).toFixed(2)}%` : '0.00%'

              for (let q = 0; q < 4; q++) {
                let mx = $lodash.findIndex(actQuarterValues, (o) => { return o.quarter === q + 1 })
                let accQuarArr = $lodash.filter(accValues, (o) => { return o.quarter === q + 1 && +o.value > 0 })
                let mval = mx > -1 ? +actQuarterValues[mx].value : 0
                let dval = accQuarArr.length > 0 ? +accQuarArr[accQuarArr.length - 1].value : 0

                obj[`value-${_word}-Q${q + 1}`] = dval > 0 ? `${(mval / dval * 100).toFixed(2)}%` : '0.00%'
              }

              for (let m = 0; m < 12; m++) {
                let mx = $lodash.findIndex(actMonthValues, (o) => { return o.month === m + 1 })
                let dx = $lodash.findIndex(accValues, (o) => { return o.month === m + 1 })

                let mval = mx > -1 ? +actMonthValues[mx].value : 0
                let dval = dx > -1 ? +accValues[dx].value : 0

                obj[`value-${_word}-${m + 1}`] = dval > 0 ? `${(mval / dval * 100).toFixed(2)}%` : '0.00%'
              }

              break

            case 'gmvAmountCloudRate': // 平台云仓GMV/总计GMV 占比
              const { gmvAmountTotal } = originData
              let gmvTotalValues = gmvAmountTotal[`${_word}`] || []

              // 年度占比总计
              let ymval = $lodash.sumBy(cValues, 'value') // 分子
              let ydval = $lodash.sumBy(gmvTotalValues, 'value') // 分母

              obj[`value-${_word}`] = +ydval > 0 ? `${(+ymval / (+ydval) * 100).toFixed(2)}%` : '0.00%'

              // 季度维度占比
              for (let q = 0; q < 4; q++) {
                let cloudArr = $lodash.filter(cValues, (o) => { return o.quarter === q + 1 })
                let gmvTotalArr = $lodash.filter(gmvTotalValues, (o) => { return o.quarter === q + 1 })

                let cloudTotal = cloudArr.length > 0 ? $lodash.sumBy(cValues, 'value') : 0
                let dval = gmvTotalArr.length > 0 ? $lodash.sumBy(gmvTotalArr, 'value') : 0 // 分母

                obj[`value-${_word}-Q${q + 1}`] = +dval > 0 ? `${((+cloudTotal / +dval) * 100).toFixed(2)}%` : '0.00%'
              }

              // 月维度占比
              for (let m = 0; m < 12; m++) {
                let mx = $lodash.findIndex(cValues, (o) => { return o.month === m + 1 })
                let dx = $lodash.findIndex(gmvTotalValues, (o) => { return o.month === m + 1 })

                let mval = mx > -1 ? +cValues[mx].value : 0
                let dval = dx > -1 ? +gmvTotalValues[dx].value : 0

                obj[`value-${_word}-${m + 1}`] = +dval > 0 ? `${(mval / dval * 100).toFixed(2)}%` : '0.00%'
              }

              break
            case 'cloudOrderRate': // 平台云仓订单/总订单数占比
              // 年维度占比
              let cloudOrdersVal = $lodash.sumBy(cloudTotalOrderValues, 'value')
              let totalOrdersVal = $lodash.sumBy(orderTotalValues, 'value')

              obj[`value-${_word}`] = +totalOrdersVal > 0 ? `${(+cloudOrdersVal / +totalOrdersVal * 100).toFixed(2)}%` : '0.00'

              // 季度维度占比
              for (let q = 0; q < 4; q++) {
                let cloudTotalOrders = $lodash.filter(cloudTotalOrderValues, (o) => { return o.quarter === q + 1 })
                let totalOrders = $lodash.filter(orderTotalValues, (o) => { return o.quarter === q + 1 })

                let mval = cloudTotalOrders.length > 0 ? $lodash.sumBy(cloudTotalOrders, 'value') : 0
                let dval = totalOrders.length > 0 ? $lodash.sumBy(totalOrders, 'value') : 0

                obj[`value-${_word}-Q${q + 1}`] = +dval > 0 ? `${(+mval / (+dval) * 100).toFixed(2)}%` : '0.00%'
              }

              // 月度维度占比
              for (let m = 0; m < 12; m++) {
                let cx = $lodash.findIndex(cloudTotalOrderValues, (o) => { return o.month === m + 1 })
                let tx = $lodash.findIndex(orderTotalValues, (o) => { return o.month === m + 1 })

                let mval = cx > -1 ? +cloudTotalOrderValues[cx].value : 0
                let dval = tx > -1 ? +orderTotalValues[tx].value : 0

                obj[`value-${_word}-${m + 1}`] = +dval > 0 ? `${((+mval) / (+dval) * 100).toFixed(2)}%` : '0.00%'
              }

              break

            case 'privateDomainsPerTicketSales': // 私域客单价: 私域GMV(gmvAmountPrivateDomains) / 私域订单数(increasedPrivateDomainOrderNum)
              let privateOrders = increasedPrivateDomainOrderNum[`${_word}`] || []
              // 年维度
              obj[`value-${_word}`] = ($lodash.sumBy(dValues, 'value') / $lodash.sumBy(privateOrders, 'value') * 100 / 100).toFixed(2)

              // 季度维度
              for (let q = 0; q < 4; q++) {
                let gmvdomainsArr = $lodash.filter(dValues, (o) => { return o.quarter === q + 1 })
                let privateOrdersArr = $lodash.filter(privateOrders, (o) => { return o.quarter === q + 1 })

                let mval = gmvdomainsArr.length > 0 ? $lodash.sumBy(gmvdomainsArr, 'value') : 0
                let dval = privateOrdersArr.length > 0 ? $lodash.sumBy(privateOrdersArr, 'value') : 0

                obj[`value-${_word}-Q${q + 1}`] = +dval > 0 ? ((+mval / +dval) * 100 / 100).toFixed(2) : '0.00'
              }

              // 月维度
              for (let m = 0; m < 12; m++) {
                let mx = $lodash.findIndex(dValues, (o) => { return o.month === m + 1 })// 分子: 私域月份GMV
                let dx = $lodash.findIndex(privateOrders, (o) => { return o.month === m + 1 }) // 分母: 私域订单数(月维度)
                let mval = mx > -1 ? dValues[mx].value : 0
                let dval = dx > -1 ? privateOrders[dx].value : 0
                obj[`value-${_word}-${m + 1}`] = +dval > 0 ? ((+mval / +dval) * 100 / 100).toFixed(2) : '0.00'
              }

              break
            case 'cloudPerTicketSales': // 云仓客单价: 云仓GMV(gmvAmountCloud) / 云仓订单数(increasedCloudOrderNum)

              // 年维度
              obj[`value-${_word}`] = ($lodash.sumBy(cValues, 'value') / $lodash.sumBy(cloudTotalOrderValues, 'value') * 100 / 100).toFixed(2)

              // 季度维度
              for (let q = 0; q < 4; q++) {
                let cloudArr = $lodash.filter(cValues, (o) => { return o.quarter === q + 1 })
                let totalOrders = $lodash.filter(cloudTotalOrderValues, (o) => { return o.quarter === q + 1 })

                let mval = cloudArr.length > 0 ? $lodash.sumBy(cloudArr, 'value') : 0
                let dval = totalOrders.length > 0 ? $lodash.sumBy(totalOrders, 'value') : 0

                obj[`value-${_word}-Q${q + 1}`] = +dval > 0 ? ((+mval / +dval) * 100 / 100).toFixed(2) : '0.00'
              }

              // 月维度
              for (let m = 0; m < 12; m++) {
                let mx = $lodash.findIndex(cValues, (o) => { return o.month === m + 1 }) // 分子: 云仓GMV月维度
                let dx = $lodash.findIndex(cloudTotalOrderValues, (o) => { return o.month === m + 1 }) // 分母: 云仓订单数月维度
                let mval = mx > -1 ? cValues[mx].value : 0
                let dval = dx > -1 ? cloudTotalOrderValues[dx].value : 0

                obj[`value-${_word}-${m + 1}`] = dval > 0 ? ((mval / dval) * 100 / 100).toFixed(2) : '0.00'
              }

              break

            /** 云仓客单价/总计客单价占比
             *    云仓客单价 = 云仓GMV(gmvAmountCloud) / 云仓订单数(increasedCloudOrderNum)
             *    总计客单价 = (私域GMV(gmvAmountPrivateDomains) + 云仓GMV(gmvAmountCloud)) / 订单总数(increasedOrderNum)
             */
            case 'cloudPerTicketSalesRate':
              let yearGMVCloudVal = $lodash.sumBy(cValues, 'value')
              let yearGMVPrivateVal = $lodash.sumBy(dValues, 'value')
              let yearCloudOrdersVal = $lodash.sumBy(cloudTotalOrderValues, 'value')
              let yearTotalOrdersVal = $lodash.sumBy(orderTotalValues, 'value')

              let yearCloudPerTicket = yearCloudOrdersVal > 0 ? yearGMVCloudVal / yearCloudOrdersVal : 0
              let yeartotalPerTicket = yearTotalOrdersVal > 0 ? (yearGMVCloudVal + yearGMVPrivateVal) / yearTotalOrdersVal : 0

              // 年维度
              obj[`value-${_word}`] = yeartotalPerTicket > 0 ? `${(yearCloudPerTicket / yeartotalPerTicket * 100).toFixed(2)}%` : '0.00'

              // 季度维度
              for (let q = 0; q < 4; q++) {
                let cloudArr = $lodash.filter(cValues, (o) => { return o.quarter === q + 1 })
                let privateArr = $lodash.filter(dValues, (o) => { return o.quarter === q + 1 })
                let cldOrdersArr = $lodash.filter(cloudTotalOrderValues, (o) => { return o.quarter === q + 1 })
                let totOrdersArr = $lodash.filter(orderTotalValues, (o) => { return o.quarter === q + 1 })

                let gmvCloudVal = cloudArr.length > 0 ? +$lodash.sumBy(cloudArr, 'value') : 0
                let gmvPrivateVal = privateArr.length > 0 ? +$lodash.sumBy(privateArr, 'value') : 0
                let cloudOrdersVal = cldOrdersArr.length > 0 ? +$lodash.sumBy(cldOrdersArr, 'value') : 0
                let ordersTotalVal = totOrdersArr.length > 0 ? +$lodash.sumBy(totOrdersArr, 'value') : 0

                // 云仓客单价 = 云仓GMV / 云仓订单数
                let cloudPerTicket = cloudOrdersVal > 0 ? gmvCloudVal / cloudOrdersVal : 0

                // 平均客单价 = ( 云仓GMV + 私域GMV ) / 订单总数
                let totalPerTicket = ordersTotalVal > 0 ? (gmvCloudVal + gmvPrivateVal) / ordersTotalVal : 0

                obj[`value-${_word}-Q${q + 1}`] = totalPerTicket > 0 ? `${(cloudPerTicket / totalPerTicket * 100).toFixed(2)}%` : '0.00%'
              }

              // 月度维度
              for (let m = 0; m < 12; m++) {
                let x = $lodash.findIndex(cValues, (o) => { return o.month === m + 1 }) // 分子: 云仓GMV月维度
                let d = $lodash.findIndex(dValues, (o) => { return o.month === m + 1 }) // 私域GMV 月维度
                let k = $lodash.findIndex(cloudTotalOrderValues, (o) => { return o.month === m + 1 }) // 分母: 云仓订单数月维度
                let t = $lodash.findIndex(orderTotalValues, (o) => { return o.month === m + 1 }) // 订单总数月维度

                let gmvCloudVal = x > -1 ? +cValues[x].value : 0
                let gmvPrivateVal = d > -1 ? +dValues[d].value : 0
                let cloudOrdersVal = k > -1 ? +cloudTotalOrderValues[k].value : 0
                let ordersTotalVal = t > -1 ? +orderTotalValues[t].value : 0

                // 云仓客单价 = 云仓GMV / 云仓订单数
                let cloudMonthPer = cloudOrdersVal > 0 ? gmvCloudVal / cloudOrdersVal : 0

                // 平均客单价 = ( 云仓GMV + 私域GMV ) / 订单总数
                let totalMonthPer = ordersTotalVal > 0 ? (gmvCloudVal + gmvPrivateVal) / ordersTotalVal : 0

                obj[`value-${_word}-${m + 1}`] = totalMonthPer > 0 ? `${(cloudMonthPer / totalMonthPer * 100).toFixed(2)}%` : '0.00%'
              }

              break
            default:
              let values = originData[row.key] && originData[row.key] !== undefined ? originData[row.key][`${_word}`] : []

              // 年维度
              obj[`value-${_word}`] = values && values.length > 0 ? (row.toFixed ? ($lodash.sumBy(values, 'value') * 100 / 100).toFixed(2) : $lodash.sumBy(values, 'value')) : 0

              // 季度维度
              for (let q = 0; q < 4; q++) {
                let arr = $lodash.filter(values, (o) => { return o.quarter === q + 1 })
                let val = arr.length > 0 ? $lodash.sumBy(arr, 'value') : 0

                obj[`value-${_word}-Q${q + 1}`] = row.toFixed ? `${(val * 100 / 100).toFixed(2)}` : val
              }

              // 月份维度
              for (let k = 0; k < 12; k++) {
                let index = $lodash.findIndex(values, (o) => { return o.month === k + 1 })
                obj[`value-${_word}-${k + 1}`] = index > -1 ? (row.toFixed ? (values[index].value * 100 / 100).toFixed(2) : values[index].value) : 0
              }
          }
        }
      }

      tableData.push(obj)
    })

    this.setState({
      tableData
    })
  }

  // 设置对象key为驼峰
  setCamelCaseKey = (maps) => {
    let result = {}
    $lodash.forEach(maps, (values, key) => {
      result[$lodash.camelCase(key)] = values
    })

    return result
  }

  // 根据月份设置季度
  setQuarterByMonth = (month) => {
    let i = $lodash.findIndex(fullsMonthOptions, (o) => { return o.month === month })
    let quarter = i > -1 ? fullsMonthOptions[i].quarter : null

    return quarter
  }

  // 设置GMV总订单金额字段
  setNeedSumOriginKey = (obj, sourceObj, key) => {
    let result = {}

    for (let n = 0; n < yLen; n++) {
      let arr = []
      let year = YEAR - n
      let tarValues = obj[`${year}`] || []
      let souValues = sourceObj[`${year}`] || []

      for (let m = 0; m < 12; m++) {
        let t = $lodash.findIndex(tarValues, (o) => { return o.month === m + 1 })
        let s = $lodash.findIndex(souValues, (o) => { return o.month === m + 1 })

        let tval = t > -1 ? tarValues[t].value : 0
        let sval = s > -1 ? souValues[s].value : 0

        arr.push({
          year: `${year}`,
          quarter: this.setQuarterByMonth(m + 1),
          month: m + 1,
          value: +((tval + sval) * 100 / 100).toFixed(2),
          keyword: key
        })
      }

      result[`${year}`] = arr
    }

    return result
  }

  /* 设置平均客单价字段
   * 平均客单价 = (云仓GMV + 私域GMV) / 订单总数
  */
  setAveragePerTicketOriginKey = (gmvAmountCloud, gmvAmountPrivateDomains, increasedOrderNum, key) => {
    let result = {}

    for (let n = 0; n < yLen; n++) {
      let year = YEAR - n
      let arr = []
      let gmvCloudValues = gmvAmountCloud[`${year}`] || []
      let gmvDomainsValues = gmvAmountPrivateDomains[`${year}`] || []
      let orderNumValues = increasedOrderNum[`${year}`] || []

      for (let m = 0; m < 12; m++) {
        let t = $lodash.findIndex(gmvCloudValues, (o) => { return o.month === m + 1 })
        let s = $lodash.findIndex(gmvDomainsValues, (o) => { return o.month === m + 1 })
        let r = $lodash.findIndex(orderNumValues, (o) => { return o.month === m + 1 })

        let cloudVal = t > -1 ? +gmvCloudValues[t].value : 0
        let domainsVal = s > -1 ? +gmvDomainsValues[s].value : 0
        let orderVal = r > -1 ? +orderNumValues[r].value : 0

        arr.push({
          year: `${year}`,
          quarter: this.setQuarterByMonth(m + 1),
          month: m + 1,
          value: orderVal > 0 ? +((cloudVal + domainsVal) / orderVal * 100 / 100).toFixed(2) : 0.00,
          keyword: key
        })
      }

      result[`${year}`] = arr
    }

    return result
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    })
  }
  // 设置悬浮
  gettitle = (e) => {
    return (
      `<div>
      <div><span>${e.marker}${e.name}</span></div>
      <div class=${styles.formatter}><span >全部</span><span>${e.value}</span></div>
      </div>`
    )
  }

  // 收起和展开
  getfaly =() => {
    const { faly = 0 } = this.state
    this.setState({
      faly: faly === 0 ? 1 : 0
    })
    let id = document.getElementById('rowid')
    id.style.height = faly === 0 ? '25px' : '400px'
  }
  render () {
    const { searchData, tableData = [], faly } = this.state

    return (
      <>
        <div className={`${styles.searchLand}`}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <div style={{ marginTop: '20px' }}>
          <Card>
            <Row id='rowid' className={styles.mianrow}>
              <div id='serviceNestedPiesChart' style={{ width: `${window.innerWidth - 300}px`, height: '400px' }} />
            </Row>
            <div className={styles.line}> <Divider />
              <Button onClick={() => { this.getfaly() }} className={styles.aicon}> <img src={Icon[faly].src} /></Button>
            </div>
          </Card>

        </div>

        <CustomerTable data={tableData} />
      </>
    )
  }
}
