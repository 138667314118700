/** 首页模块 **/
import Home from '@/pages/home/home/index'
import SetAccount from '@/pages/home/account/index'
import AddressList from '@/pages/home/address-list/index' // 地址管理
import Setpword from '@/pages/home/setpayword/index' // 支付设置
import Statistic from '@/pages/home/statistic/index' // 数据统计概览
// import LoginWhiteList from '@/pages/home/loginWhiteList/index' // 登录白名单
import EarlyWarning from '@/pages/home/earlyWarning/index'
import HotBrand from '@/pages/home/hotBrand/index'

export default [
  {
    path: '/admin/home',
    name: '首页',
    component: Home
  },
  {
    path: '/admin/setAccount',
    name: '账户设置',
    component: SetAccount
  },
  {
    path: '/admin/addressList',
    name: '地址管理',
    component: AddressList
  },
  {
    path: '/admin/setpword',
    name: '支付设置',
    component: Setpword
  },
  {
    path: '/admin/statistic',
    name: '数据统计概览',
    component: Statistic
  },
  // {
  //   path: '/admin/loginWhiteList',
  //   name: '登录白名单列表',
  //   component: LoginWhiteList
  // },
  {
    path: '/admin/earlyWarning',
    name: '预警管理',
    component: EarlyWarning
  },
  {
    path: '/admin/hotBrand',
    name: '优势品牌统计',
    component: HotBrand
  }
]
