import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Divider, Button, Tag } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const { skuGoodsTypeMaps } = filter

const skuGoodsTypeClassMaps = {
  'SPOT': styles.spotTag,
  'FUTURES': styles.futuresTag,
  'CLOUD_SPOT': styles.cloudTag
}

export default class CorrectModal extends Component {
  constructor (props) {
    super(props)
    this.getProductListInCorrectEnquireDetails = $lodash.debounce(this.getProductListInCorrectEnquireDetails, 300)
  }
  static propTypes = {
    keyword: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    tableData: []
  }

  componentDidMount () {
    const { keyword } = this.props

    this.getProductListInCorrectEnquireDetails(keyword)
  }

  // 获取可添加的产品列表
  getProductListInCorrectEnquireDetails = async (value) => {
    const res = await Api.getProductListInCorrectEnquireDetails({ model: value })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 渲染商品类型
  renderSkuGoodsTypeName = (skuGoodsType) => {
    const obj = skuGoodsTypeMaps.find((item) => { return item.id === skuGoodsType })
    const label = obj !== undefined ? obj.aliasName : ''

    return label
  }

  render () {
    const { keyword, onCancel, onConfirm } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title='您输入的产品订货号/型号可能有误' bodyStyle={{ padding: '16px' }}
          width={560}
          visible
          onCancel={() => onCancel()}
          footer={null}
        >
          <div className={`${styles.searchLand}`}>
            <Input placeholder='请输入...' autoComplete='off' defaultValue={keyword}
              onChange={(e) => this.getProductListInCorrectEnquireDetails(e.target.value)}
            />
          </div>
          <div className={`${styles.resultLand}`}>
            <ul style={{ marginBottom: '0px' }}>
              {
                tableData.map((sku, i) => (
                  <li className={`${styles.resultRow}`} key={`waitSelected-${i}`}>
                    <div className={`${styles.resultContainer}`}>
                      <span className={`${styles.brandTag}`}>{sku.brandName}</span>
                      <span className={`${styles.tag} ${skuGoodsTypeClassMaps[sku.skuGoodsType]}`}>{this.renderSkuGoodsTypeName(sku.skuGoodsType)}</span>
                      <span dangerouslySetInnerHTML={{ __html: sku.itemNumberHighlight }} />
                      <Divider type='vertical' style={{ backgroundColor: '#999999' }} />
                      <span dangerouslySetInnerHTML={{ __html: sku.modelHighlight }} />
                    </div>
                    <div className={`${styles.resultBtns}`}>
                      { sku.modelExactMatch || sku.itemNumberExactMatch ? <Tag color='#52C41A'>完全匹配</Tag> : null }

                      <Button type='danger' size='small' onClick={() => onConfirm(sku)}>确定</Button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </Modal>
      </>
    )
  }
}
