import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Radio } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import Api from '@/common/api/index'

// 订单详情-发货批次详情
class OrderSendOutDetails extends Component {
  static propTypes = {
    ident: PropTypes.string,
    detailInfo: PropTypes.object,
    getDetails: PropTypes.func
  }
  state = {
    batchNumberInfo: [],
    tableData: [],
    activeKey: ''
  }
  columns = [
    {
      title: '序号',
      key: 'sendProductDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'sendProductDetailsCol-1',
      width: 120,
      ellipsis: true,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'sendProductDetailsCol-2',
      width: 140,
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'sendProductDetailsCol-3',
      width: 130,
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'sendProductDetailsCol-4',
      width: 180,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '订单总数量',
      key: 'sendProductDetailsCol-6',
      dataIndex: 'purchaseQuantity'
    },
    {
      title: '本次发货数量',
      key: 'sendProductDetailsCol-7',
      dataIndex: 'deliveryQuantity'
    },
    {
      title: '含税单价',
      key: 'sendProductDetailsCol-8',
      width: 100,
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'sendProductDetailsCol-9',
      width: 100,
      dataIndex: 'totalTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{($lodash.round(+record.totalTaxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    this.getOrderSendOutDetails()
  }

  // 获取发货批次
  getOrderSendOutDetails = async () => {
    const { ident, detailInfo = {}, getDetails } = this.props
    let params = {
      pageNo: 1,
      pageSize: 999
    }

    if (ident === 'SUPPLY_ORDER') {
      params.orderId = detailInfo.orderId
    }
    if (ident === 'PURCHASE_ORDER') {
      params.purchaseOrderId = detailInfo.purchaseOrderId
      params.distributorId = detailInfo.distributorId
    }

    const res = await getDetails(params)
    const { code, data } = res
    if (+code === 10000) {
      const { batchNumberInfo = [] } = data
      if (batchNumberInfo && batchNumberInfo.length > 0) {
        const defaultActiveKey = `${batchNumberInfo[0].pickingId}-${batchNumberInfo[0].batchNumber}`

        this.setState({
          batchNumberInfo: batchNumberInfo
        }, () => {
          this.handleChangeTabPane(defaultActiveKey)
        })
      }
    }
  }

  // 获取批次发货产品详细
  getPurchaseOrderSendOutBatchDetail = async (params) => {
    const res = await Api.getPurchaseOrderSendOutBatchDetail(params)
    const { code, data = [] } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 点击切换批次
  handleChangeTabPane = (value) => {
    this.setState({
      activeKey: value
    }, () => {
      this.getPurchaseOrderSendOutBatchDetail({ pickingId: $lodash.split(value, '-')[0], batchNum: $lodash.split(value, '-')[1] })
    })
  }

  tableFooterContent = () => {
    const { tableData = [] } = this.state
    return (
      <div style={{ textAlign: 'right' }}>
        <label className='label'>总计应收金额</label><span style={{ color: '#F5222D' }}>&yen;{ ($lodash.sumBy(tableData, 'totalTaxUnitPrice') * 100 / 100).toFixed(2) }</span>
      </div>
    )
  }

  render () {
    const { batchNumberInfo = [], tableData = [], activeKey = '' } = this.state

    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <Radio.Group buttonStyle='solid' value={activeKey} onChange={(e) => this.handleChangeTabPane(e.target.value)}>
            {
              batchNumberInfo.map((item, i) => (
                <Radio.Button value={`${item.pickingId}-${item.batchNumber}`} key={`${item.pickingId}-${item.batchNumber}`}>
                  {moment(item.pickingOrderTime).format('YYYY-MM-DD')}&nbsp;发货批次-{item.batchNumber}
                </Radio.Button>
              ))
            }
          </Radio.Group>
        </div>
        <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
          footer={() => this.tableFooterContent()}
        />
      </>
    )
  }
}

export default OrderSendOutDetails
