
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_USER_MENU = 'SET_USER_MENU'
export const SET_AUTHOR_BUTTONMAPS = 'SET_AUTHOR_BUTTONMAPS'
export const SET_UNNOTICE_COUNT = 'SET_UNNOTICE_COUNT'
export const SET_NOTICE_DETAIL = 'SET_NOTICE_DETAIL'
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING'
export const SET_EDITENQUIRE_DETAILS = 'SET_EDITENQUIRE_DETAILS'
export const SET_TABLECOLUMN_KEYS = 'SET_TABLECOLUMN_KEYS'

// 设置用户信息
export function setUserInfo (data) {
  return { type: SET_USER_INFO, data }
}

// 设置用户菜单
export function setUserMenu (data) {
  return { type: SET_USER_MENU, data }
}

// 设置用户当前页面权限按钮集合
export function setAuthorButtonMaps (data) {
  return { type: SET_AUTHOR_BUTTONMAPS, data }
}

// 设置未读消息数
export function setUnnoticeCount (data) {
  return { type: SET_UNNOTICE_COUNT, data }
}

// 设置消息详情信息
export function setNoticeDetail (data) {
  return { type: SET_NOTICE_DETAIL, data }
}

// 设置全局Loading
export function setPageLoading (data) {
  return { type: SET_PAGE_LOADING, data }
}

// 询价单-设置新增、编辑询价单详情
export function setEditEnquireDetails (data) {
  return { type: SET_EDITENQUIRE_DETAILS, data }
}

// 设置列表columns配置项
export function setTableColumnKeys (data) {
  return { type: SET_TABLECOLUMN_KEYS, data }
}
