import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Select, Row, Divider } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input
const Option = Select.Option

class BanklinkForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    processorList: PropTypes.array,
    detailInfo: PropTypes.object,
    info: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type, detailInfo } = this.props
        if (type === 2) {
          values.id = detailInfo.id
        }
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { type = 1, info = {}, detailInfo = {}, processorList = [] } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 16
      }
    }

    return (
      <div>
        <Modal width={650}
          title={type === 1 ? '新增账号' : '编辑账号'}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Row style={{ marginBottom: '16px' }}>
            <div style={{ fontSize: '16px', fontWeight: '700' }}>
              {info.merchantName}
            </div>
            <div>{info.domainAddress}</div>
          </Row>
          <Divider />
          <Row>
            <Form {...formItemLayout}>
              <FormItem label='开户银行'>
                {
                  getFieldDecorator('openBank', {
                    initialValue: detailInfo.openBank || '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='开户账户名称'>
                {
                  getFieldDecorator('accountName', {
                    initialValue: detailInfo.accountName || '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='记账宝账号'>
                {
                  getFieldDecorator('bankLinkageNo', {
                    initialValue: detailInfo.bankLinkageNo || '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>

              <FormItem label='前置机'>
                {
                  getFieldDecorator('processorId', {
                    initialValue: detailInfo.processorId || '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Select placeholder='请输入...' autoComplete='off'>
                      {
                        processorList.map((item, i) => (
                          <Option value={item.id} key={`processor-${i}`}>{item.ipAddress}</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
              <FormItem label='描述'>
                {
                  getFieldDecorator('desc', {
                    initialValue: detailInfo.desc || '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const BanklinkModal = Form.create()(BanklinkForm)

export default BanklinkModal
