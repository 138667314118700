import React, { Component } from 'react'
import { message, Modal, Row, Col, Icon, Empty } from 'antd'
import PropTypes from 'prop-types'
import Api from '@/common/api/index'
import moment from 'moment'

import FailDetail from './failDetailsModal'
import styles from './commont.module.scss'

export default class onSelectInfo extends Component {
  static propTypes = {
    oncloseModal: PropTypes.func
  }

  state={
    DataList: [],
    failDetailsModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    this.getOneExpressTaskList()
  }

  // 获取任务进度新
  getOneExpressTaskList=() => {
    let param = {
      pageNo: 1,
      pageSize: 999,
      taskTypeList: ['DEPPON_APPEND_FEE', 'DEPPON_ADD_INFO']
    }
    Api.getOneExpressTaskList(param).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          DataList: data.list || []
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 打开查看失败详情弹窗
  failModal = (item) => {
    this.setState({
      failDetailsModal: true,
      rowDetail: item
    })
  }

  // 关闭查看失败详情弹窗
  oncloseModal = () => {
    this.setState({
      failDetailsModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { DataList = [], failDetailsModal = false, rowDetail = {} } = this.state

    return (
      <div>
        <Modal title='任务进度' bodyStyle={{ padding: '12px', height: '500px', overflowY: 'scroll' }}
          width={1000}
          visible
          mask
          closable={false}
          cancelText='刷新'
          okText='关闭'
          onCancel={() => { this.getOneExpressTaskList() }}
          onOk={this.props.oncloseModal}
        >
          <div>
            <Row>
              {
                DataList.length > 0 && DataList
                  ? <div>
                    {
                      DataList.map((item, i) => (
                        <Col span={24} key={`id-${i}`}>
                          <div style={{ marginBottom: '20px' }}>
                            <div className={styles.rowDIV}><label className={styles.rowLabel}>任务号 ：{item.id}</label></div>
                            <div className={styles.rowDIV}><label className={styles.rowLabel}>任务描述：{item.taskName}</label></div>
                            <div className={styles.rowDIV}><label className={styles.rowLabel}>操作人：{item.createName}</label>
                              {
                                item.createTime
                                  ? <label className={`${styles.rowLabel} ${styles.labelmarginLeft}`}>操作时间：{moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</label>
                                  : '----'
                              }
                            </div>

                            {
                              +item.totalNum !== +item.completeNum
                                ? <div>
                                  <label style={{ color: 'rgb(23, 189, 240)', fontWeight: 'bold' }}><Icon type='question-circle' />受理中</label>
                                </div>
                                : <div>
                                  {
                                    +item.totalNum === (+item.successNum)
                                      ? <div>
                                        <label style={{ color: '#52C41A', fontWeight: 'bold' }}><Icon type='check-circle' />任务成功</label>
                                      </div>
                                      : <div>
                                        <div className={styles.rowDIV}><label style={{ color: 'red', fontWeight: 'bold' }}><Icon type='close-circle' />任务失败</label>
                                          <label className={`${styles.rowLabel} ${styles.labelmarginLeft}`}>失败详情:</label>
                                          <label onClick={() => { this.failModal(item) }} className={styles.labelmarginLeft}><a>查看详情</a></label>
                                        </div>
                                      </div>
                                  }
                                </div>
                            }

                            <div className={styles.rowDIV}>
                              <label className={styles.rowLabel}>添加总数：  { +item.totalNum }</label>
                              <label className={`${styles.rowLabel} ${styles.labelmarginLeft}`}>已成功数：  { +item.successNum } </label>
                              <label className={`${styles.rowLabel} ${styles.labelmarginLeft}`}>失败数：{+item.totalNum - (+item.successNum)}</label>
                            </div>
                          </div>
                        </Col>
                      ))
                    }
                  </div>
                  : <Empty />
              }

            </Row>

          </div>
          {
            failDetailsModal
              ? <FailDetail
                oncloseModal={() => { this.oncloseModal() }}
                rowDetail={rowDetail}
              />
              : null
          }
        </Modal>
      </div>
    )
  }
}
