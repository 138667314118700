/* 标定售价弹窗 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Descriptions, Table, Modal, Row, Col, InputNumber, Button, Icon, message, Tooltip } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import PrcieFormModal from '../priceFormModal/index'
import ColumnsConfigModal from '@/components/columnsConfigModal'

import Api from '@/common/api/index'
import { setTableColumnKeys } from '@/reducers/action'
import consts from '@/common/utils/consts'

import styles from './index.module.scss'

const { confirm } = Modal
const { MAX_NUMBER } = consts
const columnConfigKey = 'ASSGIN_PRICE_TABLE'

const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderEditCell = (form) => {
    const { getFieldDecorator } = form
    const { editing, dataIndex, title, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ marginBottom: '0px' }}>
            {
              getFieldDecorator(dataIndex, {
                rules: [
                  { required: true, message: `请填写${title}!` }
                ],
                initialValue: +record[dataIndex]
              })(
                <InputNumber min={0} max={MAX_NUMBER} />
              )
            }
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderEditCell}</EditableContext.Consumer>
  }
}

class AssginContentsFormTable extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    userInfo: PropTypes.object,
    columnsMap: PropTypes.object, // 列设置
    dispatch: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    tableData: [],
    selectedRowKeys: [],
    priceFormModal: false,
    editingKey: null, // 编辑中的key
    columnsConfigModal: false
  }
  baseColumns = [
    {
      title: '序号',
      key: 'column-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '工单编号',
      key: 'assginPriceCol-workOrderId',
      dataIndex: 'workOrderId'
    },
    {
      title: '售价(￥)',
      key: 'assginPriceCol-sellingPrice',
      editable: true,
      dataIndex: 'sellingPrice'
    },
    {
      title: '供应价(￥)',
      key: 'assginPriceCol-supplyPrice',
      dataIndex: 'supplyPrice'
    },
    {
      title: '数量',
      key: 'assginPriceCol-quantity',
      dataIndex: 'quantity'
    },
    {
      title: '预付比例',
      key: 'assginPriceCol-prepaymentRatio',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => (
        <>{ record.prepaymentRatio ? <>{ (+record.prepaymentRatio * 100 / 100).toFixed(2) }%</> : null }</>
      )
    },
    {
      title: '货期(工作日)',
      key: 'assginPriceCol-deliveryPeriodDays',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>{+record.deliveryPeriodDays === 0 ? '现货' : `${+record.deliveryPeriodDays}`}</>
      )
    },
    {
      title: '是否承担运费',
      key: 'assginPriceCol-isUndertakeFreight',
      dataIndex: 'isUndertakeFreight',
      render: (text, record, index) => (
        <>{ record.isUndertakeFreight === 'UNDERTAKE' ? '是' : '否' }</>
      )
    },
    {
      title: '运费金额',
      key: 'assginPriceCol-freightPrice',
      dataIndex: 'freightPrice',
      render: (text, record, index) => (
        <>&yen;{(+record.freightPrice * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '报价有效期',
      key: 'assginPriceCol-expiryDateTime',
      dataIndex: 'expiryDateTime',
      render: (text, record, index) => (
        <>{ record.expiryDateTime ? moment(record.expiryDateTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '供应商户',
      key: 'assginPriceCol-distributorName',
      ellipsis: true,
      dataIndex: 'distributorName',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.distributorName}>
            <span>{ record.distributorName }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '备注',
      key: 'assginPriceCol-remarks',
      ellipsis: true,
      dataIndex: 'remarks',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.remarks}>
            <span>{ record.remarks }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '报价人',
      key: 'assginPriceCol-platformUserName',
      dataIndex: 'platformUserName'
    },
    {
      title: '操作',
      key: 'column-operations',
      render: (text, record, index) => {
        const { editingKey = null } = this.state
        const editable = this.isEditing(record)

        return editable
          ? (
            <>
              <EditableContext.Consumer>
                {
                  form => (
                    <Button type='link' size='small' onClick={() => { this.handleSaveEdit(form, record) }}>保存</Button>
                  )
                }
              </EditableContext.Consumer>
              <Button type='link' size='small' onClick={() => this.handleCancelEdit()}>取消</Button>
            </>
          )
          : (
            <>
              <Button type='link' size='small' disabled={editingKey} onClick={() => this.handleEditPrice(record)}>修改售价</Button>
            </>
          )
      }
    }
  ]

  componentDidMount () {
    this.getAssginPriceDetals()
  }

  // 获取标定售价内容
  getAssginPriceDetals = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getAssginPriceDetals({ enquiryDetailId: detailInfo.enquiryDetailId, commoditySkuId: detailInfo.commoditySkuId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || [],
        editingKey: null
      })
    }
  }

  // 保存售价修改
  saveEditSellingPriceInAssginPrice = async (params) => {
    const res = await Api.saveEditSellingPriceInAssginPrice(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.getAssginPriceDetals()
    }
  }

  // 保存手动新增协作报价
  saveManualAssginPriceDetails = async (params) => {
    const res = await Api.saveManualAssginPriceDetails(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.handleShowPriceFormModal(false)

      this.getAssginPriceDetals()
    }
  }

  // 保存列表项配置
  saveUserTableColumnSetting = async (params, newcolumnsMap) => {
    const res = await Api.saveUserTableColumnSetting(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.props.dispatch(setTableColumnKeys({ columnsMap: newcolumnsMap }))

      this.closeModal()
    } else {
      message.warning(res.message)
    }
  }

  // 点击确定
  handleConfirm = () => {
    const { detailInfo = {} } = this.props
    const { selectedRowKeys = [] } = this.state

    if (selectedRowKeys.length === 0) {
      return message.warning('请选择售价')
    }

    this.props.onConfirm({ ...detailInfo, enquiryCollaborateId: selectedRowKeys[0], collaborateState: 'HAS_QUOTATION' })
  }

  // 点击选择售价
  handleSelectedChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击新增手写按钮
  handleShowPriceFormModal = (bool) => {
    this.setState({
      priceFormModal: bool
    })
  }

  // 确定新增手写
  handleConfirmManualPrice = (values) => {
    const { detailInfo = {} } = this.props

    values.orderId = detailInfo.orderId
    values.commoditySkuId = detailInfo.commoditySkuId

    const params = {
      commoditySkuId: detailInfo.commoditySkuId,
      enquiryDetailId: detailInfo.enquiryDetailId,
      quotationDetailList: [values]
    }

    this.saveManualAssginPriceDetails(params)
  }

  // 点击修改售价
  handleEditPrice = (record) => {
    this.setState({
      editingKey: record.id
    })
  }

  // 点击保存售价
  handleSaveEdit = (form, record) => {
    form.validateFields((err, values) => {
      if (!err) {
        this.saveEditSellingPriceInAssginPrice({ ...values, id: record.id })
      }
    })
  }

  // 点击取消保存编辑
  handleCancelEdit = (record, index) => {
    confirm({
      title: '确定取消保存？',
      onOk: () => {
        this.setState({
          editingKey: null
        })
      }
    })
  }

  isEditing = (record) => record.id === this.state.editingKey

  // 点击列设置
  showColumnsConfigModal = (bool) => {
    this.setState({
      columnsConfigModal: bool
    })
  }

  // 确定保存列表项配置
  handleConfirmTableColumnSetting = (selectedKeys, newcolumns) => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: {
        selectedColumnKeys: [...selectedKeys],
        sortColumnKeys: [...newcolumns]
      }
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  // 确定重置列表项配置
  handleResetTableColumnSetting = () => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: null
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  render () {
    const { detailInfo = {}, columnsMap = {}, onCancel } = this.props
    const { tableData = [], selectedRowKeys = [], priceFormModal = false, columnsConfigModal = false } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    /**
     * 获取redux缓存里面的相关页面的列展示信息
     *   sortColumnKeys: 排序后的所有列的key selectedColumnKeys:要展示的列的key
     * 1- 首先将baseColumns列顺序按 sortColumnKeys 里面的顺序排序
     * 2- 将baseColumns过滤 保留包含在 selectedColumnKeys中的key
     */
    const sortColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].sortColumnKeys ? columnsMap[columnConfigKey].sortColumnKeys : []
    const selectedColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys ? columnsMap[columnConfigKey].selectedColumnKeys : []

    const newArr = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : this.baseColumns

    // 列表展示的项
    const tableColumns = selectedColumnKeys.length > 0 ? $lodash.filter(newArr, (record) => { return $lodash.includes(selectedColumnKeys, record.key) }) : newArr

    const _baseColumns = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : $lodash.map(this.baseColumns, (record) => { return { key: record.key, title: record.title } })

    const columnsConfig = $lodash.filter(_baseColumns, (o) => { return !$lodash.includes(['column-0', 'column-operations'], o.key) })

    const checkedColKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys
      ? columnsMap[columnConfigKey].selectedColumnKeys
      : $lodash.map(columnsConfig, (o) => { return o.key })

    const columns = tableColumns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      }
    })

    const rowSelection = {
      columnTitle: <>
        <Tooltip title='列设置'>
          <a className={`${styles.anticonSetting}`} onClick={() => this.showColumnsConfigModal(true)}><Icon type='setting' style={{ fontSize: '20px' }} /></a>
        </Tooltip>
      </>,
      columnWidth: 44,
      type: 'radio',
      selectedRowKeys: selectedRowKeys,
      onChange: this.handleSelectedChange,
      getCheckboxProps: record => ({
        disabled: +record.commoditySkuId === 0
      })
    }

    return (
      <>
        <div className={`${styles.descriptionsBox}`} style={{ marginBottom: '15px' }}>
          <Descriptions>
            <Descriptions.Item label='品牌'>{ detailInfo.brandName }</Descriptions.Item>
            <Descriptions.Item label='型号'>{ detailInfo.model }</Descriptions.Item>
            <Descriptions.Item label='订货号'>{ detailInfo.itemNumber }</Descriptions.Item>

            <Descriptions.Item label='产品名称'>{ detailInfo.name }</Descriptions.Item>
            <Descriptions.Item label='单位'>{ detailInfo.unit }</Descriptions.Item>
            <Descriptions.Item label='数量'>{ detailInfo.quantity }</Descriptions.Item>

            <Descriptions.Item label='期望价格'>
              { detailInfo.expectPrice ? <>&yen;{ (+detailInfo.expectPrice * 100 / 100).toFixed(2) }</> : null }
            </Descriptions.Item>
            <Descriptions.Item label='期望折扣'>
              { detailInfo.expectDiscount ? <>{ (+detailInfo.expectDiscount * 100 / 100).toFixed(2) }%</> : null }
            </Descriptions.Item>
            <Descriptions.Item label='期望货期'>
              { +detailInfo.expectDeliveryPeriodDays > 0 ? `${+detailInfo.expectDeliveryPeriodDays}工作日` : '现货' }
            </Descriptions.Item>
          </Descriptions>
        </div>

        <Row style={{ marginBottom: '12px' }}>
          <Col span={16}>
            <Button type='primary' onClick={() => this.handleShowPriceFormModal(true)}><Icon type='plus' />新增手写</Button>
          </Col>
        </Row>

        <div>
          <EditableContext.Provider value={this.props.form}>
            <Table rowKey='id' bordered
              components={components}
              columns={columns}
              dataSource={tableData}
              rowSelection={rowSelection}
              pagination={false}
            />
          </EditableContext.Provider>
        </div>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>

          <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleConfirm()}>确定</Button>
        </div>

        {
          priceFormModal
            ? <PrcieFormModal
              commoditySkuId={detailInfo.commoditySkuId}
              onConfirm={(values) => this.handleConfirmManualPrice(values)}
              onCancel={() => this.handleShowPriceFormModal(false)}
            />
            : null
        }

        {
          columnsConfigModal
            ? <ColumnsConfigModal
              configKey={columnConfigKey}
              selectedColumnKeys={checkedColKeys}
              disabledBeforeColumns={[{ key: 'column-0', title: '序号' }]}
              disabledAfterColumns={[{ key: 'column-operations', title: '操作' }]}
              allcolumns={columnsConfig}
              onConfirm={(checkedKeys, columns) => this.handleConfirmTableColumnSetting(checkedKeys, columns)}
              onReset={() => this.handleResetTableColumnSetting()}
              onCancel={() => this.showColumnsConfigModal(false)}
            />
            : null
        }
      </>
    )
  }
}

const AssginPriceContents = Form.create()(AssginContentsFormTable)

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    columnsMap: state.columnsMap || {}
  }
}

export default connect(mapStateToProps)(AssginPriceContents)
