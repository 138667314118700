import Ajax from '../utils/ajax'

export default {
  /* banner模板 */
  // 获取banner模板列表
  getBannerMaterialList (params) {
    return Ajax.get('/mallModel/bannerList', { params })
  },

  // 保存新增banner模板
  saveAddBannerMaterial (params) {
    return Ajax.post('/mallModel/addBanner', params)
  },

  // 更新banner模板
  saveUpdateBannerMaterial (params) {
    return Ajax.post('/mallModel/updateBanner', params)
  },

  // 获取品牌封面信息
  getBrandCoverDetail (params) {
    return Ajax.get('/mallModel/queryBrandRegionModelVo', { params })
  },

  // 保存新增/编辑品牌封面信息
  saveSetBrandCoverDetail (params) {
    return Ajax.post('/mallModel/saveBrandModel', params)
  },

  /* 品牌馆 */
  // 品牌馆详情
  getBrandmuseContainerInfo (params) {
    return Ajax.post('/platform/brandHouse/containerInfo', params)
  },

  // 容器新增
  saveAddBrandmuseContainer (params) {
    return Ajax.post('/platform/brandHouse/containerAdd', params)
  },

  // 容器编辑
  saveEditBrandmuseContainer (params) {
    return Ajax.post('/platform/brandHouse/containerEdit', params)
  },

  // 容器删除
  deleteBrandmuseContainer (params) {
    return Ajax.post('/platform/brandHouse/containerDelete', params)
  },

  // 获取模板内容
  getBrandmuseTemplateElementList (params) {
    return Ajax.post('/platform/brandHouse/selectElementByContainerId', params)
  },

  // 模板内容编辑
  saveEditBrandmuseElement (params) {
    return Ajax.post('/platform/brandHouse/elementEdit', params)
  },

  // 模板内容删除
  deleteBrandmuseElement (params) {
    return Ajax.post('/platform/brandHouse/elementDelete', params)
  },

  /* 名片 */
  // 获取名片申请列表
  getCardMiniProgramApplyList (params) {
    return Ajax.get('/businessCard/page', { params })
  },

  // 保存名片申请审核
  updateCardApplyStatus (params) {
    return Ajax.post('/businessCard/update', params)
  },

  // 获取标签列表
  getLabelList (params) {
    return Ajax.post('/platform/distributorLabel/listPage', params)
  },

  // 保存标签
  saveLabel (params) {
    return Ajax.post('/platform/distributorLabel/labelSave', params)
  },

  // 标签删除
  deleteLabel (params) {
    return Ajax.post('/platform/distributorLabel/delete', params)
  },

  /* IIASaaS banner广告 */
  getAdvertListInBanner (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/listPageForSetting', params)
  },

  // 新增banner广告
  saveAdvertInBanner (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/advertisingAdd', params)
  },

  // 编辑banner广告
  editAdvertInBanner (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/advertisingEdit', params)
  },

  // 获取banner广告配置详情
  getAdvertDetailInBanner (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/infoByAdvertising', params)
  },

  // 新增banner广告容器
  saveAdvertContainerAddInBanner (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/containerAdd', params)
  },

  // 编辑banner广告容器
  editAdvertContainerInBanner (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/containerEdit', params)
  },

  // 删除banner广告容器
  deleteAdvertContainerInBanner (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/containerDelete', params)
  },

  // banner广告页面容器元素新增
  saveElementInBannerContainer (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/elementAdd', params)
  },

  // banner广告页面容器元素编辑
  editElementInBannerContainer (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/elementEdit', params)
  },

  // banner广告容器设置-生成产品链接
  generatorDetailInBannerAdvert (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/generatorDetailUrl', params)
  },

  // banner广告绑定商户
  saveBannerAdvertBindMerchants (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/merchantBatchInsert', params)
  },

  // banner广告已绑定商户列表
  getBannerAdvertBindedMerchants (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/merchantInfoList', params)
  },

  // banner广告商户启用禁用
  updateBannerAdvertStatusInMerchant (params) {
    return Ajax.post('/platform/cloudAdvertisingSetting/changeStatus', params)
  },

  /* 云产品推荐 */
  // 云仓产品推荐设置
  saveRecommendProductSetting (params) {
    return Ajax.post('/platform/recommendSku/add', params)
  },

  // 推荐商品列表
  getRecommendProductList (params) {
    return Ajax.post('/platform/recommendSku/listPage', params)
  },

  // 推荐商品上下架
  updateRecommendStatus (params) {
    return Ajax.post('/platform/recommendSku//modify', params)
  },

  // 推荐商品删除
  deleteProductInRecommend (params) {
    return Ajax.post('/platform/recommendSku/delete', params)
  },

  // 通知工具联系人列表列表
  notificationList (params) {
    return Ajax.post('cloudMsgNotice/listAll', params)
  },

  // 通知工具联系人添加
  notificationAdd (params) {
    return Ajax.post('cloudMsgNotice/store', params)
  },

  // 通知工具删除联系人
  notificationDelete (params) {
    return Ajax.get('cloudMsgNotice/delete', { params })
  },

  // 通知工具设置时间/cloudMsgNotice/timeQuery
  notificationTime (params) {
    return Ajax.post('/cloudMsgNotice/timeSet', params)
  },

  // 通知工具设置时间
  notificationSelectime (params) {
    return Ajax.post('/cloudMsgNotice/timeQuery', params)
  },

  // 群发通知发布
  distributionSend (params) {
    return Ajax.get('/cloudMsgNotice/groupSend', { params })
  },
  // 短信发送记录
  getsmsSendRecordlist (params) {
    return Ajax.post('/sms/get/smsSendRecord/list', params)
  },
  // 短信模板类型
  getsmsTemplate (params) {
    return Ajax.post('/sms/get/smsTemplate/list', params)
  },
  // 短信模板新增
  getsmsAddTemplate (params) {
    return Ajax.post('/sms/add/smsTemplate', params)
  },
  // 短信模板修改
  getsmsmodifyTemplate (params) {
    return Ajax.post('/sms/modify/smsTemplate', params)
  },

  // 短信模板审核状态查询
  getSynSmsTemplateStatus (params) {
    return Ajax.get('/sms/batch/synSmsTemplateStatus', { params })
  },

  // 短信发送状态查询
  getSynSmsSendRecordStatus (params) {
    return Ajax.get('/sms/batch/synSmsSendRecordStatus', { params })
  }

}
