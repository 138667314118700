import Ajax from '../utils/ajax'

export default {
  // 新建询价单-搜索产品
  getEnquireSearchProductList (params) {
    return Ajax.post('/product/index/search', params)
  },

  // 询价单-保存新增询价单
  saveAddEnquireOrder (params) {
    return Ajax.post('/enquiry/order/manage/center/add', params)
  },

  // 询价单-更新询价单
  updateEnquireOrder (params) {
    return Ajax.post('/enquiry/order/manage/center/update', params)
  },

  // 询价单-获取询价单列表
  getEnquireOrderList (params) {
    return Ajax.post('/enquiry/order/manage/center/listPage', params)
  },

  // 询价单-询价详情修正结果查询
  getCorrectEnquireOrderDetails (params) {
    return Ajax.get('/enquiry/order/manage/center/detail/correct/query', { params })
  },

  // 询价单-询价详情修正结果更新
  updateCorrectEnquireOrderDetails (params) {
    return Ajax.post('/enquiry/order/manage/center/detail/correct/update', params)
  },

  // 询价单-保存询价详情
  saveCorrectEnquireOrderDetails (params) {
    return Ajax.post('/enquiry/order/manage/center/detail/correct/add', params)
  },

  // 询价单-修正产品 > 获取待选产品列表
  getProductListInCorrectEnquireDetails (params) {
    return Ajax.get('/product/index/singleSearch', { params })
  },

  // 询价单-获取标定售价内容
  getAssginPriceDetals (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/workOrderDetailList', params)
  },

  // 询价单-标定售价-保存修改售价
  saveEditSellingPriceInAssginPrice (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/sellingPriceEdit', params)
  },

  // 询价单-保存手动新增协作报价
  saveManualAssginPriceDetails (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/createdCollaborateQuotation', params)
  },

  // 询价单-获取询价过程详情
  getEnquireProcessDetails (params) {
    return Ajax.get('/enquiry/order/manage/center/query/enquiry/process', { params })
  },

  // 询价单-获取报价单PDF url
  getPdfUrlInEnquireOrderList (params) {
    return Ajax.get('/enquiry/order/manage/center/get/pdf/url', { params })
  },

  // 报价单-获取报价单预览数据
  getPreviewQuotationDetails (params) {
    return Ajax.post('/enquiry/order/manage/center/detail/preview/Quotation', params)
  },

  // 报价单-生成报价单
  saveCreateQuotationOrder (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/generate', params)
  },

  // 报价单-获取报价单列表
  getQuotationOrderList (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/listPage', params)
  },

  // 报价单-获取报价单详情
  getQuotationOrderDetails (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/getQuotationOrderDetail', params)
  },

  // 报价单- 获取报价单详情PDF
  getQuotationOrderDetailsPdfUrl (params) {
    return Ajax.get('/api/platform/enquiry/quotationOrder/getDetailPDF', { params })
  },

  // 询价单- 获取报价单预 > 预览订单产品信息
  getQuotationPreviewOrderProductDetails (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/enquiryAdvanceOrderResult', params)
  },

  // 询价单- 模糊搜索询价单号
  getEnqireOrderIdsInLike (params) {
    return Ajax.get('/enquiry/order/manage/center/search/enquiryOrderId', { params })
  },

  // 询价单- 根据工单编号精确查找产品数据
  getEnquireProductInfoByWorkOrderId (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/getDetailForChooseSalePrice', params)
  },

  // 询价单- 查看自动报价任务进度
  getAutoQuotationProgressDetails (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/getTaskInfo', params)
  },

  // 询价信息发布- 获取发布的询价信息列表
  getEnquireInformationPublishList (params) {
    return Ajax.post('/oms/enquiry/dailyNotice/listPage', params)
  },

  // 询价信息发布- 新增询价信息
  saveCreateEnquireInformationPublish (params) {
    return Ajax.post('/oms/enquiry/dailyNotice/add', params)
  },

  // 询价信息发布- 新增询价信息
  saveEditEnquireInformationPublish (params) {
    return Ajax.post('/oms/enquiry/dailyNotice/modify', params)
  },

  // 询价单- 获取我的预报价单列表
  getPrepareQuotationsOrderList (params) {
    return Ajax.post('/enquiry/predict/quotation/order/listPage', params)
  },

  // 询价单- 获取预报价单详情
  getPrepareQuotationsDetails (params) {
    return Ajax.post('/enquiry/predict/quotation/order/detail', params)
  },

  // 询价单- 获取预报价单PDF路径
  getPrepareQuotationOrderPdfUrl (params) {
    return Ajax.post('/enquiry/predict/quotation/order/findPdf', params)
  },

  // 获取询价统计列表
  getEnquiryBoardList (params) {
    return Ajax.post('/enquiry/order/manage/center/statistics', params)
  },

  // 获取报价统计列表
  getQuotationsBoardList (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/statistics', params)
  },

  // 获取预报价统计列表
  getPrepareQuotationsBoardList (params) {
    return Ajax.post('/enquiry/predict/quotation/order/listPageForStatistics', params)
  }
}
