import React, { Component } from 'react'
import { Card, Row, Divider, Button } from 'antd'
import * as echarts from 'echarts'
import moment from 'moment'
import $lodash from 'lodash'

import Data from './units'
import SearchForm from '@/components/search-form/index'
import OrderTable from './orderTable'
import Request from '@/common/api/dashboardApi'
import filter from '@/common/utils/filter'
import { getYearOptions } from '@/common/utils/mUtils'

import styles from './index.module.scss'

import { Icon } from '../js/Icon' // 伸缩展开icon

// 当前年
const YEAR = moment().get('year')
const { fullsMonthOptions, echartscolorinit } = filter

// 订单数量统计
export default class OrderNumbersReport extends Component {
  static propTypes = {}
  state = {
    faly: 0,
    searchData: [
      {
        name: 'year',
        formType: 'Select',
        itemParams: {
          label: '查询年份'
        },
        cptParams: {
          placeholder: '请选择查询年份...'
        },
        rules: {
          initialValue: YEAR
        },
        options: getYearOptions(YEAR - 2019 + 1)
      },
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月份'
        },
        cptParams: {
          placeholder: '请选择查询月份...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { month: 0, monthLabel: '全部' },
          ...fullsMonthOptions
        ],
        optionValue: ['month', 'monthLabel']
      }
    ],
    query: {
      year: YEAR,
      month: 0
    },
    originChartData: [], // 图形源数据
    tableData: [], // 表格数据
    series: [],
    originData: {} // 源数据
  }

  componentDidMount () {
    this.getDashboardAnalyse()
  }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const res = await Request.getDashboardAnalyse({ type: 'order_amount_dimension' })
    this.getTableDataInit(res)
    this.getEchartsInit(res)
  }

  // Table数据初始化
  getTableDataInit = (res) => {
    let purchaseArr = Data.DatainitPurchase(res)
    let supplyArr = Data.DatainitSupply(res)
    let selfArr = Data.DatainitEu(res)

    this.setState({
      originData: $lodash.groupBy([...purchaseArr, ...supplyArr, ...selfArr], 'year')
    }, () => {
      this.setTableData()
    })
  }
  // Echarts数据初始化
   getEchartsInit=(res) => {
     let newresult = this.getinitone(res)
     let lineObj = $lodash.groupBy(newresult[YEAR], 'name')
     let barSeries = this.setEChartData(lineObj, 'bar')
     let lineSeries = this.setEChartData(lineObj, 'line')
     this.setState({
       originChartData: newresult,
       series: [...barSeries, ...lineSeries]
     }, () => {
       this.initECharts()
     })
   }

   // 设置Echarts数据格式
   getinitone = (res) => {
     let values = $lodash.map([...res.cloud_purchase_thisyear, ...res.eu_order_thisyear, ...res.cloud_supply_thisyear], (item) => {
       let obj = {
         name: item[3].split(',')[0],
         regionCode: item[3].split(',')[1],
         year: item[2].split('-')[0],
         month: +item[2].split('-')[1],
         value: item[1],
         quantity: item[0]
       }
       return obj
     })

     let newvalues = []
     for (let i in values) {
       let key = newvalues.findIndex(item => { return item.name === values[i].name && item.year === values[i].year && item.month === values[i].month })
       if (key > -1) {
         newvalues[key].value += values[i].value
         newvalues[key].quantity += values[i].quantity
       } else {
         let obj = {
           name: values[i].name,
           regionCode: values[i].regionCode,
           year: values[i].year,
           month: values[i].month,
           value: values[i].value,
           quantity: values[i].quantity
         }
         newvalues.push(obj)
       }
     }

     return $lodash.groupBy(newvalues, 'year')
   }

   // 设置EChart图表数据格式
  setEChartData = (maps, type) => {
    let arr = []
    $lodash.forEach(maps, (values, key) => {
      values = $lodash.unionBy(values, fullsMonthOptions, 'month')
      values = $lodash.orderBy(values, ['month'], 'asc')
      let obj = {
        name: key,
        type: type,
        data: type === 'bar' ? $lodash.map(values, 'value') : $lodash.map(values, 'quantity'),
        data1: type === 'bar' ? $lodash.map(values, 'quantity') : $lodash.map(values, 'value'),
        yAxisIndex: type === 'bar' ? 0 : 1
      }
      arr.push(obj)
    })

    return arr
  }

  // 初始化ECharts
  initECharts = () => {
    var myChart
    if (document.getElementById('orderNumbersChart') == null) {
      return
    }
    echarts.dispose(document.getElementById('orderNumbersChart'))
    myChart = echarts.init(document.getElementById('orderNumbersChart'))
    const { series = [] } = this.state
    this.getdatainit(series)
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${this.getYearinit()}趋势图`
      },
      tooltip: {
        borderColor: '#fff',
        borderWidth: '1',
        formatter: (e) => {
          return this.gettitle(e)
        },
        borderRadius: 4
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      legend: {
        orient: 'horizontal',
        width: '400px', // 图例组件的宽度
        height: '300px',
        right: '13%',
        selectedMode: true, // 图例选择的模式，
        formatter: (name) => {
          return `${name}`
        }
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '订单金额(￥)',
          axisLabel: {
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: '订单数量(单)',
          axisLabel: {
            formatter: '{value}'
          }
        }
      ],
      series: series
    })
  }

  // 设置表格源数据
  setTableData = () => {
    const { query = {}, originData = {} } = this.state
    let month = query.month
    let yearMaps = $lodash.groupBy(originData[query.year], 'name')
    let result = []
    $lodash.forEach(yearMaps, (values, key) => {
      let obj = {
        name: key
      }
      let selfArr = $lodash.filter(values, (stem) => { return +month === 0 ? stem.keyword === 'self' : stem.keyword === 'self' && +stem.month === +month })
      let purchaseArr = $lodash.filter(values, (stem) => { return +month === 0 ? stem.keyword === 'purchase' : stem.keyword === 'purchase' && +stem.month === +month })
      let supplyArr = $lodash.filter(values, (stem) => { return +month === 0 ? stem.keyword === 'supply' : stem.keyword === 'supply' && +stem.month === +month })
      obj['EUorderNumber'] = $lodash.sumBy(selfArr, 'quantity')
      obj['EUorderAmount'] = $lodash.sumBy(selfArr, 'value')

      obj['purchaseOrderNumber'] = $lodash.sumBy(purchaseArr, 'quantity')
      obj['purchaseOrderAmount'] = $lodash.sumBy(purchaseArr, 'value')

      obj['supplyOrderNumber'] = $lodash.sumBy(supplyArr, 'quantity')
      obj['supplyOrderAmount'] = $lodash.sumBy(supplyArr, 'value')

      obj['regionOrderNumber'] = obj['EUorderNumber'] + obj['supplyOrderNumber'] + obj['purchaseOrderNumber']
      obj['regionOrderAmount'] = obj['EUorderAmount'] + obj['purchaseOrderAmount'] + obj['supplyOrderAmount']

      result.push(obj)
    })
    let arrlist = [...result, { name: '订单类型总计',
      EUorderNumber: $lodash.sumBy(result, 'EUorderNumber'),
      EUorderAmount: $lodash.sumBy(result, 'EUorderAmount'),
      purchaseOrderNumber: $lodash.sumBy(result, 'purchaseOrderNumber'),
      purchaseOrderAmount: $lodash.sumBy(result, 'purchaseOrderAmount'),
      supplyOrderNumber: $lodash.sumBy(result, 'supplyOrderNumber'),
      supplyOrderAmount: $lodash.sumBy(result, 'supplyOrderAmount'),
      regionOrderNumber: $lodash.sumBy(result, 'regionOrderNumber'),
      regionOrderAmount: $lodash.sumBy(result, 'regionOrderAmount')

    }]
    this.setState({
      tableData: arrlist
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.year === undefined) {
      search.year = YEAR
      search.month = 0
    }
    const { originChartData } = this.state
    let lineObj = $lodash.groupBy(originChartData[search.year], 'name')
    let barSeries = this.setEChartData(lineObj, 'bar')
    let lineSeries = this.setEChartData(lineObj, 'line')
    this.setState({
      query: search,
      series: [...barSeries, ...lineSeries]
    }, () => {
      this.initECharts()
      this.setTableData()
    })
  }

  getdatainit =(series) => {
    series.map((item) => {
      if (item.type === 'bar') {
        item.name = `${(item.name).substring(0, 2)}(金额数)`
      }
      if (item.type === 'line') {
        item.name = `${(item.name).substring(0, 2)}(订单数)`
      }
    })
  }

  // 设置地区
  getareainit=(seriesName) => {
    let name = seriesName.substring(0, 2)
    return `${name}地区`
  }

  // 显示年份
  getYearinit=() => {
    const { query } = this.state
    return query.year
  }

  // 设置悬浮
  gettitle = (e) => {
    // bar
    let formatterbar = `<span style="display:inline-block;margin-right:4px;border-radius:0px;width:20px;height:10px;background-color:${e.color};"></span>`
    // line
    let formatteline = `<div style="display:flex">
          <div style="color:${e.color}">-</div>
          <div style="width:12px;height:12px; border:1px solid ${e.color}; border-radius:500px;}"></div>
          <div style="color:${e.color};">-</div></div>`
    let i = e.dataIndex
    let type = e.componentSubType
    const { series } = this.state
    let obj = $lodash.filter(series, (item) => { return item.name === e.seriesName })
    return (
      `<div class=${styles.formatter}>
    <div><span>${this.getareainit(e.seriesName)}</span> <span class=${styles.formatterspan}> ${this.getYearinit()}-${e.name}</span></div>
    <div><span class=${styles.spanicon}>${formatterbar}</span> <span>订单金额</span> <span class=${styles.formatterspan}>${type === 'bar' ? obj[0].data ? obj[0].data[i] : 0 : obj[0].data1 ? obj[0].data1[i] : 0}</span></div>
    <div><span class=${styles.spanicon}>${formatteline}</span> <span>订单数量</span> <span class=${styles.formatterspan}>${type === 'bar' ? obj[0].data1 ? obj[0].data1[i] : 0 : obj[0].data ? obj[0].data[i] : 0}</span></div>
    </div>`
    )
  }

  // 收起和展开
  getfaly =() => {
    const { faly = 0 } = this.state
    this.setState({
      faly: faly === 0 ? 1 : 0
    })
    let id = document.getElementById('rowid')

    id.style.height = faly === 0 ? '25px' : '400px'
  }

  render () {
    const { searchData, tableData, faly } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Row style={{ display: 'block', marginTop: '20px', whiteSpace: 'nowrap' }}>
          <Card>
            <Row id='rowid' className={styles.mianrow}>
              <div id='orderNumbersChart' style={{ width: `${window.innerWidth - 300}px`, height: '400px' }} />
            </Row>

            <div className={styles.line}> <Divider />
              <Button onClick={() => { this.getfaly() }} className={styles.aicon} > <img src={Icon[faly].src} /></Button>

            </div>
          </Card>
        </Row>

        <OrderTable tableData={tableData} />
      </>
    )
  }
}
