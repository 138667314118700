import Ajax from '../utils/ajax'

export default {
  // 获取主订单交期预警列表
  getOrderDeliveryWarningList (params) {
    return Ajax.post('/oms/warning/deliveryDays/getOrderGoodsDetail', params)
  },

  // 获取代理证逾期预警列表
  getBrandAuthOverWarningList (params) {
    return Ajax.post('/oms/warning/supplyBrandAuth/listByCloseToOverTime', params)
  },

  // 获取询价单逾期预警列表
  getEnquiryOverWarningList (params) {
    return Ajax.post('/oms/warning/enquiryOrder/listByCloseToOverTime', params)
  },

  // 获取第三方商品同步预警预警列表
  getSyncStopWarningList (params) {
    return Ajax.post('/oms/warning/syncData/listByThirdPartyData', params)
  },

  // 获取相同产品询价预警列表
  getALikeProductEnquiryWarningList (params) {
    return Ajax.post('/oms/warning/enquiryOrder/getSameOrderGoodsDetail', params)
  }
}
