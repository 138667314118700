export const ALLwarging = {
  label: `<h1>1、模板格式规范：</h1>
  <p>500字符以内（含变量）。字符串类型，支持中英文、大小写、字符。不支持【】，会与签名混淆。模板内容只支持这些符号：~!@#$%&*（），。？,。+{}_-=￥……^。</p>
  <h1>2、内容规范：</h1>
  <p>短信模板需明确表述短信发送的实际内容。</p>
  <p>所有模板均禁止发送金融相关的所有内容。</p>
  <p>不支持发送未经许可的信息，主要指邀请注册、邀请成为会员的商业性信息。</p>
  <p>禁止发送涉及：色情、赌博、毒品、党政、法律维权、众筹、慈善募捐、宗教、迷信、股票、留学移民、面试招聘、博彩、贷款、催款还款、信用卡提额、投资理财、中奖、抽奖、一元夺宝、一元秒杀、一元云购、二类电商、A货、烟酒、交友、暴力、恐吓、皮草、返现返利、代开发票、运营商禁止发送的信息、代理注册、代办证件、加群、加QQ或者加微信、贩卖个人信息、运营商业务、流量营销、违反广告法用语、殡葬、刷单、做任务、空包网、邀请好评、转店类、拉新、众包业务、POS机、积分兑换等内容的短信。</p>
  <p>禁止在关键字或关键信息中出现错别字、变体字、异体字、各类干扰符号等；禁止出现各类非正常混合字以及非常用的表达法。</p>
  <p>不支持内容中含有直接或间接访问应用内测分发平台的行为。</p>
  <p>地产、留学、招聘、交友、游戏等行业仅支持发送验证码短信。</p>
  <p>如出现违法违规或者损害到相关他人权益的，平台将保留最终追究的权利！请各会员严格遵守规范要求，加强自身业务安全，发送合规短信。</p>`
}

export const getTitle = [
  { type: 'NOTICE',
    label: `
        <p>1、同一个变量在模板中仅可出现一次。例如不支持以下模板：您家宝贝"$ {name}"已经到达$ {name}现场！。</p>
        <p>2、不支持短链接与变量直接组合的格式。例如：t.cn$ {code}，t.cn为短链接，$ {code}为变量。</p>
        <p>3、个人用户设置变量时，必须设置变量属性。</p>
        <p>4、短信通知模板除公共规范外，另不支持在短信通知中发送营销内容。</p>
      ` },
  { type: 'CAPTCHA',
    label: `
         <p>1、在模板中仅支持一个变量。</p>
         <p>2、验证码模板变量的实际内容仅支持数字或英文字母。</p>
         <p>3、模板内容中必须包含验证码，注册码，校验码或动态码这4个词语其中之一。</p>
         <p>4、模板必须体现和说明使用平台、 用途或验证码失效时间三者之一。</p>
       ` },
  { type: 'BROADCAST',
    label: `
         <p>1、不支持变量。</p>
         <p>2、推广短信除公共规范外，另不支持保险、房地产、教育、培训、游戏、美容、医疗、会所、酒吧、足浴、助考、商标注册、装修、建材、家私、会展、车展、房展、汽车销售、知识产权、整形、招商加盟、POS机、积分兑换等信息的短信。</p>
         <p>3、推广短信模板中，不支持向非会员用户发送营销内容。</p>
         <p>4、推广短信模板结尾处必须说明退订方式。仅支持回复TD、T或N退订短信，回复其他内容无效。</p>
  ` },
  { type: 'OTHER',
    label: `
         <p>1、不支持变量。</p>
         <p>2、推广短信除公共规范外，另不支持保险、房地产、教育、培训、游戏、美容、医疗、会所、酒吧、足浴、助考、商标注册、装修、建材、家私、会展、车展、房展、汽车销售、知识产权、整形、招商加盟、POS机、积分兑换等信息的短信。</p>
         <p>3、推广短信模板中，不支持向非会员用户发送营销内容。</p>
         <p>4、推广短信模板结尾处必须说明退订方式。仅支持回复TD、T或N退订短信，回复其他内容无效。</p>
  ` }
]
