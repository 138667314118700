import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input

class RefuseapplyForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    status: PropTypes.string,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () { }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, status = 'status' } = this.props
        values.id = detailInfo.id
        values[status] = 'AUDIT_FAIL'
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { form, status = 'status' } = this.props
    const { getFieldDecorator } = form

    return (
      <>
        <Modal title='拒绝原因'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem style={{ marginBottom: '0px' }}>
              {
                getFieldDecorator(`${status === 'status' ? 'remark' : 'remarks'}`, {
                  rules: [
                    { required: true, message: '请填写拒绝原因' }
                  ]
                })(
                  <TextArea rows={6} placeholder='请输入拒绝原因' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const RefuseapplyModal = Form.create()(RefuseapplyForm)

export default RefuseapplyModal
