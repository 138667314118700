import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Radio, Empty } from 'antd'

import LogisticsTrace from '@/components/logisticsTrace/index'

import Api from '@/common/api/index'

export default class Logistics extends Component {
  static propTypes = {
    detailInfo: PropTypes.object
  }
  state = {
    showTrace: false,
    selectedCourier: null, // 选中的运单号
    logisticsTraces: [] // 物流轨迹数据
  }

  componentDidMount () {
    const { detailInfo = {} } = this.props
    if (detailInfo.courierNumArray && detailInfo.courierNumArray.length > 0) {
      this.handleSelectedCourierNumber(detailInfo.courierNumArray[0])
    }
  }

  // 获取运单号物流轨迹
  getPurchaseOrderSendOutLogisticsDetail = () => {
    const { detailInfo = {} } = this.props
    const { selectedCourier } = this.state

    let params = {
      ShipperCode: detailInfo.courierCode,
      LogisticCode: selectedCourier,
      CustomerName: detailInfo.receiverMobile ? detailInfo.receiverMobile.substr(-4) : ''
    }

    Api.getPurchaseOrderSendOutLogisticsDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = data.traces || []

        this.setState({
          logisticsTraces: _list.reverse(),
          showTrace: true
        })
      }
    })
  }

  // 点击选择包裹
  handleSelectedCourierNumber = (key) => {
    this.setState({
      selectedCourier: key
    }, () => {
      this.getPurchaseOrderSendOutLogisticsDetail()
    })
  }

  render () {
    const { detailInfo } = this.props
    const { selectedCourier, showTrace = false, logisticsTraces } = this.state
    const { courierNumArray = [] } = detailInfo

    return (
      <div>
        {
          courierNumArray && courierNumArray.length > 0
            ? <>
              <Row>
                <Radio.Group buttonStyle='solid' value={selectedCourier}
                  onChange={(e) => this.handleSelectedCourierNumber(e.target.value)}
                >
                  {
                    courierNumArray.map((cour, n) => (
                      <Radio.Button value={cour} key={`cour-${n}`}>运单号{cour}</Radio.Button>
                    ))
                  }
                </Radio.Group>
              </Row>

              <Row>
                {
                  showTrace
                    ? <LogisticsTrace
                      logisticsTraces={logisticsTraces}
                      courierCode={detailInfo.courierCode}
                    />
                    : null
                }
              </Row>
            </>
            : <>
              <Empty description='暂无运单号信息' />
            </>
        }
      </div>
    )
  }
}
