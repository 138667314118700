import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Button, Icon, message } from 'antd'

import Api from '@/common/api/index'

import FloorModal from './floorModal'
import FloorTpl1 from './floorTpl1'
import FloorTpl2 from './floorTpl2'
import FloorTpl3 from './floorTpl3'
import FloorTpl4 from './floorTpl4'
import FloorTpl5 from './floorTpl5'

class BrandmuseDetail extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    detailInfo: {},
    pageList: [], // 所有页面集合
    navInfo: {},
    floorList: [],
    floorModal: false
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    if (state && state.detailInfo) {
      this.setState({
        detailInfo: state.detailInfo
      }, () => {
        this.getBrandmuseContainerInfo()
      })
    }
  }

  // 获取品牌馆详情
  getBrandmuseContainerInfo = () => {
    const { detailInfo = {} } = this.state
    Api.getBrandmuseContainerInfo({ brandId: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          floorList: data || []
        })
      }
    })
  }

  // 创建页面楼层
  saveAddBrandmuseContainer = (params) => {
    Api.saveAddBrandmuseContainer(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getBrandmuseContainerInfo()
      }
    })
  }

  // 编辑楼层名称
  saveEditBrandmuseContainer = (params) => {
    Api.saveEditBrandmuseContainer(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getBrandmuseContainerInfo()
      }
    })
  }

  // 删除楼层
  deleteBrandmuseContainer = (params) => {
    Api.deleteBrandmuseContainer(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getBrandmuseContainerInfo()
      }
    })
  }

  // 点击确定创建楼层
  handleConfirmCreateFloor = (values) => {
    const { detailInfo = {} } = this.state
    values.brandId = detailInfo.id
    this.saveAddBrandmuseContainer(values)
  }

  // 点击创建楼层
  handleCreateFloor = () => {
    this.setState({
      floorModal: true
    })
  }

  closeModal = () => {
    this.setState({
      floorModal: false
    })
  }

  render () {
    const { floorModal = false, floorList = [] } = this.state

    return (
      <div>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row>
            {
              floorList.length > 0
                ? <div>
                  {
                    floorList.map((item, f) => (
                      <Row key={`floor-${f}`}>
                        {
                          +item.type === 1
                            ? <Row style={{ marginBottom: '16px' }}>
                              <FloorTpl1
                                floorInfo={item}
                                onRefush={() => this.getBrandmuseContainerInfo()}
                                onDelete={(params) => this.deleteBrandmuseContainer(params)}
                              />
                            </Row>
                            : null
                        }

                        {
                          +item.type === 2
                            ? <Row style={{ marginBottom: '16px' }}>
                              <FloorTpl2
                                floorInfo={item}
                                onRefush={() => this.getBrandmuseContainerInfo()}
                                onDelete={(params) => this.deleteBrandmuseContainer(params)}
                              />
                            </Row>
                            : null
                        }

                        {
                          +item.type === 3
                            ? <Row style={{ marginBottom: '16px' }}>
                              <FloorTpl3
                                floorInfo={item}
                                onRefush={() => this.getBrandmuseContainerInfo()}
                                onDelete={(params) => this.deleteBrandmuseContainer(params)}
                              />
                            </Row>
                            : null
                        }

                        {
                          +item.type === 4
                            ? <Row style={{ marginBottom: '16px' }}>
                              <FloorTpl4
                                floorInfo={item}
                                onRefush={() => this.getBrandmuseContainerInfo()}
                                onDelete={(params) => this.deleteBrandmuseContainer(params)}
                              />
                            </Row>
                            : null
                        }

                        {
                          +item.type === 5
                            ? <Row style={{ marginBottom: '16px' }}>
                              <FloorTpl5
                                floorInfo={item}
                                onRefush={() => this.getBrandmuseContainerInfo()}
                                onDelete={(params) => this.deleteBrandmuseContainer(params)}
                              />
                            </Row>
                            : null
                        }
                      </Row>
                    ))
                  }
                </div>
                : <div style={{ padding: '30px 0px', textAlign: 'center', color: '#666666' }}>
                  暂无页面内容信息，您可以点击下方 创建楼层 来编辑你要装饰的内容
                </div>
            }
          </Row>

          <Row>
            <Button type='dashed' block
              onClick={() => this.handleCreateFloor(1, {})}
            >
              <Icon type='plus' />创建楼层
            </Button>
          </Row>
        </Card>

        {
          floorModal
            ? <FloorModal
              onConfirm={(values) => this.handleConfirmCreateFloor(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}

export default BrandmuseDetail
