import Ajax from '../utils/ajax'

export default {
  // 获取云供应订单列表
  getSupplyOrderList (params) {
    return Ajax.post('/platform/supplyOrder/list', params)
  },

  // 订单列表添加备注
  getRemarks (params) {
    return Ajax.post('/platform/supplyOrder/addRemarks', params)
  },
  // 获取云供应取消订单
  getsupplyOrderOrdercancel (params) {
    return Ajax.post('/platform/supplyOrder/cancel', params)
  },

  // 获取云供应订单详情
  getSupplyOrderDetail (params) {
    return Ajax.post('/platform/supplyOrder/detail', params)
  },

  // 获取云供应订单产品
  getSupplyOrderGoodsList (params) {
    return Ajax.post('/platform/supplyOrder/goodsList', params)
  },

  // 取消云供应订单
  saveCancelSupplyOrder (params) {
    return Ajax.post('/platform/supplyOrder/cancel', params)
  },

  // 云供应订单发货
  submitSupplyOrderVerifySendOut (params) {
    return Ajax.post('/cloud/supplyOrder/supplyOrderVerifySendOut', params)
  },

  /* 云采购订单 */
  // 获取云采购订单列表
  getPurchOrderList (params) {
    return Ajax.post('/platform/purchaseOrder/list', params)
  },

  // 获取云采购订单详情
  getPurchOrderDetail (params) {
    return Ajax.post('/platform/purchaseOrder/purchaseOrderInfo', params)
  },

  // 获取云采购订单产品
  getPurchOrderGoodsList (params) {
    return Ajax.post('/platform/purchaseOrder/goodsList', params)
  },

  // 获取云采购订单运单号详情
  getPurchOrderMailDetail (params) {
    return Ajax.post('/cloud/supplyOrder/logisticsInfo', params)
  },

  // 采购订单-发货批次查询
  getPurchaseOrderSendOutBatchList (params) {
    return Ajax.get('/cloud/purchaseOrder/getCourierInfoBySplit', { params })
  },

  // 采购订单-批次发货明细查询
  getPurchaseOrderSendOutBatchDetail (params) {
    return Ajax.get('/cloud/purchaseOrder/getCourierSkuInfoBySplit', { params })
  },

  // 采购订单-物流轨迹查询
  getPurchaseOrderSendOutLogisticsDetail (params) {
    return Ajax.get('/cloud/purchaseOrder/getLogisticsDetail', { params })
  },

  // 获取待分配的采购订单列表
  getDispensePurchOrderList (params) {
    return Ajax.post('/platform/cloudOrder/task/listPage', params)
  },

  // 待采购订单列表取消
  getcloudOrdercancel (params) {
    return Ajax.post('/platform/cloudOrder/task/cancel', params)
  },

  // 获取待分配的采购订单详情
  getDispensePurchProductDetail (params) {
    return Ajax.get('/platform/cloudOrder/task/detail', { params })
  },

  // 分配采购单
  dispensePurchProduct (params) {
    return Ajax.post('/platform/cloudOrder/task/allocate', params)
  },

  // 获取采购订单合同下载链接
  getPurchaseTradeContractDownFile (params) {
    return Ajax.post('/platform/contract/purchase/downloadFile', params)
  },

  /* 手工订单 */
  // 手工订单-获取商品信息
  getOrderProductByHandle (params) {
    return Ajax.post('/cloud/purchaseOrder/queryOrderGoodsByHand', params)
  },

  // 手工订单- 提交下单
  submitPurchOrederByHandle (params) {
    return Ajax.post('/cloud/purchaseOrder/purchaseOrderCreatedByHand', params)
  },

  /* EU订单 */
  // 获取EU客户订单列表
  getEuOrderListByEndUserId (params) {
    return Ajax.post('/platform/endUser/orderList', params)
  },

  // 获取EU订单-子订单列表
  getEUTradeChildOrderList (params) {
    return Ajax.post('/platform/finance/euOrderDetailList', params)
  },

  // 获取EU子订单明细
  getEuOrderDetail (params) {
    return Ajax.post('/platform/endUser/orderGoodsList', params)
  },

  // 财务模块-获取全部EU客户订单列表
  getEutradeOrderList (params) {
    return Ajax.post('/platform/finance/euOrderList', params)
  },

  /* 其他 */
  // 根据订单ID获取客户收货地址
  getReceiveAddressByOrderId (params) {
    return Ajax.post('/cloud/supplyOrder/orderGoodsAddressInfo', params)
  },

  // 获取云端交易详情
  getCloudTradeDetailList (params) {
    return Ajax.post('/platform/finance/cloudTradeInfoBySupplyOrder', params)
  },

  // 获取商品供应明细
  getSupplyDetailInOrderSku (params) {
    return Ajax.post('/platform/finance/cloudSupplyOrderPurchaseOrderGoodsDetail', params)
  },
  // 订单列表确认收款
  getsupplyOrderConfirmPaid (params) {
    return Ajax.post('/platform/finance/supplyOrderConfirmPaid', params)
  },

  // 订单列表- 确认收货
  saveConfirmSupplyOrderTakeDelivery (params) {
    return Ajax.post('/platform/supplyOrder/confirm', params)
  },

  // 报价单- 生成订单
  submitQuotationCreategenerateOrder (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/generateOrder', params)
  },

  // 查看订单付款凭证信息
  getCloudOrderPayVoucherDetails (params) {
    return Ajax.post('/platform/purchaseOrder/paidHistory', params)
  }

}
