import Ajax from '../utils/ajax'

export default {
  // 获取统计部门列表
  getPurposeDepartmentList (params) {
    return Ajax.get('/statistics/department/member/listDepartmentByAll', { params })
  },

  // 获取统计- 员工列表
  getPurposeMemberList (params) {
    return Ajax.post('/statistics/department/member/listPageByStatisticsMember', params)
  },

  // 获取统计业务指标详情
  getPurposeTargetDetails (params) {
    return Ajax.get('/statistics/department/member/getTargetMap', { params })
  },

  // 保存统计部门分配
  savePurposeSetToDepartment (params) {
    return Ajax.post('/statistics/department/member/allocateDepart', params)
  },

  // 保存统计指标调整
  saveEditPurposeTarget (params) {
    return Ajax.post('/statistics/department/member/targetEdit', params)
  }
}
