import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Tabs, Button, Icon } from 'antd'

import BanklinkTable from '../component/banklink-table'
import AlipaySet from '../component/alipaySet' // 支付宝配置

import Api from '@/common/api/index'

const { TabPane } = Tabs
const tabMaps = [
  { label: '记账宝支付', code: 6 },
  { label: '支付宝支付', code: 3 }
]

export default class SetPay extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    tableData: [],
    info: {},
    selectedkey: 0
  }

  componentDidMount () {
    const { location } = this.props.history
    const { state = {} } = location

    this.setState({
      info: state
    }, () => {
      this.handleSelectedPayItem(6)
    })
  }

  // 获取商户记账宝账号列表
  getBankLinkageSettings = (params) => {
    return Api.getBankLinkageSettings(params)
  }

  // 保存记账宝账号
  saveSetbanklink = (params) => {
    return Api.saveSetbanklink(params)
  }

  // 查询前置机列表
  getBankLinkageProcessorList = (params) => {
    return Api.getBankLinkageProcessorList(params)
  }

  // 点击选择配置支付方式
  handleSelectedPayItem = (key) => {
    this.setState({
      selectedkey: +key
    })
  }

  // 返回列表
  handleJumpToLink = () => {
    this.props.history.push('/admin/customerList')
  }

  render () {
    const { info, selectedkey = 0 } = this.state

    return (
      <>
        <Card title={`支付方式配置`}>
          <Row style={{ marginBottom: '20px' }}>
            <Button onClick={this.handleJumpToLink}>
              <Icon type='left' />返回客户列表
            </Button>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <div style={{ fontSize: '16px', fontWeight: '700' }}>
              {info.merchantName}
            </div>
            <div>{info.domainAddress}</div>
          </Row>
          <Row>
            <Tabs onChange={this.handleSelectedPayItem}>
              {
                tabMaps.map((item, i) => (
                  <TabPane tab={item.label} key={item.code} />
                ))
              }
            </Tabs>
          </Row>
          <Row>
            {
              +info.distributorId > 0 && selectedkey === 6
                ? <BanklinkTable
                  info={info}
                  saveSetbanklink={(params) => this.saveSetbanklink(params)}
                  getBankLinkageProcessorList={(params) => this.getBankLinkageProcessorList(params)}
                  getBanklinkList={(params) => this.getBankLinkageSettings(params)}
                />
                : null
            }

            {
              selectedkey === 3
                ? <AlipaySet
                  detailInfo={info}
                />
                : null
            }

          </Row>
        </Card>
      </>
    )
  }
}
