import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Row, Switch, message, Col, Modal } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import InsurancetypeModal from './insurancetypeModal'
import InsuranceModal from './insuranceModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

import styles from './commont.module.scss'

const { confirm } = Modal
const { pageSizeOptions, insuranceTypeMaps } = filter

class CustomerService extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }

  state = {
    nowTime: moment().format('YYYY-MM-DD'), // 当前日期时间戳
    insurancetypeModal: false, // 更改服务类型模拟框辨识
    insuranceModal: false, // 调整服务模拟框辨识
    btnModal: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '启用状态'
        },
        cptParams: {
          placeholder: '请选择状态...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLE', name: '启用' },
          { id: 'DISABLE', name: '禁用' }
        ]
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '服务类型'
        },
        cptParams: {
          placeholder: '请选择服务类型...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...insuranceTypeMaps
        ]
      }
    ],
    query: {}, // 检索条件
    tableData: [],
    editingKey: '', // 快递费率编辑中
    editingKey1: '', // 服务费率编辑中
    rowDetail: {} // 当前选择的行数据

  }

  columns = [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户编号',
      key: 'service-1',
      dataIndex: 'distributorId'
    },
    {
      title: '公司名称',
      key: 'service-2',
      dataIndex: 'distributorName'
    },
    {
      title: 'IIASaaS服务(保险)',
      key: 'service-3',
      width: 160,
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            {
              PATH_ENV === 'prod' && (+record.distributorId === 100005 || +record.distributorId === 100006)
                ? <div>IIASaaS禁用保险</div>
                : <div>
                  {
                    buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1
                      ? <Switch
                        checked={record.status === 'ENABLE'}
                        checkedChildren='启用'
                        unCheckedChildren='禁用'
                        onChange={(checked) => this.handleChangeSwitch(checked, record)}
                      />
                      : <div>
                        {
                          record.status === 'ENABLE' ? '启用' : '禁用'
                        }
                      </div>
                  }
                </div>
            }
          </div>
        )
      }
    },
    {
      title: '服务类型',
      key: 'service-4',
      dataIndex: 'distributorName',
      render: (text, record, index) => (
        <div>
          {
            record.type === 'COMMON' ? '普通' : '年费'
          }
        </div>
      )
    },
    {
      title: '服务到期日期',
      key: 'service-5',
      render: (text, record, index) => (
        <div>
          {moment(record.endTime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      )
    },
    {
      title: '费率(‰)',
      key: 'service-6',
      dataIndex: 'rate',
      render: (text, record, index) => (
        <>{((+record.rate) * 1000).toFixed(2)}</>
      )
    },
    {
      title: '已保货物金额',
      key: 'service-7',
      dataIndex: 'useAmount',
      render: (text, record, index) => (
        <>{(+record.useAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '保险货物金额上限',
      key: 'service-8',
      dataIndex: 'totalAmount'
    },
    {
      title: '操作',
      key: 'service-9',
      width: 260,
      render: (text, record, index) => {
        return (
          <div >
            <a onClick={() => { this.showInsuranceInfoModal(record) }}> 调整服务内容</a>
            <a style={{ marginLeft: '20px' }} onClick={() => { this.showInsuranceTypeModal(record) }}>更改服务类型</a>
          </div>

        )
      }
    }
  ]

  componentDidMount () {
    this.getCustomerListInsurance()
  }

  // 获取使用保险服务的客户列表
  getCustomerListInsurance = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getCustomerListInsurance(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state
        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)
        this.setState({
          searchData
        })
      }
    })
  }

  // 服务类型弹框
  showInsuranceTypeModal = (record) => {
    this.setState({
      insurancetypeModal: true,
      rowDetail: record
    })
  }

  // 调整服务内容弹出
  showInsuranceInfoModal = (record) => {
    this.setState({
      insuranceModal: true,
      rowDetail: record
    })
  }

  // 更改服务类型
  updateCustomerInsuranceType = (params) => {
    Api.updateCustomerInsuranceType(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getCustomerListInsurance()
      }
    })
  }

  // 调整服务内容
  updateCustomerInsuranceInfo = (params) => {
    Api.updateCustomerInsuranceInfo(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getCustomerListInsurance()
      }
    })
  }

  // IIASaas服务启用/禁用
  updateServiceStatus = (params) => {
    Api.getCustomerInsuranceStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getCustomerListInsurance()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getCustomerListInsurance()
    })
  }

  // 切换启用禁用状态
  handleChangeSwitch = (checked, record) => {
    let params = {
      status: checked ? 'ENABLE' : 'DISABLE',
      distributorId: record.distributorId
    }
    if (!checked) {
      confirm({
        title: '确认要关闭IIASaaS保险服务？',
        onOk: () => {
          this.updateServiceStatus(params)
        }
      })
    } else {
      this.updateServiceStatus(params)
    }
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      insuranceModal: false,
      insurancetypeModal: false,
      btnModal: false,
      rowDetail: {},
      btnstatus: ''
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCustomerListInsurance()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCustomerListInsurance()
    })
  }

  // 设置行属性
  rowClassName=(record, index) => {
    const { nowTime } = this.state
    let classname = ''
    let _end = moment(record.endTime).format('YYYY-MM-DD')
    let result = moment(_end).diff(nowTime, 'days')

    if (result <= 7) {
      classname = styles.redBackground
    } else if (result <= 30) {
      classname = styles.yellowBackground
    }
    return classname
  }

  render () {
    const { tableData = [], pages, searchData, insurancetypeModal = false, insuranceModal = false, rowDetail = {} } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Col span={16}>
                <div className={styles.div} style={{ float: 'left' }}>
                  <label className={styles.labelPingan}>临近7天到期</label> <span className={`${styles.colorBlock} ${styles.redColor}`} />
                  <label className={styles.labelPingan}>临近30天到期</label> <span className={`${styles.colorBlock} ${styles.yellowColor}`} />
                </div>
              </Col>
              <Col span={8} />
            </Row>
            <Row>
              <Table rowKey='id' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
                rowClassName={this.rowClassName}
              />
            </Row>
          </Card>
        </Row>

        {
          insurancetypeModal
            ? <InsurancetypeModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.updateCustomerInsuranceType(values)} />
            : null
        }

        {
          insuranceModal
            ? <InsuranceModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.updateCustomerInsuranceInfo(values)}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}
export default connect(mapStateToProps)(CustomerService)
