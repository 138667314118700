import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Form, Button, Input, message, Alert, Icon, Checkbox } from 'antd'

import Api from '@/common/api/index'

import styles from '@/pages/system/menu-setting/menuset.module.scss'

const FormItem = Form.Item
const { TextArea } = Input

class AuthorityForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    detailInfo: {}, // 部门信息
    type: 1, // 1-新增 2-编辑
    info: {},
    pageShow: false,
    selectedMenuKeys: [],
    menuList: []
  }

  componentDidMount () {
    const { location } = this.props.history
    const { state } = location

    if (!state || !state.detailInfo) {
      return message.warning('参数不合法')
    }

    this.setState({
      detailInfo: state.detailInfo || {},
      type: state.type || 1
    }, () => {
      if (+state.type === 2) {
        this.getDepartRoleDetail()
      } else {
        this.getAllMenuList()
      }
    })
  }

  // 获取所有菜单
  getAllMenuList = () => {
    Api.getAllMenuList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          menuList: data || [],
          pageShow: true
        })
      }
    })
  }

  // 获取角色详情
  getDepartRoleDetail = () => {
    const { detailInfo = {} } = this.state
    let params = {
      id: detailInfo.roleId
    }

    Api.getDepartRoleDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {},
          menuList: data.menuTreeVo || [],
          pageShow: true
        })
      }
    })
  }

  // 保存编辑角色
  saveEditDepartRole = (params) => {
    Api.saveEditDepartRole(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.props.history.push({ pathname: '/admin/departList' })
      }
    })
  }

  // 保存新增角色
  saveAddDepartRole = (params) => {
    Api.saveAddDepartRole(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.props.history.push({ pathname: '/admin/departList' })
      }
    })
  }

  // 点击保存
  handleSave = () => {
    const { detailInfo = {}, info = {}, type = 1, menuList = [] } = this.state

    let _menuIds = []

    menuList.map((item, i) => {
      if (item.menuList && item.menuList.length > 0) {
        let j = item.menuList.findIndex(it => {
          return it.auth
        })
        if (j > -1) {
          _menuIds.push(item.menuId)
        }

        item.menuList.map((menu, idx) => {
          if (menu.auth) {
            _menuIds.push(menu.menuId)

            if (menu.buttonList && menu.buttonList.length > 0) {
              menu.buttonList.map((btn, ind) => {
                if (btn.auth) {
                  _menuIds.push(btn.menuId)
                }
              })
            }
          }
        })
      }
    })

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (_menuIds.length === 0) {
          return message.warning('请为该角色分配权限！')
        }

        let params = {
          ...values,
          departId: detailInfo.departId,
          menuIds: _menuIds
        }

        if (type === 2) {
          params.id = info.roleId
          this.saveEditDepartRole(params)
        } else {
          this.saveAddDepartRole(params)
        }
      }
    })
  }

  // 点击返回
  handleBack = () => {
    this.props.history.goBack()
  }

  onSelectedMenuKeys = (keys = []) => {
    this.setState({
      selectedMenuKeys: keys
    })
  }

  // 点击展开、收起目录
  handleCollapseOpen = (i) => {
    let { menuList = [] } = this.state

    menuList[i].isOpen = !menuList[i].isOpen

    this.setState({
      menuList
    })
  }

  // 点击子集展开、收起
  handleChildCollapseOpen = (i, idx) => {
    let { menuList = [] } = this.state

    menuList[i].menuList[idx].isOpen = !menuList[i].menuList[idx].isOpen

    this.setState({
      menuList
    })
  }

  // 点击选择菜单
  onChangeMenubox = (val, record, cataIndex) => {
    let { menuList = [] } = this.state
    let _menuChild = menuList[cataIndex].menuList

    let i = _menuChild.findIndex(item => {
      return +item.menuId === +record.menuId
    })

    if (i > -1) {
      _menuChild[i].auth = val ? 1 : 0
      menuList[cataIndex].menuList = _menuChild
    }

    this.setState({
      menuList
    })
  }

  // 点击选择按钮
  onChangeBtnbox = (val, record, cataIndex, menuIndex) => {
    let { menuList = [] } = this.state
    let _btnChild = menuList[cataIndex].menuList[menuIndex].buttonList

    let n = _btnChild.findIndex(item => {
      return +item.menuId === +record.menuId
    })

    if (n > -1) {
      _btnChild[n].auth = val ? 1 : 0
      menuList[cataIndex].buttonList = _btnChild
    }

    this.setState({
      menuList
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo = {}, info = {}, menuList = [], pageShow = false } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Button icon='left'
              onClick={() => this.handleBack()}
            >
              返回列表
            </Button>
          </Row>

          <Row>
            <Col span={4}>
              <Row style={{ marginBottom: '10px' }}>
                <Alert type='success' message={`部门：${detailInfo.departName}`} />
              </Row>
              <Form>
                <FormItem label='角色名称'>
                  {
                    getFieldDecorator('roleName', {
                      initialValue: info.roleName || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off'

                      />
                    )
                  }
                </FormItem>
                <FormItem label='角色描述'>
                  {
                    getFieldDecorator('description', {
                      initialValue: info.description || ''

                    })(
                      <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Form>
            </Col>

            <Col span={20} style={{ paddingLeft: '32px' }}>
              <div style={{ marginBottom: '10px' }}>
                <Alert type='error' message='角色权限设置' />
              </div>
              {
                pageShow
                  ? <div>
                    {
                      menuList && menuList.length > 0
                        ? <ul className={`${styles.menuTree}`}>
                          {
                            menuList.map((item, i) => (
                              <li className={`${styles.treeNode} ${styles.inline}`}
                                key={`${item.parentId}-${item.menuId}`}
                              >
                                <div>
                                  <span className={`${styles.treeSwitcher}`}
                                    onClick={() => this.handleCollapseOpen(i)}
                                  >
                                    {
                                      !item.isOpen
                                        ? <Icon type='caret-right'
                                          style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)' }}
                                        />
                                        : <Icon type='caret-down'
                                          style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)' }}
                                        />
                                    }

                                  </span>

                                  {/* <span className={`${styles.treeIndeter}`} /> */}

                                  <span className={`${styles.treeCon}`}>
                                    <span>{item.name}</span>
                                  </span>
                                </div>

                                {
                                  <ul className={`${styles.menuChildtree} ${item.isOpen ? styles.opend : styles.none}`}>
                                    {
                                      item.menuList && item.menuList.length > 0 && item.menuList.map((menu, idx) => (
                                        <li className={`${styles.treeNode}`}
                                          key={`${menu.parentId}-${menu.menuId}`}
                                        >
                                          <div>
                                            <span className={`${styles.treeSwitcher}`}
                                              onClick={() => this.handleChildCollapseOpen(i, idx)}
                                            >
                                              {
                                                !menu.isOpen
                                                  ? <Icon type='caret-right'
                                                    style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)' }}
                                                  />
                                                  : <Icon type='caret-down'
                                                    style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)' }}
                                                  />
                                              }
                                            </span>

                                            <span className={`${styles.treeIndeter}`}>
                                              <Checkbox
                                                checked={+menu.auth === 1}
                                                onChange={(e) => this.onChangeMenubox(e.target.checked, menu, i)}
                                              />
                                            </span>

                                            <span className={`${styles.treeCon}`}>
                                              <span>{menu.name}</span>
                                            </span>
                                          </div>

                                          <ul className={`${styles.menuChildtree} ${menu.isOpen ? styles.opend : styles.none}`}>
                                            {
                                              menu.buttonList && menu.buttonList.map((btn, bix) => (
                                                <li className={`${styles.treeNode}`}
                                                  key={`${menu.parentId}-${btn.menuId}`}
                                                >
                                                  <div>
                                                    <span className={`${styles.treeSwitcher}`} />

                                                    <span className={`${styles.treeIndeter}`}>
                                                      <Checkbox
                                                        checked={+btn.auth === 1}
                                                        onChange={(e) => this.onChangeBtnbox(e.target.checked, btn, i, idx)}
                                                      />
                                                    </span>

                                                    <span className={`${styles.treeCon}`}>
                                                      <span>{btn.name}</span>
                                                    </span>
                                                  </div>
                                                </li>
                                              ))
                                            }
                                          </ul>
                                        </li>
                                      ))
                                    }
                                  </ul>
                                }
                              </li>
                            ))
                          }
                        </ul>
                        : null
                    }
                  </div>
                  : null
              }

            </Col>
          </Row>

          <Row style={{ textAlign: 'center' }}>
            <Button type='primary'
              onClick={() => this.handleSave()}>保存</Button>
          </Row>
        </Card>
      </>
    )
  }
}

const Authority = Form.create()(AuthorityForm)

export default Authority
