import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, message, Popconfirm, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import OrderSendModal from './orderSendModal'
import PaymoenyModal from './paymoeny-modal'// 确认付款
import RemarksModal from './remarks-modal' // 备注
import OrderDetailsDrawer from '../component/orderDetailsDrawer/index'
import PayBankDownFormModal from '../component/payBankDownModal/index' // 支付信息下载弹窗
import OrderEnclosureDrawer from '../component/orderEnclosureDrawer' // 订单附件弹窗

import Api from '@/common/api'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderOrderStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, orderStatusMaps } = filter
const { MAX_PAGESIZE } = consts

class TradeOrder extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '采购商'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '下单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      },
      {
        name: 'remarks',
        formType: 'Input',
        itemParams: {
          label: '备注'
        },
        cptParams: {

        }
      },
      {
        name: 'belongUserId',
        formType: 'Select',
        itemParams: {
          label: '运营负责人'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 5)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    ztableDat: [],
    detailModal: false,
    orderSendModal: false, // 确认发货弹窗
    payBankDownModal: false, // 支付信息下载弹窗
    enclosureModal: false, // 订单附件弹窗
    rowDetail: {},
    paymoeny: false,
    remarks: false
  }

  columns = [
    {
      title: '序号',
      key: 'tradeOrder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单编号',
      width: 120,
      key: 'tradeOrder-1',
      dataIndex: 'orderId'
    },
    {
      title: '订单日期',
      key: 'tradeOrder-2',
      width: 140,
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '采购商',
      key: 'tradeOrder-3',
      width: 150,
      ellipsis: true,
      dataIndex: 'merchantName'
    },
    {
      title: '运营负责人',
      key: 'tradeOrder-4',
      ellipsis: true,
      dataIndex: 'belongUserName'
    },
    {
      title: '支付信息',
      key: 'tradeOrder-payBankDetails',
      render: (text, record, index) => (
        <><a onClick={() => this.handleShowPayBankDownModal(record)}>点击下载</a></>
      )
    },
    {
      title: '订单金额',
      key: 'tradeOrder-5',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '应付金额',
      key: 'tradeOrder-6',
      dataIndex: 'receivableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.receivableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'tradeOrder-7',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'tradeOrder-8',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '备注',
      key: 'tradeOrder-9',
      with: 100,
      ellipsis: true,
      dataIndex: 'remarks'
    },
    {
      title: '订单附件',
      key: 'tradeOrder-10',
      with: 80,
      render: (text, record, index) => (
        <>
          <a onClick={() => this.showOrderEnclosureModal(record)}>点击查看</a>
        </>
      )
    },
    {
      title: '订单状态',
      key: 'tradeOrder-11',
      width: 100,
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'tradeOrder-12',
      width: 240,
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            <Button type='link' size='small' onClick={() => this.showOrderDetail(record)}>查看明细</Button>

            {
              buttonMaps && buttonMaps['confirmCollect'] && +buttonMaps['confirmCollect'].auth === 1 && (['PAYING', 'TAIL_PAYING'].includes(record.orderStatus))
                ? <Button type='link' size='small' onClick={() => this.handlePaymoeny(record)}>确认收款</Button>
                : null
            }

            {
              buttonMaps && buttonMaps['cancelorder'] && +buttonMaps['cancelorder'].auth === 1 && record.orderStatus === 'PAYING'
                ? <Popconfirm title='确定取消?' onConfirm={() => this.getsupplyOrderOrdercancel(record.orderId)}>
                  <Button type='link' size='small'>取消订单</Button>
                </Popconfirm>
                : null
            }

            {
              buttonMaps && buttonMaps['downloadContract'] && +buttonMaps['downloadContract'].auth === 1
                ? <>
                  <Button type='link' size='small' onClick={() => this.handleDownloadContract(record)}>下载合同</Button>

                  <Button type='link' size='small' onClick={() => this.handleCreateContractAgain(record)}>重新生成合同</Button>
                </>
                : null
            }

            {
              record.orderStatus === 'WAIT_TO_RECEIVE'
                ? <Button type='link' size='small' onClick={() => this.handleTakeDelivery(record)}>确认收货</Button>
                : null
            }

            <Button type='link' size='small' onClick={() => this.handleDownloadPickingOrder(record)}>下载拣货单</Button>

            <Button type='link' size='small' onClick={() => { this.getRemarks(record) }}>备注</Button>
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getSupplyOrderList()
  }

  // 获取全部成员
  getMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[searchIndex].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []

        this.setState({
          searchData
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        const { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 添加备注
  getRemarks = (record) => {
    this.setState({
      rowDetail: record,
      remarks: true
    })
  }

  // 确认添加备注
  getaddRemarks = (param) => {
    Api.getRemarks(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('添加成功')
        this.closeModal()
        this.getSupplyOrderList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 获取订单列表
  getSupplyOrderList = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSupplyOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 子订单列表信息查询
  getPurchOrderList = async (orderId) => {
    const res = await Api.getPurchOrderList({ orderId: orderId, pageNo: 1, pageSize: 999 })
    const { code, data } = res
    if (+code === 10000) {
      const { tableData = [] } = this.state
      const i = tableData.findIndex((o) => { return o.orderId === orderId })

      if (i === -1) { return false }

      tableData[i]['rowTable'] = data.list || []

      this.setState({
        tableData: [...tableData]
      })
    }
  }

  // 取消订单
  getsupplyOrderOrdercancel = (orderId) => {
    Api.getsupplyOrderOrdercancel({ orderId: orderId }).then((res) => {
      const { code } = res
      if (+code === 10000) {
        message.success('取消成功')
        this.getSupplyOrderList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 获取合同下载链接
  getSupplyOrderContractDownloadLink = async (params) => {
    const res = await Api.getSupplyOrderContractDownloadLink(params)
    const { code, data } = res
    if (+code === 10000) {
      if (!data || data === '') {
        return message.warning('暂无可下载的合同链接')
      }

      window.open(data)
    }
  }

  // 重新生成合同
  createSupplyOrderContractAgain = async (params) => {
    const res = await Api.createSupplyOrderContractAgain(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.getSupplyOrderList()
    }
  }

  // 获取订单产品
  getSupplyOrderGoodsList = (params) => {
    return Api.getSupplyOrderGoodsList(params)
  }

  // 供应订单发货
  submitSupplyOrderVerifySendOut = (params) => {
    Api.submitSupplyOrderVerifySendOut(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getSupplyOrderList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 确认收款
  getsupplyOrderConfirmPaid = (params) => {
    Api.getsupplyOrderConfirmPaid(params).then((res) => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getSupplyOrderList()
      } else {
        message.error(res.messages)
      }
    })
  }

  // 确认收货
  saveConfirmSupplyOrderTakeDelivery = async (params) => {
    const res = await Api.saveConfirmSupplyOrderTakeDelivery(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.getSupplyOrderList()
    } else {
      message.error(res.messages)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime && search.orderTime.length === 2) {
      let orderTime = search.orderTime
      search.beginDate = orderTime[0].format('YYYY-MM-DD')
      search.endDate = orderTime[1].format('YYYY-MM-DD')

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getSupplyOrderList()
    })
  }

  // 点击查看明细
  showOrderDetail = (record = {}) => {
    this.setState({
      detailModal: true,
      rowDetail: record
    })
  }

  // 打开确认收款弹框
  handlePaymoeny=(record) => {
    this.setState({
      paymoeny: true,
      rowDetail: record
    })
  }

  // 点击确认收货
  handleTakeDelivery = (record) => {
    Modal.confirm({
      title: '是否确认收货?',
      content: <>此操作会将订单状态扭转为订单完成</>,
      onOk: () => {
        this.saveConfirmSupplyOrderTakeDelivery({ orderIdList: [record.orderId] })
      }
    })
  }

  // 点击下载支付信息
  handleShowPayBankDownModal = (record) => {
    this.setState({
      rowDetail: record,
      payBankDownModal: true
    })
  }

  // 下载支付信息
  downloadPayBankDetail = (params, type) => {
    if (type === 'PDF') {
      DownloadApi.downloadPaymentInfoByPdf(params)
    }

    if (type === 'WORD') {
      DownloadApi.downloadPaymentInfoByWord(params)
    }
  }

  // 点击下载合同
  handleDownloadContract = (record) => {
    this.getSupplyOrderContractDownloadLink({ distributorId: record.distributorId, orderId: record.orderId })
  }

  // 点击重新生成合同
  handleCreateContractAgain = (record) => {
    Modal.confirm({
      title: '确定重新生成合同？',
      content: '此操作会根据订单数据和公司基础数据重新生成合同',
      onOk: () => {
        this.createSupplyOrderContractAgain({ distributorId: record.distributorId, orderId: record.orderId })
      }
    })
  }

  // 点击查看订单附件
  showOrderEnclosureModal = (record) => {
    this.setState({
      rowDetail: record,
      enclosureModal: true
    })
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    if (!expanded) { return false }

    this.getPurchOrderList(record.orderId)
  }

  // 点击确认发货
  showLogisticsModal = (record = {}) => {
    this.setState({
      orderSendModal: true,
      rowDetail: record
    })
  }

  // 点击下载拣货单
  handleDownloadPickingOrder = (record) => {
    DownloadApi.downloadSupplyOrderPickingOrder({ orderId: record.orderId })
  }

  // 渲染订单详情
  renderExpandRow = (row = {}) => {
    const { rowTable = [] } = row
    const rowColumns = [
      {
        title: '子订单号',
        key: `expandRow-${row.purchaseOrderId}-0`,
        dataIndex: 'purchaseOrderId'
      },
      {
        title: '订单时间',
        key: `expandRow-${row.orderId}-1`,
        render: (text, record, index) => (
          <>
            { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
          </>
        )
      },
      {
        title: '订单金额',
        key: `expandRow-${row.orderId}-2`,
        dataIndex: 'payableAmount',
        render: (text, record, index) => (
          <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
        )
      },
      {
        title: '订单状态',
        key: `expandRow-${row.orderId}-3`,
        dataIndex: 'orderStatus',
        render: (text, record, index) => (
          <>{ renderOrderStatusLabel(record.orderStatus) }</>

        )
      },
      {
        title: '操作',
        key: `expandRow-${row.orderId}-4`,
        render: (text, record, index) => {
          const { buttonMaps = {} } = this.props

          return (
            <div>
              {
                buttonMaps && buttonMaps['sendout'] && +buttonMaps['sendout'].auth === 1 && !['WAIT_TO_RECEIVE', 'ORDER_COMPLETE'].includes(record.orderStatus)
                  ? <a onClick={() => this.showLogisticsModal(record)}>确认发货</a>
                  : null
              }
            </div>
          )
        }
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey='purchaseOrderId'
          columns={rowColumns}
          dataSource={rowTable}
          pagination={false}
        />
      </Row>
    )
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      orderSendModal: false,
      payBankDownModal: false,
      enclosureModal: false,
      paymoeny: false,
      remarks: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyOrderList()
    })
  }

  render () {
    const { searchData, remarks = false, tableData, pages, detailModal = false, orderSendModal = false, payBankDownModal = false, enclosureModal = false,
      rowDetail = {}, paymoeny = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='orderId' bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
            onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
            expandedRowRender={(record) => this.renderExpandRow(record)}
          />
        </Card>

        <OrderDetailsDrawer visible={detailModal}
          ident={'SUPPLY_ORDER'}
          detailInfo={rowDetail}
          getProductDetail={(values) => this.getSupplyOrderGoodsList(values)}
          onCancel={() => this.closeModal()}
        />

        {
          orderSendModal
            ? <OrderSendModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.submitSupplyOrderVerifySendOut(params)}
            />
            : null
        }

        {/* 确认收款 */}
        {
          paymoeny
            ? <PaymoenyModal
              onCancel={this.closeModal}
              detailInfo={rowDetail}
              onConfirm={(params) => this.getsupplyOrderConfirmPaid(params)}
            />
            : null
        }

        {
          remarks
            ? <RemarksModal
              onCancel={this.closeModal}
              detailInfo={rowDetail}
              onConfirm={(params) => this.getaddRemarks(params)}
            />
            : null
        }

        {
          payBankDownModal
            ? <PayBankDownFormModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(params, type) => this.downloadPayBankDetail(params, type)}
            />
            : null
        }

        {/* 订单附件弹窗 */}
        <OrderEnclosureDrawer
          visible={enclosureModal}
          name='PURCHASE_ENCLOSURE'
          type='PURCHASE_PLATFORM'
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(TradeOrder)
