import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Table, Button, Switch, message, Modal, Divider } from 'antd'
import moment from 'moment'

import DepartModal from '../component/depart-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

class DepartmentList extends Component {
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }
  state = {
    departModal: false,
    type: 1,
    rowDetail: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'depart-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (
        `${index + 1}`
      )
    },
    {
      title: '部门名称',
      key: 'depart-1',
      dataIndex: 'departName'
    },
    {
      title: '部门职能',
      key: 'depart-3',
      dataIndex: 'jobDesc'
    },
    {
      title: '添加时间',
      key: 'depart-4',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '是否启用',
      key: 'depart-5',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            {
              buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1
                ? <Switch checkedChildren='启用' unCheckedChildren='禁用'
                  checked={+record.status === 1}
                  onChange={(checked) => this.changeDepartStatus(checked, record)}
                />
                : <div>
                  {
                    +record.status === 1 ? '启用' : '禁用'
                  }
                </div>
            }
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'depart-6',
      width: 220,
      render: (text, record, index) => {
        const { buttonMaps } = this.props
        return (
          <div>
            <a onClick={() => this.jumpToDepartRole(record)}>关联角色</a>
            <Divider type='vertical' />

            {
              buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <a onClick={() => this.handleSetDepart(record, 2)}>编辑</a>
                  <Divider type='vertical' />
                </div>
                : null
            }

            {
              buttonMaps && buttonMaps['delete'] && +buttonMaps['delete'].auth === 1
                ? <a onClick={() => this.handleDeleteDepart(record)}>删除</a>
                : null
            }

          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getDepartmentList()
  }

  // 获取部门列表
  getDepartmentList = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getDepartmentList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存角色启用/禁用
  updateDepartStatus = (params) => {
    Api.updateDepartStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.getDepartmentList()
      }
    })
  }

  // 新增部门
  saveAddDepartment = (params) => {
    Api.saveAddDepartment(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getDepartmentList()
      }
    })
  }

  // 编辑部门
  saveEditDepartment = (params) => {
    Api.saveEditDepartment(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getDepartmentList()
      }
    })
  }

  // 删除部门
  deleteDepartment = (params) => {
    Api.deleteDepartment(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getDepartmentList()
      }
    })
  }

  // 点击切换启用/禁用
  changeDepartStatus = (checked, record) => {
    let params = {
      id: record.id,
      status: checked ? 1 : 0
    }

    this.updateDepartStatus(params)
  }

  // 点击关联部门角色
  jumpToDepartRole = (record) => {
    this.props.history.push({ pathname: '/admin/roleList', state: { detailInfo: record } })
  }

  // 点击新增/编辑部门
  handleSetDepart = (record, type = 1) => {
    this.setState({
      departModal: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击删除部门
  handleDeleteDepart = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.deleteDepartment({ id: record.id })
      }
    })
  }

  // 点击确定保存部门
  handleSaveDepart = (params) => {
    const { type = 1 } = this.state
    if (type === 1) {
      this.saveAddDepartment(params)
    } else {
      this.saveEditDepartment(params)
    }
  }

  closeModal = () => {
    this.setState({
      type: 1,
      departModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getDepartmentList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getDepartmentList()
    })
  }

  render () {
    const { pages, tableData, departModal = false, type = 1, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: false,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Button type='primary' icon='plus'
                onClick={() => this.handleSetDepart({}, 1)}
              >新增部门</Button>
            </Row>
            <Row>
              <Table rowKey='id' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
              />
            </Row>
          </Card>
        </Row>

        {
          departModal
            ? <DepartModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleSaveDepart(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(DepartmentList)
