import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input, Icon, Upload, message, Select, Button, Divider } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './setfloor.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts
const FormItem = Form.Item
const Option = Select.Option

const imgSizeTips = [
  { type: 1, tipText: `图片宽为400px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 2, tipText: `图片宽为1200px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 3, tipText: `图片尺寸为 240px * 620px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 4, tipText: `图片尺寸为 400px * 400px, 图片大小不超过${IMG_LT2M}MB` }
]

class SetfloorForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    coverList: [], // 封面
    list: [],
    brandList: [], // 品牌列表
    requiredIndex: -1
  }

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    const { detailInfo = {} } = this.props

    if (detailInfo.elementList && detailInfo.elementList.length > 0) {
      let _list = []
      let _coverList = []

      _list = detailInfo.elementList.filter(item => {
        return +item.type !== 99
      })

      let obj = detailInfo.elementList.find(item => {
        return +item.type === 99
      })

      if (obj) {
        _coverList.push(obj)
      }

      if (_list.length < 8) {
        let _num = 8 - _list.length
        _list = this.initProductList(_list, _num)
      }

      this.setState({
        list: _list,
        coverList: _coverList
      })
    } else {
      this.setState({
        coverList: [ { imageUrl: '' } ],
        list: this.initProductList([], 8)
      })
    }
  }

  // 获取品牌
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          brandList: data || []
        })
      }
    })
  }

  // 生成产品链接
  generatorDetailInBannerAdvert = (params, i) => {
    Api.generatorDetailInBannerAdvert(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.props.form.setFieldsValue({
          [`item[${i}].commoditySkuId`]: data.commoditySkuId,
          [`item[${i}].linkUrl`]: data.skuDetailUrl
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        const { item = [], cover = [] } = values

        let i = item.findIndex((record) => {
          return record.imageUrl === '' || record.linkUrl === ''
        })

        if (i > -1) {
          message.warning('请上传图片！ 请生成链接！')
          this.scrollToAnchor(i)

          this.setState({
            requiredIndex: i
          })
          return false
        }

        let params = {
          containerId: detailInfo.id,
          elementList: $lodash.concat(item, cover)
        }

        this.props.onConfirm(params)
      }
    })
  }

  // 点击生成链接
  handleGenerateDetailUrl = (i) => {
    let values = this.props.form.getFieldsValue([`gen[${i}].brandId`, `gen[${i}].skuCode`])
    let obj = values.gen[i]

    if (!obj.brandId || !obj.skuCode || obj.skuCode === '') {
      return message.warning('请输入产品信息')
    }

    this.generatorDetailInBannerAdvert(obj, i)
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过 ${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  onChangeUploadImage = (info, i, str) => {
    if (info.file.status === 'done') {
      let { coverList = [], list = [] } = this.state
      const { data } = info.file.response

      if (str === 'COVER') {
        coverList[i].imageUrl = data.imageUrl
      } else {
        list[i].imageUrl = data.imageUrl
      }

      this.setState({
        coverList,
        list
      })
    }
  }

  // 删除图片
  handleRemoveImage = (i, str) => {
    let { list = [], coverList = [] } = this.state

    if (str === 'COVER') {
      coverList[i].imageUrl = ''
    } else {
      list[i].imageUrl = ''
    }

    this.setState({
      list,
      coverList
    })
  }

  // 设置初始化数据结构
  initProductList = (arr, num) => {
    for (let i = 0; i < num; i++) {
      let obj = {
        imageUrl: '',
        name: '',
        remark: '',
        linkUrl: ''
      }

      arr.push(obj)
    }

    return arr
  }

  // 滚动到锚点位置
  scrollToAnchor = (i) => {
    let anchorElement = document.getElementById(`brickItem-${i}`)
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // 上传图片文字提示
  renderImgSizeTips = (type) => {
    const obj = imgSizeTips.find(item => { return item.type === type })
    const text = obj !== undefined ? obj.tipText : ''

    return text
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { coverList = [], list = [], brandList = [], requiredIndex = -1 } = this.state

    return (
      <div>
        <Modal title='编辑楼层' bodyStyle={{ padding: '12px', height: '500px', overflowY: 'auto' }}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            {/* 封面图片START */}
            <Row className={`${styles.rowHeader}`}>
              <Divider dashed style={{ margin: '0px 5px' }}>封面</Divider>
              <Row className={`${styles.rowTips}`}>建议：{this.renderImgSizeTips(3)}</Row>
            </Row>

            {
              coverList.map((cover, n) => (
                <Row className={`${styles.rowContainer}`} key={`cover-${n}`}>
                  <Row className={`${styles.rowThumb}`}>
                    {
                      cover.imageUrl && cover.imageUrl !== ''
                        ? (<div className={styles.thumbWrap}>
                          <div className={styles.thumbInfo}>
                            <img src={cover.imageUrl} />

                            <a className={styles.prewModal}>
                              <Icon type='delete' style={{ fontSize: '20px' }}
                                onClick={() => this.handleRemoveImage(n, 'COVER')}
                              />
                            </a>
                          </div>
                        </div>
                        ) : (
                          <Upload
                            showUploadList={false}
                            listType='picture-card'
                            accept='image/png, image/jpg, image/jpeg'
                            headers={{ Token: getStore('token') }}
                            action={uploadOrdinaryImageUrl}
                            beforeUpload={this.beforeUpload}
                            onChange={(info) => this.onChangeUploadImage(info, n, 'COVER')}
                          >
                            <div>
                              <Icon type='plus' />
                              <div style={{ fontSize: '12px' }}>点击上传</div>
                            </div>
                          </Upload>
                        )
                    }
                  </Row>
                  <Row className={`${styles.rowSet}`}>
                    <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: '25%' }}>
                      {
                        getFieldDecorator(`cover[${n}].imageUrl`, {
                          initialValue: cover.imageUrl || ''
                        })(
                          <Input disabled />
                        )
                      }
                    </FormItem>

                    <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: '25%' }}>
                      {
                        getFieldDecorator(`cover[${n}].type`, {
                          initialValue: cover.type || 99
                        })(
                          <Input disabled />
                        )
                      }
                    </FormItem>
                  </Row>
                </Row>
              ))
            }
            {/* 封面图片END */}

            <Row className={`${styles.rowHeader}`}>
              <Divider dashed style={{ margin: '0px 5px' }}>产品</Divider>
              <Row className={`${styles.rowTips}`}>建议：{this.renderImgSizeTips(4)}</Row>
            </Row>

            {
              list.map((item, i) => (
                <Row className={`${styles.rowContainer} ${requiredIndex === i ? styles.errorBorder : ''}`} key={`brickItem-${i}`} id={`brickItem-${i}`}>
                  <Row className={`${styles.rowThumb}`}>
                    {
                      item.imageUrl && item.imageUrl !== ''
                        ? (<div className={styles.thumbWrap}>
                          <div className={styles.thumbInfo}>
                            <img src={item.imageUrl} />

                            <a className={styles.prewModal}>
                              <Icon type='delete' style={{ fontSize: '20px' }}
                                onClick={() => this.handleRemoveImage(i, 'PRODUCT')}
                              />
                            </a>
                          </div>
                        </div>)
                        : <Upload
                          showUploadList={false}
                          listType='picture-card'
                          accept='image/png, image/jpg, image/jpeg'
                          headers={{ Token: getStore('token') }}
                          action={uploadOrdinaryImageUrl}
                          beforeUpload={this.beforeUpload}
                          onChange={(info) => this.onChangeUploadImage(info, i, 'PRODUCT')}
                        >
                          <div>
                            <Icon type='plus' />
                            <div style={{ fontSize: '12px' }}>点击上传</div>
                          </div>
                        </Upload>
                    }
                  </Row>
                  <Row className={`${styles.rowSet}`}>
                    <FormItem label='名称' style={{ marginBottom: '5px' }}>
                      {
                        getFieldDecorator(`item[${i}].name`, {
                          initialValue: item.name || ''
                        })(
                          <Input placeholder='请输入...' autoComplete='off' />
                        )
                      }
                    </FormItem>
                    <FormItem label='描述' style={{ marginBottom: '5px' }}>
                      {
                        getFieldDecorator(`item[${i}].remark`, {
                          initialValue: item.remark || ''
                        })(
                          <Input placeholder='请输入...' autoComplete='off' />
                        )
                      }
                    </FormItem>

                    <Row className={`${styles.intelLand}`}>
                      <Row className={`${styles.intelItem} ${styles.intelControl}`}>
                        <FormItem style={{ marginBottom: '0px' }}>
                          {
                            getFieldDecorator(`gen[${i}].brandId`, {

                            })(
                              <Select placeholder='品牌'>
                                {
                                  brandList.map((brand) => (
                                    <Option value={brand.brandId} key={`brickItem-${i}-${brand.barndId}`}>{brand.BrandName}</Option>
                                  ))
                                }
                              </Select>
                            )
                          }
                        </FormItem>

                      </Row>
                      <Row className={`${styles.intelItem} ${styles.intelControl}`}>
                        <FormItem style={{ marginBottom: '0px' }}>
                          {
                            getFieldDecorator(`gen[${i}].skuCode`, {

                            })(
                              <Input placeholder='型号/订货号' autoComplete='off' />
                            )
                          }
                        </FormItem>
                      </Row>
                      <Row className={`${styles.intelItem} ${styles.intelBtns}`}>
                        <FormItem style={{ marginBottom: '0px' }}>
                          <Button size='small' type='primary'
                            onClick={() => this.handleGenerateDetailUrl(i)}
                          >生成链接</Button>
                        </FormItem>
                      </Row>
                    </Row>

                    <FormItem label='链接' style={{ marginBottom: '5px' }}>
                      {
                        getFieldDecorator(`item[${i}].linkUrl`, {
                          initialValue: item.remark || ''
                        })(
                          <Input disabled />
                        )
                      }
                    </FormItem>

                    <Row className={`${styles.hiddenLand}`} style={{ display: 'none' }}>
                      <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: '25%' }}>
                        {
                          getFieldDecorator(`item[${i}].imageUrl`, {
                            initialValue: item.imageUrl || ''
                          })(
                            <Input disabled />
                          )
                        }
                      </FormItem>

                      <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: '25%' }}>
                        {
                          getFieldDecorator(`item[${i}].type`, {
                            initialValue: item.type || 5
                          })(
                            <Input disabled />
                          )
                        }
                      </FormItem>

                      <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: '25%' }}>
                        {
                          getFieldDecorator(`item[${i}].commoditySkuId`, {
                            initialValue: item.commoditySkuId || ''
                          })(
                            <Input disabled />
                          )
                        }
                      </FormItem>
                    </Row>

                  </Row>
                </Row>
              ))
            }
          </Form>
        </Modal>
      </div>
    )
  }
}

const SetfloorModal = Form.create()(SetfloorForm)

export default SetfloorModal
