import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Descriptions, Form, Table } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import Api from '@/common/api/index'
import { renderAddressLabel, renderSaledTypeLabel, renderSaledReasonTypeLabel, renderSaledRefundTypeLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item

export default class DrawerContents extends Component {
  static propTypes = {
    detailInfo: PropTypes.object
  }
  state = {
    info: {},
    tableData: [],
    imageUrls: []
  }
  columns = [
    {
      title: '序号',
      key: 'supplyProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '型号',
      key: 'supplyProductCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'supplyProductCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'supplyProductCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'supplyProductCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'supplyProductCol-6',
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'supplyProductCol-7',
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{+record.sellingPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'supplyProductCol-8',
      dataIndex: '_totalPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.quantity * +record.sellingPrice, 2) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {
    this.getSupplyOrderSaledDetails()
  }

  // 获取云供应售后申请详情
  getSupplyOrderSaledDetails = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getSupplyOrderSaledDetails({ id: detailInfo.id })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data || {},
        tableData: data.skuInfoList || [],
        imageUrls: data.goodsImageList || []
      })
    }
  }

  render () {
    const { detailInfo = {} } = this.props
    const { info = {}, tableData = [], imageUrls = [] } = this.state
    const { receiverAddress = {} } = info

    return (
      <div className={`${styles.drawerContents}`}>
        {/* <div className={`${styles.tagWrap}`}>
          <span className={`${styles.tag}`}>{ renderSupplySaledStatusLabel(detailInfo.status) }</span>
        </div> */}
        <Descriptions>
          <Descriptions.Item label='订单编号'>{ detailInfo.purchaseOrderId }</Descriptions.Item>
          <Descriptions.Item label='订单日期'>{ info.orderDate ? moment(info.orderDate).format('YYYY-MM-DD HH:mm:ss') : null }</Descriptions.Item>
        </Descriptions>

        <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />

        <div style={{ marginTop: '8px' }}>
          <Form>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='售后类型' style={{ marginBottom: '0px' }}>
                { renderSaledTypeLabel(info.type) }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='售后原因' style={{ marginBottom: '0px' }}>
                { renderSaledReasonTypeLabel(info.reasonType) }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='详细描述' style={{ marginBottom: '0px' }}>
                <p style={{ marginBottom: '0px', wordBreak: 'break-all' }}>{ info.backRemark }</p>
              </FormItem>
            </div>

            <FormItem label='可添加图片来帮助售后人员判断(最多9张)' style={{ marginBottom: '0px' }}>
              {
                imageUrls.map((item, index) => (
                  <div className={`${styles.pictureWrap}`} key={`supplySaledPictureCol-${index}`}>
                    <UploadThumbnail imageUrl={item} />
                  </div>
                ))
              }
            </FormItem>

            {
              ['REFUND_RETURN_PRODUCTS', 'REFUND'].includes(info.type)
                ? <>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='退款方式' style={{ marginBottom: '0px' }}>{ renderSaledRefundTypeLabel(info.refundType) }</FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='预估退款金额' style={{ marginBottom: '0px' }}>&yen;{ ($lodash.round(+info.refundAmount, 2) * 100 / 100).toFixed(2)}</FormItem>
                  </div></>
                : null
            }

            {
              ['CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(info.type)
                ? <>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='换货、补寄收货地址' style={{ marginBottom: '0px' }}>
                      {
                        receiverAddress && receiverAddress.province
                          ? <>{ renderAddressLabel(receiverAddress.province, receiverAddress.city, receiverAddress.county, receiverAddress.address) } { receiverAddress.name } { receiverAddress.mobile }</>
                          : null
                      }
                    </FormItem>
                  </div>
                </>
                : null
            }
          </Form>
        </div>
      </div>
    )
  }
}
