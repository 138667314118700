import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Table, Badge, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import DetailModal from './detail-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
const { pageSizeOptions, cardApplyStatusMaps } = filter

class MiniprogramApply extends Component {
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'companyName',
        formType: 'Input',
        itemParams: {
          label: '公司名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cardApplyStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {},
    detailModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'miniApply-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      key: 'miniApply-1',
      width: 180,
      ellipsis: true,
      dataIndex: 'companyName'
    },
    {
      title: '联系人',
      key: 'miniApply-2',
      dataIndex: 'contactPerson'
    },
    {
      title: '联系方式',
      key: 'miniApply-3',
      dataIndex: 'contactNumber'
    },
    {
      title: '申请时间',
      key: 'miniApply-4',
      render: (text, record, index) => (
        <div>
          {
            record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'
          }
        </div>
      )
    },
    {
      title: '审核状态',
      key: 'miniApply-5',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ this.renderAuditStatusLabel(record.status) }</>
      )
    },
    {
      title: '操作',
      key: 'miniApply-7',
      width: 160,
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <Row style={{ display: 'flex' }}>

            {
              buttonMaps && buttonMaps['getdetail'] && +buttonMaps['getdetail'].auth === 1
                ? <div style={{ marginRight: '8px' }}>
                  <a type='primary' onClick={() => this.showDetailModal(record)}>查看详情</a>
                </div>
                : null
            }

            {
              buttonMaps && buttonMaps['examine'] && +buttonMaps['examine'].auth === 1 && (['APPLY_IN', 'RE_APPLY', 'EXPAND_APPLY'].includes(record.status))
                ? <div>
                  <a type='primary' style={{ marginRight: '8px' }}
                    onClick={() => this.updateCardApplyStatus({ id: record.id, status: 'APPROVED' })}
                  >通过</a>
                  <a type='primary'
                    onClick={() => this.updateCardApplyStatus({ id: record.id, status: 'REJECT_BACK' })}
                  >拒绝</a>
                </div>
                : null
            }

          </Row>
        )
      }
    }
  ]

  componentDidMount () {
    this.getCardMiniProgramApplyList()
  }

  // 获取名片申请列表
  getCardMiniProgramApplyList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCardMiniProgramApplyList(params).then(res => {
      const { code, data = {} } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.resultList || [],
          pages: {
            ...pages,
            total: data.totalNum || 0
          }
        })
      }
    })
  }

  // 审核名片小程序
  updateCardApplyStatus = (params) => {
    Api.updateCardApplyStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getCardMiniProgramApplyList()
      }
    })
  }

  showDetailModal = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => this.getCardMiniProgramApplyList())
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 渲染审核状态
  renderAuditStatusLabel = (status) => {
    const obj = cardApplyStatusMaps.find(item => { return item.id === status })
    const el = obj !== undefined ? <Badge status={obj.result} text={obj.name} /> : null

    return el
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCardMiniProgramApplyList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCardMiniProgramApplyList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], detailModal = false, rowDetail = {} } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <Table rowKey='id' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          detailModal
            ? <DetailModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(MiniprogramApply)
