import React, { Component } from 'react'
import { Row, Card, Col, Statistic, Table, Divider, Tooltip, Progress } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api'

import filter from '@/common/utils/filter'

const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')

export default class index extends Component {
  state = {
    searchData: [
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { month: 0, monthLabel: '全年' },
          ...fullsMonthOptions
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['month', 'monthLabel']
      }
    ],
    query: {},
    newMonthAliveUser: 0, // 新增月活客户数量
    cloudPurchaseAmount: 0, // 云采购合同金额
    cloudPurchaseSentOut: 0, // 云采购销售额（发货）
    cloudPurchaseSentOutSeries: [], // echarts云采购销售金额（发货）
    averageGrossMargin: 0, // 平均毛利率
    tableDataForCus: [], // 月活客户明细列表
    monthAliveSeries: [], // 月活客户Echarts
    percentForUserNum: 0, // 月活客户总数进度条
    percentForAmount: 0 // 云采购销售额进度条
  }
  columnsForCus = [
    {
      title: '序号',
      key: 'cusListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '员工名称',
      key: 'cusListCol-1',
      dataIndex: 'userName'
    },
    {
      title: '月活客户总数',
      key: 'cusListCol-2',
      dataIndex: 'monthAliveTotal'
    },
    {
      title: '云采购合同金额',
      key: 'cusListCol-3',
      dataIndex: 'cloudPurchaseAmoutForCus',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云采购发货金额',
      key: 'cusListCol-4',
      dataIndex: 'cloudPurchaseSendOutAmountForCus',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '员工平均毛利率',
      key: 'cusListCol-5',
      dataIndex: 'averageData',
      render: (text, record, index) => {
        return (
          <span>{parseFloat(text).toFixed(2)}%</span>
        )
      }
    }
  ]

  // 获取报表数据
  getOperationStatistics = async () => {
    const { query = {} } = this.state
    const res = await Api.getPerformanceStatistics({ type: 'performance_overview_customer_success_department', user_id: 17 })
    let cloudPurchaseAmountObj = res.list_department_cloud_purchase_amount_newly // 云采购合同额总数据
    let cloudPurchaseSentOutObj = res.list_department_cloud_purchase_amount_send_out_newly // 云采购销售额总数据
    let cloudSupplyAmountObj = res.list_department_cloud_supply_amount_send_out_newly // 云供应金额总数据
    let listMonthAliveDetailObj = res.list_distributors_month_alive // 月活列表总数据
    let targetObj = res.list_statistics_department_targets // 目标总数据
    let _tabledataForCus = [] // 月活明细列表总数据

    // 月活客户总数
    let monthAliveUsersData = []
    $lodash.forEach(listMonthAliveDetailObj, (value, key) => {
      const obj = [key, value]
      monthAliveUsersData.push(obj)
    })

    // 云采购合同额数据
    let cloudPurchaseAmountdatas = []
    $lodash.forEach(cloudPurchaseAmountObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 3)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudPurchaseAmountdatas.push(obj)
      }
    })

    // 云采购销售额数据
    let cloudPurchaseSendOutdatas = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 3)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudPurchaseSendOutdatas.push(obj)
      }
    })

    // 平均毛利率  数据处理
    let averageGrossMarginData = 0
    // 计算云采购总金额
    let cloudPurchaseAmountDatasforAverage = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if ((value.trans_month.includes(YEAR) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 3)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudPurchaseAmountDatasforAverage.push(obj)
      }
    })
    // 计算云供应总金额
    let cloudSupplyAmountData = []
    $lodash.forEach(cloudSupplyAmountObj, (value, key) => {
      if (value.trans_month.includes(YEAR) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudSupplyAmountData.push(obj)
      }
    })
    // 平均毛利率=（云采购订单总金额-云供应订单总金额）/云采购订单总金额*100%
    averageGrossMarginData = (($lodash.sumBy(cloudPurchaseAmountDatasforAverage, 'value')) > 0)
      ? (($lodash.sumBy(cloudPurchaseAmountDatasforAverage, 'value')) - ($lodash.sumBy(cloudSupplyAmountData, 'value'))) / ($lodash.sumBy(cloudPurchaseAmountDatasforAverage, 'value')) * 100 : 0
    // 月活客户总数进度条
    let percentForUserData = 0
    $lodash.forEach(targetObj, (value, key) => {
      if ((value.target_name === '新增月活客户数量') && (value.depart_id === 3)) {
        percentForUserData = parseFloat((monthAliveUsersData.length / value.target_value) * 100).toFixed(2)
      }
    })

    // 云采购销售额进度条
    let percentForAmountData = 0
    $lodash.forEach(targetObj, (value, key) => {
      if ((value.target_name === '云采购销售额') && (value.depart_id === 3)) {
        percentForAmountData = parseFloat(($lodash.sumBy(cloudPurchaseSendOutdatas, 'value') / value.target_value) * 100).toFixed(2)
      }
    })

    // 云采购销售额Echarts折线图数据
    const obj = $lodash.groupBy(cloudPurchaseSentOutObj, 'trans_month')
    const cloudPurchaseSendEcharts = {}
    for (const month in obj) {
      obj[month].forEach(item => {
        if (item.trans_month.includes(YEAR) && (item.depart_id === 3)) {
          const key = +(item.trans_month.split('-')[1])
          const value = item.amount_sum
          if (cloudPurchaseSendEcharts[key]) {
            cloudPurchaseSendEcharts[key] += value
          } else {
            cloudPurchaseSendEcharts[key] = value
          }
        }
      })
    }
    let cloudPurchaseSendEchartsOBJ = []
    $lodash.forEach(cloudPurchaseSendEcharts, (value, key) => {
      const objs = { month: +(key), val: parseFloat(value).toFixed(2) }
      cloudPurchaseSendEchartsOBJ.push(objs)
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const cloudPurchaseSendEchartsdata = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(cloudPurchaseSendEchartsOBJ, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 设置月活明细table列表数据
    if (res.table_details) {
      _tabledataForCus = this.setTableDataForCus(res.table_details)
    }

    this.setState({
      newMonthAliveUser: monthAliveUsersData.length > 0 ? monthAliveUsersData.length : 0, // 月活客户总数
      cloudPurchaseAmount: cloudPurchaseAmountdatas.length > 0 ? $lodash.sumBy(cloudPurchaseAmountdatas, 'value') : 0, // 云采购合同额
      cloudPurchaseSentOut: cloudPurchaseSendOutdatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutdatas, 'value') : 0, // 云采购销售
      averageGrossMargin: averageGrossMarginData > 0 ? parseFloat(averageGrossMarginData).toFixed(2) : 0, // 平均毛利率
      percentForUserNum: percentForUserData, // 月活客户总数进度条
      percentForAmount: percentForAmountData, // 云采购销售额进度条
      cloudPurchaseSentOutSeries: cloudPurchaseSendEchartsdata, // 云采购销售金额（发货）Echarts
      tableDataForCus: _tabledataForCus // 月活明细

    }, () => {
      this.initECharts()
    })
  }

  // 初始化图表
  initECharts = () => {
    const { cloudPurchaseSentOutSeries } = this.state
    var myChart = echarts.init(document.getElementById('operationStatistics'))
    // 绘制图表
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${YEAR}客户成功总览折线图`
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['云采购销售金额（发货）']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisLabel: {
          formatter: `{value}月`
        }
      },
      yAxis: [
        {
          type: 'value',
          name: '金额',
          scale: true, /* 按比例显示 */
          axisLabel: {
            formatter: '{value} ￥'
          }
        }
      ],
      series: [
        {
          name: '云采购销售金额（发货）',
          type: 'line',
          yAxisIndex: 0,
          itemStyle: { normal: { label: { show: true } } },
          data: cloudPurchaseSentOutSeries
        }
      ]
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    }, () => {
      this.getOperationStatistics()
    })
  }

  // 设置月活客户明细table列表数据结构
  setTableDataForCus = (origindatas) => {
    const { query = {} } = this.state
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云采购订单金额table数据
      let cloudPurchaseTabledatas = []
      // 云供应订单金额table数据
      let cloudSupplyTabledatas = []
      // 云采购发货金额table数据
      let cloudPurchaseSendOutTabledatas = []
      let averageTableData = 0
      if (values.depart_id === 3) {
        // 云采购订单金额table数据
        $lodash.forEach(values.list_department_cloud_purchase_amount_newly, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudPurchaseTabledatas.push(obj)
          }
        })
        // 云采购发货金额table数据
        $lodash.forEach(values.list_department_cloud_purchase_amount_send_out_newly, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudPurchaseSendOutTabledatas.push(obj)
          }
        })
        // 云供应订单金额table数据
        $lodash.forEach(values.cloud_supply_amount_send_out_newly_year_chart, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudSupplyTabledatas.push(obj)
          }
        })
        // 平均毛利率
        averageTableData = ($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') > 0) ? (($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') - $lodash.sumBy(cloudSupplyTabledatas, 'value')) / ($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value')) * 100) : 0
      }

      return {
        userName: values.user_name,
        monthAliveTotal: values.month_alive_users_count,
        cloudPurchaseAmoutForCus: cloudPurchaseTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseTabledatas, 'value') : 0, // 云采购合同额数据
        cloudPurchaseSendOutAmountForCus: cloudPurchaseSendOutTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') : 0, // 云采购销售额
        averageData: averageTableData > 0 ? parseFloat(averageTableData).toFixed(2) : 0
      }
    }), function (item) {
      return -item.cloudPurchaseAmoutForCus
    })
    return list
  }

  render () {
    const { searchData, newMonthAliveUser, cloudPurchaseAmount, cloudPurchaseSentOut, averageGrossMargin, tableDataForCus, percentForUserNum, percentForAmount } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px', paddingLeft: '30px' }}>
          <Col span={6} >
            <Statistic title='月活客户总数' value={newMonthAliveUser} />
          </Col>
          <Col span={6}>
            <Statistic title='云采购合同额' value={cloudPurchaseAmount} precision={2} prefix='￥' />
          </Col>
          <Col span={6}>
            <Statistic title='云采购销售额(发货)' value={cloudPurchaseSentOut} precision={2} prefix='￥' />
          </Col>
          <Col span={6}>
            <Statistic title='云仓平均毛利率' value={averageGrossMargin} precision={2} suffix='%' />
          </Col>
        </Row>
        <Card bodyStyle={{ padding: '12px' }}>
          <Tooltip title='prompt text' style={{ width: '100px', display: 'inline-block' }}>
            <span>月活客户总数:</span>
          </Tooltip>
          <Progress style={{ width: '700px', display: 'inline-block', paddingLeft: '30px', paddingRight: '60px' }}
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
            strokeWidth='20px'
            percent={percentForUserNum}
            status='active'
          />
          <Tooltip title='prompt text' style={{ width: '100px', display: 'inline-block' }}>
            <span>云采购销售额:</span>
          </Tooltip>
          <Progress style={{ width: '700px', display: 'inline-block', paddingLeft: '30px', paddingRight: '60px' }}
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
            strokeWidth='20px'
            percent={percentForAmount}
            status='active'
          />
        </Card>
        <Card bodyStyle={{ padding: '12px' }}>
          <div id='operationStatistics' style={{ width: '100%', height: '500px' }} />
        </Card>
        <Divider orientation='left'>员工明细</Divider>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columnsForCus}
            dataSource={tableDataForCus}
            pagination={false}
          />
        </Card>
      </div>
    )
  }
}
