import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table, Button, Descriptions } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class OccupyModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'occupy-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单号',
      key: 'occupy-1',
      dataIndex: 'orderId',
      ellipsis: true
    },
    {
      title: '订单时间',
      key: 'occupy-2',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '采购商家',
      key: 'occupy-3',
      dataIndex: 'distributorName'
    },
    {
      title: '采购数量',
      key: 'occupy-4',
      dataIndex: 'quantity'
    }
  ]

  componentDidMount () {
    this.getStockOccupiedDetailInOnlineProd()
  }

  // 查询订单占用库存
  getStockOccupiedDetailInOnlineProd = () => {
    const { detailInfo = {} } = this.props
    const { pages } = this.state
    let params = {
      commoditySkuId: detailInfo.commoditySkuId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getStockOccupiedDetailInOnlineProd(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStockOccupiedDetailInOnlineProd()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStockOccupiedDetailInOnlineProd()
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { pages, tableData = [] } = this.state

    // 分页配置
    const pagination = {
      size: 'small',
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Modal title='订单库存明细'
          bodyStyle={{ padding: '15px', height: '500px', overflowY: 'auto' }}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={[
            <Button key={`btn-0`} type='primary'
              onClick={() => this.props.onCancel()}>关闭</Button>
          ]}
        >
          <Row>
            <Descriptions>
              <Descriptions.Item label='品牌'>{detailInfo.brandName}</Descriptions.Item>
              <Descriptions.Item label='型号'>{detailInfo.model}</Descriptions.Item>
              <Descriptions.Item label='订货号'>{detailInfo.itemNumber}</Descriptions.Item>
              <Descriptions.Item label='订单库存'>{+detailInfo.occupyStockTotal}</Descriptions.Item>
            </Descriptions>
          </Row>
          <Row>
            <Table rowKey='cloudOrderId'
              dataSource={tableData}
              columns={this.columns}
              pagination={pagination}
            />
          </Row>
        </Modal>
      </div>
    )
  }
}

export default OccupyModal
