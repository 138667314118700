import Ajax from '../utils/ajax'

export default {
  /* 云供应 */
  // 云供应-品牌审核列表
  getCloudAuditList (params) {
    return Ajax.post('/cloudSku/brandSupply/auditList', params)
  },

  // 云供应-审核
  saveMerchantSkuAudit (params) {
    return Ajax.post('/cloudSupplyAudit/audit', params)
  },

  // 云供应-获取品牌代理状态列表
  getAgencyListInCloudSupply (params) {
    return Ajax.post('/cloudSupplyAudit/listPageForAuthRecord', params)
  },

  // 云供应-商户代理证设置
  setAgencyInCloudSupply (params) {
    return Ajax.post('/cloudSupplyAudit/brandAuthSetting', params)
  },

  /* 云库产品 */
  // 获取云库云仓统计信息
  getCloudSkuStatisticsInfo (params) {
    return Ajax.post('/oms/cloudCommoditySku/getCloudSkuStatisticsInfo', params)
  },

  // 获取云库商品列表
  getAllCloudSkuList (params) {
    return Ajax.post('/oms/cloudCommoditySku/listPageByAllCloudSku', params)
  },

  // 获取云库商品列表（+搜索）
  getALLSerCloudSkuList (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/listForCloudSupplierInfo', params)
  },

  // 获取云库产品的供应商明细
  getMerchantInfoByCloudSkuId (params) {
    return Ajax.post('/oms/cloudCommoditySku/supplierInfoByAll', params)
  },

  // 获取供应商云库sku列表
  getMerchantCloudSkuList (params) {
    return Ajax.post('/oms/cloudCommoditySku/skuListBySupplier', params)
  },

  // 获取虚拟库列表
  getCloudInventoryList (params) {
    return Ajax.post('/oms/cloudCommoditySku/merchantList', params)
  },

  /* 云仓 */
  // 获取云仓产品列表
  getCloudOnlineSkuList (params) {
    return Ajax.post('/oms/cloudCommoditySku/listPageByCloudSku', params)
  },

  // 云仓-获取供应商明细
  getMerchantInfoByOnlineSkudId (params) {
    return Ajax.post('/oms/cloudCommoditySku/supplierInfoByCloud', params)
  },

  // 云仓-获取利润规则详情
  getCloudRuleDetail (params) {
    return Ajax.post('/ruleSettings/list', params)
  },

  // 云仓-保存规则设置
  saveCloudRule (params) {
    return Ajax.post('/ruleSettings/save', params)
  },

  // 云仓-查看订单库存明细
  getStockOccupiedDetailInOnlineProd (params) {
    return Ajax.post('/platform/cloudSku/listPageByStockOccupied', params)
  },

  // 虚拟云仓- 获取虚拟库数据统计
  getInventDetailDashboardAnalyse (params) {
    return Ajax.get('/oms/cloudCommoditySku/getBrandStatisticsData', { params })
  },

  /* 云特价 */
  // 云特价-保存特价设置
  getspecialPriceByMerchant (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/specialPriceSetting', params)
  },

  // 云特价-获取特价设置记录
  getSetSpecialPriceFlow (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/listPage', params)
  },

  // 云特价-设备记录(云特价模块使用中)
  newgetSetSpecialPriceFlow (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/listPageForRecord', params)
  },

  // 云特价-延期设置
  saveSpecialPriceSetExtend (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/batchDealForOverTimeSetting', params)
  },

  // 云特价-获取设置日志
  getCloudSpecialPriceSettingLogs (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/listPageForSpecialPriceOperationLog', params)
  },

  // 云特价-设置记录中止操作
  setEndCloud (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/shutDownSpecialRecord', params)
  },

  // 云特价-获取导入设置失败明细下载链接
  getSetSpecialPriceUploadFailDownloadLink (params) {
    return Ajax.post('/platform/disCloudSku/specialPrice/failedDataExport', params)
  },

  // 云仓列表获取
  getCloudLibrard (params) {
    return Ajax.post('/cloud/merchant/stockSku/listPage', params)
  },

  // 云交易记录列表
  listPageTradingInfo (params) {
    return Ajax.post('/oms/cloudSku/listPageTradingInfo', params)
  },

  // 云仓上新
  getPutOnShelve (params) {
    return Ajax.post('/platform/cloudSku/oneKeyPutOnShelve', params)
  },

  // 获取云交易记录（无成交利率）列表
  getCloudTransactionRecordsNotRateList (params) {
    return Ajax.post('/oms/cloudSku/listPageTradingInfo', params)
  }
}
