import React, { Component } from 'react'
import { Card, Table } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { repLineBreak, pageSizeOptions } = filter

class ReferenceSyncData extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'result',
        formType: 'Select',
        itemParams: {
          label: '匹配状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'SUCCESS', name: '已匹配' },
          { id: 'FAIL', name: '未匹配' }
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '传输时间'
        },
        cptParams: {}
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '传输对象'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'brandName',
        formType: 'Input',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumberOrModel',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号...',
          autoComplete: 'off'
        }
      },
      {
        name: '_itemNumbers',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号/订货号...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }

  columns = [
    {
      title: '序号',
      width: 44,
      align: 'center',
      key: 'referenceSyncCol-0',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '匹配状态',
      key: 'referenceSyncCol-1',
      dataIndex: 'result',
      render: (text, record, index) => (
        <>{ record.result === 'SUCCESS' ? <span className='greenColor'>已匹配</span> : <span className='redColor'>未匹配</span> }</>
      )
    },

    {
      title: '传输时间',
      key: 'referenceSyncCol-2',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },

    {
      title: '传输对象',
      key: 'referenceSyncCol-3',
      dataIndex: 'distributorName'
    },
    {
      title: '品牌',
      key: 'referenceSyncCol-4',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'referenceSyncCol-5',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'referenceSyncCol-6',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'referenceSyncCol-7',
      dataIndex: 'productName',
      ellipsis: true
    },
    {
      title: '价格',
      key: 'referenceSyncCol-8',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{ +record.price }</>
      )
    },
    {
      title: '库存数量',
      key: 'referenceSyncCol-9',
      dataIndex: 'stock'
    },
    {
      title: '单位',
      key: 'referenceSyncCol-10',
      dataIndex: 'unit'
    },
    {
      title: '最小包装',
      key: 'referenceSyncCol-11',
      dataIndex: 'minPackage'
    },
    {
      title: '最小订货量',
      key: 'referenceSyncCol-12',
      dataIndex: 'minOrder'
    }
  ]

  componentDidMount () {
    this.getReferenceSyncData()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 获取外部监控数据
  getReferenceSyncData = async () => {
    const { query = {}, pages = {} } = this.state
    const res = await Api.getReferenceSyncData({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { times, _itemNumbers } = search

    if (times) {
      search['beginDate'] = times.length === 2 ? moment(times[0]).format('YYYY-MM-DD') : undefined
      search['endDate'] = times.length === 2 ? moment(times[1]).format('YYYY-MM-DD') : undefined
    }

    if (_itemNumbers && $lodash.trim(_itemNumbers) !== '') {
      let _itemNos = repLineBreak($lodash.trim(_itemNumbers), '<br>')
      search.itemNumberOrModels = _itemNos.split('<br>')
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getReferenceSyncData()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getReferenceSyncData()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getReferenceSyncData()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default ReferenceSyncData
