import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Modal, Table, Button, Upload, Icon, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import SeriesModal from '../component/seriesModal/index' // 编辑系列信息弹窗
import SkuBaseModal from '../component/skuBaseModal/index' // 型号基础信息弹窗

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'

const { confirm } = Modal
const { uploadSeriesItemUrl } = UploadApi
const { pageSizeOptions } = filter

class ProductSeries extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'seriesName',
        formType: 'Input',
        itemParams: {
          label: '系列名称'
        },
        cptParams: {
          placeholder: '请输入系列名称...',
          autoComplete: 'off'
        }
      },
      {
        name: 'createTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '创建时间'
        },
        cptParams: {

        }
      },
      {
        name: 'createName',
        formType: 'Input',
        itemParams: {
          label: '创建人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '修改时间'
        },
        cptParams: {

        }
      },
      {
        name: 'modifyName',
        formType: 'Input',
        itemParams: {
          label: '修改人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }
    ],
    query: {},
    tableData: [],
    type: 1, // 1- 新增 2-修改 3-新增 部分不可编辑
    seriesModal: false,
    skuBaseModal: false,
    rowDetail: {} // 当前选择的行数据
  }

  columns = [
    {
      title: '序号',
      width: 44,
      align: 'center',
      key: 'series-0',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '系列名称',
      key: 'series-2',
      dataIndex: 'name'
    },
    {
      title: '创建时间',
      key: 'series-3',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '创建人',
      key: 'series-4',
      dataIndex: 'createName'
    },
    {
      title: '修改时间',
      key: 'series-5',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '修改人',
      key: 'series-6',
      dataIndex: 'modifyName'
    },
    {
      title: '操作',
      key: 'series-7',
      width: 220,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showSeriesModal(2, record)}>编辑系列</Button>

          <Button type='link' size='small' onClick={() => this.showSkuBaseModal(3, { seriesId: record.id, seriesName: record.name, ...record })}>添加型号</Button>

          <Button type='link' size='small' onClick={() => this.handleJumpLink(record)}>关联产品</Button>

          <Button type='link' size='small' onClick={() => this.handleDeleteSeries(record)}>删除系列</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location

    if (!state.brandId) {
      return message.warning('参数不正确')
    }

    this.setState({
      brandId: state.brandId,
      brandName: state.brandName,
      englishBrandName: state.englishBrandName
    }, () => {
      this.getSeriesList()
    })
  }

  // 获取品牌系列列表
  getSeriesList = async () => {
    const { pages = {}, query = {}, brandId } = this.state

    const res = await Api.getSeriesList({ ...query, brandId: brandId, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存系列
  saveSeries = async (params) => {
    const res = await Api.saveSeries(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSeriesList()
    }
  }

  // 删除系列
  deleteSeries = async (params) => {
    const res = await Api.deleteSeries(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.getSeriesList()
    } else {
      message.warning(res.message)
    }
  }

  // 保存新增型号
  saveAddSku = async (params) => {
    const res = await Api.saveAddSku(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSeriesList()
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages } = this.state

    if (search.createTimes) {
      search.createdBeginDate = search.createTimes.length === 2 ? moment(search.createTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.createdEndDate = search.createTimes.length === 2 ? moment(search.createTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    if (search.modifyTimes) {
      search.modifyBeginDate = search.modifyTimes.length === 2 ? moment(search.modifyTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.modifyEndDate = search.modifyTimes.length === 2 ? moment(search.modifyTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getSeriesList()
    })
  }

  // 点击添加、编辑系列
  showSeriesModal = (type, row = {}) => {
    this.setState({
      seriesModal: true,
      type: type,
      rowDetail: row
    })
  }

  // 点击删除系列
  handleDeleteSeries = (record) => {
    confirm({
      title: '确定删除该系列？',
      content: '',
      onOk: () => {
        this.deleteSeries({ id: record.id })
      }
    })
  }

  // 点击添加型号
  showSkuBaseModal = (type, row) => {
    this.setState({
      type: type,
      rowDetail: row,
      skuBaseModal: true
    })
  }

  // 点击去关联产品
  handleJumpLink = (record) => {
    this.props.history.push({ pathname: '/admin/bindSeriesSku', state: { ...record, bindParentCode: 'SERIES' } })
  }

  // 点击下载模板
  handleDownloadTemplate = () => {
    DownloadApi.downloadSeriesUploadTemplate({ excelTemplateType: 'BRAND_SERIES_IMPORT' })
  }

  // 上传EXCEL
  changeUploadFile = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      let msg = info.file.response.message

      if (+code === 10000) {
        const { brandId, brandName } = this.state
        this.props.history.push({ pathname: '/admin/matchSheet', state: { importId: data, sourceType: 'UPLOAD_SERIES', defaultParams: { brandId: brandId, brandName: brandName } } })
      } else {
        message.error(msg)
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击返回品牌列表
  handleBack = () => {
    this.props.history.push({ pathname: '/admin/brand' })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      seriesModal: false,
      skuBaseModal: false,
      rowDetail: {},
      type: 1
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSeriesList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSeriesList()
    })
  }

  render () {
    const { pages, searchData, brandId, brandName, englishBrandName, tableData, rowDetail = {}, type = 1, seriesModal = false, skuBaseModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <div style={{ marginBottom: '20px' }}>
              <Button onClick={() => this.handleBack()}><Icon type='arrow-left' />返回品牌列表</Button>
              <div style={{ display: 'inline-block', verticalAlign: 'top', lineHeight: '32px', marginLeft: '24px', fontSize: '16px', color: '#333333' }}>
                <label className={'label'}>所属品牌</label><span>{ brandName }{ englishBrandName && englishBrandName !== '' ? `/${englishBrandName}` : null }</span>
              </div>
            </div>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.showSeriesModal(1, {})}><Icon type='plus' />添加系列</Button>

            <Upload name='file' showUploadList={false}
              headers={{ Token: getStore('token') }}
              action={uploadSeriesItemUrl}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              onChange={(info) => this.changeUploadFile(info)}
            >
              <Button type='primary' style={{ marginLeft: '10px' }}><Icon type='upload' />批量导入</Button>
            </Upload>

            <Button style={{ marginLeft: '10px' }} onClick={() => this.handleDownloadTemplate()}><Icon type='download' />下载模板</Button>
          </div>

          <Table rowKey='id' bordered
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        { // 添加、编辑系列弹窗
          seriesModal
            ? <SeriesModal
              type={type}
              detailInfo={{ ...rowDetail, brandId: brandId, brandName: brandName }}
              onConfirm={(values) => this.saveSeries(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        { // 添加型号弹窗
          skuBaseModal
            ? <SkuBaseModal
              type={type}
              detailInfo={{ ...rowDetail, brandId: brandId }}
              onConfirm={(params) => this.saveAddSku(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
export default ProductSeries
