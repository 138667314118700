import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal, message } from 'antd'

import Setfloor1Modal from './setfloor1Modal'

import Api from '@/common/api/index'

import styles from './navdecorate.module.scss'

const { confirm } = Modal

export default class FloorTpl1 extends Component {
  static propTypes = {
    floorInfo: PropTypes.object,
    onRefush: PropTypes.func,
    onDelete: PropTypes.func
  }
  state = {
    editModal: false
  }

  componentDidMount () {

  }

  // 楼层模板内容编辑
  saveEditBrandmuseElement = (params) => {
    Api.saveEditBrandmuseElement(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.onRefush()
      }
    })
  }

  // 点击确定保存元素
  handleConfirmElement = (params) => {
    this.saveEditBrandmuseElement(params)
  }

  // 点击编辑楼层
  handleShowEditModal = () => {
    this.setState({
      editModal: true
    })
  }

  // 点击删除楼层
  handleRemoveFloor = () => {
    confirm({
      title: '确定删除该楼层？',
      onOk: () => {
        const { floorInfo = {} } = this.props
        this.props.onDelete({ id: floorInfo.id })
      }
    })
  }

  closeModal = () => {
    this.setState({
      editModal: false
    })
  }

  render () {
    const { floorInfo = {} } = this.props
    const { editModal = false } = this.state
    const { elementList = [] } = floorInfo
    let rowDetail = {}

    if (elementList && elementList.length > 0) {
      rowDetail = elementList[0]
    }

    return (
      <div>
        <Row className={`${styles.floorRow}`} gutter={12}>
          <Col span={20}>
            {
              +rowDetail.id > 0
                ? <div className={`${styles.flex} ${styles.floorTpl1} ${styles.solidLine}`}>
                  <div className={`${styles.pullLeft}`}>
                    <div className={`${styles.imgWrap}`}>
                      <img src={elementList[0].imageUrl} />
                    </div>
                  </div>
                  <div className={`${styles.mediaBody}`}>
                    <div>
                      {elementList[0].remark}
                    </div>
                  </div>
                </div>
                : <div className={`${styles.flex} ${styles.floorTpl1} ${styles.solidLine}`}>
                  <div className={`${styles.pullLeft}`}>
                    <div className={`${styles.imgWrap} ${styles.seat}`}>
                      图片
                    </div>
                  </div>
                  <div className={`${styles.mediaBody} ${styles.seat}`}>
                    <div>
                      文案描述
                    </div>
                  </div>
                </div>
            }

          </Col>
          <Col span={4}>
            <div style={{ marginBottom: '10px' }}>
              <Button type='primary' size='small'
                onClick={() => this.handleShowEditModal(rowDetail)}
              >
                编辑内容
              </Button>
            </div>

            <div>
              <Button type='danger' size='small'
                onClick={() => this.handleRemoveFloor()}
              >
                删除楼层
              </Button>
            </div>
          </Col>
        </Row>

        {
          editModal
            ? <Setfloor1Modal
              type={+rowDetail.id > 0 ? 2 : 1}
              detailInfo={floorInfo}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.handleConfirmElement(params)}
            />
            : null
        }
      </div>
    )
  }
}
