import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import moment from 'moment'

export default function WorkHeader (props) {
  const { detailInfo = {} } = props

  return (
    <>
      <h3 style={{ marginBottom: '12px' }}>
        <label className={`formLabel`}>工单编号</label><span>{ detailInfo.workOrderId }</span>
      </h3>

      <Alert type='error' message={<>
        <p style={{ marginBottom: '0px' }}>
          <label className={`formLabel`}>派发时间</label>
          <span>{ detailInfo.createTime ? moment(detailInfo.createTime).format('YYYY-MM-DD HH:mm:ss') : '' }</span>
        </p>
        { detailInfo.limitTime ? <p style={{ marginBottom: '0px' }}>请在{moment(detailInfo.limitTime).format('YYYY-MM-DD HH:mm:ss')}之前完成</p> : null }
      </>}
      />
    </>
  )
}

WorkHeader.propTypes = {
  detailInfo: PropTypes.object
}
