import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Row, Col, Button, Icon } from 'antd'
import $lodash from 'lodash'

import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option
const ADDRESSTYPE = 'MERCHANT_RECEIVER_ADDRESS'

class OrderUserForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    distributorId: PropTypes.number,
    distributorName: PropTypes.string,
    onSelectAddress: PropTypes.func
  }
  state = {
    addressList: [],
    type: 1,
    rowDetail: {},
    addressModal: false
  }

  componentDidMount () {
    this.getAddressList()
  }

  // 获取客户收货地址列表
  getAddressList = async () => {
    const { form, distributorId, onSelectAddress } = this.props
    const res = await Api.getAddressList({ bindData: distributorId, addressType: ADDRESSTYPE })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        addressList: data || []
      }, () => {
        if (data && data.length > 0) {
          setTimeout(() => {
            form.setFieldsValue({ addressId: data[0].id })

            onSelectAddress(data[0].id)
          }, 20)
        }
      })
    }
  }

  // 新增收货地址
  saveAddAddress = async (params) => {
    const res = await Api.saveAddAddress(params)
    const { code } = res
    if (+code === 10000) {
      this.closeModal()
      this.getAddressList()
    }
  }

  // 更新收货地址
  saveEditAddress = async (params) => {
    const res = await Api.saveEditAddress(params)
    const { code } = res
    if (+code === 10000) {
      this.closeModal()
      this.getAddressList()
    }
  }

  // 点击新增、编辑地址
  showAddressModal = (selectedAddressId, type) => {
    const { addressList = [] } = this.state
    this.setState({
      type: type,
      rowDetail: type === 1 ? {} : $lodash.find(addressList, (o) => { return o.id === selectedAddressId }),
      addressModal: true
    })
  }

  // 点击保存收货地址
  handleConfirmModifyAddress = (values) => {
    const { distributorId } = this.props
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveAddAddress({ ...values, bindData: distributorId, addressType: ADDRESSTYPE })
    } else {
      this.saveEditAddress({ ...values, bindData: distributorId, addressType: ADDRESSTYPE })
    }
  }

  closeModal = () => {
    this.setState({
      type: 1,
      rowDetail: {},
      addressModal: false
    })
  }

  render () {
    const { form, distributorName, onSelectAddress } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { addressList = [], type = 1, rowDetail = {}, addressModal = false } = this.state

    return (
      <>
        <div>
          <Form>
            <div className={`${styles.formitemRow}`}>
              <FormItem label={<span className={`${styles.required}`}>客户名称</span>} style={{ marginBottom: '0px' }}>{ distributorName }</FormItem>
            </div>

            <Row>
              <Col span={10}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label={<span style={{ display: 'inline-block', maxWidth: '74px' }}>收货地址</span>}>
                    {
                      getFieldDecorator('addressId', {
                        rules: [
                          { required: true, message: '请选择收货地址' }
                        ]
                      })(
                        <Select onChange={(value) => onSelectAddress(value)}>
                          {
                            addressList.map((item, i) => (
                              <Option value={item.id} key={item.id}>
                                { renderAddressLabel(item.province, item.city, item.county, item.address) } { item.name } { item.mobile }
                              </Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </div>
              </Col>
              <Col span={8}>
                <FormItem>
                  <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.showAddressModal(0, 1)}>
                    <Icon type='plus' />添加地址
                  </Button>

                  {
                    getFieldValue('addressId') !== undefined
                      ? <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.showAddressModal(getFieldValue('addressId'), 2)}>
                        <Icon type='edit' />修改地址
                      </Button>
                      : null
                  }
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>

        {
          addressModal
            ? <AddressModal
              type={type}
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.handleConfirmModifyAddress(values)}
            />
            : null
        }
      </>
    )
  }
}

const OrderUser = Form.create()(OrderUserForm)

export default OrderUser
