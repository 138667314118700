import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Statistic, Button, Icon, Modal, Badge } from 'antd'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter
const cloudPutOnMaps = [
  { type: 'ALL', label: '全部', status: '' },
  { type: 'ENABLE', label: '启用', status: 'success' },
  { type: 'DISABLE', label: '禁用', status: 'error' },
  { type: 'UNDERWAY', label: '配置中', status: 'processing' }
]

export default class CusbrandList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'enableStatus',
        formType: 'Select',
        itemParams: {
          label: '云上架'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: cloudPutOnMaps,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: 'ALL'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    distributorId: null,
    type: 1
  }

  columns = [
    {
      title: '序号',
      key: 'cusbrand-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌名称',
      key: 'cusbrand-1',
      render: (text, record, index) => (
        <div>
          <div className={`brandLabel nameCn`}>{record.brandName}</div>
          <div className={`brandLabel nameEn`}>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '产品统计',
      key: 'cusbrand-2',
      sorter: (a, b) => a.skuTotal - b.skuTotal,
      render: (text, record, index) => (
        <div>
          <Statistic value={record.skuTotal} valueStyle={{ fontSize: '14px', color: '#000000' }} />
        </div>
      )
    },
    {
      title: '云上架',
      key: 'cusbrand-3',
      render: (text, record, index) => (
        <div>{ this.renderCloudPutOnStatusLabel(record.enableStatus) }</div>
      )
    },
    {
      title: '操作',
      key: 'cusbrand-4',
      render: (text, record, index) => (
        <div>
          {
            record.enableStatus === 'UNDERWAY' ? null : <a onClick={() => this.handleLinkToProduct(record)}>查看产品</a>
          }

          {
            record.enableStatus && record.enableStatus !== 'UNDERWAY'
              ? this.renderPutShlevesBtn(record)
              : null
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      distributorId: state.distributorId,
      type: state.type
    }, () => {
      this.getCustomerPutWayBrandList()
    })
  }

  // 获取商户经营品牌
  getCustomerPutWayBrandList = () => {
    const { pages, query = {}, distributorId, type } = this.state
    let params = {
      ...query,
      distributorId: distributorId,
      type: type,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getCustomerPutWayBrandList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.dataList.list || [],
          pages: {
            ...pages,
            total: data.dataList.total || 0
          }
        })
      }
    })
  }

  // 商户推广品牌启用/禁用
  saveCustomerPutWayBrandStatus = (params) => {
    Api.saveCustomerPutWayBrandStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getCustomerPutWayBrandList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    }, () => {
      this.getCustomerPutWayBrandList()
    })
  }

  // 点击查看产品
  handleLinkToProduct = (record = {}) => {
    const { distributorId, type } = this.state
    this.props.history.push({ pathname: '/admin/cusbrandProduct', state: { brandId: record.brandId, type: type, distributorId: distributorId } })
  }

  // 点击上架/下架
  handleChangeBrandPutWayStatus = (record, status) => {
    const { distributorId } = this.state
    let params = {
      distributorId: distributorId,
      brandId: record.brandId,
      enableStatus: status
    }

    if (status === 'DISABLE') {
      confirm({
        title: '确定禁用品牌？',
        content: '如果禁用品牌，则该品牌下所有商品都将被下架',
        onOk: () => {
          this.saveCustomerPutWayBrandStatus(params)
        }
      })

      return false
    }

    this.saveCustomerPutWayBrandStatus(params)
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  // 渲染云上架状态
  renderCloudPutOnStatusLabel = (status) => {
    let obj = cloudPutOnMaps.find(item => {
      return item.type === status
    })

    return obj ? <Badge status={obj.status} text={obj.label} /> : `--`
  }

  // 渲染上下架操作按钮
  renderPutShlevesBtn = (record) => {
    return record.enableStatus === 'ENABLE'
      ? <a style={{ marginLeft: '6px' }} onClick={() => this.handleChangeBrandPutWayStatus(record, 'DISABLE')}>下架</a>
      : <a style={{ marginLeft: '6px' }} onClick={() => this.handleChangeBrandPutWayStatus(record, 'ENABLE')}>上架</a>
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCustomerPutWayBrandList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCustomerPutWayBrandList()
    })
  }

  render () {
    const { pages, searchData, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}><Icon type='left' />返回</Button>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <SearchForm {...this.porps}
                dataSource={searchData}
                handleSubmit={(search) => this.handleSearch(search)}
              />
            </Row>
            <Row>
              <Table rowKey='brandId' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>
      </>
    )
  }
}
