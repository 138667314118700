import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Button } from 'antd'
import $lodash from 'lodash'

import ToolForm from '@/components/tool-form/index'

import styles from '@/components/tool-form/tool.module.scss'

class SearchForm extends Component {
  handleSearch = e => {
    e.preventDefault()
    this.props.handleSubmit(this.props.form.getFieldsValue())
  }

  cleanForm = () => {
    const { form, handleSubmit, onResetCallBack } = this.props

    form.resetFields()

    if (onResetCallBack && $lodash.isFunction(onResetCallBack)) {
      onResetCallBack()

      return false
    }

    handleSubmit({})
  }

  render () {
    const { dataSource = [], disabledReset = false } = this.props
    let _offset = (3 - (dataSource.length % 4)) * 6

    return (
      <Form onSubmit={this.handleSearch}>
        <Row gutter={24} className={`${styles.searchRow}`}>
          <ToolForm {...this.props} />

          <Col span={6} offset={_offset} style={{ textAlign: 'right' }}>
            <Form.Item style={{ marginBottom: '10px' }}>
              <Button type='primary' onClick={(obj) => this.handleSearch(obj)}>搜索</Button>
              {
                !disabledReset
                  ? <Button style={{ marginLeft: '10px' }} onClick={() => this.cleanForm()}>重置</Button>
                  : null
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

SearchForm.propTypes = {
  form: PropTypes.object,
  dataSource: PropTypes.array,
  disabledReset: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onResetCallBack: PropTypes.func
}

export default Form.create()(SearchForm)
