import React, { Component } from 'react'
import { Card, Row, Col, Upload, Button, Icon, message } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

export default class SystemTools extends Component {
  static propTypes = {}
  state = {
    imageUrl: ''
  }

  componentDidMount () {}

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 图片上传
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.setState({
          imageUrl: data.imageUrl
        })
      } else {
        message.error('上传失败')
      }
    }
  }

  render () {
    const { imageUrl = '' } = this.state
    return (
      <>
        <Row gutter={24} style={{ marginBottom: '12px' }}>
          <Col span={12}>
            <Card title='图片上传'
              bodyStyle={{ padding: '12px' }}
            >
              <Row style={{ marginBottom: '12px' }}>
                <Upload
                  showUploadList={false}
                  accept='image/png, image/jpg, image/jpeg'
                  headers={{ Token: getStore('token') }}
                  action={uploadOrdinaryImageUrl}
                  beforeUpload={this.beforeUpload}
                  onChange={(info) => this.handleChangeUpload(info)}
                >
                  <Button>
                    <Icon type='upload' />点击上传
                  </Button>
                </Upload>
              </Row>

              <Row>
                <div style={{ marginBottom: '2px' }}>图片路径:</div>
                <div>{imageUrl}</div>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* <div>
          <XlsxGenerator />
        </div> */}
      </>
    )
  }
}
