export const submitMatchkeysMaps = {
  'ENQUIRE_SPECIAL_PRODUCTS': { // 特价产品导入
    importType: 'MERCHANT_BARGAINS_PRODUCT_SETTING',
    matchNoColNum: 0,
    brandNameColNum: 0,
    modelColNum: 1,
    itemNoColNum: 2,
    bargainsLowestPriceColNum: 3,
    bargainsDiscountRateColNum: 4
  },
  'ENQUIRE_PRODUCTS_UPLOAD': { // 询价产品导入搜索
    importType: 'ENQUIRY',
    brandNameColNum: 0,
    modelColNum: 1,
    matchNoColNum: 2,
    itemNoColNum: 2,
    quantityColNum: 3
  },
  'UPLOAD_WORK_ORDER': { // 导入工单
    importType: 'WORK_ORDER',
    brandNameColNum: 0,
    matchNoColNum: 1,
    distributorNameColNum: 2,
    supplyPriceColNum: 3,
    quantityColNum: 4,
    deliveryPeriodDaysColNum: 5,
    prepaymentRatioColNum: 6,
    isUndertakeFreightColNum: 7,
    freightPriceColNum: 8,
    expiryDateTimeColNum: 9,
    remarkColNum: 10
  },
  'UPLOAD_SERIES': { // 导入系列
    importType: 'BRAND_SERIES',
    matchNoColNum: 0,
    seriesNameColNum: 0,
    seriesDescColNum: 1
  },
  'AUTO_QUOTATION_IMPORT': { // 询价导入价格
    importType: 'AUTO_QUOTATION_IMPORT',
    brandNameColNum: 0,
    matchNoColNum: 1,
    distributorNameColNum: 2,
    supplyPriceColNum: 3,
    quantityColNum: 4,
    deliveryPeriodDaysColNum: 5,
    prepaymentRatioColNum: 6,
    isUndertakeFreightColNum: 7,
    freightPriceColNum: 8,
    expiryDateTimeColNum: 9,
    remarkColNum: 10
  }
}
