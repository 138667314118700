import React, { Component } from 'react'
import { Card, Row, Table } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class Serviverecoed extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  state = {
    query: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '公司名称',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '操作时间'
        },
        cptParams: {
        }
      },
      {
        name: 'createName',
        formType: 'Select',
        itemParams: {
          label: '操作人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val)
          }
        },
        options: [],
        optionValue: ['loginName', 'userName']
      }
    ],
    tableData: [ ]
  }

  columns= [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      key: 'service-1',
      dataIndex: 'distributorName'
    },
    {
      title: '操作时间',
      key: 'service-2',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss ') : '----' }
        </>
      )
    },

    {
      title: '操作人',
      key: 'service-3',
      render: (text, record, index) => (
        <div>
          {record.createName ? record.createName : '----'}
        </div>
      )
    },
    {
      title: '备注',
      key: 'service-4',
      dataIndex: 'remarks'
    }
  ]

  componentDidMount () {
    this.getCustomerServiceRecordList()
  }

  // 获取商户服务配置
  getCustomerServiceRecordList=() => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getCustomerServiceRecordList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: ({
            ...pages,
            total: data.total || 0
          })
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCustomerServiceRecordList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCustomerServiceRecordList()
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 模糊搜索-操作人列表
  getMemberList = (val) => {
    if (val === '') { return false }

    let params = {
      distributorName: val,
      pageNo: 1,
      pageSize: 999
    }

    Api.getMemberList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        let _options = [
          { loginName: '', userName: '全部' }
        ]
        if (data.list && data.list.length > 0) {
          _options = _options.concat(data.list)
        }
        searchData[2].options = _options
        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search['beginDate'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.times[1]).format('YYYY-MM-DD')
      delete search.times
    }
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCustomerServiceRecordList()
    })
  }

  render () {
    const { searchData = [], pages, tableData = [] } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>
      </>
    )
  }
}
