/** 权限模块 **/
import DepartmentList from '@/pages/personnel/department/index' // 部门管理
import RoleList from '@/pages/personnel/role/index' // 角色管理
import MemberList from '@/pages/personnel/member/index' // 成员管理
import SetAuthority from '@/pages/personnel/authority/index' // 权限设置

export default [
  {
    path: '/admin/roleList',
    name: '角色管理',
    component: RoleList
  },
  {
    path: '/admin/memberList',
    name: '成员管理',
    component: MemberList
  },
  {
    path: '/admin/setAuthor',
    name: '权限管理',
    component: SetAuthority
  },
  {
    path: '/admin/departList',
    name: '部门管理',
    component: DepartmentList
  }
]
