import React, { Component } from 'react'
import { Card, Input, Col, Row, Button, message } from 'antd'
import Api from '@/common/api/index'

const { TextArea } = Input

export default class DistributionTool extends Component {
  state = {
    SendInfo: ''
  }

  // 群发
  distributionSend = () => {
    Api.distributionSend({}).then((res) => {
      const { code } = res
      if (+code === 10000) {
        message.success('发布成功')
      }
    })
  }

  render () {
    return (
      <Card>
        <Row>
          <Col span={12}>
            <TextArea rows={4} />
            <Button style={{ float: 'right', marginTop: '20px' }} type='primary' onClick={() => { this.distributionSend() }} >发布</Button>
          </Col>
        </Row>
      </Card>
    )
  }
}
