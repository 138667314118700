import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Descriptions, Button, Upload, Icon, message, Form, Input, Tooltip } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './child.module.scss'

const FormItem = Form.Item
const { uploadOrdinaryImageUrl } = UploadApi
const MAXSIZE = 5

class PaymationForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    bankInfo: {} // 银行信息
  }

  componentDidMount () {
    const { getDetail, detailInfo } = this.props

    let params = {
      purchaseOrderId: detailInfo.purchaseOrderId
    }

    getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          bankInfo: data || {}
        })
      }
    })
  }

  // 点击确定付款
  handleConfirm = () => {
    let paymentUrl = this.props.form.getFieldValue('paymentUrl')

    if (paymentUrl === undefined || paymentUrl === '') {
      return message.warning('请上传付款凭证')
    }

    const { detailInfo } = this.props
    let params = {
      purchaseOrderId: detailInfo.purchaseOrderId,
      paymentUrl: paymentUrl,
      realPaymentAmount: +detailInfo.realPaymentAmount
    }

    this.props.onConfirm(params)
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < MAXSIZE
    if (!isLt1M) {
      message.warning(`图片大小不可超过${MAXSIZE}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        if (data && data.imageUrl) {
          setTimeout(() => {
            this.props.form.setFieldsValue({
              paymentUrl: data.imageUrl || ''
            })
          }, 30)
        }
      } else {
        message.error('上传失败')
      }
    }
  }

  render () {
    const { detailInfo } = this.props
    const { getFieldDecorator } = this.props.form
    const { bankInfo = {} } = this.state

    let _bankAccount = `${bankInfo.accnbr}` + `${bankInfo.dmanbr}`

    return (
      <>
        <Modal title='转账信息' width={1000} bodyStyle={{ padding: '12px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={[
            <Button type='primary' key={`paymationBtn-0`} onClick={this.props.onCancel}>
              关闭
            </Button>,
            <Button type='primary' key='paymation-1' onClick={this.handleConfirm}>
              确定付款
            </Button>
          ]}
        >
          <Row style={{ marginBottom: '10px' }}>
            <Descriptions>
              <Descriptions.Item label='订单金额'>
                &yen;{(+detailInfo.payableAmount * 100 / 100).toFixed(2)}
              </Descriptions.Item>

              <Descriptions.Item label='应付金额'>
                &yen;{(+detailInfo.realPaymentAmount * 100 / 100).toFixed(2)}
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <div className={`${styles.tableView}`}>
            <table style={{ width: '100%' }}>
              <thead />
              <tbody>
                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>收款方</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>{bankInfo.dmanam}</div>
                  </td>
                </tr>

                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>收款账号</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>
                      {_bankAccount.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')}
                    </div>
                  </td>
                </tr>

                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>银行</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>{bankInfo.bankName}</div>
                  </td>
                </tr>

                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>开户行</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>{bankInfo.openingBank}</div>
                  </td>
                </tr>

                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>订单编号</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>{detailInfo.purchaseOrderId}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Row className={`${styles.formLand}`} style={{ marginTop: '24px' }}>
            <Form onSubmit={this.handleConfirm}>
              <Row className={`${styles.formitemContent}`}>
                <Row className={`${styles.showformitem}`}>
                  <Row className={`${styles.uploadCol}`}>
                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label={<span className={`${styles.required}`}>付款凭证</span>}>
                        <Upload showUploadList={false}
                          accept='image/png, image/jpg, image/jpeg, pdf'
                          headers={{ Token: getStore('token') }}
                          action={uploadOrdinaryImageUrl}
                          beforeUpload={this.beforeUpload}
                          onChange={(info) => this.handleChangeUpload(info)}
                        >
                          <Button><Icon type='upload' />点击上传</Button>
                        </Upload>
                      </FormItem>
                    </Row>
                  </Row>

                  <Row className={`${styles.tipsCol}`}>
                    <FormItem>
                      <Row style={{ fontSize: '12px', color: '#ff4444' }}>(注意：上传图片或PDF文件限制大小为5M)</Row>
                    </FormItem>
                  </Row>
                </Row>

                <Row className={`${styles.visibleformitem}`}>
                  <Row className={`${styles.formitemRow}`}>
                    <FormItem label='付款凭证'>
                      {
                        getFieldDecorator('paymentUrl', {
                          rules: [
                            { required: true, message: '请上传付款凭证' }
                          ]
                        })(
                          <Input disabled autoComplete='off' />
                        )
                      }
                    </FormItem>
                  </Row>
                </Row>

                {
                  this.props.form.getFieldValue('paymentUrl') !== undefined && this.props.form.getFieldValue('paymentUrl') !== ''
                    ? <Row className={`${styles.linkRow}`}>
                      <Tooltip title={this.props.form.getFieldValue('paymentUrl')}>
                        <a className={`${styles.linkWrap}`} href={this.props.form.getFieldValue('paymentUrl')} target='_blank'>
                          <span className={`${styles.leftText}`}>点击</span>
                          <span className={`${styles.linkContent}`}>{this.props.form.getFieldValue('paymentUrl')}</span>
                          <span className={`${styles.rightText}`}>查看详情</span>
                        </a>
                      </Tooltip>
                    </Row>
                    : null
                }

              </Row>
            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const PaymationModal = Form.create()(PaymationForm)

export default PaymationModal
