import React, { Component } from 'react'
import { Modal, Timeline, Empty } from 'antd'
import PropTypes from 'prop-types'

import Api from '@/common/api/index'

import moment from 'moment'
export default class onSelectInfo extends Component {
  static propTypes={
    oncloseModal: PropTypes.func,
    //  okRemarksModal: PropTypes.func,
    rowDetail: PropTypes.object
  }
  state={
    info: []
  }
  componentDidMount () {
    this.getOneAddRemarksList()
  }

  // 获取初步快递核算单备注列表
  getOneAddRemarksList=() => {
    let param = {
      orderNo: this.props.rowDetail.orderNo
    }

    Api.getOneAddRemarksList(param).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || []
        })
      }
    })
  }

  render () {
    const { info = [] } = this.state
    return (
      <div>
        <Modal width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'scroll' }}
          title='备注详情'
          visible
          onCancel={this.props.oncloseModal}
          onOk={this.props.oncloseModal}
        >
          <div>
            {
              info && info.length > 0
                ? <Timeline>
                  {
                    info.map((item, i) => (
                      <Timeline.Item key={i}>
                        {
                          item.createTime ? <div>  <span>{ moment(item.createTime).format('YYYY-MM-DD HH:ss:mm')} </span><span style={{ marginLeft: '20px' }}>{ item.remarks }  </span>     <span style={{ float: 'right', marginRight: '100px' }}>操作人：{item.createName}</span></div>
                            : <div> ---- { item.remarks }</div>
                        }
                      </Timeline.Item>
                    ))
                  }
                </Timeline>
                : <Empty />
            }
          </div>
        </Modal>
      </div>
    )
  }
}
