import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Table, Badge } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import ProgressModal from '../smsRecord/progressModal/index'
import SmsInfomodal from './info-modal' // 发送内容

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSMSTypeLabel } from '@/common/utils/mUtils'
const { pageSizeOptions, SMSTypeMaps, SMSExamineStatusMaps } = filter

export default class SMSTemplate extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state={
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}, // 检索参数
    type: 0,
    smsInfomodal: false,
    progressModal: false,
    datail: {},
    searchData: [
      {
        name: 'code',
        formType: 'Input',
        itemParams: {
          label: '模板CODE',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '模板类型'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...SMSTypeMaps
        ]
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '审核状态'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...SMSExamineStatusMaps
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '创建时间'
        },
        cptParams: {

        }
      }

    ]
  }

  columns = [
    {
      title: '序号',
      key: 'specialFlow-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '模板标题',
      key: 'specialFlow-1',
      width: 150,
      dataIndex: 'title'
    },

    {
      title: '模板名称',
      key: 'specialFlow-2',
      width: 150,
      dataIndex: 'name'
    },
    {
      title: '模板CODE',
      key: 'specialFlow-3',
      width: 150,
      dataIndex: 'code'
    },
    {
      title: '模板类型',
      key: 'specialFlow-4',
      width: 150,
      render: (text, record, index) => (
        <>{ renderSMSTypeLabel(record.type) }</>
      )
    },
    {
      title: '模板内容',
      key: 'specialFlow-5',
      dataIndex: 'name',
      width: 70,
      render: (text, record, index) => {
        return (
          <a onClick={() => { this.handleSmsinfo(record, 0) }}>点击查看</a>
        )
      }
    },
    {
      title: '申请说明',
      key: 'specialFlow-6',
      dataIndex: 'name',
      width: 70,
      render: (text, record, index) => {
        return (
          <a onClick={() => { this.handleSmsinfo(record, 1) }}>点击查看</a>
        )
      }
    },
    {
      title: '审核状态',
      key: 'specialFlow-7',
      dataIndex: 'name',
      width: 150,
      render: (text, record, index) => (
        <>{ this.renderSMSExamineStatusLabel(record.type) }</>
      )
    },
    {
      title: '失败原因',
      key: 'specialFlow-8',
      dataIndex: 'reason',
      width: 150,
      ellipsis: true
    },
    {
      title: '创建时间',
      key: 'specialFlow-9',
      width: 150,
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '操作',
      key: 'specialFlow-10',
      dataIndex: 'name',
      width: 150,
      render: (text, record, index) => {
        return (
          record.status !== 'AUDIT_ING'
            ? <><a onClick={() => { this.handleGoTemplate(1, record) }}>编辑</a></>
            : null
        )
      }
    }
  ]

  componentDidMount () {
    this.getsmsTemplate()
  }

  // 获取处理特价记录
  getsmsTemplate = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getsmsTemplate(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  getSynSmsTemplateStatus = async (params) => {
    const res = await Api.getSynSmsTemplateStatus(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        progressModal: true
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      let _times = search.times
      search['startDate'] = _times[0].format('YYYY-MM-DD')
      search['endDate'] = _times[1].format('YYYY-MM-DD')
      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getsmsTemplate()
    })
  }

  // 内容查看
  handleSmsinfo =(record, type) => {
    this.setState({
      smsInfomodal: true,
      type: type,
      datail: record
    })
  }

  handleGoTemplate = (faly, record) => {
    this.props.history.push({ pathname: '/admin/SMStemplateInfo', state: { ...record, faly } })
  }

  // 渲染短信模板审核状态
  renderSMSExamineStatusLabel = (status) => {
    const obj = SMSExamineStatusMaps.find(item => { return item.id === status })
    const el = obj !== undefined ? <Badge text={obj.name} color={obj.color} /> : null

    return el
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getsmsTemplate()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getsmsTemplate()
    })
  }

  onCancel = () => {
    this.setState({
      smsInfomodal: false,
      progressModal: false,
      datail: {}
    })
  }

  render () {
    const { searchData, pages, tableData = [], smsInfomodal = false, progressModal = false, type, datail = {} } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <div style={{ marginBottom: '15px' }}>
            <Button type='primary' onClick={() => { this.handleGoTemplate(0, {}) }}>新增模板</Button>
            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.getSynSmsTemplateStatus({})}>查询进度</Button>
          </div>

          <Table rowKey='id' bodyStyle={{ overflow: 'scroll' }}
            bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}

          />
        </Card>
        {
          smsInfomodal
            ? <SmsInfomodal
              datail={datail}
              type={type}
              onCancel={() => { this.onCancel() }}
            />
            : null
        }

        {
          progressModal
            ? <ProgressModal
              onCancel={() => this.onCancel()}
            />
            : null
        }
      </>
    )
  }
}
