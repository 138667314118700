import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Switch, InputNumber, Input, DatePicker, Button, Select, Modal } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import Api from '@/common/api/index'

import consts from '@/common/utils/consts'

const Option = Select.Option
const { confirm } = Modal
const { MAX_NUMBER } = consts

export default function TeamWorkEditTable (props) {
  const { isPreview = false, list = [], onChangeInput, onAddRow, onDeleteRow } = props
  const [customerList, setCustomerList] = useState([])

  // 模糊搜索-获取商家列表
  const getMerchantListByKeyword = async (val) => {
    if (val === '') { return false }
    const res = await Api.getMerchantListByKeyword({ distributorName: val })
    const { code, data } = res
    if (+code === 10000 && data && data.length > 0) {
      setCustomerList(data)
    }
  }

  // 点击删除
  const handleDelete = (record) => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        onDeleteRow([record])
      }
    })
  }

  const columns = [
    {
      title: '序号',
      key: 'TeamWorkOrderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'TeamWorkOrderCol-1',
      width: 150,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'TeamWorkOrderCol-2',
      width: 150,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'TeamWorkOrderCol-3',
      width: 150,
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'TeamWorkOrderCol-4',
      width: 150,
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: '报价商户',
      key: 'TeamWorkOrderCol-5',
      width: 260,
      dataIndex: 'distributorId',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.distributorName }</>
              : <Select placeholder='请搜索...' showSearch filterOption={false} style={{ width: '100%' }}
                onSearch={(val) => getMerchantListByKeyword(val)}
                onChange={(value) => onChangeInput(value, 'distributorId', index)}
              >
                {
                  customerList.map((item, n) => (
                    <Option value={item.id} title={item.distributorName} key={`customerOptions-${index}-${item.id}`}>
                      { item.distributorName }
                    </Option>
                  ))
                }

              </Select>
          }
        </>
      )
    },
    {
      title: '售价',
      key: 'TeamWorkOrderCol-6',
      width: 120,
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{+record.sellingPrice}</>
      )
    },
    {
      title: '供应价格',
      key: 'TeamWorkOrderCol-7',
      width: 120,
      dataIndex: 'supplyPrice',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>&yen;{+record.supplyPrice}</>
              : <>
                <InputNumber min={0} max={MAX_NUMBER} onChange={(value) => onChangeInput(value, 'supplyPrice', index)} />
              </>
          }
        </>
      )
    },
    {
      title: '数量',
      key: 'TeamWorkOrderCol-8',
      width: 120,
      dataIndex: 'quantity',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.quantity }</>
              : <><InputNumber min={0} max={MAX_NUMBER} onChange={(value) => onChangeInput(value, 'quantity', index)} /></>
          }
        </>
      )
    },
    {
      title: '实际货期',
      key: 'TeamWorkOrderCol-9',
      width: 120,
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ +record.deliveryPeriodDays > 0 ? `${+record.deliveryPeriodDays}工作日` : '现货' }</>
              : <><InputNumber min={0} max={MAX_NUMBER} onChange={(value) => onChangeInput(value, 'deliveryPeriodDays', index)} /></>
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'TeamWorkOrderCol-10',
      width: 120,
      dataIndex: 'advancePaymentRate',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.advancePaymentRate ? `${(+record.advancePaymentRate * 100 / 100).toFixed(2)}%` : null }</>
              : <>
                <InputNumber min={0} max={MAX_NUMBER} onChange={(value) => onChangeInput(value, 'advancePaymentRate', index)} />
              </>
          }
        </>
      )
    },
    {
      title: '是否承担运费',
      key: 'TeamWorkOrderCol-11',
      width: 100,
      dataIndex: 'isUndertakeFreight',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.isUndertakeFreight === 'UNDERTAKE' ? '是' : '否' }</>
              : <Switch checked={record.isUndertakeFreight === 'UNDERTAKE'} onChange={(value) => onChangeInput(value ? 'UNDERTAKE' : 'UN_UNDERTAKE', 'isUndertakeFreight', index)} />
          }
        </>
      )
    },
    {
      title: '运费金额',
      key: 'TeamWorkOrderCol-12',
      width: 120,
      dataIndex: 'freightPrice',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>&yen;{ (+record.freightPrice * 100 / 100).toFixed(2) }</>
              : <>
                <InputNumber min={0} max={MAX_NUMBER}
                  disabled={record.isUndertakeFreight === 'UNDERTAKE'}
                  onChange={(value) => onChangeInput(value, 'freightPrice', index)}
                />
              </>
          }
        </>
      )
    },
    {
      title: '报价有效期',
      key: 'TeamWorkOrderCol-13',
      width: 220,
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.expiryDateTime ? moment(record.expiryDateTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
              : <>
                <DatePicker showTime onOk={(value) => onChangeInput(moment(value).format('YYYY-MM-DD HH:mm:ss'), 'expiryDateTime', index)} />
              </>
          }
        </>
      )
    },
    {
      title: '备注',
      key: 'TeamWorkOrderCol-14',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.remarks }</>
              : <Input autoComplete='off' max={200} onChange={(e) => onChangeInput(e.target.value, 'remarks', index)} />
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'TeamWorkOrderCol-15',
      width: 120,
      fixed: 'right',
      render: (text, record, index) => {
        return (
          <>
            <Button type='link' size='small' onClick={() => onAddRow(index)}>添加</Button>

            {
              record.editable && $lodash.includes(record.rowKey, 'customize')
                ? <Button type='link' size='small' disabled={list.length < 2} onClick={() => handleDelete(record)}>删除</Button>
                : null
            }
          </>
        )
      }
    }
  ]

  return (
    <>
      <Table rowKey='rowKey' bordered
        columns={isPreview ? $lodash.pullAllBy(columns, [{ key: 'TeamWorkOrderCol-15' }], 'key') : columns}
        dataSource={list}
        pagination={false}
        scroll={{ x: 2400 }}
      />
    </>
  )
}

TeamWorkEditTable.propTypes = {
  isPreview: PropTypes.bool,
  list: PropTypes.array,
  onChangeInput: PropTypes.func,
  onAddRow: PropTypes.func,
  onDeleteRow: PropTypes.func
}
