import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Result } from 'antd'

class ProgressModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () {

  }

  render () {
    return (
      <Modal title='进度查询' visible
        maskClosable={false}
        onCancel={() => this.props.onCancel()}
        footer={null}
      >
        <Result status='success'
          title='成功!'
        />
      </Modal>
    )
  }
}

export default ProgressModal
