import React from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Icon, Dropdown, Menu } from 'antd'
import $lodash from 'lodash'

import DownloadApi from '@/common/api/downloadApi'

const { downloadHelpListQuotationBaseDetails } = DownloadApi

export default function TeamWorkContents (props) {
  const { isPreview, list = [] } = props

  // 点击下载数据
  const handleDownloadBaseDetails = (type) => {
    if (list.length === 0) { return false }
    const newdatas = $lodash.assign([], list)

    const baseDetails = $lodash.map(newdatas, (row, index) => {
      return {
        order: index + 1,
        ...row
      }
    })

    const params = {
      fileType: type,
      excelTemplateType: 'ENQUIRY_WORK_COLLABORATE_LIST',
      dataJsonString: JSON.stringify(baseDetails)
    }

    downloadHelpListQuotationBaseDetails(params)
  }

  const dropdownMenus = (
    <Menu>
      <Menu.Item>
        <a onClick={() => handleDownloadBaseDetails('EXCEL')}>
          <Icon type='download' /><span style={{ marginLeft: '5px' }}>导出excel</span>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => handleDownloadBaseDetails('TEXT')}>
          <Icon type='download' /><span style={{ marginLeft: '5px' }}>导出txt</span>
        </a>
      </Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: '序号',
      key: 'TeamWorkContentsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'TeamWorkContentsCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'TeamWorkContentsCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'TeamWorkContentsCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'TeamWorkContentsCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'TeamWorkContentsCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'TeamWorkContentsCol-6',
      dataIndex: 'quantity'
    },
    {
      title: '期望价格',
      key: 'TeamWorkContentsCol-7',
      dataIndex: 'expectPrice',
      render: (text, record, index) => (
        <>{ record.expectPrice ? <>&yen;{+record.expectPrice}</> : null }</>
      )
    },
    {
      title: '期望折扣',
      key: 'TeamWorkContentsCol-8',
      dataIndex: 'expectDiscount',
      render: (text, record, index) => (
        <>{record.expectDiscount ? <>{(+record.expectDiscount * 100 / 100).toFixed(2)}%</> : null}</>
      )
    },
    {
      title: '期望货期',
      key: 'TeamWorkContentsCol-9',
      dataIndex: 'expectDeliveryPeriodDays',
      render: (text, record, index) => (
        <>
          { +record.expectDeliveryPeriodDays > 0 ? `${+record.expectDeliveryPeriodDays}工作日` : '现货' }
        </>
      )
    }
  ]

  return (
    <div style={{ marginBottom: '24px' }}>
      <div>
        <Table rowKey='id' bordered
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
      {
        !isPreview
          ? <div style={{ marginTop: '15px' }}>
            <Dropdown disabled={list.length === 0} overlay={dropdownMenus}>
              <Button type='primary'>导出数据<Icon type='down' /></Button>
            </Dropdown>
          </div>
          : null
      }

    </div>
  )
}

TeamWorkContents.propTypes = {
  isPreview: PropTypes.bool,
  list: PropTypes.array
}
