import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Icon } from 'antd'

import BindedSkuTable from './bindedSkuTable/index'
import CanBindSkuTable from './canbindSkuTable/index'

// 关联产品
export default class BindSeriesSku extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    showPage: false,
    detailInfo: {}, // 关联的信息
    updateCanBindCount: 0,
    updateBindCount: 0
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    if (state) {
      this.setState({
        detailInfo: state,
        showPage: true
      })
    }
  }

  // 点击返回
  handleBack = () => {
    this.setState({
      detailInfo: {}
    }, () => {
      this.props.history.go(-1)
    })
  }

  handleUpdateCanBindSkuTable = () => {
    const { updateCanBindCount = 0 } = this.state

    this.setState({
      updateCanBindCount: updateCanBindCount + 1
    })
  }

  handleUpdateBindSkuTable = () => {
    const { updateBindCount = 0 } = this.state

    this.setState({
      updateBindCount: updateBindCount + 1
    })
  }

  render () {
    const { showPage = false, detailInfo = {}, updateCanBindCount = 0, updateBindCount = 0 } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }} bordered={false}>
            <Button onClick={() => this.handleBack()}><Icon type='arrow-left' />返回</Button>
            <span style={{ marginLeft: '24px', fontSize: '16px', color: '#333333' }}>
              <label className={`label`}>所属系列</label><span>{ detailInfo.name }</span>
            </span>
          </Card>
        </div>

        {
          showPage
            ? <>
              <BindedSkuTable detailInfo={detailInfo} updateBindCount={updateBindCount} onRelieveSuccss={() => this.handleUpdateCanBindSkuTable()} />

              <CanBindSkuTable detailInfo={detailInfo} updateCanBindCount={updateCanBindCount} onBindSuccess={() => this.handleUpdateBindSkuTable()} />
            </>
            : null
        }
      </>
    )
  }
}
