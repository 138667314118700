import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Form, Descriptions, Upload, Input, InputNumber, Select, Radio, Row, Col, Button, Icon, message, DatePicker } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'
import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore, getCourierName, renderAddressLabel, renderSaledTypeLabel, renderSaledReasonTypeLabel, renderSaledRefundTypeLabel, renderSaledBackGoodsTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
// import SaasCourierJson from '@/assets/json/saasCourier.json'
import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const Option = Select.Option
const { RangePicker } = DatePicker

const { uploadOrdinaryImageUrl } = UploadApi
const { REMARKS_MAX_NUM, MAX_NUMBER } = consts
const { saasCouierCodeMaps, expressTypeMaps, expressReceiptFlags, expressReceiptReturnRequirements, expressPackWoodenFrames } = filter
const UPLOAD_MAX_NUM = 9

class DrawerContents extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onSelectedReplacementAddress: PropTypes.func
  }
  state = {
    receivingAddressList: [], // 平台收件地址列表
    info: {},
    pickUpReceiveAddress: {}, // 换货、补寄收件地址
    tableData: [],
    uploadImageUrls: [], // 上传的图片
    addressModal: false, // 地址弹窗
    addressType: null, // 地址类型
    type: 1, // 1-新增 2-修改
    addressInfo: {}
  }
  columns = [
    {
      title: '序号',
      key: 'purchaseProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '型号',
      key: 'purchaseProductCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'purchaseProductCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'purchaseProductCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'purchaseProductCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'purchaseProductCol-6',
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'purchaseProductCol-7',
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{+record.sellingPrice }</>
      )
    },
    {
      title: '含税单品小计',
      key: 'purchaseProductCol-8',
      dataIndex: '_totalPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.quantity * +record.sellingPrice, 2) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {
    const { detailInfo = {} } = this.props
    if (['WAITING_BACK', 'WAITING_DELIVER'].includes(detailInfo.status)) { // 待退货 && 待发货 需获取平台发货地址列表
      this.getAddressList()
    }

    this.getPurchaseOrderSaledDetails()
  }

  // 获取平台收货\发货地址列表
  getAddressList = async () => {
    const res = await Api.getAddressList({ adressType: 'PLATFORM_SENDER_ADDRESS', bindData: MAX_NUMBER })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        receivingAddressList: data || []
      })
    }
  }

  // 保存平台新增地址
  saveAddAddress = async (values) => {
    const res = await Api.saveAddAddress({ ...values, addressType: 'PLATFORM_SENDER_ADDRESS', bindData: MAX_NUMBER })
    const { code } = res
    if (+code === 10000) {
      this.closeModal()
      this.getAddressList()
    }
  }

  // 保存云采购售后修改换货、补寄收货地址
  savePurchaseOrderSaledReceiveAddress = async (params) => {
    const res = await Api.savePurchaseOrderSaledReceiveAddress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderSaledDetails()
    }
  }

  // 获取云采购订单售后详情
  getPurchaseOrderSaledDetails = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getPurchaseOrderSaledDetails({ id: detailInfo.id })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data || {},
        pickUpReceiveAddress: data && data.pickUpReceiveAddress && data.pickUpReceiveAddress.province ? data.pickUpReceiveAddress : data.pickUpSendAddress,
        tableData: data.afterSaleOrderGoods || []
      })
    }
  }

  // 渲染快递方式选项
  renderTransportTypeOptions = (courierCode) => {
    const newArr = expressTypeMaps[courierCode]

    return (
      <Select>
        {
          newArr.map((item) => (
            <Option value={item.id} key={item.id}>{ item.name }</Option>
          ))
        }
      </Select>
    )
  }

  // 上传图片
  handleChangeUpload = (info = {}) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        const { uploadImageUrls = [] } = this.state

        uploadImageUrls.push(data.imageUrl)

        this.setState({
          uploadImageUrls: [...uploadImageUrls]
        }, () => {
          this.props.form.setFieldsValue({ uploadImageUrls: uploadImageUrls.join(',') })
        })
      } else {
        message.warning('上传失败')
      }
    }
  }

  // 删除图片
  handleRemoveUpload = (n) => {
    const { uploadImageUrls = [] } = this.state

    uploadImageUrls.splice(n, 1)

    this.setState({
      uploadImageUrls: [...uploadImageUrls]
    }, () => {
      this.props.form.setFieldsValue({ uploadImageUrls: uploadImageUrls.join(',') })
    })
  }

  // 选择快递服务
  handleCheckedLogisticsWatEnum = (value) => {
    const Enums = value.split('-')

    this.props.form.setFieldsValue({ courierCode: Enums[1], transportType: null })
  }

  // 选择地址
  handleChangeReplacementAddress = (value) => {
    const { receivingAddressList = [] } = this.state
    const obj = receivingAddressList.find((item) => { return +value === +item.id })

    this.props.onSelectedReplacementAddress(obj)
  }

  // 点击新增地址
  showAddressModal = (record, type, addressType) => {
    this.setState({
      addressInfo: record,
      type: type,
      addressType: addressType,
      addressModal: true
    })
  }

  // 确定修改地址
  confirmMofifyAddress = (values) => {
    const { info = {}, type = 1, addressType } = this.state

    if (type === 1) {
      this.saveAddAddress(values)
    }

    if (type === 2) {
      const params = {
        id: info.id,
        addressInfo: values,
        afterSaleOrderAddressType: addressType
      }
      this.savePurchaseOrderSaledReceiveAddress(params)
    }
  }

  closeModal = () => {
    this.setState({
      addressInfo: {},
      addressType: null,
      type: 1,
      addressModal: false
    })
  }

  render () {
    const { form, detailInfo = {} } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { info = {}, pickUpReceiveAddress = {}, tableData = [], uploadImageUrls = [], receivingAddressList = [], addressModal = false, type = 1, addressInfo = {} } = this.state
    const { pickUpSendAddress = {}, imageUrls = [] } = info

    return (
      <>
        <Descriptions>
          <Descriptions.Item label='订单编号'>{ info.orderId }</Descriptions.Item>
          <Descriptions.Item label='订单日期'>{ info.orderTime ? moment(info.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</Descriptions.Item>
          <Descriptions.Item label='商户名称'>{ info.distributorName }</Descriptions.Item>
          <Descriptions.Item label='管理员姓名'>{ info.adminUser }</Descriptions.Item>
          <Descriptions.Item label='管理员联系方式'>{ info.adminMobile }</Descriptions.Item>
        </Descriptions>

        <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />

        <div style={{ marginTop: '8px' }}>
          <div className={`${styles.formitemRow}`}>
            <FormItem label='售后类型' style={{ marginBottom: '0px' }}>{ renderSaledTypeLabel(info.type) }</FormItem>
          </div>

          <div className={`${styles.formitemRow}`}>
            <FormItem label='售后原因' style={{ marginBottom: '0px' }}>{ renderSaledReasonTypeLabel(info.reasonType) }</FormItem>
          </div>

          <div className={`${styles.formitemRow}`}>
            <FormItem label='详细描述' style={{ marginBottom: '0px' }}>
              <p style={{ marginBottom: '0px', wordBreak: 'break-all' }}>{ info.remark }</p>
            </FormItem>
          </div>

          <FormItem label='可添加图片来帮助售后人员判断(最多9张)' style={{ marginBottom: '0px' }}>
            {
              imageUrls && imageUrls.length > 0
                ? <>
                  {
                    imageUrls.map((item, index) => (
                      <div className={`${styles.pictureWrap}`} key={`purchaseSaledPictureCol-${index}`}>
                        <UploadThumbnail imageUrl={item} />
                      </div>
                    ))
                  }
                </>
                : null
            }
          </FormItem>

          {
            ['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS'].includes(info.type)
              ? <>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='退货方式' style={{ marginBottom: '0px' }}>
                    { renderSaledBackGoodsTypeLabel(info.backGoodsType) }
                  </FormItem>
                </div>

                {
                  info.backGoodsType === 'IIASAAS'
                    ? <Row>
                      <Col span={16}>
                        <div className={`${styles.formitemRow}`}>
                          <FormItem label='取件地址' style={{ marginBottom: '0px' }}>
                            {
                              pickUpSendAddress && pickUpSendAddress.province
                                ? <>{ renderAddressLabel(pickUpSendAddress.province, pickUpSendAddress.city, pickUpSendAddress.county, pickUpSendAddress.address) } { pickUpSendAddress.name } { pickUpSendAddress.mobile } </>
                                : null
                            }
                          </FormItem>
                        </div>
                      </Col>

                      {
                        ['WAITING_BACK'].includes(info.status)
                          ? <Col span={4}>
                            <FormItem>
                              <Button type='link' size='small' onClick={() => this.showAddressModal(pickUpSendAddress, 2, 'PICK_UP')}>修改地址</Button>
                            </FormItem>
                          </Col>
                          : null
                      }
                    </Row>
                    : null
                }

              </>
              : null
          }

          {
            ['CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(info.type) && info.status === 'WAITING_DELIVER'
              ? <Row>
                <Col span={16}>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='换货、补寄收件地址' style={{ marginBottom: '0px' }}>
                      {
                        pickUpReceiveAddress && pickUpReceiveAddress.province
                          ? <>{renderAddressLabel(pickUpReceiveAddress.province, pickUpReceiveAddress.city, pickUpReceiveAddress.county, pickUpReceiveAddress.address)} {pickUpReceiveAddress.name} { pickUpReceiveAddress.mobile }</>
                          : null
                      }
                    </FormItem>
                  </div>
                </Col>
                <Col span={4}>
                  <FormItem>
                    <Button type='link' size='small' onClick={() => this.showAddressModal(pickUpReceiveAddress || {}, 2, 'REPLACE')}>修改地址</Button>
                  </FormItem>
                </Col>
              </Row>
              : null
          }

          {
            ['REFUND_RETURN_PRODUCTS', 'REFUND'].includes(info.type)
              ? <>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='退款方式' style={{ marginBottom: '0px' }}>{ renderSaledRefundTypeLabel(info.refundType) }</FormItem>
                </div>

                <div className={`${styles.formitemRow}`}>
                  <FormItem label='预估退款金额' style={{ marginBottom: '0px' }}>&yen;{ ($lodash.round(+info.backAmount, 2) * 100 / 100).toFixed(2)}</FormItem>
                </div>
              </>
              : null
          }

          <Row>
            <Col span={12}>
              <div className={`${styles.formitemRow}`}>
                <FormItem label='客服备注'>
                  {
                    getFieldDecorator('backRemark', {

                    })(
                      <TextArea rows={4}
                        placeholder={`请输入内容，不超过${REMARKS_MAX_NUM}字...`}
                        autoComplete='off'
                        maxLength={REMARKS_MAX_NUM}
                      />
                    )
                  }
                </FormItem>
              </div>
            </Col>
          </Row>

          {
            ['WAITING_AUDIT', 'WAITING_REFUND'].includes(info.status)
              ? <Row>
                <FormItem style={{ display: 'none' }}>
                  {
                    getFieldDecorator('uploadImageUrls', {

                    })(<Input disabled autoComplete='off' />)
                  }
                </FormItem>
                <FormItem label={detailInfo.status === 'WAITING_REFUND' ? '上传退款凭证' : '客服上传图片说明(最多9张)'}>
                  {
                    uploadImageUrls.length > 0
                      ? <>
                        {
                          uploadImageUrls.map((item, index) => (
                            <div className={`${styles.pictureWrap}`} key={`uploadSaledPictureCol-${index}`}>
                              <UploadThumbnail imageUrl={item} onDelete={() => this.handleRemoveUpload(index)} />
                            </div>
                          ))
                        }
                      </>
                      : null
                  }

                  {
                    uploadImageUrls.length < UPLOAD_MAX_NUM
                      ? <div className={`${styles.pictureWrap}`}>
                        <Upload name='file'
                          listType='picture-card'
                          accept='image/png, image/jpg, image/jpeg'
                          showUploadList={false}
                          action={uploadOrdinaryImageUrl}
                          headers={{ Token: getStore('token') }}
                          beforeUpload={this.beforeUpload}
                          onChange={(info) => this.handleChangeUpload(info)}
                        >
                          <div>
                            <Icon type='plus' />
                            <div>点击上传</div>
                          </div>
                        </Upload>
                      </div>
                      : null
                  }
                </FormItem>
              </Row>
              : null
          }

          {
            ['WAITING_BACK', 'WAITING_DELIVER'].includes(info.status)
              ? <>
                <Row>
                  <Col span={16}>
                    <div className={`${styles.formitemRow}`}>
                      <FormItem label='快递服务'>
                        {
                          getFieldDecorator('logisticsWayEnum', {
                            initialValue: 'SAAS-DBL',
                            rules: [
                              { required: true, message: '请选择快递服务' }
                            ]
                          })(
                            <Radio.Group onChange={(e) => this.handleCheckedLogisticsWatEnum(e.target.value)}>
                              {
                                saasCouierCodeMaps.map((item, index) => (
                                  <Radio value={item.id} key={item.id}>{ item.courierName }</Radio>
                                ))
                              }
                            </Radio.Group>
                          )
                        }
                      </FormItem>
                    </div>

                    <div className={`${styles.formitemRow}`}>
                      <FormItem style={{ display: 'none' }}>
                        {
                          getFieldDecorator('courierCode', {
                            initialValue: 'DBL'
                          })(<Input disabled autoComplete='off' />)
                        }
                      </FormItem>
                      <FormItem label='快递公司'>
                        { getCourierName(getFieldValue('logisticsWayEnum').split('-')[1]) }
                      </FormItem>
                    </div>

                    <div className={`${styles.formitemRow}`}>
                      <FormItem label='快递方式'>
                        {
                          getFieldDecorator('transportType', {
                            rules: [
                              { required: true, message: '请选择快递方式' }
                            ]
                          })(
                            this.renderTransportTypeOptions(getFieldValue('logisticsWayEnum'))
                          )
                        }
                      </FormItem>
                    </div>

                    <div className={`${styles.formitemRow}`}>
                      <FormItem label='包裹数量'>
                        {
                          getFieldDecorator('totalNumber', {
                            rules: [
                              { required: true, message: '请填写包裹数量' }
                            ]
                          })(
                            <InputNumber min={1} max={MAX_NUMBER} />
                          )
                        }
                      </FormItem>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={16}>
                    <div className={`${styles.formitemRow}`}>
                      <FormItem label={detailInfo.status === 'WAITING_DELIVER' ? '发货地址' : '收件地址'}>
                        {
                          getFieldDecorator('replacementAddressId', {
                            rules: [
                              { required: true, message: `请选择${detailInfo.status === 'WAITING_DELIVER' ? '发货地址' : '收件地址'}` }
                            ]
                          })(
                            <Select onChange={(value) => this.handleChangeReplacementAddress(value)}>
                              {
                                receivingAddressList.map((item, i) => (
                                  <Option value={item.id} key={`replacementAddressOpt-${item.id}`}>
                                    { renderAddressLabel(item.province, item.city, item.county, item.address) } { item.name } { item.mobile }
                                  </Option>
                                ))
                              }
                            </Select>
                          )
                        }
                      </FormItem>
                    </div>
                  </Col>
                  <Col span={4}>
                    <FormItem>
                      <Button type='link' size='small' onClick={() => this.showAddressModal({}, 1, detailInfo.status === 'WAITING_DELIVER' ? 'IIASAAS_SEND' : 'IIASAAS_RECEIVE')}>
                        新增地址
                      </Button>
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <div className={`${styles.formitemRow}`}>
                      <FormItem label='付费类型'>
                        {
                          getFieldDecorator('logisticsPayType', {
                            rules: [
                              { required: true, message: '请选择付费类型' }
                            ]
                          })(
                            <Radio.Group buttonStyle='solid'>
                              <Radio.Button value='MONTHLY_STATEMENT'>包邮</Radio.Button>
                              <Radio.Button value='FREIGHT_COLLECT'>到付</Radio.Button>
                            </Radio.Group>
                          )
                        }
                      </FormItem>
                    </div>
                  </Col>
                </Row>

                {
                  ['SAAS-DBL', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                    ? <Row>
                      <Col span={12}>
                        <div className={`${styles.formitemRow}`}>
                          <FormItem label='保价金额'>
                            {
                              getFieldDecorator('insuranceValue', {
                                rules: [
                                  { required: false, message: '请填写保价金额' }
                                ]
                              })(
                                <InputNumber formatter={value => `￥ ${value}`} style={{ width: '120px' }} />
                              )
                            }
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    : null
                }

                {
                  ['SAAS-DBL', 'SAAS-SF', 'SAAS-JD', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                    ? <Row>
                      <Col span={12}>
                        <Row className={`${styles.formitemRow}`}>
                          <FormItem label='签单返还'>
                            {
                              getFieldDecorator('receiptType', {
                                initialValue: expressReceiptFlags[getFieldValue('logisticsWayEnum')][0].id,
                                rules: [
                                  { required: true, message: '请选择签单返还' }
                                ]
                              })(
                                <Select>
                                  {
                                    expressReceiptFlags[getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                                      <Option value={receiOpt.id} key={`JDRECEIPT-${i}`}>{ receiOpt.name }</Option>
                                    ))
                                  }
                                </Select>
                              )
                            }
                          </FormItem>
                        </Row>
                      </Col>
                    </Row>
                    : null
                }

                {
                  ['SAAS-DBL'].includes(getFieldValue('logisticsWayEnum')) && ['DEPPON_1', 'DEPPON_2'].includes(getFieldValue('receiptType'))
                    ? <Row>
                      <Col span={12}>
                        <div className={`${styles.formitemRow}`}>
                          <FormItem label='签收回单返单类型'>
                            {
                              getFieldDecorator('returnRequirement', {
                                rules: [
                                  { required: true, message: '请选择签收回单返单类型' }
                                ]
                              })(
                                <Select>
                                  {
                                    expressReceiptReturnRequirements[getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                                      <Option value={receiOpt.id} key={`returnRequirement-${i}`}>{ receiOpt.name }</Option>
                                    ))
                                  }
                                </Select>
                              )
                            }
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    : null
                }

                { // 木架方式
                  ['SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                    ? <Row>
                      <Col span={12}>
                        <div className={`${styles.formitemRow}`}>
                          <FormItem label='木架方式'>
                            {
                              getFieldDecorator('woodenFrame', {
                                initialValue: '0',
                                rules: [
                                  { required: true, message: '请选择木架方式' }
                                ]
                              })(
                                <Select>
                                  {
                                    expressPackWoodenFrames[getFieldValue('logisticsWayEnum')].map((woodOpt, i) => (
                                      <Option value={woodOpt.id} key={`woodenFrameOpt-${i}`}>{ woodOpt.name }</Option>
                                    ))
                                  }
                                </Select>
                              )
                            }
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    : null
                }

                {
                  ['SAAS-DBL'].includes(getFieldValue('logisticsWayEnum'))
                    ? <Row>
                      <Col span={12}>
                        <div className={`${styles.formitemRow}`}>
                          <FormItem label='上门接货期限'>
                            {
                              getFieldDecorator('pickUpTimes', {

                              })(
                                <RangePicker showTime />
                              )
                            }
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    : null
                }

                {
                  ['SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                    ? <Row>
                      <Col span={12}>
                        <div className={`${styles.formitemRow}`}>
                          <FormItem label='预计重量'>
                            {
                              getFieldDecorator('actualWeight', {

                              })(
                                <InputNumber style={{ width: '120px' }} />
                              )
                            }
                            <span style={{ marginLeft: '8px' }}>kg</span>
                            <span className={'redColor'} style={{ marginLeft: '8px' }}>此项会影响调配车辆，请认真估计</span>
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    : null
                }

                {
                  ['SAAS-DBL', 'SAAS-SF', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                    ? <Row>
                      <Col span={12}>
                        <div className={`${styles.formitemRow}`}>
                          <FormItem label='备注'>
                            {
                              getFieldDecorator('waybillRemark', {

                              })(
                                <TextArea placeholder={`请输入内容，不超过${['SAAS-DBL', 'SAAS-SF'].includes(getFieldValue('logisticsWayEnum')) ? 100 : 200}字...`}
                                  rows={4}
                                  maxLength={['SAAS-DBL', 'SAAS-SF'].includes(getFieldValue('logisticsWayEnum')) ? 100 : 200}
                                />
                              )
                            }
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    : null
                }
              </>
              : null
          }
        </div>

        {
          addressModal
            ? <AddressModal
              detailInfo={addressInfo}
              type={type}
              onConfirm={(values) => this.confirmMofifyAddress(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default DrawerContents
