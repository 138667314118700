import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Button } from 'antd'

import { renderAddressLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option

class InvoiceReceivingForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    isEdit: PropTypes.bool,
    addressList: PropTypes.array,
    sendAddressList: PropTypes.array, // 发货地址列表
    receivingAddressUpdate: PropTypes.bool, // 收件地址可更新
    receivingAddress: PropTypes.object, // 收件地址信息
    handleAddAddress: PropTypes.func
  }
  state = {

  }

  render () {
    const { form, isEdit = true, addressList = [], sendAddressList = [], receivingAddressUpdate = false, receivingAddress = {}, handleAddAddress } = this.props
    const { getFieldDecorator, getFieldValue } = form

    return (
      <>
        {
          isEdit
            ? <>
              <div className={`${styles.formitemRow}`}>
                <FormItem label='收票地址'>
                  {
                    addressList.length > 0
                      ? <div style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                        {
                          getFieldDecorator('invoiceAddressId', {
                            rules: [
                              { required: getFieldValue('logisticsWayEnum') === 'SAAS', message: '请选择收票地址' }
                            ]
                          })(
                            <Select placeholder='请选择...'
                              showSearch
                              optionFilterProp='children'
                            >
                              {
                                addressList.map((opt, i) => (
                                  <Option value={opt.id} key={opt.id}>
                                    { renderAddressLabel(opt.province, opt.city, opt.county, opt.address) } {opt.name} {opt.mobile}
                                  </Option>
                                ))
                              }
                            </Select>
                          )
                        }
                      </div>
                      : <div style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>暂无收票地址，可点击右侧新增地址</div>
                  }

                  <div style={{ display: 'inline-block', width: '120px', textAlign: 'right' }}>
                    <Button type='link' onClick={() => handleAddAddress()}>新增地址</Button>
                  </div>
                </FormItem>
              </div>

              {
                getFieldValue('logisticsWayEnum') === 'SAAS'
                  ? <div className={`${styles.formitemRow}`}>
                    <FormItem label='发货地址'>
                      {
                        getFieldDecorator('sendAddressId', {
                          rules: [
                            { required: true, message: '请选择发货地址' }
                          ]
                        })(
                          <Select placeholder='请选择...'>
                            {
                              sendAddressList.map((sopt, n) => (
                                <Option value={sopt.id} key={`sendAddressOpt-${sopt.id}`}>
                                  { renderAddressLabel(sopt.province, sopt.city, sopt.county, sopt.address) } {sopt.name} {sopt.mobile}
                                </Option>
                              ))
                            }
                          </Select>
                        )
                      }
                    </FormItem>
                  </div>
                  : null
              }
            </>
            : <>
              <div className={`${styles.formitemRow}`}>
                <FormItem label='收票地址' style={{ marginBottom: '0px' }}>
                  <div style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                    { renderAddressLabel(receivingAddress.province, receivingAddress.city, receivingAddress.county, receivingAddress.address) } {receivingAddress.name} {receivingAddress.mobile}
                  </div>

                  {
                    receivingAddressUpdate
                      ? <div style={{ display: 'inline-block', width: '120px', textAlign: 'right' }}>
                        <Button type='link' onClick={() => handleAddAddress()}>修改地址</Button>
                      </div>
                      : null
                  }
                </FormItem>
              </div>
            </>
        }
      </>
    )
  }
}

const InvoiceReceiving = Form.create()(InvoiceReceivingForm)

export default InvoiceReceiving
