import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Tabs } from 'antd'

import SysnoticeList from './sysnoiceList'
import SysnoticesendFlow from './sysnoticesendFlow'

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabMaps = [
  { id: 'SYSTEMNOTICE', name: '系统通知列表' },
  { id: 'SYSNOTICEFLOW', name: '系统通知发送历史' }
]

class NotifyList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    activeTab: 'SYSTEMNOTICE'
  }

  componentDidMount () {

  }

  // 点击切换
  handleChangeTabPane = (key) => {
    this.setState({
      activeTab: key
    })
  }

  render () {
    const { activeTab = 'SYSTEMNOTICE' } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <Row className={`${styles.pageTabLand}`}>
              <Tabs onChange={this.handleChangeTabPane}>
                {
                  tabMaps.map((item) => (
                    <TabPane tab={item.name} key={item.id} />
                  ))
                }
              </Tabs>
            </Row>
          </Card>
        </Row>

        <Row>
          {
            activeTab === 'SYSTEMNOTICE'
              ? <SysnoticeList history={this.props.history} />
              : null
          }

          {
            activeTab === 'SYSNOTICEFLOW'
              ? <SysnoticesendFlow history={this.props.history} />
              : null
          }
        </Row>
      </div>
    )
  }
}

export default NotifyList
