import productApi from './productApi'
import userApi from './userApi'
import cloudstoreApi from './cloudstoreApi'
import customerApi from './customerApi'
import personnelApi from './personnelApi'
import systemApi from './systemApi'
import orderApi from './orderApi'
import financeApi from './financeApi'
import contractApi from './contractApi'
import businessApi from './businessApi'
import payApi from './payApi'
import notifysApi from './notifysApi'
import profitsetApi from './profitsetApi'
import operateApi from './operateApi'
import expressApi from './expressApi'
import enquireApi from './enquireApi'
import workorderApi from './workorderApi'
import matchApi from './matchApi'
import warehouseApi from './warehouseApi'
import invoiceApi from './invoiceApi'
import saledApi from './saledApi'
import domainManageApi from './domainManageApi'
import purposeApi from './purposeApi'
import dashboardApi from './dashboardApi'
import earlywarningApi from './earlywarningApi'
import hotBrandApi from './hotBrandApi'
import erpManageApi from './erpManageApi'

export default {
  ...userApi,
  ...productApi,
  ...cloudstoreApi,
  ...customerApi,
  ...personnelApi,
  ...systemApi,
  ...orderApi,
  ...financeApi,
  ...contractApi,
  ...businessApi,
  ...payApi,
  ...notifysApi,
  ...profitsetApi,
  ...operateApi,
  ...expressApi,
  ...enquireApi,
  ...workorderApi,
  ...matchApi,
  ...warehouseApi,
  ...invoiceApi,
  ...saledApi,
  ...domainManageApi,
  ...purposeApi,
  ...dashboardApi,
  ...earlywarningApi,
  ...hotBrandApi,
  ...erpManageApi
}
