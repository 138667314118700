import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Button } from 'antd'

import DrawerContents from '../drawerContents/index'

import styles from './index.module.scss'

export default class SupplyDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  render () {
    const { visible = false, detailInfo = {}, onCancel } = this.props

    return (
      <Drawer title={<><span>售后申请</span><span style={{ marginLeft: '16px' }}>售后单号：{detailInfo.id }</span></>}
        width={1300} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        destroyOnClose
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        {
          visible
            ? <DrawerContents detailInfo={detailInfo} />
            : null
        }

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>
        </div>
      </Drawer>
    )
  }
}
