import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, InputNumber } from 'antd'
import $lodash from 'lodash'

const FormItem = Form.Item

class SettingForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    activeDepartId: PropTypes.string,
    activeChildren: PropTypes.array,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  handleConfirm = () => {
    const { form, activeChildren = [], onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const targetList = $lodash.map(values, (value, key) => {
          const id = key.split('-')[1]
          const obj = $lodash.find(activeChildren, (o) => { return +o.id === +id })

          return {
            ...obj,
            targetValue: value
          }
        })

        onConfirm({ targetList: targetList })
      }
    })
  }

  render () {
    const { form, activeDepartId, activeChildren = [], onCancel } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 }
    }

    return (
      <Modal title='指标调整'
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        onOk={() => this.handleConfirm()}
      >
        {
          activeChildren && activeChildren.length > 0
            ? <>
              <Form>
                {
                  activeChildren.map((item, index) => (
                    <FormItem label={item.targetName} key={`formitem-${index}`} {...formItemLayout}>
                      {
                        getFieldDecorator(`${activeDepartId}-${item.id}`, {
                          initialValue: item.targetValue,
                          rules: [
                            { required: true, message: `请输入${item.targetName}` }
                          ]
                        })(<InputNumber autoComplete='off' style={{ width: '100%' }} />)
                      }
                    </FormItem>
                  ))
                }
              </Form>
            </>
            : null
        }
      </Modal>
    )
  }
}

const SettingModal = Form.create()(SettingForm)

export default SettingModal
