/* 快递管理 */
import ExpressService from '@/pages/express/expressService/index' // 快递服务
import ExpressRecords from '@/pages/express/expressRecords/index' // 快递记录
import SendAddress from '@/pages/express/sendAddress/index'

export default [
  {
    path: '/admin/expressService',
    name: '快递服务',
    component: ExpressService
  },
  {
    path: '/admin/expressRecords',
    name: '快递记录',
    component: ExpressRecords
  },
  {
    path: '/admin/sendAddress',
    name: '发货地址',
    component: SendAddress
  }
]
