import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Icon, Table } from 'antd'

import { downloadExcel } from '@/common/utils/downloadFile'

export default class CustomerTable extends Component {
  static propTypes = {
    data: PropTypes.array
  }
  state = {
    columns: [
      {
        title: '',
        key: 'customerTableCol-0',
        width: 270,
        dataIndex: 'name'
      },
      {
        title: '2021',
        key: 'customerTableCol-2021-YEAR',
        width: 120,
        dataIndex: 'value-2021'
      },
      {
        title: '第一季度',
        key: 'customerTableCol-2021-Q1',
        width: 100,
        dataIndex: 'value-2021-Q1'
      },
      {
        title: '一月',
        key: 'customerTableCol-2021-Q1-1',
        width: 100,
        dataIndex: 'value-2021-1'
      },
      {
        title: '二月',
        key: 'customerTableCol-2021-Q1-2',
        width: 100,
        dataIndex: 'value-2021-2'
      },
      {
        title: '三月',
        key: 'customerTableCol-2021-Q1-3',
        width: 100,
        dataIndex: 'value-2021-3'
      },
      {
        title: '第二季度',
        key: 'customerTableCol-2021-Q2',
        width: 100,
        dataIndex: 'value-2021-Q2'
      },
      {
        title: '四月',
        key: 'customerTableCol-2021-Q2-4',
        width: 100,
        dataIndex: 'value-2021-4'
      },
      {
        title: '五月',
        key: 'customerTableCol-2021-Q2-5',
        width: 100,
        dataIndex: 'value-2021-5'
      },
      {
        title: '六月',
        key: 'customerTableCol-2021-Q2-6',
        width: 100,
        dataIndex: 'value-2021-6'
      },
      {
        title: '第三季度',
        key: 'customerTableCol-2021-Q3',
        width: 100,
        dataIndex: 'value-2021-Q3'
      },
      {
        title: '七月',
        key: 'customerTableCol-2021-Q3-7',
        width: 100,
        dataIndex: 'value-2021-7'
      },
      {
        title: '八月',
        key: 'customerTableCol-2021-Q3-8',
        width: 100,
        dataIndex: 'value-2021-8'
      },
      {
        title: '九月',
        key: 'customerTableCol-2021-Q3-9',
        width: 100,
        dataIndex: 'value-2021-9'
      },
      {
        title: '第四季度',
        key: 'customerTableCol-2021-Q4',
        width: 100,
        dataIndex: 'value-2021-Q4'
      },
      {
        title: '十月',
        key: 'customerTableCol-2021-Q4-10',
        width: 100,
        dataIndex: 'value-2021-10'
      },
      {
        title: '十一月',
        key: 'customerTableCol-2021-Q4-11',
        width: 100,
        dataIndex: 'value-2021-11'
      },
      {
        title: '十二月',
        key: 'customerTableCol-2021-Q4-12',
        width: 100,
        dataIndex: 'value-2021-12'
      },
      {
        title: '2020',
        key: 'customerTableCol-2020',
        width: 100,
        dataIndex: 'value-2020'
      },
      {
        title: '第一季度',
        key: 'customerTableCol-2020-Q1',
        width: 100,
        dataIndex: 'value-2020-Q1'
      },
      {
        title: '一月',
        key: 'customerTableCol-2020-Q1-1',
        width: 100,
        dataIndex: 'value-2020-1'
      },
      {
        title: '二月',
        key: 'customerTableCol-2020-Q1-2',
        width: 100,
        dataIndex: 'value-2020-2'
      },
      {
        title: '三月',
        key: 'customerTableCol-2020-Q1-3',
        width: 100,
        dataIndex: 'value-2020-3'
      },
      {
        title: '第二季度',
        key: 'customerTableCol-2020-Q2',
        width: 100,
        dataIndex: 'value-2020-Q2'
      },
      {
        title: '四月',
        key: 'customerTableCol-2020-Q2-4',
        width: 100,
        dataIndex: 'value-2020-4'
      },
      {
        title: '五月',
        key: 'customerTableCol-2020-Q2-5',
        width: 100,
        dataIndex: 'value-2020-5'
      },
      {
        title: '六月',
        key: 'customerTableCol-2020-Q2-6',
        width: 100,
        dataIndex: 'value-2020-6'
      },
      {
        title: '第三季度',
        key: 'customerTableCol-2020-Q3',
        width: 100,
        dataIndex: 'value-2020-Q3'
      },
      {
        title: '七月',
        key: 'customerTableCol-2020-Q3-7',
        width: 100,
        dataIndex: 'value-2020-7'
      },
      {
        title: '八月',
        key: 'customerTableCol-2020-Q3-8',
        width: 100,
        dataIndex: 'value-2020-8'
      },
      {
        title: '九月',
        key: 'customerTableCol-2020-Q3-9',
        width: 100,
        dataIndex: 'value-2020-9'
      },
      {
        title: '第四季度',
        key: 'customerTableCol-2020-Q4',
        width: 100,
        dataIndex: 'value-2020-Q4'
      },
      {
        title: '十月',
        key: 'customerTableCol-2020-Q4-10',
        width: 100,
        dataIndex: 'value-2020-10'
      },
      {
        title: '十一月',
        key: 'customerTableCol-2020-Q4-11',
        width: 100,
        dataIndex: 'value-2020-11'
      },
      {
        title: '十二月',
        key: 'customerTableCol-2020-Q4-12',
        width: 100,
        dataIndex: 'value-2020-12'
      },
      {
        title: '2019',
        key: 'customerTableCol-2019',
        width: 100,
        dataIndex: 'value-2019'
      },
      {
        title: '第一季度',
        key: 'customerTableCol-2019-Q1',
        width: 100,
        dataIndex: 'value-2019-Q1'
      },
      {
        title: '一月',
        key: 'customerTableCol-2019-Q1-1',
        width: 100,
        dataIndex: 'value-2019-1'
      },
      {
        title: '二月',
        key: 'customerTableCol-2019-Q1-2',
        width: 100,
        dataIndex: 'value-2019-2'
      },
      {
        title: '三月',
        key: 'customerTableCol-2019-Q1-3',
        width: 100,
        dataIndex: 'value-2019-3'
      },
      {
        title: '第二季度',
        key: 'customerTableCol-2019-Q2',
        width: 100,
        dataIndex: 'value-2019-Q2'
      },
      {
        title: '四月',
        key: 'customerTableCol-2019-Q2-4',
        width: 100,
        dataIndex: 'value-2019-4'
      },
      {
        title: '五月',
        key: 'customerTableCol-2019-Q2-5',
        width: 100,
        dataIndex: 'value-2019-5'
      },
      {
        title: '六月',
        key: 'customerTableCol-2019-Q2-6',
        width: 100,
        dataIndex: 'value-2019-6'
      },
      {
        title: '第三季度',
        key: 'customerTableCol-2019-Q3',
        width: 100,
        dataIndex: 'value-2019-Q3'
      },
      {
        title: '七月',
        key: 'customerTableCol-2019-Q3-7',
        width: 100,
        dataIndex: 'value-2019-7'
      },
      {
        title: '八月',
        key: 'customerTableCol-2019-Q3-8',
        width: 100,
        dataIndex: 'value-2019-8'
      },
      {
        title: '九月',
        key: 'customerTableCol-2019-Q3-9',
        width: 100,
        dataIndex: 'value-2019-9'
      },
      {
        title: '第四季度',
        key: 'customerTableCol-2019-Q4',
        width: 100,
        dataIndex: 'value-2019-Q4'
      },
      {
        title: '十月',
        key: 'customerTableCol-2019-Q4-10',
        width: 100,
        dataIndex: 'value-2019-10'
      },
      {
        title: '十一月',
        key: 'customerTableCol-2019-Q4-11',
        width: 100,
        dataIndex: 'value-2019-11'
      },
      {
        title: '十二月',
        key: 'customerTableCol-2019-Q4-12',
        width: 100,
        dataIndex: 'value-2019-12'
      }
    ]
  }

  // 点击导出数据
  handleDownload = () => {
    const { data = [] } = this.props
    const { columns = [] } = this.state
    let arr = []
    let titleArr = []

    columns.map((ctem) => {
      titleArr.push(ctem.title)
    })

    arr.push(titleArr)

    data.forEach((item) => {
      let rowArr = []
      columns.map((ctem) => {
        rowArr.push(item[ctem.dataIndex])
      })

      arr.push(rowArr)
    })

    downloadExcel(arr, '服务商&最终用户综合统计表.xlsx')
  }

  render () {
    const { data = [] } = this.props
    const { columns = [] } = this.state

    return (
      <div style={{ marginTop: '20px' }}>
        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' disabled={data.length === 0} onClick={() => this.handleDownload()}><Icon type='download' />导出数据</Button>
          </div>
          <Table rowKey={(record, index) => `customerTableCol-${index}`} bordered
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: 1200 }}
          />
        </Card>
      </div>
    )
  }
}
