/* 运营模块 */
import Material from '@/pages/business/material/index' // 运营图
import BrandCover from '@/pages/business/brandcover/index' // 品牌封面片
import BrandMuseum from '@/pages/business/brand-museum/index' // 品牌馆
import BrandmuseDetail from '@/pages/business/brandmuse-detail/index' // 品牌馆详情
import MiniprogramApply from '@/pages/business/miniprogram-apply/index' // 名片小程序申请列表
import LabelLibrary from '@/pages/business/label-library/index' // 标签列表
import RecommendList from '@/pages/business/recommend-list/index' // 推荐产品

import BanneradvertList from '@/pages/business/bannerAdvert/index' // banner广告列表
import BannerBind from '@/pages/business/bannerBind/index' // banner广告绑定商户
import BannermallSet from '@/pages/business/bannermallSet/index' // banner广告页面设置
import Notification from '@/pages/business/notification-tool/index' // 通知工具
import Distribution from '@/pages/business/distribution-tool/index' // 群发通知工具

import SmsRcored from '@/pages/business/smsRecord/index' // 短信发送记录
import SmsTemplate from '@/pages/business/smsTemplate/index' // 短信模板
import TemPlateForm from '@/pages/business/smsTemplate/temPlateInfo'// 编辑模板

export default [
  {
    path: '/admin/material',
    name: '商城素材设置',
    component: Material
  },
  {
    path: '/admin/setbrandCover',
    name: '品牌封面设置',
    component: BrandCover
  },
  {
    path: '/admin/brandMuseum',
    name: '品牌馆',
    component: BrandMuseum
  },
  {
    path: '/admin/brandmuseDetail',
    name: '品牌馆详情',
    component: BrandmuseDetail
  },
  {
    path: '/admin/cardApply',
    name: '名片申请',
    component: MiniprogramApply
  },
  {
    path: '/admin/labelLibrary',
    name: '标签管理',
    component: LabelLibrary
  },
  {
    path: '/admin/recommendList',
    name: '推荐产品',
    component: RecommendList
  },
  {
    path: '/admin/bannerAdvert',
    name: 'banner广告',
    component: BanneradvertList
  },
  {
    path: '/admin/bannerBind',
    name: 'banner广告关联',
    component: BannerBind
  },
  {
    path: '/admin/bannermallSet',
    name: 'banner广告详情',
    component: BannermallSet
  },
  {
    path: '/admin/notification',
    name: '通知工具',
    component: Notification
  },
  {
    path: '/admin/distribution',
    name: '群发通知工具',
    component: Distribution
  },
  {
    path: '/admin/SMSrecord',
    name: '短信发送记录',
    component: SmsRcored
  },
  {
    path: '/admin/SMStemplate',
    name: '短信模板配置',
    component: SmsTemplate
  },
  {
    path: '/admin/SMStemplateInfo',
    name: '短信模板编辑',
    component: TemPlateForm
  }
]
