import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, Form, Input, Row } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input

class MemberForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    type: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    info: {}
  }

  componentDidMount () {

  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type } = this.props

        if (type === 2) {
          values.id = detailInfo.id
        }

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { detailInfo, type = 1 } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    return (
      <div>
        <Modal title={type === 1 ? '新增部门' : '编辑部门'}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Row>
            <Form {...formItemLayout}>
              <FormItem label='部门名称'>
                {
                  getFieldDecorator('departName', {
                    initialValue: detailInfo.departName || '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>

              <FormItem label='部门职能'>
                {
                  getFieldDecorator('jobDesc', {
                    initialValue: detailInfo.jobDesc || ''
                  })(
                    <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const MemberModal = Form.create()(MemberForm)

export default MemberModal
