import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Form, Select, Input } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input

const tempTypeMap = [
  { type: 'CURRENT_TEMPLATE', code: 3, label: '通用模板' },
  { type: 'CLOUD_SUPPLY', code: 1, label: '客户模板' }
]

class RactForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    buttonMaps: PropTypes.object,
    detailInfo: PropTypes.object,
    type: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    info: {},
    cusOptions: [], // 客户列表
    selectedUserId: 0, // 当前选择的用户
    selectedTempType: 3
  }

  componentDidMount () {
    const { type = 1, detailInfo = {} } = this.props
    this.getCustomerList()

    if (type === 2) {
      this.setState({
        selectedTempType: +detailInfo.atType
      })
    }
  }

  // 获取客户列表
  getCustomerList = () => {
    let params = {
      pageNo: 1,
      pageSize: 99999
    }

    Api.getCustomerList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _options = []

        let _list = data.list || []

        _options = _options.concat(_list)

        this.setState({
          cusOptions: _options
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type } = this.props
        if (type === 2) {
          values.agreementId = detailInfo.agreementId
        }

        this.props.onConfirm(values)
      }
    })
  }

  // 监听模板选择
  onSelectedTempType = (val) => {
    this.setState({
      selectedTempType: val
    })
  }

  render () {
    const { type = 1, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { cusOptions = [], selectedTempType = 3 } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <div>
        <Modal title={type === 1 ? '新增合同模板' : '编辑合同模板'}
          width={800}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form>
            <FormItem label='模板类型' {...formItemLayout}>
              {
                getFieldDecorator('atType', {
                  initialValue: detailInfo && detailInfo.atType ? +detailInfo.atType : 3,
                  rules: [
                    { required: true, message: '请选择模板类型' }
                  ]
                })(
                  <Select
                    onChange={this.onSelectedTempType}
                  >
                    {
                      tempTypeMap.map((item, i) => (
                        <Option value={item.code} key={`tempType-${i}`}>{item.label}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>
            {
              +selectedTempType === 1
                ? <FormItem label='客户名称' {...formItemLayout}>
                  {
                    getFieldDecorator('distributorId', {
                      initialValue: +detailInfo.distributorId || '',
                      rules: [
                        { required: true, message: '请选择客户' }
                      ]
                    })(
                      <Select
                        showSearch
                        optionFilterProp='children'
                      >
                        {
                          cusOptions.map((item, i) => (
                            <Option value={item.distributorId} key={item.distributorId}>
                              {item.merchantName}
                            </Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
                : null
            }

            <FormItem label='模板名称' {...formItemLayout}>
              {
                getFieldDecorator('atName', {
                  initialValue: detailInfo.atName || '',
                  rules: [
                    { required: true, message: '请输入模板名称' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='模板内容' {...formItemLayout}>
              {
                getFieldDecorator('atContent', {
                  initialValue: detailInfo.atContent || '',
                  rules: [
                    { required: true, message: '请输入模板内容' }
                  ]
                })(
                  <TextArea placeholder='请输入...' autoComplete='off' rows={6} />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const ContractplModal = Form.create()(RactForm)
const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}
export default connect(mapStateToProps)(ContractplModal)
