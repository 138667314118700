import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Table, message, Button } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import RechargeModal from '../component/recharge-modal'
import MbalanceflowModal from '../component/mbalanceflow-modal'
import WriteoffModal from './writeoffModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class BanlanceList extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '客户名称',
          extra: ''
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    rechargeModal: false, // 余额充值弹窗
    balanceflowModal: false, // 余额流水弹窗
    writeoffModal: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'balanceList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      key: 'balanceList-1',
      dataIndex: 'distributorName'
    },
    {
      title: '账户余额',
      key: 'balanceList-2',
      render: (text, record, index) => (
        <>&yen;{(+record.totalBalance * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '操作',
      key: 'balanceList-8',
      width: 200,
      render: (text, record, index) => {
        const { buttonMaps } = this.props
        return (
          <div>
            {
              buttonMaps && buttonMaps['recharge'] && +buttonMaps['recharge'].auth === 1
                ? <Button type='primary' size='small' onClick={() => this.showRechargeModal(record)}>
                  余额充值
                </Button>
                : null
            }

            <a style={{ marginLeft: '6px' }} onClick={() => this.showBalanceFlowModal(record)}>查看流水</a>

            {
              buttonMaps && buttonMaps['writeoff'] && +buttonMaps['writeoff'].auth === 1
                ? <Button size='small' type='primary' style={{ marginLeft: '6px' }}
                  onClick={() => this.showWriteOffModal(record)}
                >核销</Button>
                : null
            }

          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getMerchantBalanceList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取商户余额列表
  getMerchantBalanceList = () => {
    const { pages, query } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query
    }

    Api.getMerchantBalanceList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存客户余额充值
  saveBalanceRecharge = (params) => {
    Api.saveBalanceRecharge(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getMerchantBalanceList()
      }
    })
  }

  // 获取商户余额流水
  getMerchantBalanceFlow = (params) => {
    return Api.getMerchantBalanceFlow(params)
  }

  // 商户余额核销
  setWriteOffBabalanceInMerchant = (params) => {
    Api.setWriteOffBabalanceInMerchant(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getMerchantBalanceList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getMerchantBalanceList()
    })
  }

  // 点击余额充值
  showRechargeModal = (record = {}) => {
    this.setState({
      rechargeModal: true,
      rowDetail: record
    })
  }

  // 点击查看余额流水
  showBalanceFlowModal = (record = {}) => {
    this.setState({
      balanceflowModal: true,
      rowDetail: record
    })
  }

  // 点击核销
  showWriteOffModal = (record) => {
    this.setState({
      writeoffModal: true,
      rowDetail: record || {}
    })
  }

  closeModal = () => {
    this.setState({
      rechargeModal: false,
      balanceflowModal: false,
      writeoffModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMerchantBalanceList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMerchantBalanceList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], rechargeModal = false, balanceflowModal = false, writeoffModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <Table rowKey='id' bordered
                dataSource={tableData}
                columns={this.columns}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          rechargeModal
            ? <RechargeModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.saveBalanceRecharge(params)}
            />
            : null
        }

        {
          balanceflowModal
            ? <MbalanceflowModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              getDetail={(params) => this.getMerchantBalanceFlow(params)}
            />
            : null
        }

        {
          writeoffModal
            ? <WriteoffModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.setWriteOffBabalanceInMerchant(params)}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(BanlanceList)
