import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import SearchForm from '@/components/search-form/index'

const { pageSizeOptions } = filter

export default class UnbindModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'merchantName',
        formType: 'Input',
        itemParams: {
          label: '商户'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'unbindList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '公司名称',
      key: 'unbindList-1',
      dataIndex: 'merchantName'
    }
  ]

  componentDidMount () {
    this.getCustomerList()
  }

  // 获取商户列表
  getCustomerList = () => {
    const { query = {} } = this.state
    Api.getCustomerList({ ...query, pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  // 选择未关联的商户
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { detailInfo = {} } = this.props
    const { selectedRowKeys } = this.state
    let params = {
      advertisingId: detailInfo.id,
      distributorIdList: selectedRowKeys
    }

    this.props.onConfirm(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCustomerList()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCustomerList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCustomerList()
    })
  }

  render () {
    const { searchData, pages, tableData, selectedRowKeys = [] } = this.state

    const pagination = {
      size: 'small',
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <div>
        <Modal title='关联商户' width={1000} bodyStyle={{ padding: '12px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Row>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
            <Row>
              <Table rowKey='id' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
                rowSelection={rowSelection}
                scroll={{ y: 380 }}
              />
            </Row>
          </Row>
        </Modal>
      </div>
    )
  }
}
