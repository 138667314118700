import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Col, Input, InputNumber,
  Radio, Upload, Icon, message, Button } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import Api from '@/common/api/index'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './invoice.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

class RechargeForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    imgkey: '',
    yztime: 59,
    btnDisabled: false
  }

  componentDidMount () {}

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo } = this.props
        const { imgkey = '' } = this.state

        if (imgkey === '') {
          return message.warning('请上传凭证图片')
        }

        values.voucherUrl = imgkey
        values.distributorId = detailInfo.distributorId
        this.props.onConfirm(values)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      let { imgkey = '' } = this.state
      const { data } = info.file.response

      imgkey = data.imageUrl

      this.setState({
        imgkey
      })
    }
  }

  // 删除图片
  removeImg = () => {
    let { imgkey = '' } = this.state

    imgkey = ''

    this.setState({
      imgkey
    })
  }

  // 点击获取验证码
  handleGetMessCode = () => {
    Api.sendMessageCode({}).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
      }
    })
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo } = this.props
    const { imgkey = '', yztime = 59, btnDisabled = false } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    }

    const uploadButton = (
      <div>
        <Icon type='plus' />
        <div>点击上传</div>
      </div>
    )

    return (
      <>
        <Modal title='余额充值' width={600} bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Form>
              <FormItem label='客户名称' {...formItemLayout}>
                <div style={{ width: '400px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  <span style={{ marginRight: '5px' }}>{detailInfo.distributorName}</span>
                </div>
              </FormItem>

              <FormItem label='充值类型' {...formItemLayout}>
                {
                  getFieldDecorator('rechargeType', {
                    initialValue: 'DRAFT',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Radio.Group>
                      <Radio value={'DRAFT'} key={`rechargeType-0`}>承兑汇票</Radio>
                      <Radio value={'CASH'} key={`rechargeType-1`}>现金</Radio>
                    </Radio.Group>
                  )
                }
              </FormItem>

              <FormItem label='充值金额' {...formItemLayout}>
                {
                  getFieldDecorator('rechargeAmount', {
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <InputNumber placeholder='请输入...' autoComplete='off'
                      style={{ width: '165px' }}
                    />
                  )
                }
              </FormItem>

              <FormItem label='验证码' {...formItemLayout}>
                <Row gutter={10}>
                  <Col span={12}>
                    {
                      getFieldDecorator('verifyCode', {
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off'
                          style={{ width: '100%' }}
                        />
                      )
                    }
                  </Col>
                  <Col span={12}>
                    <Button disabled={btnDisabled} onClick={this.handleGetMessCode}>
                      {
                        !btnDisabled ? '获取验证码' : `${yztime} s 后重新发送`
                      }
                    </Button>
                  </Col>
                </Row>
              </FormItem>

              <FormItem>
                <label className={`${styles.formLabel} ${styles.fill}`}>凭证图片</label>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  {
                    imgkey !== ''
                      ? <div className={styles.thumbWrap}>
                        <div className={styles.thumbInfo}>
                          <img src={imgkey} />

                          <a className={styles.prewModal}>
                            <Icon type='delete' style={{ fontSize: '20px' }}
                              onClick={() => this.removeImg()}
                            />
                          </a>
                        </div>
                      </div>
                      : <Upload
                        showUploadList={false}
                        listType='picture-card'
                        accept='image/png, image/jpg, image/jpeg'
                        headers={{ Token: getStore('token') }}
                        action={uploadOrdinaryImageUrl}
                        beforeUpload={this.beforeUpload}
                        onChange={(info) => this.handleChangeUpload(info)}
                      >
                        {uploadButton}
                      </Upload>
                  }
                </div>
              </FormItem>

              <FormItem label='备注' {...formItemLayout}>
                {
                  getFieldDecorator('remarks', {

                  })(
                    <TextArea placeholder='请输入...' autoComplete='off'
                      rows={4}
                    />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const BalanceRecharge = Form.create()(RechargeForm)

export default BalanceRecharge
