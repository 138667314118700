/* 仓库模块 */
import WarehouseEntryBill from '@/pages/warehouse/warehouseEntryBill/index' // 入库单
import WarehouseOutBill from '@/pages/warehouse/warehouseOutBill/index' // 出库单

export default [
  {
    path: '/admin/warehouseEntryBill',
    name: '采购入库单',
    component: WarehouseEntryBill
  },
  {
    path: '/admin/warehouseOutBill',
    name: '销售出库单',
    component: WarehouseOutBill
  }
]
