import React, { Component } from 'react'
import { Card, Table, Button, Icon, Tooltip } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderEnquireOrderStatusLabel, renderMatchingLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { pageSizeOptions, enquireOrderStateMaps, enquireMatchStatusMaps, repLineBreak } = filter
const { MAX_PAGESIZE } = consts
const enquiryTypeOptions = $lodash.filter(enquireOrderStateMaps, (o) => { return o.id !== 'INTERNAL_DRAFT' })

class EnquiryBoard extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'enquiryOrderId',
        formType: 'Input',
        itemParams: {
          label: '询价单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'enquiryTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '询价日期'
        },
        cptParams: {

        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '询价商户'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '负责人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'enquiryType',
        formType: 'Select',
        itemParams: {
          label: '询价单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...enquiryTypeOptions
        ]
      },
      {
        name: 'matchingState',
        formType: 'Select',
        itemParams: {
          label: '数据类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...enquireMatchStatusMaps,
          { id: 'WRITE', name: '手写数据' }
        ]
      },
      {
        name: 'brandName',
        formType: 'Input',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'enquiryBoardCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '询价单编号',
      key: 'enquiryBoardCol-enquiryOrderId',
      dataIndex: 'enquiryOrderId'
    },
    {
      title: '询价日期',
      key: 'enquiryBoardCol-submitTime',
      dataIndex: 'submitTime',
      render: (text, record, index) => (
        <>{ record.submitTime ? moment(record.submitTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '回复日期',
      key: 'enquiryBoardCol-replyTime',
      dataIndex: 'replyTime',
      render: (text, record, index) => (
        <>{ record.replyTime ? moment(record.replyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '询价商户',
      key: 'enquiryBoardCol-distributorName',
      dataIndex: 'distributorName'
    },
    {
      title: '负责人',
      key: 'enquiryBoardCol-platformUserName',
      dataIndex: 'platformUserName'
    },
    {
      title: '询价单类型',
      key: 'enquiryBoardCol-enquiryType',
      dataIndex: 'enquiryType',
      render: (text, record, index) => (
        <>{ renderEnquireOrderStatusLabel(record.enquiryType) }</>
      )
    },
    {
      title: '行号',
      key: 'enquiryBoardCol-rownum',
      dataIndex: 'rownum'
    },
    {
      title: '品牌',
      key: 'enquiryBoardCol-brandName',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'enquiryBoardCol-model',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}><span>{ record.model }</span></Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'enquiryBoardCol-itemNumber',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}><span>{ record.itemNumber }</span></Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'enquiryBoardCol-name',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}><span>{ record.name }</span></Tooltip>
        </>
      )
    },
    {
      title: '单位',
      key: 'enquiryBoardCol-unit',
      dataIndex: 'unit'
    },
    {
      title: '期望价格',
      key: 'enquiryBoardCol-expectPrice',
      dataIndex: 'expectPrice',
      render: (text, record, index) => (
        <>&yen;{ +record.expectPrice }</>
      )
    },
    {
      title: '数量',
      key: 'enquiryBoardCol-quantity',
      dataIndex: 'quantity'
    },
    {
      title: '期望折扣',
      key: 'enquiryBoardCol-expectDiscount',
      dataIndex: 'expectDiscount',
      render: (text, record, index) => (
        <>{record.expectDiscount ? <>{(+record.expectDiscount * 100 / 100).toFixed(2)}%</> : null}</>
      )
    },
    {
      title: '期望货期',
      key: 'enquiryBoardCol-expectDeliveryPeriodDays',
      dataIndex: 'expectDeliveryPeriodDays',
      render: (text, record, index) => (
        <>{+record.expectDeliveryPeriodDays === 0 ? '现货' : `${+record.expectDeliveryPeriodDays}工作日`}</>
      )
    },
    {
      title: '数据类型',
      key: 'enquiryBoardCol-matchingState',
      dataIndex: 'matchingState',
      render: (text, record, index) => (
        <>{ renderMatchingLabel(record.matchingState, record.dataType) }</>
      )
    }
  ]

  componentDidMount () {
    this.getEnquiryBoardList()
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[5].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        const { searchData = [] } = this.state
        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取全部成员
  getMemberList = (val = '') => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.list && data.list.length > 0) {
        const { searchData = [] } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, userName: '全部' }], data.list)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取询价统计列表
  getEnquiryBoardList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getEnquiryBoardList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { matchingState } = search

    if (search.enquiryTimes) {
      search['beginDate'] = search.enquiryTimes.length === 2 ? moment(search.enquiryTimes[0]).format('YYYY-MM-DD') : undefined
      search['endDate'] = search.enquiryTimes.length === 2 ? moment(search.enquiryTimes[1]).format('YYYY-MM-DD') : undefined
      delete search.enquiryTimes
    }

    if (search.itemNo) {
      let modelList = repLineBreak(search.itemNo, '<br>')
      search['modelList'] = modelList.split('<br>')
    }

    if (matchingState) {
      switch (matchingState) {
        case 'WRITE':
          search.dataType = 'WRITE'
          search.matchingState = undefined
          break
        case 'MATCHED':
          search.dataType = 'SEARCH'
          break
        case 'UNMATCHED':
          search.dataType = 'SEARCH'
          break
        default:
          search.dataType = 'ALL'
      }
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      },
      query: search || {}
    }, () => {
      this.getEnquiryBoardList()
    })
  }

  // 点击导出
  handleDownload = () => {
    const { query = {} } = this.state
    DownloadApi.downloadEnquiryBoardStatistics({ ...query })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEnquiryBoardList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEnquiryBoardList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handleDownload()}><Icon type='download' />导出</Button>
          </div>

          <Table rowKey={(record, index) => `${record.enquiryOrderId}-${record.commoditySkuId}-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default EnquiryBoard
