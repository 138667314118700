import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, DatePicker, Upload, Button, Icon, Tooltip, Input, message } from 'antd'
import moment from 'moment'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './agency.module.scss'

const FormItem = Form.Item
const { RangePicker } = DatePicker
const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

class AgencyForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type } = this.props

        if (values && values.date && values.date.length > 0) {
          const { date } = values
          values['startTime'] = moment(date[0]).format('YYYY-MM-DD')
          values['endTime'] = moment(date[1]).format('YYYY-MM-DD')
        }

        values['id'] = detailInfo.id
        values['type'] = type
        delete values['date']

        this.props.onConfirm(values)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response

      if (+code === 10000) {
        message.success('上传成功')
        setTimeout(() => {
          this.props.form.setFieldsValue({
            agencyUrl: data.imageUrl
          })
        }, 20)
      } else {
        message.error('上传文件失败')
      }
    }
  }

  render () {
    const { detailInfo = {}, type = 1 } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <div>
        <Modal title={`${type === 1 ? '代理证查看' : '代理日期设置'}`}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem label={'代理证'} {...formItemLayout} style={{ marginBottom: '0px' }}>
              <Row style={{ position: 'relative' }}>
                <Row className={`${styles.valueFormitem}`}>
                  <FormItem>
                    {
                      getFieldDecorator('agencyUrl', {
                        initialValue: detailInfo.agencyUrl || '',
                        rules: [
                          { required: false, message: '请上传代理证图片' }
                        ]
                      })(
                        <Input autoComplete='off' disabled />
                      )
                    }
                  </FormItem>
                </Row>

                <Row className={`${styles.showFormitem}`}>
                  <FormItem style={{ display: 'inline-block', width: '120px' }}>
                    <Upload name='file'
                      showUploadList={false}
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <Button>
                        <Icon type='upload' />点击上传
                      </Button>
                    </Upload>
                  </FormItem>
                  <Row className={`${styles.showControl}`}>
                    <Tooltip title={this.props.form.getFieldValue('agencyUrl')}>
                      <a href={this.props.form.getFieldValue('agencyUrl')} target='_blank'>
                        {this.props.form.getFieldValue('agencyUrl')}
                      </a>
                    </Tooltip>
                  </Row>
                </Row>
              </Row>
            </FormItem>
            {
              type === 2
                ? <FormItem label='代理日期' {...formItemLayout}>
                  {
                    getFieldDecorator('date', {
                      initialValue: detailInfo.agencyStartTime && detailInfo.agencyEndTime ? [moment(detailInfo.agencyStartTime), moment(detailInfo.agencyEndTime)] : [],
                      rules: [
                        { required: true, message: '请设置代理日期' }
                      ]
                    })(
                      <RangePicker />
                    )
                  }
                </FormItem>
                : null
            }

          </Form>
        </Modal>
      </div>
    )
  }
}

const AgencyModal = Form.create()(AgencyForm)

export default AgencyModal
