import React, { Component } from 'react'
import { Card, Table, Row, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import HandleModal from './handle-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const Status = [
  { type: 'all', label: '全部' },
  { type: 'UNPROCESS', label: '待处理' },
  { type: 'PROCESS', label: '已处理' }
]
const ResultStatus = [
  { type: 'ALL', label: '全部' },
  { type: 'SUCCESS', label: '成功' },
  { type: 'FAILED', label: '失败' },
  { type: 'PROCESSING', label: '推进中' }
]

export default class ApplyFor extends Component {
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    handlemodal: false,
    rowDetail: {},
    tableData: [],
    searchData: [
      {
        name: 'processStatus',
        formType: 'Select',
        itemParams: {
          label: '处理状态'
        },
        cptParams: {

        },
        options: Status,
        optionValue: ['type', 'label']
      },
      {
        name: 'processResult',
        formType: 'Select',
        itemParams: {
          label: '处理结果'
        },
        cptParams: {

        },
        options: ResultStatus,
        optionValue: ['type', 'label']
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {}
      }

    ]
  }

  columns = [
    {
      title: '序号',
      key: 'tradeOrder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '姓名',
      width: 104,
      key: 'tradeOrder-1',
      dataIndex: 'userName'
    },
    {
      title: '公司名称',
      width: 144,
      key: 'tradeOrder-2',
      dataIndex: 'company',
      render: (text, record, index) => (
        <span>{ record.company ? record.company : '----' }</span>
      )
    },
    {
      title: '联系方式',
      width: 104,
      key: 'tradeOrder-3',
      dataIndex: 'userCall'
    },
    {
      title: '邮箱',
      width: 104,
      key: 'tradeOrder-4',
      dataIndex: 'userMail',
      render: (text, record, index) => (
        <div>  { record.userMail ? record.userMail : '----' } </div>
      )
    },
    {
      title: '申请时间',
      key: 'tradeOrder-5',
      width: 140,
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '处理状态',
      width: 104,
      key: 'tradeOrder-6',
      dataIndex: 'processStatus',
      render: (text, record, index) => (
        <>
          {this.getprocessStatus(record.processStatus)}
        </>
      )
    },
    {
      title: '处理结果',
      width: 104,
      key: 'tradeOrder-7',
      dataIndex: 'processResult',
      render: (text, record, index) => (
        <div>
          {this.getprocessResult(record.processResult)}
        </div>
      )
    },
    {
      title: '处理人',
      width: 104,
      key: 'tradeOrder-8',
      dataIndex: 'processUser'
    },
    {
      title: '处理时间',
      width: 140,
      key: 'tradeOrder-9',
      render: (text, record, index) => (
        <>
          { record.processTime ? moment(record.processTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '操作',
      width: 50,
      key: 'tradeOrder-10',
      dataIndex: 'orderId',
      render: (text, record, index) => {
        return (
          <>
            {record.processResult === 'PROCESSING' ? <a onClick={() => { this.handleModal(record) }}>处理</a> : null}
          </>

        )
      }
    }
  ]

  componentDidMount () {
    this.listPageForMerchantApply()
  }

  // 合作伙伴申请列表
  listPageForMerchantApply = () => {
    const { query = {} } = this.state
    let params = {
      ...query,
      pageNo: 1,
      pageSize: 999
    }
    Api.listPageForMerchantApply(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  // 获取处理状态
  getprocessStatus = (processStatus) => {
    const obj = Status.find(item => { return item.type === processStatus })
    const label = obj !== undefined ? obj.label : null

    return label
  }

  // 获取处理结果
  getprocessResult = (processResult) => {
    const obj = ResultStatus.find(item => { return item.type === processResult })
    const label = obj !== undefined ? obj.label : null

    return label
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      let orderTime = search.orderTime
      search.applyStartTime = orderTime[0].format('YYYY-MM-DD')
      search.applyEndTime = orderTime[1].format('YYYY-MM-DD')

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.listPageForMerchantApply()
    })
  }

  handleResultOk = (param) => {
    this.handleProcessing(param)
  }

  // 处理结果
  handleProcessing = (param) => {
    Api.handleProcessing(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('处理成功')
        this.listPageForMerchantApply()
        this.closeModal()
      }
    })
  }

  // 处理弹框
  handleModal=(record) => {
    this.setState({
      handlemodal: true,
      rowDetail: record
    })
  }

  // 取消
  closeModal=() => {
    this.setState({
      handlemodal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.listPageForMerchantApply()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.listPageForMerchantApply()
    })
  }

  render () {
    const { searchData = [], pages, tableData = [], handlemodal, rowDetail = {} } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }
    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>

          <Table bordered rowKey='id'
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>

        {
          handlemodal
            ? <HandleModal
              onCancel={this.closeModal}
              rowDetail={rowDetail}
              onConfirm={(params) => this.handleResultOk(params)}
            />
            : null
        }
      </>
    )
  }
}
