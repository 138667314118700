import React from 'react'
import { Badge } from 'antd'

export const tableColumnsMaps = {
  'ENQUIRE_SPECIAL_PRODUCTS': [ // 特价产品导入
    {
      title: '序号',
      key: 'matchDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'matchDetailsCol-1',
      width: 130,
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '匹配编号',
      key: 'matchDetailsCol-2',
      width: 130,
      dataIndex: 'matchNo',
      ellipsis: true
    },
    {
      title: '状态',
      key: 'matchDetailsCol-3',
      width: 100,
      render: (text, record, index) => (
        <Badge status={record.matchResult === 'SUCCESS' ? 'success' : 'error'} text={record.matchResult === 'SUCCESS' ? '成功' : '失败'} />
      )
    },
    {
      title: '原因',
      key: 'matchDetailsCol-4',
      render: (text, record, index) => (
        <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
          {record.failReason}
        </div>
      )
    },
    {
      title: '型号',
      key: 'matchDetailsCol-5',
      width: 120,
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'matchDetailsCol-6',
      width: 120,
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'matchDetailsCol-7',
      width: 120,
      ellipsis: true,
      dataIndex: 'quantity'
    },
    {
      title: '产品名称',
      key: 'matchDetailsCol-8',
      ellipsis: true,
      dataIndex: 'name'
    }
  ],
  'ENQUIRE_PRODUCTS_UPLOAD': [ // 询价产品导入
    {
      title: '序号',
      key: 'matchDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'matchDetailsCol-1',
      width: 130,
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '匹配编号',
      key: 'matchDetailsCol-2',
      width: 130,
      dataIndex: 'matchNo',
      ellipsis: true
    },
    {
      title: '状态',
      key: 'matchDetailsCol-3',
      width: 100,
      render: (text, record, index) => (
        <Badge status={record.matchResult === 'SUCCESS' ? 'success' : 'error'} text={record.matchResult === 'SUCCESS' ? '成功' : '失败'} />
      )
    },
    {
      title: '原因',
      key: 'matchDetailsCol-4',
      render: (text, record, index) => (
        <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
          {record.failReason}
        </div>
      )
    },
    {
      title: '型号',
      key: 'matchDetailsCol-5',
      width: 120,
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'matchDetailsCol-6',
      width: 120,
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'matchDetailsCol-7',
      width: 120,
      ellipsis: true,
      dataIndex: 'quantity'
    },
    {
      title: '产品名称',
      key: 'matchDetailsCol-8',
      ellipsis: true,
      dataIndex: 'name'
    }
  ],
  'UPLOAD_WORK_ORDER': [ // 导入工单
    {
      title: '序号',
      key: 'matchDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'matchDetailsCol-1',
      width: 130,
      dataIndex: 'importBrandName',
      ellipsis: true
    },
    {
      title: '匹配编号',
      key: 'matchDetailsCol-2',
      width: 130,
      dataIndex: 'matchNo',
      ellipsis: true
    },
    {
      title: '商户名称',
      key: 'matchDetailsCol-13',
      width: 150,
      dataIndex: 'importDistributorName',
      ellipsis: true
    },
    {
      title: '供应价格',
      key: 'matchDetailsCol-3',
      width: 130,
      dataIndex: 'importSupplyPrice'
    },
    {
      title: '数量',
      key: 'matchDetailsCol-4',
      dataIndex: 'importQuantity'
    },
    {
      title: '实际货期',
      key: 'matchDetailsCol-5',
      dataIndex: 'importDeliveryPeriodDays',
      render: (text, record, index) => (
        <>{ +record.importDeliveryPeriodDays > 0 ? `${+record.importDeliveryPeriodDays}工作日` : '现货' }</>
      )
    },
    {
      title: '预付比例',
      key: 'matchDetailsCol-6',
      dataIndex: 'importPrepaymentRatio'
    },
    {
      title: '是否承担运费',
      key: 'matchDetailsCol-7',
      dataIndex: 'importIsUndertakeFreight'
    },
    {
      title: '运费金额',
      key: 'matchDetailsCol-8',
      dataIndex: 'importFreightPrice'
    },
    {
      title: '报价有效期',
      key: 'matchDetailsCol-9',
      dataIndex: 'importExpiryDateTime'
    },
    {
      title: '备注',
      key: 'matchDetailsCol-10',
      dataIndex: 'importRemark'
    },
    {
      title: '状态',
      key: 'matchDetailsCol-11',
      width: 100,
      render: (text, record, index) => (
        <Badge status={record.matchResult === 'SUCCESS' ? 'success' : 'error'} text={record.matchResult === 'SUCCESS' ? '成功' : '失败'} />
      )
    },
    {
      title: '原因',
      key: 'matchDetailsCol-12',
      render: (text, record, index) => (
        <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
          {record.failReason}
        </div>
      )
    }
  ],
  'UPLOAD_SERIES': [ // 导入系列
    {
      title: '序号',
      key: 'matchDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '系列名称',
      key: 'matchDetailsCol-1',
      dataIndex: 'seriesName',
      ellipsis: true
    },
    {
      title: '系列描述',
      key: 'matchDetailsCol-2',
      dataIndex: 'seriesDesc',
      ellipsis: true
    },
    {
      title: '状态',
      key: 'matchDetailsCol-3',
      render: (text, record, index) => (
        <Badge status={record.matchResult === 'SUCCESS' ? 'success' : 'error'} text={record.matchResult === 'SUCCESS' ? '成功' : '失败'} />
      )
    },
    {
      title: '原因',
      key: 'matchDetailsCol-4',
      render: (text, record, index) => (
        <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
          {record.failReason}
        </div>
      )
    }
  ],
  'AUTO_QUOTATION_IMPORT': [ // 询价导入价格
    {
      title: '序号',
      key: 'matchDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'matchDetailsCol-1',
      width: 130,
      dataIndex: 'importBrandName',
      ellipsis: true
    },
    {
      title: '匹配编号',
      key: 'matchDetailsCol-2',
      width: 130,
      dataIndex: 'matchNo',
      ellipsis: true
    },
    {
      title: '商户名称',
      key: 'matchDetailsCol-13',
      width: 150,
      dataIndex: 'importDistributorName',
      ellipsis: true
    },
    {
      title: '供应价格',
      key: 'matchDetailsCol-3',
      width: 130,
      dataIndex: 'importSupplyPrice'
    },
    {
      title: '数量',
      key: 'matchDetailsCol-4',
      dataIndex: 'importQuantity'
    },
    {
      title: '实际货期',
      key: 'matchDetailsCol-5',
      dataIndex: 'importDeliveryPeriodDays',
      render: (text, record, index) => (
        <>{ +record.importDeliveryPeriodDays > 0 ? `${+record.importDeliveryPeriodDays}工作日` : '现货' }</>
      )
    },
    {
      title: '预付比例',
      key: 'matchDetailsCol-6',
      dataIndex: 'importPrepaymentRatio'
    },
    {
      title: '是否承担运费',
      key: 'matchDetailsCol-7',
      dataIndex: 'importIsUndertakeFreight'
    },
    {
      title: '运费金额',
      key: 'matchDetailsCol-8',
      dataIndex: 'importFreightPrice'
    },
    {
      title: '报价有效期',
      key: 'matchDetailsCol-9',
      dataIndex: 'importExpiryDateTime'
    },
    {
      title: '备注',
      key: 'matchDetailsCol-10',
      dataIndex: 'importRemark'
    },
    {
      title: '状态',
      key: 'matchDetailsCol-11',
      width: 100,
      render: (text, record, index) => (
        <Badge status={record.matchResult === 'SUCCESS' ? 'success' : 'error'} text={record.matchResult === 'SUCCESS' ? '成功' : '失败'} />
      )
    },
    {
      title: '原因',
      key: 'matchDetailsCol-12',
      render: (text, record, index) => (
        <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
          {record.failReason}
        </div>
      )
    }
  ]
}
