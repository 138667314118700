import PurchaseSaled from '@/pages/saled/purchaseSaled/index' // 采购订单售后
import SupplySaled from '@/pages/saled/supplySaled/index' // 供应订单售后

export default [
  {
    path: '/admin/purchaseSaled',
    name: '云采购售后申请',
    component: PurchaseSaled
  },
  {
    path: '/admin/supplySaled',
    name: '云供应售后申请',
    component: SupplySaled
  }
]
