import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Row, Switch, message } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class CustomersupplySet extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '云计算状态'
        },
        cptParams: {
          placeholder: '请选择云计算状态...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ALLOWED', name: '是' },
          { id: 'NOT_ALLOWED', name: '否' }
        ]
      }
    ],
    tableData: [],
    editingKey: '', // 编辑中
    rowDetail: {} // 当前选择的行数据

  }

  columns = [
    {
      title: '序号',
      key: 'cuscloudSet-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户编号',
      key: 'cuscloudSet-1',
      dataIndex: 'distributorId'
    },
    {
      title: '公司名称',
      key: 'cuscloudSet-2',
      dataIndex: 'distributorName'
    },
    {
      title: '云计算',
      key: 'cuscloudSet-3',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            {
              buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1
                ? <Switch
                  checked={record.status === 'ALLOWED'}
                  checkedChildren='是'
                  unCheckedChildren='否'
                  onChange={(checked) => this.handleChangeSwitch(checked, record)}
                />
                : <div>
                  {
                    record.status === 'ALLOWED' ? '是' : '否'
                  }
                </div>
            }

          </div>
        )
      }
    },
    {
      title: '操作人',
      key: 'cuscloudSet-4',
      dataIndex: 'modifyName'
    },
    {
      title: '操作时间',
      key: 'cuscloudSet-5',
      render: (text, record, index) => (
        <>
          { record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getMerchantCloudSetList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取商户云供应设置列表
  getMerchantCloudSetList = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getMerchantCloudSetList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 更新商户云供应状态
  updateMerchantCloudSetStatus = (params) => {
    Api.updateMerchantCloudSetStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getMerchantCloudSetList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getMerchantCloudSetList()
    })
  }

  // 切换启用禁用状态
  handleChangeSwitch = (checked, record) => {
    let params = {
      status: checked ? 'ALLOWED' : 'NOT_ALLOWED',
      id: record.id
    }
    this.updateMerchantCloudSetStatus(params)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMerchantCloudSetList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMerchantCloudSetList()
    })
  }

  render () {
    const { tableData = [], pages, searchData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(CustomersupplySet)
