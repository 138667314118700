import Ajax from '../utils/ajax'

export default {
  // 云采购发票- 获取待开票列表数据
  getPurchaseOrderInvoiceUnOpenList (params) {
    return Ajax.post('/dis/invoice/list/page/opening', params)
  },

  // 云采购发票- 获取待开票详情
  getPurchaseOrderInvoiceUnOpenDetails (params) {
    return Ajax.post('/dis/invoice/get/merchantOrderInvoice/detail', params)
  },

  // 云采购发票- 获取开票完成列表
  getPurchaseOrderInvoiceOpenedList (params) {
    return Ajax.post('/dis/invoice/list/page/record/opened', params)
  },

  // 云采购发票- 获取开票完成详情
  getPurchaseOrderInvoiceOpenedDetails (params) {
    return Ajax.get('/dis/invoice/get/merchantInvoiceRecord/detail', { params })
  },

  // 云采购发票- 确认开票
  submitPurchaseOrderInvoiceOpening (params) {
    return Ajax.post('/dis/invoice/add/merchant/invoice/record', params)
  },

  // 云采购发票- 开票完成更新发票信息
  updatePurchaseOrderInvoiceOpenedDetails (params) {
    return Ajax.post('/dis/invoice/update/merchant/invoice/record', params)
  },

  // 云采购发票- 重新开票
  submitPurchaseOrderInvoiceRepeatOpening (params) {
    return Ajax.get('/dis/invoice/discard/merchant/invoice/record', { params })
  },

  // 云供应发票- 获取待申请开票列表
  getSupplyOrderInvoiceUnopenList (params) {
    return Ajax.post('/platform/platOpenInvoice/listPageInvoiceOrderUnApply', params)
  },

  // 云供应发票- 获取申请开票详情信息
  getSupplyOrderInvoiceUnopenDetails (params) {
    return Ajax.post('/platform/platOpenInvoice/invoiceOrderApplyDetail', params)
  },

  // 云供应发票- 保存开票申请
  saveSupplyOrderInvoiceApplyOpen (params) {
    return Ajax.post('/platform/platOpenInvoice/invoiceOrderApplySave', params)
  },

  // 云供应发票- 获取申请历史列表
  getSupplyOrderInvoiceApplyRecords (params) {
    return Ajax.post('/platform/platOpenInvoice/listPagePlatformApplyInvoiceFlow', params)
  },

  // 云供应发票- 获取申请历史列表
  getSupplyOrderInvoiceOpenedDetails (params) {
    return Ajax.post('/platform/platOpenInvoice/getPlatformOpenInvoiceInfo', params)
  },

  // 云供应发票- 重新申请开票
  submitSupplyOrderInvoiceRepeatOpening (params) {
    return Ajax.post('/platform/platOpenInvoice/invoiceRecordOpenReload', params)
  }

}
