import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Descriptions, Progress, Divider } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'

export default class AutoQuotationProgressModal extends Component {
  static propTypes = {
    enquiryOrderId: PropTypes.string,
    onCancel: PropTypes.func
  }
  state = {
    tableData: []
  }

  componentDidMount () {
    this.getAutoQuotationProgressDetails()
  }

  // 获取任务进度详情
  getAutoQuotationProgressDetails = async () => {
    const { enquiryOrderId } = this.props
    const res = await Api.getAutoQuotationProgressDetails({ enquiryOrderId: enquiryOrderId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  render () {
    const { onCancel } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title='任务进度' width={800} bodyStyle={{ height: '500px', overflowY: 'auto' }}
          visible
          onCancel={() => onCancel()}
          onOk={() => this.getAutoQuotationProgressDetails()}
          okText='刷新'
        >
          {
            tableData.length > 0
              ? <>
                {
                  tableData.map((item, index) => (
                    <div key={`progressItem-${index}`} style={{ marginBottom: '24px' }}>
                      <Descriptions>
                        <Descriptions.Item label='任务编号'>{ item.id }</Descriptions.Item>
                        <Descriptions.Item label='任务描述'>{ item.taskDesc }</Descriptions.Item>
                        <Descriptions.Item label='报价总数'>{ +item.quotationSkuTotal }</Descriptions.Item>
                        <Descriptions.Item label='操作人'>{ item.platformUserName }</Descriptions.Item>
                        <Descriptions.Item label='操作时间'>{ item.modifyTime ? moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</Descriptions.Item>
                      </Descriptions>

                      <Progress percent={item.status === 'COMPLETED' ? 100 : 70} />

                      { index === tableData.length - 1 ? null : <Divider dashed /> }
                    </div>
                  ))
                }
              </>
              : null
          }

        </Modal>
      </>
    )
  }
}
