import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Button, Select, Input, Checkbox } from 'antd'
import $lodash from 'lodash'

import filter from '@/common/utils/filter'

const { skuGoodsTypeMaps } = filter
const skuGoodsTypeOptions = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })
const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const CheckboxGroup = Checkbox.Group

class SearchForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    brandList: PropTypes.array,
    handleSubmit: PropTypes.func
  }
  state = {}

  handleSearch = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleSubmit(values)
      }
    })
  }

  render () {
    const { brandList = [] } = this.props
    const { getFieldDecorator } = this.props.form

    const options = $lodash.map(skuGoodsTypeOptions, (item) => {
      return {
        value: item.id,
        label: item.name
      }
    })

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <Form>
            <FormItem label='品牌' style={{ marginBottom: '4px' }}>
              {
                getFieldDecorator('brandId', {
                  rules: [
                    { required: true, message: '请选择品牌' }
                  ]
                })(
                  <Select showSearch optionFilterProp='children' placeholder='请选择...'>
                    {
                      brandList.map((brand, n) => (
                        <Option value={brand.brandId} key={`brandOption-${brand.brandId}`}>{brand.showName}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>

            <FormItem label='订货号' style={{ marginBottom: '4px' }}>
              {
                getFieldDecorator('paramStr', {
                  rules: [
                    { required: true, message: '请输入订货号...' }
                  ]
                })(
                  <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='产品类型'>
              {
                getFieldDecorator('skuGoodsTypeList', {
                  rules: [
                    { required: true, message: '请选择产品类型...' }
                  ]
                })(
                  <CheckboxGroup options={options} />
                )
              }
            </FormItem>

            <FormItem>
              <Button type='primary' onClick={() => this.handleSearch()}>
                搜索
              </Button>
            </FormItem>
          </Form>
        </Card>
      </>
    )
  }
}

export default Form.create()(SearchForm)
