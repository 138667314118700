import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Button, Icon, Card, Table } from 'antd'

import { downloadExcel } from '@/common/utils/downloadFile'

export default class NumbersTable extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }
  state = {}
  columns = [
    {
      title: '销售大区',
      key: 'merchantNumbersCol-0',
      dataIndex: 'name'
    },
    {
      title: '新增域名备案商户数',
      key: 'merchantNumbersCol-1',
      dataIndex: 'recordNumber'
    },
    {
      title: '新增下单商户数',
      key: 'merchantNumbersCol-2',
      dataIndex: 'orderNumber'
    }
  ]

  handleDownload = () => {
    const { tableData = [] } = this.props
    let arr = [
      ['销售大区', '新增域名备案商户数', '新增下单商户数']
    ]

    tableData.forEach((item, i) => {
      let rowArr = []
      rowArr.push(item.name, item.recordNumber, item.orderNumber)
      arr.push(rowArr)
    })

    downloadExcel(arr, '商户数量统计表.xlsx')
  }

  render () {
    const { tableData = [] } = this.props

    return (
      <Row style={{ marginTop: '20px' }}>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Button type='primary' disabled={tableData.length === 0} onClick={() => this.handleDownload()}>
              <Icon type='download' />导出数据
            </Button>
          </Row>
          <Table rowKey={(record, index) => `merchantNumbersCol-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Card>
      </Row>
    )
  }
}
