import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Select, Input, message } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const FormItem = Form.Item
const Option = Select.Option
const { MAX_PAGESIZE } = consts
const modalKeyMap = {
  'platformUser': { title: '分配运营', formItemLabel: '分配运营' },
  'belongUser': { title: '设置归属', formItemLabel: '客户采购归属为' },
  'supplyBelongUser': { title: '设置归属', formItemLabel: '客户供应归属为' }
}

class DistributionForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    modalKey: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    list: []
  }

  componentDidMount () {
    this.getMemberList()
  }

  // 获取全部成员
  getMemberList = async () => {
    const res = await Api.getMemberList({ pageNo: 1, pageSize: MAX_PAGESIZE })
    const { code, data } = res
    if (+code === 10000) {
      if (data && data.list && data.list.length > 0) {
        this.setState({
          list: $lodash.concat(data.list, [{ id: 0, userName: '无' }])
        })
      } else {
        message.warning('暂无数据')
      }
    }
  }

  // 点击确定
  handleConfirm = () => {
    const { form, modalKey, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        onConfirm(values, modalKey)
      }
    })
  }

  // 点击选择
  handleChangeIdSelect = (value) => {
    const { form, modalKey } = this.props
    const { list = [] } = this.state

    const obj = $lodash.find(list, (record) => { return record.id === value })

    form.setFieldsValue({ [`${modalKey}RealName`]: obj.userName })
  }

  render () {
    const { form, detailInfo = {}, modalKey = 'platformUser' } = this.props
    const { getFieldDecorator } = form
    const { list = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title={modalKeyMap[modalKey].title}
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <div style={{ display: 'none' }}>
              <FormItem label='商户ID' {...formItemLayout}>
                {
                  getFieldDecorator('distributorId', {
                    initialValue: detailInfo.distributorId
                  })(<Input disabled autoComplete='off' />)
                }
              </FormItem>
            </div>

            <div style={{ display: 'none' }}>
              <FormItem label='名称' {...formItemLayout}>
                {
                  getFieldDecorator(`${modalKey}RealName`, {
                    initialValue: detailInfo[`${modalKey}RealName`] || '无'
                  })(<Input disabled autoComplete='off' />)
                }
              </FormItem>
            </div>

            <FormItem label={modalKeyMap[modalKey].formItemLabel} {...formItemLayout}>
              {
                getFieldDecorator(`${modalKey}Id`, {
                  initialValue: detailInfo[`${modalKey}Id`] || 0,
                  rules: [
                    { required: true, message: `请选择${modalKeyMap[modalKey].label}` }
                  ]
                })(
                  <Select showSearch optionFilterProp='children' onChange={(value) => this.handleChangeIdSelect(value)}>
                    {
                      list.map((item) => (
                        <Option value={item.id} key={item.id}>{ item.userName }</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const distributionModal = Form.create()(DistributionForm)

export default distributionModal
