import React, { Component } from 'react'
import { Card, Tabs } from 'antd'

import UnOpenInvoiceList from './unopenInvoiceList/index'
import OpenedInvoiceList from './openedInvoiceList/index'

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'OPEN', name: '待申请' },
  { id: 'OPEND', name: '申请历史' }
]

class SupplyInvoice extends Component {
  state = {
    activeKey: 'OPEN'
  }

  componentDidMount () {}

  // 点击选择tab类型
  handleChangeTabPane = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'OPEN' } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <div className={`${styles.pageTabLand}`}>
              <Tabs onChange={this.handleChangeTabPane}>
                {
                  tabPaneMaps.map(item => (
                    <TabPane tab={item.name} key={item.id} />
                  ))
                }
              </Tabs>
            </div>
          </Card>
        </div>

        {
          activeKey === 'OPEN'
            ? <UnOpenInvoiceList />
            : null
        }

        {
          activeKey === 'OPEND'
            ? <OpenedInvoiceList />
            : null
        }
      </>
    )
  }
}

export default SupplyInvoice
