import React, { Component } from 'react'
import { Modal, Row, Timeline } from 'antd'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

export default class progressModal extends Component {
  static propTypes ={
    onCancel: PropTypes.func,
    rowDetail: PropTypes.object
  }

  state = {
    initTime: this.props.rowDetail.initTime,
    datelist: this.props.rowDetail.detailList
  }

  // 关闭
  handleOk=() => {
    this.props.onCancel()
  }

  render () {
    const { initTime, datelist = [] } = this.state
    return (
      <>
        <Modal title='初始化详情' visible bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
          onOk={() => { this.handleOk() }}
          onCancel={() => { this.props.onCancel() }}
          footer={null}
        >
          <Row gutter={30}>
            <div style={{ height: '40px', background: '#EEEEEE', marginBottom: '20px' }}>
              <label className={'mr10'} style={{ lineHeight: '40px', paddingLeft: '20px' }}>初始化时间：</label><label>{initTime}</label></div>
            <Timeline >
              {
                datelist.map((item, i) => (
                  <Timeline.Item style={{ marginLeft: '20px' }} key={i} color={item.status === 'INITED' ? '#52c41a' : '#f5222d'}>
                    {item.name} <span className={item.status === 'INITED' ? styles.success : styles.error} style={{ marginLeft: '20px' }}>{item.status === 'INITED' ? '已完成' : '未完成' }</span>
                  </Timeline.Item>
                ))
              }
            </Timeline>
          </Row>

        </Modal>
      </>
    )
  }
}
