import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Button } from 'antd'

import WorkOrderContents from '../workOrderContents'

import styles from './index.module.scss'

class WorkOrderDrawer extends Component {
  static propTypes = {
    history: PropTypes.object,
    visible: PropTypes.bool,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {}

  render () {
    const { history, visible = false, detailInfo = {}, onCancel } = this.props
    return (
      <>
        <Drawer title='协作工单' width={1300} bodyStyle={{ paddingBottom: '80px' }}
          visible={visible}
          destroyOnClose
          placement='right'
          closable={false}
          onClose={() => onCancel()}
        >
          {
            visible
              ? <WorkOrderContents
                history={history}
                detailInfo={detailInfo}
                onCancel={() => onCancel()}
              />
              : null
          }
          <div className={`${styles.btns}`}>
            <Button onClick={() => onCancel()}>关闭</Button>
          </div>
        </Drawer>
      </>
    )
  }
}

export default WorkOrderDrawer
