import React, { Component } from 'react'
import { Row, Card, Col, Statistic } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api'

import filter from '@/common/utils/filter'

const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')
// const CURRENTMONTH = moment().get('month')

export default class index extends Component {
  state = {
    searchData: [
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { month: 0, monthLabel: '全年' },
          ...fullsMonthOptions
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['month', 'monthLabel']
      }
    ],
    tableData: [],
    query: {},
    skuNum: 0, // 产品数据数量
    imageNum: 0, // 图片数据数量
    skuNumSeries: [], // 产品数据数量Echarts
    imageSeries: []// 图片数据数量Echarts
  }

  // 获取报表数据
  getSalesStatistics = async () => {
    const { query = {} } = this.state
    const res = await Api.getPerformanceStatistics({ type: 'data_input_department', user_id: 5 })
    let skuObj = res.list_commodity_sku_count // 产品数据数量总数据
    let imageObj = res.list_commodity_image_count // 图片数据数量总数据

    // 产品数据数量
    let skuDatas = []
    $lodash.forEach(skuObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        skuDatas.push(obj)
      }
    })

    // 图片数据数量
    let imageDatas = []
    $lodash.forEach(imageObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        imageDatas.push(obj)
      }
    })
    // 产品数据数量Echarts数据
    let skuNumEcharts = []
    $lodash.forEach(skuObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: value }
        skuNumEcharts.push(obj)
      }
    })

    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const skuNumEchartsdatas = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(skuNumEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 图片数据数量Echarts数据
    let imageNumEcharts = []
    $lodash.forEach(imageObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: value }
        imageNumEcharts.push(obj)
      }
    })

    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const imageNumEchartsdatas = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(imageNumEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    this.setState({
      imageNum: imageDatas.length > 0 ? $lodash.sumBy(imageDatas, 'value') : 0, // 图片数据数量
      skuNum: skuDatas.length > 0 ? $lodash.sumBy(skuDatas, 'value') : 0, // 产品数据数量
      skuNumSeries: skuNumEchartsdatas, // 云采购销售金额（发货）Echarts
      imageSeries: imageNumEchartsdatas// 新增成交采购商Echarts数据
    }, () => {
      this.initECharts()
    })
  }

  // 初始化图表
  initECharts = () => {
    const { skuNumSeries, imageSeries } = this.state

    var myChart = echarts.init(document.getElementById('dataStatisticsEcharts'))
    // 绘制图表
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${YEAR}数据统计折线图`
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['产品数据数量', '图片数据数量']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisLabel: {
          formatter: `{value}月`
        }
      },
      yAxis: [ {
        type: 'value',
        name: '产品数据数量',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} 个'
        }
      },
      {
        type: 'value',
        name: '图片数据数量',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} 个'
        }
      }],
      series: [
        {
          name: '产品数据数量',
          type: 'line',
          yAxisIndex: 0,
          itemStyle: { normal: { label: { show: true } } },
          data: skuNumSeries
        },
        {
          name: '图片数据数量',
          type: 'line',
          yAxisIndex: 1,
          itemStyle: { normal: { label: { show: true } } },
          data: imageSeries
        }
      ]
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    }, () => {
      this.getSalesStatistics()
    })
  }

  render () {
    const { searchData, skuNum, imageNum } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px', paddingLeft: '30px' }}>
          <Col span={6} >
            <Statistic title='产品数据数量' value={skuNum} style={{ color: 'black' }} />
          </Col>
          <Col span={6}>
            <Statistic title='图片数据数量' value={imageNum} />
          </Col>
        </Row>
        <Card bodyStyle={{ padding: '12px' }}>
          <div id='dataStatisticsEcharts' style={{ width: '100%', height: '500px' }} />
        </Card>
      </div>
    )
  }
}
