import React, { Component } from 'react'
import { Table, Modal, message, Button, Card } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form'
import RecordModal from './invoiceRecordModal/index'
import LogisticsModal from '@/components/logisticsModal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderInvoiceTypeLabel, renderInvoiceOpenedStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, invoiceTypeMaps, invoiceOpenedStatusMaps } = filter
const { confirm } = Modal
const _invoiceOpenedStatusMaps = $lodash.filter(invoiceOpenedStatusMaps, (o) => { return o.id !== 'WAIT_TO_APPLY' && o.id !== 'OPENING' })

class PurchaseInvoiceOpened extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'invoiceType',
        formType: 'Select',
        itemParams: {
          label: '发票类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...invoiceTypeMaps
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '开票时间'
        },
        cptParams: {

        }
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '修改时间'
        },
        cptParams: {

        }
      },
      {
        name: 'createName',
        formType: 'Input',
        itemParams: {
          label: '开票人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '开票状态'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ..._invoiceOpenedStatusMaps
        ]
      },
      {
        name: 'remark',
        formType: 'Input',
        itemParams: {
          label: '备注'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    detailModal: false,
    logisticsModal: false, // 物流弹窗
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'invoiceRecord-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'invoiceRecord-1',
      width: 160,
      ellipsis: true,
      dataIndex: 'distributorName'
    },
    {
      title: '发票类型',
      key: 'invoiceRecord-2',
      dataIndex: 'invoiceType',
      render: (text, record, index) => (
        <>{ renderInvoiceTypeLabel(record.invoiceType) }</>
      )
    },
    {
      title: '开票金额',
      key: 'invoiceRecord-3',
      dataIndex: 'amount',
      render: (text, record, index) => (
        <>&yen;{ (+record.amount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '实际开票金额',
      key: 'invoiceRecord-4',
      dataIndex: 'realAmount',
      render: (text, record, index) => (
        <>&yen;{ (+record.realAmount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '开票时间',
      key: 'invoiceRecord-5',
      dataIndex: 'invoicedTime',
      render: (text, record, index) => (
        <>{ record.invoicedTime ? moment(record.invoicedTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '修改时间',
      key: 'invoiceRecord-6',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '开票人',
      key: 'invoiceRecord-7',
      dataIndex: 'createName'
    },
    {
      title: '开票状态',
      key: 'invoiceRecord-8',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.status) }</>
      )
    },
    {
      title: '备注',
      key: 'invoiceRecord-9',
      ellipsis: true,
      dataIndex: 'remarks'
    },
    {
      title: '操作',
      key: 'invoiceRecord-10',
      width: 200,
      render: (text, record, index) => (
        <>
          {
            record.status === 'DISCARDED'
              ? null
              : <>
                <Button type='link' size='small' onClick={() => this.showRecordDetail(record)}>查看详情</Button>
                <Button type='link' size='small' onClick={() => this.handleDiscardApplyInvoice(record)}>重新开票</Button>
                <Button type='link' size='small' onClick={() => this.handleShowLogisticsModal(record)}>查看物流</Button>
                <Button type='link' size='small' onClick={() => this.handleDownloadFile(record)}>导出文件</Button>
              </>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderInvoiceOpenedList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取云采购发票- 开票完成列表
  getPurchaseOrderInvoiceOpenedList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getPurchaseOrderInvoiceOpenedList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 云采购发票- 开票完成-更新发票信息
  updatePurchaseOrderInvoiceOpenedDetails = async (params) => {
    const res = await Api.updatePurchaseOrderInvoiceOpenedDetails(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderInvoiceOpenedList()
    }
  }

  // 废弃开票申请记录-重新开票
  submitPurchaseOrderInvoiceRepeatOpening = (params) => {
    Api.submitPurchaseOrderInvoiceRepeatOpening(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getPurchaseOrderInvoiceOpenedList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search['createBeginDate'] = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search['createBeginDate'] = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    if (search.modifyTimes) {
      search['modifyBeginDate'] = search.modifyTimes.length === 2 ? search.modifyTimes[0].format('YYYY-MM-DD') : undefined
      search['modifyEndDate'] = search.modifyTimes.length === 2 ? search.modifyTimes[1].format('YYYY-MM-DD') : undefined

      delete search.modifyTimes
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getPurchaseOrderInvoiceOpenedList()
    })
  }

  // 点击查看发票详情
  showRecordDetail = (record = {}) => {
    this.setState({
      rowDetail: record,
      detailModal: true
    })
  }

  // 点击重新开票
  handleDiscardApplyInvoice = (record) => {
    confirm({
      title: '确定重新申请开票？',
      onOk: () => {
        this.submitPurchaseOrderInvoiceRepeatOpening({ id: record.id })
      }
    })
  }

  // 点击导出文件
  handleDownloadFile = (record = {}) => {
    DownloadApi.downloadPurchaseOrderInvoiceOpenedFile({ id: record.id })
  }

  // 点击查看物流
  handleShowLogisticsModal = (record = {}) => {
    this.setState({
      rowDetail: { courierCode: record.courierCode, courierNumber: record.courierNumber, mobile: record.phoneNumber },
      logisticsModal: true
    })
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      logisticsModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchaseOrderInvoiceOpenedList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchaseOrderInvoiceOpenedList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], rowDetail = {}, detailModal = false, logisticsModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
            scroll={{ x: 1400 }}
          />
        </Card>

        {
          detailModal
            ? <RecordModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.updatePurchaseOrderInvoiceOpenedDetails(params)}
            />
            : null
        }

        {
          logisticsModal
            ? <LogisticsModal
              courierNumberList={[rowDetail]}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default PurchaseInvoiceOpened
