import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Card } from 'antd'

import WorkHeader from '../components/workHeader/index'
import WorkContents from '../components/workContents/index'
import WorkEditable from '../components/workEditTable/index'
import TeamWorkContents from '../components/TeamWorkContents/index'
import TeamWorkEditTable from '../components/TeamWorkEditTable/index'

import Api from '@/common/api/index'

export default class WorkDetails extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    detailInfo: {},
    enquiryWorkOrderSkuInfoList: [],
    tableData: []
  }

  componentDidMount () {
    const { id, workOrderType } = this.props.history.location.state
    this.getWorkOrderDetail({ id: id, workOrderType: workOrderType })
  }

  // 获取工单详情
  getWorkOrderDetail = async (params) => {
    const res = await Api.getWorkOrderDetail(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        detailInfo: data || {},
        enquiryWorkOrderSkuInfoList: data.workOrderSkuInfoList || [],
        tableData: data.quotationDetails || []
      })
    }
  }

  handleBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { detailInfo = {}, enquiryWorkOrderSkuInfoList = [], tableData } = this.state
    const enquiryWorkOrderSkuInfo = enquiryWorkOrderSkuInfoList[0] || {}

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}><Icon type='left' />返回列表</Button>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <WorkHeader detailInfo={detailInfo} />
          {
            detailInfo.workOrderType === 'HELP_LIST_QUOTATION'
              ? <>
                <TeamWorkContents
                  isPreview
                  list={enquiryWorkOrderSkuInfoList}
                />

                <TeamWorkEditTable
                  isPreview
                  list={tableData}
                  onChangeInput={(value, dataIndex, index) => this.handleChangeInput(value, dataIndex, index)}
                  onAddRow={(index) => this.handleAddRow(index)}
                  onDeleteRow={(keys) => this.handleDeleteRow(keys)}
                />
              </>
              : <>
                <WorkContents
                  isPreview
                  info={enquiryWorkOrderSkuInfo}
                  distributorName={detailInfo.distributorName}
                />

                <WorkEditable
                  isPreview
                  list={tableData}
                />
              </>
          }

        </Card>
      </>
    )
  }
}
