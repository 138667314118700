import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Card, Tag, Button, message } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import UploadSkuImageModal from '../component/uploadSkuImageModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderCloudSupplyExamineStatusLabel } from '@/common/utils/mUtils'

const { repLineBreak, cloudSupplyExamineMaps, pageSizeOptions } = filter

export default class ImageExamine extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '上传时间'
        },
        cptParams: {

        }
      },
      {
        name: 'auditStatus',
        formType: 'Select',
        itemParams: {
          label: '审核状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudSupplyExamineMaps
        ]
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    uploadImageModal: false,
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'disputeSku-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'disputeSku-1',
      dataIndex: 'merchantName'
    },
    {
      title: '品牌',
      key: 'sku-2',
      width: 150,
      render: (text, record, index) => (
        <div>
          <div>{record.brandName}</div>
          <div>{record.englishBrandName}</div>
        </div>
      )
    },
    {
      title: '型号',
      key: 'disputeSku-2',
      width: 150,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.model }} />
          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'disputeSku-3',
      ellipsis: true,
      width: 150,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'disputeSku-4',
      ellipsis: true,
      dataIndex: 'name',
      width: 180
    },
    {
      title: '上传时间',
      key: 'disputeSku-5',
      dataIndex: 'uploadTime',
      render: (text, record, index) => (
        <>{ record.uploadTime ? record.uploadTime : '----' }</>
      )
    },
    {
      title: '审核状态',
      key: 'disputeSku10',
      dataIndex: 'auditStatus',
      render: (text, record, index) => (
        <>{ renderCloudSupplyExamineStatusLabel(record.auditStatus) }</>
      )
    },
    {
      title: '审核时间',
      key: 'disputeSku-7',
      dataIndex: 'auditTime',
      render: (text, record, index) => (
        <>{record.auditTime ? record.auditTime : '----'}</>
      )
    },
    {
      title: '操作人',
      key: 'disputeSku-8',
      dataIndex: 'operator',
      render: (text, record, index) => (
        <>{ record.operator ? record.operator : '----' }</>
      )
    },
    {
      title: '操作',
      key: 'disputeSku-9',
      render: (text, record, index) => (
        <Button type='link' size='small' onClick={() => this.showImageDetailModal(record)}>查看详情</Button>
      )
    }

  ]

  componentDidMount () {
    this.getImgexamineList()
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
  }

  // 查询列表
  getImgexamineList = async () => {
    const { query = {}, pages } = this.state

    const res = await Api.getImgexamineList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取图片详情
  getImgDetail = (params) => {
    return Api.getImgDetail(params)
  }

  // 保存图片设置
  saveMerchantSkuImageModify = async (params) => {
    const res = await Api.saveMerchantSkuImageModify(params)
    const { code } = res
    if (+code === 10000) {
      message.success('应用成功')
      this.closeModal()
      this.getImgexamineList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { itemNo, times } = search

    if (itemNo && itemNo !== '') {
      let num = repLineBreak(search.itemNo, '<br>')
      search['itemNo'] = num
    }

    if (times) {
      search.beginDateStr = times.length === 2 ? moment(times[0]).format('YYYY-MM-DD') : undefined
      search.endDateStr = times.length === 2 ? moment(times[1]).format('YYYY-MM-DD') : undefined
    }

    this.setState({
      query: {
        ...search
      },
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => this.getImgexamineList())
  }

  // 打开详情页
  showImageDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      uploadImageModal: true
    })
  }

  // 点击关闭弹窗
  handleCancelModal = () => {
    this.closeModal()
    this.getImgexamineList()
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getImgexamineList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getImgexamineList()
    })
  }

  // 关闭详情页
  closeModal =() => {
    this.setState({
      uploadImageModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { history } = this.props
    const { pages, searchData, tableData = [], rowDetail = {}, uploadImageModal = false } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey={(record, index) => `${record.distributorId}-${record.commoditySkuId}-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        {
          uploadImageModal
            ? <UploadSkuImageModal history={history}
              detailInfo={rowDetail}
              getImageDetails={(params) => this.getImgDetail({ ...params, distributorId: rowDetail.distributorId })}
              onConfirm={(params) => this.saveMerchantSkuImageModify({ ...params, distributorId: rowDetail.distributorId })}
              onCancel={() => this.handleCancelModal()}
            />
            : null
        }
      </>

    )
  }
}
