import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Breadcrumb, Icon, Dropdown, Menu, Button, Badge, Tabs, Empty } from 'antd'
import { setUserInfo, setUserMenu, setUnnoticeCount, setNoticeDetail } from '@/reducers/action'

import Api from '@/common/api/index'

import filter from '@/common/utils/filter'
import { removeStore } from '@/common/utils/mUtils'
import { routerMap } from '@/routes/router'

import styles from './index.module.scss'

const { notifyTypeMaps } = filter
const { TabPane } = Tabs

class HeaderBar extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    userInfo: PropTypes.object,
    unnoticeCount: PropTypes.number
  }

  state = {
    unreadList: [],
    noticeVisible: false // 消息下拉窗
  }

  componentDidMount () {}

  // 获取已接收的未读消息列表
  getReceivedInformationList = () => {
    let params = {
      msgType: 'ALL',
      msgReadType: 'UNREAD',
      pageNo: 1,
      pageSize: 10
    }

    Api.getReceivedInformationList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = []
        if (data && data.list) {
          _list = data.list.slice(0, 5)
        }
        this.setState({
          unreadList: _list
        })
      }
    })
  }

  // 点击菜单
  handleMenuClick = (key) => {
    if (+key === 1) {
      this.props.history.push('/admin/setAccount')
    }

    if (+key === 2) {
      this.handleLoginOut()
      return false
    }
  }

  // 退出登录
  handleLoginOut = async () => {
    const res = await Api.loginout({})
    const { code } = res
    if (+code === 10000) {
      removeStore('token')
      this.props.dispatch(setUserInfo({ userInfo: {} }))
      this.props.dispatch(setUserMenu({ menus: [] }))
      this.props.dispatch(setUnnoticeCount({ unnoticeCount: 0 }))
      this.props.history.push('/login')
    }
  }

  // 点击跳转到消息详情
  handleLinkToNotifyDetail = (record) => {
    this.setState({
      noticeVisible: false
    }, () => {
      this.props.dispatch(setNoticeDetail({ noticeDetail: record }))
      this.props.history.push({ pathname: `/admin/notifyDetail` })
    })
  }

  // 点击查看更多消息
  handleLinkToInformationList = () => {
    this.setState({
      noticeVisible: false
    }, () => {
      this.props.history.push({ pathname: '/admin/informationList', state: { activeTab: 'RECEIVED' } })
    })
  }

  // 监听下拉菜单click事件
  changeNoticeVisible = (visible) => {
    this.setState({
      noticeVisible: visible
    }, () => {
      if (visible) {
        this.getReceivedInformationList()
      }
    })
  }

  // 消息下拉窗
  renderDropdownMenuElement = (unreadList) => {
    return (
      <Tabs>
        <TabPane tab={'通知消息'} key='dropTabPane-1'>
          <Row className={`${styles.noticeDropWrap}`}>
            <Row className={`${styles.noticeDropContainer}`}>
              {
                unreadList.length > 0
                  ? <Row>
                    <Row className={`${styles.noticeHeader}`}>
                      <div className={`${styles.dropTitle}`}>未读消息</div>
                      <div className={`${styles.dropAction}`} onClick={() => this.handleLinkToInformationList()}>查看更多</div>
                    </Row>

                    <div className={`${styles.noticeBody}`}>
                      {
                        unreadList.map((item, i) => (
                          <a className={`${styles.noticeItem}`} key={`unnotice-${item.id}-${i}`}
                            onClick={() => this.handleLinkToNotifyDetail(item)}
                          >
                            <span>{this.renderNoticeTypeLabel(item.msgType)}</span>
                            <span style={{ marginLeft: '5px' }}>{item.title}</span>
                          </a>
                        ))
                      }
                    </div>
                  </Row>
                  : <Row>
                    <Empty description='暂无消息' />
                  </Row>
              }
            </Row>
          </Row>
        </TabPane>
      </Tabs>
    )
  }

  // 渲染用户下拉菜单
  renderDropMenu = () => {
    return (
      <Menu onClick={(item) => this.handleMenuClick(item.key)}>
        <Menu.Item key={1}>
          <Icon type='user' />修改密码
        </Menu.Item>
        <Menu.Item key={2}>
          <Icon type='logout' />退出登录
        </Menu.Item>
      </Menu>
    )
  }

  // 渲染消息类型
  renderNoticeTypeLabel = (status) => {
    let str = ''
    let obj = notifyTypeMaps.find(item => { return item.id === status })

    if (obj) {
      str = `[${obj.name}]`
    }

    return str
  }

  render () {
    const { location, userInfo = {}, unnoticeCount = 0 } = this.props
    const { unreadList = [], noticeVisible = false } = this.state

    return (
      <div className={`${styles.headerBar}`}>
        <div className={`${styles.customBread}`}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/admin/home'>首页</Link>
            </Breadcrumb.Item>
            {
              routerMap.map((route, i) => {
                if (route.path && location.pathname === route.path) {
                  return (<Breadcrumb.Item key={i}>{route.name}</Breadcrumb.Item>)
                }
              })
            }
          </Breadcrumb>
        </div>
        <div className={`${styles.customCon}`}>
          <Dropdown trigger={['click']} visible={noticeVisible}
            overlay={this.renderDropdownMenuElement(unreadList)}
            onVisibleChange={this.changeNoticeVisible}
          >
            <Button type='link'>
              <Badge count={unnoticeCount}>
                <Icon type='bell' style={{ fontSize: '16px' }} />
              </Badge>
            </Button>
          </Dropdown>

          <Dropdown overlay={this.renderDropMenu()} placement='bottomCenter'>
            <Button type='link'>
              <span>{userInfo.loginName}</span>
              <Icon type='caret-down' style={{ color: 'rgba(153, 153, 153, 0.8)' }} />
            </Button>
          </Dropdown>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    unnoticeCount: state.unnoticeCount
  }
}

export default connect(mapStateToProps)(withRouter(HeaderBar))
