import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Table } from 'antd'
import moment from 'moment'
import { setNoticeDetail } from '@/reducers/action'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const msgTypeSearchMaps = [
  { id: 'PLATFORM_ANNOUNCEMENT', name: 'IIASaaS公告' }
]

class SendedInformation extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'msgType',
        formType: 'Select',
        itemParams: {
          label: '信息类型'
        },
        cptParams: {

        },
        options: msgTypeSearchMaps,
        rules: {
          initialValue: 'PLATFORM_ANNOUNCEMENT'
        }
      },
      {
        name: 'sendTime',
        formType: 'RangePicker',
        itemParams: {
          label: '发送时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      msgType: 'PLATFORM_ANNOUNCEMENT'
    }
  }

  columns = [
    {
      title: '序号',
      key: 'sendedInformation-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '信息类型',
      key: 'sendedInformation-1',
      render: (text, record, index) => (
        <div>{this.renderMsgTypeLabel(record.msgType)}</div>
      )
    },
    {
      title: '标题',
      key: 'sendedInformation-2',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '内容',
      key: 'sendedInformation-3',
      ellipsis: true,
      render: (text, record, index) => (
        <a onClick={() => this.handleLinkToNotifyDetail(record)}>点击查看内容</a>
      )
    },
    {
      title: '发送时间',
      key: 'sendedInformation-4',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '发送对象',
      key: 'sendedInformation-5',
      render: (text, record, index) => (
        <div>全部商户</div>
      )
    },
    {
      title: '发布人',
      key: 'sendedInformation-6',
      dataIndex: 'sendName'
    },
    {
      title: '操作',
      key: 'sendedInformation-7',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.handleLinkToNotifyDetail(record)}>查看</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getIIASaaSSystemNoticeList()
  }

  // 获取已发送的IIASaaS公告
  getIIASaaSSystemNoticeList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      status: 'SENT',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getIIASaaSSystemNoticeList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.sendTime && search.sendTime.length === 2) {
      search['beginDate'] = moment(search.sendTime[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.sendTime[1]).format('YYYY-MM-DD')

      delete search.sendTime
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getIIASaaSSystemNoticeList()
    })
  }

  // 点击跳转到消息详情
  handleLinkToNotifyDetail = (record = {}) => {
    this.props.dispatch(setNoticeDetail({ noticeDetail: record }))
    this.props.history.push({ pathname: '/admin/notifyDetail' })
  }

  // 渲染消息类型
  renderMsgTypeLabel = (status) => {
    let str = ''
    let obj = msgTypeSearchMaps.find((item) => {
      return item.id === status
    })

    if (obj) {
      str = obj.name
    }

    return str
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getIIASaaSSystemNoticeList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getIIASaaSSystemNoticeList()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>
          <Row>
            <Table rowKey='id' bordered
              dataSource={tableData}
              columns={this.columns}
              pagination={pagination}
            />
          </Row>
        </Card>
      </div>
    )
  }
}

export default connect()(SendedInformation)
