/* 询价处理页面 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Button, message, Modal } from 'antd'
import $lodash from 'lodash'

import EnquireProductPreview from '../components/enquirePreviewTable/index'
import Remarks from '../components/remarks/index'
import EnquireProductHandle from './enquireProducts/index'
import EnquireDrawer from './enquireDrawer/index'

import Api from '@/common/api/index'

const { confirm, success, error } = Modal

class EnquireForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    detailInfo: {},
    tableData: [],
    detailCorrections: [], // 修正的产品列表
    enquireRemarks: [], // 询价单备注信息列表
    quotationdatas: {}, // 预览报价单信息
    quotationRemarks: [], // 报价单备注信息列表
    quotationDesc: '', // 报价单描述信息
    drawerModal: false, // 预览弹窗
    quotationProductList: [] // 预览报价单数据
  }

  componentDidMount () {
    const { detailInfo = {} } = this.props.history.location.state
    const { enquiryOrderDetails = [], enquiryRemarks = [] } = detailInfo

    this.setState({
      detailInfo: detailInfo,
      enquireRemarks: enquiryRemarks && enquiryRemarks.length > 0 ? [enquiryRemarks[0]] : [],
      tableData: enquiryOrderDetails
    }, () => {
      this.getCorrectEnquireOrderDetails()
    })
  }

  onRef = (ref) => { this.child = ref }

  // 获取询价单修正结果
  getCorrectEnquireOrderDetails = async () => {
    const { detailInfo = {} } = this.state
    const res = await Api.getCorrectEnquireOrderDetails({ orderId: detailInfo.orderId })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        detailCorrections: data.detailCorrections || []
      }, () => {
        if (data && data.remarks && data.remarks.length > 0) {
          this.props.form.setFieldsValue({ remarks: data.remarks[0].remarks || '' })
        }
      })
    }
  }

  /* 修正产品更新
   * 恢复报价、停止报价
  */
  updateCorrectEnquireOrderDetails = async (params) => {
    const res = await Api.updateCorrectEnquireOrderDetails(params)
    const { code } = res

    if (+code === 10000) {
      message.success('操作成功')
      this.child.closeModal()
      this.getCorrectEnquireOrderDetails()
    }
  }

  // 保存询价详情
  saveCorrectEnquireOrderDetails = async (params) => {
    const res = await Api.saveCorrectEnquireOrderDetails(params)
    const { code } = res
    if (+code === 10000) {
      this.child.closeModal()

      success({
        title: '操作成功',
        onOk: () => {
          this.props.history.go(-1)
        }
      })
    }
  }

  // 获取预览报价单数据
  getPreviewQuotationDetails = async (quotationParams) => {
    const res = await Api.getPreviewQuotationDetails(quotationParams)
    const { code, data } = res
    if (+code === 10000) {
      if (!data.previewResultStatus) {
        return message.warning(data.message)
      }

      this.setState({
        quotationdatas: data || {},
        quotationProductList: data.enquiryDetailCorrects || [],
        quotationRemarks: data.quotationRemark && data.quotationRemark !== '' ? [{ remarks: data.quotationRemark }] : [],
        quotationDesc: data.desc || '',
        drawerModal: true
      })
    }
  }

  // 生成报价单
  saveCreateQuotationOrder = async (params) => {
    const res = await Api.saveCreateQuotationOrder(params)
    const { code, data } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.closeDrawerModal()
    } else if (code === 'SOS_999999') {
      error({
        title: res.message,
        content: (<>
          {
            data && data.length > 0 && data.map((item) => (
              <p>{ item }</p>
            ))
          }
        </>)
      })
    }
  }

  /* 点击保存按钮&预览报价单按钮
   *
  */
  handleSaveEnquire = (ident) => {
    const { detailInfo = {}, detailCorrections = [] } = this.state

    if (detailCorrections.length === 0) { return message.warning('暂无数据~~') }

    // 找出标定售价 assignPrice > 最近成交价格 recentTransactionPrice
    const needwarndatas = $lodash.filter(detailCorrections, (record) => { return +record.recentTransactionPrice > 0 && +record.assignPrice > +record.recentTransactionPrice })

    const params = {
      orderId: detailInfo.orderId,
      quotationNumber: detailInfo.quotationNumber,
      remark: this.props.form.getFieldValue('remarks') || '',
      enquiryDetailCorrects: detailCorrections
    }

    if (ident === 'SAVE') {
      this.saveCorrectEnquireOrderDetails(params)
    }

    if (ident === 'PREVIEW') {
      if (needwarndatas.length > 0) {
        confirm({
          title: <><p>以下订货号标定售价大于最近成交价格</p><p>请确认是否使用此价格？</p></>,
          content: <ul>
            {
              needwarndatas.map((item, index) => (
                <li key={`needwarnItem-${item.itemNumber}-${index}`}>{ item.itemNumber }</li>
              ))
            }
          </ul>,
          onOk: () => {
            this.getPreviewQuotationDetails(params)
          }
        })
      } else {
        this.getPreviewQuotationDetails(params)
      }
    }
  }

  // 点击生成报价单
  handleCreateQuotation = (orderDetailList = []) => {
    const { detailInfo = {}, quotationdatas = {} } = this.state

    const params = {
      distributorId: detailInfo.distributorId,
      enquiryNumber: detailInfo.quotationNumber,
      orderId: detailInfo.orderId,
      orderDetailList: orderDetailList,
      enquiryRemarkId: quotationdatas.enquiryRemarkId,
      quotationRemarkId: quotationdatas.quotationRemarkId
    }

    this.saveCreateQuotationOrder(params)
  }

  // 点击取消按钮
  handleGoBack = () => {
    confirm({
      title: '确定取消？',
      content: '',
      onOk: () => {
        this.props.history.push({ pathname: '/admin/enquireOrderList' })
      }
    })
  }

  // 关闭弹窗
  closeDrawerModal = () => {
    this.setState({
      drawerModal: false
    })
  }

  render () {
    const { detailInfo = {}, tableData = [], detailCorrections = [], quotationProductList = [], enquireRemarks = [], quotationRemarks = [], quotationDesc = '', drawerModal = false } = this.state

    return (
      <>
        <div style={{ marginBottom: '24px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <div style={{ marginBottom: '15px' }}>
              <EnquireProductPreview list={tableData} />
            </div>

            <Remarks title='商户备注' remarks={enquireRemarks} />
          </Card>
        </div>

        <EnquireProductHandle {...this.props}
          onRef={this.onRef}
          orderId={detailInfo.orderId}
          enquiryOrderId={detailInfo.enquiryOrderId}
          list={detailCorrections}
          onChangeCollaborateState={(params) => this.updateCorrectEnquireOrderDetails(params)}
        />

        <div style={{ marginTop: '24px', textAlign: 'center' }}>
          <Button size='large' style={{ width: '120px' }} onClick={() => this.handleGoBack()}>取消</Button>
          <Button type='primary' size='large' style={{ marginLeft: '16px', width: '120px' }} onClick={() => this.handleSaveEnquire('SAVE')}>
            保存
          </Button>
          <Button type='primary' size='large' style={{ marginLeft: '16px', width: '120px' }} onClick={() => this.handleSaveEnquire('PREVIEW')}>
            预览报价单
          </Button>
        </div>

        <EnquireDrawer
          visible={drawerModal}
          list={detailCorrections}
          quotationList={quotationProductList}
          enquireRemarks={enquireRemarks}
          quotationRemarks={quotationRemarks}
          quotationDesc={quotationDesc}
          onCancel={() => this.closeDrawerModal()}
          onConfirm={(orderDetailList) => this.handleCreateQuotation(orderDetailList)}
        />
      </>
    )
  }
}

const EnquireHandle = Form.create()(EnquireForm)

export default EnquireHandle
