import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Input, Button, Icon, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import { connect } from 'react-redux'
import { setUserMenu } from '@/reducers/action'

import Api from '@/common/api/index'

import { setStore } from '@/common/utils/mUtils'
import styles from './login.module.scss'

const FormItem = Form.Item

class LoginForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    dispatch: PropTypes.func
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEnterKey)
    this.props.dispatch(setUserMenu({ menus: [] }))
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEnterKey)
  }

  // 监听enter按键事件
  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin()
    }
  }

  // 登录
  login = (params) => {
    Api.login(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setStore('token', data.userToken)
        this.props.history.push('/admin/home')
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击登录
  handleLogin = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { password } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['password'] = jsencrypt.encrypt(password)
        this.login(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div className={`${styles.loginWrapper}`}>
        <div className={`${styles.loginCon}`}>
          <Row className={`${styles.titleCon}`}>
            <h3 className={`${styles.title}`}>IIASaaS运营管理平台</h3>
            <p className={`${styles.titleEn}`}>Business Management System</p>
          </Row>
          <Row>
            <div className={`${styles.loginForm}`}>
              <Form>
                <FormItem>
                  {
                    getFieldDecorator('loginName', {
                      rules: [
                        { required: true, message: '请输入用户名!' }
                      ]
                    })(
                      <Input placeholder='请输入用户名'
                        style={{ height: '44px' }}
                        prefix={<Icon type='user' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                      />
                    )
                  }
                </FormItem>
                <FormItem>
                  {
                    getFieldDecorator('password', {
                      rules: [
                        { required: true, message: '请输入密码!' }
                      ]
                    })(
                      <Input.Password
                        placeholder='请输入密码'
                        style={{ height: '44px' }}
                        prefix={<Icon type='lock' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                      />
                    )
                  }
                </FormItem>
                <FormItem>
                  <Button type='primary' className={`${styles.loginButton}`}
                    onClick={(e) => this.handleLogin(e)}
                  >
                    登录
                  </Button>
                </FormItem>
              </Form>
            </div>
          </Row>
        </div>
      </div>
    )
  }
}

const Login = Form.create()(LoginForm)

export default connect()(Login)
