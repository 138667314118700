import React, { Component } from 'react'
import { Card, Row, Col, Divider, Button } from 'antd'
import * as echarts from 'echarts'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import MCOrderTable from './mcOrderTable'

import Request from '@/common/api/dashboardApi'
import filter from '@/common/utils/filter'
import { getYearOptions } from '@/common/utils/mUtils'

import styles from './index.module.scss'
import { Icon } from './../js/Icon' // 伸缩展开icon

// 当前年
const YEAR = moment().get('year')
const { fullsMonthOptions, echartscolorinit } = filter
const regionOptions = [
  { 'code': 1001, 'name': '华北地区' },
  { 'code': 1002, 'name': '华东地区' },
  { 'code': 1013, 'name': '西部地区' }

]

// 商户订单统计
export default class MCOrdersReport extends Component {
  static propTypes = {}
  state = {
    faly: 0,
    searchData: [
      {
        name: 'year',
        formType: 'Select',
        itemParams: {
          label: '查询年份'
        },
        cptParams: {
          placeholder: '请选择查询年份...'
        },
        rules: {
          initialValue: YEAR
        },
        options: getYearOptions(YEAR - 2019 + 1)
      },
      {
        name: 'regionCode',
        formType: 'Select',
        itemParams: {
          label: '查询地区'
        },
        cptParams: {
          placeholder: '请选择查询地区...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { code: 0, name: '全部' },
          ...regionOptions
        ],
        optionValue: ['code', 'name']
      }
    ],
    query: {
      year: YEAR,
      regionCode: 0
    },
    originData: {}, // 源数据
    pieSeries: [], // 饼图数据
    barSeries: [], // 柱状图数据
    tableData: [] // 表格数据
  }

  componentDidMount () {
    this.getDashboardAnalyse()
  }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const res = await Request.getDashboardAnalyse({ type: 'order_visual_by_merchant' })

    this.setState({
      originData: res && res.result && res.result.length > 0 ? this.setOriginData(res.result) : {}
    }, () => {
      this.initPieECharts()
      this.initBarECharts()
      this.setTableData()
    })
  }

  // 绘制饼图
  initPieECharts = () => {
    var pieChart
    if (document.getElementById('mcOrdersPieChart') == null) {
      return
    }
    echarts.dispose(document.getElementById('mcOrdersPieChart'))

    let series = this.setPieData()
    pieChart = echarts.init(document.getElementById('mcOrdersPieChart'))
    pieChart.setOption({
      color: echartscolorinit,
      tooltip: {
        trigger: 'item',
        formatter: (series) => {
          return `${series.marker} 订单数${series.value}`
        }
      },
      title: {
        text: `${this.getYearinit()}趋势图`,
        subtext: '销售大区',
        x: 'left',
        y: 'left'
      },
      legend: {
        orient: 'vertical',
        bottom: 'bottom'
      },
      series: [
        {
          type: 'pie',
          radius: '50%',
          label: {
            normal: {
              formatter: `{b}({d}%)`,
              textStyle: {
                fontWeight: 'normal',
                fontSize: 15
              }
            }
          },
          data: series,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }

        }
      ]
    })
  }

  // 绘制柱状图
  initBarECharts = () => {
    var barChart
    if (document.getElementById('mcOrdersBarChart') == null) {
      return
    }
    echarts.dispose(document.getElementById('mcOrdersBarChart'))
    let series = this.setBarData()
    barChart = echarts.init(document.getElementById('mcOrdersBarChart'))
    barChart.setOption({
      tooltip: {
        formatter: (series) => {
          return `${series.name} ${series.value} ${'(单)'}`
        }
      },
      color: ['#4FA8F9 '],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      title: {
        text: `萨斯云订单数量TOP10`,
        x: 'left',
        y: 'left'
      },
      xAxis: {
        type: 'category',
        data: ['top1', 'top2', 'top3', 'top4', 'top5', 'top6', 'top7', 'top8', 'top9', 'top10']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: series,
        type: 'bar'
      }]
    })
  }

  // 设置饼图数据格式
  setPieData = () => {
    const { query = {}, originData = {} } = this.state
    let result = []
    let pieObj = $lodash.groupBy(originData[query.year], 'name')
    $lodash.forEach(pieObj, (values, key) => {
      result.push({
        value: $lodash.sumBy(values, 'value'),
        name: key
      })
    })

    return result
  }

  // 地区转换
  getInitcode=(code) => {
    let name = ''
    let obj = regionOptions.find((item) => { return +item.code === +code })
    if (obj) {
      name = obj.name
    } else {
      name = '全部'
    }
    return name
  }

  // 设置柱状图数据格式
  setBarData = () => {
    const { query = {}, originData = {} } = this.state

    let barObj = $lodash.groupBy(originData[query.year], 'merchantName')
    let arr = []
    let result = []

    $lodash.forEach(barObj, (values, key) => {
      arr.push({
        value: $lodash.sumBy(values, 'value'),
        name: key
      })
    })

    arr = $lodash.orderBy(arr, ['value'], ['desc'])
    result = arr.length > 10 ? $lodash.slice(arr, 0, 10) : arr

    return result
  }

  // 设置表格数据格式
  setTableData = () => {
    const { query = {}, originData = {} } = this.state
    let tableObj = {}
    let result = []
    tableObj = $lodash.groupBy(originData[query.year], 'merchantName')
    $lodash.forEach(tableObj, (values, key) => {
      values = $lodash.unionBy(values, fullsMonthOptions, 'month')

      let obj = {
        name: values[0].name,
        recordDate: values[0].recordDate,
        totalNumbers: values[0].totalNumbers,
        yearNumbers: $lodash.sumBy(values, 'value'),
        merchantName: key,
        year: values[0].year
      }

      values.map((item) => {
        let i = $lodash.findIndex(fullsMonthOptions, (o) => { return o.month === item.month })
        if (i > -1) {
          obj[fullsMonthOptions[i]['monthTitle']] = item.value
        }
      })

      result.push(obj)
    })

    let newresult
    if (+query.regionCode === 0) {
      newresult = result.filter((item) => {
        return +item.year === +query.year
      })
    } else {
      newresult = result.filter((item) => {
        return +item.year === +query.year && item.name === this.getInitcode(query.regionCode)
      })
    }

    this.setState({
      tableData: newresult
    })
  }

  // 设置源数据格式
  setOriginData = (data = []) => {
    let arr = $lodash.map(data, (item) => {
      let obj = {
        value: item[0],
        year: item[1].split('-')[0],
        month: +item[1].split('-')[1],
        name: item[2].split(',')[0], // 销售大区
        regionCode: item[2].split(',')[1],
        merchantName: item[3], // 商户名称
        recordDate: item[4] // 域名备案日期
      }
      return obj
    })

    let result = []
    $lodash.forEach($lodash.groupBy(arr, 'merchantName'), (values, key) => {
      values.map((item) => {
        item['totalNumbers'] = $lodash.sumBy(values, 'value')
        result.push(item)
      })
    })

    return $lodash.groupBy(result, 'year')
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.year === undefined) {
      search.year = YEAR
      search.regionCode = 0
    }
    this.setState({
      query: search
    }, () => {
      this.getYearinit()
      this.initPieECharts()
      this.initBarECharts()
      this.setTableData()
    })
  }
  // 显示年份
  getYearinit=() => {
    const { query } = this.state
    return query.year
  }

  // 收起和展开
  getfaly =() => {
    const { faly = 0 } = this.state
    this.setState({
      faly: faly === 0 ? 1 : 0
    })
    let id = document.getElementById('rowid')
    id.style.height = faly === 0 ? '25px' : '400px'
  }
  render () {
    const { searchData, tableData = [], faly = 0 } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Row style={{ display: 'block', marginTop: '20px', whiteSpace: 'nowrap' }}>
          <Card>
            <Row id='rowid' className={styles.mianrow}>
              <Col span={12}>
                <div id='mcOrdersPieChart' style={{ width: '80%', height: '400px' }} />
              </Col>
              <Col span={12}>
                <div id='mcOrdersBarChart' style={{ width: '80%', height: '400px' }} />
              </Col>

            </Row>

            <div className={styles.line}> <Divider />
              <Button onClick={() => { this.getfaly() }} className={styles.aicon}><img src={Icon[faly].src} /></Button>
            </div>

          </Card>
        </Row>

        <MCOrderTable tableData={tableData} />
      </>
    )
  }
}
