import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Row, Col, Modal, message, Switch, Button, Statistic, Icon, Tooltip } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import MCLabelModal from '../component/MCLabelModal/index'
import ErpoperationModal from './erpoperationModal'
import InfoModal from './infoModal'
import AddressModal from '@/components/address-modal/index'
import DistributionModal from './distributionModal/index' // 分配运营弹窗
import PayBankDownFormModal from '@/pages/order/component/payBankDownModal/index' // 支付信息下载弹窗
import ColumnsConfigModal from '@/components/columnsConfigModal' // 列设置弹窗

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { setTableColumnKeys } from '@/reducers/action'
import RegionJson from '@/assets/json/region.json'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderCustomerAuthorLabel, renderRegionName } from '@/common/utils/mUtils'

const { pageSizeOptions, disableStatusMaps, erpEnableStatusMaps, authorMaps } = filter
const { MAX_PAGESIZE } = consts
const { confirm } = Modal

const columnConfigKey = 'CUSTOMER_LIST_TABLE'

class CustomerList extends Component {
  constructor (props) {
    super(props)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    userInfo: PropTypes.object,
    buttonMaps: PropTypes.object,
    columnsMap: PropTypes.object
  }

  state = {
    query: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorName',
        formType: 'Input',
        itemParams: {
          label: '公司名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'statisticsMerchantName',
        formType: 'Input',
        itemParams: {
          label: '统计名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '权限'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...authorMaps
        ]
      },
      {
        name: 'domainName',
        formType: 'Input',
        itemParams: {
          label: '域名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '备案时间'
        },
        cptParams: {}
      },
      {
        name: 'label',
        formType: 'Input',
        itemParams: {
          label: '标签'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'erpEnableStatus',
        formType: 'Select',
        itemParams: {
          label: 'ERP是否启用'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...erpEnableStatusMaps
        ]
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '是否启用'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...disableStatusMaps
        ]
      },
      {
        name: 'regionCode',
        formType: 'Select',
        itemParams: {
          label: '所属销售大区'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { code: '', name: '全部' },
          ...RegionJson
        ],
        optionValue: ['code', 'name']
      },
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '所属运营'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 9)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'belongUserId',
        formType: 'Select',
        itemParams: {
          label: '采购归属'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 10)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'supplyBelongUserId',
        formType: 'Select',
        itemParams: {
          label: '供应归属'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 11)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      }
    ],
    labelModal: false,
    erpoperationModal: false,
    distributionModal: false, // 分配运营弹窗
    type: 1, // 1-新增 2-编辑
    infomodal: false,
    addressModal: false, // 地址弹窗
    productModal: false, // 绑定产品弹窗
    payBankDownModal: false,
    columnsConfigModal: false, // 列设置弹窗
    tableData: [],
    rowDetail: {} // 当前选择的行数据
  }

  baseColumns = [
    {
      title: '序号',
      key: 'column-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户编号',
      key: 'customerListCol-distributorId',
      dataIndex: 'distributorId'
    },
    {
      title: '权限',
      key: 'customerListCol-type',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ renderCustomerAuthorLabel(record.type) }</>
      )
    },
    {
      title: '公司名称',
      key: 'customerListCol-name',
      dataIndex: 'name'
    },
    {
      title: '统计名称',
      key: 'customerListCol-statisticsMerchantName',
      dataIndex: 'statisticsMerchantName'
    },
    {
      title: '支付信息',
      key: 'customerListCol-payBankDetails',
      render: (text, record, index) => (
        <><a onClick={() => this.handleShowPayBankDownModal(record)}>点击下载</a></>
      )
    },
    {
      title: '域名',
      key: 'customerListCol-domainName',
      dataIndex: 'domainName'
    },
    {
      title: '备案时间',
      key: 'customerListCol-recordTime',
      dataIndex: 'recordTime',
      render: (text, record, index) => (
        <>
          {record.recordTime ? moment(record.recordTime).format('YYYY-MM-DD') : '----'}
        </>
      )
    },
    {
      title: '标签',
      key: 'customerListCol-label',
      ellipsis: true,
      width: 200,
      dataIndex: 'label'
    },
    {
      title: 'ERP是否启用',
      key: 'customerListCol-erpEnableStatus',
      dataIndex: 'erpEnableStatus',
      render: (text, record, index) => (
        <>
          {
            record.erpUsername && record.erpUsername !== ''
              ? <Switch checked={record.erpEnableStatus === 'ENABLE'}
                checkedChildren='启用'
                unCheckedChildren='禁用'
                onChange={(checked) => this.handleChangeSwitchErpStatus(checked, record)}
              />
              : <div>----</div>
          }
        </>
      )
    },
    {
      title: 'ERP账套名称',
      key: 'customerListCol-erpUsername',
      dataIndex: 'erpUsername'
    },
    {
      title: '特价产品数',
      key: 'customerListCol-bargainsTotal',
      dataIndex: 'bargainsTotal',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.handleLinkToProductSet(record)}>{+record.bargainsTotal > 0 ? +record.bargainsTotal : '-----'}</a>
        </>
      )
    },
    {
      title: '自营品牌数',
      key: 'customerListCol-brandNums',
      dataIndex: 'brandCountForSoldBySelf',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.handleLinkToCusbrand(record, 1)}>
            <Statistic value={record.brandCountForSoldBySelf} valueStyle={{ fontSize: '14px', color: '#1890ff' }} />
          </a>
        </>
      )
    },
    {
      title: '推广品牌数',
      key: 'customerListCol-hotBrandNums',
      dataIndex: 'brandCountForSoldByCloud',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.handleLinkToCusbrand(record, 2)}>
            <Statistic value={record.brandCountForSoldByCloud} valueStyle={{ fontSize: '14px', color: '#1890ff' }} />
          </a>
        </>
      )
    },
    {
      title: '是否启用',
      key: 'customerListCol-status',
      width: 80,
      dataIndex: 'status',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <>
            {
              buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1
                ? <Switch checked={record.status === 'ENABLE'}
                  checkedChildren='启用'
                  unCheckedChildren='禁用'
                  onChange={(checked) => this.handleChangeSwitch(checked, record)}
                />
                : <span>{ record.status === 'ENABLE' ? '启用' : '禁用' }</span>
            }
          </>
        )
      }
    },
    {
      title: '所属销售大区',
      key: 'customerListCol-regionCode',
      dataIndex: 'regionCode',
      render: (text, record, index) => (
        <>{ renderRegionName(record.regionCode) }</>
      )
    },
    {
      title: '所属运营',
      key: 'customerListCol-operationMemberName',
      dataIndex: 'operationMemberName'
    },
    {
      title: '采购归属',
      key: 'customerListCol-belongUserRealName',
      dataIndex: 'belongUserRealName'
    },
    {
      title: '供应归属',
      key: 'customerListCol-supplyBelongUserRealName',
      dataIndex: 'supplyBelongUserRealName'
    },
    {
      title: '初始化详情',
      key: 'customerListCol-initDetail',
      render: (text, record, index) => {
        return (
          <a onClick={() => { this.selCusinit(record) }}>点击查看</a>
        )
      }
    },
    {
      title: '创建人',
      key: 'customerListCol-createName',
      dataIndex: 'createName'
    },
    {
      title: '创建时间',
      key: 'customerListCol-createTime',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '操作人',
      key: 'customerListCol-modifyName',
      dataIndex: 'modifyName'
    },
    {
      title: '操作时间',
      key: 'customerListCol-modifyTime',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '操作',
      key: 'column-operations',
      width: 280,
      fixed: 'right',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <>
            {
              buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <Button type='link' size='small' onClick={() => this.handleJumpLinkToAddCus(record, 2)}>编辑</Button>
                : null
            }

            {
              buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <Button type='link' size='small' onClick={() => this.handleJumpLinkToSetPay(record)}>支付配置</Button>
                : null
            }

            <Button type='link' size='small' onClick={() => this.showDistributorlabelModal(record)}>标签</Button>

            <Button type='link' size='small' onClick={() => this.showErpOperationModal(record)}>关联ERP</Button>

            <Button type='link' size='small' onClick={() => this.showDistributionModal(record, 'platformUser')}>分配运营</Button>

            <Button type='link' size='small' onClick={() => this.showAddressModal(record, 1)}>新增地址</Button>

            <Button type='link' size='small' onClick={() => this.handleLinkToAddressDetail(record)}>查看地址</Button>

            <Button type='link' size='small' onClick={() => this.showDistributionModal(record, 'belongUser')}>设置采购归属</Button>

            <Button type='link' size='small' onClick={() => this.showDistributionModal(record, 'supplyBelongUser')}>设置供应归属</Button>
          </>
        )
      }
    }
  ]
  // 保存分配人员方法集合
  bindPersonnelForNameMaps = [
    [ // 分配运营
      (name) => name === 'platformUser',
      (values) => this.saveBindBusinessPersonnel(values)
    ],
    [ // 分配客户采购归属
      (name) => name === 'belongUser',
      (values) => this.saveBindBelongPersonnel(values)
    ],
    [ // 分配客户供应归属
      (name) => name === 'supplyBelongUser',
      (values) => this.saveBindSupplyBelongPersonnel(values)
    ]
  ]

  componentDidMount () {
    this.getCustomerList()
  }

  // 获取全部成员
  getMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[searchIndex].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list, { id: 0, userName: '无' }] : []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取客户列表
  getCustomerList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCustomerList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 客户启用/禁用
  deleteCustomer = (params) => {
    Api.supplierchangeStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getCustomerList()
      }
    })
  }

  // 设置商户标签
  setDistributorLabel = (params) => {
    Api.setDistributorLabel(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getCustomerList()
        this.closeModal()
      }
    })
  }

  // 保存ERP账套设置
  saveErpConfigSetting = (params) => {
    Api.saveErpConfigSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getCustomerList()
      }
    })
  }

  // ERP账套启用禁用
  updateErpConfigStatus = (params) => {
    Api.updateErpConfigStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getCustomerList()
      }
    })
  }

  // 账号启用/禁用
  handleChangeSwitch = (checked, record) => {
    let params = {
      status: checked ? 'ENABLE' : 'DISABLE',
      distributorId: record.distributorId
    }

    if (!checked) {
      confirm({
        title: '确定禁用该客户？',
        onOk: () => {
          this.deleteCustomer(params)
        }
      })

      return false
    }

    this.deleteCustomer(params)
  }

  // 切换ERP启用禁用状态
  handleChangeSwitchErpStatus = (checked, record) => {
    let params = {
      distributorId: record.distributorId,
      enableStatus: checked ? 'ENABLE' : 'DISABLE'
    }
    if (!checked) {
      confirm({
        title: '确定禁用？',
        onOk: () => {
          this.updateErpConfigStatus(params)
        }
      })

      return false
    }

    this.updateErpConfigStatus(params)
  }

  // 查看初始化详情
  selCusinit = (record) => {
    Api.selCusinit({ distributorId: record.distributorId }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          rowDetail: data,
          infomodal: true
        })
      }
    })
  }

  // 保存分配运营成员
  saveBindBusinessPersonnel = async (params) => {
    const res = await Api.saveBindBusinessPersonnel(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getCustomerList()
    }
  }

  // 保存分配客户采购归属
  saveBindBelongPersonnel = async (params) => {
    const res = await Api.saveBindBelongPersonnel(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getCustomerList()
    }
  }

  // 保存分配客户供应归属
  saveBindSupplyBelongPersonnel = async (params) => {
    const res = await Api.saveBindSupplyBelongPersonnel(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getCustomerList()
    }
  }

  // 保存新增地址
  saveAddAddress = async (params) => {
    const res = await Api.saveAddAddress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
    }
  }

  // 保存列表项配置
  saveUserTableColumnSetting = async (params, newcolumnsMap) => {
    const res = await Api.saveUserTableColumnSetting(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.props.dispatch(setTableColumnKeys({ columnsMap: newcolumnsMap }))
      this.closeModal()
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search.recordTimeBegin = search.times.length === 2 ? moment(search.times[0]).format('YYYY-MM-DD') : undefined
      search.recordTimeEnd = search.times.length === 2 ? moment(search.times[1]).format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getCustomerList()
    })
  }

  // 跳转到支付配置
  handleJumpLinkToSetPay = (row) => {
    this.props.history.push({ pathname: '/admin/paySet', state: row })
  }

  // 跳转到编辑客户
  handleJumpLinkToAddCus = (row, type) => {
    this.props.history.push({ pathname: '/admin/addCustomer', state: { ...row, pageType: type } })
  }

  // 点击跳转到品牌
  handleLinkToCusbrand = (record, type) => {
    this.props.history.push({ pathname: '/admin/cusbrandList', state: { distributorId: record.distributorId, type: type } })
  }

  // 跳转到地址列表
  handleLinkToAddressDetail = (record) => {
    this.props.history.push({ pathname: '/admin/customerAddressList', state: { distributorId: record.distributorId, addressType: 'MERCHANT_RECEIVER_ADDRESS' } })
  }

  // 点击分配运营 || 分配客户采购归属 || 分配客户供应归属
  showDistributionModal = (record, modalKey) => {
    this.setState({
      rowDetail: { ...record, modalKey: modalKey },
      distributionModal: true
    })
  }

  /* 确定保存分配人员
   * 分配运营 || 分配客户采购归属 || 分配客户供应归属
  */
  confirmBindDistributionPersonnel = (params, modalKey) => {
    if (!modalKey || modalKey === '') { return message.warning('modalKey参数不能为空') }

    const getDescribe = this.bindPersonnelForNameMaps.find((item) => { return item[0](modalKey) })

    getDescribe ? getDescribe[1](params) : message.warning('modalKey参数错误')
  }

  // 点击新增地址
  showAddressModal = (record, type) => {
    this.setState({
      addressModal: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击保存地址
  handleSaveAddress = (values) => {
    const { rowDetail } = this.state
    this.saveAddAddress({ ...values, bindData: rowDetail.distributorId, addressType: 'MERCHANT_RECEIVER_ADDRESS' })
  }

  // 标签弹窗
  showDistributorlabelModal = (record) => {
    this.setState({
      labelModal: true,
      rowDetail: record || {}
    })
  }

  // 关联ERP操作弹窗
  showErpOperationModal = (record) => {
    this.setState({
      rowDetail: record,
      erpoperationModal: true
    })
  }

  // 点击新增特价产品
  handleLinkToProductSet = (record) => {
    sessionStorage.setItem('backPath', this.props.history.location.pathname)

    this.props.history.push({ pathname: '/admin/enquireProductSet', state: { distributorId: record.distributorId } })
  }

  // 点击下载支付信息
  handleShowPayBankDownModal = (record) => {
    this.setState({
      rowDetail: record,
      payBankDownModal: true
    })
  }

  // 下载支付信息
  downloadPayBankDetail = (params, type) => {
    if (type === 'PDF') {
      DownloadApi.downloadPaymentInfoByPdf(params)
    }

    if (type === 'WORD') {
      DownloadApi.downloadPaymentInfoByWord(params)
    }
  }

  // 点击列设置
  showColumnsConfigModal = () => {
    this.setState({
      columnsConfigModal: true
    })
  }

  // 确定保存列表项配置
  handleConfirmTableColumnSetting = async (selectedKeys, newcolumns) => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: {
        selectedColumnKeys: [...selectedKeys],
        sortColumnKeys: [...newcolumns]
      }
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  // 确定重置列表项配置
  handleResetTableColumnSetting = () => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: null
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      type: 1,
      labelModal: false,
      erpoperationModal: false,
      infomodal: false,
      addressModal: false,
      distributionModal: false,
      payBankDownModal: false,
      columnsConfigModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCustomerList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCustomerList()
    })
  }

  render () {
    const { columnsMap = {} } = this.props
    const { tableData, pages, searchData, rowDetail = {}, labelModal = false, erpoperationModal = false, infomodal = false, addressModal = false,
      type = 1, distributionModal = false, payBankDownModal = false, columnsConfigModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    /**
     * 获取redux缓存里面的相关页面的列展示信息
     *   sortColumnKeys: 排序后的所有列的key selectedColumnKeys:要展示的列的key
     * 1- 首先将baseColumns列顺序按 sortColumnKeys 里面的顺序排序
     * 2- 将baseColumns过滤 保留包含在 selectedColumnKeys中的key
     */
    const sortColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].sortColumnKeys ? columnsMap[columnConfigKey].sortColumnKeys : []
    const selectedColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys ? columnsMap[columnConfigKey].selectedColumnKeys : []

    const newArr = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : this.baseColumns

    // 列表展示的项
    const tableColumns = selectedColumnKeys.length > 0 ? $lodash.filter(newArr, (record) => { return $lodash.includes(selectedColumnKeys, record.key) }) : newArr

    const _baseColumns = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : $lodash.map(this.baseColumns, (record) => { return { key: record.key, title: record.title } })

    const columnsConfig = $lodash.filter(_baseColumns, (o) => { return !$lodash.includes(['column-0', 'column-operations'], o.key) })

    const checkedColKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys
      ? columnsMap[columnConfigKey].selectedColumnKeys
      : $lodash.map(columnsConfig, (o) => { return o.key })

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Col span={16}>
              <Button type='primary' onClick={() => this.handleJumpLinkToAddCus({}, 1)}>
                <Icon type='plus' />添加客户
              </Button>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Tooltip title='列设置'>
                <a style={{ display: 'inline-block', padding: '0px 6px', lineHeight: '32px' }} onClick={() => this.showColumnsConfigModal()}>
                  <Icon type='setting' style={{ fontSize: '20px' }} />
                </a>
              </Tooltip>
            </Col>
          </Row>

          <Table rowKey={(record, index) => { return `${record.distributorId}-${index}` }} bordered
            columns={tableColumns}
            dataSource={tableData}
            pagination={pagination}
            scroll={{ x: true }}
          />
        </Card>

        {
          labelModal
            ? <MCLabelModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.setDistributorLabel(params)}
            />
            : null
        }

        {
          erpoperationModal
            ? <ErpoperationModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.saveErpConfigSetting(params)}
            />
            : null
        }

        {
          infomodal
            ? <InfoModal
              onCancel={() => this.closeModal()}
              rowDetail={rowDetail}
            />
            : null
        }

        {
          addressModal
            ? <AddressModal
              type={type}
              detailInfo={{}}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.handleSaveAddress(values)}
            />
            : null
        }

        {
          distributionModal
            ? <DistributionModal
              detailInfo={rowDetail}
              modalKey={rowDetail.modalKey}
              onConfirm={(values, modalKey) => this.confirmBindDistributionPersonnel(values, modalKey)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          payBankDownModal
            ? <PayBankDownFormModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(params, type) => this.downloadPayBankDetail(params, type)}
            />
            : null
        }

        { // 列设置弹窗
          columnsConfigModal
            ? <ColumnsConfigModal
              configKey={columnConfigKey}
              selectedColumnKeys={checkedColKeys}
              disabledBeforeColumns={[{ key: 'column-0', title: '序号' }]}
              disabledAfterColumns={[{ key: 'column-operations', title: '操作' }]}
              allcolumns={columnsConfig}
              onConfirm={(checkedKeys, columns) => this.handleConfirmTableColumnSetting(checkedKeys, columns)}
              onReset={() => this.handleResetTableColumnSetting()}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    buttonMaps: state.buttonMaps,
    columnsMap: state.columnsMap || {}
  }
}

export default connect(mapStateToProps)(CustomerList)
