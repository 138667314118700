/* 成员管理页面 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Table, Button, Switch, message, Modal } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import MemberModal from '../component/member-modal'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'
import filter from '@/common/utils/filter'

const personStatusMap = [
  { type: 'ALL', code: '-1', label: '全部' },
  { type: 'ENABLED', code: '1', label: '启用' },
  { type: 'DISABLE', code: '0', label: '禁用' }
]
const { pageSizeOptions } = filter
const { RESET_PASSWORD } = consts

class MemberList extends Component {
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'account',
        formType: 'Input',
        itemParams: {
          label: '登录名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'realName',
        formType: 'Input',
        itemParams: {
          label: '员工姓名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'mobile',
        formType: 'Input',
        itemParams: {
          label: '手机号码'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '账号状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: personStatusMap,
        optionValue: ['type', 'label']
      }
    ],
    memberModal: false,
    type: 1,
    query: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'member-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '登录名',
      key: 'member-1',
      dataIndex: 'loginName'
    },
    {
      title: '真实姓名',
      key: 'member-2',
      dataIndex: 'userName'
    },
    {
      title: '手机号码',
      key: 'member-9',
      dataIndex: 'mobile'
    },
    {
      title: '邮箱地址',
      key: 'member-3',
      dataIndex: 'email'
    },
    {
      title: '部门',
      key: 'member-4',
      dataIndex: 'departName'
    },
    {
      title: '添加时间',
      key: 'member-5',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },

    {
      title: '是否启用',
      key: 'member-7',
      render: (text, record, index) => {
        const { buttonMaps } = this.props
        return (
          <div>
            {
              buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1
                ? <Switch checkedChildren='启用' unCheckedChildren='禁用'
                  checked={record.status === 'ENABLED'}
                  onChange={(checked) => this.changePersonStatus(checked, record)}
                />
                : <div>
                  {
                    record.status === 'ENABLED' ? '启用' : '禁用'
                  }
                </div>
            }
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'member-8',
      width: 220,
      render: (text, record, index) => {
        const { buttonMaps = {} } = this.props
        return (
          <>
            {
              buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <Button type='link' size='small' onClick={() => this.showMemberModal(record, 2)}>编辑</Button>
                : null
            }

            {
              record.status === 'ENABLED'
                ? <Button type='link' size='small' onClick={() => this.handleConfirmResetPassword(record)}>重置密码</Button>
                : null
            }
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getMemberList()
  }

  // 获取员工列表
  getMemberList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getMemberList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取员工信息详情
  getPersonDetail = (params) => {
    return Api.getPersonDetail(params)
  }

  // 保存新增员工信息
  saveAddMember = (params) => {
    Api.saveAddMember(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getMemberList()
      }
    })
  }

  // 保存编辑员工信息
  saveEditMember = (params) => {
    Api.saveEditMember(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getMemberList()
      }
    })
  }

  // 更新员工账号启用/禁用状态
  updateMemberStatus = (params) => {
    Api.updateMemberStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getMemberList()
      }
    })
  }

  // 重置密码
  resetPlatformUserLoginPassword = async (params) => {
    const res = await Api.resetPlatformUserLoginPassword(params)
    const { code } = res
    if (+code === 10000) {
      this.countDown()
    }
  }

  // 点击新增/编辑成员
  showMemberModal = (row = {}, type) => {
    this.setState({
      memberModal: true,
      type: type,
      rowDetail: row
    })
  }

  // 点击保存员工信息
  handleSubmitMember = (params) => {
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveAddMember(params)
    } else {
      this.saveEditMember(params)
    }
  }

  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20,
        total: 0
      }
    }, () => {
      this.getMemberList()
    })
  }

  // 点击重置密码
  handleConfirmResetPassword = (record) => {
    Modal.confirm({
      title: '确定重置密码？',
      onOk: () => {
        this.resetPlatformUserLoginPassword({ id: record.id })
      }
    })
  }

  changePersonStatus = (checked, record) => {
    let params = {
      id: record.id,
      status: checked ? 'ENABLED' : 'DISABLE'
    }

    this.updateMemberStatus(params)
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '登录密码重置成功',
      content: <div>
        <p style={{ margin: '0px' }}>初始密码为{RESET_PASSWORD}</p>
        <p style={{ margin: '0px' }}>窗口将在{secondsToGo}s后关闭</p>
      </div>,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.getMemberList()
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: <div>
          <p style={{ margin: '0px' }}>初始密码为{RESET_PASSWORD}</p>
          <p style={{ margin: '0px' }}>窗口将在{secondsToGo}s后关闭</p>
        </div>
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.getMemberList()
    }, secondsToGo * 1000)
  }

  closeModal = () => {
    this.setState({
      memberModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMemberList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMemberList()
    })
  }

  render () {
    const { searchData = [], tableData = [], pages, memberModal = false, type = 1, rowDetail } = this.state
    const { buttonMaps } = this.props

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>

            <Row style={{ marginBottom: '10px' }}>
              {
                buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                  ? <Button type='primary' icon='plus'
                    onClick={() => this.showMemberModal({}, 1)}
                  >添加成员</Button>
                  : null
              }
            </Row>
            <Row>
              <Table rowKey='id' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
              />
            </Row>
          </Card>
        </Row>

        {
          memberModal
            ? <MemberModal
              detailInfo={rowDetail}
              type={type}
              onConfirm={(params) => this.handleSubmitMember(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(MemberList)
