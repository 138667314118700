import React, { Component } from 'react'
import { Modal, Table, Row } from 'antd'
import PropTypes from 'prop-types'
import styles from './setspecial.module.scss'
export default class Errlist extends Component {
  static propTypes = {
    errInfoList: PropTypes.array,
    onCancel: PropTypes.func
  }
  state={
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: []
  }
  componentDidMount () {
    this.getErrList()
  }
  columns = [
    {
      title: '序号',
      key: 'specialLog-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '型号/订单号',
      key: 'specialLog-1',
      width: 44,
      render: (text, record, index) => (
        <div>
          <Row className={`${styles.tableRow}`}>
            <label className={`${styles.rowLabel}`}>型<em style={{ visibility: 'hidden' }}>型</em>号</label>
            <span title={record.model}>{record.model}</span>
          </Row>
          <Row className={`${styles.tableRow}`}>
            <label className={`${styles.rowLabel}`}>订货号</label>
            <span title={record.itemNumber}>{record.itemNumber}</span>
          </Row>
        </div>
      )
    }
  ]
  // 获取错误列表数据
  getErrList=() => {
    const { errInfoList } = this.props
    this.setState({
      tableData: errInfoList
    })
  }
  render () {
    const { tableData = [] } = this.state

    return (
      <div>
        <Modal title='重复列表' visible onCancel={this.props.onCancel} onOk={this.props.onCancel} >
          <Row>
            <Table rowKey='id'
              bordered
              columns={this.columns}
              dataSource={tableData}
            />
          </Row>
        </Modal>
      </div>
    )
  }
}
