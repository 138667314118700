import React, { Component } from 'react'
import { Row, Card, Divider, Button } from 'antd'
import * as echarts from 'echarts'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import MonthTable from './monthTable'
import filter from '@/common/utils/filter'
import { getYearOptions } from '@/common/utils/mUtils'

import Api from '@/common/api/index'
import Request from '@/common/api/dashboardApi'

import styles from './index.module.scss'

import { Icon } from './../js/Icon' // 伸缩展开icon
// 当前年
const YEAR = moment().get('year')
const { fullsMonthOptions, echartscolorinit } = filter
const legendLabels = [
  { id: 'cloudSupplyOrder', name: '云供应订单' },
  { id: 'cloudSpotOrder', name: '云采购订单' },
  { id: 'selfOrder', name: '自营订单' },
  { id: 'logisticsOrder', name: '快递' }
]

// 商户订单数量统计
export default class MCOrdersNumberReport extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    faly: 0,
    searchData: [
      {
        name: 'distributor_id',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'year',
        formType: 'Select',
        itemParams: {
          label: '查询年份'
        },
        cptParams: {
          placeholder: '请选择查询年份...'
        },
        rules: {
          initialValue: YEAR
        },
        options: getYearOptions(YEAR - 2019 + 1)
      }
    ],
    distributorName: '上海索尚自动化科技有限公司',
    query: {
      year: YEAR,
      distributor_id: 100153
    },
    tableData: [], // 表格数据
    series: [] // 图形数据
  }

  componentDidMount () {
    this.getDashboardAnalyse()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = async (keyword) => {
    if (keyword === '') { return false }

    const res = await Api.getMerchantListByKeyword({ distributorName: keyword })
    const { code, data } = res
    if (+code === 10000 && data && data.length > 0) {
      let { searchData } = this.state

      searchData[0].options = data

      this.setState({
        searchData
      })
    }
  }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const { query = {}, distributorName } = this.state
    const res = await Request.getDashboardAnalyse({ type: 'merchant_info_interactive', ...query })
    let arr = []

    $lodash.forEach(res, (values, key) => {
      if (values && values.length > 0) {
        values.map((item) => {
          arr.push({
            year: item[1].split('-')[0],
            month: +item[1].split('-')[1],
            distributorName: item[0],
            value: item[2],
            keyword: ['selfSpotOrder', 'selfFuturesOrder'].includes(key) ? 'selfOrder' : key
          })
        })
      } else {
        arr.push({
          year: query.year,
          month: 1,
          distributorName: distributorName,
          value: 0,
          keyword: ['selfSpotOrder', 'selfFuturesOrder'].includes(key) ? 'selfOrder' : key
        })
      }
    })

    let selfObj = $lodash.groupBy($lodash.remove(arr, (item) => { return item.keyword === 'selfOrder' }), 'month')

    $lodash.forEach(selfObj, (values, key) => {
      if (values.length === 1) {
        arr = [...arr, ...values]
      } else {
        arr.push({ ...values[0], value: $lodash.sumBy(values, 'value') })
      }
    })

    this.setState({
      tableData: this.setTableData([...arr]),
      series: this.setLineSeries([...arr])
    }, () => {
      this.initECharts()
    })
  }

  // 绘制图表
  initECharts = () => {
    var myChart
    if (document.getElementById('mcOrdersNumbersChart') == null) {
      return
    }
    echarts.dispose(document.getElementById('mcOrdersNumbersChart'))
    myChart = echarts.init(document.getElementById('mcOrdersNumbersChart'))
    const { series = [] } = this.state

    this.getdatainit(series)
    myChart.setOption({
      color: echartscolorinit,
      legend: {
        data: $lodash.map(series, 'name'),
        orient: 'horizontal',
        x: 'right',
        y: 'top'
      },

      title: {
        text: `${this.getYearinit()}趋势图`
      },
      tooltip: {
        borderColor: '#fff',
        borderWidth: '1',
        formatter: (e) => {
          return this.gettitle(e)
        },
        borderRadius: 4
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: $lodash.map(fullsMonthOptions, (item) => { return item.monthLabel })
      },
      yAxis: {
        type: 'value',
        name: '订单单数(单)'
      },
      series: series
    })
  }

   // 显示年份
   getYearinit=() => {
     const { query } = this.state
     return query.year
   }
  // 设置表格数据
  setTableData = (data) => {
    let result = []

    $lodash.forEach($lodash.groupBy(data, 'month'), (values, key) => {
      let obj = {
        year: values[0].year,
        month: values[0].month,
        distributorName: values[0].distributorName
      }

      values.map((item) => { obj[item.keyword] = item.value })
      result.push(obj)
    })

    result = $lodash.unionBy(result, fullsMonthOptions, 'month')

    return result
  }

  // 设置折线图数据
  setLineSeries = (data) => {
    let result = []

    $lodash.forEach($lodash.groupBy(data, 'keyword'), (values, key) => {
      values = $lodash.orderBy($lodash.unionBy(values, fullsMonthOptions, 'month'), ['month'], 'asc')

      let obj = {
        name: this.renderlegendLabel(key),
        type: 'line',
        stack: '总量',
        data: $lodash.map(values, 'value')
      }

      result.push(obj)
    })

    return result
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { searchData } = this.state
    let list = searchData[0].options
    let i = $lodash.findIndex(list, (item) => { return item.id === search.distributor_id })

    this.setState({
      query: search,
      distributorName: i > -1 ? list[i].distributorName : ''
    }, () => {
      this.getDashboardAnalyse()
    })
  }

  renderlegendLabel = (key) => {
    let label = ''
    let i = $lodash.findIndex(legendLabels, (item) => { return item.id === key })

    if (i > -1) { label = legendLabels[i].name }

    return label
  }

  // 设置数据
  getdatainit=(series) => {
    // 云采购订单
    let objycg = $lodash.find(series, (item) => { return item.name === '云采购订单' })
    // 云供应订单
    let objygy = $lodash.find(series, (item) => { return item.name === '云供应订单' })
    // 快递订单
    let objkd = $lodash.find(series, (item) => { return item.name === '快递' })
    // 自营订单
    let objzy = $lodash.find(series, (item) => { return item.name === '自营订单' })
    // 云采购订单
    if (objycg) {
      objycg.objycg = objycg && objycg.data ? objycg.data : []
      objycg.objygy = objygy && objygy.data ? objygy.data : []
      objycg.objkd = objkd && objkd.data ? objkd.data : []
      objycg.objzy = objzy && objzy.data ? objzy.data : []
    }
    // 云供应订单
    if (objygy) {
      objygy.objycg = objycg && objycg.data ? objycg.data : []
      objygy.objygy = objygy && objygy.data ? objygy.data : []
      objygy.objkd = objkd && objkd.data ? objkd.data : []
      objygy.objzy = objzy && objzy.data ? objzy.data : []
    }
    // 快递
    if (objkd) {
      objkd.objycg = objycg && objycg.data ? objycg.data : []
      objkd.objygy = objygy && objygy.data ? objygy.data : []
      objkd.objkd = objkd && objkd.data ? objkd.data : []
      objkd.objzy = objzy && objzy.data ? objzy.data : []
    }
    // 自营
    if (objzy) {
      objzy.objycg = objycg && objycg.data ? objycg.data : []
      objzy.objygy = objygy && objygy.data ? objygy.data : []
      objzy.objkd = objkd && objkd.data ? objkd.data : []
      objzy.objzy = objzy && objzy.data ? objzy.data : []
    }
  }

  // 悬浮
  gettitle = (e) => {
    // line
    let formatteline = `<div style="display:flex; align-items: center;">
            <div style="color:${e.color};">-</div>
            <div style="width:12px;height:12px; border:1px solid ${e.color}; border-radius:500px;}"></div>
            <div style="color:${e.color};">-</div></div>`

    let i = e.dataIndex
    const { series } = this.state
    let obj = $lodash.filter(series, (item) => { return item.name === e.seriesName })
    return (
      `<div>
      <div><span class=${styles.titlespan}>订单数(单)</span></div>
      <div><span class=${styles.spanicon}>${formatteline}</span><span>自营订单</span><span class=${styles.formatterspan}>${obj && obj[0].objzy ? obj[0].objzy[i] : '0'}</span></div>
      <div><span class=${styles.spanicon}>${formatteline}</span><span>云供应</span><span class=${styles.formatterspan}>${obj && obj[0].objygy ? obj[0].objygy[i] : '0'}</span></div>
      <div><span class=${styles.spanicon}>${formatteline}</span><span>云采购</span><span class=${styles.formatterspan}>${obj && obj[0].objycg ? obj[0].objycg[i] : '0'}</span></div>
      <div><span class=${styles.spanicon}>${formatteline}</span><span>快递</span><span class=${styles.formatterspan}>${obj && obj[0].objkd ? obj[0].objkd[i] : '0'}</span></div>
      </div>`
    )
  }

   // 收起和展开
   getfaly =() => {
     const { faly = 0 } = this.state
     this.setState({
       faly: faly === 0 ? 1 : 0
     })
     let id = document.getElementById('rowid')
     id.style.height = faly === 0 ? '25px' : '400px'
   }
   render () {
     const { searchData, distributorName = '', tableData = [], faly } = this.state

     return (
       <>
         <Card bodyStyle={{ padding: '12px' }}>
           <SearchForm {...this.props}
             dataSource={searchData}
             handleSubmit={(obj) => this.handleSearch(obj)}
           />
         </Card>

         <Row style={{ display: 'block', marginTop: '20px', whiteSpace: 'nowrap' }}>
           <Card>
             <Row id='rowid' className={styles.mianrow}>
               <div id='mcOrdersNumbersChart' style={{ width: `${window.innerWidth - 300}px`, height: '400px' }} />
             </Row>
             <div className={styles.line}> <Divider />
               <Button onClick={() => { this.getfaly() }} className={styles.aicon}><img src={Icon[faly].src} /></Button>
             </div>
           </Card>
         </Row>

         <MonthTable tableData={tableData} distributorName={distributorName} />
       </>
     )
   }
}
