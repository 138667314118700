import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Button } from 'antd'

import InvoiceForm from '../express-invoice/invoiceForm'
import InvoiceTitle from '../express-invoice/invoiceTitle'
import InvoiceContent from './invoiceContent'

import Api from '@/common/api/index'

import styles from '../express-invoice/express.module.scss'

const FormItem = Form.Item

class InvoiceformCard extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    info: {}
  }

  componentDidMount () {
    this.getInsuranceInvoiceDetail()
  }

  // 获取保险发票详情
  getInsuranceInvoiceDetail = () => {
    const { detailInfo = {} } = this.props

    Api.getInsuranceInvoiceDetail({ insureInvoiceId: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { info = {} } = this.state
        let _flowIdList = []

        if (info.insureFlowList && info.insureFlowList.length > 0) {
          info.insureFlowList.map((item) => {
            _flowIdList.push(item.flowId)
          })
        }

        if (values.radioType === 'INVOICENO') {
          info.invoiceCode = values.invoiceCode
        } else {
          info.invoiceImageUrl = values.invoiceImageUrl
        }

        info.courierCode = values.courierCode
        info.courierNumber = values.courierNumber
        info['flowIdList'] = _flowIdList

        this.props.onConfirm(info)
      }
    })
  }

  render () {
    const { info = {} } = this.state

    return (
      <div>
        <Modal title='发票详情' width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'scroll' }}
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          onOk={() => this.handleConfirm()}
          footer={[
            <Button key={'invoiceBtn-1'} type='primary' onClick={() => this.handleConfirm()}>保存</Button>,
            <Button key={'invoiceBtn-2'} onClick={() => this.props.onCancel()}>取消</Button>
          ]}
        >

          <Row style={{ marginBottom: '15px' }}>
            <InvoiceForm {...this.props} info={info} />
          </Row>

          <Row style={{ marginBottom: '15px' }}>
            <InvoiceTitle info={info} />
          </Row>

          <Row style={{ marginBottom: '15px' }}>
            <InvoiceContent tableData={info.insureFlowList || []} />
          </Row>

          <Form>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='票价合计' style={{ marginBottom: '0px' }}>
                <div>&yen;{ (+info.invoiceTotalPrice * 100 / 100).toFixed(2) }</div>
              </FormItem>
            </Row>

            <Row className={`${styles.formitemRow}`}>
              <FormItem label='收票地址' style={{ marginBottom: '0px' }}>
                <div>{ info.address ? info.address : '----' }</div>
              </FormItem>
            </Row>
          </Form>

        </Modal>
      </div>
    )
  }
}

const InvoiceModal = Form.create()(InvoiceformCard)

export default InvoiceModal
