import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Tree, Icon, Dropdown, Menu, Button, message } from 'antd'

const { TreeNode } = Tree

class classficTree extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectMenu: PropTypes.func.isRequired,
    onAddTreeNode: PropTypes.func.isRequired,
    buttonMaps: PropTypes.object
  }
  state = {

  }

  renderDropdownMenu = (params) => {
    return (
      <Menu style={{ width: '100px', textAlign: 'center' }}
        onClick={(item) => this.handleMenuItem({ ...params, type: item.key })}
      >
        <Menu.Item key='2'>编辑</Menu.Item>
        <Menu.Item key='3'>删除</Menu.Item>
      </Menu>
    )
  }

  renderTreeIcon = (item) => {
    return (
      <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(item)}>
        <Icon type='form' />
      </Dropdown>
    )
  }

  // 选择菜单操作
  handleMenuItem = (params) => {
    const { buttonMaps } = this.props

    if (+params.type === 2) {
      if (buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1) {
        this.props.onSelectMenu(params)
      } else {
        return message.warning('您暂无权限使用编辑功能！')
      }
    }

    if (+params.type === 3) {
      if (buttonMaps && buttonMaps['delete'] && +buttonMaps['delete'].auth === 1) {
        this.props.onSelectMenu(params)
      } else {
        return message.warning('您暂无权限使用删除功能！')
      }
    }
  }

  // 选择分类
  handleSelectTreeNode = (selectedKeys = []) => {
    if (selectedKeys.length > 0) {
      this.props.onSelect(selectedKeys)
    }
  }

  renderKeyObj = (item, type) => {
    let obj = {
      parentId: +type === 1 ? item.id : item.parentId,
      parentName: +type === 1 ? item.name : item.parentName,
      id: +type === 1 ? '' : item.id,
      name: +type === 1 ? '' : item.name
    }

    if (type && +type === 1) {
      obj.type = 1
    }

    return JSON.stringify(obj)
  }

  renderAddIcon = () => {
    return (
      <Icon type='plus' />
    )
  }

  render () {
    const { data = [], buttonMaps } = this.props

    return (
      <div>
        <Row>
          {
            data && data.length > 0
              ? (
                <Tree showIcon
                  onSelect={(selectedKeys) => this.handleSelectTreeNode(selectedKeys)}
                >
                  {
                    data.map((item1) => (
                      <TreeNode title={`${item1.name}/${item1.iiaCode}`}
                        key={`1-${item1.id}-${this.renderKeyObj(item1)}`}
                        icon={this.renderTreeIcon({ ...item1 })}>
                        {
                          item1.classifications && item1.classifications.map((item2) => (
                            <TreeNode title={`${item2.name}/${item2.iiaCode}`}
                              key={`2-${item1.id}-${item2.id}-${this.renderKeyObj(item2)}`}
                              icon={this.renderTreeIcon({ ...item2, parentId: item1.id, parentName: item1.name })}>
                              {
                                item2.classifications && item2.classifications.map((item3) => (
                                  <TreeNode title={`${item3.name}/${item3.iiaCode}`}
                                    key={`3-${item1.id}-${item2.id}-${item3.id}-${this.renderKeyObj(item3)}`}
                                    icon={this.renderTreeIcon({ ...item3, parentId: item2.id, parentName: item2.name })}>
                                    {
                                      item3.classifications && item3.classifications.map((item4) => (
                                        <TreeNode title={`${item4.name}/${item4.iiaCode}`}
                                          key={`4-${item1.id}-${item2.id}-${item3.id}-${item4.id}-${this.renderKeyObj(item4)}`}
                                          icon={this.renderTreeIcon({ ...item4, parentId: item3.id, parentName: item3.name })} />
                                      ))
                                    }

                                    {
                                      buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                                        ? <TreeNode
                                          key={`4-${item1.id}-${item2.id}-${this.renderKeyObj(item3, 1)}`}
                                          icon={this.renderAddIcon()}
                                          title={`新增分类`}
                                        />
                                        : null
                                    }

                                  </TreeNode>
                                ))
                              }

                              {
                                buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                                  ? <TreeNode
                                    key={`3-${item1.id}-${item2.id}-${this.renderKeyObj(item2, 1)}`}
                                    icon={this.renderAddIcon()}
                                    title={`新增分类`}
                                  />
                                  : null
                              }

                            </TreeNode>
                          ))
                        }

                        {
                          buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                            ? <TreeNode
                              key={`2-${item1.id}-${this.renderKeyObj(item1, 1)}`}
                              icon={this.renderAddIcon()}
                              title='新增分类'
                            />
                            : null
                        }

                      </TreeNode>
                    ))
                  }
                </Tree>
              ) : ''
          }
        </Row>

        {
          buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
            ? <Button type='dashed' icon='plus'
              style={{ marginBottom: '10px' }}
              onClick={() => this.props.onAddTreeNode()}
            >增加分类</Button>
            : null
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(classficTree)
