/* 询价管理 */
import EnquireOrderList from '@/pages/enquireManage/enquireOrderList/index' // 询价单列表
import CreateEnquireOrder from '@/pages/enquireManage/createEnquireOrder/index' // 创建、编辑询价单
import EnquireProcess from '@/pages/enquireManage/enquireProcess/index' // 询价过程
import EnquireHandle from '@/pages/enquireManage/enquireHandle/index' // 询价处理
import QuotationList from '@/pages/enquireManage/quotationList/index' // 报价单列表
import QuotationDetails from '../pages/enquireManage/quotationDetails/index' // 报价单详情
import PrepareQuotations from '@/pages/enquireManage/prepareQuotations/index' // 预报价单列表
import PrepareQuotedDetails from '@/pages/enquireManage/prepareQuotedDetails/index' // 预报价单详情

import EnquireInformationList from '@/pages/enquireManage/enquireInformationList'
import CreateEnquireInformation from '@/pages/enquireManage/createEnquireInformation'

export default [
  {
    path: '/admin/enquireOrderList',
    name: '询价单列表',
    component: EnquireOrderList
  },
  {
    path: '/admin/createEnquireOrder',
    name: '创建询价单',
    component: CreateEnquireOrder
  },
  {
    path: '/admin/enquireHandle',
    name: '询价处理',
    component: EnquireHandle
  },
  {
    path: '/admin/enquireProcess',
    name: '询价过程',
    component: EnquireProcess
  },
  {
    path: '/admin/quotationList',
    name: '报价单列表',
    component: QuotationList
  },
  {
    path: '/admin/quotationDetails',
    name: '报价单详情',
    component: QuotationDetails
  },
  {
    path: '/admin/enquireInformationList',
    name: '每日信息发布',
    component: EnquireInformationList
  },
  {
    path: '/admin/createEnquireInformation',
    name: '询价内容编辑',
    component: CreateEnquireInformation
  },
  {
    path: '/admin/prepareQuotaions',
    name: '预报价单列表',
    component: PrepareQuotations
  },
  {
    path: '/admin/prepareQuotedDetails',
    name: '报价详情',
    component: PrepareQuotedDetails
  }
]
