import React, { Component } from 'react'
import { Button, Card, Row, Table } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import GetMoenyModal from './getMoenyModal'
import GetDetails from './detailsModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class Sfindex extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  state={
    MoenyModal: false, // 确认收款弹出框
    DetailsModal: false, // 详情弹窗
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },

      {
        name: 'switchStatus1',
        formType: 'MonthPicker',
        itemParams: {
          label: '发货日期'
        },
        cptParams: {

        }
      },
      {
        name: 'switchStatus',
        formType: 'Select',
        itemParams: {
          label: '账单状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLED1', name: '已逾期' },
          { id: 'DISABLE2', name: '未逾期' },
          { id: 'DISABLE3', name: '已经算' }
        ]
      }
    ],
    tableData: [
      {
        id: '1',
        gname: '胡彦斌',
        ctime: 32,
        cwho: '西湖区湖底公园1号',
        bu: '西湖区湖底公园1号'
      },
      {
        id: '2',
        gname: '胡彦斌',
        ctime: 32,
        cwho: '西湖区湖底公园1号',
        bu: '西湖区湖底公园1号',
        money: 111
      }
    ]
  }
  columns= [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'service-1',
      dataIndex: 'gname'
    },
    {
      title: '真实姓名',
      key: 'service-2',
      dataIndex: 'ctime'
    },

    {
      title: '手机号码',
      key: 'service-3',
      dataIndex: 'cwho'
    },
    {
      title: '账单状态',
      key: 'service-4',
      dataIndex: 'bu'
    },
    {
      title: '账单日期',
      key: 'service-5',
      dataIndex: 'bu',
      render: (text, record, index) => (
        <>
          { record.ctime ? moment(record.ctime).format('YYYY-MM') : '----' }
        </>
      )
    },
    {
      title: '当月运费应付金额',
      key: 'service-6',
      dataIndex: 'money',
      render: (text, record, index) => (
        <>{ (+record.money * 1000 / 1000).toFixed(2) }</>
      )
    },
    {
      title: '当月运费已付金额',
      key: 'service-7',
      dataIndex: 'money',
      render: (text, record, index) => (
        <>{(+record.money * 1000 / 1000).toFixed(2)}</>
      )
    },
    {
      title: '当月运费未付金额',
      key: 'service-8',
      dataIndex: 'money',
      render: (text, record, index) => (
        <>{(+record.money * 1000 / 1000).toFixed(2)}</>
      )
    },
    {
      title: '操作',
      key: 'service-10',
      render: (text, record, index) => {
        return (
          <div>
            <a onClick={() => { this.onModalOpen() }}>确认收款</a>
          </div>

        )
      }
    },
    {
      title: '',
      key: 'service-11',
      render: (text, record, index) => {
        return (
          <div onClick={() => { this.onModalDetails() }}>
            <a>查看账单详情</a>
          </div>
        )
      }
    }
  ];
  // 获取德邦明细列表（接口未改）
  getCustomerListInsurance = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getCustomerListInsurance(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }
    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }
// 打开收款弹窗
onModalOpen=() => {
  this.setState({
    MoenyModal: true
  })
}
// 打开详情弹框
onModalDetails=() => {
  this.setState({
    DetailsModal: true
  })
}
// 关闭弹框
oncloseModal=() => {
  this.setState({
    MoenyModal: false,
    DetailsModal: false
  })
}
render () {
  const { searchData = [], pages, tableData = [], MoenyModal = false, DetailsModal = false } = this.state
  const pagination = {
    showSizeChanger: true,
    total: pages.total,
    showTotal: (total) => { return `共 ${total} 条` },
    current: pages.pageNo,
    pageSize: pages.pageSize,
    pageSizeOptions: pageSizeOptions,
    onChange: this.pageChange,
    onShowSizeChange: this.pageSizeChange
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>
        </Card>
      </Row>

      <Row>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Button style={{ marginRight: '10px' }} >申请开票</Button>
            <Button type='primary'>导出明细</Button>
          </Row>
          <Row>
            <Table rowKey='id' bordered
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
            />
          </Row>
        </Card>
      </Row>
      {
        MoenyModal
          ? <GetMoenyModal
            oncloseModal={this.oncloseModal}
          />
          : null
      }
      {
        DetailsModal
          ? <GetDetails
            oncloseModal={this.oncloseModal} />
          : null
      }
    </div>
  )
}
}
