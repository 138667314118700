import React, { Component } from 'react'
import { Row, Card, Button, Divider } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import ActiveTable from './activeTable'

import Request from '@/common/api/dashboardApi'
import RegionJson from '@/assets/json/region.json'

import styles from './index.module.scss'

import { Icon } from './../js/Icon'
import filter from '@/common/utils/filter' // 伸缩展开icon

// 当前年
const YEAR = moment().get('year')
// 当前月
const MONTH = moment().get('month') + 1
const { echartscolorinit } = filter

export default class MCActiveReport extends Component {
  static propTypes = {}
  state = {
    faly: 0,
    searchData: [
      {
        name: 'orderType',
        formType: 'Select',
        itemParams: {
          label: '订单类型'
        },
        cptParams: {
          placeholder: '请选择订单类型...'
        },
        rules: {
          initialValue: 'all'
        },
        options: [
          { id: 'all', name: '全部' },
          { id: 'eu', name: '自营订单' },
          { id: 'cloudPurchase', name: '云采购订单' },
          { id: 'cloudSupply', name: '云供应订单' }
        ]
      }
    ],
    query: {
      orderType: 'all'
    },
    originData: {}, // 源数据
    tableData: [], // 表格数据
    series: [] // 图表数据
  }

  componentDidMount () {
    this.getDashboardAnalyse()
  }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const res = await Request.getDashboardAnalyse({ type: 'merchant_activation' })
    let resObj = this.setCamelCaseKey(res)
    delete resObj.situationInCertainDays

    $lodash.forEach(resObj, (values, key) => {
      if ($lodash.includes(['activationAll', 'activationCloudPurchase', 'activationCloudSupply', 'activationEu'], key)) {
        let valuesArr = $lodash.map(values, (item) => {
          let obj = {
            name: item[4].split(',')[0], // 销售大区中文名
            regionCode: item[4].split(',')[1], // 销售大区code
            year: +item[3], // 年
            amountTotal: item[2], // 订单总金额
            orderNumber: item[0], // 订单数量
            quantity: item[1] // 商户数量
          }

          return obj
        })

        resObj[key] = $lodash.groupBy(valuesArr, 'regionCode')
      } else if ($lodash.includes(['activationAllFirstMonth', 'activationCloudPurchaseFirstMonth', 'activationCloudSupplyFirstMonth', 'activationEuFirstMonth'], key)) {
        let valuesArr = $lodash.map(values, (item) => {
          let obj = {
            name: item[2].split(',')[0],
            regionCode: item[2].split(',')[1],
            year: +item[1].split('-')[0],
            month: +item[1].split('-')[1],
            quantity: item[0]
          }
          return obj
        })

        resObj[key] = $lodash.groupBy(valuesArr, 'regionCode')
      } else {
        let valuesArr = $lodash.map(values, (item) => {
          let obj = {
            name: item[1].split(',')[0], // 销售大区中文名
            regionCode: item[1].split(',')[1],
            quantity: item[0]
          }

          return obj
        })

        resObj[key] = $lodash.groupBy(valuesArr, 'regionCode')
      }
    })

    this.setState({
      originData: {
        all: this.setOriginData(resObj.activationAll, resObj.activationAllFirstMonth, resObj.activationAllGetAlive,
          resObj.allInCertainDays30, resObj.allInCertainDays60, resObj.allInCertainDays90, resObj.allInCertainDays120, resObj.allInCertainDays150),

        cloudPurchase: this.setOriginData(resObj.activationCloudPurchase, resObj.activationCloudPurchaseFirstMonth, resObj.activationCloudPurchaseGetAlive,
          resObj.cloudPurchaseInCertainDays30, resObj.cloudPurchaseInCertainDays60, resObj.cloudPurchaseInCertainDays90, resObj.cloudPurchaseInCertainDays120, resObj.cloudPurchaseInCertainDays150),

        cloudSupply: this.setOriginData(resObj.activationCloudSupply, resObj.activationCloudSupplyFirstMonth, resObj.activationCloudSupplyGetAlive,
          resObj.cloudSupplyInCertainDays30, resObj.cloudSupplyInCertainDays60, resObj.cloudSupplyInCertainDays90, resObj.cloudSupplyInCertainDays120, resObj.cloudSupplyInCertainDays150),

        self: this.setOriginData(resObj.activationEu, resObj.activationEuFirstMonth, resObj.activationEuGetAlive,
          resObj.euInCertainDays30, resObj.euInCertainDays60, resObj.euInCertainDays90, resObj.euInCertainDays120, resObj.euInCertainDays150)
      }
    }, () => {
      // this.setEChartData()
      this.initECharts()
      this.setTableData()
    })
  }

  // 初始化图形
  initECharts = () => {
    const myChart = echarts.init(document.getElementById('merchantActiveChart'))
    let data = this.setEChartData()

    myChart.setOption({
      color: echartscolorinit,
      tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      title: {
        text: `销售大区`
      },

      legend: {
        data: ['总计', '活跃', '不活跃'],
        orient: 'horizontal',
        x: 'right',
        y: 'top'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          name: '客户数量',
          type: 'value'
        }
      ],
      yAxis: [
        {
          type: 'category',
          axisTick: {
            show: false
          },
          data: data.yAxis
        }
      ],
      series: [
        {
          name: '总计',
          type: 'bar',
          label: {
            show: true,
            position: 'inside'
          },
          emphasis: {
            focus: 'series'
          },
          data: data.totalArr
        },
        {
          name: '活跃',
          type: 'bar',
          stack: '总量',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: data.activeArr
        },
        {
          name: '不活跃',
          type: 'bar',
          stack: '总量',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: data.noActiveArr
        }
      ]
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.orderType === undefined) {
      search.orderType = 'all'
    }
    this.setState({
      query: search
    }, () => {
      this.initECharts()
      this.setTableData()
    })
  }

  // 设置对象key为驼峰
  setCamelCaseKey = (maps) => {
    let result = {}
    $lodash.forEach(maps, (values, key) => {
      if ($lodash.camelCase(key) === 'situationInCertainDays') {
        $lodash.forEach(values, (sitvalues, sitkey) => { result[$lodash.camelCase(sitkey)] = sitvalues })
      }
      result[$lodash.camelCase(key)] = values
    })

    return result
  }

  // 设置源数据
  setOriginData = (obj, firstMonthObj, aliveObj, certainDays30, certainDays60, certainDays90, certainDays120, certainDays150) => {
    let result = {}

    $lodash.forEach(obj, (values, key) => {
      const _yearActiveNum = aliveObj[key] && aliveObj[key].length > 0 ? aliveObj[key][0].quantity : 0

      result[key] = {
        name: values.length > 0 ? values[0].name : '----', // 销售区域
        regionCode: values.length > 0 ? values[0].regionCode : '-1', // 销售区域编码
        amountTotal: values.length > 0 ? $lodash.sumBy(values, 'amountTotal') : 0,
        orderNumber: values.length > 0 ? $lodash.sumBy(values, 'orderNumber') : 0,
        merchantTotalNum: values.length > 0 ? $lodash.sumBy(values, 'quantity') : 0, // 累计商户数量
        yearActiveNum: _yearActiveNum,
        active30DaysNum: certainDays30[key] && certainDays30[key].length > 0 ? certainDays30[key][0].quantity : 0,
        firstMonthNotActiveNum: _yearActiveNum - certainDays30[key][0].quantity,
        secondMonthNotActiveNum: _yearActiveNum - certainDays60[key][0].quantity,
        thirdMonthNotActiveNum: _yearActiveNum - certainDays90[key][0].quantity,
        fourMonthNotActiveNum: _yearActiveNum - certainDays120[key][0].quantity,
        fiveMonthNotActiveNum: _yearActiveNum - certainDays150[key][0].quantity
      }
    })

    $lodash.forEach(firstMonthObj, (values, key) => {
      let arr = $lodash.filter(values, (o) => { return o.year === YEAR })
      let m = $lodash.findIndex(values, (o) => { return o.year === YEAR && o.month === MONTH })

      result[key]['yearAddActiveNum'] = arr.length > 0 ? $lodash.sumBy(arr, 'quantity') : 0
      result[key]['monthAddActiveNum'] = m > -1 ? values[m].quantity : 0
    })

    return result
  }

  // 设置图形数据
  setEChartData = () => {
    const { query = {}, originData = {} } = this.state
    let data = originData[query.orderType]
    let yAxis = []
    let totalArr = []
    let activeArr = []
    let noActiveArr = []

    let tableData = $lodash.map(data, (item) => { return item })

    tableData.map((item) => {
      let _notActive = -(item.merchantTotalNum - item.yearActiveNum)
      totalArr.push(item.merchantTotalNum)
      activeArr.push(item.yearActiveNum)
      noActiveArr.push(_notActive)
      yAxis.push(item.name)
    })

    return { yAxis, totalArr, activeArr, noActiveArr }
  }

  // 设置表格数据
  setTableData = () => {
    const { query = {}, originData = {} } = this.state
    let data = originData[query.orderType]
    let tableData = $lodash.map(data, (item) => { return item })

    let totalObj = {
      regionCode: '0',
      name: '合计',
      merchantTotalNum: $lodash.sumBy(tableData, 'merchantTotalNum'),
      yearAddActiveNum: $lodash.sumBy(tableData, 'yearAddActiveNum'),
      yearActiveNum: $lodash.sumBy(tableData, 'yearActiveNum'),
      active30DaysNum: $lodash.sumBy(tableData, 'active30DaysNum'),
      monthAddActiveNum: $lodash.sumBy(tableData, 'monthAddActiveNum'),
      firstMonthNotActiveNum: $lodash.sumBy(tableData, 'firstMonthNotActiveNum'),
      secondMonthNotActiveNum: $lodash.sumBy(tableData, 'secondMonthNotActiveNum'),
      thirdMonthNotActiveNum: $lodash.sumBy(tableData, 'thirdMonthNotActiveNum'),
      fourMonthNotActiveNum: $lodash.sumBy(tableData, 'fourMonthNotActiveNum'),
      fiveMonthNotActiveNum: $lodash.sumBy(tableData, 'fourMonthNotActiveNum')
    }

    tableData.push(totalObj)

    this.setState({
      tableData
    })
  }

  renderRegionLabel = (code) => {
    let label = '----'
    let obj = RegionJson.find((item) => { return +item.code === +code })
    if (obj !== undefined) {
      label = obj.name
    }

    return label
  }

   // 收起和展开
   getfaly =() => {
     const { faly = 0 } = this.state
     this.setState({
       faly: faly === 0 ? 1 : 0
     })
     let id = document.getElementById('rowid')
     id.style.height = faly === 0 ? '25px' : '400px'
   }

   render () {
     const { searchData, tableData = [], faly } = this.state

     return (
       <>
         <Card bodyStyle={{ padding: '12px' }}>
           <SearchForm {...this.props}
             dataSource={searchData}
             handleSubmit={(obj) => this.handleSearch(obj)}
           />
         </Card>

         <Row style={{ marginTop: '20px' }}>
           <Card>
             <Row id='rowid' className={styles.mianrow}>
               <div id='merchantActiveChart' style={{ width: `${window.innerWidth - 300}px`, height: '400px' }} />
             </Row>
             <div className={styles.line}> <Divider />
               <Button onClick={() => { this.getfaly() }} className={styles.aicon}><img src={Icon[faly].src} /></Button>
             </div>

           </Card>

         </Row>

         <ActiveTable tableData={tableData} />
       </>
     )
   }
}
