import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

import styles from './index.module.scss'

export default class SheetTable extends Component {
  static propTypes = {
    list: PropTypes.array
  }
  state = {}

  componentDidMount () {}

  render () {
    const { list = [] } = this.props
    let colSpan = 6
    if (list && list.length > 0) {
      colSpan = Math.floor(24 / list.length)
    }

    return (
      <>
        <Row gutter={12}>
          {
            list.map((row, i) => (
              <Col span={colSpan} key={`sheetTableCol-${i}`}>
                <div className={`${styles.colTitle}`} title={row.columnName}>
                  { row.columnName }
                </div>

                {
                  row.cellArray && row.cellArray.length > 0
                    ? <ul className={`${styles.listWrapper}`}>
                      {
                        row.cellArray.map((item, n) => (
                          <li className={`${styles.listItem}`} key={`listItem-${i}-${n}`}>{ item }</li>
                        ))
                      }
                    </ul>
                    : null
                }

              </Col>
            ))
          }
        </Row>
      </>
    )
  }
}
