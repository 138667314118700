import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input, Icon, Upload, message } from 'antd'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './setfloor.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const FormItem = Form.Item
const { TextArea } = Input
const { IMG_LT2M } = consts

const imgSizeTips = [
  { type: 1, tipText: `图片宽为400px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 2, tipText: `图片宽为1200px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 3, tipText: `图片尺寸为 240px * 620px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 4, tipText: `图片尺寸为 400px * 400px, 图片大小不超过${IMG_LT2M}MB` }
]

class SetfloorForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-编辑
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    coverImage: '', // 封面图片
    editInfo: {},
    list: []
  }

  componentDidMount () {
    const { type = 1 } = this.props
    if (type === 2) {
      this.getBrandmuseTemplateElementList()
    } else {
      this.initProductList([], 8)
    }
  }

  // 获取楼层内容
  getBrandmuseTemplateElementList = () => {
    const { detailInfo = {} } = this.props
    Api.getBrandmuseTemplateElementList({ containerId: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = []
        let _coverImage = ''

        if (data && data.length > 0) {
          _list = data.filter(item => {
            return +item.type !== 99
          })

          let obj = data.find(item => {
            return +item.type === 99
          })

          if (obj) {
            _coverImage = obj.imageUrl
          }
        }

        if (_list.length < 8) {
          let _num = 8 - _list.length
          _list = this.initProductList(_list, _num)
        }

        this.setState({
          editInfo: data || {},
          list: _list,
          coverImage: _coverImage
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { detailInfo = {} } = this.props
    const { list = [], coverImage = '' } = this.state

    if (coverImage === '') {
      return message.warning('请上传封面图片')
    }

    let arr = []

    list.map(item => {
      if (item.imageUrl !== '') {
        let obj = {
          id: item.id || 0,
          imageUrl: item.imageUrl,
          linkUrl: item.linkUrl || '',
          name: item.name || '',
          remark: item.remark || '',
          type: 5
        }

        arr.push(obj)
      }
    })

    arr.push({ imageUrl: coverImage, type: 99, name: '', remark: '', linkUrl: '', id: 0 })

    let params = {
      containerId: detailInfo.id,
      brandId: detailInfo.brandId,
      elementList: arr
    }

    this.props.onConfirm(params)
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info, i, str) => {
    if (info.file.status === 'done') {
      let { coverImage = '', list = [] } = this.state
      const { data } = info.file.response

      if (str === 'COVER') {
        coverImage = data.imageUrl
      } else {
        list[i].imageUrl = data.imageUrl
      }

      this.setState({
        coverImage,
        list
      })
    }
  }

  // 删除封面图片
  handleRemoveCoverImage = () => {
    this.setState({
      coverImage: ''
    })
  }

  // 删除图片
  handleRemoveImage = (i) => {
    let { list = [] } = this.state

    list[i].imageUrl = ''

    this.setState({
      list
    })
  }

  // 监听输入框变化
  changeInputValue = (val, i, str = '') => {
    let { list = [] } = this.state

    if (str === '') {
      return false
    }

    list[i][str] = val

    this.setState({
      list
    })
  }

  // 设置初始化数据结构
  initProductList = (arr, num) => {
    for (let i = 0; i < num; i++) {
      let obj = {
        imageUrl: '',
        name: '',
        remark: '',
        linkUrl: ''
      }

      arr.push(obj)
    }

    return arr
  }

  // 上传图片文字提示
  renderImgSizeTips = (type) => {
    const obj = imgSizeTips.find(item => { return item.type === type })
    const text = obj !== undefined ? obj.tipText : ''

    return text
  }

  render () {
    const { coverImage = '', list = [] } = this.state
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <div>
        <Modal title='编辑楼层' bodyStyle={{ padding: '12px', height: '500px', overflowY: 'auto' }}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <div style={{ color: '#ff3434' }}>
            建议：{this.renderImgSizeTips(3)}
          </div>
          <Row className={`${styles.editRow}`}>
            <div className={styles.uploadArea}>
              {
                coverImage && coverImage !== ''
                  ? (
                    <div className={styles.thumbWrap}>
                      <div className={styles.thumbInfo}>
                        <img src={coverImage} />

                        <a className={styles.prewModal}>
                          <Icon type='delete' style={{ fontSize: '20px' }}
                            onClick={() => this.handleRemoveCoverImage()}
                          />
                        </a>
                      </div>
                    </div>
                  )
                  : (
                    <Upload
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info, 0, 'COVER')}
                    >
                      <div>
                        <Icon type='plus' />
                        <div style={{ fontSize: '12px' }}>点击上传</div>
                      </div>

                    </Upload>
                  )
              }
            </div>
            <div className={styles.inputArea}>
              <div>封面图片</div>
            </div>

          </Row>
          <Row>
            <div style={{ color: '#ff3434' }}>
              建议：{this.renderImgSizeTips(4)}
            </div>
            <Form>
              {
                list.map((item, i) => (
                  <Row className={`${styles.editRow}`} key={`formItem-${i}`}>
                    <div className={styles.uploadArea}>
                      {
                        item.imageUrl && item.imageUrl !== ''
                          ? (
                            <div className={styles.thumbWrap}>
                              <div className={styles.thumbInfo}>
                                <img src={item.imageUrl} />

                                <a className={styles.prewModal}>
                                  <Icon type='delete' style={{ fontSize: '20px' }}
                                    onClick={() => this.handleRemoveImage(i)}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          : (
                            <Upload
                              showUploadList={false}
                              listType='picture-card'
                              accept='image/png, image/jpg, image/jpeg'
                              headers={{ Token: getStore('token') }}
                              action={uploadOrdinaryImageUrl}
                              beforeUpload={this.beforeUpload}
                              onChange={(info) => this.handleChangeUpload(info, i, 'LIST')}
                            >
                              <div>
                                <Icon type='plus' />
                                <div style={{ fontSize: '12px' }}>点击上传</div>
                              </div>

                            </Upload>
                          )
                      }
                    </div>
                    <div className={styles.inputArea}>
                      <FormItem label='名称' {...formItemLayout}>
                        <Input
                          placeholder='请输入...'
                          autoComplete='off'
                          value={item.name || ''}
                          onChange={(e) => this.changeInputValue(e.target.value.trim(), i, 'name')}
                        />
                      </FormItem>

                      <FormItem label='描述' {...formItemLayout}>
                        <TextArea
                          rows={4}
                          placeholder='请输入...'
                          autoComplete='off'
                          value={item.remark || ''}
                          onChange={(e) => this.changeInputValue(e.target.value.trim(), i, 'remark')}
                        />

                      </FormItem>

                      <FormItem label='跳转链接' {...formItemLayout}>
                        <Input
                          placeholder='请输入...'
                          autoComplete='off'
                          value={item.linkUrl || ''}
                          onChange={(e) => this.changeInputValue(e.target.value.trim(), i, 'linkUrl')}
                        />
                      </FormItem>
                    </div>
                  </Row>
                ))
              }

            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const SetfloorModal = Form.create()(SetfloorForm)

export default SetfloorModal
