import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, message, Modal } from 'antd'

import SetfloorModal from './setfloor3Modal'
import FloorModal from './floorModal'

import Api from '@/common/api/index'

import styles from './navdecorate.module.scss'

const { confirm } = Modal

export default class FloorTpl5 extends Component {
  static propTypes = {
    floorInfo: PropTypes.object,
    onRefush: PropTypes.func,
    onDelete: PropTypes.func
  }
  state = {
    editModal: false,
    floorModal: false
  }

  componentDidMount () { }

  // 保存编辑楼层名称
  saveEditNavFloorName = (params) => {
    Api.saveEditNavFloorName(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.onRefush()
      }
    })
  }

  // 编辑banner广告容器
  editAdvertContainerInBanner = (params) => {
    Api.editAdvertContainerInBanner(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.onRefush()
      }
    })
  }

  // 楼层模板内容编辑
  saveEditBrandmuseElement = (params) => {
    Api.saveEditBrandmuseElement(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.onRefush()
      }
    })
  }

  // banner广告容器元素编辑
  editElementInBannerContainer = (params) => {
    Api.editElementInBannerContainer(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.onRefush()
      }
    })
  }

  // 点击编辑楼层
  handleShowEditModal = () => {
    this.setState({
      editModal: true
    })
  }

  // 确定编辑元素
  handleConfirmEditElement = (params) => {
    this.saveEditBrandmuseElement(params)
  }

  // 点击删除楼层
  handleRemoveFloor = () => {
    confirm({
      title: '确定删除该楼层？',
      onOk: () => {
        const { floorInfo = {} } = this.props
        this.props.onDelete({ id: floorInfo.id })
      }
    })
  }

  // 点击编辑名称
  handleShowEditNameModal = () => {
    this.setState({
      floorModal: true
    })
  }

  // 确定编辑容器
  handleConfirmEditContainer = (params) => {
    const { floorInfo = {} } = this.props
    params.id = floorInfo.id
    params.type = 5
    this.saveEditNavFloorName(params)
  }

  // 设置初始化数据结构
  initProductList = (arr, num) => {
    for (let i = 0; i < num; i++) {
      let obj = {
        imageUrl: '',
        name: '',
        remark: '',
        linkUrl: ''
      }

      arr.push(obj)
    }

    return arr
  }

  closeModal = () => {
    this.setState({
      editModal: false,
      floorModal: false
    })
  }

  render () {
    const { floorInfo = {} } = this.props
    const { editModal = false, floorModal = false } = this.state
    const { elementList = [] } = floorInfo
    let coverInfo = {}
    let list = []

    if (elementList.length > 0) {
      list = elementList.filter(item => {
        return +item.type !== 99
      })

      let obj = elementList.find(item => {
        return +item.type === 99
      })

      if (obj) {
        coverInfo = obj
      }
    }

    if (list.length < 8) {
      let _num = 8 - list.length
      list = this.initProductList(list, _num)
    }

    return (
      <div>
        <Row className={`${styles.floorRow}`} gutter={12}>
          <Col span={20}>
            <div className={`${styles.floorTpl5} ${styles.solidLine}`}>
              {/* title */}
              <div className={`${styles.floorTitle}`}>
                {floorInfo.name}
              </div>

              <div className={`${styles.flex}`}>
                <div className={`${styles.pullLeft}`}>
                  <div className={`${styles.imgWrap}`}>
                    <img src={coverInfo.imageUrl} />
                  </div>
                </div>
                <div className={`${styles.mediaBody}`}>

                  <Row className={`${styles.brickList}`} gutter={12}>
                    {
                      list.map((item, i) => (
                        <Col span={6}
                          key={`product-${i}`}
                        >
                          <div className={`${styles.brickItem}`}>
                            <div className={`${styles.figure}`}>
                              {
                                item.imageUrl !== ''
                                  ? <img src={item.imageUrl} />
                                  : <span className={`${styles.seatImg}`}>图片</span>
                              }

                            </div>
                            <div className={`${styles.name}`}>
                              {
                                item.name !== ''
                                  ? item.name
                                  : '名称'
                              }
                            </div>
                            <div className={`${styles.desc}`}>
                              {item.remark !== '' ? item.remark : '描述文案'}
                            </div>
                          </div>
                        </Col>
                      ))
                    }
                  </Row>

                </div>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div style={{ marginBottom: '10px' }}>
              <Button type='primary' size='small'
                onClick={() => this.handleShowEditNameModal()}
              >
                编辑名称
              </Button>
            </div>

            <div style={{ marginBottom: '10px' }}>
              <Button type='primary' size='small'
                onClick={() => this.handleShowEditModal()}
              >
                编辑内容
              </Button>
            </div>

            <div>
              <Button type='danger' size='small'
                onClick={() => this.handleRemoveFloor()}
              >
                删除楼层
              </Button>
            </div>
          </Col>
        </Row>

        {
          editModal
            ? <SetfloorModal
              type={list.length > 0 ? 2 : 1}
              detailInfo={floorInfo}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.saveEditBrandmuseElement(params)}
            />
            : null
        }

        {
          floorModal
            ? <FloorModal
              type={2}
              detailInfo={{ name: floorInfo.name }}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleConfirmEditContainer(params)}
            />
            : null
        }
      </div>
    )
  }
}
