import MCActiveReport from '@/pages/dashboard/mcActiveReport/index'
import MCNumbersReport from '@/pages/dashboard/mcNumbersReport/index'
import MCOrdersReport from '@/pages/dashboard/mcOrdersReport/index'
import OrderNumbersReport from '@/pages/dashboard/orderNumbersReport/index'
import MCOrderNumbersReport from '@/pages/dashboard/mcOrderNumbersReport'
import ComprehensiveReport from '@/pages/dashboard/comprehensiveReport'
import WorkdBoard from '@/pages/dashboard/worksBoard/index'
import SpecialPriceBoard from '@/pages/dashboard/specialPriceBoard/index'

import QuotationsBoard from '@/pages/dashboard/quotationsBoard/index' // 报价统计
import EnquiryBoard from '@/pages/dashboard/enquiryBoard' // 询价统计
import PrepareQuotedBoard from '@/pages/dashboard/prepareQuotedBoard' // 预报价统计
import WorkQuoteBoard from '@/pages/dashboard/workQuoteBoard' // 工单报价统计
import UnplaceOrderQuoteBoard from '@/pages/dashboard/unplaceorderQuotedBoard' // 未成单报价统计

export default [
  {
    path: '/admin/mcActiveReport',
    name: '商户活跃统计',
    component: MCActiveReport
  },
  {
    path: '/admin/mcNumbersReport',
    name: '商户数量统计',
    component: MCNumbersReport
  },
  {
    path: '/admin/mcOrdersReport',
    name: '商户订单统计',
    component: MCOrdersReport
  },
  {
    path: '/admin/orderNumbersReport',
    name: '订单数量统计',
    component: OrderNumbersReport
  },
  {
    path: '/admin/mcOrderNumbersReport',
    name: '商户订单数量查询',
    component: MCOrderNumbersReport
  },
  {
    path: '/admin/comprehensiveReport',
    name: '综合统计',
    component: ComprehensiveReport
  },
  {
    path: '/admin/workdBoard',
    name: '工单统计',
    component: WorkdBoard
  },
  {
    path: '/admin/specialPriceBoard',
    name: '商户特价产品统计',
    component: SpecialPriceBoard
  },
  {
    path: '/admin/quotationsBoard',
    name: '报价统计',
    component: QuotationsBoard
  },
  {
    path: '/admin/enquiryBoard',
    name: '询价统计',
    component: EnquiryBoard
  },
  {
    path: '/admin/prepareQuotedBoard',
    name: '预报价统计',
    component: PrepareQuotedBoard
  },
  {
    path: '/admin/workQuoteBoard',
    name: '工单报价统计',
    component: WorkQuoteBoard
  },
  {
    path: '/admin/unplaceorderQuoteBoard',
    name: '未成单报价统计',
    component: UnplaceOrderQuoteBoard
  }
]
