import React, { Component } from 'react'

import Api from '@/common/api/index'

export default class FinanceAnalysis extends Component {
  static propTypes = {}
  state = {
    pageUrl: ''
  }

  componentDidMount () {
    this.getFinanceAnalysisViewUrl()
  }

  getFinanceAnalysisViewUrl = () => {
    Api.getFinanceAnalysisViewUrl({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          pageUrl: data
        })
      }
    })
  }

  render () {
    let winHeight = document.body.clientHeight || document.documentElement.clientHeight
    let h = winHeight - 74

    const { pageUrl = '' } = this.state

    return (
      <div id='analysisWrap'>
        <iframe style={{ width: '100%', height: `${h}px`, border: '0px' }}
          src={pageUrl}
        />
      </div>
    )
  }
}
