import React, { Component } from 'react'
import { Card, Table, Button, Icon, Tooltip } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { pageSizeOptions, repLineBreak } = filter
const { MAX_PAGESIZE } = consts

class QutationsBoard extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'quotationOrderId',
        formType: 'Input',
        itemParams: {
          label: '报价单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '报价日期'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '报价商户'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '报价人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'brandName',
        formType: 'Input',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'quotationsBoardCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '报价单编号',
      key: 'quotationsBoarCol-quotationOrderId',
      dataIndex: 'quotationOrderId'
    },
    {
      title: '报价日期',
      key: 'quotationsBoarCol-createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '报价商户',
      key: 'quotationsBoarCol-distributorName',
      dataIndex: 'distributorName'
    },
    {
      title: '报价人',
      key: 'quotationsBoarCol-platformUserName',
      dataIndex: 'platformUserName'
    },
    {
      title: '行号',
      key: 'quotationsBoarCol-rownum',
      dataIndex: 'rownum'
    },
    {
      title: '品牌',
      key: 'quotationsBoarCol-brandName',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'quotationsBoarCol-model',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}><span>{ record.model }</span></Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'quotationsBoarCol-itemNumber',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}><span>{ record.itemNumber }</span></Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'quotationsBoarCol-name',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}><span>{ record.name }</span></Tooltip>
        </>
      )
    },
    {
      title: '单位',
      key: 'quotationsBoarCol-unit',
      dataIndex: 'unit'
    },
    {
      title: '价格',
      key: 'quotationsBoarCol-sellingPrice',
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{ +record.sellingPrice }</>
      )
    },
    {
      title: '数量',
      key: 'quotationsBoarCol-quantity',
      dataIndex: 'quantity'
    },
    {
      title: '是否承担运费',
      key: 'quotationsBoarCol-isUndertakeFreight',
      dataIndex: 'isUndertakeFreight',
      render: (text, record, index) => (
        <>{ record.isUndertakeFreight === 'UNDERTAKE' ? '是' : '否' }</>
      )
    },
    {
      title: '报价有效期',
      key: 'quotationsBoarCol-expiryDateTime',
      dataIndex: 'expiryDateTime',
      render: (text, record, index) => (
        <>{ record.expiryDateTime ? moment(record.expiryDateTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    }
  ]

  componentDidMount () {
    this.getQuotationsBoardList()
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[4].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        const { searchData = [] } = this.state
        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取全部成员
  getMemberList = (val = '') => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.list && data.list.length > 0) {
        const { searchData = [] } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, userName: '全部' }], data.list)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取报价统计列表
  getQuotationsBoardList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getQuotationsBoardList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search['beginDate'] = search.times.length === 2 ? moment(search.times[0]).format('YYYY-MM-DD') : undefined
      search['endDate'] = search.times.length === 2 ? moment(search.times[1]).format('YYYY-MM-DD') : undefined
      delete search.times
    }

    if (search.itemNo) {
      let modelList = repLineBreak(search.itemNo, '<br>')
      search['modelList'] = modelList.split('<br>')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      },
      query: search || {}
    }, () => {
      this.getQuotationsBoardList()
    })
  }

  // 点击导出
  handleDownload = () => {
    const { query = {} } = this.state
    DownloadApi.downloadQuotationsBoardStatistics({ ...query })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getQuotationsBoardList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getQuotationsBoardList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handleDownload()}><Icon type='download' />导出</Button>
          </div>

          <Table rowKey={(record, index) => `${record.quotationOrderId}-${record.commoditySkuId}-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default QutationsBoard
