import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class BindRelationForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    bindKey: PropTypes.string, // BIND_CUSTOMER-客户关系 BIND_BRAND-品牌关系
    type: PropTypes.string, // ADD-新增 EDIT-编辑
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  handleConfirm = () => {
    const { form, bindKey, type = 'ADD', detailInfo = {}, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const params = {
          ...values,
          id: type === 'EDIT' ? detailInfo.id : undefined,
          brandId: bindKey === 'BIND_BRAND' && type === 'EDIT' ? detailInfo.brandId : undefined,
          distributorId: bindKey === 'BIND_CUSTOMER' && type === 'EDIT' ? detailInfo.distributorId : undefined
        }

        onConfirm(params)
      }
    })
  }

  render () {
    const { form, bindKey = 'BIND_CUSTOMER', type = 'ADD', detailInfo = {}, onCancel } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title={type === 'EDIT' ? '编辑关系' : '新增关系'}
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => onCancel()}
        >
          <Form>
            {
              bindKey === 'BIND_CUSTOMER'
                ? <>
                  <FormItem label='ERP客户编号' {...formItemLayout}>
                    {
                      getFieldDecorator('erpDistributorCode', {
                        initialValue: detailInfo.erpDistributorCode,
                        rules: [
                          { required: true, message: '请填写ERP客户编号' }
                        ]
                      })(<Input placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='ERP客户名称' {...formItemLayout}>
                    {
                      getFieldDecorator('erpDistributorName', {
                        initialValue: detailInfo.erpDistributorName,
                        rules: [
                          { required: true, message: '请填写ERP客户名称' }
                        ]
                      })(<Input placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                </>
                : <>
                  <FormItem label='ERP品牌编号' {...formItemLayout}>
                    {
                      getFieldDecorator('erpBrandCode', {
                        initialValue: detailInfo.erpBrandCode,
                        rules: [
                          { required: true, message: '请填写ERP品牌编号' }
                        ]
                      })(<Input placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='ERP品牌全名' {...formItemLayout}>
                    {
                      getFieldDecorator('erpBrandName', {
                        initialValue: detailInfo.erpBrandName,
                        rules: [
                          { required: true, message: '请填写ERP品牌全名' }
                        ]
                      })(<Input placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='ERP助记码' {...formItemLayout}>
                    {
                      getFieldDecorator('erpBrandAlias', {
                        initialValue: detailInfo.erpBrandAlias,
                        rules: [
                          { required: true, message: '请填写ERP助记码' }
                        ]
                      })(<Input placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                </>
            }
          </Form>
        </Modal>

      </>
    )
  }
}

const BindRelationModal = Form.create()(BindRelationForm)

export default BindRelationModal
