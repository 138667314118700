import React, { Component } from 'react'
import { Row, Tabs, Card } from 'antd'

import PinganServer from './pinganService' // 平安保险
import LogisticsService from './logistics-service'

import SAASCourierJson from '@/assets/json/saasCourier.json'

import styles from './commont.module.scss'
const { TabPane } = Tabs
const tabPaneMaps = [
  ...SAASCourierJson,
  { aliasCode: 'INSURANCE', aliasName: '平安保险' }
]

export default class index extends Component {
  static propTypes = {}
  state = {
    activeKey: 'DBL'
  }

  componentDidMount () {}

  handleChangeTabPane = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'DBL' } = this.state

    return (
      <>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <Row className={`${styles.tabWrap}`}>
              <Tabs activeKey={activeKey} onChange={(key) => this.handleChangeTabPane(key)}>
                {
                  tabPaneMaps.map((item) => (
                    <TabPane tab={item.aliasName} key={item.aliasCode} />
                  ))
                }
              </Tabs>
            </Row>
          </Card>
        </Row>
        {
          activeKey === 'INSURANCE'
            ? <PinganServer />
            : null
        }

        {
          activeKey === 'DBL'
            ? <LogisticsService activeKey={activeKey} />
            : null
        }

        {
          activeKey === 'SF'
            ? <LogisticsService activeKey={activeKey} />
            : null
        }

        {
          activeKey === 'JD'
            ? <LogisticsService activeKey={activeKey} />
            : null
        }

        {
          activeKey === 'KYSY'
            ? <LogisticsService activeKey={activeKey} />
            : null
        }

      </>
    )
  }
}
