import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Icon } from 'antd'

import EnquireOrderPreview from '../components/enquirePreviewTable/index'
import QuotationPreviewTable from '../components/quotationPreviewTable/index'
import Remarks from '../components/remarks/index'

import Api from '@/common/api/index'

import styles from './index.module.scss'

export default class EnquireProcess extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    enquiryOrderDetails: [], // 询价单数据
    enquiryQuotationDetails: [], // 报价单数据
    enquireRemarks: ''
  }

  componentDidMount () {
    const { orderId } = this.props.history.location.state

    this.getEnquireProcessDetails({ orderId: orderId })
  }

  // 获取询价过程详情
  getEnquireProcessDetails = async (params) => {
    const res = await Api.getEnquireProcessDetails(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        enquiryOrderDetails: data.enquiryOrderDetails || [],
        enquiryQuotationDetails: data.enquiryQuotationDetails || [],
        enquireRemarks: data.remark || ''
      })
    }
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { enquiryOrderDetails = [], enquiryQuotationDetails = [], enquireRemarks = '' } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}><Icon type='left' />返回列表</Button>
        </div>
        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '15px' }}>
              <EnquireOrderPreview list={enquiryOrderDetails} />
            </div>
            <Remarks title='商户备注' remarks={[{ remarks: enquireRemarks }]} />
          </div>

          <div style={{ marginBottom: '24px', fontSize: '18px', color: '#333333' }}>
            全网1200+供应商正在为您报价，{enquiryQuotationDetails.length > 0 ? '已有' : '暂无'}供应商回复
          </div>

          {
            enquiryQuotationDetails.map((record, index) => (
              <div className={`${styles.quotationItem}`} key={`quotationListItem-${index}`}>
                <div style={{ marginBottom: '15px' }}>
                  <QuotationPreviewTable
                    title={<>第{index + 1}次报价</>}
                    descriptions={record.desc}
                    list={record.enquiryQuotationOrderDetails || []}
                  />
                </div>
                <Remarks title='商户备注' remarks={record.enquiryRemark && record.enquiryRemark !== '' ? [{ remarks: record.enquiryRemark }] : []} />
                <Remarks title='客服备注' remarks={record.quotationRemark && record.quotationRemark !== '' ? [{ remarks: record.quotationRemark }] : []} />
              </div>
            ))
          }
        </Card>
      </>
    )
  }
}
