import Ajax from '../utils/ajax'

export default {

  /* 产品管理 */
  // 获取产品参数列表
  getAddAttributeParamterList (params) {
    return Ajax.post('/platform/attribute/listPage', params)
  },

  // 新增产品参数
  saveAddAttributeParamterItem (params) {
    return Ajax.post('/platform/attribute/attributeAdd', params)
  },

  // 删除产品参数
  deleteAttributeParamterItem (params) {
    return Ajax.post('/platform/attribute/deleteById', params)
  },

  // 编辑产品参数
  saveEditAttributeParamterItem (params) {
    return Ajax.post('/platform/attribute/attributeEdit', params)
  },

  /* 分类管理 */
  // 获取分类列表
  getProductCategoryList (params) {
    return Ajax.get('/commodity/category/list', { params })
  },

  // 新增/编辑 保存分类
  saveClassification (params) {
    return Ajax.post('/commodity/category/save', params)
  },

  // 删除分类
  deleteClassification (params) {
    return Ajax.post('/commodity/category/delete', params)
  },
  /* 分类管理 END */

  // 获取品牌列表
  getBrandList (params) {
    return Ajax.post('/commodity/brand/queryList', params)
  },

  // 获取品牌列表(检索用)
  getBrandListInSearch (params) {
    return Ajax.post('/commodity/brand/listPageForSearch', params)
  },

  // 新增/编辑 保存品牌
  saveBrand (params) {
    return Ajax.post('/commodity/brand/save', params)
  },

  // 删除品牌
  deleteBrand (params) {
    return Ajax.post('/commodity/brand/delete', params)
  },

  /* 系列管理 */
  // 获取系列列表
  getSeriesList (params) {
    return Ajax.post('/commodity/brandSeries/list', params)
  },

  // 新增/编辑 保存系列
  saveSeries (params) {
    return Ajax.post('/commodity/brandSeries/save', params)
  },

  // 删除系列
  deleteSeries (params) {
    return Ajax.post('/commodity/brandSeries/delete', params)
  },
  /* 系列 END */

  // 产品型号
  // 产品型号(SKU)列表
  getSkuList (params) {
    return Ajax.post('/commodity/sku/skuList', params)
  },

  // 获取SKU详情信息
  getCommoditySkuInfo (params) {
    return Ajax.get('/commodity/sku/selectCommoditySkuDetail', { params })
  },

  // 新增 保存型号
  saveAddSku (params) {
    return Ajax.post('/commodity/sku/add', params)
  },

  // 编辑 保存型号
  saveSku (params) {
    return Ajax.post('/commodity/sku/skuSave', params)
  },

  // 删除型号
  saveDeleteSku (params) {
    return Ajax.post('/commodity/sku/remove', params)
  },

  // 产品分类管理
  // 获取未建立分类关系的产品列表
  getnoBindCategoryList (params) {
    return Ajax.post('/commodity/sku/noBindCategoryList', params)
  },

  // 保存产品与分类的关联
  saveSkuBindToCategory (params) {
    return Ajax.post('/commodity/sku/skuBindToCategory', params)
  },

  // 获取已绑定系列的产品列表
  getSkuListInBindedSeries (params) {
    return Ajax.post('/commodity/brandSeries/listPageByBindSeries', params)
  },

  // 获取未绑定系列的产品列表
  getnoBindSeriesList (params) {
    return Ajax.post('/commodity/sku/noBindSeriesList', params)
  },

  // 保存产品与系列的关联
  saveSkuBindToSeries (params) {
    return Ajax.post('/commodity/sku/skuBindToSeries', params)
  },

  // 取消产品与系列的关联
  cancelBindSkuToSeries (params) {
    return Ajax.post('/commodity/brandSeries/batchCancelBind', params)
  },

  // 获取品牌下的所有产品
  queryThroughIndex (params) {
    return Ajax.post('/commodity/sku/queryThroughIndex', params)
  },

  // 根据第四级分类查找父级
  queryCategoryAllTiers (params) {
    return Ajax.get('/commodity/category/queryCategoryAllTiers', { params })
  },

  // 获取产品详情信息
  getSkuDetail (params) {
    return Ajax.post('/commodity/sku/view', params)
  },

  // 获取SKU重复数据列表
  getUploadSkuErrorList (params) {
    return Ajax.post('/commodity/sku/uploadSkuList', params)
  },

  // 根据重复数据查询SKU信息
  getSkuInfoByDisputeId (params) {
    return Ajax.post('/commodity/sku/selectSkuByUploadLog', params)
  },

  // 重复数据修改
  uploadLogUpdate (params) {
    return Ajax.post('/commodity/sku/uploadLogUpdate', params)
  },

  // 重复数据批量覆盖
  batchSycToCommoditySku (params) {
    return Ajax.post('/commodity/sku/batchSycToCommoditySku', params)
  },

  // 重复数据单个覆盖
  sycUpdateSkuToSku (params) {
    return Ajax.post('/commodity/sku/sycUploadSkuToSku', params)
  },

  // 重复数据单个删除
  sysRemoveDisputeSku (params) {
    return Ajax.post('/commodity/sku/uploadLogRemove', params)
  },

  // 同步分类信息至商家
  updateMerchantCategorys (params) {
    return Ajax.post('/commodity/category/distributorRefresh', params)
  },

  // 根据关键字搜索分类
  getSearchCategoryBykeyword (params) {
    return Ajax.post('/commodity/category/selectListByCategory', params)
  },

  // 获取外部未同步商品批次号列表
  getListPageForUnSyncDataInOutside (params) {
    return Ajax.post('/platform/commoditySku/listPageForUnSyncData', params)
  },

  // 获取外部产品数量
  handleLookSkuTotal (params) {
    return Ajax.get('/platform/commoditySku/countByUnSyncSkuData', { params })
  },

  // 外部数据监控- 获取外部监控数据
  getReferenceSyncData (params) {
    return Ajax.post('/externalDataSync/query/sync/detail', params)
  },

  // 产品列表- 获取商品图片详情接口
  getProductSkuImg (params) {
    return Ajax.get('/platform/commoditySku/imageDetail', { params })
  },

  // 产品列表- 更新商品图片详情接口
  updateProductBatchSkuImgs (params) {
    return Ajax.post('/platform/commoditySku/batchUploadImage', params)
  },

  // 产品列表- 获取关联图片任务进度列表
  getBindSkuImagesTaskProgressList (params) {
    return Ajax.post('/platform/commoditySku/listPageForBatchUploadImageTask', params)
  },

  // 自建图片审核列表
  getImgexamineList (params) {
    return Ajax.post('/platform/commoditySku/listPageForAttributeAuditImage', params)
  },

  // 自建图片审核详情
  getImgDetail (params) {
    return Ajax.post('/platform/commoditySku/attributeAuditImageDetail', params)
  },

  // 自建图片审核详情更新
  saveMerchantSkuImageModify (params) {
    return Ajax.post('/platform/commoditySku/merchantSkuImageModify', params)
  },

  // 商户列表-获取商户绑定的特价产品列表
  getMerchantBindedSpecialProductList (params) {
    return Ajax.post('/platform/merchant/bargainsProduct/listPage', params)
  },

  // 商户列表 》 特价产品设置 》 获取可绑定的产品列表
  getCanBindProductList (params) {
    return Ajax.post('/platform/merchant/bargainsProduct/listPageForProductIndex', params)
  },

  // 商户列表 》 特价产品设置 》 保存新增绑定产品
  saveBindedProductsToMerchant (params) {
    return Ajax.post('/platform/merchant/bargainsProduct/batchInsert', params)
  },

  // 商户列表 》 特价产品设置 》 删除绑定的产品
  deleteBindedProductsToMerchant (params) {
    return Ajax.post('/platform/merchant/bargainsProduct/batchRemove', params)
  },

  // 商户列表 》 特价产品设置 》 更新绑定的产品
  updateBindedProductsToMerchant (params) {
    return Ajax.post('/platform/merchant/bargainsProduct/modify', params)
  },

  // 爬虫数据管理(引用参数数据管理)
  getReferenceSkuList (params) {
    return Ajax.post('/platform/system/commodity/external/info/query/commodity/data/page', params)
  }
}
