import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table, Button, Icon, Tag, Pagination, Tooltip } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './cloudBrand.module.scss'

const { pageSizeOptions, repLineBreak, skuGoodsTypeMaps } = filter
const skuGoodsTypeOptions = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

class CloudBrand extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...skuGoodsTypeOptions
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    commoditySkuId: 0,
    query: {},
    detailInfo: {},
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'cloudproduct-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '分类',
      key: 'cloudproduct-1',
      width: 120,
      ellipsis: true,
      dataIndex: 'classificationName',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.classificationName}>
            <span>{ record.classificationName }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品信息',
      key: 'cloudproduct-2',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <div>
          <div className={`${styles.listRow}`}>
            <label className={`${styles.rowLabel}`}>品牌</label>
            <span title={record.brandName}>{record.brandName}</span>
          </div>
          <div className={`${styles.listRow}`}>
            <label className={`${styles.rowLabel}`}>系列</label>
            <span title={record.series}>{record.series}</span>
          </div>
          <div className={`${styles.listRow}`}>
            <label className={`${styles.rowLabel}`}>名称</label>
            <span title={record.name}>{record.name}</span>
          </div>
        </div>
      )
    },
    {
      title: '型号',
      key: 'cloudproduct-3',
      width: 140,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'cloudproduct-4',
      ellipsis: true,
      width: 130,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品类型',
      key: 'cloudproduct-5',
      width: 80,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '表价',
      key: 'cloudproduct-6',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '市场均价',
      key: 'cloudproduct-7',
      dataIndex: 'avgPrice',
      render: (text, record, index) => (
        <>&yen;{+record.avgPrice}</>
      )
    },
    {
      title: '总库存',
      key: 'cloudproduct-8',
      dataIndex: 'inventory'
    },
    {
      title: '订单库存',
      key: 'cloudproduct-9',
      dataIndex: 'orderInventory'
    },
    {
      title: '操作',
      key: 'cloudproduct-10',
      width: 140,
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.handleLinkToSupplier(record)}>
            查看供应商
          </a>
        </>
      )
    }
  ]

  componentDidMount () {
    const { state } = this.props.history.location
    this.setState({
      detailInfo: state.detailInfo
    }, () => {
      this.getSupplyProductsDetail()
    })
  }

  // 获取品牌产品列表
  getSupplyProductsDetail = () => {
    const { pages, query = {}, detailInfo = {} } = this.state
    let params = {
      ...query,
      id: detailInfo.id,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSupplyProductsDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.itemNo) {
      let _paramStr = repLineBreak(search.itemNo, '<br>')
      search.itemNo = _paramStr
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => this.getSupplyProductsDetail())
  }

  // 点击查看供应商
  handleLinkToSupplier = (record) => {
    const { detailInfo } = this.state
    this.props.history.push({ pathname: '/admin/cloudSupplier', state: { detailInfo: { ...record, brandProfitMargin: detailInfo.profitMargin } } })
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyProductsDetail()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyProductsDetail()
    })
  }

  render () {
    const { searchData, detailInfo, pages, tableData } = this.state

    // 分页配置
    const pagination = {
      // showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Button onClick={() => this.handleBack()}><Icon type='left' />返回列表</Button>
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }} title={`${detailInfo.brandName}`}>
          <Row style={{ marginBottom: '20px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>
          <Row>
            <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}`} bordered
              pagination={false}
              columns={this.columns}
              dataSource={tableData}
              scroll={{ x: 1400 }}
            />

            <div style={{ margin: '16px 0px', textAlign: 'right' }}>
              <Pagination {...pagination} />
            </div>
          </Row>
        </Card>
      </>
    )
  }
}

export default CloudBrand
