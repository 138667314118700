import React, { Component } from 'react'
import { Card, Table } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api/index'
import consts from '@/common/utils/consts'
import filter from '@/common/utils/filter'

const { MAX_PAGESIZE } = consts
const { pageSizeOptions, repLineBreak } = filter

export default class SpecialPriceBoard extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'itemModel',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号...'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '所属商户'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'specialPriceCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'specialPriceCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'specialPriceCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'specialPriceCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'specialPriceCol-4',
      dataIndex: 'name'
    },
    {
      title: '最低价格',
      key: 'specialPriceCol-5',
      dataIndex: 'lowestPrice',
      render: (text, record, index) => (
        <>&yen;{+record.lowestPrice}</>
      )
    },
    {
      title: '折扣',
      key: 'specialPriceCol-6',
      render: (text, record, index) => (
        <>{ (+record.discountRate * 100 * 100 / 100).toFixed(2) }%</>
      )
    },
    {
      title: '所属商户',
      key: 'specialPriceCol-7',
      dataIndex: 'distributorName'
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch()
    this.getMerchantBindedSpecialProductList()
  }

  // 获取品牌列表
  getBrandListInSearch = () => {
    Api.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取商户绑定的特价产品列表
  getMerchantBindedSpecialProductList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getMerchantBindedSpecialProductList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.itemNo) {
      search['itemNo'] = repLineBreak(search.itemNo, '<br>')
    }

    this.setState({
      query: search || {},
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getMerchantBindedSpecialProductList()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMerchantBindedSpecialProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMerchantBindedSpecialProductList()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)} />
          </Card>
        </div>

        <div>
          <Card bodyStyle={{ padding: '12px' }}>
            <Table rowKey='id' bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Card>
        </div>
      </>
    )
  }
}
