import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Radio, Upload, Icon, Row, message } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './child.module.scss'

const FormItem = Form.Item
const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

const tipsTextMaps = [
  `图片尺寸 740px * 460px, 大小不超过${IMG_LT2M}MB`,
  `图片尺寸 1920px * 450px, 大小不超过${IMG_LT2M}MB`
]

class EditbannerForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-编辑
    modelType: PropTypes.number, // 0-940banner 1-1920banner弹窗
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    bannerImage: ''
  }

  componentDidMount () {
    const { type = 1, detailInfo = {} } = this.props

    if (type === 2) {
      this.setState({
        bannerImage: detailInfo.imageKey
      })
    }
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, modelType = 0, detailInfo } = this.props
        const { bannerImage = '' } = this.state

        if (bannerImage === '') {
          return message.warning('请上传图片')
        }

        if (type === 2) {
          values.id = detailInfo.id
        }

        values.type = 'BANNER'

        values.imageKey = bannerImage

        values.modelType = modelType

        this.props.onConfirm(values)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  changeUploadBannerImage = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.setState({
          bannerImage: data.imageUrl
        })
      } else {
        message.error('上传失败')
      }
    }
  }

  // 前端伤处图片
  removeImg = () => {
    this.setState({
      bannerImage: ''
    })
  }

  render () {
    const { modelType = 0, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { bannerImage = '' } = this.state

    return (
      <div>
        <Modal title={`banner模板`}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem label='名称'>
              {
                getFieldDecorator('name', {
                  initialValue: detailInfo.name || '',
                  rules: [
                    { required: true, message: '请输入名称' }
                  ]
                })(
                  <Input placeholder='请输入名称' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='启用状态'>
              {
                getFieldDecorator('enable', {
                  initialValue: detailInfo.enable || 'ENABLE',
                  rules: [
                    { required: true, message: '请选择启用状态' }
                  ]
                })(
                  <Radio.Group>
                    <Radio value={'ENABLE'}>启用</Radio>
                    <Radio value={'DISABLE'}>禁用</Radio>
                  </Radio.Group>
                )
              }
            </FormItem>

            <Row>
              <Row style={{ display: 'inline-block' }}>
                <label className={`formLabel required`}>图片</label>
                <span>{tipsTextMaps[modelType]}</span>
                {
                  <Row>
                    <div className={`${styles.inline}`} key={`uploadBanner`}>
                      {
                        bannerImage !== ''
                          ? (
                            <div className={styles.thumbWrap}>
                              <div className={styles.thumbInfo}>
                                <img src={bannerImage} />

                                <a className={styles.prewModal}>
                                  <Icon type='delete' style={{ fontSize: '20px' }}
                                    onClick={() => this.removeImg()}
                                  />
                                </a>
                              </div>
                            </div>
                          ) : (
                            <Upload
                              showUploadList={false}
                              listType='picture-card'
                              accept='image/png, image/jpg, image/jpeg'
                              headers={{ Token: getStore('token') }}
                              action={uploadOrdinaryImageUrl}
                              beforeUpload={this.beforeUpload}
                              onChange={(info) => this.changeUploadBannerImage(info)}
                            >
                              <Icon type='plus' />
                            </Upload>

                          )
                      }
                    </div>
                  </Row>
                }
              </Row>

            </Row>
          </Form>

        </Modal>
      </div>
    )
  }
}

const EditbannerModal = Form.create()(EditbannerForm)

export default EditbannerModal
