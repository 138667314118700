import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Descriptions, Empty, Button, Icon, Modal } from 'antd'

import AddressModal from '../address-modal/index'

import { renderAddressLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const { confirm } = Modal

class AddressList extends Component {
  static propTypes = {
    componentType: PropTypes.string, // 组件应用场景标识 SEND-发货地址
    addressType: PropTypes.string, // 地址组件业务枚举
    id: PropTypes.number,
    getList: PropTypes.func,
    onSaveAddress: PropTypes.func,
    onEditAddress: PropTypes.func,
    onDeleteAddress: PropTypes.func
  }
  state = {
    list: [],
    type: 1,
    detailInfo: {},
    addressModal: false
  }

  componentDidMount () {
    this.getAddressList()
  }

  // 获取地址列表
  getAddressList = () => {
    const { addressType, id, getList } = this.props

    getList({ addressType: addressType, bindData: id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          list: data || []
        })
      }
    })
  }

  // 保存新增地址
  saveAddress = (params) => {
    const { onSaveAddress } = this.props
    onSaveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.closeModal()
        this.getAddressList()
      }
    })
  }

  // 保存编辑地址
  editAddress = (params) => {
    this.props.onEditAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.closeModal()
        this.getAddressList()
      }
    })
  }

  // 删除地址
  deleteAddress = (params) => {
    this.props.onDeleteAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.closeModal()
        this.getAddressList()
      }
    })
  }

  // 点击保存
  handleSubmit = (values) => {
    const { id, addressType } = this.props
    const { type = 1 } = this.state
    let params = Object.assign({}, values)

    params.bindData = id
    params.addressType = addressType

    if (type === 2) {
      this.editAddress(params)
    } else {
      this.saveAddress(params)
    }
  }

  // 点击删除
  handleDelete = (record) => {
    confirm({
      title: '确定删除?',
      onOk: () => {
        const { id, addressType } = this.props
        this.deleteAddress({ id: record.id, bindData: id, addressType: addressType })
      }
    })
  }

  showAddressModal = (record, type) => {
    this.setState({
      detailInfo: record,
      type: type,
      addressModal: true
    })
  }

  closeModal = () => {
    this.setState({
      type: 1,
      detailInfo: {},
      addressModal: false
    })
  }

  render () {
    const { componentType } = this.props
    const { list = [], addressModal = false, type = 1, detailInfo = {} } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '15px' }}>
            <Button type='primary' disabled={list.length === 10} onClick={() => this.showAddressModal({}, 1)}>
              <Icon type='plus' />新增地址
            </Button>
          </div>

          {
            list.length > 0
              ? <>
                {
                  list.map((item, i) => (
                    <div className={`${styles.listCard} ${item.defaultType === 'ENABLE' ? styles.background : ''}`} key={item.id}>
                      <Descriptions title={<><span>{item.name}</span>{ item.defaultType === 'ENABLE' ? <span className={`${styles.defaultTag}`}>默认地址</span> : null }</>}>
                        <Descriptions.Item label='姓名'>{ item.name }</Descriptions.Item>
                        <Descriptions.Item label='联系方式'>{ item.mobile }</Descriptions.Item>
                        <Descriptions.Item label='所在地区'>{ renderAddressLabel(item.province, item.city, item.county) }</Descriptions.Item>
                        <Descriptions.Item label='详细地址'>{ item.address }</Descriptions.Item>
                      </Descriptions>
                      <div className={`${styles.extraLand}`}>
                        <Button type='link' onClick={() => this.showAddressModal(item, 2)}>编辑</Button>
                        <Button type='link' onClick={() => this.handleDelete(item)}>删除</Button>
                      </div>
                    </div>
                  ))
                }
              </>
              : <Empty />
          }
        </Card>

        {
          addressModal
            ? <AddressModal
              componentType={componentType}
              type={type}
              detailInfo={detailInfo}
              onConfirm={(values) => this.handleSubmit(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default AddressList
