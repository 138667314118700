import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

class EnquiryDetailModal extends Component {
  static propTypes = {
    content: PropTypes.string,
    onCancel: PropTypes.func
  }
  state = {}

  render () {
    const { content = '', onCancel } = this.props

    return (
      <Modal title='询价内容' bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
        visible
        onCancel={() => onCancel()}
        footer={[
          <Button key='cancel-btn' onClick={() => onCancel()}>关闭</Button>
        ]}
      >
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </Modal>
    )
  }
}

export default EnquiryDetailModal
