import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Col, Input, Select, Table, InputNumber } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item
const Option = Select.Option

class OrderSendForm extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    form: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    CourierList: [], // 快递公司列表
    rows: [
      { code: '', mailNo: '' }
    ],
    tableData: [] // 产品列表
  }
  columns = [
    {
      title: '序号',
      key: 'sendProduct-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'sendProduct-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'sendProduct-2',
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'sendProduct-3',
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'sendProduct-4',
      dataIndex: 'quantity'
    },
    {
      title: '已发货数量',
      key: 'sendProduct-5',
      dataIndex: 'deliveryQuantity'
    },
    {
      title: '发货数量',
      key: 'sendProduct-6',
      render: (text, record, index) => (
        <div>
          <InputNumber size='small'
            min={0}
            max={record.quantity - record.deliveryQuantity}
            defaultValue={record.quantity - record.deliveryQuantity}
            disabled={record.quantity - record.deliveryQuantity === 0}
            onChange={(val) => this.handleChangeSendingNumber(val, index)}
          />
        </div>
      )
    },
    {
      title: '单位',
      key: 'sendProduct-7',
      dataIndex: 'unit'
    }
  ]

  componentDidMount () {
    this.getCourierInfo()
    this.getPurchOrderGoodsList()
  }

  // 获取快递公司列表
  getCourierInfo = () => {
    Api.getCourierInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          CourierList: data || []
        })
      }
    })
  }

  // 获取采购订单产品列表
  getPurchOrderGoodsList = () => {
    const { detailInfo } = this.props
    let params = {
      purchaseOrderId: detailInfo.purchaseOrderId,
      distributorId: detailInfo.distributorId,
      pageNo: 1,
      pageSize: 99999
    }
    Api.getPurchOrderGoodsList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data.orderDetail && data.orderDetail.list && data.orderDetail.list.length > 0) {
          data.orderDetail.list.map(item => {
            item['sendNumber'] = item.quantity - item.deliveryQuantity
          })
        }

        this.setState({
          tableData: data.orderDetail.list || []
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { detailInfo } = this.props
        const { tableData = [] } = this.state
        let _arr = []

        tableData.map(item => {
          if (item.sendNumber && +item.sendNumber > 0) {
            let obj = {
              purchaseOrderId: detailInfo.purchaseOrderId,
              commoditySkuId: item.commoditySkuId,
              deliveryQuantity: item.sendNumber
            }
            _arr.push(obj)
          }
        })

        values.purchaseOrderId = detailInfo.purchaseOrderId
        values.purchaseOrderGoods = _arr

        this.props.onConfirm(values)
      }
    })
  }

  // 监听发货数量的输入
  handleChangeSendingNumber = (val, i) => {
    let { tableData = [] } = this.state

    tableData[i].sendNumber = val

    this.setState({
      tableData
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo } = this.props
    const { CourierList = [], tableData = [] } = this.state
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    }

    return (
      <>
        <Modal title={`订单编号：${detailInfo.purchaseOrderId}`}
          width={1000}
          visible
          maskClosable={false}
          bodyStyle={{ padding: '16px', maxHeight: '400px', overflow: 'auto' }}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Form>
              <Row gutter={12}>
                <Col span={10}>
                  <FormItem label='快递公司' {...formItemLayout}>
                    {
                      getFieldDecorator('courierCode', {
                        rules: [
                          { required: true, message: '请选择快递公司' }
                        ]
                      })(
                        <Select placeholder='请选择快递公司'>
                          {
                            CourierList.map((item, i) => (
                              <Option value={item.courierCode} key={`courier-${item.id}`}>{item.courierName}</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem>
                    {
                      getFieldDecorator(`courierNumber`, {
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        rules: [
                          { required: true, message: '请填写运单号' }
                        ]
                      })(
                        <Input placeholder='请输入运单号' autoComplete='off' />
                      )
                    }
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </Row>

          <Row>
            <Table rowKey={(item, i) => { return i }} bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={false}
            />
          </Row>
        </Modal>
      </>
    )
  }
}

const OrderSendModal = Form.create()(OrderSendForm)

export default OrderSendModal
