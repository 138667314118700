import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Upload, Icon, message } from 'antd'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './setfloor.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

const imgSizeTips = [
  { type: 1, tipText: `图片宽为400px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 2, tipText: `图片宽为1200px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 3, tipText: `图片尺寸为 240px * 620px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 4, tipText: `图片尺寸为 400px * 400px, 图片大小不超过${IMG_LT2M}MB` }
]

class SetfloorModal extends Component {
  static propTypes = {
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    rowDetail: {}
  }

  componentDidMount () {
    const { type = 1 } = this.props
    if (type === 2) {
      this.getBrandmuseTemplateElementList()
    }
  }

  // 获取楼层模板内容
  getBrandmuseTemplateElementList = () => {
    const { detailInfo = {} } = this.props
    Api.getBrandmuseTemplateElementList({ containerId: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _rowDetail = {}
        if (data && data.length > 0) {
          _rowDetail = data[0]
        }
        this.setState({
          rowDetail: _rowDetail
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { detailInfo = {} } = this.props
    const { rowDetail = {} } = this.state

    if (!rowDetail.imageUrl || rowDetail.imageUrl === '') {
      return message.warning('请上传图片！')
    }

    let arr = [
      {
        imageUrl: rowDetail.imageUrl,
        type: 6
      }
    ]

    let params = {
      containerId: detailInfo.id,
      brandId: detailInfo.brandId,
      id: rowDetail.id || 0,
      elementList: arr
    }

    this.props.onConfirm(params)
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info, i) => {
    if (info.file.status === 'done') {
      let { rowDetail = {} } = this.state
      const { data } = info.file.response

      rowDetail.imageUrl = data.imageUrl

      this.setState({
        rowDetail
      })
    }
  }

  // 删除图片
  handleRemoveImage = () => {
    let { rowDetail } = this.state

    rowDetail.imageUrl = ''

    this.setState({
      rowDetail
    })
  }

  // 上传图片文字提示
  renderImgSizeTips = (type) => {
    const obj = imgSizeTips.find(item => { return item.type === type })
    const text = obj !== undefined ? obj.tipText : ''

    return text
  }

  render () {
    const { rowDetail = {} } = this.state

    return (
      <div>
        <Modal title='编辑楼层' bodyStyle={{ padding: '12px' }}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >

          <div style={{ color: '#ff3434' }}>
            建议：{this.renderImgSizeTips(2)}
          </div>

          <Row className={`${styles.editRow}`}>
            <div className={styles.uploadArea}>
              {
                rowDetail.imageUrl && rowDetail.imageUrl !== ''
                  ? (
                    <div className={styles.thumbWrap}>
                      <div className={styles.thumbInfo}>
                        <img src={rowDetail.imageUrl} />

                        <a className={styles.prewModal}>
                          <Icon type='delete' style={{ fontSize: '20px' }}
                            onClick={() => this.handleRemoveImage()}
                          />
                        </a>
                      </div>
                    </div>
                  )
                  : (
                    <Upload
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <div>
                        <Icon type='plus' />
                        <div style={{ fontSize: '12px' }}>点击上传</div>
                      </div>

                    </Upload>
                  )
              }
            </div>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default SetfloorModal
