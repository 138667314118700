import React, { Component } from 'react'
import { Card, Table, Pagination, message, Tooltip } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import CloudStatistic from '../component/cloudStatistic/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderSkuGoodsTypeLabel, renderCloudStoreLevelLabel } from '@/common/utils/mUtils'

const { repLineBreak, skuGoodsTypeMaps, cloudStoreLevelMaps } = filter
const { pageSizeOptions } = consts
const skuGoodsTypeOptions = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

// 云库产品列表
class CloudProd extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {

  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (value) => this.handleChangeBrandId(value)
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...skuGoodsTypeOptions
        ]
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '供应商'
        },
        cptParams: {
          placeholder: '请选择供应商...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [
          { id: '', distributorName: '全部' }
        ],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'outDateLevel',
        formType: 'Select',
        itemParams: {
          label: '云仓等级'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudStoreLevelMaps
        ]
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '更新时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    selectedBrandId: 0 // 选中的品牌ID
  }

  columns = [
    {
      title: '序号',
      key: 'cloudProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'cloudProductCol-1',
      dataIndex: 'brandName',
      render: (text, record, index) => (
        <>
          <p><label className='label'>中</label>{ record.brandName }</p>
          <p><label className='label'>英</label>{ record.englishBrandName }</p>
        </>
      )
    },
    {
      title: '系列',
      key: 'cloudProductCol-2',
      width: 120,
      ellipsis: true,
      dataIndex: 'series',
      render: (text, record, index) => (
        <><Tooltip placement='topLeft' title={record.series}>
          <span>{ record.series }</span></Tooltip></>
      )
    },
    {
      title: '型号',
      key: 'cloudProductCol-3',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}>
            <span>{ record.model }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'cloudProductCol-4',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}>
            <span>{ record.itemNumber }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'cloudProductCol-5',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>
            <span>{ record.name }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品类型',
      key: 'cloudProductCol-6',
      width: 80,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '厂商表价',
      key: 'cloudProductCol-7',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '供应价格',
      key: 'cloudProductCol-8',
      dataIndex: 'cloudSupplyPrice',
      render: (text, record, index) => (
        <>&yen;{+record.cloudSupplyPrice}</>
      )
    },
    {
      title: '库存数量',
      key: 'cloudProductCol-9',
      dataIndex: 'cloudStockTotal',
      render: (text, record, index) => (
        <>{ record.skuGoodsType === 'CLOUD_FUTURES' ? <span style={{ fontSize: '16px' }}>&infin;</span> : +record.cloudStockTotal }</>
      )
    },
    {
      title: '货期',
      key: 'cloudProductCol-10',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>{ +record.deliveryPeriodDays > 0 ? `${+record.deliveryPeriodDays}工作日` : '当天发货'}</>
      )
    },
    {
      title: '预付比例',
      key: 'cloudProductCol-11',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => (
        <>{(+record.prepaymentRatio * 100 / 100).toFixed(2)}%</>
      )
    },
    {
      title: '供应商名称',
      key: 'cloudProductCol-12',
      dataIndex: 'distributorName'
    },
    {
      title: '云仓等级',
      key: 'cloudProductCol-13',
      dataIndex: 'outDateLevel',
      render: (text, record, index) => (
        <>{ renderCloudStoreLevelLabel(record.outDateLevel) }</>
      )
    },
    {
      title: '更新时间',
      key: 'cloudProductCol-14',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    this.getAllCloudSkuList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state
        searchData[4].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)
        this.setState({
          searchData
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取云库产品列表
  getAllCloudSkuList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getAllCloudSkuList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    } else {
      message.error(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { itemNo, modifyTimes } = search
    if (itemNo) {
      search['itemNo'] = repLineBreak(itemNo, '<br>')
    }

    if (modifyTimes) {
      search['modifyBeginTime'] = modifyTimes.length === 2 ? modifyTimes[0].format('YYYY-MM-DD') : undefined
      search['modifyEndTime'] = modifyTimes.length === 2 ? modifyTimes[1].format('YYYY-MM-DD') : undefined
    }

    this.setState({
      query: search || {},
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getAllCloudSkuList()
    })
  }

  // 品牌选择
  handleChangeBrandId = (value) => {
    this.setState({
      selectedBrandId: value
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getAllCloudSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getAllCloudSkuList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], selectedBrandId = 0 } = this.state

    // 分页配置
    const pagination = {
      // showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <CloudStatistic
              brandId={selectedBrandId}
              cloudType={'SUPPLY_ALL'}
            />
          </div>
          <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
          <div style={{ margin: '16px 0px', textAlign: 'right' }}>
            <Pagination {...pagination} />
          </div>
        </Card>
      </>
    )
  }
}

export default CloudProd
