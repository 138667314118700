import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { message, Modal, Table } from 'antd'

import Api from '@/common/api/index'

export default class detailsModel extends Component {
  static propTypes = {
    onModalClose: PropTypes.func,
    rowInfo: PropTypes.object
  }
  state = {
    tableData: []
  }

  columns =[
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '产品描述',
      key: 'service-1',
      dataIndex: 'name'
    },
    {
      title: '型号',
      key: 'service-2',
      dataIndex: 'model'
    },

    {
      title: '订单号',
      key: 'service-3',
      dataIndex: 'commoditySkuId'
    },
    {
      title: '数量',
      key: 'service-4',
      dataIndex: 'batchQuantity'
    }
  ]

  componentDidMount () {
    this.getInsuranceGoodslist()
  }

  // 点击物品获取清单
  getInsuranceGoodslist = () => {
    const { rowInfo = {} } = this.props
    let params = {
      distributorId: rowInfo.distributorId,
      orderId: rowInfo.orderId,
      pickingId: rowInfo.pickingId,
      batchNum: rowInfo.batchNum,
      pageNo: 1,
      pageSize: 999
    }

    Api.getInsuranceGoodslist(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <div>
        <Modal title='货物明细' width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'scroll' }}
          visible
          onOk={this.props.onModalClose}
          onCancel={this.props.onModalClose}
        >
          <Table rowKey={(record, i) => `${record.commoditySkuId}-${i}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Modal>
      </div>
    )
  }
}
