import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input } from 'antd'

const FormItem = Form.Item

class LabelForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type, detailInfo = {} } = this.props
        if (+type === 2) {
          values.id = detailInfo.id
        }
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { type = 1, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Modal title={type === 1 ? '新增标签' : '编辑标签'}
          width={600}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Form>
              <FormItem label='标签名称'>
                {
                  getFieldDecorator('label', {
                    initialValue: detailInfo.label || '',
                    rules: [
                      { required: true, message: '请输入标签名称' }
                    ]
                  })(
                    <Input placeholder='请输入标签名称' autoComplete='off' />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const EditModal = Form.create()(LabelForm)

export default EditModal
