
let Socket = ''
let setIntervalWesocketPush = null
let connectCount = 0 // WS连接计数

/**
 * 建立websocket连接
 * @param {string} url ws地址
*/
const createSocket = (url) => {
  Socket && Socket.close()
  if (!Socket) {
    Socket = new WebSocket(url)
    connectCount = connectCount + 1
    Socket.onopen = () => {
      console.log('已建立websocket链接')
    }

    Socket.onerror = () => {
      console.log(`onerrorWS: 建立websocket连接失败，准备第${connectCount}次重连......`)
      reconnectWS(url)
    }

    Socket.onclose = () => {
      Socket = null
    }

    Socket.onmessage = (e) => {
      window.dispatchEvent(new CustomEvent('onmessageWS', {
        detail: {
          data: e.data
        }
      }))
    }
  }
}

/* 连接失败重连 */
const reconnectWS = (url) => {
  Socket.close()
  clearInterval(setIntervalWesocketPush)

  Socket = null

  if (+connectCount === 3) {
    return false
  }

  setTimeout(() => {
    createSocket(url)
  }, 5000)
}

/* 断开websocket连接 */
const oncloseWS = () => {
  if (Socket) {
    Socket.close()
    clearInterval(setIntervalWesocketPush)
    Socket = null
    connectCount = 0
  } else {
    clearInterval(setIntervalWesocketPush)
    Socket = null
    connectCount = 0
  }
}

export {
  Socket,
  createSocket,
  reconnectWS,
  oncloseWS
}
