import AREA from '@/assets/json/area'
import filter from './filter'
import CourierJson from '@/assets/json/courier.json'
import RegionJson from '@/assets/json/region.json'
import $lodash from 'lodash'

const { trimAll, trimLine, repLineBreak, authorMaps, brandTypeMaps, SMSTypeMaps, SMSSendTypeMaps, SMSExamineStatusMaps, cloudSupplyExamineMaps,
  orderTypeMaps, orderStatusMaps, orderDeliveryStatusMaps, skuGoodsTypeMaps, invoiceTypeMaps, invoiceOpenedStatusMaps,
  saledTypeMaps, saledStatusMaps, supplySaledStatusMaps, saledReasonTypeMaps, saledRefundTypeMaps, saledBackGoodsTypeMaps, cloudStoreLevelMaps,
  enquireOrderStateMaps, collaborateStateMaps, enquireOverdueStatusMaps, enquireMatchStatusMaps, answerEnquiredStatusMaps, workOrderStateMaps, workOrderTypeMaps } = filter

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 获取url上的参数
 */
export const getUrlParams = () => {
  const url = location.search // 获取url中"?"符后的字串
  let theRequest = {}
  if (url.indexOf('?') !== -1) {
    let str = url.substr(1)
    let strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

/**
 * 数组排序
 */
export const compare = (property) => {
  return function (a, b) {
    var value1 = a[property]
    var value2 = b[property]
    return value1 - value2
  }
}

let needLoadingRequestCount = 0

function startLoading () {
  let loading = document.getElementById('loadingWrapper')
  loading.style.display = 'block'
}

function endLoading () {
  let loading = document.getElementById('loadingWrapper')
  loading.style.display = 'none'
}

/**
 * 显示全屏loading
 */
export const showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

/**
 * 隐藏全屏loading
 */
export const tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

/**
 * 统一处理订货号 型号格式
 */
export const regFormat = (str) => {
  let num = ''
  num = repLineBreak(str, '<br>')
  num = trimAll(num)
  num = trimLine(num)

  return num
}

/**
 * 渲染客户权限
 */
export const renderCustomerAuthorLabel = (code) => {
  const obj = authorMaps.find((item) => { return item.id === code })
  const name = obj !== undefined ? obj.name : '----'

  return name
}

/**
 * 根据物流公司code 获取物流公司名称
*/
export const getCourierName = (code = '') => {
  const obj = CourierJson.find(item => { return item.courierCode === code })
  const name = obj !== undefined ? obj.courierName : '----'

  return name
}

/**
 * 省市区数据改为多维数组结构
 */
export const addressToTree = () => {
  const newProvinceArr = []

  $lodash.forEach(AREA.provinceList, (provinceValue, provinceKey) => {
    const obj = { code: provinceKey, name: provinceValue }
    const newCityArr = []

    $lodash.forEach(AREA.cityList, (cityValue, cityKey) => {
      if (provinceKey.substring(0, 2) === cityKey.substring(0, 2)) {
        const cityObj = { code: cityKey, name: cityValue }
        const newAreaArr = []

        $lodash.forEach(AREA.countyList, (areaValue, areaKey) => {
          if (cityKey.substring(0, 4) === areaKey.substring(0, 4)) {
            const areaObj = { code: areaKey, name: areaValue }

            newAreaArr.push(areaObj)
            cityObj.childList = newAreaArr
          }
        })

        newCityArr.push(cityObj)
        obj.childList = newCityArr
      }
    })

    newProvinceArr.push(obj)
  })

  return newProvinceArr
}

/**
 * 根据城市code码 获取城市名称
 */
export const getCityName = (provinceCode, cityCode, countyCode) => {
  return [AREA.provinceList[provinceCode] || '', AREA.cityList[cityCode] || '', AREA.countyList[countyCode] || '']
}

// 渲染地址中文
export const renderAddressLabel = (provinceCode, cityCode, countyCode, address = '') => {
  let str = '----'

  if (+provinceCode > 0) {
    const names = getCityName(provinceCode, cityCode, countyCode)

    str = address && address !== '' ? `${names[0]} ${names[1]} ${names[2]} ${address}` : `${names[0]} ${names[1]} ${names[2]}`
  }

  return str
}

// 渲染销售区域名称
export const renderRegionName = (code) => {
  const obj = RegionJson.find((item) => { return +item.code === +code })
  const label = obj !== undefined ? obj.name : '----'

  return label
}

// 渲染品牌类型
export const renderBrandTypeLabel = (status) => {
  const obj = brandTypeMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染云供应审核状态
export const renderCloudSupplyExamineStatusLabel = (status) => {
  const obj = cloudSupplyExamineMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单类型
export const renderOrderTypeLabel = (status) => {
  const obj = orderTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单状态
export const renderOrderStatusLabel = (status) => {
  const obj = orderStatusMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单发货状态
export const renderOrderDeliveryStatusLabel = (status) => {
  const obj = orderDeliveryStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染产品类型
export const renderSkuGoodsTypeLabel = (status, key = 'name') => {
  const obj = skuGoodsTypeMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj[key] : null

  return label
}

// 渲染短信发送模板类型
export const renderSMSTypeLabel = (status) => {
  const obj = SMSTypeMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染短信发送状态
export const renderSMSSendTypeLabel = (status) => {
  const obj = SMSSendTypeMaps.find(item => { return item.id === status })
  const el = obj !== undefined ? obj.name : null

  return el
}

// 渲染短信模板审核状态
export const renderSMSExamineStatusLabel = (status) => {
  const obj = SMSExamineStatusMaps.find(item => { return item.id === status })
  const el = obj !== undefined ? obj.name : null

  return el
}

/**
 * 渲染发票类型
 */
export const renderInvoiceTypeLabel = (status) => {
  const obj = invoiceTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染开票状态
 */
export const renderInvoiceOpenedStatusLabel = (status) => {
  const obj = invoiceOpenedStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后类型
 */
export const renderSaledTypeLabel = (status) => {
  const obj = saledTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后状态
 */
export const renderSaledStatusLabel = (status) => {
  const obj = saledStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染云供应售后状态
 */
export const renderSupplySaledStatusLabel = (status) => {
  const obj = supplySaledStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后原因
 */
export const renderSaledReasonTypeLabel = (status) => {
  const obj = saledReasonTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后退款方式
 */
export const renderSaledRefundTypeLabel = (status) => {
  const obj = saledRefundTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后退货方式
 */
export const renderSaledBackGoodsTypeLabel = (status) => {
  const obj = saledBackGoodsTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染云仓等级
 */
export const renderCloudStoreLevelLabel = (status) => {
  const obj = cloudStoreLevelMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染询价单类型
*/
export const renderEnquireOrderStatusLabel = (status) => {
  const obj = enquireOrderStateMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : ''

  return label
}

/*
 * 渲染询价单协作状态
*/
export const renderCollaborateStateLabel = (status) => {
  const obj = collaborateStateMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : ''

  return label
}

/**
 * 渲染询价单数据类型
*/
export const renderMatchingLabel = (status, dataType) => {
  const obj = enquireMatchStatusMaps.find((o) => { return o.id === status })
  const label = dataType === 'WRITE' ? '手写数据' : (obj !== undefined ? obj.name : null)

  return label
}

/**
 * 渲染工单是否逾期
 */
export const renderWorkOrderDueStatusLabel = (status) => {
  const obj = enquireOverdueStatusMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : ''

  return label
}

/**
 * 渲染报价类型
*/
export const renderAnswerStatusLabel = (status) => {
  const obj = answerEnquiredStatusMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染工单状态
 * @param {*} status
 * @returns name
 */
export const renderWorkStatusLabel = (status) => {
  const obj = workOrderStateMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : ''

  return label
}

/**
 * 渲染工单类型
 * @param {*} status
 * @returns name
 */
export const renderWorkTypeLabel = (status) => {
  const obj = workOrderTypeMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : ''

  return label
}

/**
 * 银行卡号每4位 空格分隔
*/
export const formatBankAccount = (value) => {
  return value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
}

// https或者http开头验证
export const domainname = /(http|https):\/\/([\w.]+\/?)\S*/

// 登录名长度为4-16位字符，只能包含数字,大小写字母,下划线、横杠
export const Loginname = /^[a-zA-Z0-9_]{4,16}$/

// 密码长度为6-12位字符，可包含数字,字母(区分大小写)
export const Loginpassword = /^[a-zA-Z0-9_]{6,12}$/

export const getYearOptions = (len) => {
  let options = []
  if (len > 0) {
    for (let i = 0; i < len; i++) {
      options.push({ id: `${2019 + i}`, name: `${2019 + i}年` })
    }
  }
  options.reverse()
  return options
}
