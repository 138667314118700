import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Alert, Input, Card, Select, Button } from 'antd'
import $lodash from 'lodash'

import { ALLwarging, getTitle } from './text'
import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const { SMSTypeMaps } = filter
const FormItem = Form.Item
const Option = Select.Option

class temPlateInfo extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object,
    getsmsAddTemplate: PropTypes.func
  }

  state={
    label: ''
  }
  componentDidMount () {
    this.handleOnchange(this.props.form.getFieldValue('type'))
  }

  // 点击保存
  handleSumit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      const { state } = this.props.history.location
      if (!err) {
        if (state.faly === 0) {
          this.getsmsAddTemplate(values)
        } else {
          values.id = state.id
          this.getsmsmodifyTemplate(values)
        }
      }
    })
  }

  // 新增
  getsmsAddTemplate = (values) => {
    Api.getsmsAddTemplate(values).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.goBack()
      }
    })
  }
  // 修改
  getsmsmodifyTemplate = (values) => {
    Api.getsmsmodifyTemplate(values).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.goBack()
      }
    })
  }

  // 提示信息
  handleInfo = () => {
    return (
      <div className={styles.template}>
        <div dangerouslySetInnerHTML={{ __html: ALLwarging.label }} />
      </div>
    )
  }
  handleInfofaly = () => {
    const { label } = this.state
    return (
      <div className={styles.template}>
        <div dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    )
  }

  handleOnchange = (status) => {
    const obj = $lodash.find(getTitle, item => { return item.type === status })

    this.setState({
      label: obj !== undefined ? obj.name : null
    }, () => { this.handleInfofaly() })
  }

  // 返回
  goBack = () => {
    this.props.history.goBack()
  }
  render () {
    const { getFieldDecorator } = this.props.form
    const { state } = this.props.history.location
    const { label } = this.state
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    return (
      <Card>
        <Row gutter={24}>
          <Col span={10}>
            <Form>
              <FormItem label='标题' {...formItemLayout}>
                {
                  getFieldDecorator('title', {
                    initialValue: state.title || '',
                    rules: [
                      { required: true, message: '请输入标题' }
                    ]
                  })(
                    <Input
                      autoComplete='off'
                    />
                  )
                }
              </FormItem>

              <FormItem label='模板名称' {...formItemLayout}
                extra='长度为1~30个字符。不能与现有列表中存在模板相同'
              >
                {
                  getFieldDecorator('name', {
                    initialValue: state.name || '',
                    rules: [
                      { required: true, message: '请输入模板名称' }
                    ]
                  })(
                    <Input
                      autoComplete='off'
                    />
                  )
                }
              </FormItem>

              <FormItem label='模板类型' {...formItemLayout}>
                {
                  getFieldDecorator('type', {
                    initialValue: state.type || '',
                    rules: [
                      { required: true, message: '请选择模板类型' }
                    ]
                  })(
                    <Select onChange={(value) => { this.handleOnchange(value) }}>
                      {
                        SMSTypeMaps.map((obj, i) => (
                          <Option key={i} value={obj.id}>{obj.name}</Option>
                        ))
                      }

                    </Select>
                  )
                }
              </FormItem>

              <FormItem label='模板内容' {...formItemLayout}
                extra='模板内容，长度为1~500个字符'
              >
                {
                  getFieldDecorator('content', {
                    initialValue: state.content || '',
                    rules: [
                      { required: true, message: '请输入模板内容' }
                    ]
                  })(
                    <Input.TextArea
                      autoComplete='off'
                      rows={8}
                    />
                  )
                }
              </FormItem>

              <FormItem label='申请说明' {...formItemLayout}
                extra='请在申请说明中描述您的业务使用场景，长度为1~100个字符'
              >
                {
                  getFieldDecorator('remark', {
                    initialValue: state.remark || '',
                    rules: [
                      { required: true, message: '请输入申请说明' }
                    ]
                  })(
                    <Input.TextArea
                      autoComplete='off'
                      rows={8}
                    />
                  )
                }
              </FormItem>
            </Form>
            <div style={{ width: '300PX', margin: '0 auto', marginTop: '50PX' }}>
              <Button onClick={() => { this.goBack() }}>取消</Button>
              <Button style={{ marginLeft: '20px' }} type='primary' onClick={() => { this.handleSumit() }}>保存</Button>
            </div>
          </Col>
          <Col span={8}>
            <Alert message={this.handleInfo()} type='success' />
            {
              label !== ''
                ? <Alert style={{ marginTop: '10px' }} message={this.handleInfofaly()} type='success' />
                : null
            }

          </Col>
        </Row>

      </Card>
    )
  }
}
const temPlateForm = Form.create()(temPlateInfo)
export default temPlateForm
