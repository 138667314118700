import React, { Component } from 'react'
import { Row, Card, Table, Button, Icon, message, Modal } from 'antd'
import moment from 'moment'

import EditModal from './edit-modal'
import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

class LabelLibrary extends Component {
  static propTypes = {}
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'label',
        formType: 'Input',
        itemParams: {
          label: '标签名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }
    ],
    query: {},
    tableData: [],
    rowDetail: {},
    type: 1,
    editModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'labelList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '标签',
      key: 'labelList-1',
      dataIndex: 'label'
    },
    {
      title: '操作时间',
      key: 'labelList-2',
      render: (text, record, index) => (
        <>
          { record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '操作人',
      key: 'labelList-3',
      dataIndex: 'modifyName'
    },
    {
      title: '操作',
      key: 'labelList-4',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.handleShowEditModal(record, 2)}>编辑</a>
          <a style={{ marginLeft: '6px' }} onClick={() => this.handleDeleteLabel(record)}>删除</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getLabelList()
  }

  // 获取标签列表
  getLabelList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getLabelList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存标签
  saveLabel = (params) => {
    Api.saveLabel(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getLabelList()
      }
    })
  }

  // 删除标签
  deleteLabel = (params) => {
    Api.deleteLabel(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getLabelList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getLabelList()
    })
  }

  // 点击新增、编辑标签
  handleShowEditModal = (record, type) => {
    this.setState({
      editModal: true,
      rowDetail: record,
      type: type
    })
  }

  // 点击删除标签
  handleDeleteLabel = (record) => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        this.deleteLabel({ id: record.id })
      }
    })
  }

  closeModal = () => {
    this.setState({
      editModal: false,
      rowDetail: {},
      type: 1
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getLabelList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getLabelList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], type = 1, editModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Button type='primary' onClick={() => this.handleShowEditModal({}, 1)}>
                <Icon type='plus' />新增
              </Button>
            </Row>
            <Row>
              <Table rowKey='id' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          editModal
            ? <EditModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.saveLabel(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default LabelLibrary
