import axios from 'axios'
import { message } from 'antd'
import config from '@/common/utils/config'
import configureStore from './configureStore'
import { removeStore, showFullScreenLoading, tryHideFullScreenLoading } from './mUtils'

const { store } = configureStore()
const { dashboardhost } = config
let instance = axios.create({
  baseURL: dashboardhost,
  timeout: 60000 // 请求60s超时
})

// request 拦截器
instance.interceptors.request.use(config => {
  // config.headers.Token = getStore('token') || ''
  config.headers['Content-Type'] = 'application/json;charset=UTF-8'

  if (!config.headers.HideLoading) {
    showFullScreenLoading()
  }

  return config
}, error => {
  tryHideFullScreenLoading()
  return Promise.reject(error)
})

// http response响应器
instance.interceptors.response.use(response => {
  tryHideFullScreenLoading()

  return Promise.resolve(response.data)
}, error => {
  message.destroy()
  tryHideFullScreenLoading()
  const { status, data } = error.response

  switch (status) {
    case 400:
      message.warning(data.message)
      break
    case 401:
      removeStore('token')
      store.dispatch({ type: 'SET_USER_INFO', data: { userInfo: {} } })
      window.location.href = '/login'
      break
    case 500:
      message.error('服务器开小差了，请稍后再试！')
      break
    default:
      message.error('服务器开小差了，请稍后再试！')
  }

  return Promise.reject(error.response.status)
})

export default instance
