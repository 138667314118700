import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Button, Icon, Table } from 'antd'
import $lodash from 'lodash'

import { downloadExcel } from '@/common/utils/downloadFile'
import filter from '@/common/utils/filter'

const { fullsMonthOptions } = filter

export default class MonthTable extends Component {
  static propTypes = {
    distributorName: PropTypes.string,
    tableData: PropTypes.array
  }
  state = {}
  columns = [
    {
      title: '月度',
      key: 'mcOrdersNumberCol-0',
      render: (text, record, index) => (
        <div>{ this.renderMonthLabel(record.month) }</div>
      )
    },
    {
      title: '商户名称',
      key: 'mcOrdersNumberCol-1',
      render: (text, record, index) => {
        const { distributorName = '' } = this.props

        return <div>{distributorName}</div>
      }
    },
    {
      title: '自营订单数量',
      key: 'mcOrdersNumberCol-2',
      render: (text, record, index) => (
        <div>
          { record.selfOrder ? record.selfOrder : 0 }
        </div>
      )
    },
    {
      title: '云供应订单数量',
      key: 'mcOrdersNumberCol-3',
      render: (text, record, index) => (
        <div>{ record.cloudSupplyOrder ? +record.cloudSupplyOrder : 0 }</div>
      )
    },
    {
      title: '云采购订单数量',
      key: 'mcOrdersNumberCol-4',
      render: (text, record, index) => (
        <div>{ record.cloudSpotOrder ? +record.cloudSpotOrder : 0 }</div>
      )
    },
    {
      title: 'IIASaaS快递数量',
      key: 'mcOrdersNumberCol-5',
      render: (text, record, index) => (
        <div>{ record.logisticsOrder ? +record.logisticsOrder : 0 }</div>
      )
    }
  ]

  // 点击下载
  handleDownload = () => {
    const { tableData = [], distributorName } = this.props
    let arr = [
      ['月度', '商户名称', '自营订单数量', '云供应订单数量', '云采购订单数量', 'IIASaaS快递数量']
    ]

    tableData.forEach((record, i) => {
      let rowArr = []
      let label = this.renderMonthLabel(record.month)

      rowArr.push(label, distributorName, record.selfOrder ? +record.selfOrder : 0,
        record.cloudSupplyOrder ? +record.cloudSupplyOrder : 0, record.cloudSpotOrder ? +record.cloudSpotOrder : 0, record.logisticsOrder ? +record.logisticsOrder : 0)
      arr.push(rowArr)
    })

    downloadExcel(arr, '商户订单数量查询表.xlsx')
  }

  // 渲染月份
  renderMonthLabel = (month) => {
    let label = ''
    let i = $lodash.findIndex(fullsMonthOptions, (item) => { return +month === +item.month })

    if (i > -1) {
      label = fullsMonthOptions[i].monthLabel
    }

    return label
  }

  render () {
    const { tableData = [] } = this.props

    return (
      <Row style={{ marginTop: '20px' }}>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Button type='primary' disabled={tableData.length === 0} onClick={() => this.handleDownload()}>
              <Icon type='download' />导出数据
            </Button>
          </Row>
          <Table rowKey={(record, index) => `mcOrdersNumberCol-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Card>
      </Row>
    )
  }
}
