import Ajax from '../utils/ajax'

export default {

  // 成员管理

  // 成员管理- 获取全部成员
  getMemberList (params) {
    return Ajax.post('/platformUser/userList', params)
  },

  // 成员管理- 新增成员
  saveAddMember (params) {
    return Ajax.post('/platformUser/userAdd', params)
  },

  // 成员管理- 编辑成员
  saveEditMember (params) {
    return Ajax.post('/platformUser/userEdit', params)
  },

  // 成员管理- 成员启用禁用
  updateMemberStatus (params) {
    return Ajax.post('/platformUser/userStatusChange', params)
  },

  // 成员管理- 重置密码
  resetPlatformUserLoginPassword (params) {
    return Ajax.post('/platformUser/resetPassword', params)
  },

  // 部门管理

  // 部门管理- 获取部门列表
  getDepartmentList (params) {
    return Ajax.post('/platformDepartment/list', params)
  },

  // 部门管理- 新增部门
  saveAddDepartment (params) {
    return Ajax.post('/platformDepartment/add', params)
  },

  // 部门管理- 编辑部门
  saveEditDepartment (params) {
    return Ajax.post('/platformDepartment/edit', params)
  },

  // 部门管理- 删除部门
  deleteDepartment (params) {
    return Ajax.post('/platformDepartment/delete', params)
  },

  // 部门管理- 部门启用禁用
  updateDepartStatus (params) {
    return Ajax.post('/platformDepartment/disableChange', params)
  },

  // 角色管理

  // 角色管理- 获取角色列表
  getDepartRoleList (params) {
    return Ajax.post('/platformRole/roleList', params)
  },

  // 角色管理- 获取角色详情
  getDepartRoleDetail (params) {
    return Ajax.post('/platformRole/roleDetail', params)
  },

  // 角色管理- 新增角色
  saveAddDepartRole (params) {
    return Ajax.post('/platformRole/roleAdd', params)
  },

  // 角色管理- 角色编辑
  saveEditDepartRole (params) {
    return Ajax.post('/platformRole/roleEdit', params)
  },

  // 角色管理- 删除角色
  deleteDepartRole (params) {
    return Ajax.post('/platformRole/roleDelete', params)
  },

  // 角色管理- 角色启用禁用
  updateDepartRoleStatus (params) {
    return Ajax.post('/platformRole/roleShowChange', params)
  },

  // 获取运营成员
  getAllBusinessPersonnel (params) {
    return Ajax.get('/platform/merchant/getAllOperationsManager', { params })
  }
}
