import React, { Component } from 'react'
import { Card, Table, Button } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

/* 出库单 */
class WarehouseOutBill extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '出库时间'
        },
        cptParams: {

        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '采购单位'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'entryBillCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单编号',
      key: 'entryBillCol-1',
      dataIndex: 'orderId'
    },
    {
      title: '出库单号',
      key: 'entryBillCol-2',
      dataIndex: 'saleOrderId'
    },
    {
      title: '出库时间',
      key: 'entryBillCol-3',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '采购单位',
      key: 'entryBillCol-4',
      dataIndex: 'distributorName',
      ellipsis: true
    },
    {
      title: '操作',
      key: 'entryBillCol-5',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleDownloadBillDetails(record)}>下载</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getWarehouseOutBillList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        const { searchData } = this.state

        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取出库单列表
  getWarehouseOutBillList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getWarehouseOutBillList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times && search.times.length === 2) {
      search.beginDate = search.times[0].format('YYYY-MM-DD')
      search.endDate = search.times[1].format('YYYY-MM-DD')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getWarehouseOutBillList()
    })
  }

  // 点击下载
  handleDownloadBillDetails = (record = {}) => {
    DownloadApi.downloadWarehouseOutBillDetails({ saleOrderId: record.saleOrderId, pickingId: record.pickingId })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getWarehouseOutBillList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getWarehouseOutBillList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default WarehouseOutBill
