/* 系统配置模块 */
import MenuSetting from '@/pages/system/menu-setting/index' // 菜单配置
import SystemTools from '@/pages/system/system-tool/index' // 系统工具

export default [
  {
    path: '/admin/menuSetting',
    name: '角色管理',
    component: MenuSetting
  },
  {
    path: '/admin/systemTools',
    name: '系统工具',
    component: SystemTools
  }
]
