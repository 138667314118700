import Ajax from '../utils/ajax'

export default {

  // 菜单管理
  // 获取系统菜单
  getAllMenuList (params) {
    return Ajax.post('/platformMenu/menuList', params)
  },

  // 新增系统菜单
  saveAddMenu (params) {
    return Ajax.post('/platformMenu/menuAdd', params)
  },

  // 保存编辑系统菜单
  saveEditMenu (params) {
    return Ajax.post('/platformMenu/menuEdit', params)
  },

  // 菜单删除
  deleteMenu (params) {
    return Ajax.post('/platformMenu/menuRemove', params)
  },

  // 获取物流公司列表
  getCourierInfo (params) {
    return Ajax.get('/cloud/purchaseOrder/courierInfo', { params })
  },

  // 获取数据统计
  getPlatformStatisticTotal (params) {
    return Ajax.get('/cloud/biData/selectInfo', { params })
  },

  // 获取财务分析页面Url
  getFinanceAnalysisViewUrl (params) {
    return Ajax.get('/platform/finance/cloudDataReportUrl', { params })
  },

  // 收件地址管理
  // 获取收件地址列表
  getReceivingAddressList (params) {
    return Ajax.post('/platform/bills/address/listPage', params)
  },

  // 保存新增收件地址
  saveAddReceivingAddress (params) {
    return Ajax.post('/platform/bills/address/add', params)
  },

  // 编辑收件地址
  saveEditReceivingAddress (params) {
    return Ajax.post('/platform/bills/address/modify', params)
  },

  // 删除收件地址
  deleteReceivingAddress (params) {
    return Ajax.post('/platform/bills/address/remove', params)
  },

  // 修改客户订单收货地址
  saveModifyReceivingAddressByOrderId (params) {
    return Ajax.post('/cloud/supplyOrder/modifyOrderGoodsAddress', params)
  },

  /* 地址管理 */
  // 获取地址列表(不分页)
  getAddressList (params) {
    return Ajax.post('/address/info/manage/center/list', params)
  },

  // 获取地址列表(分页)
  getAddressListPage (params) {
    return Ajax.post('/address/info/manage/center/listPage', params)
  },

  // 保存新增地址
  saveAddAddress (params) {
    return Ajax.post('/address/info/manage/center/add', params)
  },

  // 保存修改地址
  saveEditAddress (params) {
    return Ajax.post('/address/info/manage/center/update', params)
  },

  // 删除地址
  saveDeleteAddress (params) {
    return Ajax.get('/address/info/manage/center/delete', { params })
  },

  // 获取物流轨迹
  getLogisticsTraceByMailNo (params) {
    return Ajax.get('/cloud/purchaseOrder/getLogisticsDetail', { params })
  },

  // 获取商户收票地址列表
  getMerchantReceivingInvoiceAddressList (params) {
    return Ajax.post('/dis/invoice/list/invoice', params)
  },

  // 新增商户收票地址
  saveMerchantReceivingInvoiceAddress (params) {
    return Ajax.post('/dis/invoice/add/invoice', params)
  },

  // 登录白名单- 获取白名单列表
  getLoginWhiteList (params) {
    return Ajax.post('/oms/ip/white/manage/queryPage', params)
  },

  // 登录白名单- 获取当前IP
  getCurrentNetworkAddress (params) {
    return Ajax.get('/oms/ip/white/manage/get/ip', { params })
  },

  // 登录白名单- 单个添加IP
  saveAddLoginIpWhiteItem (params) {
    return Ajax.post('/oms/ip/white/manage/add', params)
  },

  // 登录白名单- 单个删除IP
  deleteLoginIpWhiteItem (params) {
    return Ajax.get('/oms/ip/white/manage/delete', { params })
  }
}
