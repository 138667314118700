import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber } from 'antd'

const FormItem = Form.Item

class MoenyForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    rowInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    oncloseModal: PropTypes.func
  }
  state = {

  }

  // 点击确认
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { rowInfo = {} } = this.props

        values['distributorId'] = rowInfo.distributorId
        values['dueMonth'] = rowInfo.dueMonth

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 10 }
    }

    return (
      <>
        <Modal title='确认收款'
          visible
          maskClosable={false}
          onOk={() => { this.handleConfirm() }}
          onCancel={this.props.oncloseModal}
        >
          <Form>
            <FormItem label='此次确认收款金额' {...formItemLayout}>
              {
                getFieldDecorator('payAmount', {
                  rules: [
                    { required: true, message: '请填写收款金额' }
                  ]
                })(
                  <InputNumber style={{ width: '100%' }} min={0} />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const MoenyModal = Form.create()(MoenyForm)

export default MoenyModal
