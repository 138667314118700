import Ajax from '../utils/ajax'

export default {
  // 获取客户列表
  getCustomerList (params) {
    return Ajax.post('/platformMerchant/list', params)
  },

  // 搜索-联想搜索商家
  getMerchantListByKeyword (params) {
    return Ajax.post('/cloudCommoditySku/selectDistributorByName', params, { headers: { HideLoading: true } })
  },

  // 搜索-按类型获取商户列表
  getMerchantListBySelectType (params) {
    return Ajax.post('/platformMerchant/selectByType', params)
  },

  // 保存客户
  saveCustomer (params) {
    return Ajax.post('/platformMerchant/save', params)
  },

  // 供应商列表
  supplierList (params) {
    return Ajax.post('/platform/merchant/listPageForSupplier', params)
  },

  // 供应商列表启用禁用
  supplierStatus (params) {
    return Ajax.post('/platform/merchant/changeUserNameStatus', params)
  },

  // 供应商-登录名重复校验
  supplierSaveUsernameValidate (params) {
    return Ajax.post('/platform/merchant/supplierSaveUsernameValidate', params)
  },

  // 供应商-获取供应平台域名
  getSupplierDomain (params) {
    return Ajax.get('/platform/merchant/getSupplierDomainName', { params })
  },

  // 供应商-保存新增、编辑供应商信息
  saveSupplierInformation (params) {
    return Ajax.post('/platform/merchant/supplierSave', params)
  },

  // 客户状态启用禁用
  supplierchangeStatus (params) {
    return Ajax.post('/platformMerchant/changeStatus', params)
  },

  // 重置密码
  resetPassword (params) {
    return Ajax.post('/platformMerchant/resetPasswords', params)
  },

  // 获取EU客户列表
  getEuCustomerList (params) {
    return Ajax.post('/platform/endUser/endUserList', params)
  },

  /* 获取采购商列表 START */
  getPurchaserList (params) {
    return Ajax.post('/platform/merchant/listPageForPurchasers', params)
  },

  // 采购商-保存新增、编辑采购商信息
  savePurchaserInformation (params) {
    return Ajax.post('/platform/merchant/purchaseSave', params)
  },

  // 获取采购平台域名
  getPurchaserDomain (params) {
    return Ajax.get('/platform/merchant/getPurchaseDomainName', params)
  },

  // 采购商-保存启用禁用
  savePurchaserEnableStatus (params) {
    return Ajax.post('/platform/merchant/changePurchaseUserNameStatus', params)
  },

  // 采购商-登录名重复校验
  purchaseSaveUsernameValidate (params) {
    return Ajax.post('/platform/merchant/purchaseSaveUsernameValidate', params)
  },

  /* 采购商 END */

  /* 工聚宝客户管理START */
  // 工聚宝客户- 获取工聚宝客户列表
  getToolBoxCustomerList (params) {
    return Ajax.post('/platform/merchant/listPageForSupplyPurchase', params)
  },

  // 工聚宝客户- 保存编辑工聚宝客户信息
  saveToolBoxCustomerInfomation (params) {
    return Ajax.post('/platform/merchant/supplyPurchaseSave', params)
  },

  // 工聚宝客户- 保存启用禁用
  saveToolBoxCustomerEnableStatus (params) {
    return Ajax.post('/platform/merchant/changeSupplyPurchaseUserNameStatus', params)
  },

  // 工聚宝客户- 获取域名
  getToolBoxCustomerDomain (params) {
    return Ajax.get('/platform/merchant/getSupplyPurchaseDomainName', params)
  },

  // 工聚宝客户- 登录名重复校验
  toolBoxCustomerSaveUsernameValidate (params) {
    return Ajax.post('/platform/merchant/supplyPurchaseSaveUsernameValidate', params)
  },

  /* 工聚宝客户管理END */

  /* 商户付款配置 */
  // 查询前置机列表
  getBankLinkageProcessorList (params) {
    return Ajax.post('/platform/bankLinkageProcessorList', params)
  },

  // 商户记账宝账号列表
  getBankLinkageSettings (params) {
    return Ajax.post('/platformMerchant/bankLinkageSettings', params)
  },

  // 保存记账宝账号
  saveSetbanklink (params) {
    return Ajax.post('/platformMerchant/bankLinkageSave', params)
  },

  // 商户支付宝配置信息
  getMerchantAlipaySet (params) {
    return Ajax.post('/platform/merchant/alipay/info', params)
  },

  // 保存支付宝配置信息
  saveMerchantAlipaySet (params) {
    return Ajax.post('/platform/merchant/alipay/save', params)
  },

  /* IIASaaS服务配置 */
  // IIASaaS服务-商户云供应设置列表
  getMerchantCloudSetList (params) {
    return Ajax.post('/api/dis/cloudSupplySettings/listPage', params)
  },

  // IIASaaS服务-更新商户云供应设置
  updateMerchantCloudSetStatus (params) {
    return Ajax.post('/api/dis/cloudSupplySettings/audit', params)
  },

  // 商户服务配置记录
  getCustomerServiceRecordList (params) {
    return Ajax.post('/platform/logisticsSaaS/configLogPage', params)
  },

  /* 快递服务 */
  // 获取商户物流配置
  getCustomerServiceList (params) {
    return Ajax.post('/oms/logisticsDiscountConfig/listPageForLogisticsDiscount', params)
  },

  // 快递服务-启用/禁用状态
  updateServiceStatus (params) {
    return Ajax.post('/oms/logisticsDiscountConfig/statusOperation', params)
  },

  // 快递服务-保存物流费率
  saveSeriveLogistics (params) {
    return Ajax.post('/oms/logisticsDiscountConfig/modifyLogisticsDiscount', params)
  },

  // 快递服务-保存快递费率
  saveSeriveRate (params) {
    return Ajax.post('/oms/logisticsDiscountConfig/modifyCourierDiscount', params)
  },

  /* 保险服务 */
  // 平安保险-查询列表
  getCustomerListInsurance (params) {
    return Ajax.post('/insurance/listQuery', params)
  },

  // 平安保险-启用禁用
  getCustomerInsuranceStatus (params) {
    return Ajax.post('/insurance/updatePinganInsureEnableStatus', params)
  },

  // 平安保险-更改保险服务类型
  updateCustomerInsuranceType (params) {
    return Ajax.post('/insurance/updatePinganInsureTypeStatus', params)
  },

  // 平安保险-保存服务设置
  updateCustomerInsuranceInfo (params) {
    return Ajax.post('/insurance/updatePinganInsureRateStatus', params)
  },

  /* 商户其他 */
  // 设置商户标签
  setDistributorLabel (params) {
    return Ajax.post('/platform/merchant/markedLabelForDistributor', params)
  },

  // 获取客户经营品牌列表
  getCustomerPutWayBrandList (params) {
    return Ajax.post('/platform/merchant/brandInfoForPutWay', params)
  },

  // 商户管理-推广品牌启用/禁用
  saveCustomerPutWayBrandStatus (params) {
    return Ajax.post('/platform/merchant/purchaseBrandOperate', params)
  },

  // 获取客户经营品牌产品列表
  getCustomerPutWayProductInBrand (params) {
    return Ajax.post('/platform/merchant/skuInfoForPutWay', params)
  },

  // 商户管理-推广的产品启用/禁用
  saveCustomerPutWayProductStatus (params) {
    return Ajax.post('/platform/merchant/purchaseSkuOperate', params)
  },

  // 商户管理-保存ERP账套设置
  saveErpConfigSetting (params) {
    return Ajax.post('/platform/merchant/erpConfigSetting', params)
  },

  // 商户管理- ERP账套启用禁用
  updateErpConfigStatus (params) {
    return Ajax.post('/platform/merchant/erpConfigAudit', params)
  },

  // 客户管理-查询初始化
  selCusinit (params) {
    return Ajax.post('/platform/merchant/dataInitDetail', params)
  },

  // 客户管理》客户列表》添加客户》模糊查询列表
  getCompanyInfoByKeywords (params) {
    return Ajax.post('/platform/merchant/getCompanyInfoByKeywords', params)
  },

  // 客户管理》客户列表》添加客户》商户精确查询信息
  getCompanyInfoByName (params) {
    return Ajax.post('/platform/merchant/getCompanyInfoByName', params)
  },

  // 客户管理》客户列表》分配运营》保存运营分配
  saveBindBusinessPersonnel (params) {
    return Ajax.post('/platform/merchant/allotOperationsManager', params)
  },

  // 客户管理》客户列表》分配客户归属》保存客户采购归属分配
  saveBindBelongPersonnel (params) {
    return Ajax.post('/platform/merchant/belongToPlatformUserEdit', params)
  },

  // 客户管理》客户列表》分配客户归属》保存客户供应归属分配
  saveBindSupplyBelongPersonnel (params) {
    return Ajax.post('/platform/merchant/belongBySupplyToPlatformUserEdit', params)
  }
}
