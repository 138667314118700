import React, { Component } from 'react'

import WecomeText from '@/assets/json/welcome.json'
import welcome from '@/assets/images/welcome.png'

import styles from './home.module.scss'

export default class Home extends Component {
  static propTypes = {}
  state = {

  }

  componentDidMount () {

  }

  render () {
    let n = WecomeText.length
    let i = parseInt(Math.random() * n)
    return (
      <div className={`${styles.homeWrapper}`}>
        <div className={`${styles.containerWrap}`}>
          <div className={`${styles.welcomeWrap}`}>
            <h3>IIASaaS&nbsp;&nbsp;运营管理平台</h3>
            <p>{WecomeText[i].text}</p>
          </div>
          <img className={`${styles.pic}`} src={welcome} />
        </div>
      </div>
    )
  }
}
