/* ERP管理 */
import CustomerRelations from '@/pages/erpManage/customerRelations' // 客户关系映射
import BrandRelations from '@/pages/erpManage/brandRelations/index' // 品牌关系映射

export default [
  {
    path: '/admin/customerRelations',
    name: '客户关系映射',
    component: CustomerRelations
  },
  {
    path: '/admin/brandRelations',
    name: '品牌关系映射',
    component: BrandRelations
  }
]
