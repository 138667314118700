import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Table, Button, Icon } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form'

import Api from '@/common/api'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { downUnSyncSkusInOutside } = DownloadApi
const { pageSizeOptions } = filter

class OutsideBatch extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '对接时间'
        },
        cptParams: {}
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '对接来源'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      width: 44,
      align: 'center',
      key: 'outSideBatch-0',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '批次号',
      key: 'outSideBatch-1',
      dataIndex: 'batchNo'
    },

    {
      title: '对接时间',
      key: 'outSideBatch-2',
      render: (text, record, index) => (
        <>{ record.joinTime ? moment(record.joinTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },

    {
      title: '对接来源',
      key: 'outSideBatch-3',
      dataIndex: 'dataFrom'
    },
    {
      title: '产品总数',
      key: 'outSideBatch-4',
      dataIndex: 'skuTotal',
      render: (text, record, index) => (
        <div>
          {
            !record.selectNo
              ? <a href='##' onClick={() => this.handleLookSkuTotal(record, index)}>点击查看</a>
              : <span>{ +record.selectNo }</span>
          }

        </div>
      )
    },
    {
      title: '操作',
      key: 'outSideBatch-5',
      render: (text, record, index) => {
        let { buttonMaps = {} } = this.props

        return (
          <Row>
            {
              buttonMaps['download'] && +buttonMaps['download'].auth === 1
                ? <Button type='primary' size='small' onClick={() => this.handleDownload(record)}>
                  <Icon type='download' />导出
                </Button>
                : null
            }

          </Row>
        )
      }
    }
  ]

  componentDidMount () {
    this.getListPageForUnSyncDataInOutside()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[1].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取未导入批次列表
  getListPageForUnSyncDataInOutside = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getListPageForUnSyncDataInOutside(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击查看数量
  handleLookSkuTotal = (record, i) => {
    let { tableData = [] } = this.state
    let param = {
      batchNo: record.batchNo
    }
    Api.handleLookSkuTotal(param).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        tableData[i]['selectNo'] = data

        this.setState({
          tableData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search['beginDate'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.times[1]).format('YYYY-MM-DD')
      delete search.times
    }
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      }
    }, () => {
      this.getListPageForUnSyncDataInOutside()
    })
  }

  // 点击导出
  handleDownload = (record) => {
    downUnSyncSkusInOutside({ batchNo: record.batchNo })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getListPageForUnSyncDataInOutside()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getListPageForUnSyncDataInOutside()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <Table rowKey='batchNo' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(OutsideBatch)
