import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Table, Descriptions, Button, Tag } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderWorkOrderDueStatusLabel, renderWorkStatusLabel, renderWorkTypeLabel } from '@/common/utils/mUtils'

const { pageSizeOptions } = filter

class WorkOrderContents extends Component {
  static propTypes = {
    history: PropTypes.object,
    detailInfo: PropTypes.object
  }
  state = {
    pages: { pageNo: 1, pageSize: 20, total: 0 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'workDrawerContentsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '协作工单编号',
      key: 'workDrawerContentsCol-1',
      dataIndex: 'workOrderId'
    },
    {
      title: '派发日期',
      key: 'workDrawerContentsCol-2',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '发布人',
      key: 'workDrawerContentsCol-3',
      dataIndex: 'fromPlatformUserName'
    },
    {
      title: '处理时间',
      key: 'workDrawerContentsCol-4',
      dataIndex: 'handlingTime',
      render: (text, record, index) => (
        <>{ record.handlingTime ? moment(record.handlingTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '处理人',
      key: 'workDrawerContentsCol-5',
      dataIndex: 'toPlatformUserName'
    },
    {
      title: '是否逾期',
      key: 'workDrawerContentsCol-6',
      dataIndex: 'dueStatus',
      render: (text, record, index) => (
        <>{ renderWorkOrderDueStatusLabel(record.dueStatus) }</>
      )
    },
    {
      title: '工单状态',
      key: 'workDrawerContentsCol-7',
      dataIndex: 'workOrderState',
      render: (text, record, index) => (
        <>{ renderWorkStatusLabel(record.workOrderState) }</>
      )
    },
    {
      title: '工单类型',
      key: 'workDrawerContentsCol-8',
      dataIndex: 'workOrderType',
      render: (text, record, index) => (
        <>{ renderWorkTypeLabel(record.workOrderType) }</>
      )
    },
    {
      title: '操作',
      key: 'workDrawerContentsCol-9',
      render: (text, record, index) => (
        <>
          {
            record.operateAuthority
              ? <Button type='link' size='small' onClick={() => this.handleLinkToWorkHandle(record)}>处理工单</Button>
              : <Button type='link' size='small' onClick={() => this.handleLinkToWorkDetails(record)}>查看详情</Button>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getWorkOrdersList()
  }

  // 获取工单列表
  getWorkOrdersList = async () => {
    const { detailInfo = {} } = this.props
    const { pages } = this.state

    const res = await Api.getWorkOrdersList({ orderId: detailInfo.orderId, queryType: 'ALL', autoQuotationFlag: 0, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击跳转处理工单
  handleLinkToWorkHandle = (record) => {
    this.props.history.push({ pathname: '/admin/workHandle', state: { id: record.id, workOrderType: record.workOrderType } })
  }

  // 点击跳转查看详情
  handleLinkToWorkDetails = (record) => {
    this.props.history.push({ pathname: '/admin/workDetails', state: { id: record.id, workOrderType: record.workOrderType } })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getWorkOrdersList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getWorkOrdersList()
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <Descriptions>
            <Descriptions.Item label='询价单号'>{ detailInfo.enquiryOrderId }</Descriptions.Item>
            <Descriptions.Item label='询价商户'>{ detailInfo.distributorName }</Descriptions.Item>
            <Descriptions.Item label='负责人'>{ detailInfo.platformUserName }</Descriptions.Item>
          </Descriptions>
        </div>

        <Row style={{ marginBottom: '12px' }}>
          <Col span={8} offset={16} style={{ textAlign: 'right' }}>
            <Tag color='#FFA39E'><span style={{ visibility: 'hidden' }}>未处理</span></Tag><span>未处理</span>
          </Col>
        </Row>

        <Table rowKey='id' bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
          rowClassName={(record, index) => { return record.workOrderState === 'UN_PROCESS' ? 'backgroundError' : null }} // 工单状态为未处理加上背景颜色
        />
      </>
    )
  }
}

export default WorkOrderContents
