import React, { Component } from 'react'
import { message, Modal, Empty, Timeline } from 'antd'
import PropTypes from 'prop-types'
import Api from '@/common/api/index'
export default class failDetailsModal extends Component {
  static propTypes={
    oncloseModal: PropTypes.func,
    rowDetail: PropTypes.object
  }
  state={
    DataList: []
  }
  componentDidMount () {
    this.getOneFailTaskList()
  }

  // 获取失败详情信息
  getOneFailTaskList=() => {
    const { id } = this.props.rowDetail
    let param = {
      pageNo: 1,
      pageSize: 999,
      taskId: id,
      result: 'FAIL'
    }

    Api.getOneFailTaskList(param).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          DataList: data.list || []
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  render () {
    const { DataList = [] } = this.state
    return (
      <div >
        <Modal width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'scroll' }}
          title='失败详情'
          visible
          mask
          onOk={this.props.oncloseModal}
          onCancel={this.props.oncloseModal}
        >
          {
            DataList.length > 0 && DataList
              ? <Timeline>
                {
                  DataList.map((item, i) => (
                    <Timeline.Item key={i}>
                      {
                        <div>
                          <span style={{ marginLeft: '20px' }}>{`第${item.rowNum}行导入失败` }  </span>
                          <span style={{ marginLeft: '20px' }}>{`失败原因为：${item.failReason}` }  </span>
                        </div>
                      }
                    </Timeline.Item>
                  ))
                }
              </Timeline>
              : <Empty />
          }
        </Modal>
      </div>
    )
  }
}
