/* 报价单详情页面 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Icon } from 'antd'

import EnquirePreviewTable from '../components/enquirePreviewTable/index'
import QuotationPreviewTable from '../components/quotationPreviewTable/index'
import Remarks from '../components/remarks/index'

import Api from '@/common/api/index'

export default class QuotationDetails extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    quotationOrderId: null,
    distributorId: null,
    distributorName: null,
    info: {},
    enquiryOrderDetails: [],
    quotationOrderDetails: []
  }

  componentDidMount () {
    const { quotationOrderId, distributorId, distributorName } = this.props.history.location.state

    this.setState({
      quotationOrderId: quotationOrderId,
      distributorId: distributorId,
      distributorName: distributorName
    }, () => {
      this.getQuotationOrderDetails()
    })
  }

  // 获取报价单详情
  getQuotationOrderDetails = async () => {
    const { quotationOrderId } = this.state
    const res = await Api.getQuotationOrderDetails({ quotationOrderId: quotationOrderId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data || {},
        enquiryOrderDetails: data.enquiryOrderDetails || [],
        quotationOrderDetails: data.quotationOrderDetails || []
      })
    }
  }

  // 点击预览订单
  handleLinkToOrder = () => {
    const { info = {}, quotationOrderId, distributorId, distributorName } = this.state
    this.props.history.push({ pathname: '/admin/orderSubmit',
      state: {
        orderId: info.orderId,
        quotationOrderId: quotationOrderId,
        distributorId: distributorId,
        distributorName: distributorName
      }
    })
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { quotationOrderId = null, info = {}, enquiryOrderDetails = [], quotationOrderDetails = [] } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}><Icon type='left' />返回列表</Button>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '15px' }}>
              <EnquirePreviewTable
                list={enquiryOrderDetails}
              />
            </div>
            <Remarks title='商户备注' remarks={info.enquiryRemark && info.enquiryRemark !== '' ? [{ remarks: info.enquiryRemark }] : []} />
          </div>

          <>
            <div style={{ marginBottom: '15px' }}>
              <QuotationPreviewTable
                title={<>报价单号：<>{ quotationOrderId }</></>}
                descriptions={info.matchResult || ''}
                list={quotationOrderDetails}
              />
            </div>
            <Remarks title='客服备注' remarks={info.quotationRemark && info.quotationRemark !== '' ? [{ remarks: info.quotationRemark }] : []} />
          </>

          <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <Button type='primary' size='large' onClick={() => this.handleLinkToOrder()}>预览订单</Button>
          </div>
        </Card>

      </>
    )
  }
}
