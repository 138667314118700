import React, { Component } from 'react'
import { Card, Row, Col, Statistic } from 'antd'

import Api from '@/common/api/index'

class StatisticPage extends Component {
  static propTypes = {}
  state = {
    info: {},
    year: 0
  }

  componentDidMount () {
    const time = new Date()
    this.setState({
      year: time.getFullYear()
    })
    this.getPlatformStatisticTotal()
  }

  getPlatformStatisticTotal = () => {
    Api.getPlatformStatisticTotal({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  // 计算今日客单价
  renderCusPriceStatistic = () => {
    const { info = {} } = this.state
    let num = (((info.cloudOrderAmountForToday + info.euTransactionOrderAmountForToday) * 100) / ((info.euTransactionOrderCountForToday + info.cloudOrderCountForToday) * 100)).toFixed(2)

    return num
  }

  render () {
    const { info = {}, year } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card title='交易概览'>
            <Row gutter={12}>
              <Col span={4}>
                <Statistic title='总销售额'
                  value={(info.cloudOrderAmount + info.euTransactionOrderAmount).toFixed(2)}
                />
              </Col>

              <Col span={4}>
                <Statistic title='今日销售额'
                  value={(info.cloudOrderAmountForToday + info.euTransactionOrderAmountForToday).toFixed(2)}
                />
              </Col>

              <Col span={4}>
                <Statistic title='今日云销售额' value={+info.cloudOrderAmountForToday} />
              </Col>

              <Col span={4}>
                <Statistic title='云总销售额' value={+info.cloudOrderAmount} />
              </Col>

              <Col span={4}>
                <Statistic title='今日商户销售额' value={+info.euTransactionOrderAmountForToday} />
              </Col>

              <Col span={4}>
                <Statistic title='商户总销售额' value={+info.euTransactionOrderAmount} />
              </Col>
            </Row>

            <Row >
              <Col span={4}>
                <Statistic title={`${year}总销售额`}
                  value={(info.tradingTotalAmountForThisYear)}
                />
              </Col>

              <Col span={4}>
                <Statistic title={`${year}云总销售额`}
                  value={(info.cloudTradingTotalAmountForThisYear)}
                />
              </Col>

              <Col span={4}>
                <Statistic title={`${year}商户总销售额`}
                  value={(info.merchantTradingTotalAmountForThisYear)} />
              </Col>
            </Row>
          </Card>
        </Row>

        <Row style={{ marginBottom: '15px' }}>
          <Card title='订单概览'>
            <Row gutter={12}>
              <Col span={4}>
                <Statistic title='总订单数'
                  value={+info.euTransactionOrderCount + info.cloudOrderCount}
                />
              </Col>

              <Col span={4}>
                <Statistic title='今日订单数'
                  value={+info.euTransactionOrderCountForToday + info.cloudOrderCountForToday}
                />
              </Col>

              <Col span={4}>
                <Statistic title='今日云订单数' value={+info.cloudOrderCountForToday} />
              </Col>

              <Col span={4}>
                <Statistic title='云订单总数' value={+info.cloudOrderCount} />
              </Col>

              <Col span={4}>
                <Statistic title='今日商户订单数' value={+info.euTransactionOrderCountForToday} />
              </Col>

              <Col span={4}>
                <Statistic title='商户订单总数' value={+info.euTransactionOrderCount} />
              </Col>

              <Col span={4}>
                {/* 总客单价 = 总销售额 / 总订单数 */}
                <Statistic title='总客单价'
                  value={
                    (+info.euTransactionOrderCount + info.cloudOrderCount) > 0
                      ? ((info.cloudOrderAmount + info.euTransactionOrderAmount) / (info.euTransactionOrderCount + info.cloudOrderCount)).toFixed(2)
                      : 0
                  }
                />
              </Col>

              <Col span={4}>
                {/* 商户客单价 = 今日销售额 / 今日订单数 */}
                <Statistic title='商户客单价'
                  value={
                    +info.euTransactionOrderCountForToday + info.cloudOrderCountForToday > 0
                      ? this.renderCusPriceStatistic()
                      : 0
                  }
                />
              </Col>

              <Col span={4}>
                {/* 云客单价 = 云销售额 / 云订单数 */}
                <Statistic title='云客单价'
                  value={
                    +info.cloudOrderCount > 0
                      ? (+info.cloudOrderAmount / info.cloudOrderCount).toFixed(2)
                      : 0
                  }
                />
              </Col>
              <Col span={4}>
                <Statistic title='今日快递数' value={info.courierOrderTotalForToday} />
              </Col>
            </Row>

            <Row >
              <Col span={4}>
                <Statistic title={`${year}总订单数`}
                  value={(info.orderTotalForThisYear)}
                />
              </Col>

              <Col span={4}>
                <Statistic title={`${year}云订单总数`}
                  value={(info.cloudOrderTotalForThisYear)}
                />
              </Col>

              <Col span={4}>
                <Statistic title={`${year}商户订单总数`}
                  value={(info.merchantOrderTotalForThisYear)} />
              </Col>
            </Row>

          </Card>
        </Row>

        <Row>
          <Card title='客户概览'>
            <Row gutter={12}>
              <Col span={4}>
                <Statistic title='客户总数' value={+info.euCount} />
              </Col>

              <Col span={4}>
                <Statistic title='今日新增客户' value={+info.euCountForToday} />
              </Col>

              <Col span={4}>
                <Statistic title='商户总数' value={+info.distributorCount} />
              </Col>

              <Col span={4}>
                <Statistic title='今日新增商户' value={+info.distributorCountForToday} />
              </Col>

              <Col span={4}>
                <Statistic title='成交总客户数' value={+info.euTransactionCount} />
              </Col>

              <Col span={4}>
                <Statistic title='云成交总客户数' value={+info.euTransactionCountForToday} />
              </Col>
            </Row>

            <Row >
              <Col span={4}>
                <Statistic title={`${year}客户总数`}
                  value={(info.endUserTotalForThisYear)}
                />
              </Col>

              <Col span={4}>
                <Statistic title={`${year}商户总数`}
                  value={(info.merchantTotalForThisYear)}
                />
              </Col>

              <Col span={4}>
                <Statistic title={`${year}成交总客户数`}
                  value={(info.endUserTradingTotalForThisYear)} />
              </Col>
            </Row>

          </Card>
        </Row>
      </div>
    )
  }
}

export default StatisticPage
