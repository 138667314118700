import React, { Component } from 'react'
import { Row, Card, Table, Tag, Pagination, Tooltip } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

const { skuGoodsTypeMaps } = filter
const { pageSizeOptions } = consts
const skuGoodsTypeOptions = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

class SetprofitFlow extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '供应商'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...skuGoodsTypeOptions
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '操作时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'setprofitFlow-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '供应商名称',
      key: 'setprofitFlow-1',
      width: 160,
      ellipsis: true,
      dataIndex: 'distributorName',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.distributorName}>
            <span>{ record.distributorName }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '型号',
      key: 'setprofitFlow-2',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'setprofitFlow-3',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '品牌',
      key: 'setprofitFlow-4',
      dataIndex: 'brandName'
    },
    {
      title: '产品名称',
      key: 'setprofitFlow-5',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>
            <span>{ record.name }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品类型',
      key: 'setprofitFlow-6',
      width: 80,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '调整利润率',
      key: 'setprofitFlow-7',
      dataIndex: 'skuProfitMargin',
      render: (text, record, index) => (
        <>
          { (+record.skuProfitMargin * 100 / 100).toFixed(2) }%
        </>
      )
    },
    {
      title: '操作日期',
      key: 'setprofitFlow-8',
      render: (text, record, index) => (
        <>
          { record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '操作人',
      key: 'setprofitFlow-9',
      dataIndex: 'modifyName'
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    this.getProfitSettingFlow()
  }

  // 获取利润设置记录
  getProfitSettingFlow = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getProfitSettingFlow(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        const { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      let _times = search.times
      search['beginDate'] = _times[0].format('YYYY-MM-DD')
      search['endDate'] = _times[1].format('YYYY-MM-DD')

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getProfitSettingFlow()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getProfitSettingFlow()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getProfitSettingFlow()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    // 分页配置
    const pagination = {
      // showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={false}
            />
            <div style={{ margin: '16px 0px', textAlign: 'right' }}>
              <Pagination {...pagination} />
            </div>
          </Card>
        </Row>
      </>
    )
  }
}

export default SetprofitFlow
