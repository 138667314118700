import React, { Component } from 'react'
import { Card, Table } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { repLineBreak, pageSizeOptions } = filter

// 爬取SKU数据管理
class ReferenceSkus extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'brand',
        formType: 'Input',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号'
        },
        cptParams: {
          placeholder: '请输入型号...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '订货号'
        },
        cptParams: {
          placeholder: '请输入订货号',
          autoComplete: 'off'
        }
      },
      {
        name: '_itemNumbers',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号/订货号...',
          autoComplete: 'off',
          rows: 4
        }
      },
      {
        name: 'distributor',
        formType: 'Select',
        itemParams: {
          label: '供应商户'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: []
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '更新时间'
        },
        cptParams: {

        }
      }
    ]
  }
  columns = [
    {
      title: '序号',
      key: 'referenceSkuCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'referenceSkuCol-1',
      dataIndex: 'brand'
    },
    {
      title: '型号',
      key: 'referenceSkuCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'referenceSkuCol-3',
      dataIndex: 'item_number'
    },
    {
      title: '库存数量',
      key: 'referenceSkuCol-4',
      dataIndex: 'stock'
    },
    {
      title: '供应价格',
      key: 'referenceSkuCol-5',
      dataIndex: 'price'
    },
    {
      title: '供应商户',
      key: 'referenceSkuCol-6',
      dataIndex: 'distributorName'
    },
    {
      title: '更新时间',
      key: 'referenceSkuCol-7',
      dataIndex: 'input_time',
      render: (text, record, index) => (
        <>{ record.input_time ? moment(record.input_time).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    }
  ]

  componentDidMount () {
    this.getReferenceSkuList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[4].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取引用数据列表
  getReferenceSkuList = async () => {
    const { pages, query = {} } = this.state

    const res = await Api.getReferenceSkuList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { times, _itemNumbers } = search

    if (times) {
      search.beginDate = times.length === 2 ? moment(times[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.endDate = times.length === 2 ? moment(times[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    if (_itemNumbers && $lodash.trim(_itemNumbers) !== '') {
      let _itemNos = repLineBreak($lodash.trim(_itemNumbers), '<br>')
      search.itemNumbers = _itemNos.split('<br>')
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getReferenceSkuList()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getReferenceSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getReferenceSkuList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ PADDING: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey={(record, index) => `referenceSkuRow-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default ReferenceSkus
