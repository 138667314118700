/* 订单模块 */
import TradeOrder from '@/pages/order/trade-order/index' // 总订单
import PurchOrder from '@/pages/order/purch-order/index' // 采购订单列表
import EuorderList from '@/pages/order/euorder-list/index' // eu客户订单列表
import UndispenseOrder from '@/pages/order/undispense-order/index' // 待分发的订单
import DispenseOrder from '@/pages/order/dispense-order/index' // 订单分发
import CloudTrade from '@/pages/order/cloudtrade-order/index' // 云端交易详情
import OrderSubmit from '@/pages/order/orderSubmit/index' // 订单预览页面

export default [
  {
    path: '/admin/tradeOrder',
    name: '订单管理',
    component: TradeOrder
  },
  {
    path: '/admin/purchOrder',
    name: '采购订单列表',
    component: PurchOrder
  },
  {
    path: '/admin/euOrder',
    name: 'EU客户订单列表',
    component: EuorderList
  },
  {
    path: '/admin/undispenseOrder',
    name: '待采购订单',
    component: UndispenseOrder
  },
  {
    path: '/admin/dispenseOrder',
    name: '订单采购',
    component: DispenseOrder
  },
  {
    path: '/admin/cloudTrade',
    name: '云端交易详情',
    component: CloudTrade
  },
  {
    path: '/admin/orderSubmit',
    name: '预览订单',
    component: OrderSubmit
  }
]
