import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import $lodash from 'lodash'

import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

export default class OrderProducts extends Component {
  static propTypes = {
    list: PropTypes.array,
    totalAmount: PropTypes.number, // 商品总金额
    spotTotalAmount: PropTypes.number, // 现货金额
    paymentAmount: PropTypes.number, // 应付金额
    prepaidAmount: PropTypes.number, // 期货定金
    balancePaymentAmount: PropTypes.number // 期货尾款
  }
  state = {

  }
  columns = [
    {
      title: '序号',
      key: 'orderProductsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'orderProductsCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'orderProductsCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'orderProductsCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'orderProductsCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'orderProductsCol-5',
      dataIndex: 'unit'
    },
    {
      title: '产品类型',
      key: 'orderProductsCol-6',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '价格',
      key: 'orderProductsCol-7',
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{+record.sellingPrice}</>
      )
    },
    {
      title: '数量',
      key: 'orderProductsCol-8',
      dataIndex: 'quantity'
    },
    {
      title: '预付比列',
      key: 'orderProductsCol-9',
      dataIndex: 'advancePaymentRate',
      render: (text, record, index) => (
        <>{(+record.advancePaymentRate * 100 / 100)}%</>
      )
    },
    {
      title: '金额',
      key: 'orderProductsCol-10',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.sellingPrice * record.quantity, 2) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {}

  render () {
    const { list = [], totalAmount = 0, spotTotalAmount = 0, prepaidAmount = 0, balancePaymentAmount = 0, paymentAmount = 0 } = this.props

    return (
      <>
        <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType || index}`} bordered
          columns={this.columns}
          dataSource={list}
          pagination={false}
        />

        <ul className={`${styles.orderTotals}`}>
          <li className={`${styles.totalItem}`}>
            <div className={`${styles.totalItemTitle}`}>现货金额</div>
            <div className={`${styles.totalItemContent}`}>&yen;{ ($lodash.round(+spotTotalAmount, 2) * 100 / 100).toFixed(2) }</div>
          </li>

          <li className={`${styles.totalItem}`}>
            <div className={`${styles.totalItemTitle}`}>期货订金</div>
            <div className={`${styles.totalItemContent}`}>&yen;{ ($lodash.round(+prepaidAmount, 2) * 100 / 100).toFixed(2) }</div>
          </li>

          <li className={`${styles.totalItem}`}>
            <div className={`${styles.totalItemTitle}`}>期货尾款</div>
            <div className={`${styles.totalItemContent}`}>&yen;{ ($lodash.round(+balancePaymentAmount, 2) * 100 / 100).toFixed(2) }</div>
          </li>

          <li className={`${styles.totalItem}`}>
            <div className={`${styles.totalItemTitle}`}>本次应付</div>
            <div className={`${styles.totalItemContent}`}>&yen;{ ($lodash.round(+paymentAmount, 2) * 100 / 100).toFixed(2) }</div>
          </li>

          <li className={`${styles.totalItem}`}>
            <div className={`${styles.totalItemTitle}`}>剩余金额</div>
            <div className={`${styles.totalItemContent}`}>&yen;{ ($lodash.round(+totalAmount - paymentAmount, 2) * 100 / 100).toFixed(2) }</div>
          </li>

          <li className={`${styles.totalItem}`}>
            <div className={`${styles.totalItemTitle}`}>订单总计</div>
            <div className={`${styles.totalItemContent}`}>&yen;{($lodash.round(+totalAmount, 2) * 100 / 100).toFixed(2)}</div>
          </li>
        </ul>
      </>
    )
  }
}
