import Ajax from '../utils/ajax'
export default {

  // 获取合作伙伴数量
  getPartnersTotal (params) {
    return Ajax.get('/oms/api/partners/getPartnersTotal', { params })
  },

  // 保存合作伙伴数
  savePartnersTotal (params) {
    return Ajax.post('/oms/api/partners/savePartnersTotal', params)
  },

  // 合作伙伴申请列表
  listPageForMerchantApply (params) {
    return Ajax.post('/oms/api/partners/listPageForMerchantApply', params)
  },

  // 处理结果
  handleProcessing (params) {
    return Ajax.post('/oms/api/partners/processing', params)
  },

  // 获取品牌
  listByAll (params) {
    return Ajax.post('/api/oms/mainSite/brandLogo/listByAll', params)
  },

  // 保存品牌接口
  listByAllsave (params) {
    return Ajax.post('/api/oms/mainSite/brandLogo/save', params)
  },

  // 工聚宝- 获取工聚宝下载信息列表
  getToolsBoxBusinessDownloadRecords (params) {
    return Ajax.post('/oms/official/website/contract/download/query/by/page', params)
  }

}
