import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input, InputNumber } from 'antd'

const FormItem = Form.Item
const nameMap = {
  'CATALOG': [ '', '新增目录', '编辑目录' ],
  'MENU': ['', '新增菜单', '编辑菜单'],
  'BTN': ['', '新增按钮', '编辑按钮']
}

const labelNameMap = {
  'CATALOG': { name: '目录名称', code: 0 },
  'MENU': { name: '菜单名称', code: 1 },
  'BTN': { name: '按钮名称', code: 2 }
}

class SetmenuForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    parentId: PropTypes.number,
    floor: PropTypes.string,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, parentId = 0, floor, detailInfo } = this.props

        let params = {
          ...values,
          parentId: parentId,
          type: labelNameMap[floor]['code']
        }

        if (+type === 2) {
          params.menuId = detailInfo.menuId
        }

        this.props.onConfirm(params)
      }
    })
  }

  // 渲染弹窗名称
  renderModalTitle = () => {
    const { type = 1, floor = 'CATALOG' } = this.props
    let _tit = '新增目录'

    _tit = nameMap[floor][type]

    return _tit
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { floor, detailInfo = {} } = this.props

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    return (
      <div>
        <Modal title={this.renderModalTitle()}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Form>
              <FormItem label={labelNameMap[floor]['name']} {...formItemLayout}>
                {
                  getFieldDecorator('name', {
                    initialValue: detailInfo.name ? detailInfo.name : '',
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>

              {
                floor === 'CATALOG'
                  ? <FormItem label='目录图标' {...formItemLayout}>
                    {
                      getFieldDecorator('icon', {
                        initialValue: detailInfo.icon ? detailInfo.icon : '',
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

              {
                floor === 'MENU'
                  ? <FormItem label='跳转路由' {...formItemLayout}>
                    {
                      getFieldDecorator('url', {
                        initialValue: detailInfo.url ? detailInfo.url : '',
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

              {
                floor === 'BTN'
                  ? <FormItem label='按钮标识' {...formItemLayout}>
                    {
                      getFieldDecorator('ident', {
                        initialValue: detailInfo.ident ? detailInfo.ident : '',
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        rules: [
                          { required: false, message: '该项必填' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

              <FormItem label='apiUrl' {...formItemLayout}>
                {
                  getFieldDecorator('apiUrl', {
                    initialValue: detailInfo.apiUrl ? detailInfo.apiUrl : ''
                  })(
                    <Input autoComplete='off' />
                  )
                }
              </FormItem>

              <FormItem label='排序' {...formItemLayout}>
                {
                  getFieldDecorator('orderNum', {
                    initialValue: detailInfo.orderNum ? detailInfo.orderNum : '',
                    rules: [
                      { required: floor !== 'BTN', message: '该项必填' }
                    ]
                  })(
                    <InputNumber placeholder='请输入' />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const SetmenuModal = Form.create()(SetmenuForm)

export default SetmenuModal
