import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Modal, Tooltip, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import ProgressModal from '../bindProgressModal/index' // 任务进度弹窗

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions, repLineBreak } = filter

export default class CanBindSkuTable extends Component {
  static propTypes = {
    form: PropTypes.object,
    onConfirmBind: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (val) => this.getSeriesList(val)
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'seriesId',
        formType: 'Select',
        itemParams: {
          label: '系列'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['id', 'name']
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    query: {},
    tabelData: [],
    selectedRowKeys: [],
    selectedType: 'CURRENT_PAGE',
    progressModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'bindSkuImageCol-1',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'bindSkuImageCol-2',
      dataIndex: 'brandName'
    },
    {
      title: '系列',
      key: 'bindSkuImageCol-3',
      dataIndex: 'series'
    },
    {
      title: '型号',
      key: 'bindSkuImageCol-4',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <><Tooltip placement='topLeft' title={record.model}>{ record.model }</Tooltip></>
      )
    },
    {
      title: '订货号',
      key: 'bindSkuImageCol-5',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <><Tooltip placement='topLeft' title={record.itemNumber}>{ record.itemNumber }</Tooltip></>
      )
    },
    {
      title: '产品名称',
      key: 'bindSkuImageCol-6',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <><Tooltip placement='topLeft' title={record.name}>{ record.name }</Tooltip></>
      )
    },
    {
      title: '操作',
      key: 'bindSkuImageCol-7',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleBindSelectedKeys('CURRENT_PAGE', [record.commoditySkuId])}>关联</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
  }

  // 获取品牌列表
  getBrandListInSearch = async (params) => {
    const res = await Api.getBrandListInSearch(params)
    const { code, data } = res
    if (+code === 10000 && data && data.length > 0) {
      let { searchData } = this.state

      data.map(item => {
        item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
      })

      searchData[0].options = data || []

      this.setState({
        searchData: [...searchData]
      })
    }
  }

  // 获取系列列表
  getSeriesList = async (val) => {
    const res = await Api.getSeriesList({ brandId: val, pageNo: 1, pageSize: 999 })
    const { code, data } = res

    if (+code === 10000 && data && data.list && data.list.length > 0) {
      let { searchData } = this.state

      searchData[1].options = $lodash.concat([{ id: '', name: '全部' }], data.list)

      this.setState({
        searchData: [...searchData]
      }, () => {
        this.props.form.setFieldsValue({ seriesId: undefined })
      })
    }
  }

  // 获取型号列表
  getSkuList = async () => {
    const { query = {}, pages } = this.state
    const res = await Api.getSkuList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { itemNo } = search

    if (itemNo && $lodash.trim(itemNo) !== '') {
      const num = repLineBreak(search.itemNo, '<br>')

      search.itemNo = num
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getSkuList()
    })
  }

  // 点击关联
  handleBindSelectedKeys = (type, keys = []) => {
    const { query = {}, pages = {} } = this.state
    if (keys.length === 0) { return message.warning('请选择要关联的产品~~') }

    Modal.confirm({
      title: '确定关联？',
      onOk: () => {
        this.props.onConfirmBind(type, keys, { ...query, pageSize: type === 'ALL' ? pages.total : pages.pageSize })
      }
    })
  }

  // 点击选择产品
  setSelectedRowKeys = (selectedType, keys) => {
    this.setState({
      selectedRowKeys: keys,
      selectedType: selectedType
    })
  }

  // 点击查看任务进度
  showTaskProgressModal = () => {
    this.setState({
      progressModal: true
    })
  }

  closeModal = () => {
    this.setState({
      progressModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSkuList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], selectedRowKeys = [], selectedType = 'CURRENT_PAGE', progressModal = false } = this.state

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      hideDefaultSelections: true,
      onChange: (keys) => this.setSelectedRowKeys('CURRENT_PAGE', keys),
      selections: [
        {
          key: 'CURRENT_PAGE',
          text: '选择本页数据',
          onSelect: () => {
            if (tableData.length === 0) { return message.warning('暂无可关联的数据~~') }
            const keys = $lodash.map(tableData, (o) => { return o.commoditySkuId })

            this.setSelectedRowKeys('CURRENT_PAGE', keys)
          }
        },
        {
          key: 'ALL',
          text: '选择全部数据',
          onSelect: () => {
            if (tableData.length === 0) { return message.warning('暂无可关联的数据~~') }
            const keys = $lodash.map(tableData, (o) => { return o.commoditySkuId })

            this.setSelectedRowKeys('ALL', keys)
          }
        }
      ]
    }

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card title='批量关联产品' bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '20px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </div>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handleBindSelectedKeys(selectedType, selectedRowKeys)}>批量关联</Button>
            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.showTaskProgressModal()}>查看任务进度</Button>
            {
              selectedRowKeys.length > 0
                ? <span style={{ marginLeft: '10px' }}>已选中&nbsp;{selectedType === 'ALL' ? <>{ pages.total }</> : <>{ selectedRowKeys.length }</>}&nbsp;条数据</span>
                : null
            }
          </div>

          <Table rowKey='commoditySkuId' bordered
            columns={this.columns}
            dataSource={tableData}
            rowSelection={rowSelection}
            pagination={pagination}
          />
        </Card>

        {
          progressModal
            ? <ProgressModal
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
