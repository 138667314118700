import React, { Component } from 'react'
import { Card, Table, Button, Icon, Tooltip } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderEnquireOrderStatusLabel } from '@/common/utils/mUtils'
import styles from '../index.module.scss'

const { pageSizeOptions } = filter
const warningDaysMaps = [
  { id: 7, name: '7天' },
  { id: 10, name: '10天' },
  { id: 14, name: '14天' },
  { id: 30, name: '30天' },
  { id: 36500, name: '30天以上' }
]

class AlikeEnquiry extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'warningDays',
        formType: 'Select',
        itemParams: {
          label: '预警期限'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        rules: {
          initialValue: 7
        },
        options: [
          ...warningDaysMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: { warningDays: 7 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'ALIKE_WARNING-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '询价单编号',
      key: 'ALIKE_WARNING-enquiryOrderId',
      dataIndex: 'enquiryOrderId'
    },
    {
      title: '询价日期',
      key: 'ALIKE_WARNING-submitTime',
      dataIndex: 'submitTime',
      render: (text, record, index) => (
        <>{ record.submitTime ? moment(record.submitTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '询价商户',
      key: 'ALIKE_WARNING-distributorIdId',
      dataIndex: 'distributorName'
    },
    {
      title: '负责人',
      key: 'ALIKE_WARNING-platformUserName',
      dataIndex: 'platformUserName'
    },
    {
      title: '询价单类型',
      key: 'ALIKE_WARNING-enquiryType',
      dataIndex: 'enquiryType',
      render: (text, record, index) => (
        <>{ renderEnquireOrderStatusLabel(record.enquiryType) }</>
      )
    },
    {
      title: '行号',
      key: 'ALIKE_WARNING-rownum',
      dataIndex: 'rownum'
    },
    {
      title: '品牌',
      key: 'ALIKE_WARNING-brandName',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'ALIKE_WARNING-model',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}><span>{ record.model }</span></Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'ALIKE_WARNING-itemNumber',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}><span>{ record.itemNumber }</span></Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'ALIKE_WARNING-name',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}><span>{ record.name }</span></Tooltip>
        </>
      )
    },
    {
      title: '期望价格',
      key: 'ALIKE_WARNING-expectPrice',
      dataIndex: 'expectPrice',
      render: (text, record, index) => (
        <>&yen;{ +record.expectPrice }</>
      )
    },
    {
      title: '数量',
      key: 'ALIKE_WARNING-quantity',
      dataIndex: 'quantity'
    }
  ]

  componentDidMount () {
    this.getALikeProductEnquiryWarningList()
  }

  // 获取相同产品询价预警列表
  getALikeProductEnquiryWarningList = async () => {
    const { query = {}, pages } = this.state
    const res = await Api.getALikeProductEnquiryWarningList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getALikeProductEnquiryWarningList()
    })
  }

  // 点击下载
  handleDownload = () => {
    const { query } = this.state

    DownloadApi.downloadALikeProductEnquiryWarningList({ ...query, pageNo: 1, pageSize: 99999 })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getALikeProductEnquiryWarningList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getALikeProductEnquiryWarningList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div className={`${styles.title}`}>相同询价预警</div>

        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <div style={{ marginBottom: '12px' }}>
          <Button type='primary' onClick={() => this.handleDownload()}><Icon type='download' />导出</Button>
        </div>

        <Table rowKey={(record, index) => `${record.enquiryOrderId}-${record.commoditySkuId}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </>
    )
  }
}

export default AlikeEnquiry
