import React, { Component } from 'react'
import { Card, Descriptions, Button, Statistic, message } from 'antd'
import $lodash from 'lodash'

import PersonnelTable from './personnelTable/index'
import SettingModal from './settingModal/index'

import Api from '@/common/api/index'
import styles from './index.module.scss'

class PurposeSetting extends Component {
  state = {
    targetData: [],
    activeDepartId: null,
    activeChildren: [],
    settingModal: false
  }

  componentDidMount () {
    this.getPurposeTargetDetails()
  }

  // 获取统计指标详情
  getPurposeTargetDetails = async () => {
    const res = await Api.getPurposeTargetDetails({})
    const { code, data } = res
    if (+code === 10000) {
      let newdatas = []

      if (data) {
        newdatas = $lodash.map(data, (values, key) => {
          return {
            departId: key,
            departName: values[0].departmentName,
            children: values
          }
        })
      }

      this.setState({
        targetData: newdatas
      })
    }
  }

  // 保存统计指标调整
  saveEditPurposeTarget = async (params) => {
    const res = await Api.saveEditPurposeTarget(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getPurposeTargetDetails()
    }
  }

  showSettingModal = (departId, record) => {
    this.setState({
      activeDepartId: departId,
      activeChildren: record,
      settingModal: true
    })
  }

  closeModal = () => {
    this.setState({
      activeDepartId: null,
      activeChildren: [],
      settingModal: false
    })
  }

  render () {
    const { targetData = [], activeDepartId, activeChildren = [], settingModal = false } = this.state

    return (
      <>
        <div>
          <Card bodyStyle={{ padding: '12px' }}>
            <ul className={`${styles.list}`}>
              {
                targetData.map((record, index) => (
                  <li key={record.departId} className={`${styles.listItem}`}>
                    <div className={`${styles.listItemContent}`}>
                      <Descriptions title={record.departName}>
                        {
                          record.children.map((item, n) => (
                            <Descriptions.Item key={`${record.departId}-${item.id}`} label={`${item.targetName}(${item.unit})`}>
                              <Statistic value={item.targetValue} valueStyle={{ fontSize: '20px' }} />
                            </Descriptions.Item>
                          ))
                        }
                      </Descriptions>
                    </div>
                    <div className={`${styles.listItemMeta}`}>
                      <Button type='primary' onClick={() => this.showSettingModal(record.departId, record.children)}>调整目标</Button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </Card>
        </div>

        <PersonnelTable />

        {
          settingModal
            ? <SettingModal
              activeDepartId={activeDepartId}
              activeChildren={activeChildren}
              onConfirm={(values) => this.saveEditPurposeTarget(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default PurposeSetting
