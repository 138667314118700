import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Checkbox, Icon, Button, message } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import $lodash from 'lodash'

import styles from './index.module.scss'

class ColumnsConfigForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    disabledBeforeColumns: PropTypes.array, // 前置不参与移动勾选的列表项
    disabledAfterColumns: PropTypes.array, // 后置不参与移动勾选的列表项
    allcolumns: PropTypes.array,
    selectedColumnKeys: PropTypes.array, // 选中的key
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onReset: PropTypes.func
  }
  state = {
    columns: []
  }

  componentDidMount () {
    const { allcolumns = [] } = this.props

    this.setState({
      columns: allcolumns
    })
  }

  // 点击初始化
  handleReset = () => {
    Modal.confirm({
      title: '确定重置?',
      content: <div>列表配置将恢复到初始状态</div>,
      onOk: () => {
        this.props.onReset()
      }
    })
  }

  // 点击保存
  handleConfirm = () => {
    const { form, disabledBeforeColumns = [], disabledAfterColumns = [], onConfirm } = this.props
    const { columns = [] } = this.state

    const values = form.getFieldValue('checkedValues')

    if (!values || values.length === 0) { return message.warning('请选择要展示的列表项') }
    const sortkeys = $lodash.map(columns, (o) => { return o.key })

    const beforeKeys = disabledBeforeColumns.length > 0 ? $lodash.map(disabledBeforeColumns, (o) => { return o.key }) : []
    const afterKeys = disabledAfterColumns.length > 0 ? $lodash.map(disabledAfterColumns, (o) => { return o.key }) : []

    const newSortKeys = [...beforeKeys, ...sortkeys, ...afterKeys]

    onConfirm(values, newSortKeys)
  }

  // 重新记录数组顺序
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    // 删除并记录 删除元素
    const [removed] = result.splice(startIndex, 1)
    // 将原来的元素添加进数组
    result.splice(endIndex, 0, removed)

    return result
  }

  // 拖拽结束
  handleDragEnd = (result) => {
    if (!result.destination) { return false }
    const { columns = [] } = this.state

    const { source = {}, destination = {} } = result
    const current = [...columns]

    this.setState({
      columns: this.reorder(current, source.index, destination.index)
    })
  }

  render () {
    const { form, disabledBeforeColumns = [], disabledAfterColumns = [], selectedColumnKeys = [], onCancel } = this.props
    const { getFieldDecorator } = form

    const { columns = [] } = this.state

    const disabledBeforeKeys = disabledBeforeColumns && disabledBeforeColumns.length > 0 ? $lodash.map(disabledBeforeColumns, (o) => { return o.key }) : []
    const disabledAfterKeys = disabledAfterColumns && disabledAfterColumns.length > 0 ? $lodash.map(disabledAfterColumns, (o) => { return o.key }) : []

    return (
      <>
        <Modal title='列设置' bodyStyle={{ padding: '24px 0px' }}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={[
            <Button key={`btns-CANCEL`} onClick={() => onCancel()}>取消</Button>,
            <Button type='danger' key={`btns-INIT`} onClick={() => this.handleReset()}>重置</Button>,
            <Button type='primary' key={`btns-CONFIRM`} onClick={() => this.handleConfirm()}>保存</Button>
          ]}
        >
          <Form>
            <Form.Item style={{ marginBottom: '0px' }}>
              {
                getFieldDecorator('checkedValues', {
                  initialValue: [...disabledBeforeKeys, ...selectedColumnKeys, ...disabledAfterKeys]
                })(
                  <Checkbox.Group style={{ width: '100%' }}>
                    {
                      disabledBeforeColumns.length > 0
                        ? <>
                          {
                            disabledBeforeColumns.map((btem) => (
                              <div className={`${styles.treenode}`} key={btem.key}>
                                <div className={`${styles.treenodePrefix}`}>
                                  <Checkbox value={btem.key} disabled />
                                </div>

                                <div className={`${styles.treenodeMain}`}>{ btem.title }</div>
                                <div className={`${styles.treenodeActions}`} />
                              </div>
                            ))
                          }
                        </>
                        : null
                    }

                    <div>
                      <DragDropContext onDragEnd={this.handleDragEnd}>
                        <Droppable droppableId='droppable' direction='vertical'>
                          {
                            (droppableProvided, droppableSnapshot) => (
                              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                {
                                  columns.map((record, index) => (
                                    <Draggable key={record.key}
                                      draggableId={`draggable-${record.key}`}
                                      index={index}
                                    >
                                      {
                                        (provided, snapshot) => (
                                          <div className={`${styles.treenode}`} ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div className={`${styles.treenodePrefix}`}><Checkbox value={record.key} /></div>
                                            <div className={`${styles.treenodeMain}`}>{ record.title }</div>
                                            <div className={`${styles.treenodeActions}`}><Icon type='drag' style={{ color: '#1890FF' }} /></div>
                                          </div>
                                        )
                                      }
                                    </Draggable>
                                  ))
                                }

                                {droppableProvided.placeholder}
                              </div>
                            )
                          }
                        </Droppable>
                      </DragDropContext>
                    </div>

                    {
                      disabledAfterColumns.length > 0
                        ? <>
                          {
                            disabledAfterColumns.map((atem) => (
                              <div className={`${styles.treenode}`} key={atem.key}>
                                <div className={`${styles.treenodePrefix}`}>
                                  <Checkbox value={atem.key} disabled />
                                </div>

                                <div className={`${styles.treenodeMain}`}>{ atem.title }</div>
                                <div className={`${styles.treenodeActions}`} />
                              </div>
                            ))
                          }
                        </>
                        : null
                    }
                  </Checkbox.Group>
                )
              }

            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}

const ColumnsConfigModal = Form.create()(ColumnsConfigForm)

export default ColumnsConfigModal
