import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Descriptions, Progress, Button, Divider } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

const progressStatusMap = {
  ACCEPTED: 'active',
  SUCCESS: 'success',
  FAIL: 'exception'
}

class BindProgressModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func
  }
  state = {
    tableData: []
  }

  componentDidMount () {
    this.getBindSkuImagesTaskProgressList()
  }

  // 获取图片关联SKU任务进度列表
  getBindSkuImagesTaskProgressList = async () => {
    const res = await Api.getBindSkuImagesTaskProgressList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || []
      })
    }
  }

  // 点击导出任务失败结果
  handleDownloadTaskFailResult = (record) => {
    DownloadApi.downloadBindSkuTaskFailResult({ id: record.id })
  }

  render () {
    const { onCancel } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title='任务进度' width={800} bodyStyle={{ height: '500px', overflowY: 'auto' }}
          visible
          onCancel={() => onCancel()}
          onOk={() => this.getBindSkuImagesTaskProgressList()}
          okText='刷新'
        >
          {
            tableData.length > 0
              ? <>
                {
                  tableData.map((item, index) => (
                    <div key={`progressItem-${index}`} style={{ marginBottom: '24px' }}>
                      <Descriptions>
                        <Descriptions.Item label='任务编号'>{ item.id }</Descriptions.Item>
                        <Descriptions.Item label='任务描述' span={2}>{ item.taskDesc }</Descriptions.Item>
                        <Descriptions.Item label='操作人'>{ item.modifyName }</Descriptions.Item>
                        <Descriptions.Item label='操作时间' span={2}>{ item.modifyTime ? moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</Descriptions.Item>
                        <Descriptions.Item label='关联总数'>{ +item.totalQuantity }</Descriptions.Item>
                        <Descriptions.Item label='成功数量'>{ +item.successQuantity }</Descriptions.Item>
                        <Descriptions.Item label='失败数量'>
                          <span>{ item.syncStatus === 'FAIL' ? item.totalQuantity - item.successQuantity : 0 }</span>
                          {
                            item.syncStatus === 'FAIL'
                              ? <Button type='link' size='small' onClick={() => this.handleDownloadTaskFailResult(item)}>导出失败结果</Button>
                              : null
                          }
                        </Descriptions.Item>
                      </Descriptions>

                      <Progress percent={item.syncStatus === 'ACCEPTED' ? 30 : 100} status={progressStatusMap[item.syncStatus]} />

                      { index === tableData.length - 1 ? null : <Divider dashed /> }
                    </div>
                  ))
                }
              </>
              : null
          }
        </Modal>
      </>
    )
  }
}

export default BindProgressModal
