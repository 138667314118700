import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Button, Row, Icon, Divider, Col, Input, Cascader, Upload, Select, message, Modal, AutoComplete } from 'antd'
import $lodash from 'lodash'
import JSEncrypt from 'jsencrypt'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore, removeStore, Loginname, Loginpassword, addressToTree, renderCustomerAuthorLabel } from '@/common/utils/mUtils'
import PublickeyJson from '@/assets/json/publicKey.json'
import RegionJson from '@/assets/json/region.json'

import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const Option = Select.Option
const AutoCompleteOption = AutoComplete.Option
const { uploadOrdinaryImageUrl } = UploadApi
const cityOptions = addressToTree()

class MCInformationForm extends Component {
  constructor (props) {
    super(props)
    this.getCompanyInfoByKeywords = $lodash.debounce(this.getCompanyInfoByKeywords, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    author: PropTypes.string,
    type: PropTypes.string,
    detailInfo: PropTypes.object,
    getDomain: PropTypes.func,
    onCheckLoginname: PropTypes.func, // 校验登录名
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    isCheckedLoginname: false, // 是否校验
    loginnameCanUse: true, // 登录名是否可用
    uncompanyList: [],
    statisticsMerchantList: []
  }

  componentDidMount () {
    this.getDomainConfig()
  }

  // 获取域名
  getDomainConfig = () => {
    this.props.getDomain({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.form.setFieldsValue({
            domainAddress: data
          })
        }, 20)
      }
    })
  }

  // 商户名称查询
  getCompanyInfoByKeywords = async (value = '', key) => {
    if (value === '') { return false }

    const res = await Api.getCompanyInfoByKeywords({ keyword: value, pageNo: 1, pageSize: 10 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        [`${key}List`]: data || []
      })
    }
  }

  // 获取企业信息
  getCompanyInfoByName = async (params) => {
    const res = await Api.getCompanyInfoByName(params)
    const { code, data } = res
    if (+code === 10000 && data && data.econKindCode) {
      setTimeout(() => {
        this.props.form.setFieldsValue({
          merchantName: data.name,
          address: data.address,
          citys: [`${data.province}`, `${data.city}`, `${data.county}`],
          merchantCreditNo: data.creditNo
        })
      }, 20)
    }
  }

  // 校验登录名是否重复
  onCheckLoginname = (params) => {
    this.props.onCheckLoginname(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          loginnameCanUse: data,
          isCheckedLoginname: true
        }, () => {
          data === true ? message.success('该登录名可用') : message.warning('登录名不可用,请进行更换')
        })
      }
    })
  }

  // 编辑-重置密码
  resetPassword = (params) => {
    Api.resetPassword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  saveMCInformation = (params) => {
    this.props.onConfirm(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.props.onCancel()
      }
    })
  }

  // 点击保存
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (err) { return false }
      const { type = 'ADD', detailInfo = {} } = this.props
      const { isCheckedLoginname = false, loginnameCanUse = true } = this.state
      const { businessLicenseUrl, citys, password, statisticsMerchantName } = values

      if (type === 'ADD' && !isCheckedLoginname) {
        return message.warning('请先验证登录名是否可用')
      }

      if (type === 'ADD' && !loginnameCanUse) {
        return message.warning('登录名不可用,请进行更换')
      }

      if (!businessLicenseUrl || businessLicenseUrl === '') {
        return message.warning('请上传营业执照图片')
      }

      if (type === 'ADD') {
        const jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['password'] = jsencrypt.encrypt(password)
      } else {
        values.distributorId = detailInfo.distributorId
      }

      if (values.citys && values.citys.length > 0) {
        values.province = citys[0]
        values.city = citys.length === 2 ? citys[0] : citys[1]
        values.county = citys.length === 2 ? citys[1] : citys[2]
      }

      values.statisticsMerchantName = statisticsMerchantName.split('-')[0]

      this.saveMCInformation(values)
    })
  }

  // 上传营业执照
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data, message } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.props.form.setFieldsValue({
            businessLicenseUrl: data.imageUrl || ''
          })
        }, 20)
      } else if (code === 'PLATFORM_99060') {
        removeStore('token')
        this.props.history.push('/login')
      } else {
        message.error(message)
      }
    }
  }

  // 删除营业执照
  removeUploadLicense = () => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        businessLicenseUrl: ''
      })
    }, 20)
  }

  // 点击选择公司
  handleSelectedCompany = (value) => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        merchantName: value
      }, () => {
        this.getCompanyInfoByName({ keyword: value, pageNo: 1, pageSize: 10 })
      })
    }, 20)
  }

  // 点击重置密码
  handleResetPassword = () => {
    Modal.confirm({
      title: '确定重置密码？',
      content: '',
      onOk: () => {
        const { detailInfo = {} } = this.props
        const params = {
          distributorId: detailInfo.distributorId
        }

        this.resetPassword(params)
      }
    })
  }

  // 点击返回
  handleBack = () => {
    this.props.onCancel()
  }

  // 设置省市区级联回显
  renderCascaderDefaultValue = () => {
    const { detailInfo = {} } = this.props
    let arr = []

    if (+detailInfo.province > 0 && +detailInfo.city > 0) {
      if (+detailInfo.province === +detailInfo.city) {
        arr.push(`${detailInfo.province}`, `${detailInfo.county}`)
      } else {
        arr.push(`${detailInfo.province}`, `${detailInfo.city}`, `${detailInfo.county}`)
      }
    }

    return arr
  }

  render () {
    const { author, type = 'ADD', detailInfo = {} } = this.props
    const { uncompanyList = [] } = this.state
    // statisticsMerchantList = []
    const { getFieldDecorator, getFieldValue } = this.props.form

    return (
      <Card title={type === 'EDIT' ? '修改客户' : '添加客户'}>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}>
            <Icon type='left' />返回列表
          </Button>
        </Row>

        <Row>
          <Form>
            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户工商信息</span>
            </Divider>
            <Row gutter={24}>
              <Col span={10}>
                <div className={styles.merchant}>
                  <FormItem label='商户名称'>
                    {
                      getFieldDecorator('merchantName', {
                        initialValue: detailInfo.name || '',
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <AutoComplete placeholder='营业执照上的公司名称'
                          onSearch={(value) => this.getCompanyInfoByKeywords(value, 'uncompany')}
                          onSelect={(value) => this.handleSelectedCompany(value)}
                        >
                          {
                            uncompanyList.length > 0 && uncompanyList.map((comp) => (
                              <AutoCompleteOption key={comp.name}>{comp.name}</AutoCompleteOption>
                            ))
                          }
                        </AutoComplete>
                      )
                    }
                  </FormItem>
                </div>
              </Col>

              <Col span={10}>
                <FormItem label='统计名称'>
                  {
                    getFieldDecorator('statisticsMerchantName', {
                      initialValue: detailInfo.statisticsMerchantName,
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='营业执照上的公司名称' />
                      // <Select placeholder='营业执照上的公司名称' showSearch filterOption={false}
                      //   defaultActiveFirstOption={false}
                      //   showArrow={false}
                      //   notFoundContent={null}
                      //   onSearch={(value) => this.getCompanyInfoByKeywords(value, 'statisticsMerchant')}
                      // >
                      //   {
                      //     statisticsMerchantList.length > 0 && statisticsMerchantList.map((item, index) => (
                      //       <Option value={`${item.name}-${item.creditNo}`} key={`${item.name}-${item.creditNo}`}>{ item.name }</Option>
                      //     ))
                      //   }
                      // </Select>
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='工商营业执照注册号/统一社会信用代码'>
                  {
                    getFieldDecorator('merchantCreditNo', {
                      initialValue: detailInfo.creditNo || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='15位税务登记证号或18位统一社会信用代码' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='商户所在地区'>
                  {
                    getFieldDecorator('citys', {
                      initialValue: type === 'EDIT' ? this.renderCascaderDefaultValue() : [],
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Cascader placeholder='请选择省市区'
                        fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                        options={cityOptions}
                      />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label='商户详细地址'>
                  {
                    getFieldDecorator('address', {
                      initialValue: detailInfo.address || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <TextArea rows={4} placeholder='公司注册详细地址' />
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='商户开票电话'>
                  {
                    getFieldDecorator('mobile', {
                      initialValue: detailInfo.mobile || '',
                      rules: [
                        { required: true, message: '请填写商户开票电话' }
                      ]
                    })(
                      <Input placeholder='请输入商户电话' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem style={{ display: 'none' }}>
                  {
                    getFieldDecorator('businessLicenseUrl', {
                      initialValue: detailInfo.businessLicenseUrl || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>

                <div style={{ lineHeight: '40px' }}>
                  <label className={`${styles.formLabel} ${styles.required}`}>营业执照电子版</label>
                </div>
                {
                  getFieldValue('businessLicenseUrl') !== undefined && getFieldValue('businessLicenseUrl') !== ''
                    ? <UploadThumbnail
                      imageUrl={getFieldValue('businessLicenseUrl')}
                      onDelete={() => this.removeUploadLicense()}
                    />
                    : <Upload name='file'
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <div>
                        <Icon type='plus' />
                      </div>
                    </Upload>
                }
              </Col>
            </Row>
            <Row gutter={24}>
              {
                ['JUST_PURCHASE', 'SUPPLY_PURCHASE'].includes(author)
                  ? <Col span={10}>
                    <FormItem label='发票抬头(公司名称)'>
                      {
                        getFieldDecorator('invoiceTitle', {
                          initialValue: detailInfo.invoiceTitle || '',
                          rules: [
                            { required: true, message: '请输入发票抬头' }
                          ]
                        })(
                          <Input placeholder='请输入...' autoComplete='off' />
                        )
                      }
                    </FormItem>
                  </Col>
                  : null
              }

              <Col span={10}>
                <FormItem label='开户银行'>
                  {
                    getFieldDecorator('accountBank', {
                      initialValue: detailInfo.accountBank || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='基本存款账户开户银行' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='银行账号'>
                  {
                    getFieldDecorator('account', {
                      initialValue: detailInfo.account || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='基本存款账户账号' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='银行联行号'>
                  {
                    getFieldDecorator('bankNo', {
                      initialValue: detailInfo.bankNo || ''
                    })(
                      <Input placeholder='基本银行联行号' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户其他信息</span>
            </Divider>
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='管理员登录名'
                  extra={<span className={`${styles.formExtra}`}>登录名长度为4-16位字符，只能包含数字,大小写字母,下划线</span>}
                >
                  {
                    getFieldDecorator('loginName', {
                      initialValue: detailInfo.loginName || '',
                      rules: [
                        { required: true, message: '该项必填' },

                        {
                          validator (rule, value, callback) {
                            if (value && !Loginname.test(value)) {
                              /* eslint-disable */
                            callback('请输入正确的登录名')
                            /* eslint-enable */
                            } else {
                              callback()
                            }
                          }
                        }
                      ]
                    })(
                      <Input.Search placeholder='以字母开头,2-20位字母或数字' autoComplete='off'
                        enterButton='校验是否重复'
                        disabled={type === 'EDIT'}
                        onSearch={(value) => this.onCheckLoginname({ loginName: value })}
                      />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                {
                  type === 'ADD'
                    ? <FormItem label='管理员登录密码'
                      extra={<span className={`${styles.formExtra}`}>密码长度为6-12位字符，可包含数字,字母(区分大小写)</span>}
                    >
                      {
                        getFieldDecorator('password', {
                          initialValue: detailInfo.password || '',
                          rules: [
                            { required: true, message: '该项必填' },
                            {
                              validator (rule, value, callback) {
                                if (value && !Loginpassword.test(value)) {
                                  /* eslint-disable */
                                      callback('密码格式不正确')
                                      /* eslint-enable */
                                } else {
                                  callback()
                                }
                              }
                            }
                          ]
                        })(
                          <Input.Password placeholder='6-18位大小写字母或数字' autoComplete='off' disabled={type !== 'ADD'} />
                        )
                      }
                    </FormItem>
                    : <FormItem label='密码' extra={<span className={`${styles.formExtra}`} style={{ visibility: 'hidden' }}>密码长度为6-12位字符，可包含数字,字母(区分大小写)</span>}>
                      <span>******</span>
                      <Button type='primary' style={{ marginLeft: '12px' }} onClick={() => this.handleResetPassword()}>
                       重置密码
                      </Button>
                    </FormItem>
                }
              </Col>

              <Col span={10}>
                <FormItem label='管理员身份证姓名'>
                  {
                    getFieldDecorator('adminUser', {
                      initialValue: detailInfo.adminUser,
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='管理员手机号'>
                  {
                    getFieldDecorator('adminMobile', {
                      initialValue: detailInfo.adminMobile || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ],
                      getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label='商户所属区域'>
                  {
                    getFieldDecorator('regionCode', {
                      initialValue: detailInfo.regionCode,
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Select placeholder='请选择商户所属区域'>
                        {
                          RegionJson.map((region, i) => (
                            <Option value={region.code} key={region.code}>{ region.name }</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户域名' style={{ display: 'none' }}>
                  {
                    getFieldDecorator('domainAddress', {
                      rules: [
                        { required: true, message: '请填写商户域名' }
                      ]
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>

                <FormItem label='商户域名'>
                  <>
                    {
                      getFieldValue('domainAddress') !== undefined || getFieldValue('domainAddress') !== ''
                        ? <span>{getFieldValue('domainAddress')}</span>
                        : null
                    }
                  </>
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户权限' style={{ display: 'none' }}>
                  {
                    getFieldDecorator('type', {
                      initialValue: author
                    })(
                      <Input disabled />
                    )
                  }
                </FormItem>

                <FormItem label='商户权限'>
                  <span>{ renderCustomerAuthorLabel(author) }</span>
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label='商户短信签名' style={{ display: 'none' }}>
                  {
                    getFieldDecorator('smsSignname', {
                      initialValue: detailInfo.smsSignname || 'IIaSaaS',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
                <FormItem label='商户短信签名'>
                  {
                    getFieldValue('smsSignname') !== undefined && getFieldValue('smsSignname') !== ''
                      ? <span>{getFieldValue('smsSignname')}</span>
                      : null
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label='商户标签'>
                  {
                    getFieldDecorator('label', {
                      initialValue: detailInfo.label || '',
                      rules: [
                        { required: false, message: '该项必填' }
                      ]
                    })(
                      <TextArea rows={2} placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24} style={{ paddingTop: '34px', textAlign: 'right' }}>
              <Col span={12}>
                <Button style={{ marginRight: '20px', width: '100px' }}
                  onClick={() => this.handleBack()}> 取消 </Button>

                <Button type='primary' style={{ width: '100px' }}
                  onClick={() => this.handleSubmit()} >保存 </Button>
              </Col>
            </Row>
          </Form>
        </Row>

      </Card>
    )
  }
}

const MCInformation = Form.create()(MCInformationForm)

export default MCInformation
