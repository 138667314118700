import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Icon } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import DetailModal from '@/pages/order/component/euorder-modal'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderOrderStatusLabel, renderOrderTypeLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, orderTypeMaps, orderStatusMaps } = filter

class EutradeOrder extends Component {
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {
          placeholder: '请选择...',
          allowClear: false
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '订单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderTypeMaps
        ]
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    detailModal: false,
    rowDetail: {},
    expandedRowKeys: [] // 展开的列表rowKey
  }

  columns = [
    {
      title: '序号',
      key: 'euorder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'euorder-1',
      width: 140,
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '订单编号',
      key: 'euorder-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单类型',
      key: 'euorder-3',
      width: 120,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '订单金额',
      key: 'euorder-4',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '订单状态',
      key: 'euorder-5',
      width: 100,
      dataIndex: 'orderStatus',
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    },
    {
      title: '客户名称',
      key: 'euorder-6',
      width: 150,
      ellipsis: true,
      render: (text, record, index) => (
        <div>
          <span>{record.realName}</span>
          <span>
            {
              record.enterpriseName && record.enterpriseName !== ''
                ? `/${record.enterpriseName}`
                : null
            }
          </span>
        </div>
      )
    },
    {
      title: '客户联系方式',
      key: 'euorder-7',
      width: 100,
      dataIndex: 'receiverPhoneNum'
    },
    {
      title: '客户联系地址',
      key: 'euorder-8',
      ellipsis: true,
      dataIndex: 'receiverAddress'
    },
    {
      title: '商户名称',
      key: 'euorder-9',
      ellipsis: true,
      dataIndex: 'distributorName'
    }
  ]

  componentDidMount () {
    this.getEutradeOrderList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取EU订单列表
  getEutradeOrderList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEutradeOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取EU订单-子订单列表
  getEUTradeChildOrderList = async (expanded, record) => {
    if (!expanded) { return false }
    const params = {
      orderId: record.orderId,
      skuGoodsType: record.skuGoodsType
    }
    const res = await Api.getEUTradeChildOrderList(params)
    const { code, data = [] } = res
    if (+code === 10000) {
      let { tableData = [] } = this.state
      const n = tableData.findIndex((item) => { return item.orderId === record.orderId })

      tableData[n].childOrderList = data

      this.setState({
        tableData: [...tableData]
      })
    }
  }

  // 获取订单明细
  getEuOrderDetail = (params) => {
    return Api.getEuOrderDetail(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      let orderTime = search.orderTime
      search.beginDate = orderTime[0].format('YYYY-MM-DD')
      search.endDate = orderTime[1].format('YYYY-MM-DD')

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: pages.pageSize
      },
      query: search
    }, () => {
      this.getEutradeOrderList()
    })
  }

  // 点击查看订单明细
  showEuOrderDetail = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record || {}
    })
  }

  // 点击导出EU订单
  handleDownloadEuOrder = () => {
    const { query = {} } = this.state

    DownloadApi.downloadEUTradeOrder(query)
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    let { expandedRowKeys = [] } = this.state

    this.setState({
      expandedRowKeys: expanded ? [...expandedRowKeys, record.orderId] : $lodash.pull(expandedRowKeys, record.orderId)
    }, () => {
      this.getEUTradeChildOrderList(expanded, record)
    })
  }

  // 子列表columns配置
  renderExpandRow = (row) => {
    const { orderId, childOrderList = [] } = row
    const rowColumns = [
      {
        title: '子订单',
        key: `expandRow-${orderId}-0`,
        dataIndex: 'skuGoodsType',
        render: (text, record, index) => `${orderId}-(${renderOrderTypeLabel(record.skuGoodsType)})`
      },
      {
        title: '订单金额',
        key: `expandRow-${orderId}-1`,
        dataIndex: 'orderAmount',
        render: (text, record, index) => (
          <>&yen;{ (+record.orderAmount * 100 / 100).toFixed(2) }</>
        )
      },
      {
        title: '操作',
        key: `expandRow-${orderId}-2`,
        render: (text, record, index) => (
          <>
            <Button type='link' size='small' style={{ fontSize: '12px' }}
              onClick={() => this.showEuOrderDetail(record)}>查看明细</Button>
          </>
        )
      }
    ]
    return (
      <div style={{ padding: '2px 5px' }}>
        <Table rowKey={(record, index) => `${record.orderId}-${record.skuGoodsType}-${index}`} bordered
          columns={rowColumns}
          dataSource={childOrderList || []}
          pagination={false}
        />
      </div>
    )
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEutradeOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEutradeOrderList()
    })
  }

  render () {
    const { buttonMaps = {} } = this.props
    const { tableData, pages, searchData, detailModal = false, rowDetail = {}, expandedRowKeys = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '10px' }}>
            {
              buttonMaps && buttonMaps.download && +buttonMaps.download.auth === 1
                ? <Button type='primary' onClick={this.handleDownloadEuOrder}><Icon type='download' />导出订单</Button>
                : null
            }
          </div>

          <Table rowKey='orderId' bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
            scroll={{ x: 1300 }}
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={(record) => this.renderExpandRow(record)}
            onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
          />
        </Card>

        {
          detailModal
            ? <DetailModal
              ident='EUORDER'
              detailInfo={rowDetail}
              getDetail={(params) => this.getEuOrderDetail(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(EutradeOrder)
