import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Table, InputNumber, message } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'

const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderEditCell = (form) => {
    const { getFieldDecorator } = form
    const { editing, dataIndex, title, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: '0px' }}>
            {
              getFieldDecorator(`${record.commoditySkuId}-${record.skuGoodsType}-${index}`, {

              })(
                <InputNumber size='small' max={+record.quantity} />
              )
            }
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderEditCell}</EditableContext.Consumer>
  }
}

class EditProductTable extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'productCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'productCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'productCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'productCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'productCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'productCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'productCol-6',
      editable: true,
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'productCol-7',
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    }
  ]

  componentDidMount () {
    this.getPurchOrderGoodsList()
  }

  // 获取采购订单产品列表
  getPurchOrderGoodsList = async () => {
    const { detailInfo = {} } = this.props
    const params = {
      purchaseOrderId: detailInfo.purchaseOrderId,
      distributorId: detailInfo.distributorId,
      pageNo: 1,
      pageSize: 999
    }
    const res = await Api.getPurchOrderGoodsList(params)
    const { code, data = {} } = res
    if (+code === 10000) {
      let _list = []
      if (data.orderDetail && data.orderDetail.list && data.orderDetail.list.length > 0) {
        _list = $lodash.filter(data.orderDetail.list, (record) => { return !record.isAfterSale })
      }

      this.setState({
        tableData: _list
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { tableData = [] } = this.state
        const arr = $lodash.map(values, (value, key) => {
          return {
            quantity: +value,
            _index: +key.split('-')[2]
          }
        })

        const newdatas = $lodash.filter(arr, (o) => { return +o.quantity > 0 })

        if (newdatas.length === 0) {
          return message.warning('请选择售后商品')
        }

        const selectedRows = $lodash.map(newdatas, (item) => {
          const obj = $lodash.find(tableData, (record, index) => { return index === item._index })

          return {
            ...obj,
            quantity: item.quantity,
            _index: item._index
          }
        })

        this.props.onConfirm(selectedRows)
      }
    })
  }

  render () {
    const { form, onCancel } = this.props
    const { tableData = [] } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record, i) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          index: i,
          editing: true
        })
      }
    })

    return (
      <Modal title='选择售后商品' width={1200} bodyStyle={{ padding: '16px', height: '500px', overflowY: 'auto' }}
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        onOk={() => this.handleConfirm()}
      >
        <EditableContext.Provider value={form}>
          <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
            components={components}
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </EditableContext.Provider>
      </Modal>
    )
  }
}

const ProductModal = Form.create()(EditProductTable)

export default ProductModal
