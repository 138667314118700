import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Icon } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderWorkOrderDueStatusLabel, renderWorkStatusLabel, renderWorkTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { pageSizeOptions, workOrderStateMaps, workOrderTypeMaps, enquireOverdueStatusMaps } = filter
const { MAX_PAGESIZE } = consts

class WorksBoard extends Component {
  constructor (props) {
    super(props)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'workOrderId',
        formType: 'Input',
        itemParams: {
          label: '工单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributedTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '派发日期'
        },
        cptParams: {}
      },
      {
        name: 'fromPlatformUserId',
        formType: 'Select',
        itemParams: {
          label: '发布人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 'FROMPLAT')
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'toPlatformUserId',
        formType: 'Select',
        itemParams: {
          label: '处理人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 'TOPLAT')
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'handlingTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '处理日期'
        },
        cptParams: {}
      },
      {
        name: 'dueStatus',
        formType: 'Select',
        itemParams: {
          label: '是否逾期'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...enquireOverdueStatusMaps
        ]
      },
      {
        name: 'workOrderState',
        formType: 'Select',
        itemParams: {
          label: '工单状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...workOrderStateMaps
        ]
      },
      {
        name: 'workOrderType',
        formType: 'Select',
        itemParams: {
          label: '工单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...workOrderTypeMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    activeKey: 'ALL',
    query: {},
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'workorderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '协作工单编号',
      key: 'workorderCol-1',
      dataIndex: 'workOrderId'
    },
    {
      title: '派发日期',
      key: 'workorderCol-2',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '发布人',
      key: 'workorderCol-3',
      dataIndex: 'fromPlatformUserName'
    },
    {
      title: '工单时限',
      key: 'workorderCol-4',
      dataIndex: 'limitTime',
      render: (text, record, index) => (
        <>{ record.limitTime ? moment(record.limitTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '处理时间',
      key: 'workorderCol-5',
      dataIndex: 'handlingTime',
      render: (text, record, index) => (
        <>{ record.handlingTime ? moment(record.handlingTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '处理人',
      key: 'workorderCol-6',
      dataIndex: 'toPlatformUserName'
    },
    {
      title: '是否逾期',
      key: 'workorderCol-7',
      dataIndex: 'dueStatus',
      render: (text, record, index) => (
        <>{ renderWorkOrderDueStatusLabel(record.dueStatus) }</>
      )
    },
    {
      title: '工单状态',
      key: 'workorderCol-8',
      dataIndex: 'workOrderState',
      render: (text, record, index) => (
        <>{ renderWorkStatusLabel(record.workOrderState) }</>
      )
    },
    {
      title: '工单类型',
      key: 'workorderCol-9',
      dataIndex: 'workOrderType',
      render: (text, record, index) => (
        <>{ renderWorkTypeLabel(record.workOrderType) }</>
      )
    },
    {
      title: '操作',
      key: 'workorderCol-10',
      render: (text, record, index) => (
        <>
          {
            record.operateAuthority
              ? <Button type='link' size='small' onClick={() => this.handleLinkToWorkHandle(record)}>处理工单</Button>
              : <Button type='link' size='small' onClick={() => this.handleLinkToWorkDetails(record)}>查看详情</Button>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getWorkOrderStatistics()
  }

  // 获取全部成员
  getMemberList = (val = '', type) => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        if (type === 'FROMPLAT') {
          searchData[2].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []
        }

        if (type === 'TOPLAT') {
          searchData[3].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []
        }

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取工单列表
  getWorkOrderStatistics = () => {
    const { pages, query = {} } = this.state
    const params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getWorkOrderStatistics(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.distributedTimes) {
      search['distributedDateBegin'] = moment(search.distributedTimes[0]).format('YYYY-MM-DD')
      search['distributedDateEnd'] = moment(search.distributedTimes[1]).format('YYYY-MM-DD')
      delete search.distributedTimes
    }

    if (search.handlingTimes) {
      search['handlingDateBegin'] = moment(search.handlingTimes[0]).format('YYYY-MM-DD')
      search['handlingDateEnd'] = moment(search.handlingTimes[1]).format('YYYY-MM-DD')
      delete search.handlingTimes
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getWorkOrderStatistics()
    })
  }

  // 点击导出工单
  handleDownloadWorkOrder = () => {
    const { query = {} } = this.state
    DownloadApi.downloadWorkOrderStatistics({ ...query })
  }

  // 点击跳转处理工单
  handleLinkToWorkHandle = (record) => {
    this.props.history.push({ pathname: '/admin/workHandle', state: { id: record.id, workOrderType: record.workOrderType } })
  }

  // 点击跳转查看详情
  handleLinkToWorkDetails = (record) => {
    this.props.history.push({ pathname: '/admin/workDetails', state: { id: record.id, workOrderType: record.workOrderType } })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getWorkOrderStatistics()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getWorkOrderStatistics()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handleDownloadWorkOrder()}><Icon type='download' />导出</Button>
          </div>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default WorksBoard
