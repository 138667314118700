import React, { Component } from 'react'
import { Row, Card, Table } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class SendFlow extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'sendTime',
        formType: 'RangePicker',
        itemParams: {
          label: '发送时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }

  columns = [
    {
      title: '序号',
      key: 'sendFlow-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '通知标题',
      key: 'sendFlow-2',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '通知内容',
      key: 'sendFlow-3',
      ellipsis: true,
      dataIndex: 'content'
    },
    {
      title: '发送时间',
      key: 'sendFlow-4',
      render: (text, record, index) => (
        <div>
          { record.createTime && record.sendTime !== '' ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '通知对象',
      key: 'sendFlow-5',
      dataIndex: 'notifyObject'
    }
  ]

  componentDidMount () {
    this.getSystemNotifysSendedFlow()
  }

  // 获取系统消息发送历史列表
  getSystemNotifysSendedFlow = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSystemNotifysSendedFlow(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.sendTime && search.sendTime.length === 2) {
      search['beginDate'] = moment(search.sendTime[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.sendTime[1]).format('YYYY-MM-DD')

      delete search.sendTime
    }
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getSystemNotifysSendedFlow()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSystemNotifysSendedFlow()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSystemNotifysSendedFlow()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>

            <Row>
              <Table rowKey='id' bordered
                dataSource={tableData}
                columns={this.columns}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>
      </div>
    )
  }
}

export default SendFlow
