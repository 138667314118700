import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Descriptions } from 'antd'
import filter from '@/common/utils/filter'
import { formatBankAccount, renderAddressLabel } from '@/common/utils/mUtils'

import styles from './apply.module.scss'

const { cardLevelMaps } = filter

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {

  }

  componentDidMount () {

  }

  // 渲染团队规模
  renderCardLevelName = (id) => {
    const obj = cardLevelMaps.find(item => { return item.id === id })
    const label = obj !== undefined ? obj.name : ''

    return label
  }

  render () {
    const { detailInfo = {} } = this.props

    return (
      <div>
        <Modal bodyStyle={{ padding: '12px' }}
          title='详细信息'
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.props.onCancel}
        >
          <Row>
            <h4 className={`${styles.title}`}>
              {detailInfo.companyName || '--'}
            </h4>
          </Row>
          <Row>
            <Row>
              <Descriptions>
                <Descriptions.Item label='统一社会信用代码'>
                  {
                    detailInfo.taxNo && detailInfo.taxNo !== ''
                      ? <div>{detailInfo.taxNo}</div>
                      : <div style={{ color: '#ff3434' }}>未填写</div>
                  }
                </Descriptions.Item>
              </Descriptions>
            </Row>
            <Row>
              <Descriptions>
                <Descriptions.Item label='电话号码'>
                  {
                    detailInfo.tel && detailInfo.tel !== ''
                      ? <div>{detailInfo.tel}</div>
                      : <div style={{ color: '#ff3434' }}>未填写</div>
                  }
                </Descriptions.Item>
                <Descriptions.Item label='团队规模'>
                  {
                    detailInfo.staffNumber && detailInfo.staffNumber > 0
                      ? <div>{this.renderCardLevelName(detailInfo.staffNumber)}</div>
                      : <div style={{ color: '#ff3434' }}>未填写</div>
                  }
                </Descriptions.Item>
                <Descriptions.Item label='所在地区'>
                  {
                    detailInfo.province
                      ? <div>{renderAddressLabel(detailInfo.province, detailInfo.city, detailInfo.county)}</div>
                      : <div style={{ color: '#ff3434' }}>未填写</div>
                  }
                </Descriptions.Item>
                <Descriptions.Item label='详细地址'>
                  {
                    detailInfo.address && detailInfo.address !== ''
                      ? <div>{detailInfo.address}</div>
                      : <div style={{ color: '#ff3434' }}>未填写</div>
                  }
                </Descriptions.Item>
              </Descriptions>
            </Row>
            <Row>
              <Descriptions>
                <Descriptions.Item label='开户银行'>
                  {
                    detailInfo.accountBank && detailInfo.accountBank !== ''
                      ? <div>{detailInfo.accountBank}</div>
                      : <div style={{ color: '#ff3434' }}>未填写</div>
                  }
                </Descriptions.Item>
                <Descriptions.Item label='银行账号'>
                  {
                    detailInfo.account && detailInfo.account !== ''
                      ? <div>{formatBankAccount(detailInfo.account)}</div>
                      : <div style={{ color: '#ff3434' }}>未填写</div>
                  }

                </Descriptions.Item>
              </Descriptions>
            </Row>
          </Row>
          <Row>
            <Row className={`${styles.label}`}>法人身份证图片</Row>
            <Row>
              <div className={`${styles.pictureWrapper}`}>
                {
                  detailInfo.legalCardEmblemImg && detailInfo.legalCardEmblemImg !== ''
                    ? <div className={`${styles.picItem}`}>
                      <img src={detailInfo.legalCardEmblemImg} />
                    </div>
                    : <div className={`${styles.picItem} ${styles.place}`}>
                      <div>
                      暂无身份证照片
                      </div>
                    </div>
                }

                {
                  detailInfo.legalCardInfoImg && detailInfo.legalCardInfoImg !== ''
                    ? <div className={`${styles.picItem}`}>
                      <img src={detailInfo.legalCardInfoImg} />
                    </div>
                    : <div className={`${styles.picItem} ${styles.place}`}>
                      <div>
                      暂无身份证照片
                      </div>
                    </div>
                }

              </div>
            </Row>
          </Row>
        </Modal>
      </div>
    )
  }
}
