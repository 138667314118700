import Ajax from '../utils/ajax'

export default {
  // 用户登录
  login (params) {
    return Ajax.post('/platformUser/login', params)
  },

  // 退出登录
  loginout (params) {
    return Ajax.get('/platformUser/logout', { params })
  },

  // 获取登录用户信息
  getUserInfo (params) {
    return Ajax.post('/platformUser/userInfo', params)
  },

  // 获取用户菜单
  getUserMenus (params) {
    return Ajax.get('/platformUser/userMenuTree', { params })
  },

  // 获取短信验证码
  sendMessageCode (params) {
    return Ajax.get('/platformUser/sendSmsCaptcha', { params })
  },

  // 获取用户是否有支付密码
  getHasPayPassword (params) {
    return Ajax.get('/platformUser/getPayPwdIsEmpty', { params })
  },

  // 平台用户修改密码
  saveUserModifyPassword (params) {
    return Ajax.post('/platformUser/modifyPassword', params)
  },

  // 用户设置支付密码
  saveAddPayword (params) {
    return Ajax.post('/platformUser/paidPwdAdd', params)
  },

  // 用户修改支付密码
  saveModifyPayword (params) {
    return Ajax.post('/platformUser/userPaidPwdEdit', params)
  },

  // 保存用户列表项配置
  saveUserTableColumnSetting (params) {
    return Ajax.post('/oms/user/system/setting/addOrUpdate', params)
  }
}
