import Ajax from '../utils/ajax'

export default {
  // EXCEL智能解析
  saveUploadExcelMatchSmartparse (params) {
    return Ajax.post('/api/excel/import/smart/parse', params)
  },

  // 获取EXCEL解析数据
  getUploadExcelAnalysisdatas (params) {
    return Ajax.post('/api/product/info/management/list/page/analysis/data', params)
  },

  // EXCEL解析数据匹配
  saveUploadExcelAnalysisMatch (params) {
    return Ajax.post('/api/product/info/management/match', params)
  },

  // 获取EXCEL解析数据匹配结果
  getExcelAnalysisMatchResult (params) {
    return Ajax.post('/api/product/info/management/get/status', params)
  },

  // 获取EXCEL解析数据匹配详情
  getExcelAnalysisMatchDetails (params) {
    return Ajax.post('/api/product/info/management/list/page/match/info', params)
  },

  // 保存导入的数据
  saveExcelEnquireSpecialMatchProducts (params) {
    return Ajax.post('/api/product/info/management/save', params)
  }
}
