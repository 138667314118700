import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Descriptions, Modal, Button, Icon, message } from 'antd'

import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'

import styles from './address.module.scss'

const { confirm } = Modal
const MAXNUM = 10 // 收票地址最大数量

class AddressList extends Component {
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    tableData: [],
    type: 1,
    editModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    this.getReceivingAddressList()
  }

  // 获取收件地址
  getReceivingAddressList = () => {
    Api.getReceivingAddressList({ pageNo: 1, pageSize: 10 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  // 保存新增收件地址
  saveAddReceivingAddress = (params) => {
    Api.saveAddReceivingAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getReceivingAddressList()
      }
    })
  }

  // 保存编辑收件地址
  saveEditReceivingAddress = (params) => {
    Api.saveEditReceivingAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getReceivingAddressList()
      }
    })
  }

  // 删除收票地址
  deleteReceivingAddress = (params) => {
    Api.deleteReceivingAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getReceivingAddressList()
      }
    })
  }

  // 点击提交地址信息
  handleSubmitAddress = (values) => {
    const { type } = this.state
    const params = {
      ...values,
      receiver: values.name,
      phoneNumber: values.mobile
    }

    if (type === 1) {
      this.saveAddReceivingAddress(params)
    } else {
      this.saveEditReceivingAddress(params)
    }
  }

  // 点击新增/编辑
  handleShowEditModal = (record = {}, type) => {
    this.setState({
      type: type,
      editModal: true,
      rowDetail: { ...record, name: record.receiver, mobile: record.phoneNumber }
    })
  }

  // 点击删除地址
  handleDeleteAddress = (record) => {
    confirm({
      title: '确定删除该地址？',
      content: '',
      onOk: () => {
        this.deleteReceivingAddress({ id: record.id })
      }
    })
  }

  closeModal = () => {
    this.setState({
      type: 1,
      editModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { buttonMaps } = this.props
    const { tableData = [], editModal = false, type = 1, rowDetail = {} } = this.state

    return (
      <div>
        <Row>
          <Card title='收票地址' bodyStyle={{ padding: '12px' }}>
            {
              buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                ? <Row style={{ marginBottom: '16px' }}>
                  <Button type='primary'
                    disabled={tableData.length === MAXNUM}
                    onClick={() => this.handleShowEditModal({}, 1)}
                  >
                    <Icon type='plus' />新增收票地址
                  </Button>

                  <span style={{ marginLeft: '20px' }}>
                    已创建{tableData.length}个收票地址，最多可创建{MAXNUM}个
                  </span>
                </Row>
                : null
            }

            <Row>
              {
                tableData.map((item, index) => (
                  <Row key={`address-${item.id}`}
                    className={`${styles.addressRow}`}
                  >
                    <Row>
                      <Descriptions title={item.receiver}>
                        <Descriptions.Item label='收件人'>{item.receiver}</Descriptions.Item>
                        <Descriptions.Item label='手机号码'>
                          {item.phoneNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label='所在地区'>
                          {renderAddressLabel(item.province, item.city, item.county)}
                        </Descriptions.Item>
                        <Descriptions.Item label='详细地址'>
                          {item.address}
                        </Descriptions.Item>
                      </Descriptions>
                      <Row className={`${styles.extraRow}`}>
                        {
                          buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                            ? <a className={`${styles.extraItem}`}
                              onClick={() => this.handleShowEditModal(item, 2)}
                            > 编辑</a>
                            : null
                        }

                        {
                          buttonMaps && buttonMaps['delete'] && +buttonMaps['delete'].auth === 1
                            ? <a className={`${styles.extraItem}`}
                              onClick={() => this.handleDeleteAddress(item)}
                            >删除</a>
                            : null
                        }

                      </Row>
                    </Row>
                  </Row>
                ))
              }
            </Row>
          </Card>
        </Row>

        {
          editModal
            ? <AddressModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleSubmitAddress(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(AddressList)
