import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Button, Switch, Table, Modal, message } from 'antd'
import moment from 'moment'

import RactModal from '../component/contractpl-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

class ContractplList extends Component {
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    type: 1,
    ractModal: false,
    rowDetail: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      width: 44,
      key: 'contractTemp-0',
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '模板名称',
      key: 'contractTemp-1',
      dataIndex: 'atName'
    },
    {
      title: '合同类型',
      key: 'contractTemp-3',
      render: (text, record, index) => (
        <div>
          {
            +record.atType === 1
              ? '客户模板'
              : '通用模板'
          }
        </div>
      )
    },
    {
      title: '客户名称',
      key: 'contractTemp-4',
      dataIndex: 'distributorName'
    },
    {
      title: '添加时间',
      key: 'contractTemp-5',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '是否启用',
      key: 'contractTemp-6',
      render: (text, record, index) => {
        const { buttonMaps = {} } = this.props

        return (
          <div>
            {
              buttonMaps['update'] && +buttonMaps['update'].auth === 1
                ? <Switch size='small'
                  checked={+record.disableStatus === 0}
                  checkedChildren={'启用'}
                  unCheckedChildren={'禁用'}
                  onChange={(checked) => this.handleChangeSwitch(checked, record)}
                />
                : <span>
                  {
                    +record.disableStatus === 0
                      ? '启用'
                      : '禁用'
                  }
                </span>
            }

          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'contractTemp-7',
      render: (text, record, index) => {
        const { buttonMaps = {} } = this.props

        return (
          <div>
            {
              buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <Button type='primary' size='small'
                  style={{ marginRight: '5px' }}
                  onClick={() => this.showEditTempModal(record, 2)}
                >
                  编辑
                </Button>
                : null
            }

            {
              buttonMaps['delete'] && +buttonMaps['delete'].auth === 1
                ? <Button type='primary' size='small'
                  onClick={() => this.handleDeleteRow(record)}
                >
                  删除
                </Button>
                : null
            }
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getContractTemplList()
  }

  // 获取合同模板列表
  getContractTemplList = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getContractTemplList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 删除合同模板
  saveDeleteContract = (params) => {
    Api.deleteContractTemp(params).then(res => {
      const { code } = res

      if (+code === 10000) {
        message.success('操作成功')
        this.getContractTemplList()
      }
    })
  }

  // 保存新增模板
  saveAddContractTemp = (params) => {
    Api.saveAddContractTemp(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getContractTemplList()
      }
    })
  }

  // 保存编辑模板
  saveEditContractTemp = (params) => {
    Api.saveEditContractTemp(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getContractTemplList()
      }
    })
  }

  // 保存合同模板
  handleSaveContractTemp = (params) => {
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveAddContractTemp(params)
    } else {
      this.saveEditContractTemp(params)
    }
  }

  // 点击删除
  handleDeleteRow = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        let params = {
          agreementId: record.agreementId,
          delFlag: 1
        }

        this.saveDeleteContract(params)
      }
    })
  }

  // 切换是否启用
  handleChangeSwitch = (checked, record) => {
    let params = {
      disableStatus: checked ? 0 : 1,
      agreementId: record.agreementId
    }

    Api.updateContractTempStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getContractTemplList()
      }
    })
  }

  // 新增/编辑合同模板
  showEditTempModal = (record, type) => {
    this.setState({
      ractModal: true,
      type: type,
      rowDetail: record || {}
    })
  }

  closeModal = () => {
    this.setState({
      ractModal: false,
      rowDetail: {},
      type: 1
    })
  }

  render () {
    const { buttonMaps = {} } = this.props
    const { tableData, pages, ractModal = false, type = 1, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '12px' }}>
          {
            buttonMaps['add'] && +buttonMaps['add'].auth === 1
              ? <Row style={{ marginBottom: '10px' }}>
                <Button type='primary' icon='plus'
                  onClick={() => this.showEditTempModal({}, 1)}
                >
                  新增模板
                </Button>
              </Row>
              : null
          }

          <Row>
            <Table rowKey='agreementId' bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Row>
        </Card>

        {
          ractModal
            ? <RactModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleSaveContractTemp(params)}
              onCancel={this.closeModal}
            />
            : null

        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(ContractplList)
