
import {
  SET_USER_INFO,
  SET_USER_MENU,
  SET_AUTHOR_BUTTONMAPS,
  SET_UNNOTICE_COUNT,
  SET_NOTICE_DETAIL,
  SET_PAGE_LOADING,
  SET_EDITENQUIRE_DETAILS,
  SET_TABLECOLUMN_KEYS
} from './action'

export default function counter (state = {}, action) {
  switch (action.type) {
    case SET_USER_INFO:
      return Object.assign({}, state, action.data)
    case SET_USER_MENU:
      return Object.assign([], state, action.data)
    case SET_AUTHOR_BUTTONMAPS:
      return Object.assign({}, state, action.data)
    case SET_UNNOTICE_COUNT:
      return Object.assign({}, state, action.data)
    case SET_NOTICE_DETAIL:
      return Object.assign({}, state, action.data)
    case SET_PAGE_LOADING:
      return Object.assign({}, state, action.data)
    case SET_EDITENQUIRE_DETAILS:
      return Object.assign({}, state, action.data)
    case SET_TABLECOLUMN_KEYS:
      return Object.assign({}, state, action.data)
    default:
      return state
  }
}
