import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert, Statistic, Row, Col } from 'antd'

import Api from '@/common/api/index'

export default function CloudStatistic (props) {
  const { brandId = 0, cloudType } = props
  const [statisticData, setStatisticData] = useState({ totalAmount: 0, brandAmount: 0, skuCount: 0 })

  // 获取云库统计信息
  const getCloudSkuStatisticsInfo = async () => {
    const res = await Api.getCloudSkuStatisticsInfo({ brandId: brandId, cloudType: cloudType })
    const { code, data = {} } = res
    if (+code === 10000) {
      setStatisticData({
        totalAmount: data.totalAmount || 0,
        brandAmount: data.brandTotalAmount || 0,
        skuCount: data.brandSkuTotal || 0
      })
    }
  }

  useEffect(() => {
    getCloudSkuStatisticsInfo()
  }, [brandId, cloudType])

  return (
    <>
      <Alert type='info' message={<Row gutter={24}>
        <Col span={4}>
          <Statistic title='库存总金额' value={+statisticData.totalAmount}
            precision={2}
            prefix={<span>&yen;</span>}
            valueStyle={{ color: '#52C41A' }}
          />
        </Col>
        <Col span={4}>
          <Statistic title='品牌价值' value={+statisticData.brandAmount}
            precision={2}
            prefix={<span>&yen;</span>}
            valueStyle={{ color: '#52C41A' }}
          />
        </Col>
        <Col span={4}>
          <Statistic title='品牌商品数' value={+statisticData.skuCount}
            valueStyle={{ color: '#52C41A' }}
            suffix={<span>个</span>}
          />
        </Col>
      </Row>} />
    </>
  )
}

CloudStatistic.propTypes = {
  cloudType: PropTypes.string,
  brandId: PropTypes.number
}
