import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table, Row, Col, Icon, Tooltip, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import OrderDetailsReceiving from '../orderDetailsReceiving/index'
import ColumnsConfigModal from '@/components/columnsConfigModal' // 列设置弹窗

import Api from '@/common/api/index'
import { setTableColumnKeys } from '@/reducers/action'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

const columnConfigKey = 'DETAILS_TABLE'

// 订单详情-产品明细
class OrderProductDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    userInfo: PropTypes.object,
    columnsMap: PropTypes.object,
    ident: PropTypes.string, // 组件标识
    detailInfo: PropTypes.object,
    getProductDetail: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    columnsConfigModal: false // 列设置弹窗
  }

  baseColumns = [
    {
      title: '序号',
      key: 'column-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'orderProductDetailsCol-brandName',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'orderProductDetailsCol-model',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'orderProductDetailsCol-itemNumber',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'orderProductDetailsCol-name',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>
            <span>{ record.name }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '单位',
      key: 'orderProductDetailsCol-unit',
      dataIndex: 'unit'
    },
    {
      title: '产品类型',
      key: 'orderProductDetailsCol-skuGoodsType',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '订单数量',
      key: 'orderProductDetailsCol-quantity',
      dataIndex: 'quantity'
    },
    {
      title: '已订货数量',
      key: 'orderProductDetailsCol-arrivalQuantity',
      dataIndex: 'arrivalQuantity'
    },
    {
      title: '已发货数量',
      key: 'orderProductDetailsCol-deliveryQuantity',
      dataIndex: 'deliveryQuantity'
    },
    {
      title: '未发货数量',
      key: 'orderProductDetailsCol-undeliveryQuantity',
      dataIndex: 'undeliveryQuantity',
      render: (text, record, index) => (
        <>{ +record.quantity < 0 ? null : record.quantity - record.deliveryQuantity }</>
      )
    },
    {
      title: '货期',
      key: 'orderProductDetailsCol-deliveryPeriodDays',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>
          {
            record.skuGoodsType === 'CLOUD_FUTURES'
              ? <>{ record.deliveryPeriodDays ? `${record.deliveryPeriodDays}个工作日` : '当天发货' }</>
              : '当天发货'
          }
        </>
      )
    },
    {
      title: '交货日期',
      key: 'orderProductDetailsCol-deliveryPeriodTime',
      dataIndex: 'deliveryPeriodTime',
      render: (text, record, index) => (
        <>{ record.deliveryPeriodTime ? moment(record.deliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '预计发货日期',
      key: 'orderProductDetailsCol-expectDeliveryPeriodTime',
      dataIndex: 'expectDeliveryPeriodTime',
      render: (text, record, index) => (
        <>{ record.expectDeliveryPeriodTime ? moment(record.expectDeliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '实际发货日期',
      key: 'orderProductDetailsCol-actualDeliveryPeriodTime',
      dataIndex: 'actualDeliveryPeriodTime',
      render: (text, record, index) => (
        <>{ record.actualDeliveryPeriodTime ? moment(record.actualDeliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '预付比例',
      key: 'orderProductDetailsCol-prepaymentRatio',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => (
        <>{ record.prepaymentRatio ? <>{ (+record.prepaymentRatio * 100 / 100).toFixed(2) }%</> : null }</>
      )
    },
    {
      title: '未税单价',
      key: 'orderProductDetailsCol-excludingTaxUnitPrice',
      dataIndex: 'excludingTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.excludingTaxUnitPrice }</>
      )
    },
    {
      title: '含税单价',
      key: 'orderProductDetailsCol-taxUnitPrice',
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice }</>
      )
    },
    {
      title: '含税单品小计',
      key: 'orderProductDetailsCol-totalTaxUnitPrice',
      dataIndex: 'totalTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{($lodash.round(+record.totalTaxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    this.getProducDetailsByOrderId()
  }

  // 获取订单产品详情
  getProducDetailsByOrderId = async () => {
    const { ident, detailInfo = {}, getProductDetail } = this.props
    const { pages } = this.state
    const params = {
      orderId: ['EUORDER', 'SUPPLY_ORDER'].includes(ident) ? detailInfo.orderId : undefined,
      skuGoodsType: ident === 'EUORDER' ? detailInfo.skuGoodsType : undefined,
      purchaseOrderId: ident === 'PURCHASE_ORDER' ? detailInfo.purchaseOrderId : undefined,
      distributorId: ident === 'PURCHASE_ORDER' ? detailInfo.distributorId : undefined,
      pageNo: 1,
      pageSize: 99999
    }

    const res = await getProductDetail(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.orderDetail.list || [],
        pages: {
          ...pages,
          total: data.orderDetail.total || 0
        }
      })
    }
  }

  // 保存列表项配置
  saveUserTableColumnSetting = async (params, newcolumnsMap) => {
    const res = await Api.saveUserTableColumnSetting(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.props.dispatch(setTableColumnKeys({ columnsMap: newcolumnsMap }))

      this.closeModal()
    } else {
      message.warning(res.message)
    }
  }

  // 点击列设置
  showColumnsConfigModal = () => {
    this.setState({
      columnsConfigModal: true
    })
  }

  // 确定保存列表项配置
  handleConfirmTableColumnSetting = (selectedKeys, newcolumns) => {
    const { userInfo = {}, columnsMap = {}, ident } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [`${ident}_${columnConfigKey}`]: {
        selectedColumnKeys: [...selectedKeys],
        sortColumnKeys: [...newcolumns]
      }
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  // 确定重置列表项配置
  handleResetTableColumnSetting = () => {
    const { userInfo = {}, columnsMap = {}, ident } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [`${ident}_${columnConfigKey}`]: null
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  closeModal = () => {
    this.setState({
      columnsConfigModal: false
    })
  }

  render () {
    const { columnsMap = {}, ident, detailInfo = {} } = this.props
    const { tableData = [], columnsConfigModal = false } = this.state

    /**
     * 获取redux缓存里面的相关页面的列展示信息
     *   sortColumnKeys: 排序后的所有列的key selectedColumnKeys:要展示的列的key
     * 1- 首先将baseColumns列顺序按 sortColumnKeys 里面的顺序排序
     * 2- 将baseColumns过滤 保留包含在 selectedColumnKeys中的key
     */
    const sortColumnKeys = columnsMap[`${ident}_${columnConfigKey}`] && columnsMap[`${ident}_${columnConfigKey}`].sortColumnKeys ? columnsMap[`${ident}_${columnConfigKey}`].sortColumnKeys : []
    const selectedColumnKeys = columnsMap[`${ident}_${columnConfigKey}`] && columnsMap[`${ident}_${columnConfigKey}`].selectedColumnKeys ? columnsMap[`${ident}_${columnConfigKey}`].selectedColumnKeys : []

    const newArr = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : this.baseColumns

    // 列表展示的项
    const tableColumns = selectedColumnKeys.length > 0 ? $lodash.filter(newArr, (record) => { return $lodash.includes(selectedColumnKeys, record.key) }) : newArr

    const _baseColumns = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : $lodash.map(this.baseColumns, (record) => { return { key: record.key, title: record.title } })

    const columnsConfig = $lodash.filter(_baseColumns, (o) => { return !$lodash.includes(['column-0', 'column-operations'], o.key) })

    const checkedColKeys = columnsMap[`${ident}_${columnConfigKey}`] && columnsMap[`${ident}_${columnConfigKey}`].selectedColumnKeys
      ? columnsMap[`${ident}_${columnConfigKey}`].selectedColumnKeys
      : $lodash.map(columnsConfig, (o) => { return o.key })

    return (
      <>
        <OrderDetailsReceiving ident={ident} detailInfo={detailInfo} />
        <Row style={{ marginBottom: '12px' }}>
          <Col span={8} offset={16} style={{ textAlign: 'right' }}>
            <Tooltip title='列设置'>
              <a style={{ display: 'inline-block', padding: '0px 6px', lineHeight: '32px' }} onClick={() => this.showColumnsConfigModal()}>
                <Icon type='setting' style={{ fontSize: '20px' }} />
              </a>
            </Tooltip>
          </Col>
        </Row>
        <Table bordered rowKey={(record, index) => `${record.commoditySkuid}-${index}`}
          columns={tableColumns}
          dataSource={tableData}
          rowClassName={(record, index) => (+record.quantity < 0) || (+record.quantity === 0) ? 'redColor' : ''}
          pagination={false}
          scroll={{ x: 2400, y: 400 }}
        />

        { // 列设置弹窗
          columnsConfigModal
            ? <ColumnsConfigModal
              configKey={`${ident}_${columnConfigKey}`}
              selectedColumnKeys={checkedColKeys}
              disabledBeforeColumns={[{ key: 'column-0', title: '序号' }]}
              allcolumns={columnsConfig}
              onConfirm={(checkedKeys, columns) => this.handleConfirmTableColumnSetting(checkedKeys, columns)}
              onReset={() => this.handleResetTableColumnSetting()}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    columnsMap: state.columnsMap || {}
  }
}

export default connect(mapStateToProps)(OrderProductDetails)
