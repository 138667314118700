import React, { Component } from 'react'
import { Card, Row, Table, Badge, message, Modal, Pagination, Tooltip } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import styles from './setspecial.module.scss'

const { confirm } = Modal
const { pageSizeOptions, repLineBreak, skuGoodsTypeMaps } = filter
const skuGoodsTypeOptions = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

const specialPriceOverdueStatusMaps = [
  { type: 'ALL', label: '全部' },
  { type: 'ENABLE', label: '使用中' },
  { type: 'DISABLE', label: '已作废' }
]

class SetspecialFlow extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请选择商户名称...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [
          { id: '', distributorName: '全部' }
        ],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'overTime',
        formType: 'RangePicker',
        itemParams: {
          label: '开始日期'
        }
      },
      {
        name: 'enableStatus',
        formType: 'Select',
        itemParams: {
          label: '特价状态'
        },
        options: specialPriceOverdueStatusMaps,
        optionValue: ['type', 'label']
      },
      {
        name: 'paramStr',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号/订货号...',
          rows: 4
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...skuGoodsTypeOptions
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {} // 检索参数
  }
  columns = [
    {
      title: '序号',
      key: 'specialFlowCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'specialFlowCol-1',
      dataIndex: 'distributorName'
    },
    {
      title: '品牌',
      key: 'specialFlowCol-2',
      ellipsis: true,
      dataIndex: 'brandName',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.brandName}>{ record.brandName }</Tooltip>
        </>
      )
    },
    {
      title: '型号/订货号',
      key: 'specialFlowCol-3',
      width: 200,
      render: (text, record, index) => (
        <div>
          <Row className={`${styles.tableRow}`}>
            <label className={`${styles.rowLabel}`}>型<em style={{ visibility: 'hidden' }}>型</em>号</label>
            <span title={record.model}>{record.model}</span>
          </Row>
          <Row className={`${styles.tableRow}`}>
            <label className={`${styles.rowLabel}`}>订货号</label>
            <span title={record.itemNumber}>{record.itemNumber}</span>
          </Row>
        </div>
      )
    },
    {
      title: '产品类型',
      key: 'specialFlowCol-4',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '产品名称',
      key: 'specialFlowCol-5',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>{ record.name }</Tooltip>
        </>
      )
    },
    {
      title: '云特价',
      key: 'specialFlowCol-6',
      dataIndex: 'specialPrice',
      render: (text, record, index) => (
        <>
          {
            record.enableStatus !== 'DISABLE'
              ? <p>&yen;{+record.specialPrice}</p>
              : <p style={{ color: 'red' }}>&yen;{+record.specialPrice}</p>
          }
        </>
      )
    },
    {
      title: '特价状态',
      key: 'specialFlowCol-7',
      render: (text, record, index) => (
        <div>
          {
            record.enableStatus !== 'DISABLE'
              ? <Badge status='success' text='使用中' />
              : <Badge status='error' text='已作废' />
          }
        </div>
      )
    },
    {
      title: '开始日期',
      key: 'specialFlowCol-8',
      render: (text, record, index) => (
        <>
          { record.startTime ? moment(record.startTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '作废日期',
      key: 'specialFlowCol-9',
      render: (text, record, index) => (
        <>
          { record.disableTime ? moment(record.disableTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },

    {
      title: '操作',
      key: 'specialFlowCol-10',
      render: (text, record, index) => (
        <>
          {
            record.enableStatus !== 'DISABLE'
              ? <a onClick={() => this.handleStopSpecial(record)}>终止</a>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    this.newgetSetSpecialPriceFlow()
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data = [] } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取处理特价记录
  newgetSetSpecialPriceFlow = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.newgetSetSpecialPriceFlow(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 终止云特价
  setEndCloud = (params) => {
    Api.setEndCloud(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.newgetSetSpecialPriceFlow()
      }
    })
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages } = this.state
    if (search.paramStr && search.paramStr !== '') {
      search['paramStr'] = repLineBreak(search.paramStr, '<br>')
    }
    if (search.overTime && search.overTime.length > 0) {
      search.beginDate = search.overTime[0].format('YYYY-MM-DD')
      search.endDate = search.overTime[1].format('YYYY-MM-DD')
    }
    delete search.overTime
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.newgetSetSpecialPriceFlow())
  }

  // 点击终止操作
  handleStopSpecial = (record) => {
    confirm({
      title: '确定终止此云特价？',
      onOk: () => {
        this.setEndCloud({ specialInfoId: record.id, skuGoodsType: record.skuGoodsType })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.newgetSetSpecialPriceFlow()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.newgetSetSpecialPriceFlow()
    })
  }

  render () {
    const { pages, tableData = [], searchData } = this.state
    const pagination = {
      // showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }} title='云特价设置记录'>
          <Row style={{ marginBottom: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>

          <Row>
            <Table rowKey={(record, index) => `flow-${record.commoditySkuId}-${record.skuGoodsType}-${index}`}
              bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={false}
            />

            <div style={{ margin: '16px 0px', textAlign: 'right' }}>
              <Pagination {...pagination} />
            </div>
          </Row>
        </Card>
      </>
    )
  }
}

export default SetspecialFlow
