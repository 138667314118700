import React, { Component } from 'react'
import { Card, Row, Divider, Button } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'
import * as echarts from 'echarts'

import SearchForm from '@/components/search-form/index'
import CloudStoreTable from './cloudStoreTable'

import Request from '@/common/api/dashboardApi'
import filter from '@/common/utils/filter'

import { getYearOptions } from '@/common/utils/mUtils'
import { cloudTableRows, quarterOptions } from './localdatas'
import styles from './index.module.scss'
import { Icon } from './../js/Icon' // 伸缩展开icon

const yearOptions = getYearOptions(moment().get('year') - 2019 + 1)
const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')
const yLen = YEAR - 2019 + 1
const colLen = (YEAR - 2019 + 1) * 17 + 1

export default class CloudStoreReport extends Component {
  static propTypes = {

  }
  state = {
    faly: 0,
    searchData: [
      {
        name: 'year',
        formType: 'Select',
        itemParams: {
          label: '查询年份'
        },
        cptParams: {
          placeholder: '请选择查询年份...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { id: 0, name: '全部' },
          ...yearOptions
        ]
      },
      {
        name: 'quarter',
        formType: 'Select',
        itemParams: {
          label: '查询季度'
        },
        cptParams: {
          placeholder: '请选择查询季度...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { id: 0, name: '全部' },
          ...quarterOptions
        ]
      },
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月份'
        },
        cptParams: {
          placeholder: '请选择查询月份...'
        },
        rules: {
          initialValue: 0
        },
        options: [
          { month: 0, monthLabel: '全部' },
          ...fullsMonthOptions
        ],
        optionValue: ['month', 'monthLabel']
      }
    ],
    query: {
      year: 0
    },
    tableData: []
  }

  componentDidMount () {
    this.getDashboardAnalyse()
  }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const res = await Request.getDashboardAnalyse({ type: 'big_table' })
    let resObj = this.setCamelCaseKey(res)
    let result = {}

    $lodash.forEach(resObj, (values, key) => {
      let arr = $lodash.map(values, (row) => {
        let len = row.length
        let _month = $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? +row[0].split('-')[1] : null
        let obj = {
          year: $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? row[0].split('-')[0] : `${row[0]}`,
          quarter: len === 3 ? row[1] : _month ? this.setQuarterByMonth(_month) : null,
          month: _month,
          value: len === 3 ? row[2] : row[1],
          keyword: key
        }
        return obj
      })

      result[key] = $lodash.groupBy($lodash.orderBy(arr, ['month'], 'asc'), 'year')
    })

    this.setState({
      originData: result
    }, () => {
      this.initECharts()
      this.setCloudStoreTableData()
    })
  }

  // 初始化图表
  initECharts = () => {
    let myChart = echarts.init(document.getElementById('cloudNestedPiesChart'))
    let series = this.setNestedPiesData()

    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `全部年份趋势图`
      },
      tooltip: {
        trigger: 'item',
        borderColor: '#fff',
        // formatter: '{a} <br/>{b}: {c} ({d}%)',
        formatter: (e) => {
          return this.gettitle(e)
        }
      },
      legend: {
        data: [
          '云仓平台订单金额',
          '私域商城订单金额',
          '服务商',
          '经销商',
          '最终厂商'
        ]
      },
      series: [
        {
          name: '',
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '30%'],
          label: {
            position: 'inner',
            fontSize: 14
          },
          labelLine: {
            show: false
          },
          data: series['peiSeries']
        },
        {
          name: '',
          type: 'pie',
          radius: ['45%', '60%'],
          labelLine: {
            length: 30
          },
          label: {
            show: true
          },
          data: series['annularSeries']
        }
      ]
    })
  }

  // 设置图形数据
  setNestedPiesData = () => {
    const { originData = {}, query = {} } = this.state
    const { gmvAmountCloud = {}, gmvAmountPrivateDomains = {}, accumulativeAgentNum = {}, accumulativeServiceProviderNum = {} } = originData
    let result = {}
    let gmvPrivateValues = []
    let gmvCloudValues = []
    let accAgentValues = [] // 累计经销商
    let accServiceProviderValues = [] // 累计服务商

    if (+query.year === 0) {
      for (let n = 0; n < yLen; n++) {
        let _word = YEAR - n
        gmvPrivateValues = $lodash.assign(gmvPrivateValues, gmvAmountPrivateDomains[`${_word}`])
        gmvCloudValues = $lodash.assign(gmvCloudValues, gmvAmountCloud[`${_word}`])
        accAgentValues = $lodash.assign(accAgentValues, accumulativeAgentNum[`${_word}`])
        accServiceProviderValues = $lodash.assign(accServiceProviderValues, accumulativeServiceProviderNum[`${_word}`])
      }
    } else {
      gmvPrivateValues = [...gmvAmountPrivateDomains[`${query.year}`]]
      gmvCloudValues = [...gmvAmountCloud[`${query.year}`]]
      accAgentValues = [...accumulativeAgentNum[`${query.year}`]]
      accServiceProviderValues = [...accumulativeServiceProviderNum[`${query.year}`]]
    }

    let gmvArr = [
      { name: '私域商城订单金额', value: gmvPrivateValues.length > 0 ? `${($lodash.sumBy(gmvPrivateValues, 'value') * 100 / 100).toFixed(2)}` : 0 },
      { name: '云仓平台订单金额', value: gmvCloudValues.length > 0 ? `${($lodash.sumBy(gmvCloudValues, 'value') * 100 / 100).toFixed(2)}` : 0 }
    ]

    let peiSeriesArr = [
      { name: '最终厂商', value: 0 },
      { name: '服务商', value: accServiceProviderValues.length > 0 ? accServiceProviderValues[accServiceProviderValues.length - 1].value : 0 },
      { name: '经销商', value: accAgentValues.length > 0 ? accAgentValues[accAgentValues.length - 1].value : 0 }
    ]

    result['annularSeries'] = gmvArr
    result['peiSeries'] = peiSeriesArr

    return result
  }

  // 设置云仓表格数据
  setCloudStoreTableData = () => {
    const { originData } = this.state
    let tableData = []

    cloudTableRows.map((row) => {
      let obj = $lodash.assign({}, row)

      for (let i = 0; i < colLen; i++) {
        for (let n = 0; n < yLen; n++) {
          let _word = YEAR - n
          const { accumulativeMerchants = {}, cloudActivatedServiceProviderNumYear = {}, cloudActivatedServiceProviderNumQuarter = {}, cloudActivatedServiceProviderNumMonth = {} } = originData
          const cloudProviderYearValues = cloudActivatedServiceProviderNumYear[`${_word}`] || []
          const cloudProviderQuarterValues = cloudActivatedServiceProviderNumQuarter[`${_word}`] || []
          const cloudProviderMonthValues = cloudActivatedServiceProviderNumMonth[`${_word}`] || []

          switch (row.key) {
            case 'cloudActivatedServiceProviderNum':
              obj[`value-${_word}`] = $lodash.sumBy(cloudProviderYearValues, 'value')

              for (let q = 0; q < 4; q++) { // 季度字段
                let k = $lodash.findIndex(cloudProviderQuarterValues, (o) => { return o.quarter === q + 1 })

                obj[`value-${_word}-Q${q + 1}`] = k > -1 ? cloudProviderQuarterValues[k].value : 0
              }

              for (let m = 0; m < 12; m++) {
                let idx = $lodash.findIndex(cloudProviderMonthValues, (o) => { return o.month === m + 1 })

                obj[`value-${_word}-${m + 1}`] = idx > -1 ? cloudProviderMonthValues[idx].value : 0
              }

              break

            case 'cloudActivatedRate': // 产生云仓销售占比
              let rowValues = accumulativeMerchants[`${_word}`] && accumulativeMerchants[`${_word}`].length > 0 ? $lodash.unionBy(accumulativeMerchants[`${_word}`], fullsMonthOptions, 'month') : []
              rowValues = $lodash.orderBy(rowValues, 'month', ['asc'])

              let ynum = $lodash.sumBy(cloudProviderYearValues, 'value') // 年维度分子
              let _effectValues = $lodash.filter(rowValues, (o) => { return o.value && +o.value > 0 })

              let yden = _effectValues.length > 0 ? rowValues[_effectValues.length - 1].value : 0 // 年维度分母-累计商户

              // 年维度占比
              obj[`value-${_word}`] = `${(ynum / yden * 100).toFixed(2)}%`

              for (let q = 0; q < 4; q++) { // 季度维度占比
                let k = $lodash.findIndex(cloudProviderQuarterValues, (o) => { return o.quarter === q + 1 })
                let qnum = k > -1 ? cloudProviderQuarterValues[k].value : 0 // 季度维度分子
                let qarrs = $lodash.filter(rowValues, (item) => { return item.quarter === q + 1 && +item.value > 0 })
                let qden = qarrs.length > 0 ? qarrs[qarrs.length - 1].value : 0 // 季度维度分母

                obj[`value-${_word}-Q${q + 1}`] = qden > 0 ? `${(qnum / qden * 100).toFixed(2)}%` : '0.00%'
              }

              for (let m = 0; m < 12; m++) { // 月度维度占比
                let idx = $lodash.findIndex(rowValues, (o) => { return o.month === m + 1 })
                let mdx = $lodash.findIndex(cloudProviderMonthValues, (o) => { return o.month === m + 1 })
                let mnum = mdx > -1 ? cloudProviderMonthValues[mdx].value : 0 // 月维度分子
                let mden = idx > -1 ? rowValues[idx].value : 0 // 月维度分母

                obj[`value-${_word}-${m + 1}`] = mden > 0 ? `${(mnum / mden * 100).toFixed(2)}%` : '0.00%'
              }

              break

            default:
              let values = originData[row.key] && originData[row.key] !== undefined ? originData[row.key][`${_word}`] : []

              obj[`value-${_word}`] = values && values.length > 0 ? (row.toFixed ? ($lodash.sumBy(values, 'value') * 100 / 100).toFixed(2) : $lodash.sumBy(values, 'value')) : 0

              // 季度维度
              for (let q = 0; q < 4; q++) {
                let arr = $lodash.filter(values, (qtem) => { return qtem.quarter === q + 1 })
                let qval = arr.length > 0 ? $lodash.sumBy(arr, 'value') : 0

                obj[`value-${_word}-Q${q + 1}`] = row.toFixed ? (qval * 100 / 100).toFixed(2) : qval
              }

              for (let m = 0; m < 12; m++) {
                let index = $lodash.findIndex(values, (o) => { return o.month === m + 1 })

                obj[`value-${_word}-${m + 1}`] = index > -1 ? (row.toFixed ? (values[index].value * 100 / 100).toFixed(2) : values[index].value) : 0
              }
          }
        }
      }

      tableData.push(obj)
    })

    this.setState({
      tableData
    })
  }

  // 设置对象key为驼峰
  setCamelCaseKey = (maps) => {
    let result = {}
    $lodash.forEach(maps, (values, key) => {
      result[$lodash.camelCase(key)] = values
    })

    return result
  }

  // 根据月份设置季度
  setQuarterByMonth = (month) => {
    let i = $lodash.findIndex(fullsMonthOptions, (o) => { return o.month === month })
    let quarter = i > -1 ? fullsMonthOptions[i].quarter : null

    return quarter
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    })
  }
  // 设置悬浮
  gettitle = (e) => {
    return (
      `<div>
      <div><span>${e.marker}${e.name}</span></div>
      <div class=${styles.formatter}><span >全部</span><span>${e.value}</span></div>
      </div>`
    )
  }
  // 收起和展开
  getfaly =() => {
    const { faly = 0 } = this.state
    this.setState({
      faly: faly === 0 ? 1 : 0
    })
    let id = document.getElementById('rowid')
    id.style.height = faly === 0 ? '25px' : '400px'
  }

  render () {
    const { searchData, tableData = [], faly } = this.state

    return (
      <>
        <div className={`${styles.searchLand}`}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <div style={{ marginTop: '20px' }}>
          <Card>
            <Row id='rowid' className={styles.mianrow}>
              <div id='cloudNestedPiesChart' style={{ width: `${window.innerWidth - 300}px`, height: '400px' }} />
            </Row>
            <div className={styles.line}> <Divider />
              <Button onClick={() => { this.getfaly() }} className={styles.aicon}> <img src={Icon[faly].src} /></Button>
            </div>
          </Card>
        </div>

        <CloudStoreTable data={tableData} />
      </>
    )
  }
}
