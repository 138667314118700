import React, { Component } from 'react'
import { Row, Card, Col, Statistic, Table, Divider, Progress, Tooltip } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api'

import filter from '@/common/utils/filter'

const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')

export default class index extends Component {
  state = {
    searchData: [
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { month: 0, monthLabel: '全年' },
          ...fullsMonthOptions
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['month', 'monthLabel']
      }
    ],
    tableData: [],
    query: {},
    newSupplyUser: 0, // 新增供应商数量,
    newPurchaseUser: 0, // 新增采购商数量,
    cloudPurcaseOrderNum: 0, // 云采购订单数量,
    cloudPurchaseSentOut: 0, // 云采购销售额
    cloudSupplyOrderNum: 0, // 云供应订单数量
    cloudSupplyOrderAmount: 0, // 云供应订单金额
    percentForPurNum: 0, // 新增采购商数量进度数值
    percentForSupNum: 0, // 新增供应商数量进度数值
    percentForOrder: 0, // 云采购订单数量进度数值
    newSupplyUsersSeries: [], // 新增供应商Echarts数据
    newPurchaseUsersSeries: []// 新增采购商Echarts数据
  }
  columns = [
    {
      title: '序号',
      key: 'infoListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '员工名称',
      key: 'infoListCol-1',
      dataIndex: 'userName'
    },
    {
      title: '采购商总数',
      key: 'infoListCol-2',
      dataIndex: 'totalPurchaser'
    },
    {
      title: '云采购订单数量',
      key: 'infoListCol-3',
      dataIndex: 'cloudPurchaseNum'
    },
    {
      title: '云采购发货金额',
      key: 'infoListCol-4',
      dataIndex: 'cloudPurchaseSendOutAmout',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '供应商总数',
      key: 'infoListCol-5',
      dataIndex: 'totalSupplyer'
    },
    {
      title: '云供应订单数量',
      key: 'infoListCol-6',
      dataIndex: 'cloudSupplyOrderNum'
    },
    {
      title: '云供应发货金额',
      key: 'infoListCol-7',
      dataIndex: 'cloudSupplySendOutAmount',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    }
  ]

  // 获取报表数据
  getSalesStatistics = async () => {
    const { query = {} } = this.state
    const res = await Api.getPerformanceStatistics({ type: 'performance_overview_implementation_department', user_id: 2 })

    let newPurchaseUserObj = res.list_department_purchase_users_newly // 新增采购商数量总数据
    let newSupplyUserObj = res.list_department_supply_users_newly // 新增采购商数量总数据
    let cloudPurchaseAmountObj = res.list_department_cloud_purchase_amount_newly // 云采购订单总数据
    let cloudPurchaseSentOutObj = res.list_department_cloud_purchase_amount_send_out_newly // 云采购销售额list_department_cloud_supply_amount_paid_newly
    let cloudSupplyAmountObj = res.list_department_cloud_supply_amount_paid_newly // 云供应订单总数据
    let percentForNumObj = res.list_statistics_department_targets // 目标总数据
    let _tabledata = []

    // 新增采购商数据
    let newPurchaserdatas = []
    $lodash.forEach(newPurchaseUserObj, (value, key) => {
      if (((value.first_month.includes(YEAR) && parseInt(value.first_month.split('-')[1]) === parseInt(query.month)) || (value.first_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 5)) {
        const obj = {
          month: value.first_month.split('-')[1],
          value: value.distributor_id
        }
        newPurchaserdatas.push(obj)
      }
    })

    // 新增供应商数据
    let newSupplyerdatas = []
    $lodash.forEach(newSupplyUserObj, (value, key) => {
      if (((value.first_month.includes(YEAR) && parseInt(value.first_month.split('-')[1]) === parseInt(query.month)) || (value.first_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 5)) {
        const obj = {
          month: value.first_month.split('-')[1],
          value: value.distributor_id
        }
        newSupplyerdatas.push(obj)
      }
    })

    // 云采购订单数量
    let cloudPurchaseNumdatas = []
    $lodash.forEach(cloudPurchaseAmountObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 5)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.order_count
        }
        cloudPurchaseNumdatas.push(obj)
      }
    })

    // 云采购销售额数据
    let cloudPurchaseSendOutdatas = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 5)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudPurchaseSendOutdatas.push(obj)
      }
    })

    // 云供应订单数量
    let cloudSupplyNumdatas = []
    $lodash.forEach(cloudSupplyAmountObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 5)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.order_count
        }
        cloudSupplyNumdatas.push(obj)
      }
    })

    // 云供应销售额数据
    let cloudSupplyAmountdatas = []
    $lodash.forEach(cloudSupplyAmountObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 5)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudSupplyAmountdatas.push(obj)
      }
    })

    // 新增采购商数量进度条数值
    let percentForPurNumData = 0
    $lodash.forEach(percentForNumObj, (value, key) => {
      if ((value.target_name === '新增采购商数量') && (value.depart_id === 5)) {
        percentForPurNumData = parseFloat((newPurchaserdatas.length / value.target_value) * 100).toFixed(2)
      }
    })

    // 新增供应商数量进度条数值
    let percentForSupNumData = 0
    $lodash.forEach(percentForNumObj, (value, key) => {
      if ((value.target_name === '新增供应商数量') && (value.depart_id === 5)) {
        percentForSupNumData = parseFloat((newSupplyerdatas.length / value.target_value) * 100).toFixed(2)
      }
    })

    // 云采购订单数量进度数值
    let percentForOrderData = 0
    $lodash.forEach(percentForNumObj, (value, key) => {
      if ((value.target_name === '云采购订单数量') && (value.depart_id === 5)) {
        percentForOrderData = parseFloat(($lodash.sumBy(cloudPurchaseNumdatas, 'value') / value.target_value) * 100).toFixed(2)
      }
    })

    // 新增供应商Echarts数据
    const newObjsForSup = $lodash.groupBy(newSupplyUserObj, 'first_month')
    let differObj = []
    $lodash.forEach(newObjsForSup, (valuesArr, key) => {
      if ((key.includes(YEAR))) {
        $lodash.map(valuesArr, (value, keyarr) => {
          if ((value.depart_id === 5)) {
            const obj = { month: +(key.split('-')[1]), val: valuesArr.length }
            differObj.push(obj)
          }
        })
      }
    })
    // 去重
    const _set = new Set(differObj.map(JSON.stringify))
    const _uniqueArr = Array.from(_set).map(JSON.parse)
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const newSupllyUsersSeriesData = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(_uniqueArr, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 新增采购商Echarts数据
    const newObjs = $lodash.groupBy(newPurchaseUserObj, 'first_month')
    let differObj3 = []
    $lodash.forEach(newObjs, (valuesArr, key) => {
      if ((key.includes(YEAR))) {
        $lodash.map(valuesArr, (value, keyarr) => {
          if ((value.depart_id === 5)) {
            const obj = { month: +(key.split('-')[1]), val: valuesArr.length }
            differObj3.push(obj)
          }
        })
      }
    })
    // 去重
    const set = new Set(differObj3.map(JSON.stringify))
    const uniqueArr = Array.from(set).map(JSON.parse)
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const newPurchaseUsersSeriesData = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(uniqueArr, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 设置table列表数据
    if (res.table_details) {
      _tabledata = this.setTableData(res.table_details)
    }

    this.setState({
      newSupplyUser: newSupplyerdatas.length > 0 ? newSupplyerdatas.length : 0, // 新增供应商数量,
      newPurchaseUser: newPurchaserdatas.length > 0 ? newPurchaserdatas.length : 0, // 新增采购商数量,
      cloudPurcaseOrderNum: cloudPurchaseNumdatas.length > 0 ? $lodash.sumBy(cloudPurchaseNumdatas, 'value') : 0, // 云采购订单数量,
      cloudPurchaseSentOut: cloudPurchaseSendOutdatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutdatas, 'value') : 0, // 云采购销售额（发货）
      cloudSupplyOrderNum: cloudSupplyNumdatas.length > 0 ? $lodash.sumBy(cloudSupplyNumdatas, 'value') : 0, // 云供应订单数量
      cloudSupplyOrderAmount: cloudSupplyAmountdatas.length > 0 ? $lodash.sumBy(cloudSupplyAmountdatas, 'value') : 0, // 云供应订单金额
      percentForPurNum: percentForPurNumData, // 新增采购商数量进度数值
      percentForSupNum: percentForSupNumData, // 新增供应商数量进度数值
      percentForOrder: percentForOrderData, // 云采购订单数量进度数值
      tableData: _tabledata, // 交易明细
      newSupplyUsersSeries: newSupllyUsersSeriesData, // 新增供应商Echarts数据
      newPurchaseUsersSeries: newPurchaseUsersSeriesData// 新增采购商Echarts数据
    }, () => {
      this.initECharts()
    })
  }

  // 初始化图表
  initECharts = () => {
    const { newSupplyUsersSeries, newPurchaseUsersSeries } = this.state
    var myChart = echarts.init(document.getElementById('salesStatisticsEcharts'))
    // 绘制图表
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${YEAR}实施业绩总览折线图`
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['新增供应商数量', '新增采购商数量']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisLabel: {
          formatter: `{value}月`
        }
      },
      yAxis: [ {
        type: 'value',
        name: '数量',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} 家'
        }
      },
      {
        type: 'value',
        name: '数量',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} 家'
        }
      }],
      series: [
        {
          name: '新增供应商数量',
          type: 'line',
          yAxisIndex: 0,
          itemStyle: { normal: { label: { show: true } } },
          data: newSupplyUsersSeries
        },
        {
          name: '新增采购商数量',
          type: 'line',
          yAxisIndex: 1,
          itemStyle: { normal: { label: { show: true } } },
          data: newPurchaseUsersSeries
        }
      ]
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    }, () => {
      this.getSalesStatistics()
    })
  }

  // 设置table列表数据结构
  setTableData = (origindatas) => {
    const { query = {} } = this.state
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云采购订单数量
      let cloudPurchaseOrderData = []
      // 云采购发货金额table数据
      let cloudPurchaseSendOutTabledatas = []
      // 云供应发货金额table数据
      let cloudSupplySendOutTabledatas = []
      // 云供应订单数量
      let cloudSupplyOrderData = []
      if (values.depart_id === 5) {
        // 云供应发货金额table数据
        $lodash.forEach(values.cloud_supply_amount_send_out_newly_year_chart, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudSupplySendOutTabledatas.push(obj)
          }
        })
        // 云采购销售金额table数据
        $lodash.forEach(values.cloud_purchase_amount_send_out_newly_year_chart, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudPurchaseSendOutTabledatas.push(obj)
          }
        })
        // 云采购订单数量table数据
        $lodash.forEach(values.cloud_purchase_order_count, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudPurchaseOrderData.push(obj)
          }
        })
        // 云供应订单数量table数据
        $lodash.forEach(values.cloud_supply_order_count, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudSupplyOrderData.push(obj)
          }
        })
      }
      return {
        userName: values.user_name, // 员工姓名
        totalPurchaser: values.department_purchase_users_count, // 采购商总数
        cloudPurchaseNum: cloudPurchaseOrderData.length > 0 ? $lodash.sumBy(cloudPurchaseOrderData, 'value') : 0, // 云采购订单数量
        cloudPurchaseSendOutAmout: cloudPurchaseSendOutTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') : 0, // 云采购发货额
        totalSupplyer: values.supply_users_count, // 供应商总数
        cloudSupplyOrderNum: cloudSupplyOrderData.length > 0 ? $lodash.sumBy(cloudSupplyOrderData, 'value') : 0, // 云供应订单数量
        cloudSupplySendOutAmount: cloudSupplySendOutTabledatas.length > 0 ? $lodash.sumBy(cloudSupplySendOutTabledatas, 'value') : 0 // 云供应发货金额
      }
    }), function (item) {
      return -item.cloudPurchaseSendOutAmout
    })
    return list
  }

  render () {
    const { searchData, tableData, newSupplyUser, newPurchaseUser, cloudPurcaseOrderNum, cloudPurchaseSentOut, cloudSupplyOrderNum, cloudSupplyOrderAmount, percentForPurNum, percentForSupNum, percentForOrder } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px', paddingLeft: '30px' }}>
          <Col span={4}>
            <Statistic title='新增采购商数量' value={newPurchaseUser} precision={0} />
          </Col>
          <Col span={4}>
            <Statistic title='新增供应商数量' value={newSupplyUser} precision={0} />
          </Col>
          <Col span={4}>
            <Statistic title='云采购订单数量' value={cloudPurcaseOrderNum} precision={0} />
          </Col>
          <Col span={4}>
            <Statistic title='云采购销售额(发货)' value={cloudPurchaseSentOut} precision={2} prefix='￥' />
          </Col>
          <Col span={4}>
            <Statistic title='云供应订单数量' value={cloudSupplyOrderNum} precision={2} />
          </Col>
          <Col span={4}>
            <Statistic title='云供应订单金额' value={cloudSupplyOrderAmount} precision={2} prefix='￥' />
          </Col>
        </Row>
        <Card bodyStyle={{ padding: '12px' }}>
          <Tooltip title='prompt text' style={{ width: '100px', display: 'inline-block' }}>
            <span>新增采购商数量:</span>
          </Tooltip>
          <Progress style={{ width: '400px', display: 'inline-block', paddingLeft: '30px', paddingRight: '60px' }}
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
            strokeWidth='20px'
            percent={percentForPurNum}
            status='active'
          />
          <Tooltip title='prompt text' style={{ width: '100px', display: 'inline-block' }}>
            <span>新增供应商数量:</span>
          </Tooltip>
          <Progress style={{ width: '400px', display: 'inline-block', paddingLeft: '30px', paddingRight: '60px' }}
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
            strokeWidth='20px'
            percent={percentForSupNum}
            status='active'
          />
          <Tooltip title='prompt text' style={{ width: '100px', display: 'inline-block' }}>
            <span>云采购订单数:</span>
          </Tooltip>
          <Progress style={{ width: '400px', display: 'inline-block', paddingLeft: '30px', paddingRight: '60px' }}
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
            strokeWidth='20px'
            percent={percentForOrder}
            status='active'
          />
        </Card>
        <Card bodyStyle={{ padding: '12px' }}>
          <div id='salesStatisticsEcharts' style={{ width: '100%', height: '500px' }} />
        </Card>
        <Divider orientation='left'>交易明细</Divider>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Card>
      </div>
    )
  }
}
