import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input

class WriteoffForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        values.distributorId = detailInfo.distributorId
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title='余额核销' width={600}
          visible
          maskClosable
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem label='核销金额' {...formItemLayout}
              help={<>可核销最大金额: &yen;{(+detailInfo.totalBalance * 100 / 100).toFixed(2)}</>}
            >
              {
                getFieldDecorator('chargeOffAmount', {
                  rules: [
                    { required: true, message: '请填写核销金额' }
                  ]
                })(
                  <Input prefix={<>&yen;</>} placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='备注' {...formItemLayout}>
              {
                getFieldDecorator('remarks', {

                })(
                  <TextArea rows={4} />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const WriteoffModal = Form.create()(WriteoffForm)

export default WriteoffModal
