import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, message, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import OrderDetailsDrawer from '../component/orderDetailsDrawer/index'
import PaymethodModal from '../component/payselect-modal'
import PaybankModal from '../component/paybank-modal'
import PaymationModal from '../component/paymation-modal'
import PaycreditModal from '../component/paycredit-modal'
import BatchModal from './batchModal'
import PayVoucherModal from './payVoucherModal/index'
import SaledDrawer from './saledDrawer/index'
import OrderEnclosureDrawer from '../component/orderEnclosureDrawer' // 订单附件弹窗

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderOrderStatusLabel, renderOrderTypeLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, orderStatusMaps, cancelOrderStatusMaps, logisticsWayMaps, orderTypeMaps } = filter
const { MAX_PAGESIZE } = consts
const orderTypeOptions = $lodash.filter(orderTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

class PurchOrder extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '供应商'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'purchaseOrderId',
        formType: 'Input',
        itemParams: {
          label: '采购单号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '下单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '订单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderTypeOptions
        ]
      },
      {
        name: 'belongUserId',
        formType: 'Select',
        itemParams: {
          label: '运营负责人'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 5)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    detailModal: false,
    paybankModal: false,
    paymationModal: false,
    batchModal: false,
    rowDetail: {},
    selectedPaymethod: '', // 选择的支付方式
    payVoucherModal: false, // 付款凭证弹窗
    saledModal: false, // 售后弹窗
    enclosureModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'purchaseOrderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '采购单号',
      key: 'purchaseOrderCol-1',
      dataIndex: 'purchaseOrderId'
    },
    {
      title: '订单日期',
      key: 'purchaseOrderCol-2',
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '订单类型',
      key: 'purchaseOrderCol-3',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '供应商',
      key: 'purchaseOrderCol-4',
      dataIndex: 'merchantName'
    },
    {
      title: '运营负责人',
      key: 'purchaseOrderCol-5',
      ellipsis: true,
      dataIndex: 'belongUserName'
    },
    {
      title: '订单金额',
      key: 'purchaseOrderCol-6',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '应付金额',
      key: 'purchaseOrderCol-7',
      dataIndex: 'realPaymentAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.realPaymentAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'purchaseOrderCol-8',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'purchaseOrderCol-9',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '付款凭证',
      key: 'purchaseOrderCol-10',
      render: (text, record, index) => (
        <Button type='link' size='small' onClick={() => this.handleShowVoucherModal(record)}>点击显示</Button>
      )
    },
    {
      title: '订单附件',
      key: 'purchaseOrderCol-11',
      with: 80,
      render: (text, record, index) => (
        <>
          <a onClick={() => this.showOrderEnclosureModal(record)}>点击查看</a>
        </>
      )
    },
    {
      title: '订单状态',
      key: 'purchaseOrderCol-12',
      render: (text, record, index) => (
        <>
          <div>
            <span style={{ display: 'inline-block', width: '35px', marginRight: '3px' }}>D-P:</span>
            <span style={{ display: 'inline-block' }}>
              {renderOrderStatusLabel(record.dpOrderStatus)}
            </span>
          </div>
          <div>
            <span style={{ display: 'inline-block', width: '35px', marginRight: '3px' }}>P-RS:</span>
            <span style={{ display: 'inline-block' }}>
              {renderOrderStatusLabel(record.orderStatus)}
            </span>
          </div>
        </>
      )
    },
    {
      title: '操作',
      key: 'purchaseOrderCol-13',
      width: 200,
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <>
            <Button type='link' size='small' onClick={() => this.showOrderDetailModal(record)}>查看明细</Button>

            {
              buttonMaps && buttonMaps['pay'] && +buttonMaps['pay'].auth === 1 &&
              ((record.skuGoodsType === 'CLOUD_FUTURES' && (['PAYING', 'TAIL_PAYING'].includes(record.orderStatus))) || (record.skuGoodsType === 'CLOUD_SPOT' && record.paymentFlag === 'UNPAID' && record.orderStatus !== 'CANCEL_MANUAL'))
                ? <Button type='link' size='small'
                  onClick={() => this.showPaymethodModal(record)}
                >去付款</Button>
                : null
            }

            {
              buttonMaps && buttonMaps['downloadContract'] && +buttonMaps['downloadContract'].auth === 1 && !this.renderIsCanceled(record.orderStatus)
                ? <>
                  <Button type='link' size='small' onClick={() => this.handleDownloadContract(record)}>
                    下载合同
                  </Button>
                  <Button type='link' size='small' onClick={() => this.handleCreateContractAgain(record)}>重新生成合同</Button>
                </>
                : null
            }

            {
              buttonMaps && buttonMaps['saledApply'] && +buttonMaps['saledApply'].auth === 1 && record.orderStatus === 'ORDER_COMPLETE'
                ? <Button type='link' size='small' disabled={!record.isCompleted} onClick={() => this.showSaledFormModal(record)}>
                  { record.isCompleted ? '申请售后' : '售后中' }
                </Button>
                : null
            }

            <Button type='link' size='small' onClick={() => this.handleDownloadPickingOrder(record)}>下载拣货单</Button>
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getPurchOrderList()
  }

  // 获取全部成员
  getMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[searchIndex].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []

        this.setState({
          searchData
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取采购订单列表
  getPurchOrderList = () => {
    const { pages, query = {} } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getPurchOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取采购订单商品明细
  getPurchOrderGoodsList = (params) => {
    return Api.getPurchOrderGoodsList(params)
  }

  // 获取转账商家银行信息
  getPaidInfoByBankCard = (params) => {
    return Api.getPaidInfoByBankCard(params)
  }

  // 获取记账宝支付信息
  getPaidInfoByJzb = (params) => {
    return Api.getPaidInfoByJzb(params)
  }

  // 获取信用支付信息
  getPaidInfoByCredit = (params) => {
    return Api.getPaidInfoByCredit(params)
  }

  // 获取采购订单合同下载路径
  getPurchaseTradeContractDownFile = (params) => {
    Api.getPurchaseTradeContractDownFile(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (!data || !data.fileKey || data.fileKey === '') {
          return message.warning('暂无可下载的合同链接')
        }

        window.open(data.fileKey)
      }
    })
  }

  // 信用支付采购单
  payPurchOrderByCredit = (params) => {
    Api.payPurchOrderByCredit(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getPurchOrderList()
      }
    })
  }

  // 银行转账-确认付款
  confirmPaidToCard = (params) => {
    Api.confirmPaidToCard(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getPurchOrderList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取运单号详情
  getPurchOrderMailDetail = (params) => {
    return Api.getPurchOrderMailDetail(params)
  }

  // 采购订单-发货批次查询
  getPurchaseOrderSendOutBatchList = (expanded, record) => {
    if (!expanded) { return false }

    Api.getPurchaseOrderSendOutBatchList({ purchaseOrderId: record.purchaseOrderId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { tableData } = this.state
        let i = tableData.findIndex((item) => { return item.purchaseOrderId === record.purchaseOrderId })

        if (i > -1) {
          tableData[i]['batchList'] = data || []
        }

        this.setState({
          tableData
        })
      }
    })
  }

  // 采购订单-重新生成合同
  createPurchaseOrderContractAgain = async (params) => {
    const res = await Api.createPurchaseOrderContractAgain(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
    }
  }

  // 采购订单- 申请售后
  saveSaledApplyByPurchaseOrder = async (params) => {
    const res = await Api.saveSaledApplyByPurchaseOrder(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchOrderList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      let orderTime = search.orderTime
      search.beginDate = orderTime[0].format('YYYY-MM-DD')
      search.endDate = orderTime[1].format('YYYY-MM-DD')

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getPurchOrderList()
    })
  }

  // 点击查看订单明细
  showOrderDetailModal = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record || {}
    })
  }

  // 点击合同下载
  handleDownloadContract = (record) => {
    this.getPurchaseTradeContractDownFile({ purchaseOrderId: record.purchaseOrderId })
  }

  // 点击重新生成合同
  handleCreateContractAgain = (record) => {
    Modal.confirm({
      title: '确定重新生成合同？',
      content: '此操作会根据订单数据和公司基础数据重新生成合同',
      onOk: () => {
        this.createPurchaseOrderContractAgain({ purchaseOrderId: record.purchaseOrderId })
      }
    })
  }

  // 支付方式弹窗
  showPaymethodModal = (record = {}) => {
    this.setState({
      paymethodModal: true,
      rowDetail: record
    })
  }

  // 确定支付方式
  handleConfirmPaymethod = (params) => {
    this.setState({
      paymethodModal: false,
      [params.type]: true,
      selectedPaymethod: params.paymethod
    })
  }

  // 点击查看付款凭证
  handleShowVoucherModal = (record) => {
    this.setState({
      rowDetail: record,
      payVoucherModal: true
    })
  }

  // 查看批次详情
  showBatchModal = (row) => {
    this.setState({
      batchModal: true,
      rowDetail: row
    })
  }

  // 点击申请售后
  showSaledFormModal = (record) => {
    this.setState({
      rowDetail: record,
      saledModal: true
    })
  }

  // 点击查看订单附件
  showOrderEnclosureModal = (record) => {
    this.setState({
      rowDetail: record,
      enclosureModal: true
    })
  }

  // 下载拣货单
  handleDownloadPickingOrder = (record) => {
    DownloadApi.downloadPurchaseOrderPickingOrder({ purchaseOrderId: record.purchaseOrderId })
  }

  // 渲染展开数据
  renderExpandRow = (row) => {
    const rowColumns = [
      {
        title: '发货批次',
        key: `expandRow-${row.orderId}-0`,
        dataIndex: 'batchNum'
      },
      {
        title: '发货方式',
        key: `expandRow-${row.orderId}-1`,
        render: (text, row, index) => (
          <div>{ this.renderLogisticsWayLabel(row.logisticsWayEnum) }</div>
        )
      },
      {
        title: '快递公司',
        key: `expandRow-${row.orderId}-2`,
        dataIndex: 'courierName'
      },
      {
        title: '运单号',
        key: `expandRow-${row.orderId}-3`,
        render: (text, record, index) => (
          <>
            {
              record.courierNumArray && record.courierNumArray.length > 0
                ? record.courierNumArray.join(',')
                : '----'
            }
          </>
        )
      },
      {
        title: '发货时间',
        key: `expandRow-${row.orderId}-4`,
        render: (text, record, index) => (
          <>
            { record.deliveryTime ? moment(record.deliveryTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
          </>
        )
      },

      {
        title: '操作',
        key: `expandRow-${row.orderId}-5`,
        render: (text, record, index) => (
          <>
            <Button type='link' size='small' style={{ fontSize: '12px' }} onClick={() => this.showBatchModal(record)}>查看详情</Button>
          </>
        )
      }
    ]

    return (
      <div style={{ padding: '2px 5px' }}>
        <Table rowKey='batchNum' bordered
          columns={rowColumns}
          dataSource={row.batchList || []}
          pagination={false}
        />
      </div>
    )
  }

  // 判断是否是取消的订单
  renderIsCanceled = (status) => {
    const i = cancelOrderStatusMaps.findIndex(item => { return +item.code === +status })
    const isCanceled = i > -1

    return isCanceled
  }

  // 渲染物流服务方式
  renderLogisticsWayLabel = (status) => {
    const obj = logisticsWayMaps.find(item => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      mailModal: false,

      paycreditModal: false,
      paybankModal: false,
      paymationModal: false,

      paymethodModal: false,
      batchModal: false,
      payVoucherModal: false,
      saledModal: false,
      enclosureModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchOrderList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], detailModal = false, paymethodModal = false, batchModal = false,
      paybankModal = false, paymationModal = false, paycreditModal = false, rowDetail = {}, payVoucherModal = false, saledModal = false, enclosureModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='purchaseOrderId' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            onExpand={(expanded, record) => this.getPurchaseOrderSendOutBatchList(expanded, record)}
            expandedRowRender={(record) => this.renderExpandRow(record)}
          />
        </Card>

        <OrderDetailsDrawer visible={detailModal}
          ident={'PURCHASE_ORDER'}
          detailInfo={rowDetail}
          getProductDetail={(params) => this.getPurchOrderGoodsList(params)}
          onCancel={() => this.closeModal()}
        />

        {// 信用支付信息弹窗
          paycreditModal
            ? <PaycreditModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getPaidInfoByCredit(params)}
              onConfirm={(params) => this.payPurchOrderByCredit(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {// 支付方式弹窗
          paymethodModal
            ? <PaymethodModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleConfirmPaymethod(params)}
            />
            : null
        }

        {// 银行卡转账信息弹窗
          paybankModal
            ? <PaybankModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getPaidInfoByBankCard(params)}
              onCancel={this.closeModal}
              onConfirm={(params) => this.confirmPaidToCard(params)}
            />
            : null
        }

        {// 记账宝支付信息
          paymationModal
            ? <PaymationModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getPaidInfoByJzb(params)}
              onCancel={this.closeModal}
              onConfirm={(params) => this.confirmPaidToCard(params)}
            />
            : null
        }

        {
          batchModal
            ? <BatchModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        { // 付款凭证明细
          payVoucherModal
            ? <PayVoucherModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        <SaledDrawer visible={saledModal}
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
          onConfirm={(values) => this.saveSaledApplyByPurchaseOrder(values)}
        />

        {/* 订单附件弹窗 */}
        <OrderEnclosureDrawer
          visible={enclosureModal}
          name='PURCHASE_ENCLOSURE'
          type='SUPPLY_PLATFORM'
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(PurchOrder)
