import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Form, Select, Input, Radio, DatePicker, Button, Modal, Upload, Icon, message } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'

import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import { getStore } from '@/common/utils/mUtils'

import 'braft-editor/dist/index.css'
import styles from './sysnotice.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const FormItem = Form.Item
const Option = Select.Option
const { confirm } = Modal

class EditsysForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    editorState: BraftEditor.createEditorState(null),
    type: 1, // 1-新增 2-编辑
    detailInfo: {}
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    this.setState({
      type: state.type || 1,
      detailInfo: state.detailInfo || {},
      editorState: state.type === 1 ? BraftEditor.createEditorState(null) : BraftEditor.createEditorState(state.detailInfo.content)
    })
  }

  // 保存IIASaaS公告
  saveIIASaaSSystemNotice = (params, needBack) => {
    Api.saveIIASaaSSystemNotice(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')

        if (needBack) {
          this.props.history.push({ pathname: '/admin/informationManage' })
        }
      }
    })
  }

  // 发布IIASaaS公告
  sendpublishIIASaaSSystemNotice = (params) => {
    Api.sendpublishIIASaaSSystemNotice(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '操作成功',
          onOk: () => {
            this.props.history.push({ pathname: '/admin/informationManage' })
          }
        })
      }
    })
  }

  // 点击发布、保存
  handleSubmit = (saveType, needBack) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { type = 1, detailInfo = {}, editorState } = this.state
        if (type === 2) {
          values['id'] = detailInfo.id
        }

        if (values.sendType === 'TIME_TASK') {
          let { taskTime } = values
          values['taskTime'] = moment(taskTime).format('YYYY-MM-DD HH:mm:ss')
        }

        values['content'] = editorState.toHTML()

        if (saveType === 'SAVE') {
          this.saveIIASaaSSystemNotice(values, needBack)
        } else {
          this.sendpublishIIASaaSSystemNotice(values, needBack)
        }
      }
    })
  }

  // 点击取消
  handleCancel = () => {
    confirm({
      title: '是否保存已编辑的内容？',
      content: '',
      okText: '是',
      cancelText: '否',
      onOk: () => {
        this.handleSubmit('SAVE', true)
      },
      onCancel: () => {
        this.props.history.push({ pathname: '/admin/informationManage' })
      }
    })
  }

  // 监听富文本变化
  handleChangeEditorState = (editorState) => {
    this.setState({
      editorState
    })
  }

  // 富文本上传图片
  uploadEditorImage = (info) => {
    if (!info.file) {
      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        let { editorState } = this.state
        this.setState({
          editorState: ContentUtils.insertMedias(editorState, [ { type: 'IMAGE', url: data.imageUrl } ])
        })
      } else {
        message.error('上传图片失败')
      }
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { editorState, detailInfo = {} } = this.state

    const controls = [
      'undo', 'redo', 'separator',
      'font-size', 'line-height', 'letter-spacing', 'separator',
      'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
      'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
      'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
      'link', 'separator', 'hr', 'separator',
      // 'media',
      'separator',
      'clear'
    ]

    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload name='file'
            accept='image/*'
            showUploadList={false}
            headers={{ Token: getStore('token') }}
            action={uploadOrdinaryImageUrl}
            onChange={this.uploadEditorImage}
          >
            <Button className={'control-item button upload-button'} data-title='插入图片'>
              <Icon type='picture' />
            </Button>
          </Upload>
        )
      }
    ]

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row>
            <Form>
              <Row className={`${styles.formitemRow}`}>
                <FormItem label='类型选择'>
                  {
                    getFieldDecorator('msgType', {
                      initialValue: 'PLATFORM_ANNOUNCEMENT',
                      rules: [
                        { required: true, message: '请选择消息类型' }
                      ]
                    })(
                      <Select style={{ width: '350px' }}>
                        <Option value='PLATFORM_ANNOUNCEMENT'>IIASaaS公告</Option>
                      </Select>
                    )
                  }
                </FormItem>
              </Row>

              <Row className={`${styles.formitemRow}`}>
                <FormItem label='公告标题'>
                  {
                    getFieldDecorator('title', {
                      initialValue: detailInfo.title || '',
                      rules: [
                        { required: true, message: '请填写标题' }
                      ]
                    })(
                      <Input autoComplete='off' style={{ width: '350px' }} />
                    )
                  }
                </FormItem>
              </Row>

              <Row className={`${styles.formitemRow}`}>
                <FormItem label={<span className={`${styles.required}`}>公告内容</span>}>
                  <BraftEditor className={`${styles.editorWrap}`}
                    value={editorState}
                    controls={controls}
                    extendControls={extendControls}
                    onChange={this.handleChangeEditorState}
                  />
                </FormItem>
              </Row>

              <Row className={`${styles.formitemRow}`}>
                <FormItem label='上线类型'>
                  {
                    getFieldDecorator('sendType', {
                      initialValue: detailInfo.sendType || 'NOW',
                      rules: [
                        { required: true, message: '请选择上线类型' }
                      ]
                    })(
                      <Radio.Group>
                        <Radio value={'NOW'}>即时上线</Radio>
                        <Radio value={'TIME_TASK'}>自动上线</Radio>
                      </Radio.Group>
                    )
                  }
                </FormItem>
              </Row>

              {
                this.props.form.getFieldValue('sendType') === 'TIME_TASK'
                  ? <Row className={`${styles.formitemRow}`}>
                    <FormItem label='上线时间'>
                      {
                        getFieldDecorator('taskTime', {
                          initialValue: detailInfo.taskTime ? moment(moment(detailInfo.taskTime).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss') : moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss'),
                          rules: [
                            { required: true, message: '请选择上线时间' }
                          ]
                        })(
                          <DatePicker showTime />
                        )
                      }
                    </FormItem>
                  </Row>
                  : null
              }

              <FormItem>
                <Row className={`${styles.btns}`}>
                  <Button onClick={() => this.handleCancel()}>取消</Button>
                  <Button type='primary' style={{ marginLeft: '15px' }}
                    onClick={() => this.handleSubmit('SAVE', false)}>保存</Button>
                  <Button type='primary' style={{ marginLeft: '15px' }}
                    onClick={() => this.handleSubmit('SEND', true)}>发布</Button>
                </Row>
              </FormItem>
            </Form>
          </Row>
        </Card>
      </>
    )
  }
}

const EditsysNotice = Form.create()(EditsysForm)

export default EditsysNotice
