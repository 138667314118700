import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Icon, message } from 'antd'

import InvoiceAccountFormModal from '@/components/invoiceAccountFormModal/index'
import { formatBankAccount, renderAddressLabel } from '@/common/utils/mUtils'

import Api from '@/common/api/index'
import styles from './index.module.scss'

export default class Orderinvoice extends Component {
  static propTypes = {
    distributorId: PropTypes.number
  }
  state = {
    info: PropTypes.object,
    editInvoiceModal: false
  }

  componentDidMount () {
    this.getDistributorInvoiceTitleDetails()
  }

  // 获取开票抬头信息
  getDistributorInvoiceTitleDetails = async () => {
    const { distributorId } = this.props
    const res = await Api.getDistributorInvoiceTitleDetails({ distributorId: distributorId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data.data || {}
      })
    }
  }

  // 保存编辑商户发票抬头信息
  saveEditDistributorInvoiceTitle = async (params) => {
    const res = await Api.saveEditDistributorInvoiceTitle(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getDistributorInvoiceTitleDetails()
    }
  }

  // 点击编辑发票信息
  showEditInvoiceModal = () => {
    this.setState({
      editInvoiceModal: true
    })
  }

  // 点击保存编辑发票信息
  handleSaveEditInvoiceTitle = (params) => {
    const { distributorId } = this.props
    this.saveEditDistributorInvoiceTitle({ ...params, distributorId: distributorId })
  }

  closeModal = () => {
    this.setState({
      editInvoiceModal: false
    })
  }

  render () {
    const { info = {}, editInvoiceModal = false } = this.state

    return (
      <>
        <div style={{ marginBottom: '24px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Col span={8}>
              <div className={`${styles.headerWrapper}`}>
                <div className={`${styles.headerTitle}`}>发票信息</div>
                <div className={`${styles.headerExtra}`}>
                  <Button type='link' onClick={() => this.showEditInvoiceModal()}><Icon type='edit' />编辑</Button>
                </div>
              </div>
            </Col>
          </Row>
          <ul className={`${styles.invoiceContent}`}>
            <li className={`${styles.invoiceItem}`}>
              <label className={`${styles.label}`}>增值税专用发票(企业)抬头</label>
              <span>{ info.invoiceTitle }</span>
            </li>

            <li className={`${styles.invoiceItem}`}>
              <label className={`${styles.label}`}>统一社会信用代码</label>
              <span>{ info.creditNo }</span>
            </li>

            <li className={`${styles.invoiceItem}`}>
              <label className={`${styles.label}`}>注册地址</label>
              <span>{ renderAddressLabel(info.province, info.city, info.county, info.address) }</span>
            </li>

            <li className={`${styles.invoiceItem}`}>
              <label className={`${styles.label}`}>开票电话</label>
              <span>{ info.tel }</span>
            </li>

            <li className={`${styles.invoiceItem}`}>
              <label className={`${styles.label}`}>开户银行</label>
              <span>{info.bankName }</span>
            </li>

            <li className={`${styles.invoiceItem}`}>
              <label className={`${styles.label}`}>银行账号</label>
              <span>{ info.bankCard ? formatBankAccount(info.bankCard) : null }</span>
            </li>
          </ul>
        </div>

        {
          editInvoiceModal
            ? <InvoiceAccountFormModal
              detailInfo={info}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.handleSaveEditInvoiceTitle(params)}
            />
            : null
        }
      </>
    )
  }
}
