/**
 * 财务模块
 */
import CreditList from '@/pages/finance/credit/index' // 信用列表
import CreditDetail from '@/pages/finance/credit-detail/index' // 信用详情
import BalanceList from '@/pages/finance/merchant-balance/index' // 商户余额列表
import CashoutList from '@/pages/finance/cashout-list/index' // 商户提现申请
import EutradeOrder from '@/pages/finance/eutrade-order/index' // 客户订单列表

import InvoiceRule from '@/pages/finance/invoice-rule/index' // 开票规则设置
import SupplyInvoice from '@/pages/finance/supply-invoice/index' // 供应单开票申请
import PurchInvoice from '@/pages/finance/purch-invoice/index' // 采购订单开票
import ExpressInvoice from '@/pages/finance/express-invoice/index' // 快递服务开票
import InsuranceInvoice from '@/pages/finance/insurance-invoice/index' // 保险发票

import InsuranceBill from '@/pages/finance/insurance-bill/index' // 保险账单

import FinanceAnalysis from '@/pages/finance/analysis/index' // 财务数据分析页
import ExpressRecord from '@/pages/finance/express-record/index' // 快递记录
import Insuranceslip from '@/pages/finance/Insurance-slip/index' // 保险流水单
import Oneexpress from '@/pages/finance/one-exopress/index' // 初级快递核算单
import ExpressBill from '@/pages/finance/express-bill/index' // 快递对账单
import CustomerExopress from '@/pages/finance/customer-exopress/index' // 客户快递快递核算单

export default [
  {
    path: '/admin/financeAnalysis',
    name: '数据分析',
    component: FinanceAnalysis
  },
  {
    path: '/admin/creditList',
    name: '信用列表',
    component: CreditList
  },
  {
    path: '/admin/creditDetail',
    name: '信用详细',
    component: CreditDetail
  },
  {
    path: '/admin/supplyInvoice',
    name: '供应订单开票',
    component: SupplyInvoice
  },
  {
    path: '/admin/invoiceRule',
    name: '开票规则',
    component: InvoiceRule
  },
  {
    path: '/admin/purchInvoice',
    name: '采购订单开票',
    component: PurchInvoice
  },
  {
    path: '/admin/merchantBalance',
    name: '商户余额管理',
    component: BalanceList
  },
  {
    path: '/admin/applyCash',
    name: '商户提现申请',
    component: CashoutList
  },
  {
    path: '/admin/eutrade-order',
    name: '客户订单列表',
    component: EutradeOrder
  },
  {
    path: '/admin/expressInvoice',
    name: '快递发票',
    component: ExpressInvoice
  },
  {
    path: '/admin/insuranceBill',
    name: '保险对账单',
    component: InsuranceBill
  },
  {
    path: '/admin/expressRecord',
    name: '快递记录',
    component: ExpressRecord
  },
  {
    path: '/admin/InsuranceSlip',
    name: '保险流水单',
    component: Insuranceslip
  },
  {
    path: '/admin/oneExpress',
    name: '初步快递核算单',
    component: Oneexpress
  },
  {
    path: '/admin/expressBill',
    name: '快递对账单',
    component: ExpressBill
  },
  {
    path: '/admin/insuranceInvoice',
    name: '保险发票',
    component: InsuranceInvoice
  },
  {
    path: '/admin/customerExopress',
    name: '客户快递核算单',
    component: CustomerExopress
  }
]
