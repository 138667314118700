import Categorys from '@/pages/product/categorys/index' // 分类管理
import Brand from '@/pages/product/brand/index' // 品牌管理
import Series from '@/pages/product/series/index' // 系列管理
import SpeciList from '@/pages/product/speci-list/index' // 型号管理

import DisputeSkus from '@/pages/product/dispute-skus/index' // SKU导入详情
import ParameterList from '@/pages/product/parameter-list/index' // 产品参数配置
import BindSeriesSku from '@/pages/product/bindSeriesSku/index' // 关联产品

import CusbrandList from '@/pages/customer/cusbrand-list/index' // 客户自营&推广品牌列表
import CusbrandProduct from '@/pages/customer/cusbrand-product/index' // 品牌产品详情

import OutsideBatch from '@/pages/product/outsideBatch/index' // 外部对接产品
import ReferenceSyncData from '@/pages/product/referenceSyncData/index' // 对接数据监控
import ReferenceSkus from '@/pages/product/referenceSkus/index' // 引用产品数据

import Imgexamine from '@/pages/product/img-examine/index' // 自建图片审核
import BindSkuImages from '@/pages/product/bindSkuImages/index' // sku关联图片

export default [
  {
    path: '/admin/cation',
    name: '分类管理',
    component: Categorys
  },
  {
    path: '/admin/brand',
    name: '品牌管理',
    component: Brand
  },
  {
    path: '/admin/series',
    name: '系列管理',
    component: Series
  },
  {
    path: '/admin/specification',
    name: '型号管理',
    component: SpeciList
  },
  {
    path: '/admin/disputeSkus',
    name: '导入失败详情',
    component: DisputeSkus
  },
  {
    path: '/admin/paramterList',
    name: '产品参数配置',
    component: ParameterList
  },
  {
    path: '/admin/cusbrandList',
    name: '商户品牌',
    component: CusbrandList
  },
  {
    path: '/admin/cusbrandProduct',
    name: '商户产品',
    component: CusbrandProduct
  },
  {
    path: '/admin/outsideBatch',
    name: '对接数据管理',
    component: OutsideBatch
  },
  {
    path: '/admin/referenceSyncData',
    name: '对接数据监控',
    component: ReferenceSyncData
  },
  {
    path: '/admin/referenceSkus',
    name: '爬取数据管理',
    component: ReferenceSkus
  },
  {
    path: '/admin/imgexamine',
    name: '自建图片审核',
    component: Imgexamine
  },
  {
    path: '/admin/bindSkuImages',
    name: '批量关联图片',
    component: BindSkuImages
  },
  {
    path: '/admin/bindSeriesSku',
    name: '关联产品',
    component: BindSeriesSku
  }
]
