
// 云仓表格数据模板
export const cloudTableRows = [
  { key: 'allActivationPartners', name: '新增激活合作伙伴', toFixed: false },
  { key: 'increasedCloudStockAmount', name: '新增库存金额', toFixed: true },
  { key: 'increasedSkuNum', name: '新增SKU数量', toFixed: false },
  { key: 'increasedBrandNum', name: '新增品牌数量', toFixed: false },
  { key: 'iiaThirdErpUsedPartners', name: '与IIA系统实现实时连接的云仓作伙伴总数', toFixed: false },
  { key: 'cloudTransactionPartnersNum', name: '使用IIA提供的第三方ERP的云仓合作伙伴总数', toFixed: false },
  { key: 'cloudActivatedServiceProviderNum', name: '产生【云仓销售】的合作伙伴家数', toFixed: false },
  { key: 'cloudActivatedRate', name: '产生【云仓销售】的合作伙伴家数#VS总家数%', toFixed: true },
  { key: 'accumulativeMerchants', name: '累计合作伙伴总数', toFixed: false },
  { key: 'accumulativeCloudStockAmount', name: '累计云仓库存金额总数', toFixed: true },
  { key: 'accumulativeModelNum', name: '累计云仓商品型号总数', toFixed: false },
  { key: 'accumulativeBrandAuth', name: '累计云仓商品品牌总数', toFixed: false },
  { key: 'accumulativeAgentNum', name: '累计经销商合作伙伴总数', toFixed: false },
  { key: 'accumulativeEndManufacturerNum', name: '累计最终厂商合作伙伴总数', toFixed: false }
]

// 服务商&最终用户表格数据模板
export const merchantTableRows = [
  { key: 'serviceProviderNum', name: '新增服务商', toFixed: false },
  { key: 'increasedEuNum', name: '新增最终用户', toFixed: false },
  { key: 'privateDomainActivatedServiceProviderNumRate', name: '发生【私域商城】采购的服务商VS服务商总数%', toFixed: false },
  { key: 'cloudActivatedServiceProviderNumRate', name: '发生【云仓平台】采购的服务商VS服务商总数%', toFixed: false },
  { key: 'activatedEuNumRate', name: '发生采购的最终用户VS最终用户总数%', toFixed: false },
  { key: 'accumulativeServiceProviderNum', name: '累计服务商', toFixed: false },
  { key: 'accumulativeEuNum', name: '累计最终用户', toFixed: false },
  { key: 'gmvAmountTotal', name: 'GMV总订单金额', toFixed: true },
  { key: 'gmvAmountPrivateDomains', name: '私域商城GMV', toFixed: true },
  { key: 'gmvAmountCloud', name: '云仓平台GMV', toFixed: true },
  { key: 'gmvAmountCloudRate', name: '平台云仓GMV/总计GMV', toFixed: false },
  { key: 'increasedOrderNum', name: '订单总数', toFixed: false },
  { key: 'increasedPrivateDomainOrderNum', name: '私域商城订单', toFixed: false },
  { key: 'increasedCloudOrderNum', name: '云仓平台订单', toFixed: false },
  { key: 'cloudOrderRate', name: '平台云仓订单数/总计订单数', toFixed: false },
  { key: 'averagePerTicketSales', name: '平均客单价', toFixed: true },
  { key: 'privateDomainsPerTicketSales', name: '私域商城客单价', toFixed: true },
  { key: 'cloudPerTicketSales', name: '云仓平台客单价', toFixed: true },
  { key: 'cloudPerTicketSalesRate', name: '平台云仓客单价/总计客单价', toFixed: false }
]

export const quarterOptions = [
  { id: 1, name: '第一季度' },
  { id: 2, name: '第二季度' },
  { id: 3, name: '第三季度' },
  { id: 4, name: '第四季度' }
]
