import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUserMenu, setUserInfo, setUnnoticeCount, setNoticeDetail, setTableColumnKeys } from '@/reducers/action'
import { routerMap } from '@/routes/router.js'
import { Layout, notification, Row } from 'antd'

import HeaderBar from '../header-bar/index'
import SideMenu from '../side-menu/index'
import TaskIcon from '@/assets/images/icon/icon_task.png'
import NoticeIcon from '@/assets/images/icon/icon_notice.png'
import AnnounceIcon from '@/assets/images/icon/icon_announce.png'
import IIASaaSIcon from '@/assets/images/icon/icon_iiasaas.png'

import Api from '@/common/api/index'
import { createSocket, oncloseWS } from '@/common/utils/websocket.js'
import { getStore } from '@/common/utils/mUtils'
import config from '@/common/utils/config'

import styles from './main.module.scss'

const { Sider, Content, Footer } = Layout
const { socketWs } = config
const noticeTypeMaps = [
  { id: 'SYSTEM_MSG', name: '系统通知', icon: NoticeIcon },
  { id: 'SYSTEM_NOTIFY', name: '活动通知', icon: NoticeIcon },
  { id: 'SYSTEM_ANNOUNCEMENT', name: '系统公告', icon: AnnounceIcon },
  { id: 'TASK_DISTRIBUTE', name: '任务分发', icon: TaskIcon },
  { id: 'IIASAAS_NOTITF', name: 'IIASaaS公告', icon: IIASaaSIcon }
]

class Main extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }

  state = {
    menus: []
  }

  componentDidMount () {
    axios.all([Api.getUserInfo({}), Api.getUserMenus({})]).then(axios.spread((userRes, menuRes) => {
      if (+userRes.code === 10000 && +menuRes.code === 10000) {
        if (menuRes.data && menuRes.data.length > 0) {
          menuRes.data.map(item => {
            if (item.menuList && item.menuList.length > 0) {
              let _menuTree = item.menuList

              _menuTree.map(menu => {
                if (menu.buttonList && menu.buttonList.length > 0) {
                  let _buttonMaps = {}

                  menu.buttonList.map(btn => {
                    _buttonMaps[`${btn.ident}`] = btn
                  })

                  menu.buttonMaps = _buttonMaps
                }
              })
            }
          })
        }

        const { userSystemSetting = {} } = userRes.data
        const userTableColumnMap = userSystemSetting && userSystemSetting.content ? JSON.parse(userSystemSetting.content) : null

        this.setState({
          menus: menuRes.data || []
        }, () => {
          this.initWebsocket()
        })

        this.props.dispatch(setUserInfo({ userInfo: userRes.data || {} }))
        this.props.dispatch(setTableColumnKeys({ columnsMap: userTableColumnMap }))
        this.props.dispatch(setUserMenu({ menus: menuRes.data || [] }))
      }
    }))

    // this.getUnReadNotifyCount()

    // 注册监听事件
    window.addEventListener('onmessageWS', (e) => {
      this.getNotificationDetail(e)
    })
  }

  componentWillUnmount () {
    // 关闭socket链接
    oncloseWS()
    // 卸载监听事件
    window.removeEventListener('onmessageWS', () => {})
  }

  // 初始化websocket
  initWebsocket = () => {
    let _token = getStore('token')
    let _domain = document.domain
    let url = `${socketWs}/token=${_token}&channel=oper&domain=${_domain}`

    createSocket(url)
  }

  // 获取推送的消息
  getNotificationDetail = (e) => {
    const data = e && e.detail.data ? JSON.parse(e.detail.data) : {}

    if (data && data.msgId) {
      notification.open({
        message: <div>{ this.renderNoticeTypelabel(data.msgType)}</div>,
        description: <div className={`${styles.noticeDescription}`}>{data.msgTitle}</div>,
        icon: <span className={`${styles.noticeIcon}`}>
          <img src={this.renderNotificationIcon(data.msgType)} />
        </span>,
        onClose: () => {
          this.props.dispatch(setUnnoticeCount({ unnoticeCount: +data.msgUnreadTotal }))
        },
        onClick: () => {
          let _record = {
            id: data.msgId,
            title: data.msgTitle,
            content: data.msgContent,
            msgType: data.msgType,
            createTime: data.msgCreateTime
          }
          this.props.dispatch(setNoticeDetail({ noticeDetail: _record }))
          this.props.history.push({ pathname: '/admin/notifyDetail' })
        }
      })
    }
  }

  // 获取未读消息总数
  getUnReadNotifyCount = () => {
    Api.getUnReadNotifyCount({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.props.dispatch(setUnnoticeCount({ unnoticeCount: +data || 0 }))
      }
    })
  }

  // 渲染消息icon
  renderNotificationIcon = (status) => {
    const obj = noticeTypeMaps.find(item => { return item.id === status })
    const icon = obj !== undefined ? obj.icon : ''

    return icon
  }

  // 渲染消息类型
  renderNoticeTypelabel = (status) => {
    const obj = noticeTypeMaps.find(item => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  render () {
    const { menus = [] } = this.state

    return (
      <Layout style={{ minHeight: '100%' }}>
        <div style={{ width: '200px', overflow: 'hidden', flex: '0px 0px 200px', minWidth: '200px', maxWidth: '200px', minHeight: '100vh' }} />
        <Sider width='200' className={`${styles.leftSider}`}>
          <SideMenu menus={menus} />
        </Sider>

        <Layout style={{ position: 'relative' }}>
          <HeaderBar history={this.props.history} />

          <Content className={`${styles.singlePage}`}>
            <Switch>
              {routerMap.map((route, i) => (
                <Route
                  name={route.name}
                  exact
                  key={i}
                  path={route.path}
                  render={props => (
                    <route.component {...props} />
                  )}
                />
              ))}
            </Switch>
          </Content>

          <Footer className={`${styles.layoutFooter}`}>
            <Row className={`${styles.footerContent}`}>
              <div style={{ marginBottom: '6px' }}>专注工业流通链路的专家</div>
              <div>萨斯云科技(上海)有限责任公司</div>
            </Row>
          </Footer>
        </Layout>
      </Layout>

    )
  }
}

export default connect()(Main)
