import Ajax from '../utils/ajax'

export default {
  // 获取域名列表
  getDomainList (params) {
    return Ajax.post('/domain/list', params)
  },
  getAlertList (params) {
    return Ajax.post('/domain/alert', params)
  },
  getAlertListFifteen (params) {
    return Ajax.post('/domain/alertFifteen', params)
  }
}
