import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table, Button, Icon, Descriptions, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import ProfitModal from '@/pages/cloud-store/brandprofitSetting/profitModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

const { pageSizeOptions } = filter

class CloudSupplier extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '供应商'
        },
        cptParams: {
          placeholder: '请选择供应商...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    detailInfo: {},
    type: 1, // 1-批量设置 2-单个设置
    selectedRowKeys: [],
    rowDetail: {},
    profitModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'cloudSupplierCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '供应商',
      key: 'cloudSupplierCol-1',
      width: 150,
      ellipsis: true,
      dataIndex: 'distributorName'
    },
    {
      title: '地址',
      key: 'cloudSupplierCol-2',
      width: 150,
      ellipsis: true,
      dataIndex: 'address'
    },
    {
      title: '联系方式',
      key: 'cloudSupplierCol-3',
      dataIndex: 'mobile'
    },
    {
      title: '产品类型',
      key: 'cloudSupplierCol-4',
      width: 80,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => {
        const { detailInfo = {} } = this.state
        return (
          <>{ renderSkuGoodsTypeLabel(detailInfo.skuGoodsType) }</>
        )
      }
    },
    {
      title: '库存',
      key: 'cloudSupplierCol-5',
      width: 100,
      dataIndex: 'skuStock'
    },
    {
      title: '供应价格',
      key: 'cloudSupplierCol-6',
      dataIndex: 'supplyPrice',
      render: (text, record, index) => (
        <>&yen;{+record.supplyPrice}</>
      )
    },
    {
      title: '云仓价格',
      key: 'cloudSupplierCol-7',
      dataIndex: 'realSupplyPrice',
      render: (text, record, index) => (
        <>&yen;{+record.realSupplyPrice}</>
      )
    },
    {
      title: '利润率',
      key: 'cloudSupplierCol-8',
      dataIndex: 'profitMargin',
      render: (text, record, index) => (
        <>{ (+record.profitMargin * 100 / 100).toFixed(2) }%</>
      )
    },
    {
      title: '操作',
      key: 'cloudSupplierCol-9',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.showProfitModal(record, 2)}>设置利润</a>
        </>
      )
    }
  ]

  componentDidMount () {
    const { state } = this.props.history.location

    this.setState({
      detailInfo: state.detailInfo || {}
    }, () => {
      this.getSupplierInfoBySupplyProducts()
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state
        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)
        this.setState({
          searchData
        })
      }
    })
  }

  // 获取云产品供应商列表
  getSupplierInfoBySupplyProducts = () => {
    const { pages, query, detailInfo = {} } = this.state
    let params = {
      ...query,
      commoditySkuId: detailInfo.commoditySkuId,
      skuGoodsType: detailInfo.skuGoodsType,
      profitMargin: detailInfo.brandProfitMargin,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSupplierInfoBySupplyProducts(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 利润设置- 云供应产品利润批量设置
  saveProfitSupplySkuBatchSetting = (params) => {
    Api.saveProfitSupplySkuBatchSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getSupplierInfoBySupplyProducts()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getSupplierInfoBySupplyProducts()
    })
  }

  // 点击勾选
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击利润设置
  showProfitModal = (record, type) => {
    this.setState({
      profitModal: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击保存利润设置
  handleSaveProfitSet = (values) => {
    const { detailInfo } = this.state
    const { profitMargin, idList = [] } = values
    const params = {
      profitMargin: profitMargin
    }

    params.profitMarginDTOList = $lodash.map(idList, (item) => {
      return {
        commoditySkuId: detailInfo.commoditySkuId,
        skuGoodsType: detailInfo.skuGoodsType,
        distributorId: item
      }
    })

    this.saveProfitSupplySkuBatchSetting(params)
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  closeModal = () => {
    this.setState({
      type: 1,
      profitModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplierInfoBySupplyProducts()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplierInfoBySupplyProducts()
    })
  }

  render () {
    const { searchData = [], pages, tableData, detailInfo = {}, selectedRowKeys = [], profitModal = false, type = 1, rowDetail = {} } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Button onClick={() => this.handleBack()}><Icon type='left' />返回列表</Button>
          </Card>
        </Row>

        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Descriptions>
              <Descriptions.Item label='品牌'>{detailInfo.brandName}</Descriptions.Item>

              <Descriptions.Item label='型号'>{detailInfo.model}</Descriptions.Item>

              <Descriptions.Item label='订货号'>{detailInfo.itemNumber}</Descriptions.Item>

              <Descriptions.Item label='分类'>{detailInfo.classificationName}</Descriptions.Item>

              <Descriptions.Item label='产品名称'>{detailInfo.name}</Descriptions.Item>

              <Descriptions.Item label='产品类型'>{ renderSkuGoodsTypeLabel(detailInfo.skuGoodsType) }</Descriptions.Item>

            </Descriptions>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <div style={{ marginBottom: '10px' }}>
              <SearchForm
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </div>
            <Row style={{ marginBottom: '10px' }}>
              <Button type='primary' disabled={selectedRowKeys.length === 0}
                onClick={() => this.showProfitModal({}, 1)}
              >勾选批量设置</Button>
            </Row>
            <Row>
              <Table rowKey='distributorId' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
                rowSelection={rowSelection}
              />
            </Row>
          </Card>
        </Row>

        {
          profitModal
            ? <ProfitModal
              type={type}
              detailInfo={rowDetail}
              selectedkeys={type === 1 ? selectedRowKeys : [rowDetail.distributorId]}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.handleSaveProfitSet(params)}
            />
            : null
        }

      </>
    )
  }
}

export default CloudSupplier
