import React, { Component } from 'react'
import { Row, Button, Icon, Badge, message, Pagination } from 'antd'

import EditrecommendModal from '../component/editrecommend-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import styles from './material.module.scss'

const { pageSizeOptions } = filter

export default class MaterialContainer extends Component {
  static propTypes = {}
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    recommendModal: false,
    type: 1,
    rowDetail: {}
  }

  componentDidMount () {
    this.getRecommendMaterialList()
  }

  // 获取推荐模板列表
  getRecommendMaterialList = () => {
    const { pages } = this.state
    let params = {
      type: 'RECOMMEND',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getBannerMaterialList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.resultList || [],
          pages: {
            ...pages,
            total: data.totalNum
          }
        })
      }
    })
  }

  // 保存新增banner模板
  saveAddBannerMaterial = (params) => {
    Api.saveAddBannerMaterial(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getRecommendMaterialList()
      }
    })
  }

  // 更新banner模板
  saveUpdateBannerMaterial = (params) => {
    Api.saveUpdateBannerMaterial(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getRecommendMaterialList()
      }
    })
  }

  // 点击新增/编辑推荐模板
  handleShowEditModal = (record, type) => {
    this.setState({
      recommendModal: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击确定按钮
  handleSaveBannerMaterial = (params) => {
    const { type } = this.state
    if (type === 1) {
      this.saveAddBannerMaterial(params)
    } else {
      this.saveUpdateBannerMaterial(params)
    }
  }

  closeModal = () => {
    this.setState({
      recommendModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getRecommendMaterialList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getRecommendMaterialList()
    })
  }

  render () {
    const { pages, tableData = [], recommendModal = false, type = 1, rowDetail = {} } = this.state

    return (
      <div>
        <Row style={{ marginTop: '24px', marginBottom: '15px' }}>
          <Button type='primary' onClick={() => this.handleShowEditModal({}, 1)}>
            <Icon type='plus' />新增
          </Button>
        </Row>

        {
          tableData.length > 0
            ? <Row>
              <ul className={`${styles.materialBody}`}>
                {
                  tableData.map((item, i) => (
                    <li className={`${styles.materialItem}`} key={`material-${i}`}>
                      <div className={`${styles.picLand}`}>
                        <a href={item.imageKey} target='_blank'>
                          <img src={item.imageKey} />
                        </a>
                      </div>
                      <div className={`${styles.txtLand}`}>{item.name}</div>
                      <div className={`${styles.otherLand}`}>
                        <div className={`${styles.flexItem}`}>
                          <Badge status={item.enable === 'ENABLE' ? 'success' : 'error'}
                            text={item.enable === 'ENABLE' ? '启用中' : '禁用中'}
                          />
                        </div>
                        <div className={`${styles.flexItem} ${styles.operate}`}>
                          <a onClick={() => this.handleShowEditModal(item, 2)}>编辑</a>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>

              <Row style={{ paddingTop: '30px', textAlign: 'center' }}>
                <Pagination
                  showSizeChanger
                  total={pages.total}
                  showTotal={(total) => { return `共 ${total} 条` }}
                  current={pages.pageNo}
                  pageSize={pages.pageSize}
                  pageSizeOptions={pageSizeOptions}
                  onChange={this.pageChange}
                  onShowSizeChange={this.pageSizeChange}
                />
              </Row>
            </Row>
            : null
        }

        {
          recommendModal
            ? <EditrecommendModal
              type={type}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleSaveBannerMaterial(params)}
            />
            : null
        }
      </div>
    )
  }
}
