/** 云仓模块 **/
import CloudProd from '@/pages/cloud-store/cloud-prod/index' // 云库商品管理
import OnlineProd from '@/pages/cloud-store/online-prod/index' // 云仓商品列表

import CloudSuppos from '@/pages/cloud-store/cloud-suppos/index' // 虚拟云库管理
import CloudRule from '@/pages/cloud-store/cloud-rule/index'
import InventDetail from '@/pages/cloud-store/invent-detail/index' // 虚拟库明细
import CloudLibrary from '@/pages/cloud-store/cloud-library/index' // 虚拟云仓
import CloudExamine from '@/pages/cloud-store/cloud-examine/index' // 审核记录列表

import SetspecialPrice from '@/pages/cloud-store/setspecial-price/index' // 云特价设置
import BrandprofitSetting from '@/pages/cloud-store/brandprofitSetting/index' // 云品牌利润设置
import CloudBrand from '@/pages/cloud-store/cloudBrand/index' // 云品牌产品列表
import CloudSupplier from '@/pages/cloud-store/cloudSupplier/index' // 云产品供应商
import SetprofitFlow from '@/pages/cloud-store/setprofitFlow/index' // 利润设置记录
import CloudRecords from '@/pages/cloud-store/cloud-records/index' // 交易记录
import CloudTransactionRecordsNotRate from '@/pages/cloud-store/cloudTransactionRecordsNotRate' // 云交易记录(不含成交利率)

export default [
  {
    path: '/admin/cloudProd',
    name: '云库产品列表',
    component: CloudProd
  },
  {
    path: '/admin/onlineProd',
    name: '云仓产品列表',
    component: OnlineProd
  },
  {
    path: '/admin/cloudSuppos',
    name: '虚拟云库管理',
    component: CloudSuppos
  },
  {
    path: '/admin/cloudlibrary',
    name: '虚拟云仓',
    component: CloudLibrary
  },
  {
    path: '/admin/cloudRule',
    name: '规则设置',
    component: CloudRule
  },
  {
    path: '/admin/inventDetail',
    name: '虚拟库明细',
    component: InventDetail
  },
  {
    path: '/admin/cloudExamine',
    name: '审核列表',
    component: CloudExamine
  },
  {
    path: '/admin/setSpecialPrice',
    name: '云特价设置',
    component: SetspecialPrice
  },
  {
    path: '/admin/brandprofitSetting',
    name: '利润设置',
    component: BrandprofitSetting
  },
  {
    path: '/admin/cloudBrand',
    name: '品牌详情',
    component: CloudBrand
  },
  {
    path: '/admin/cloudSupplier',
    name: '云供应商详情',
    component: CloudSupplier
  },
  {
    path: '/admin/setprofitFlow',
    name: '利润设置记录',
    component: SetprofitFlow
  },
  {
    path: '/admin/cloudrecords',
    name: '云交易记录（内部）',
    component: CloudRecords
  },
  {
    path: '/admin/cloudTransactionRecordsNotRate',
    name: '云交易记录（外部）',
    component: CloudTransactionRecordsNotRate
  }
]
