import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Radio, Upload, Icon, Row, message } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './advert.module.scss'

const FormItem = Form.Item

const { uploadOrdinaryImageUrl } = UploadApi
const IMGSIZE = 3

class EditbannerForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-编辑
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    imageMap: [
      { id: '0', name: '740*460', imageKey: '' },
      { id: '1', name: '1920*450', imageKey: '' }
    ]
  }

  componentDidMount () {
    const { type = 1, detailInfo = {} } = this.props
    let { imageMap } = this.state

    if (type === 2) {
      detailInfo.imageList.map(item => {
        imageMap.map(record => {
          if (+item.type === +record.id) {
            record.imageKey = item.imageKey
          }
        })
      })

      this.setState({
        imageMap
      })
    }
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, detailInfo } = this.props
        const { imageMap } = this.state

        let i = imageMap.findIndex(item => {
          return item.imageKey === ''
        })

        if (i > -1) {
          return message.warning(`请上传 ${imageMap.length} 张不同尺寸图片`)
        }

        if (type === 2) {
          values.id = detailInfo.id
        }

        let imageList = []
        imageMap.map(item => {
          let obj = {
            imageKey: item.imageKey,
            type: item.id
          }

          imageList.push(obj)
        })

        values['imageList'] = imageList

        this.props.onConfirm(values)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMGSIZE
    if (!isLt1M) {
      message.warning(`图片大小不可超过 ${IMGSIZE}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  changeUploadBannerImage = (info, i) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        let { imageMap = [] } = this.state

        imageMap[i].imageKey = data.imageUrl

        this.setState({
          imageMap
        })
      } else {
        message.error('上传失败')
      }
    }
  }

  // 前端删除图片
  removeImg = (i) => {
    let { imageMap } = this.state

    imageMap[i].imageKey = ''

    this.setState({
      imageMap
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { imageMap = [] } = this.state

    return (
      <div>
        <Modal title={`banner广告`}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem label='名称'>
              {
                getFieldDecorator('name', {
                  initialValue: detailInfo.name || '',
                  rules: [
                    { required: true, message: '请输入名称' }
                  ]
                })(
                  <Input placeholder='请输入名称' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='启用状态'>
              {
                getFieldDecorator('enable', {
                  initialValue: detailInfo.enable || 'ENABLE',
                  rules: [
                    { required: true, message: '请选择启用状态' }
                  ]
                })(
                  <Radio.Group>
                    <Radio value={'ENABLE'}>启用</Radio>
                    <Radio value={'DISABLE'}>禁用</Radio>
                  </Radio.Group>
                )
              }
            </FormItem>

            <Row>
              {
                imageMap.map((item, i) => (
                  <Row style={{ display: 'inline-block' }} key={`imageItem-${i}`}>
                    <label className={`formLabel required`}>{`图片 ${item.name}`}</label>
                    {
                      <Row>
                        <div className={`${styles.inline}`} key={`uploadBanner`}>
                          {
                            item.imageKey !== ''
                              ? (
                                <div className={styles.thumbWrap}>
                                  <div className={styles.thumbInfo}>
                                    <img src={item.imageKey} />

                                    <a className={styles.prewModal}>
                                      <Icon type='delete' style={{ fontSize: '20px' }}
                                        onClick={() => this.removeImg(i)}
                                      />
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <Upload
                                  showUploadList={false}
                                  listType='picture-card'
                                  accept='image/png, image/jpg, image/jpeg'
                                  headers={{ Token: getStore('token') }}
                                  action={uploadOrdinaryImageUrl}
                                  beforeUpload={this.beforeUpload}
                                  onChange={(info) => this.changeUploadBannerImage(info, i)}
                                >
                                  <Icon type='plus' />
                                </Upload>

                              )
                          }
                        </div>
                      </Row>
                    }
                  </Row>
                ))
              }

            </Row>
          </Form>

        </Modal>
      </div>
    )
  }
}

const EditbannerModal = Form.create()(EditbannerForm)

export default EditbannerModal
