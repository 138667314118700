/* 询价单预览组件 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

import { renderMatchingLabel } from '@/common/utils/mUtils'

export default class EnquirePreview extends Component {
  static propTypes = {
    iscustomRowNum: PropTypes.bool, // 是否自定义行号
    list: PropTypes.array
  }
  state = {

  }

  columns = [
    {
      title: '行号',
      key: 'previewEnquireTableCol-0',
      width: 44,
      align: 'center',
      dataIndex: 'rownum',
      render: (text, record, index) => {
        const { iscustomRowNum = false } = this.props

        return <>{ iscustomRowNum ? `${index + 1}` : record.rownum }</>
      }
    },
    {
      title: '品牌',
      key: 'previewEnquireTableCol-1',
      width: 200,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'previewEnquireTableCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'previewEnquireTableCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'previewEnquireTableCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'previewEnquireTableCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'previewEnquireTableCol-6',
      dataIndex: 'quantity'

    },
    {
      title: '期望价格',
      key: 'previewEnquireTableCol-7',
      dataIndex: 'expectPrice',
      render: (text, record, index) => (
        <>{ record.expectPrice ? <> &yen;{record.expectPrice}</> : null } </>
      )
    },
    {
      title: '期望折扣',
      key: 'previewEnquireTableCol-8',
      dataIndex: 'expectDiscount',
      render: (text, record, index) => (
        <>{record.expectDiscount ? <>{(+record.expectDiscount * 100 / 100).toFixed(2)}%</> : null}</>
      )
    },
    {
      title: '期望货期',
      key: 'previewEnquireTableCol-9',
      dataIndex: 'expectDeliveryPeriodDays',
      render: (text, record, index) => (
        <>
          { +record.expectDeliveryPeriodDays > 0 ? `${+record.expectDeliveryPeriodDays}天` : '现货' }
        </>
      )
    },
    {
      title: '数据类型',
      key: 'previewEnquireTableCol-10',
      dataIndex: 'matchingState',
      render: (text, record, index) => (
        <>{ renderMatchingLabel(record.matchingState, record.dataType) }</>
      )
    }
  ]

  render () {
    const { list = [] } = this.props

    return (
      <>
        <h3 style={{ marginBottom: '12px' }}>询价单预览</h3>
        <Table bordered rowKey={(record, index) => { return record.id || record.rowKey }}
          columns={this.columns}
          dataSource={list}
          pagination={false}
        />
      </>
    )
  }
}
