import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Select, Input, Radio, Upload, Button, Icon, message } from 'antd'

import CourierJson from '@/assets/json/courier.json'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import styles from './express.module.scss'

const FormItem = Form.Item
const Option = Select.Option
const courierList = [ // 快递公司列表
  ...CourierJson
]

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

class InvoiceForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    info: PropTypes.object
  }
  state = {}

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        message.success('上传成功')
        setTimeout(() => {
          this.props.form.setFieldsValue({
            invoiceImageUrl: data.imageUrl
          })
        }, 30)
      } else {
        message.error('上传文件失败')
      }
    }
  }

  render () {
    const { info = {} } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <Form>
          <Row gutter={24}>
            <Col span={12}>
              <Row className={`${styles.formitemRow}`}>
                <FormItem label='物流公司'>
                  {
                    getFieldDecorator('courierCode', {
                      initialValue: info.courierCode || '',
                      rules: [
                        { required: true, message: '请选择物流公司' }
                      ]
                    })(
                      <Select placeholder='请选择...'
                        showSearch
                        optionFilterProp='children'
                        onChange={this.handleChangeLogistics}
                      >
                        {
                          courierList.map((item, i) => (
                            <Option value={item.courierCode} key={item.courierCode}>
                              { item.courierName }
                            </Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
              </Row>
            </Col>

            <Col span={12}>
              <Row className={`${styles.formitemRow}`}>
                <FormItem label='快递单号'>
                  {
                    getFieldDecorator('courierNumber', {
                      initialValue: info.courierNumber || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Row>
            </Col>

            <Col span={12}>
              <Row className={`${styles.formitemRow}`}>
                <FormItem>
                  {
                    getFieldDecorator('radioType', {
                      initialValue: info.invoiceImageUrl && info.invoiceImageUrl !== '' ? 'INVOICEIMG' : 'INVOICENO'
                    })(
                      <Radio.Group>
                        <Radio value={'INVOICENO'}>发票号码</Radio>
                        <Radio value={'INVOICEIMG'}>发票图片</Radio>
                      </Radio.Group>
                    )
                  }
                </FormItem>
              </Row>
            </Col>

            <Col span={12}>
              {
                this.props.form.getFieldValue('radioType') === 'INVOICENO'
                  ? <Row className={`${styles.formitemRow}`}>
                    <FormItem label='发票号码'>
                      {
                        getFieldDecorator('invoiceCode', {
                          initialValue: info.invoiceCode || '',
                          rules: [
                            { required: true, message: '请填写发票号码' }
                          ]
                        })(
                          <Input placeholder='请输入...' autoComplete='off' />
                        )
                      }
                    </FormItem>
                  </Row>
                  : <Row className={`${styles.uploadformitemRow}`}>
                    <Row className={`${styles.visibilityFormitem}`}>
                      <FormItem>
                        {
                          getFieldDecorator('invoiceImageUrl', {
                            initialValue: info.invoiceImageUrl || '',
                            rules: [
                              { required: true, message: '请上传发票图片' }
                            ]
                          })(
                            <Input autoComplete='off' disabled />
                          )
                        }
                      </FormItem>
                    </Row>

                    <Row>
                      <Row className={`${styles.cuslabelWrap}`}>
                        <label className={`${styles.cusformLabel} ${styles.required}`}>发票图片</label>
                      </Row>
                      <FormItem style={{ display: 'inline-block', width: '120px', marginBottom: '0px' }}>
                        <Upload name='file' showUploadList={false}
                          accept='image/png, image/jpg, image/jpeg'
                          headers={{ Token: getStore('token') }}
                          action={uploadOrdinaryImageUrl}
                          beforeUpload={this.beforeUpload}
                          onChange={(info) => this.handleChangeUpload(info)}
                        >
                          <Button>
                            <Icon type='upload' />点击上传
                          </Button>
                        </Upload>
                      </FormItem>
                      <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {
                          this.props.form.getFieldValue('invoiceImageUrl') !== undefined && this.props.form.getFieldValue('invoiceImageUrl') !== ''
                            ? <a href={this.props.form.getFieldValue('invoiceImageUrl')} target='_blank'>
                              {this.props.form.getFieldValue('invoiceImageUrl')}
                            </a>
                            : null
                        }
                      </Row>
                    </Row>
                  </Row>
              }
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default Form.create()(InvoiceForm)
