/* 利润设置API */
import Ajax from '../utils/ajax'

export default {

  // 利润设置- 品牌利润列表
  getProfitListPageForSupplyBrand (params) {
    return Ajax.post('/oms/api/profitMargin/listPageForSupplyBrand', params)
  },

  // 利润设置- 单品牌利润设置
  saveProfitSingleSetting (params) {
    return Ajax.post('/oms/api/profitMargin/singleSetting', params)
  },

  // 利润设置- 批量品牌利润设置
  saveProfitBatchSetting (params) {
    return Ajax.post('/oms/api/profitMargin/batchSetting', params)
  },

  // 利润设置- 品牌产品详情
  getSupplyProductsDetail (params) {
    return Ajax.post('/oms/api/profitMargin/supplyProductsDetail', params)
  },

  // 利润设置- 产品供应商详情
  getSupplierInfoBySupplyProducts (params) {
    return Ajax.post('/oms/api/profitMargin/supplierInfoBySupplyProducts', params)
  },

  // 利润设置- 云供应产品利润设置
  saveProfitSupplySkuBatchSetting (params) {
    return Ajax.post('/oms/api/profitMargin/supplySkuBatchSetting', params)
  },

  // 利润设置- 虚拟云库产品利润设置
  saveProfitInventSkuBatchSetting (params) {
    return Ajax.post('/oms/api/profitMargin/skuBatchSettingFoCloudMerchant', params)
  },

  // 利润设置- 利润设置记录
  getProfitSettingFlow (params) {
    return Ajax.post('/oms/api/profitMargin/listPageForProductsProfitMargin', params)
  }

}
