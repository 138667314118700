import React, { Component } from 'react'
import { Modal, Table, Row } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class details extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    oncloseModal: PropTypes.func
  }
  state={
    searchData: [
      {
        name: 'dueMonth',
        formType: 'MonthPicker',
        itemParams: {
          label: '发货日期'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns= [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'service-1',
      dataIndex: 'orderId'
    },
    {
      title: '账单日期',
      key: 'service-2',
      render: (text, record, index) => {
        const { detailInfo = {} } = this.props

        return (
          <div>
            { detailInfo.dueMonth || '----' }
          </div>
        )
      }
    },
    {
      title: '运单号',
      key: 'service-3',
      render: (text, record, index) => (
        <div>{ record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----'}</div>
      )
    },
    {
      title: '发货人',
      key: 'service-4',
      dataIndex: 'senderName'
    },

    {
      title: '运费金额',
      key: 'service-6',
      render: (text, record, index) => (
        <>
          &yen;{ ((+record.totalPrice - (+record.transportPrice) + (+record.merchantTransportPrice)) * 100 / 100).toFixed(2) }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getCusExpressList()
  }

  // 获取快递对账单详情列表
  getCusExpressList = () => {
    const { pages, query = {} } = this.state
    let params = {
      distributorId: this.props.detailInfo.distributorId,
      releaseStatus: 'RELEASE',
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCusExpressList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    search['dueMonth'] = search.dueMonth ? moment(search.dueMonth).format('YYYYMM') : undefined

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCusExpressList()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCusExpressList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCusExpressList()
    })
  }

  render () {
    const { searchData = [], pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Modal title='商户账单明细' width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'auto' }}
          visible
          maskClosable={false}
          onOk={this.props.oncloseModal}
          onCancel={this.props.oncloseModal}
        >
          <Row style={{ marginBottom: '15px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>

          <Row>
            <Table rowKey={(record, index) => `distributorDetails-${index}`} bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Row>

        </Modal>
      </>
    )
  }
}
