import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Col, Icon, Modal, Button, message } from 'antd'
import $lodash from 'lodash'

import DispenseIntro from './dispenseIntro'
import DispenseAction from './dispenseAction'
import DispenseOrderTotals from './orderTotals/index'

import Api from '@/common/api/index'
const { confirm } = Modal

class DispenseOrder extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    detailInfo: {},
    tableData: [],
    amountDetails: {}, // 采购金额
    showPage: false
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    if (!state || !state.orderId) {
      return message.warning('页面参数不正确')
    }

    this.setState({
      detailInfo: state
    }, () => {
      this.getDispensePurchProductDetail()
    })
  }

  // 获取待分发订单产品明细
  getDispensePurchProductDetail = async () => {
    const { detailInfo = {} } = this.state

    const res = await Api.getDispensePurchProductDetail({ orderId: detailInfo.orderId })
    const { code, data } = res
    if (+code === 10000) {
      let _list = data || []
      if (_list.length > 0) {
        _list.map((record, i) => {
          record.expandData = []
          if (record.purchaseOrderGoodsDetail && record.purchaseOrderGoodsDetail.length > 0) {
            record.purchaseOrderGoodsDetail.map((item, index) => {
              record.expandData.push(
                [
                  {
                    quantity: item.quantity,
                    distributorId: item.supplierOrderGoodsDetail.length > 0 ? item.supplierOrderGoodsDetail[0].supplierId : '',
                    sellingPrice: item.supplierOrderGoodsDetail.length > 0 ? item.supplierOrderGoodsDetail[0].sellingPrice : '',
                    skuGoodsType: item.skuGoodsType,
                    prepaymentRatio: item.supplierOrderGoodsDetail.length > 0 ? item.supplierOrderGoodsDetail[0].prepaymentRatio : 0
                  }
                ])
            })
          }
        })
      }

      let _amountDetails = this.calcOrderAmountDetails(_list)

      this.setState({
        tableData: _list,
        amountDetails: _amountDetails,
        showPage: true
      })
    }
  }

  // 分配采购单
  dispensePurchProduct = (params) => {
    Api.dispensePurchProduct(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '订单分发成功！',
          onOk: () => {
            this.handleBack()
          }
        })
      }
    })
  }

  // 删除
  removeListItem = (i, n, index) => {
    let { tableData = [] } = this.state
    tableData[index].expandData[i].splice(n, 1)

    let _amountDetails = this.calcOrderAmountDetails(tableData)

    this.setState({
      tableData: [...tableData],
      amountDetails: _amountDetails
    })
  }

  // 监听分发的数量
  handleChangeDispenseNo = (key, i, n, index) => {
    let { tableData = [] } = this.state
    tableData[index].expandData[i][n].quantity = key

    let _amountDetails = this.calcOrderAmountDetails(tableData)

    this.setState({
      tableData: [...tableData],
      amountDetails: _amountDetails
    })
  }

  // 选择供应商
  handleChangeRsSupplier = (key, i, n, index, item) => {
    let { tableData = [] } = this.state
    let _list = item.supplierOrderGoodsDetail
    let obj = $lodash.find(_list, (obj) => { return obj.supplierId === key })

    tableData[index].expandData[i][n].distributorId = key
    tableData[index].expandData[i][n].sellingPrice = obj.sellingPrice
    tableData[index].expandData[i][n].prepaymentRatio = obj.prepaymentRatio

    let _amountDetails = this.calcOrderAmountDetails(tableData)

    this.setState({
      tableData: [...tableData],
      amountDetails: _amountDetails
    })
  }

  // 点击增加分配
  handleAddListItem = (i, n, index, item) => {
    let { tableData = [] } = this.state
    let length = (item.supplierOrderGoodsDetail.length)

    const obj = {
      quantity: 0,
      distributorId: length > 0 ? item.supplierOrderGoodsDetail[0].supplierId : null,
      sellingPrice: length > 0 ? item.supplierOrderGoodsDetail[0].sellingPrice : '',
      skuGoodsType: item.skuGoodsType || '',
      prepaymentRatio: length > 0 ? item.supplierOrderGoodsDetail[0].prepaymentRatio : 0
    }

    tableData[index].expandData[i].splice(n + 1, 1, obj)

    let _amountDetails = this.calcOrderAmountDetails(tableData)

    this.setState({
      tableData: [...tableData],
      amountDetails: _amountDetails
    })
  }

  // 点击删除
  handleRemoveListItem = (i, n, index) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.removeListItem(i, n, index)
      }
    })
  }

  // 计算订单相关金额
  calcOrderAmountDetails = (tableData = []) => {
    let newdatas = []

    for (const record of tableData) {
      const { expandData = [] } = record
      let newArr = $lodash.flattenDeep(expandData)

      newdatas = [...newdatas, ...newArr]
    }

    // 采购总金额
    let _totalAmount = $lodash.sumBy(newdatas, (o) => { return +o.sellingPrice * +o.quantity })

    // 现货总金额
    let _spotTotalAmount = $lodash.sumBy(newdatas, (o) => { return o.skuGoodsType === 'CLOUD_SPOT' && o.sellingPrice * o.quantity })

    // 期货定金
    let _prepaidAmount = $lodash.sumBy(newdatas, (o) => { return o.skuGoodsType === 'CLOUD_FUTURES' && +o.prepaymentRatio > 0 && +o.sellingPrice * +o.quantity * o.prepaymentRatio / 100 })

    let amountDetails = {
      totalAmount: _totalAmount, // 采购总金额
      spotTotalAmount: _spotTotalAmount, // 现货总金额
      prepaidAmount: _prepaidAmount // 期货定金
    }

    return amountDetails
  }

  // 点击提交订单
  handleSubmitOrder = () => {
    let { tableData = [], detailInfo = {} } = this.state
    let setflay = []

    /// 测试
    let list = []
    tableData.map((row, index) => {
      row.expandData.map((record, i) => {
        let obj = {
          commoditySkuId: row.commoditySkuId,
          allocateToDisList: [],
          totalQuantity: row.totalQuantity
        }

        const newArr = $lodash.filter(record, (etem) => { return etem.distributorId && +etem.quantity > 0 })

        newArr.map(item => {
          obj.skuGoodsType = item.skuGoodsType

          obj.allocateToDisList.push({
            distributorId: item.distributorId,
            quantity: item.quantity,
            sellingPrice: item.sellingPrice
          })
        })

        list.push(obj)
      })
    })

    let params = {
      allocateList: list,
      orderId: detailInfo.orderId
    }

    params.allocateList.map((item, i) => {
      if (item.allocateToDisList.length === 0) {
        setflay.push(i)
      }
    })

    if (setflay.length > 0) {
      let setflaynew = setflay.map((item) => { return item + 1 }).join(',')

      return message.warn(`第${setflaynew}条单子存在未选择供应商`)
    }

    this.dispensePurchProduct(params)
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '订单分发成功！',
      content: `${secondsToGo} s后将前往待分发订单页面`,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.handleBack()
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `${secondsToGo} s后将前往待分发订单页面`
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.handleBack()
    }, secondsToGo * 1000)
  }

  // 点击返回
  handleBack = () => {
    this.props.history.push({ pathname: '/admin/undispenseOrder' })
  }

  render () {
    const { showPage = false, detailInfo = {}, tableData = [], amountDetails = {} } = this.state

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}><Icon type='left' />返回列表</Button>
        </Row>

        {
          tableData.map((record, index) => (
            record.purchaseOrderGoodsDetail.map((item, i) => (
              <Row style={{ marginBottom: '15px' }} key={`dispenseProduct-${i}`}>
                <Card bodyStyle={{ padding: '12px' }}>
                  <Row gutter={24}>
                    <Col span={8}>
                      <DispenseIntro
                        detailInfo={detailInfo}
                        record={record}
                        item={item}
                      />
                    </Col>

                    <Col span={16}>
                      <DispenseAction
                        detailInfo={record}
                        detailInfoid={item}
                        list={record.expandData}
                        onAdd={(n) => this.handleAddListItem(i, n, index, item)}
                        onDelete={(n) => this.handleRemoveListItem(i, n, index)}
                        onChangeQuantity={(value, n) => this.handleChangeDispenseNo(value, i, n, index)}
                        onSelectSupplier={(id, n) => this.handleChangeRsSupplier(id, i, n, index, item)}
                        i={i}
                      />
                    </Col>
                  </Row>
                </Card>
              </Row>
            ))
          ))
        }

        {
          showPage
            ? <Card bodyStyle={{ padding: '24px 12px' }}>
              <DispenseOrderTotals
                totalAmount={amountDetails.totalAmount || 0}
                spotTotalAmount={amountDetails.spotTotalAmount || 0}
                prepaidAmount={amountDetails.prepaidAmount || 0}
              />
              <div style={{ textAlign: 'center' }}>
                <Button type='primary' onClick={() => this.handleSubmitOrder()}>提交订单</Button>
              </div>
            </Card>
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(DispenseOrder)
