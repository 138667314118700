import React, { Component } from 'react'
import { Tabs, Card, Row } from 'antd'
import Sf from './shunfindex' // 顺丰
import Deppon from './depponindex' // 德邦
import styles from './commont.module.scss'
const { TabPane } = Tabs
const tablist = [
  { key: 'SAAS-DBL', name: '德邦快递' },
  { key: 'SAAS-SF', name: '顺丰快递' }
]

export default class index extends Component {
  state = {
    activekey: 'SAAS-DBL'
  }
  onChangeTab=(key) => {
    this.setState({
      activekey: key
    })
  }
  render () {
    const { activekey = 'SAAS-DBL' } = this.state
    return (

      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <Row className={`${styles.tabWrap}`}>
              <Tabs activeKey={activekey} onChange={(key) => { this.onChangeTab(key) }} >
                {
                  tablist.map((item) => (
                    <TabPane tab={item.name} key={item.key} />
                  ))
                }
              </Tabs>
            </Row>
          </Card>
        </Row>
        {
          activekey === 'SAAS-DBL' ? <Deppon key={activekey} /> : null
        }
        {
          activekey === 'SAAS-SF' ? <Sf key={activekey} /> : null
        }
      </div>

    )
  }
}
