import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Radio, message, Descriptions } from 'antd'

import Api from '@/common/api/index'

const modalTypeMaps = [
  { type: 'paycreditModal', key: 'CREDIT_PAY', label: '信用支付' },
  { type: 'paybankModal', key: 'BANK_CARD_PAY', label: '银行转账' },
  { type: 'paymationModal', key: 'BANK_LINKAGE_PAY', label: '记账宝支付' },
  { type: 4, key: 'ALI_PAY', label: '支付宝支付' },
  { type: 5, key: 'WECHAT_PAY', label: '微信支付' }
]

export default class PayselectModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    paymethodList: [],
    selectedKey: ''
  }

  componentDidMount () {
    this.getPaymethodListToMerchant()
  }

  // 获取支付方式
  getPaymethodListToMerchant = () => {
    const { detailInfo } = this.props
    let params = {
      purchaseOrderId: detailInfo.purchaseOrderId
    }
    Api.getPaymethodListToMerchant(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _arr = []
        if (data && data.payTypeList && data.payTypeList.length > 0) {
          _arr = data.payTypeList.filter(item => {
            return +item.payTypeStatus === 1
          })
        }

        this.setState({
          paymethodList: _arr
        })
      }
    })
  }

  handleConfirm = () => {
    const { selectedKey = '' } = this.state

    if (selectedKey === '') {
      return message.warning('请选择支付方式')
    }

    let i = modalTypeMaps.findIndex(item => { return item.key === selectedKey })

    let query = {
      paymethod: selectedKey,
      type: i > -1 ? modalTypeMaps[i].type : ''
    }

    this.props.onConfirm(query)
  }

  // 选择支付方式
  handleChangePaymethod = (key) => {
    this.setState({
      selectedKey: key
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { paymethodList = [] } = this.state

    return (
      <>
        <Modal title='支付方式' width={800} bodyStyle={{ padding: '16px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Row style={{ marginBottom: '12px' }}>
              <Descriptions>
                <Descriptions.Item label='订单金额'>
                  &yen;{(+detailInfo.payableAmount * 100 / 100).toFixed(2)}
                </Descriptions.Item>

                <Descriptions.Item label='应付金额'>
                  &yen;{(+detailInfo.realPaymentAmount * 100 / 100).toFixed(2)}
                </Descriptions.Item>

                <Descriptions.Item label='订单号'>
                  {detailInfo.purchaseOrderId}
                </Descriptions.Item>
              </Descriptions>
            </Row>
            <Row>
              <div>支付方式</div>
              <div style={{ marginTop: '12px' }}>
                <Radio.Group buttonStyle='solid'
                  onChange={(e) => this.handleChangePaymethod(e.target.value)}
                >
                  {
                    paymethodList.map((item, i) => (
                      <Radio.Button value={item.payTypeKey} key={`paymethodItem-${i}`}>{item.payTypeDesc}</Radio.Button>
                    ))
                  }
                </Radio.Group>
              </div>
            </Row>
          </Row>
        </Modal>
      </>
    )
  }
}
