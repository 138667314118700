import React, { Component } from 'react'
import { Card, Tabs, Icon, Input, Row, Form, Upload, Button, Modal, message } from 'antd'

import { list } from './json'
import { ReactSortable } from 'react-sortablejs'
import styles from './index.module.scss'
import Api from '@/common/api/index'
import { getStore } from '@/common/utils/mUtils'
import UploadApi from '@/common/api/uploadApi'
const { uploadOrdinaryImageUrl } = UploadApi
const { TabPane } = Tabs
const FormItem = Form.Item

class BrandImage extends Component {
  state={
    activeKey: 'A',
    datalist: [],
    activeDery: 0
  }

  componentDidMount () {
    let param = {
      label: 'A'
    }
    this.listByAll(param)
  }

  // 获取品牌

  listByAll = (param) => {
    Api.listByAll(param).then(res => {
      const { code, data } = res
      let obj = {
        brandUrlNormal: '',
        brandName: '',
        brandLinkUrl: ''

      }
      if (+code === 10000) {
        this.setState({
          datalist: data && data[0] ? data[0].brandLogoList : [obj]
        })
      }
    })
  }
  // 拖动排序
  handleChangeDragWidget = (newState) => {
    this.setState({
      datalist: newState
    })
  }

  // 渲染title
  getTitle = () => {
    return (
      <div style={{ color: 'red' }}>注意：请上传尺寸为218*102的品牌图片</div>
    )
  }

  // 点击加号
  handleAdd = (i) => {
    const { datalist } = this.state
    datalist.splice(i + 1, 0, { id: `BANNER-${new Date().getTime()}`, brandName: '', brandLinkUrl: '', brandUrlNormal: '' })

    this.setState({
      datalist: datalist
    })
  }

  // 点击删除
  hanldDelete = (i) => {
    Modal.confirm({
      title: '确定删除？',
      onOk: () => { this.deleteOk(i) }
    })
  }

  deleteOk =(i) => {
    const { datalist } = this.state
    if (datalist.length === 1) {
      return message.warning('至少要留一个哦~你可以选择不点击保存')
    }

    datalist.splice(i, 1)

    this.setState({
      datalist: datalist
    })
  }

  // 点击删除图片
  removeImg = (i) => {
    const { datalist } = this.state

    datalist[i].brandUrlNormal = ''

    this.setState({
      datalist
    })
  }

  // 编辑品牌名称
  handleName = (e, i) => {
    const { datalist } = this.state
    datalist[i].brandName = e.target.value
    this.setState({
      datalist
    })
  }

  // 编辑品牌链接
  handleUrl = (e, i) => {
    const { datalist } = this.state
    datalist[i].brandLinkUrl = e.target.value
    this.setState({
      datalist
    })
  }

  // Tabs面板选择
  handleTabs = (activeKey) => {
    this.setState({
      activeKey: activeKey
    }, () => {
      this.listByAll({ label: activeKey })
    })
  }

  // 上传图片
  handleChangeUpload = (info, i) => {
    if (info.file.status === 'done') {
      let { datalist } = this.state
      const { data } = info.file.response

      datalist[i].brandUrlNormal = data.imageUrl

      this.setState({
        datalist
      })
    }
  }

  // 点击保存
  hanldSave = () => {
    const { activeKey } = this.state
    const { datalist } = this.state
    if (datalist.length > 0) {
      datalist.map(item => (
        delete item.id
      ))
    }

    let param = {
      label: activeKey,
      brandLogoList: datalist
    }
    Api.listByAllsave(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('保存成功')
        this.listByAll(param)
      }
    })
  }

  handleDreg = (item) => {
    this.setState({
      activeDery: item.id
    })
  }

  render () {
    const { datalist = [], activeKey = 'A', activeDery } = this.state
    const tailFormItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    }

    return (
      <>
        <Card style={{ marginTop: '15px' }} bodyStyle={{ padding: 0 }} title={this.getTitle()}>
          <Tabs className={styles.Tabs} activeKey={activeKey} onChange={(activeKey) => { this.handleTabs(activeKey) }}>
            {
              list.map((item, i) => (
                <TabPane tab={item.dec} key={item.dec} />
              ))
            }
          </Tabs>
        </Card>
        <Row>
          <Card style={{ marginTop: '15px' }} bodyStyle={{ padding: '12px' }}>
            <Form className={styles.from}>
              <ReactSortable animation={150} handle={'.draghandle'} className={styles.main}
                list={datalist}
                ghostClass={styles.ghostClass}
                setList={newState => this.handleChangeDragWidget(newState)}>
                {
                  datalist.map((item, i) => (
                    <div className={`${styles.content} ${activeDery === item.id ? styles.isactive : styles.noactive}`} key={i} onClick={() => { this.handleDreg(item) }} >
                      {
                        activeDery === item.id
                          ? <div className={`draghandle ${styles.drag}`} ><a style={{ color: 'white' }}><Icon type='drag' style={{ fontSize: '25px' }} /></a></div>
                          : null
                      }
                      <div className={styles.conteninfo}>
                        <div className={styles.Uploadimg}>
                          {
                            !item.brandUrlNormal || item.brandUrlNormal === ''
                              ? <Upload name='file' showUploadList={false} listType='picture-card' style={{ width: '60px', height: '60px' }}
                                accept='image/png, image/jpg, image/jpeg'
                                headers={{ Token: getStore('token') }}
                                action={uploadOrdinaryImageUrl}
                                beforeUpload={this.beforeUpload}
                                onChange={(info) => this.handleChangeUpload(info, i)}
                              >
                                <div>
                                  <Icon type='plus' />
                                  <div style={{ fontSize: '12px' }}>点击上传</div>
                                </div>
                              </Upload>
                              : <div key={i} className={styles.imgmain}>
                                <div className={styles.showdivimg}>
                                  <img src={item.brandUrlNormal} className={styles.imgshow} />
                                </div>

                                <div className={styles.mainmask}>
                                  <div className={styles.mask}>
                                    <a><Icon type='delete' style={{ fontSize: '20px' }} onClick={() => this.removeImg(i)} /></a>
                                  </div>
                                </div>
                              </div>

                          }
                        </div>
                        <div className={styles.divform}>
                          <FormItem label='品牌名称' {...tailFormItemLayout} >
                            <Input placeholder='请输入品牌名称' autoComplete='off' size='small'
                              value={item.brandName}
                              onChange={(e) => { this.handleName(e, i) }}

                            />
                          </FormItem>

                          <FormItem label='跳转链接' {...tailFormItemLayout}>
                            <Input placeholder='请输入跳转链接' autoComplete='off' size='small'
                              value={item.brandLinkUrl}
                              onChange={(e) => { this.handleUrl(e, i) }}
                            />
                          </FormItem>
                        </div>
                      </div>
                      {
                        activeDery === item.id
                          ? <>
                            <div className={styles.operate}>
                              <Icon type='plus' style={{ fontSize: '18px', color: 'white', paddingLeft: '5px' }} onClick={() => { this.handleAdd(i) }} />
                              <Icon type='delete' style={{ fontSize: '18px', color: 'white', paddingRight: '5px' }} onClick={() => { this.hanldDelete(i) }} />
                            </div>
                          </>
                          : null
                      }

                    </div>

                  ))
                }
              </ReactSortable>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              <Button type='primary' onClick={() => { this.hanldSave() }}>保存</Button>
            </div>
          </Card>
        </Row>
      </>
    )
  }
}

export default BrandImage
