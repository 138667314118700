import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'

import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './dispense.module.scss'

export default class DispenseIntro extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    record: PropTypes.object,
    item: PropTypes.object
  }

  render () {
    const { detailInfo = {}, record, item } = this.props

    return (
      <div className={`${styles.introWrap}`}>
        <div className={styles.border}>

          <Row style={{ margin: '10px' }}>
            <Row className={`${styles.introRow}`}>
              <label className={`${styles.rowLabel}`}>订单编号</label>
              <span>{detailInfo.orderId}</span>
            </Row>

            <Row className={`${styles.introRow}`}>
              <label className={`${styles.rowLabel}`}>订单金额</label>
              <span>&yen;{(+detailInfo.payableAmount * 100 / 100).toFixed(2)}</span>
            </Row>

            <Row className={`${styles.introRow}`}>
              <label className={`${styles.rowLabel}`}>采&nbsp;&nbsp;购&nbsp;&nbsp;方</label>
              <span>{detailInfo.merchantName}</span>
            </Row>
          </Row>

          <Row className={`${styles.orderContainer}`} style={{ margin: '10px' }}>
            <Row className={`${styles.rowHeader}`} >
              <div className={`${styles.titleCol}`}>
                <span className={`${styles.brand}`}>{record.brandName}</span>
                <span className={`${styles.name}`} title={record.name}>
                  {record.name}
                </span>
              </div>

              <div className={`${styles.titleCol}`}>
                <label className={`${styles.rowLabel}`}>订&nbsp;&nbsp;货&nbsp;&nbsp;号</label>
                <span className={`${styles.colContainer}`} title={record.itemNumber}>
                  {record.itemNumber}
                </span>
              </div>

              <div className={`${styles.titleCol}`}>
                <label className={`${styles.rowLabel}`}>产品类型</label>
                <span className={`${styles.colContainer}`}>{ renderSkuGoodsTypeLabel(item.skuGoodsType) }</span>
              </div>

              <div className={`${styles.titleCol}`}>
                <label className={`${styles.rowLabel}`}>采购数量</label>
                <span className={`${styles.colContainer} ${styles.quantity}`}>{item.quantity}</span>
              </div>
              <div className={`${styles.titleCol}`}>
                <label className={`${styles.rowLabel}`}>采购价格</label>
                <span className={`${styles.colContainer} ${styles.quantity}`}>{item.sellingPrice}</span>
              </div>

            </Row>
          </Row>
        </div>

      </div>
    )
  }
}
