import React from 'react'
import PropTypes from 'prop-types'

export default function SupplierOptionContent (props) {
  const { distributorName, quantity, price, skuGoodsType } = props
  return (
    <>
      <span>{ distributorName }/</span>
      <span>库存:&nbsp;{ skuGoodsType === 'CLOUD_FUTURES' ? <span style={{ fontSize: '16px' }}>&infin;</span> : quantity }/</span>
      <span>售价:&nbsp;&yen;{ +price }</span>
    </>
  )
}

SupplierOptionContent.propTypes = {
  distributorName: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.number,
  skuGoodsType: PropTypes.string
}
