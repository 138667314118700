import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Row, Table, Button, Icon, Modal } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import DetailsModel from './detailsModel'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { getCourierName } from '@/common/utils/mUtils'
import SAASCourierJson from '@/assets/json/saasCourier.json'

const { downDeppenExpressRecord } = DownloadApi
const { expressTypeMaps, pageSizeOptions } = filter
const { warning } = Modal

const allTransportTypeOptions = [
  ...expressTypeMaps[`SAAS-DBL`],
  ...expressTypeMaps[`SAAS-SF`],
  ...expressTypeMaps[`SAAS-JD`],
  ...expressTypeMaps[`SAAS-KYSY`]
]

class FinanceExpressRecordForm extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    form: PropTypes.object
  }
  state = {
    rowInfo: {},
    ModelFaly: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...'
        },
        options: [],
        optionValue: ['distributorId', 'merchantName']
      },
      {
        name: 'mailNo',
        formType: 'Input',
        itemParams: {
          label: '运单号'
        },
        cptParams: {
          placeholder: '请输入...'
        },
        options: [],
        optionValue: ['distributorId', 'merchantName']
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '发货日期'
        },
        cptParams: {

        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'courierCode',
        formType: 'Select',
        itemParams: {
          label: '快递公司'
        },
        cptParams: {
          placeholder: '请选择快递公司...',
          onChange: (code) => this.handleChangeCourierCode(code)
        },
        options: [
          { aliasCode: 'ALL', aliasName: '全部' },
          ...SAASCourierJson
        ],
        optionValue: ['aliasCode', 'aliasName']
      },
      {
        name: 'transportType',
        formType: 'Select',
        itemParams: {
          label: '快递类型'
        },
        cptParams: {
          placeholder: '请选择快递类型...'
        },
        options: [
          { code: 'ALL', name: '全部' },
          ...allTransportTypeOptions
        ],
        optionValue: ['code', 'name']
      }
    ]
  }
  columns= [
    {
      title: '序号',
      key: 'expressRecord-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'expressRecord-1',
      dataIndex: 'orderId'
    },
    {
      title: '运单号',
      key: 'expressRecord-2',
      render: (text, record, index) => (
        <div>
          { record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----' }
        </div>
      )
    },

    {
      title: '发货日期',
      key: 'expressRecord-3',
      render: (text, record, index) => (
        <>
          { record.senderTime ? moment(record.senderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '收货日期',
      key: 'expressRecord-4',
      render: (text, record, index) => {
        return (
          record.signedStatus === 'SIGNED' && record.receiverTime
            ? <div>{moment(record.receiverTime).format('YYYY-MM-DD HH:mm:ss')}</div>
            : '----'
        )
      }
    },
    {
      title: '发货地址',
      key: 'expressRecord-5',
      render: (text, record, index) => {
        return (
          <div>
            {record.senderProvince} {record.senderCity} {record.senderCounty} {record.senderAddress}
          </div>
        )
      }
    },
    {
      title: '收货地址',
      key: 'expressRecord-6',
      render: (text, record, index) => {
        return (
          <div>
            {record.receiverProvince} {record.receiverCity} {record.receiverCounty} {record.receiverAddress}
          </div>
        )
      }
    },
    {
      title: '商户名称',
      key: 'expressRecord-7',
      dataIndex: 'distributorName'
    },
    {
      title: '发货人',
      key: 'expressRecord-8',
      width: 100,
      dataIndex: 'senderName'
    },
    {
      title: '发货人联系方式',
      key: 'expressRecord-9',
      width: 110,
      dataIndex: 'senderMobile'
    },
    {
      title: '收货人',
      key: 'expressRecord-10',
      width: 100,
      dataIndex: 'receiverName'
    },
    {
      title: '收货人联系方式',
      key: 'expressRecord-11',
      width: 110,
      dataIndex: 'receiverMobile'
    },
    {
      title: '货物名称',
      key: 'expressRecord-12',
      dataIndex: 'cargoName',
      render: (text, record, index) => (
        record.cargoName === '文件' || record.orderId === 0 ? record.cargoName : <a onClick={() => { this.onModalOpen(record) }}>{record.cargoName}</a>
      )
    },
    {
      title: '快递公司',
      width: 100,
      key: 'expressRecord-13',
      render: (text, record, index) => (
        <>{ getCourierName(record.courierCode) }</>
      )
    },
    {
      title: '快递类型',
      key: 'expressRecord-14',
      render: (text, record, index) => (
        <>{ this.renderExpressTypeLabel(record.courierCode, record.transportType) }</>
      )
    }
  ]

  componentDidMount () {
    this.getRecordList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }
    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取列表信息
  getRecordList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getRecordList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: ({
            ...pages,
            total: data.total || 0
          })
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    search['beginDate'] = search.times && search.times.length === 2 ? moment(search.times[0]).format('YYYY-MM-DD') : undefined
    search['endDate'] = search.times && search.times.length === 2 ? moment(search.times[1]).format('YYYY-MM-DD') : undefined
    delete search.times

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getRecordList()
    })
  }

  // 根据快递公司联动快递类型
  handleChangeCourierCode = (aliasCode = '') => {
    let { searchData } = this.state
    const options = aliasCode === '' || aliasCode === 'ALL' ? [{ code: 'ALL', name: '全部' }, ...allTransportTypeOptions] : [{ code: 'ALL', name: '全部' }, ...expressTypeMaps[`SAAS-${aliasCode}`]]

    searchData[5]['options'] = options

    this.setState({
      searchData
    }, () => {
      this.props.form.setFieldsValue({
        transportType: null
      })
    })
  }

  // 点击导出明细
  handleDownloadExpressRecordDetail = () => {
    const { query = {} } = this.state

    if (!query.beginDate || !query.endDate) {
      warning({
        icon: <Icon type='exclamation-circle' style={{ color: '#FF3434' }} />,
        title: <span style={{ color: '#FF3434' }}>请选择您想导出的发货日期</span>,
        content: <span>请在搜索栏——发货日期中<br />选择您想导出的日期<br />然后点击搜索</span>
      })

      return false
    }

    downDeppenExpressRecord({ ...query })
  }

  // 打开物品详情页面
  onModalOpen = (record) => {
    this.setState({
      ModelFaly: true,
      rowInfo: record
    })
  }

  // 关闭物品详情页面
  onModalClose=() => {
    this.setState({
      ModelFaly: false,
      rowInfo: {}
    })
  }

  // 渲染快递类型名称
  renderExpressTypeLabel = (courierCode, type) => {
    const newdatas = expressTypeMaps[`SAAS-${courierCode}`]
    let label = '----'

    if (newdatas && newdatas.length > 0) {
      const obj = newdatas.find((item) => { return item.id === type })
      label = obj !== undefined ? obj.name : '----'
    }

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getRecordList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getRecordList()
    })
  }

  render () {
    const { searchData = [], pages, tableData = [], ModelFaly, rowInfo = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handleDownloadExpressRecordDetail()}><Icon type='download' />导出明细</Button>
          </Row>
          <Table rowKey={(record, i) => `${record.distributorId}-${i}`} bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
            scroll={{ x: 1200 }}
          />
        </Card>

        {
          ModelFaly
            ? <DetailsModel onModalClose={this.onModalClose} rowInfo={rowInfo} />
            : null
        }
      </>
    )
  }
}

const FinanceExpressRecords = Form.create()(FinanceExpressRecordForm)

export default FinanceExpressRecords
