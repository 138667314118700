import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, message, Row, Button, Icon } from 'antd'

import BanklinkModal from '../component/banklink-modal'

import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class BanklinkTable extends Component {
  static propTypes = {
    info: PropTypes.object,
    saveSetbanklink: PropTypes.func,
    getBanklinkList: PropTypes.func,
    getBankLinkageProcessorList: PropTypes.func
  }

  state = {
    bankModal: false,
    type: 1,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {},
    processorList: []
  }

  columns = [
    {
      title: '序号',
      key: 'jzbCol-8',
      width: 44,
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '前置机ID',
      key: 'jzbCol-0',
      dataIndex: 'processorId'
    },
    {
      title: '记账宝账号',
      key: 'jzbCol-1',
      dataIndex: 'bankLinkageNo'
    },
    {
      title: '账号描述',
      key: 'jzbCol-2',
      dataIndex: 'desc'
    },
    {
      title: '账号启用状态',
      key: 'jzbCol-3',
      dataIndex: 'status'
    },
    {
      title: '更新时间',
      key: 'jzbCol-4',
      dataIndex: 'modifyTime'
    },
    {
      title: '创建时间',
      key: 'jzbCol-5',
      dataIndex: 'createTime'
    },
    {
      title: '操作人',
      key: 'jzbCol-6',
      dataIndex: 'operatorName'
    },
    {
      title: '操作',
      key: 'jzbCol-7',
      render: (text, record, index) => (
        <div>
          <a
            onClick={() => this.showEditBanklinkModal(record, 2)}
          >
            编辑
          </a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getBanklinkSettings()
    this.props.getBankLinkageProcessorList({ pageNo: 1, pageSize: 99999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          processorList: data.list || []
        })
      }
    })
  }

  getBanklinkSettings = () => {
    const { getBanklinkList, info } = this.props
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      merchantId: info.distributorId
    }

    getBanklinkList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  saveBanklinkAge = (params) => {
    const { info } = this.props
    params.merchantId = info.distributorId
    this.props.saveSetbanklink(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getBanklinkSettings()
      }
    })
  }

  // 点击编辑
  showEditBanklinkModal = (record, type) => {
    this.setState({
      bankModal: true,
      type: type,
      rowDetail: record || {}
    })
  }

  closeModal = () => {
    this.setState({
      bankModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getBanklinkSettings()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getBanklinkSettings()
    })
  }

  render () {
    const { tableData = [], pages, bankModal, rowDetail = {}, type = 1, processorList = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '25px' }}>
          <Button type='primary'
            onClick={() => this.showEditBanklinkModal({}, 1)}
          >
            <Icon type='plus' />新增账号
          </Button>
        </Row>

        <Row>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Row>

        {
          bankModal
            ? <BanklinkModal
              type={type}
              processorList={processorList}
              detailInfo={rowDetail}
              info={this.props.info}
              onConfirm={(params) => this.saveBanklinkAge(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}
