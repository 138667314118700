import React, { useState, useEffect } from 'react'
import $lodash from 'lodash'
import moment from 'moment'

import Api from '@/common/api/index'

import styles from './index.module.scss'

export default function EnquireList () {
  const [tableData, setTableData] = useState([])

  // 获取优势品牌列表
  const getHotBrandList = async () => {
    const res = await Api.getHotBrandList({})
    const { code, data } = res
    if (+code === 10000) {
      const newdatas = []
      if (data && data.length > 0) {
        $lodash.forEach(data, (record) => {
          const newarr = record.length > 4 ? $lodash.slice(record, 0, 4) : record

          newdatas.push(newarr)
        })
      }

      setTableData(newdatas)
    }
  }

  // 获取优势品牌-交易详情
  const getBrandDetail = async (params, index, n) => {
    const res = await Api.getBrandDetail({ ...params })
    const { code, data } = res
    if (+code === 10000) {
      tableData[index][n] = { ...tableData[index][n], isback: true, ...data }

      setTableData([...tableData])
    }
  }

  // 点击翻转
  const handleRotate = (index, n, isback) => {
    if (isback) {
      const newrows = { ...tableData[index][n], isback: false }
      tableData[index][n] = newrows

      setTableData([...tableData])

      return false
    }

    getBrandDetail(tableData[index][n], index, n)
  }

  useEffect(() => {
    getHotBrandList()
  }, [])

  return (
    <>
      {
        tableData.map((record, index) => (
          <div className={`${styles.listItem}`} key={`${record[0].classificationId}`}>
            <div className={`${styles.titleWrap}`}>{ record[0].classificationName }</div>

            <ul className={`${styles.sublist}`}>
              {
                record.map((row, n) => (
                  <li className={`${styles.subItem} ${row.isback ? styles.active : ''}`} key={`SUBITEM-${row.brandId}`} onClick={() => handleRotate(index, n, row.isback || false)}>
                    <div className={`${styles.innerBox}`}>
                      <div className={`${styles.meta}`}>
                        <div className={`${styles.name}`}>{ row.brandName }</div>
                        <p className={`${styles.description}`}>{ row.englishBrandName }</p>
                      </div>
                      <div className={`${styles.body}`}>
                        <img className={`${styles.pic}`} src={row.imageUrl} />
                      </div>
                    </div>

                    <div className={`${styles.maskBox}`}>
                      <div className={`${styles.imgBox}`}>
                        <img className={`${styles.maskPic}`} src={row.imageUrl} />
                      </div>
                      <div className={`${styles.maskInner}`}>
                        <div>
                          <p><label className='label'>交易次数</label><span>{ row.tradeTimesTotal }</span></p>
                          <p><label className='label'>最近交易时间</label><span>{ row.lastestTradeTime ? moment(row.lastestTradeTime).format('YYYY-MM-DD HH:mm:ss') : null }</span></p>
                          <p><label className='label'>累计交易金额</label>&yen;{ (+row.tradeAmountTotal * 100 / 100).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        ))
      }
    </>
  )
}
