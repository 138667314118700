
import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { Spin } from 'antd'
import { getStore } from './common/utils/mUtils'

import Login from './pages/login/index'
import Main from './components/main/index'

import './App.css'

class App extends Component {
  static propTypes = {

  }

  render () {
    return (
      <div className={`basicLayout`}>
        <Router>
          <Switch>
            <Route
              exact
              path='/'
              render={props => {
                const token = getStore('token')
                return token ? <Redirect to='/admin/home' /> : <Login {...props} />
              }}
            />
            <Route path='/login' component={Login} />
            <Route
              path='/admin'
              render={props => {
                const token = getStore('token')
                return token ? <Main {...props} /> : <Redirect to='/login' />
              }}
            />

          </Switch>
        </Router>

        <div className={`loading-wrapper`} id='loadingWrapper'>
          <Spin size='large' />
        </div>
      </div>
    )
  }
}

export default App
