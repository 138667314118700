import React, { Component } from 'react'
import { Row, Card, Table, Badge, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import InvoiceModal from './invoiceModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions, serverInvoiceOpenedMaps } = filter

export default class InsuranceInvoice extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'invoicedStatusEnum',
        formType: 'Select',
        itemParams: {
          label: '开票状态'
        },
        cptParams: {
          placeholder: '选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...serverInvoiceOpenedMaps
        ]
      },
      {
        name: 'dueMonth',
        formType: 'MonthPicker',
        itemParams: {
          label: '账单日期'
        },
        cptParams: {
          placeholder: '选择...'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    rowDetail: {},
    invoiceModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'insuranceInvoice-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'insuranceInvoice-1',
      dataIndex: 'distributorName'
    },
    {
      title: '开票金额',
      key: 'insuranceInvoice-2',
      render: (text, record, index) => (
        <div>&yen;{ (+record.invoiceAmount * 100 / 100).toFixed(2) }</div>
      )
    },
    {
      title: '账单日期',
      key: 'insuranceInvoice-3',
      dataIndex: 'dueMonth'
    },
    {
      title: '开票状态',
      key: 'insuranceInvoice-4',
      render: (text, record, index) => (
        <div>{ this.renderInvoiceStatus(record.status) }</div>
      )
    },
    {
      title: '操作',
      key: 'insuranceInvoice-5',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.showInvoiceModal(record)}>
            { record.status === 'UN_INVOICED' ? '查看详情' : '编辑' }
          </a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getInsuranceInvoiceList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取保险发票列表
  getInsuranceInvoiceList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getInsuranceInvoiceList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 开具保险发票
  saveOpenedinsuranceInvoice = (params) => {
    Api.saveOpenedinsuranceInvoice(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getInsuranceInvoiceList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    search['dueMonth'] = search.dueMonth ? moment(search.dueMonth).format('YYYY-MM') : undefined

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      },
      query: search
    }, () => {
      this.getInsuranceInvoiceList()
    })
  }

  // 发票弹窗
  showInvoiceModal = (record = {}) => {
    this.setState({
      invoiceModal: true,
      rowDetail: record
    })
  }

  // 渲染发票状态字段
  renderInvoiceStatus = (status) => {
    let label = ''
    let obj = serverInvoiceOpenedMaps.find(item => { return item.id === status })

    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{obj.name}</span>} />
    }

    return label
  }

  closeModal = () => {
    this.setState({
      invoiceModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getInsuranceInvoiceList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getInsuranceInvoiceList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], rowDetail = {}, invoiceModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        {
          invoiceModal
            ? <InvoiceModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.saveOpenedinsuranceInvoice(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
