/* 工单中心 */
import WorkOrders from '@/pages/workorderManage/workOrder/index' // 工单中心
import WorkHandle from '@/pages/workorderManage/workHandle/index' // 工单处理
import WorkDetails from '@/pages/workorderManage/workDetails/index' // 工单详情

export default [
  {
    path: '/admin/workOrders',
    name: '工单中心',
    component: WorkOrders
  },
  {
    path: '/admin/workHandle',
    name: '工单处理',
    component: WorkHandle
  },
  {
    path: '/admin/workDetails',
    name: '工单详情',
    component: WorkDetails
  }
]
