import React, { Component } from 'react'
import { Row, Card, Col, Statistic, Table, Divider } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api'

import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { MAX_PAGESIZE } = consts
const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')

export default class index extends Component {
  constructor (props) {
    super(props)
    this.getPurposeMemberList = $lodash.debounce(this.getPurposeMemberList, 300)
  }
  state = {
    searchData: [
      {
        name: 'userId',
        formType: 'Select',
        itemParams: {
          label: '员工姓名'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getPurposeMemberList(val)
          }
        },
        options: [],
        optionValue: ['userId', 'realName']
      },
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { month: 0, monthLabel: '全年' },
          ...fullsMonthOptions
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['month', 'monthLabel']
      }
    ],
    tableData: [], // 交易明细列表
    query: {},
    monthAliveUserAll: 0, // 月活客户总数
    cloudPurchaseAmount: 0, // 云采购合同金额
    cloudPurchaseSentOut: 0, // 云采购销售额（发货）
    cloudPurchaseSentOutSeries: [], // echarts云采购销售金额（发货）
    averageGrossMargin: 0, // 平均毛利率
    tableDataForCus: [] // 月活客户明细列表
  }
  columns = [
    {
      title: '序号',
      key: 'infoListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '采购商名称',
      key: 'infoListCol-1',
      dataIndex: 'purchaseName'
    },
    {
      title: '云采购合同金额',
      key: 'infoListCol-2',
      dataIndex: 'cloudPurchaseAmout',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云采购发货金额',
      key: 'infoListCol-3',
      dataIndex: 'cloudSendOutAmount',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    }
  ]

  columnsForCus = [
    {
      title: '序号',
      key: 'cusListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '月活客户名称',
      key: 'cusListCol-1',
      dataIndex: 'aliveUserName'
    },
    {
      title: '云采购合同金额',
      key: 'cusListCol-2',
      dataIndex: 'cloudPurchaseAmoutForCus',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云采购发货金额',
      key: 'cusListCol-3',
      dataIndex: 'cloudSendOutAmountForCus',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '平均毛利率',
      key: 'cusListCol-4',
      dataIndex: 'averageData',
      render: (text, record, index) => {
        return (
          <span>{text}%</span>
        )
      }
    }
  ]

  // 获取报表数据
  getOperationStatistics = async () => {
    const { query = {} } = this.state
    const res = await Api.getPerformanceStatistics({ type: 'customer_success_department', user_id: query.userId })
    let cloudPurchaseAmountObj = res.cloud_purchase_amount_newly_year_chart // 云采购合同额总数据
    let cloudPurchaseSentOutObj = res.cloud_purchase_amount_send_out_newly_year_chart // 云采购销售额总数据
    let cloudSupplyAmountObj = res.cloud_supply_amount_send_out_newly_year_chart // 云供应金额总数据
    let listMonthAliveDetailObj = res.list_month_alive_detail // 月活列表总数据
    let _tabledata = [] // 交易明细列表总数据
    let _tabledataForCus = [] // 月活明细列表总数据

    // 月活客户总数
    let monthAliveUsersData = []
    $lodash.forEach(listMonthAliveDetailObj, (value, key) => {
      const obj = [key, value]
      monthAliveUsersData.push(obj)
    })

    // 云采购合同额数据
    let cloudPurchaseAmountdatas = []
    $lodash.forEach(cloudPurchaseAmountObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        cloudPurchaseAmountdatas.push(obj)
      }
    })

    // 云采购销售额数据
    let cloudPurchaseSendOutdatas = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        cloudPurchaseSendOutdatas.push(obj)
      }
    })

    // 平均毛利率  数据处理
    let averageGrossMarginData = 0
    // 计算云采购总金额
    let cloudPurchaseAmountDatasforAverage = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        cloudPurchaseAmountDatasforAverage.push(obj)
      }
    })
    // 计算云供应总金额
    let cloudSupplyAmountData = []
    $lodash.forEach(cloudSupplyAmountObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        cloudSupplyAmountData.push(obj)
      }
    })
    // 平均毛利率=（云采购订单总金额-云供应订单总金额）/云采购订单总金额*100%
    averageGrossMarginData = ($lodash.sumBy(cloudPurchaseAmountDatasforAverage, 'value')) > 0
      ? (($lodash.sumBy(cloudPurchaseAmountDatasforAverage, 'value')) - ($lodash.sumBy(cloudSupplyAmountData, 'value'))) / ($lodash.sumBy(cloudPurchaseAmountDatasforAverage, 'value')) * 100 : 0
    // 云采购销售额Echarts折线图数据
    let cloudPurchaseSendEcharts = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: parseFloat(value).toFixed(2) }
        cloudPurchaseSendEcharts.push(obj)
      }
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const cloudPurchaseSendEchartsdata = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(cloudPurchaseSendEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 设置交易明细table列表数据
    if (res.list_purchase_users_details) {
      _tabledata = this.setTableData(res.list_purchase_users_details)
    }

    // 设置月活明细table列表数据
    if (res.list_month_alive_detail) {
      _tabledataForCus = this.setTableDataForCus(res.list_month_alive_detail)
    }

    this.setState({
      monthAliveUserAll: monthAliveUsersData.length, // 采购商总数
      cloudPurchaseAmount: cloudPurchaseAmountdatas.length > 0 ? $lodash.sumBy(cloudPurchaseAmountdatas, 'value') : 0, // 云采购合同额
      cloudPurchaseSentOut: cloudPurchaseSendOutdatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutdatas, 'value') : 0, // 云采购销售
      tableData: _tabledata, // 交易明细
      tableDataForCus: _tabledataForCus, // 月活明细
      cloudPurchaseSentOutSeries: cloudPurchaseSendEchartsdata, // 云采购销售金额（发货）Echarts
      averageGrossMargin: averageGrossMarginData > 0 ? averageGrossMarginData : 0 // 平均毛利率
    }, () => {
      this.initECharts()
    })
  }

  // 初始化图表
  initECharts = () => {
    const { cloudPurchaseSentOutSeries } = this.state
    var myChart = echarts.init(document.getElementById('operationStatistics'))
    // 绘制图表
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${YEAR}客户成功统计折线图`
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['云采购销售金额（发货）']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisLabel: {
          formatter: `{value}月`
        }
      },
      yAxis: [ {
        type: 'value',
        name: '金额',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} ￥'
        }
      }],
      series: [
        {
          name: '云采购销售金额（发货）',
          type: 'line',
          yAxisIndex: 0,
          itemStyle: { normal: { label: { show: true } } },
          data: cloudPurchaseSentOutSeries
        }
      ]
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.userId == null) {
      alert('请选择员工！')
      return false
    }
    this.setState({
      query: search
    }, () => {
      this.getOperationStatistics()
    })
  }

  // 设置月活客户明细table列表数据结构
  setTableDataForCus = (origindatas) => {
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云采购合同金额table数据
      let cloudPurchaseTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_newly, (value, key) => {
        if (key.includes(YEAR)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseTabledatas.push(obj)
        }
      })
      // 云采购销售金额table数据
      let cloudPurchaseSendOutTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_send_out_newly, (value, key) => {
        if (key.includes(YEAR)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseSendOutTabledatas.push(obj)
        }
      })
      // 云供应金额
      let cloudSupplydatas = []
      $lodash.forEach(values.cloud_supply_amount_send_out_newly, (value, key) => {
        if (key.includes(YEAR)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudSupplydatas.push(obj)
        }
      })

      // 平均毛利率
      let averageGrossMarginData = 0
      averageGrossMarginData = (($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value')) > 0) ? (($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') - $lodash.sumBy(cloudSupplydatas, 'value')) / ($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value')) * 100) : 0

      return {
        aliveUserName: values.distributor_name,
        cloudPurchaseAmoutForCus: cloudPurchaseTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseTabledatas, 'value') : 0, // 云采购合同额数据
        cloudSendOutAmountForCus: cloudPurchaseSendOutTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') : 0, // 云采购销售额
        averageData: averageGrossMarginData > 0 ? parseFloat(averageGrossMarginData).toFixed(2) : 0
      }
    }), function (item) {
      return -item.cloudPurchaseAmout
    })
    return list
  }

  // 设置交易明细table列表数据结构
  setTableData = (origindatas) => {
    const { query = {} } = this.state
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云采购合同金额table数据
      let cloudPurchaseTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseTabledatas.push(obj)
        }
      })
      // 云采购销售金额table数据
      let cloudPurchaseSendOutTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_send_out_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseSendOutTabledatas.push(obj)
        }
      })

      return {
        purchaseName: values.distributor_name,
        cloudPurchaseAmout: cloudPurchaseTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseTabledatas, 'value') : 0, // 云采购合同额数据
        cloudSendOutAmount: cloudPurchaseSendOutTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') : 0 // 云采购销售额
      }
    }), function (item) {
      return -item.cloudPurchaseAmout
    })
    return list
  }

  // 获取全部成员
  getPurposeMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }

    Api.getPurposeMemberList({ realName: val, departId: 3, pageNo: 1, pageSize: MAX_PAGESIZE }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[0].options = data && data.list && data.list.length > 0 ? [...data.list] : []
        this.setState({
          searchData
        })
      }
    })
  }
  render () {
    const { searchData, tableData, monthAliveUserAll, cloudPurchaseAmount, cloudPurchaseSentOut, averageGrossMargin, tableDataForCus } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px', paddingLeft: '30px' }}>
          <Col span={6} >
            <Statistic title='月活客户总数' value={monthAliveUserAll} style={{ color: 'black' }} />
          </Col>
          <Col span={6}>
            <Statistic title='云采购合同额' value={cloudPurchaseAmount} precision={2} prefix='￥' />
          </Col>
          <Col span={6}>
            <Statistic title='云采购销售额(发货)' value={cloudPurchaseSentOut} precision={2} prefix='￥' />
          </Col>
          <Col span={6}>
            <Statistic title='平均毛利率' value={averageGrossMargin} precision={2} suffix='%' />
          </Col>
        </Row>
        <Card bodyStyle={{ padding: '12px' }}>
          <div id='operationStatistics' style={{ width: '100%', height: '500px' }} />
        </Card>
        <Divider orientation='left'>月活客户明细</Divider>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columnsForCus}
            dataSource={tableDataForCus}
            pagination={false}
          />
        </Card>
        <Divider orientation='left'>交易明细</Divider>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Card>
      </div>
    )
  }
}
