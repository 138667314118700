import DashboardRequest from '../utils/dashboardRequest'

export default {
  // 获取数据分析报表
  getDashboardAnalyse (params) {
    return DashboardRequest.get('/reportdata', { params })
  },
  // ----- 业绩统计2023接口 -----:
  getPerformanceStatistics (params) {
    return DashboardRequest.get('/reportdata2023', { params })
  },
  getReportdata2023MemberList (params) {
    return DashboardRequest.get('/getUserList', { params })
  }
}
