import React, { Component } from 'react'
import { Row, Card, Col, Statistic, Table, Divider } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api'

import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { MAX_PAGESIZE } = consts
const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')

export default class index extends Component {
  constructor (props) {
    super(props)
    this.getPurposeMemberList = $lodash.debounce(this.getPurposeMemberList, 300)
  }
  state = {
    searchData: [
      {
        name: 'userId',
        formType: 'Select',
        itemParams: {
          label: '员工姓名'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getPurposeMemberList(val)
          }
        },
        options: [],
        optionValue: ['userId', 'realName']
      },
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { month: 0, monthLabel: '全年' },
          ...fullsMonthOptions
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['month', 'monthLabel']
      }
    ],
    tableData: [],
    query: {},
    purchaseUserAll: 0, // 采购商总数
    newPurchaseUser: 0, // 新增采购商数量
    cloudPurchaseAmount: 0, // 云采购合同金额
    cloudPurchaseSentOut: 0, // 云采购销售额（发货）
    cloudPurchaseSentOutSeries: [], // echarts云采购销售金额（发货）
    newPurchaseUsersSeries: [] // echarts新增成交采购商数量，单位“家”
  }
  columns = [
    {
      title: '序号',
      key: 'infoListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '采购商名称',
      key: 'infoListCol-1',
      dataIndex: 'purchaseName'
    },
    {
      title: '云采购合同金额',
      key: 'infoListCol-2',
      dataIndex: 'cloudPurchaseAmout',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云采购发货金额',
      key: 'infoListCol-3',
      dataIndex: 'cloudSendOutAmount',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    }
  ]

  // 获取报表数据
  getSalesStatistics = async () => {
    const { query = {} } = this.state
    const res = await Api.getPerformanceStatistics({ type: 'sales_department', user_id: query.userId })

    let newPurchaseUserObj = res.purchase_users_newly_year_chart // 新增采购商数量总数据
    let cloudPurchaseAmountObj = res.cloud_purchase_amount_newly_year_chart // 云采购合同额总数据
    let cloudPurchaseSentOutObj = res.cloud_purchase_amount_send_out_newly_year_chart // 云采购销售额
    let _tabledata = []

    // 新增采购商数据
    let newPurchaserdatas = []
    $lodash.forEach(newPurchaseUserObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        newPurchaserdatas.push(obj)
      }
    })
    // 新增成交采购商Echarts数据
    let newPurchaserEcharts = []
    $lodash.forEach(newPurchaseUserObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: value }
        newPurchaserEcharts.push(obj)
      }
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const newPurchaserEchartsEchartsdata = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(newPurchaserEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 云采购合同额数据
    let cloudPurchaseAmountdatas = []
    $lodash.forEach(cloudPurchaseAmountObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        cloudPurchaseAmountdatas.push(obj)
      }
    })

    // 云采购销售额数据
    let cloudPurchaseSendOutdatas = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        cloudPurchaseSendOutdatas.push(obj)
      }
    })

    // 云采购销售额Echarts折线图数据
    let cloudPurchaseSendEcharts = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: parseFloat(value).toFixed(2) }
        cloudPurchaseSendEcharts.push(obj)
      }
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const cloudPurchaseSendEchartsdata = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(cloudPurchaseSendEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 设置table列表数据
    if (res.cloud_purchase_distributor_table) {
      _tabledata = this.setTableData(res.cloud_purchase_distributor_table)
    }

    this.setState({
      purchaseUserAll: res.purchase_users_all_count, // 采购商总数
      newPurchaseUser: newPurchaserdatas.length > 0 ? $lodash.sumBy(newPurchaserdatas, 'value') : 0, // 新增采购商数量
      cloudPurchaseAmount: cloudPurchaseAmountdatas.length > 0 ? $lodash.sumBy(cloudPurchaseAmountdatas, 'value') : 0, // 云采购合同额
      cloudPurchaseSentOut: cloudPurchaseSendOutdatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutdatas, 'value') : 0, // 云采购销售
      tableData: _tabledata, // 交易明细
      cloudPurchaseSentOutSeries: cloudPurchaseSendEchartsdata, // 云采购销售金额（发货）Echarts
      newPurchaseUsersSeries: newPurchaserEchartsEchartsdata// 新增成交采购商Echarts数据
    }, () => {
      this.initECharts()
    })
  }

  // 初始化图表
  initECharts = () => {
    const { cloudPurchaseSentOutSeries, newPurchaseUsersSeries } = this.state

    var myChart = echarts.init(document.getElementById('salesStatisticsEcharts'))
    // 绘制图表
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${YEAR}销售统计折线图`
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['云采购销售金额（发货）', '新增成交采购商数量']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisLabel: {
          formatter: `{value}月`
        }
      },
      yAxis: [ {
        type: 'value',
        name: '金额',
        boundaryGap: false,
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} ￥'
        }
      },
      {
        type: 'value',
        name: '数量',
        boundaryGap: false,
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} 家'
        }
      }],
      series: [
        {
          name: '云采购销售金额（发货）',
          type: 'line',
          yAxisIndex: 0,
          label: { show: true },
          data: cloudPurchaseSentOutSeries
        },
        {
          name: '新增成交采购商数量',
          type: 'line',
          yAxisIndex: 1,
          label: { show: true },
          data: newPurchaseUsersSeries
        }
      ]
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.userId == null) {
      alert('请选择员工！')
      return false
    }
    this.setState({
      query: search
    }, () => {
      this.getSalesStatistics()
    })
  }

  // 设置table列表数据结构
  setTableData = (origindatas) => {
    const { query = {} } = this.state
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云采购合同金额table数据
      let cloudPurchaseTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseTabledatas.push(obj)
        }
      })
      // 云采购销售金额table数据
      let cloudPurchaseSendOutTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_send_out_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseSendOutTabledatas.push(obj)
        }
      })

      return {
        purchaseName: key,
        cloudPurchaseAmout: cloudPurchaseTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseTabledatas, 'value') : 0, // 云采购合同额数据
        cloudSendOutAmount: cloudPurchaseSendOutTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') : 0 // 云采购销售额
      }
    }), function (item) {
      return -item.cloudPurchaseAmout
    })
    return list
  }

  // 获取全部成员
  getPurposeMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }
    Api.getPurposeMemberList({ realName: val, departId: 1, pageNo: 1, pageSize: MAX_PAGESIZE }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[0].options = data && data.list && data.list.length > 0 ? [...data.list] : []
        this.setState({
          searchData
        })
      }
    }
    )
  }
  render () {
    const { searchData, tableData, purchaseUserAll, newPurchaseUser, cloudPurchaseAmount, cloudPurchaseSentOut } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px', paddingLeft: '30px' }}>
          <Col span={6} >
            <Statistic title='采购商总数' value={purchaseUserAll} style={{ color: 'black' }} />
          </Col>
          <Col span={6}>
            <Statistic title='新增采购商数量' value={newPurchaseUser} precision={0} />
          </Col>
          <Col span={6}>
            <Statistic title='云采购合同额' value={cloudPurchaseAmount} precision={2} prefix='￥' />
          </Col>
          <Col span={6}>
            <Statistic title='云采购销售额(发货)' value={cloudPurchaseSentOut} precision={2} prefix='￥' />
          </Col>
        </Row>
        <Card bodyStyle={{ padding: '12px' }}>
          <div id='salesStatisticsEcharts' style={{ width: '100%', height: '500px' }} />
        </Card>
        <Divider orientation='left'>交易明细</Divider>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Card>
      </div>
    )
  }
}
