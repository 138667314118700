import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Modal } from 'antd'

import OrderUser from './orderUser/index'
import OrderInvoice from './orderInvoice/index'
import OrderProducts from './orderProducts/index'

import Api from '@/common/api/index'

export default class OrderSubmit extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    distributorId: null,
    distributorName: '',
    orderId: null,
    quotationOrderId: null,
    showPage: false,
    selectedAddressId: null, // 选中的地址ID
    tableData: [],
    totalAmount: null, // 商品总金额
    paymentAmount: null, // 应付金额
    spotTotalAmount: 0, // 现货金额
    prepaidAmount: 0, // 期货订金
    balancePaymentAmount: 0 // 期货尾款
  }

  componentDidMount () {
    const { orderId, quotationOrderId, distributorId, distributorName } = this.props.history.location.state

    this.setState({
      orderId: orderId,
      quotationOrderId: quotationOrderId,
      distributorId: distributorId,
      distributorName: distributorName,
      showPage: true
    }, () => {
      this.getQuotationPreviewOrderProductDetails()
    })
  }

  // 获取预览订单产品信息
  getQuotationPreviewOrderProductDetails = async () => {
    const { distributorId, quotationOrderId } = this.state
    const res = await Api.getQuotationPreviewOrderProductDetails({ distributorId: distributorId, quotationOrderId: quotationOrderId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.advanceOrderGoodsDetail || [],
        spotTotalAmount: data.spotTotalAmount || 0, // 现货金额
        prepaidAmount: data.prepaidAmount || 0, // 期货订金
        balancePaymentAmount: data.balancePaymentAmount || 0, // 期货尾款
        paymentAmount: data.paymentAmount || 0, // 应付金额
        totalAmount: data.totalAmount || 0 // 订单总金额
      })
    }
  }

  // 报价单-生成订单
  submitQuotationCreategenerateOrder = async (params) => {
    const res = await Api.submitQuotationCreategenerateOrder(params)
    const { code, data } = res
    if (+code === 10000) {
      this.countDown()
    } else if (code === 'SOS_999999') {
      Modal.info({
        title: res.message,
        content: <>
          {
            data && data.map((item, i) => (
              <p key={`failItem-${i}`} style={{ marginBottom: '8px' }}>{ item }</p>
            ))
          }
        </>
      })
    }
  }

  // 点击生成订单
  handleOrderSubmit = () => {
    const { orderId, distributorId, tableData, selectedAddressId, quotationOrderId, totalAmount = 0, paymentAmount = 0, spotTotalAmount = 0, prepaidAmount = 0, balancePaymentAmount = 0 } = this.state

    const params = {
      orderId: orderId,
      quotationOrderId: quotationOrderId,
      distributorId: distributorId,
      advanceOrderGoodsDetail: tableData,
      addressId: selectedAddressId,
      totalAmount: totalAmount,
      paymentAmount: paymentAmount,
      spotTotalAmount: spotTotalAmount,
      prepaidAmount: prepaidAmount,
      balancePaymentAmount: balancePaymentAmount
    }

    this.submitQuotationCreategenerateOrder(params)
  }

  // 选中的地址ID
  handleSelectAddress = (addressId) => {
    this.setState({
      selectedAddressId: addressId
    })
  }

  // 倒计时关闭成功提示弹窗
  countDown = () => {
    let secondsToGo = 5
    const modal = Modal.success({
      title: '操作成功',
      content: `${secondsToGo}s后返回列表页面`,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.props.history.push({ pathname: '/admin/quotationList' })
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `${secondsToGo}s后返回列表页面`
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.props.history.push({ pathname: '/admin/quotationList' })
    }, secondsToGo * 1000)
  }

  render () {
    const { showPage = false, distributorId, distributorName,
      tableData = [], totalAmount = 0, spotTotalAmount = 0, prepaidAmount = 0, balancePaymentAmount = 0, paymentAmount = 0 } = this.state

    return (
      <>
        {
          showPage
            ? <Card bodyStyle={{ padding: '12px' }}>
              <OrderUser
                distributorId={distributorId}
                distributorName={distributorName}
                onSelectAddress={(addressId) => this.handleSelectAddress(addressId)}
              />

              <OrderInvoice distributorId={distributorId} />

              <OrderProducts
                list={tableData}
                totalAmount={totalAmount}
                spotTotalAmount={spotTotalAmount}
                paymentAmount={paymentAmount}
                prepaidAmount={prepaidAmount}
                balancePaymentAmount={balancePaymentAmount}
              />

              <div style={{ margin: '0px 0px 12px 0px', textAlign: 'center' }}>
                <Button type='primary' size='large' onClick={() => this.handleOrderSubmit()}>生成订单</Button>
              </div>
            </Card>
            : null
        }
      </>
    )
  }
}
