import Ajax from '../utils/ajax'
/* 工单Api */

export default {
  // 工单- 生成工单
  saveCreateWorkOrder (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/generate', params)
  },

  // 工单- 批量发布工单
  saveBatchCreateWorkOrders (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/generateForBatch', params)
  },

  // 工单- 发布协作列表工单
  saveTeamWorkOrders (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/generateForProductList', params)
  },

  // 工单- 自动报价生成工单
  saveAutoIssueQuotation (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/autoIssueQuotation', params)
  },

  // 获取工单中心列表
  getWorkOrdersList (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/listPage', params)
  },

  // 获取工单详情
  getWorkOrderDetail (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/getDetail', params)
  },

  // 保存处理工单
  saveHandleWorkOrderDetail (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/handling', params)
  },

  // 工单- 获取工单统计信息列表
  getWorkOrderStatistics (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/listPageForStatistics', params)
  },

  // 获取工单报价统计
  getWorkOrderQuoteBoardList (params) {
    return Ajax.post('/api/platform/enquiry/workOrder/listPageByStatisticsReport', params)
  }
}
