import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Table, Upload, Button, Icon, Modal, message } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

const { confirm } = Modal
const enclosureTypeMaps = [
  { id: 'CLIENT', name: '客户上传' },
  { id: 'DISTRIBUTOR', name: '商户上传' },
  { id: 'PLATFORM', name: 'IIASaaS平台上传' },
  { id: 'SUPPLY_PLATFORM', name: 'IIASaaS平台供应上传' },
  { id: 'PURCHASE_PLATFORM', name: 'IIASaaS平台采购上传' }
]
const uploadActionMap = {
  'PURCHASE_ENCLOSURE': UploadApi.uploadPurchaseOrderEnclosure,
  'SUPPLY_ENCLOSURE': UploadApi.uploadSupplyOrderEnclosure,
  'EU_ENCLOSURE': UploadApi.uploadEUOrderEnclosure
}

class OrderEnclosureDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'appendixCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '上传时间',
      key: 'appendixCol-1',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '上传类型',
      key: 'appendixCol-2',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ this.renderEnclosureUploadTypeLabel(record.type) }</>
      )
    },
    {
      title: '操作',
      key: 'appendixCol-3',
      render: (text, record, index) => (
        <>
          {
            ['CLIENT', 'DISTRIBUTOR'].includes(record.type)
              ? null
              : <Button type='primary' size='small' style={{ marginRight: '6px' }} onClick={() => this.handleDeleteEnclosure(record)}>删除</Button>
          }

          <Button type='primary' size='small' onClick={() => this.handleDownloadEnclosure(record)}>下载</Button>
        </>
      )
    }
  ]

  describeForNameMap = [
    [ // EU订单附件
      (name) => name === 'EU_ENCLOSURE',
      () => this.getEUOrderContractEnclosureList(),
      (values) => this.deleteEUOrderContractEnclosure(values)
    ],
    [ // 采购订单附件
      (name) => name === 'PURCHASE_ENCLOSURE',
      () => this.getPurchaseContractEnclosureList(),
      (values) => this.saveDeletePurchaseContractEnclosure(values)
    ],
    [ // 供应订单附件
      (name) => name === 'SUPPLY_ENCLOSURE',
      () => this.getSupplyContractEnclosureList(),
      (values) => this.saveDeleteSupplyContractEnclosure(values)
    ]
  ]

  // 获取EU订单合同附件列表
  getEUOrderContractEnclosureList = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getEUOrderContractEnclosureList({ orderId: detailInfo.orderId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 删除EU订单合同附件
  deleteEUOrderContractEnclosure = async (params) => {
    const res = await Api.deleteEUOrderContractEnclosure(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.getEUOrderContractEnclosureList()
    }
  }

  // 获取云采购订单合同附件列表
  getPurchaseContractEnclosureList = async () => {
    const { detailInfo = {}, type } = this.props
    const params = {
      orderId: detailInfo.orderId,
      distributorId: detailInfo.distributorId,
      type: type,
      orderType: detailInfo.skuGoodsType
    }

    const res = await Api.getPurchaseContractEnclosureList(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 保存云采购订单合同附件删除
  saveDeletePurchaseContractEnclosure = async (params) => {
    const res = await Api.saveDeletePurchaseContractEnclosure(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.getPurchaseContractEnclosureList()
    }
  }

  // 弹窗显示回调
  handleChangeVisible = (visible) => {
    if (visible) {
      const { name } = this.props
      const getDescribe = this.describeForNameMap.find((item) => item[0](name))

      getDescribe ? getDescribe[1]() : message.warning('页面参数错误')
    }
  }

  // 点击删除
  handleDeleteEnclosure = (record) => {
    confirm({
      title: '确定删除?',
      onOk: () => {
        const { name } = this.props
        const getDescribe = this.describeForNameMap.find((item) => item[0](name))

        getDescribe ? getDescribe[2]({ id: record.id }) : message.warning('页面参数错误')
      }
    })
  }

  // 上传订单附件
  handleChangeUploadEnclosure = (info) => {
    if (info.file.status === 'done') {
      const { code } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.handleChangeVisible(true)
        }, 10)
      } else {
        message.warning(info.file.response.message || '上传失败')
      }
    } else if (info.file.status === 'error') {
      message.warning(info.file.response.message || '上传失败')
    }
  }

  // 下载附件
  handleDownloadEnclosure = (record) => {
    if (!record.fileKey || record.fileKey === '') {
      return message.warning('暂无可下载链接')
    }

    window.open(record.fileKey)
  }

  // 渲染上传类型
  renderEnclosureUploadTypeLabel = (status) => {
    const obj = enclosureTypeMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  render () {
    const { visible = false, name, type, detailInfo = {}, onCancel } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Drawer title='订单附件' width={1300}
          placement='right'
          visible={visible}
          destroyOnClose
          afterVisibleChange={(visible) => this.handleChangeVisible(visible)}
          onClose={() => onCancel()}
        >
          <div style={{ marginBottom: '15px' }}>
            <Upload name='file'
              showUploadList={false}
              accept='image/png, image/jpg, image/jpeg, image/pdf'
              headers={{ Token: getStore('token') }}
              data={{ orderId: detailInfo.orderId, distributorId: detailInfo.distributorId || null, type: type, orderType: detailInfo.skuGoodsType }}
              action={uploadActionMap[name]}
              onChange={(info) => this.handleChangeUploadEnclosure(info)}
            >
              <Button type='primary'><Icon type='upload' />点击上传</Button>
            </Upload>
            <span style={{ marginLeft: '6px', color: '#FF0000' }}>请上传PNG、JPG、PDF格式文件</span>
          </div>

          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Drawer>
      </>
    )
  }
}

export default OrderEnclosureDrawer
