import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Form, Input, Button, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'
import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const FormItem = Form.Item
const { paywordReg } = filter

class PaywordForm extends Component {
  static propTypes = {
    form: PropTypes.object
  }
  state = {
    type: 1, // 1-设置支付密码 2-修改支付密码
    btnDisabled: false,
    yztime: 59
  }

  componentDidMount () {
    this.getHasPayPassword()
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

  // 点击获取验证码
  handleGetMessCode = () => {
    Api.sendMessageCode({}).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
      }
    })
  }

  // 保存新增支付密码
  saveAddPayword = (params) => {
    Api.saveAddPayword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.handleCancel()
      }
    })
  }

  // 保存修改支付密码
  saveModifyPayword = (params) => {
    Api.saveModifyPayword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.handleCancel()
      }
    })
  }

  // 查询是否设置支付密码
  getHasPayPassword = () => {
    Api.getHasPayPassword({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          type: data || 1
        })
      }
    })
  }

  // 点击提交保存
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1 } = this.state
        let { oldPayPwd, payPwd } = values

        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['payPwd'] = jsencrypt.encrypt(payPwd)

        if (type === 1) {
          this.saveAddPayword(values)
        } else {
          values['oldPayPwd'] = jsencrypt.encrypt(oldPayPwd)
          this.saveModifyPayword(values)
        }
      }
    })
  }

  // 点击取消
  handleCancel = () => {
    this.props.form.resetFields()
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const { type = 1, btnDisabled = false, yztime = 59 } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    const tailFormItemLayout = {
      wrapperCol: { span: 20, offset: 6 }
    }

    return (
      <div>
        <Card title={`设置支付密码`}>
          <Row style={{ width: '500px', margin: '0px auto' }}>
            <Form>
              {
                type === 1
                  ? null
                  : <FormItem label='原支付密码' {...formItemLayout}>
                    {
                      getFieldDecorator('oldPayPwd', {
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !paywordReg.test(value)) {
                                  /* eslint-disable */
                                    callback('支付密码格式不正确')
                                  /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请您填写原6位数字支付密码' }]
                          }
                        ]
                      })(
                        <Input.Password></Input.Password>
                      )
                    }
                  </FormItem>
              }

              <FormItem label={type === 1 ? '支付密码' : '新支付密码'}
                extra={'支付密码格式为6位数字'}
                {...formItemLayout}
              >
                {
                  getFieldDecorator('payPwd', {
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !paywordReg.test(value)) {
                                /* eslint-disable */
                                callback('支付密码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                      }
                    ]
                  })(
                    <Input.Password></Input.Password>
                  )
                }
              </FormItem>

              {
                type === 1
                  ? <FormItem label='验证码' {...formItemLayout}>
                    <Row gutter={10}>
                      <Col span={12}>
                        {
                          getFieldDecorator('verifyCode', {
                            rules: [
                              { required: true, message: '请填写手机验证码' }
                            ]
                          })(
                            <Input placeholder='请输入...' autoComplete='off'
                              style={{ width: '100%' }}
                            />
                          )
                        }
                      </Col>
                      <Col span={12}>
                        <Button
                          disabled={btnDisabled}
                          onClick={this.handleGetMessCode}
                        >
                          {
                            !btnDisabled ? '获取验证码' : `${yztime} s 后重新发送`
                          }
                        </Button>
                      </Col>
                    </Row>
                  </FormItem>
                  : null
              }

              <FormItem {...tailFormItemLayout}>
                <Button type='primary' style={{ marginRight: '30px' }} onClick={this.handleSubmit}>保存</Button>
                <Button onClick={this.handleCancel}>取消</Button>
              </FormItem>
            </Form>
          </Row>
        </Card>
      </div>
    )
  }
}

const SetPayword = Form.create()(PaywordForm)

export default SetPayword
