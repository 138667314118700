import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'

import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const { REMARKS_MAX_NUM } = consts
const { TextArea } = Input

export default class TextAreaForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    label: PropTypes.string,
    dataIndex: PropTypes.string,
    remarks: PropTypes.string
  }
  state = {
    count: 0
  }

  handleChange = (value) => {
    this.setState({
      count: value.length
    })
  }

  render () {
    const { form, label = '备注', dataIndex = 'remarks', remarks = '' } = this.props
    const { getFieldDecorator } = form
    const { count = 0 } = this.state

    return (
      <>
        <Form>
          <Form.Item label={label}>
            <div className={`${styles.textAreaBox}`}>
              {
                getFieldDecorator(`${dataIndex}`, {
                  initialValue: remarks
                })(
                  <TextArea placeholder={`请输入内容，不超过${REMARKS_MAX_NUM}字...`}
                    maxLength={REMARKS_MAX_NUM}
                    rows={4} autoComplete='off'
                    onChange={(e) => this.handleChange(e.target.value)}
                  />
                )
              }

              <span className={`${styles.textAreaCount}`}>
                <span>{count}</span>/<span>{REMARKS_MAX_NUM}</span>
              </span>
            </div>
          </Form.Item>
        </Form>
      </>
    )
  }
}
