import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default function OrderTotals (props) {
  const { totalAmount = 0, spotTotalAmount = 0, prepaidAmount = 0 } = props

  return (
    <ul className={`${styles.orderTotals}`}>
      <li className={`${styles.totalItem}`}>
        <div className={`${styles.totalItemTitle}`}>现货金额</div>
        <div className={`${styles.totalItemContent}`}>&yen;{ (+spotTotalAmount * 100 / 100).toFixed(2)}</div>
      </li>

      <li className={`${styles.totalItem}`}>
        <div className={`${styles.totalItemTitle}`}>期货定金</div>
        <div className={`${styles.totalItemContent}`}>&yen;{(+prepaidAmount * 100 / 100).toFixed(2)}</div>
      </li>

      <li className={`${styles.totalItem}`}>
        <div className={`${styles.totalItemTitle}`}>期货尾款</div>
        <div className={`${styles.totalItemContent}`}>&yen;{((totalAmount - spotTotalAmount - prepaidAmount) * 100 / 100).toFixed(2)}</div>
      </li>

      <li className={`${styles.totalItem}`}>
        <div className={`${styles.totalItemTitle}`}>本次应付</div>
        <div className={`${styles.totalItemContent}`}>&yen;{((spotTotalAmount + prepaidAmount) * 100 / 100).toFixed(2)}</div>
      </li>

      <li className={`${styles.totalItem}`}>
        <div className={`${styles.totalItemTitle}`}>剩余应付</div>
        <div className={`${styles.totalItemContent}`}>&yen;{(((totalAmount - spotTotalAmount - prepaidAmount)) * 100 / 100).toFixed(2)}</div>
      </li>
    </ul>
  )
}

OrderTotals.propTypes = {
  totalAmount: PropTypes.number, // 采购总金额
  spotTotalAmount: PropTypes.number, // 现货金额
  prepaidAmount: PropTypes.number // 期货定金
}
