import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, InputNumber, Select, Icon } from 'antd'

import SupplierOptionContent from './supplierOptionContent/index'

import styles from './dispense.module.scss'

const Option = Select.Option

class DispenseAction extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    list: PropTypes.array,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onChangeQuantity: PropTypes.func,
    onSelectSupplier: PropTypes.func,
    i: PropTypes.number,
    detailInfoid: PropTypes.object

  }
  state = {}

  componentDidMount () {}

  render () {
    const { detailInfo = {}, list = [], i, detailInfoid = {} } = this.props

    return (
      <Row>
        <div className={`${styles.border} ${styles.actionlist}`}>
          <ul style={{ margin: '0px', padding: '0px' }}>
            {
              list[i].map((record, n) => (
                <li key={`expandItem-${n}`} className={`${styles.listItem} ${n === list[i].length - 1 ? '' : styles.dashedBorder}`}>
                  <Row gutter={12}>
                    <Col span={6} className={`${styles.listCol}`}>
                      <Row className={`${styles.colContent}`}>
                        <span className={`${styles.brand}`}>{detailInfo.brandName}</span>
                        <span title={detailInfo.name}>{detailInfo.name}</span>
                      </Row>
                    </Col>
                    <Col span={4} className={`${styles.listCol}`}>
                      <Row className={`${styles.colContent}`}>
                        <label className={`${styles.rowLabel}`}>订货号</label>
                        <span title={detailInfo.itemNumber}>{detailInfo.itemNumber}</span>
                      </Row>
                    </Col>

                    <Col span={4} className={`${styles.listCol}`}>
                      <label className={`${styles.rowLabel}`}>数量</label>
                      <InputNumber value={record.quantity} min={0}
                        onChange={(val) => this.props.onChangeQuantity(val, n)}
                      />
                    </Col>

                    <Col span={10} className={`${styles.listCol}`}>
                      <div style={{ display: 'inline-block', width: 'calc(100% - 70px)' }}>
                        {
                          detailInfoid.supplierOrderGoodsDetail && detailInfoid.supplierOrderGoodsDetail.length > 0
                            ? <Select style={{ width: '100%' }} placeholder='请选择RS供应商' dropdownMatchSelectWidth={false}
                              showSearch
                              disabled={detailInfoid.skuGoodsType === 'CLOUD_FUTURES'}
                              optionFilterProp='children'
                              value={record.distributorId}
                              onChange={(key) => this.props.onSelectSupplier(key, n)}

                            >
                              {
                                detailInfoid.supplierOrderGoodsDetail.map((item, i) => (
                                  <Option value={item.supplierId} key={`i`} >
                                    <div key={`${i}`}>
                                      <SupplierOptionContent distributorName={item.merchantName}
                                        skuGoodsType={detailInfoid.skuGoodsType}
                                        quantity={item.quantity}
                                        price={+item.sellingPrice}
                                      />
                                    </div>
                                  </Option>
                                ))
                              }
                            </Select>
                            : <div>暂无符合条件供应商</div>
                        }
                      </div>

                      {
                        detailInfoid.skuGoodsType === 'CLOUD_FUTURES'
                          ? null
                          : <div style={{ display: 'inline-block', width: '70px', textAlign: 'right' }}>
                            {
                              n === detailInfo.expandData[i].length - 1
                                ? <a className={`${styles.operateBtn}`} onClick={() => this.props.onAdd(n)}>
                                  <Icon type='plus' style={{ fontSize: '18px' }} />
                                </a>
                                : null
                            }

                            <a className={`${styles.operateBtn}`} disabled={detailInfo.expandData[i].length === 1}
                              onClick={() => this.props.onDelete(n)}
                            >
                              <Icon type='delete' style={{ fontSize: '18px' }} />
                            </a>
                          </div>
                      }
                    </Col>
                  </Row>
                </li>
              ))
            }

          </ul>
        </div>
      </Row>
    )
  }
}

export default DispenseAction
