import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import $lodash from 'lodash'

import InvoiceExpressView from '@/components/invoiceExpressView/index'
import InvoiceAccount from '@/components/invoiceAccount/index' // 开票企业信息
import InvoiceProduct from '@/components/invoiceProduct/index' // 开票物资信息
import InvoiceReceiving from '@/components/invoiceReceiving/index' // 发票收件地址

import Api from '@/common/api/index'

class InvoiceModal extends Component {
  static propTypes = {
    ids: PropTypes.array,
    openStatus: PropTypes.string,
    onCancel: PropTypes.func
  }
  state = {
    invoiceVoucherInfo: {}, // 发票物流&凭证信息
    invoiceInfo: {}, // 发票抬头
    tableData: [], // 发票物料信息
    totalAmount: 0,
    receivingAddress: {} // 收件信息
  }

  componentDidMount () {
    this.getSupplyOrderInvoiceOpenedDetails()
  }

  // 云供应发票- 获取申请历史详情
  getSupplyOrderInvoiceOpenedDetails = async () => {
    const { ids = [] } = this.props
    const res = await Api.getSupplyOrderInvoiceOpenedDetails({ invoiceRecordId: ids[0] })
    const { code, data } = res
    if (+code === 10000) {
      if (data && data.goodsInfo && data.goodsInfo.length > 0) {
        const { goodsInfo = [] } = data
        data.goodsInfo = $lodash.map(goodsInfo, (record) => {
          return {
            ...record,
            price: record.sellingPrice,
            orderId: record.purchaseOrderId
          }
        })
      }

      this.setState({
        invoiceVoucherInfo: data || {},
        invoiceInfo: data.invoiceInfo || {},
        tableData: data.goodsInfo || [],
        totalAmount: data.totalAmount || 0,
        receivingAddress: data.addressInfo || {}
      })
    }
  }

  render () {
    const { openStatus, onCancel } = this.props
    const { invoiceVoucherInfo = {}, invoiceInfo = {}, tableData = [], totalAmount = 0, receivingAddress = {} } = this.state

    return (
      <>
        <Modal title='发票详情' bodyStyle={{ padding: '12px' }}
          width={1000}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={<div style={{ textAlign: 'center' }}>
            <Button onClick={() => onCancel()}>关闭</Button>
          </div>}
        >
          <div>
            <InvoiceExpressView info={invoiceVoucherInfo} openStatus={openStatus} />
          </div>
          <div style={{ marginTop: '16px' }}>
            <InvoiceAccount invoiceInfo={invoiceInfo} />
          </div>

          <div style={{ marginTop: '16px' }}>
            <InvoiceProduct list={tableData} totalAmount={totalAmount} />
          </div>

          <div style={{ marginTop: '16px' }}>
            <InvoiceReceiving isEdit={false} receivingAddress={receivingAddress} />
          </div>
        </Modal>
      </>
    )
  }
}

export default InvoiceModal
