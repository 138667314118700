/**
 * 上传，下载API
 */
import config from '@/common/utils/config'

const { host } = config

// 上传-普通图片(不需要水印的图片)
const uploadOrdinaryImageUrl = `${host}/api/image/uploadByOthers`

// 上传-需要水印的图片
const uploadNeedLogoImageUrl = `${host}/api/image/uploadByProduct`

// 图文识别-上传图片
const uploadIntelDiscern = host + `/cloud/supplyOrder/imageToWords`

// 导入型号
const uploadSkuUrl = host + '/commodity/sku/import'

// 导入系列
const uploadSeriesItemUrl = host + '/api/excel/import/smart/parse'

// 批量云特价设置
const uploadSpecialPriceUrl = host + `/platform/disCloudSku/specialPrice/excelImport`

// 导入附加明细模板（初步客户核算单）
const uploadMoenyInfoTemplate = host + `/api/excel/upload/logistics/deppon/append/fee`

// 导入新建物流模板（初步客户核算单）
const uploaWlInfoTemplate = host + `/api/excel/upload/logistics/deppon/add/info`

// EXCEL上传匹配sheet
const uploadMatchExcelUrl = host + `/api/excel/import/getSheetNames`

// 询价-特价产品导入
const uploadEnquireSetProductExcel = host + '/api/excel/import/smart/parseByDisId'

// 订单管理-云采购订单- 上传订单附件
const uploadPurchaseOrderEnclosure = host + `/platformAndPurchaseOrder/file/upload`

export default {
  uploadOrdinaryImageUrl,
  uploadNeedLogoImageUrl,
  uploadIntelDiscern,
  uploadSkuUrl,
  uploadSeriesItemUrl,

  uploadSpecialPriceUrl,
  uploadMoenyInfoTemplate,
  uploaWlInfoTemplate,
  uploadMatchExcelUrl,
  uploadEnquireSetProductExcel,
  uploadPurchaseOrderEnclosure
}
