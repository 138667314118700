import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class ParameterForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-编辑
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  // 点击确认
  handleConfirm = () => {
    const { type = 1, detailInfo = {} } = this.props

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (+type === 2) {
          values.id = detailInfo.id
        }
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    return (
      <div>
        <Modal title='编辑参数'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem label='参数名称' {...formItemLayout}>
              {
                getFieldDecorator('attributeName', {
                  initialValue: detailInfo.attributeName || '',
                  rules: [
                    { required: true, message: '请填写参数名称' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='参数别名' {...formItemLayout}>
              {
                getFieldDecorator('attributeField', {
                  initialValue: detailInfo.attributeField || '',
                  rules: [
                    { required: true, message: '请填写参数别名' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const ParameterModal = Form.create()(ParameterForm)

export default ParameterModal
