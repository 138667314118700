import React, { Component } from 'react'
import { Card, Table, Button, Icon, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import BindRelationModal from '../component/bindRelationModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderCustomerAuthorLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, authorMaps } = filter

class CustomerRelations extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'erpDistributorCode',
        formType: 'Input',
        itemParams: {
          label: 'ERP客户编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'erpDistributorName',
        formType: 'Input',
        itemParams: {
          label: 'ERP客户名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '客户权限'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...authorMaps
        ]
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '公司名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val, 3)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    bindRelationModal: false,
    type: 'ADD',
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'column-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: 'ERP客户编号',
      key: 'customerRelationsCol-erpDistributorCode',
      dataIndex: 'erpDistributorCode'
    },
    {
      title: 'ERP客户名称',
      key: 'customerRelationsCol-erpDistributorName',
      dataIndex: 'erpDistributorName'
    },
    {
      title: '客户权限',
      key: 'customerRelationsCol-type',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ renderCustomerAuthorLabel(record.type) }</>
      )
    },
    {
      title: '客户编号',
      key: 'customerRelationsCol-distributorId',
      dataIndex: 'distributorId'
    },
    {
      title: '公司名称',
      key: 'customerRelationsCol-distributorName',
      dataIndex: 'distributorName'
    },
    {
      title: '统计名称',
      key: 'customerRelationsCol-statisticsMerchantName',
      dataIndex: 'statisticsMerchantName'
    },
    {
      title: '操作',
      key: 'column-operations',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showBindRelationModal(record, 'EDIT')}>修改</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getERPCustomerRelationsList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val, searchIndex) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        const { searchData = [] } = this.state
        searchData[searchIndex].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取ERP客户关系映射列表
  getERPCustomerRelationsList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getERPCustomerRelationsList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存新增客户关系映射
  saveAddERPCustomerRelation = async (params) => {
    const res = await Api.saveAddERPCustomerRelation(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getERPCustomerRelationsList()
    }
  }

  // 保存编辑客户关系映射
  saveEditERPCustomerRelation = async (params) => {
    const res = await Api.saveEditERPCustomerRelation(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getERPCustomerRelationsList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      },
      query: search || {}
    }, () => {
      this.getERPCustomerRelationsList()
    })
  }

  // 点击新增、编辑
  showBindRelationModal = (record, type) => {
    this.setState({
      type: type,
      rowDetail: record,
      bindRelationModal: true
    })
  }

  // 点击确定保存ERP客户关系
  handleConfirmBindRelation = (params) => {
    const { type = 'ADD' } = this.state

    if (type === 'EDIT') {
      this.saveEditERPCustomerRelation(params)
    } else {
      this.saveAddERPCustomerRelation(params)
    }
  }

  closeModal = () => {
    this.setState({
      bindRelationModal: false,
      type: 'ADD',
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getERPCustomerRelationsList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getERPCustomerRelationsList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], bindRelationModal = false, type = 'ADD', rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.showBindRelationModal({}, 'ADD')}><Icon type='plus' />新增关系</Button>
          </div>

          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        {
          bindRelationModal
            ? <BindRelationModal
              bindKey='BIND_CUSTOMER'
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleConfirmBindRelation(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default CustomerRelations
