import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Row, Card, Table, Button, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import ProfitModal from './profitModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class ProfitSetting extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        rules: {
          initialValue: 0
        },
        options: [],
        optionValue: ['brandId', 'showName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      brandId: 0
    },
    type: 1, // 1-批量设置 2-单个设置
    profitModal: false, // 设置利润弹窗
    rowDetail: {},
    selectedRowKeys: [] // 选中的品牌
  }
  columns = [
    {
      title: '序号',
      key: 'brandProfit-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'brandProfit-1',
      dataIndex: 'brandName'
    },
    {
      title: '利润率',
      key: 'brandProfit-2',
      dataIndex: 'profitMargin',
      render: (text, record, index) => (
        <>{ (+record.profitMargin * 100 / 100).toFixed(2) }%</>
      )
    },
    {
      title: '供应商数量',
      key: 'brandProfit-3',
      sorter: (a, b) => a.supplierTotal - b.supplierTotal,
      dataIndex: 'supplierTotal'
    },
    {
      title: '操作',
      key: 'brandProfit-4',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.handleLinkToDetail(record)}>查看详情</a>
          <a style={{ marginLeft: '8px' }} onClick={() => this.showSetProfitModal(record, 2)}>利润设置</a>
        </>
      )
    }
  ]

  componentDidMount () {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    axios.all([Api.getBrandListInSearch({ pageNo: 1, pageSize: 999 }), Api.getProfitListPageForSupplyBrand(params)])
      .then(axios.spread((brandRes, listRes) => {
        let { searchData, tableData } = this.state

        if (+brandRes.code === 10000 && brandRes.data && brandRes.data.length > 0) {
          brandRes.data.map((brandItem) => {
            brandItem['showName'] = brandItem.englishBrandName && brandItem.englishBrandName !== '' ? `${brandItem.brandName}/${brandItem.englishBrandName}` : brandItem.brandName
          })

          searchData[0].options = $lodash.concat([{ brandId: 0, showName: '全部' }], brandRes.data)
        }

        if (+listRes.code === 10000) {
          let { data } = listRes
          tableData = data.list || []
          pages['total'] = data.total || 0
        }

        this.setState({
          searchData,
          tableData,
          pages
        })
      }))
  }

  // 获取品牌利润列表
  getProfitListPageForSupplyBrand = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getProfitListPageForSupplyBrand(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存利润设置
  saveProfitBatchSetting = (params) => {
    Api.saveProfitBatchSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getProfitListPageForSupplyBrand()
      }
    })
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => this.getProfitListPageForSupplyBrand())
  }

  // 点击利润设置
  showSetProfitModal = (record, type) => {
    this.setState({
      profitModal: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击查看详情
  handleLinkToDetail = (record) => {
    this.props.history.push({ pathname: '/admin/cloudBrand', state: { detailInfo: record } })
  }

  // 点击勾选
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  closeModal = () => {
    this.setState({
      type: 1,
      profitModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getProfitListPageForSupplyBrand()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getProfitListPageForSupplyBrand()
    })
  }

  render () {
    const { searchData, pages, tableData, type = 1, profitModal = false, rowDetail = {}, selectedRowKeys = [] } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Button type='primary' disabled={selectedRowKeys.length === 0}
              onClick={() => this.showSetProfitModal()}
            >勾选批量设置</Button>
          </Row>

          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            rowSelection={rowSelection}
          />
        </Card>

        {
          profitModal
            ? <ProfitModal
              type={type}
              detailInfo={rowDetail}
              selectedkeys={type === 1 ? selectedRowKeys : [rowDetail.id]}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.saveProfitBatchSetting(values)}
            />
            : null
        }
      </>
    )
  }
}

export default ProfitSetting
