import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table, Badge } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

const creditMap = [
  { type: 'ENABLE_UNKNOWN', label: '未开通', status: 'warning' },
  { type: 'ENABLE_ON', label: '启用中', status: 'success' },
  { type: 'ENABLE_OFF', label: '禁用中', status: 'error' }
]

export default class CreditList extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商家名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'enable',
        formType: 'Select',
        itemParams: {
          label: '信用状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLE_UNKNOWN', name: '未开通' },
          { id: 'ENABLE_ON', name: '启用中' },
          { id: 'ENABLE_OFF', name: '禁用中' }
        ]
      },
      {
        name: 'overdue',
        formType: 'Select',
        itemParams: {
          label: '信用逾期'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'OVERDUE_NO', name: '未逾期' },
          { id: 'OVERDUE_YES', name: '已逾期' }
        ]
      }
    ]
  }

  columns = [
    {
      title: '公司名称',
      key: 'plat-0',
      dataIndex: 'customerName'
    },
    {
      title: '信用支付',
      key: 'plat-1',
      render: (text, record, index) => (
        <div>
          <Badge status={this.renderCreditBadge(record.enable)} />
          <span>
            {this.renderCreditStatusLabel(record.enable)}
          </span>
        </div>
      )
    },
    {
      title: '信用额度',
      key: 'plat-2',
      render: (text, record, index) => (
        <div>
          {record.usedAmount}/{record.maxAmount}
        </div>
      )
    },
    {
      title: '账期时长(天)',
      key: 'plat-3',
      render: (text, record, index) => (
        <div>
          {record.period}
        </div>
      )
    },
    {
      title: '最近还款日',
      key: 'plat-4',
      render: (text, record, index) => (
        <>
          { record.lastPeriodTime ? moment(record.lastPeriodTime).format('YYYY-MM-DD') : '----' }
        </>
      )
    },
    {
      title: '操作',
      key: 'plat-5',
      width: 150,
      render: (text, record, index) => (
        <div>
          {
            record.enable !== 'ENABLE_UNKNOWN'
              ? <a
                onClick={() => this.handleJumpToDetail(record)}
              >查看详情</a>
              : null
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getPlatCreditList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取平台信用信息
  getPlatCreditList = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getPlatCreditList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    }, () => {
      this.getPlatCreditList()
    })
  }

  // 点击查看详情
  handleJumpToDetail = (record) => {
    this.props.history.push({ pathname: '/admin/creditDetail', state: { detailInfo: record } })
  }

  // 渲染信用支付状态
  renderCreditStatusLabel = (type = '') => {
    const obj = creditMap.find(item => { return item.type === type })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 选用Badge 状态
  renderCreditBadge = (type = '') => {
    const obj = creditMap.find(item => { return item.type === type })
    const label = obj !== undefined ? obj.status : ''

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPlatCreditList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPlatCreditList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}
