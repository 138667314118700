import React, { Component } from 'react'
import { Card, Row, Table, Alert } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SupplyModal from './supplyModal'
import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
// import filter from '@/common/utils/filter'

// const { pageSizeOptions } = filter
const pageSizeOptions = ['10', '20', '50', '100', '200', '500', '1000']

class CloudorderTrade extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '采购商家'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '供应单号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    profitRate: 0.00,
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'cloudTrade-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单号',
      key: 'cloudTrade-1',
      dataIndex: 'orderId'
    },
    {
      title: '订单日期',
      key: 'cloudTrade-2',
      width: 100,
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '采购商',
      key: 'cloudTrade-4',
      width: 150,
      ellipsis: true,
      dataIndex: 'distributorName'
    },
    {
      title: '采购总价',
      key: 'cloudTrade-7',
      dataIndex: 'purchaseTotalPrice',
      render: (text, record, index) => (
        <>&yen;{+record.purchaseTotalPrice}</>
      )
    },
    {
      title: '供应总价',
      key: 'cloudTrade-8',
      dataIndex: 'supplyTotalPrice',
      render: (text, record, index) => (
        <>&yen;{+record.supplyTotalPrice}</>
      )
    },
    {
      title: '利润率',
      key: 'cloudTrade-9',
      render: (text, record, index) => (
        <div>
          { (+record.profitRate * 100 * 100 / 100).toFixed(2) }%
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getCloudTradeDetailList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取云端交易详情数据
  getCloudTradeDetailList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudTradeDetailList(params).then(res => {
      const { code, data = {} } = res
      if (+code === 10000) {
        let _list = []
        let _total = 0

        if (data && data.supplyOrderInfoResult && data.supplyOrderInfoResult.list) {
          _list = data.supplyOrderInfoResult.list || []
          _total = data.supplyOrderInfoResult.total || 0
        }

        if (_list.length > 0) {
          _list.map((item, i) => {
            item.rowKey = `${item.supplyOrderId}-${i}`
          })
        }

        this.setState({
          profitRate: data.profitRate || 0,
          tableData: _list,
          pages: {
            ...pages,
            total: _total
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages } = this.state

    if (search.orderTime) {
      let orderTime = search.orderTime
      search.beginDate = orderTime[0].format('YYYY-MM-DD')
      search.endDate = orderTime[1].format('YYYY-MM-DD')

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCloudTradeDetailList()
    })
  }

  // 点击查看供应明细
  handleShowSupplyModal = (record) => {
    this.setState({
      rowDetail: record,
      supplyModal: true
    })
  }

  closeModal = () => {
    this.setState({
      supplyModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudTradeDetailList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudTradeDetailList()
    })
  }

  // 渲染展开的内容
  renderExpandedRow = (row) => {
    const rowColumns = [
      {
        title: '品牌',
        key: `expandRow-${row.supplyOrderId}-0`,
        dataIndex: 'brandName'
      },
      {
        title: '产品',
        key: `expandRow-${row.supplyOrderId}-1`,
        render: (text, record, index) => (
          <Row>
            <Row>型号: <span>{record.model}</span></Row>
            <Row>订货号: <span>{record.itemNumber}</span></Row>
            <Row>名称: <span>{record.name}</span></Row>
          </Row>
        )
      },
      {
        title: '采购数量',
        key: `expandRow-${row.supplyOrderId}-2`,
        dataIndex: 'goodsAmount'
      },
      {
        title: '采购单价',
        key: `expandRow-${row.supplyOrderId}-3`,
        dataIndex: 'supplyPrice',
        render: (text, record, index) => (
          <Row>&yen;{+record.supplyPrice}</Row>
        )
      },
      {
        title: '采购总价',
        key: `expandRow-${row.supplyOrderId}-4`,
        dataIndex: 'upplyTotalPrice',
        render: (text, record, index) => (
          <Row>&yen;{record.supplyTotalPrice}</Row>
        )
      },
      {
        title: '操作',
        key: `expandRow-${row.orderId}-5`,
        render: (text, record, index) => (
          <Row>
            <a onClick={() => this.handleShowSupplyModal({ ...record, orderId: row.orderId })}>采购详情</a>
          </Row>
        )
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey='commoditySkuId'
          columns={rowColumns}
          dataSource={row.supplyOrderGoodsList || []}
          pagination={false}
        />
      </Row>
    )
  }

  render () {
    const { searchData, pages, profitRate = 0, tableData = [], supplyModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Alert type='info' message={<div>
                <label>当页产品利润率：</label>
                <span>{(+profitRate * 100 * 100 / 100).toFixed(2)}%</span>
              </div>}
              />
            </Row>
            <Row>
              <Table rowKey='rowKey' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
                expandedRowRender={(record) => this.renderExpandedRow(record)}
              />
            </Row>
          </Card>
        </Row>

        {
          supplyModal
            ? <SupplyModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}

export default CloudorderTrade
