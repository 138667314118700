import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Button, Badge, Dropdown, Icon, Empty, Pagination, Menu, message } from 'antd'

import Api from '@/common/api/index'
import BaseModal from './baseModal'

import filter from '@/common/utils/filter'
import styles from './advert.module.scss'

const { pageSizeOptions } = filter

export default class index extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    type: 1, // 编辑类型 1-新增 2-编辑
    bannerModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    this.getAdvertListInBanner()
  }

  // 获取IIA banner广告列表
  getAdvertListInBanner = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getAdvertListInBanner(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total
          }
        })
      }
    })
  }

  // 保存新增banner广告
  saveAdvertInBanner = (params) => {
    Api.saveAdvertInBanner(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getAdvertListInBanner()
      }
    })
  }

  // 更新banner广告基本设置
  editAdvertInBanner = (params) => {
    Api.editAdvertInBanner(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getAdvertListInBanner()
      }
    })
  }

  // 点击新增/编辑banner
  handleShowEditBannerModal = (record, type) => {
    this.setState({
      bannerModal: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击确定新增、编辑
  handleConfirmSave = (params) => {
    const { type = 1 } = this.state
    if (+type === 1) {
      this.saveAdvertInBanner(params)
    } else {
      this.editAdvertInBanner(params)
    }
  }

  // 点击跳转到页面设置
  handleLinkToDetail = (record) => {
    this.props.history.push({ pathname: '/admin/bannermallSet', state: { detailInfo: record } })
  }

  // 点击跳转到关联商户
  handleLinkToBindedRecord = (record) => {
    this.props.history.push({ pathname: '/admin/bannerBind', state: { detailInfo: record } })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      bannerModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getAdvertListInBanner()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getAdvertListInBanner()
    })
  }

  renderDropDwonMenu = (record) => {
    return (
      <Menu>
        <Menu.Item>
          <a onClick={() => this.handleShowEditBannerModal(record, 2)}>图片设置</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleLinkToBindedRecord(record, 2)}>关联商户</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleLinkToDetail(record, 2)}>页面设置</a>
        </Menu.Item>
      </Menu>
    )
  }

  render () {
    const { pages, tableData, bannerModal = false, rowDetail = {}, type = 1 } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Button type='primary' onClick={() => this.handleShowEditBannerModal({}, 1)}>
            <Icon type='plus' />新增
          </Button>
        </Row>

        {
          tableData.length > 0
            ? <Row>
              <ul className={`${styles.materialBody}`}>
                {
                  tableData.map((item, i) => (
                    <li className={`${styles.materialItem}`} key={`material-${i}`}>
                      <div className={`${styles.picLand}`}>
                        <a href={item.imageKey} target='_blank'>
                          <img src={item.imageKey} />
                        </a>
                      </div>
                      <div className={`${styles.txtLand}`}>{item.name}</div>
                      <div className={`${styles.otherLand}`}>
                        <div className={`${styles.flexItem}`}>
                          <Badge status={item.enable === 'ENABLE' ? 'success' : 'error'}
                            text={item.enable === 'ENABLE' ? '启用中' : '禁用中'}
                          />
                        </div>
                        <div className={`${styles.flexItem} ${styles.operate}`}>
                          <Dropdown overlay={() => this.renderDropDwonMenu(item)}>
                            <a>设置<Icon type='down' style={{ marginLeft: '5px' }} /></a>
                          </Dropdown>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>

              <Row style={{ paddingTop: '30px', textAlign: 'center' }}>
                <Pagination
                  showSizeChanger
                  total={pages.total}
                  showTotal={(total) => { return `共 ${total} 条` }}
                  current={pages.pageNo}
                  pageSize={pages.pageSize}
                  pageSizeOptions={pageSizeOptions}
                  onChange={this.pageChange}
                  onShowSizeChange={this.pageSizeChange}
                />
              </Row>
            </Row>
            : <Row>
              <Empty />
            </Row>
        }

        {
          bannerModal
            ? <BaseModal
              type={type}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleConfirmSave(params)}
            />
            : null
        }
      </div>
    )
  }
}
