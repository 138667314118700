import React, { Component } from 'react'
import { Modal, Form, Col, Select, Row, Input } from 'antd'
import PropTypes from 'prop-types'
const FormItem = Form.Item
class updateModal extends Component {
  static propTypes={
    oncloseModal: PropTypes.func,
    form: PropTypes.object
    //  okRemarksModal: PropTypes.func,
    // rowDetail: PropTypes.object
  }
  render () {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }
    return (
      <>
        <Modal title='更改快递信息'
          visible
          onOk={this.handleOk}
          onCancel={this.props.oncloseModal}
        >
          <Row gutter={8}>
            <Col span={12}>
              <FormItem label='出发简称' {...formItemLayout}>
                {
                  getFieldDecorator('start', {
                    rules: [
                      { required: true, message: '必填' }
                    ]
                  })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label='到达简称' {...formItemLayout}>
                {
                  getFieldDecorator('start', {
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  })(<Input />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <FormItem label='快递类型' {...formItemLayout}>
                {
                  getFieldDecorator('start', {
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  })(<Select />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label='商户名称' {...formItemLayout}>
                {
                  getFieldDecorator('start', {
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  })(<Select />)}
              </FormItem>
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
}
const updateModal1 = Form.create()(updateModal)

export default updateModal1
