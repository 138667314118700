import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table } from 'antd'
import { connect } from 'react-redux'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class EucustomerList extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '所属商家',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'enterpriseName',
        formType: 'Input',
        itemParams: {
          label: '企业名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'realname',
        formType: 'Input',
        itemParams: {
          label: '真实姓名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {}, //  查询
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'euCustomer-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      key: 'euCustomer-1',
      dataIndex: 'enterpriseName'
    },
    {
      title: '真实姓名',
      key: 'euCustomer-2',
      dataIndex: 'realname'
    },
    {
      title: '所属商家',
      key: 'euCustomer-3',
      dataIndex: 'distributorName'
    },
    {
      title: '客户类型',
      key: 'euCustomer-4',
      dataIndex: 'customerType'
    },
    {
      title: '联系方式',
      key: 'euCustomer-5',
      width: 120,
      dataIndex: 'contacts'
    },
    {
      title: '入驻时间',
      key: 'euCustomer-6',
      dataIndex: 'enterTime'
    },
    {
      title: '操作',
      key: 'euCustomer-8',
      width: 120,
      render: (text, record, index) => {
        return (
          <div>
            <a onClick={() => this.handleJumpToEuOrder(record)}>查看详情</a>
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getEuCustomerList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取eu客户列表
  getEuCustomerList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEuCustomerList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getEuCustomerList()
    })
  }

  // 点击跳转到eu客户订单
  handleJumpToEuOrder = (record) => {
    this.props.history.push({ pathname: '/admin/euOrder', state: record })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEuCustomerList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEuCustomerList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='euId' bordered
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(EucustomerList)
