import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Cascader, Switch, message } from 'antd'
import $lodash from 'lodash'

import DiscernAddress from '../discernAddress/index'
import { addressToTree } from '@/common/utils/mUtils'

const FormItem = Form.Item
const { TextArea } = Input
const cityOptions = addressToTree()

class AddressForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    componentType: PropTypes.string,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { componentType, type = 1, detailInfo = {} } = this.props
        const { name, mobile, citys, address, defaultType } = values

        if ($lodash.trim(name).length === 0) { return message.warning(`请输入${componentType === 'SEND' ? '发货人' : '收货人'}名称`) }

        if ($lodash.trim(address).length === 0) { return message.warning('请输入详细地址') }

        if (+citys[1] === +citys[2]) { return message.warning('请选择行政区！') }

        let params = {
          name: $lodash.trim(name),
          mobile: $lodash.trim(mobile),
          province: citys[0],
          city: citys[1],
          county: citys[2],
          address: $lodash.trim(address),
          defaultType: defaultType ? 'ENABLE' : 'DISABLE'
        }

        if (type > 1) {
          params.id = detailInfo.id
        }

        this.props.onConfirm(params)
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  // 智能地址设置显示
  setIntelAddress = (values) => {
    const { name, mobile, address, province, city, county } = values

    setTimeout(() => {
      this.props.form.setFieldsValue({ name: name, mobile: mobile, address: address, citys: [province, city, county] })
    }, 20)
  }

  render () {
    const { componentType, type = 1, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Modal title={+type === 1 ? '新增地址' : '修改地址'} width={560} bodyStyle={{ padding: '5px 40px' }}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.handleCancel}
        >
          <div>
            <DiscernAddress onConfirm={(values) => this.setIntelAddress(values)} />
          </div>

          <Form>
            <FormItem label={`${componentType === 'SEND' ? '发' : '收'}货人姓名`} style={{ marginBottom: '2px' }}>
              {
                getFieldDecorator('name', {
                  initialValue: detailInfo.name || '',
                  rules: [
                    { required: true, message: `请填写${componentType === 'SEND' ? '发' : '收'}货人姓名` }
                  ]
                })(
                  <Input placeholder={`请输入${componentType === 'SEND' ? '发' : '收'}货人姓名`} autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='联系方式' style={{ marginBottom: '2px' }}>
              {
                getFieldDecorator('mobile', {
                  initialValue: detailInfo.mobile || '',
                  validate: [
                    {
                      trigger: 'onChange',
                      rules: [{ required: true, message: `请填写${componentType === 'SEND' ? '发' : '收'}货人联系方式` }]
                    }
                  ]
                })(
                  <Input placeholder={`请输入${componentType === 'SEND' ? '发' : '收'}货人联系方式`} autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='所在地区' colon={false} style={{ marginBottom: '2px' }}>
              {
                getFieldDecorator('citys', {
                  initialValue: +type === 2 && detailInfo.province && detailInfo.city && detailInfo.county ? [`${detailInfo.province}`, `${detailInfo.city}`, `${detailInfo.county}`] : [],
                  rules: [
                    { required: true, message: '请选择省、市、区' }
                  ]
                })(
                  <Cascader placeholder='请选择省市区'
                    fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                    options={cityOptions}
                  />
                )
              }
            </FormItem>
            <FormItem label='详细地址' colon={false} style={{ marginBottom: '2px' }}>
              {
                getFieldDecorator('address', {
                  rules: [
                    { required: true, message: '请填写详细地址' }
                  ],
                  initialValue: detailInfo.address || ''
                })(
                  <TextArea rows={4} placeholder='请输入详细地址' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem style={{ marginBottom: '0px' }}>
              <FormItem style={{ display: 'inline-block', width: 'calc(100% - 200px)' }}><label style={{ color: '#333333' }}>设置为默认地址</label></FormItem>
              <FormItem style={{ display: 'inline-block', width: '200px', textAlign: 'right' }}>
                {
                  getFieldDecorator('defaultType', {
                    initialValue: detailInfo.defaultType === 'ENABLE',
                    valuePropName: 'checked'
                  })(<Switch checkedChildren='是' unCheckedChildren='否' />)
                }
              </FormItem>
            </FormItem>
          </Form>

        </Modal>
      </>
    )
  }
}

const AddressModal = Form.create()(AddressForm)

export default AddressModal
