import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Select, Upload, Button, Icon, message } from 'antd'
import $lodash from 'lodash'

import UploadSheetModal from '@/components/uploadSheetModal'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

const FormItem = Form.Item
const Option = Select.Option
const { uploadMatchExcelUrl } = UploadApi

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 }
}

function UploadWorkOrderForm (props) {
  const { history, form, onCancel } = props
  const { getFieldDecorator } = form

  const [importId, setImportId] = useState(null)
  const [enquireOrderIdOptions, setEnquireOrderIdOptions] = useState([])
  const [uploadSheetModal, setUploadSheetModal] = useState(false)
  const [uploadSheetDetails, setUploadSheetDetails] = useState({})

  // 模糊查询加单Id
  const getEnqireOrderIdsInLike = async (value) => {
    const res = await Api.getEnqireOrderIdsInLike({ enquiryOrderId: value })
    const { code, data } = res
    if (+code === 10000) {
      let options = data && data.length > 0 ? data : []

      setEnquireOrderIdOptions(options)
    }
  }

  // 上传EXCEL智能解析
  const saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      closeModal()
      changeUploadSuccess(data)
    }
  }

  // 监听文件上传
  const handleChangeUploadFile = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setUploadSheetDetails({
          name: data.tableName || '',
          list: data.sheetNames || [],
          file: info.file.originFileObj
        })
        setUploadSheetModal(true)
      } else {
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      message.error('上传失败')
    }
  }

  // 点击确定选择导入EXCEL的sheet
  const handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  const changeUploadSuccess = (params) => {
    setImportId(params)
  }

  // 点击确定按钮
  const handleConfirm = () => {
    form.validateFields((err, values) => {
      if (!err) {
        if (!importId || importId === 0) {
          return message.warning('请上传要导入的工单文件！')
        }

        history.push({ pathname: '/admin/matchSheet', state: { importId: importId, sourceType: 'UPLOAD_WORK_ORDER', defaultParams: { enquiryOrderId: values.enquiryOrderId } } })
      }
    })
  }

  const closeModal = () => {
    setUploadSheetDetails({})
    setUploadSheetModal(false)
  }

  return (
    <>
      <Modal title='导入工单'
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        onOk={() => handleConfirm()}
      >
        <Form>
          <FormItem label='询价单号' {...formItemLayout}>
            {
              getFieldDecorator('enquiryOrderId', {
                rules: [
                  { required: true, message: '请选择要导入的询价单号' }
                ]
              })(
                <Select placeholder='请输入搜索要导入的询价单号' showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={(value) => getEnqireOrderIdsInLike(value)}
                >
                  {
                    enquireOrderIdOptions.map((item, index) => (
                      <Option value={item} key={item}>{item}</Option>
                    ))
                  }
                </Select>
              )
            }
          </FormItem>

          <FormItem label={<span className={`required`}>导入工单</span>} {...formItemLayout}>
            <Upload name='file'
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              headers={{ Token: getStore('token') }}
              action={uploadMatchExcelUrl}
              onChange={(info) => handleChangeUploadFile(info)}
            >
              <Button>
                <Icon type='upload' />点击上传文件
              </Button>
            </Upload>
          </FormItem>
        </Form>
      </Modal>

      {
        uploadSheetModal
          ? <UploadSheetModal
            name={uploadSheetDetails.name}
            list={uploadSheetDetails.list}
            file={uploadSheetDetails.file}
            onConfirm={(values) => handleConfirmUploadSheet(values)}
            onCancel={() => closeModal()}
          />
          : null
      }
    </>
  )
}

UploadWorkOrderForm.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  onCancel: PropTypes.func
}

const UploadWorkOrderModal = Form.create()(UploadWorkOrderForm)

export default UploadWorkOrderModal
