import React, { Component } from 'react'
import { Table, Button, message, Card, Tooltip } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form'
import InvoiceModal from './invoiceApplyModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderInvoiceOpenedStatusLabel, renderOrderDeliveryStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, orderDeliveryStatusMaps } = filter

class PurchaseInvoiceOpening extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  static propTypes = {

  }
  state = {
    searchData: [
      {
        name: 'id',
        formType: 'Input',
        itemParams: {
          label: '开票申请编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      },
      {
        name: 'invoiceTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedTableRows: [],
    checkedIds: [],
    checkedDistributorId: null, // 商户ID
    invoiceModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'invoiceOpeningCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '开票申请编号',
      key: 'invoiceOpeningCol-1',
      dataIndex: 'id'
    },
    {
      title: '商户名称',
      key: 'invoiceOpeningCol-2',
      width: 180,
      ellipsis: true,
      dataIndex: 'distributorName'
    },
    {
      title: '订单编号',
      key: 'invoiceOpeningCol-3',
      dataIndex: 'orderId'
    },
    {
      title: '开票金额',
      key: 'invoiceOpeningCol-4',
      dataIndex: 'invoiceAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.invoiceAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '订单日期',
      key: 'invoiceOpeningCol-5',
      dataIndex: 'orderDate',
      render: (text, record, index) => (
        <>
          { record.orderDate ? moment(record.orderDate).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '发货状态',
      key: 'invoiceOpeningCol-6',
      dataIndex: 'deliveryStatus',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '订单备注',
      key: 'invoiceOpeningCol-7',
      width: 180,
      ellipsis: true,
      dataIndex: 'remarks',
      render: (text, record, index) => (
        <>
          <Tooltip title={record.remarks}><span>{ record.remarks }</span></Tooltip>
        </>
      )
    },
    {
      title: '申请时间',
      key: 'invoiceOpeningCol-8',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '开票状态',
      key: 'invoiceOpeningCol-9',
      dataIndex: 'invoiceOrderStatus',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.invoiceOrderStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'invoiceOpeningCol-10',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showInvoiceModal([record])}>查看详情</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderInvoiceUnOpenList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[1].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取待开票的采购订单列表
  getPurchaseOrderInvoiceUnOpenList = () => {
    const { query = {}, pages } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getPurchaseOrderInvoiceUnOpenList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 云采购发票- 确认开票
  submitPurchaseOrderInvoiceOpening = async (params) => {
    const res = await Api.submitPurchaseOrderInvoiceOpening(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaseOrderInvoiceUnOpenList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search['orderBeginDate'] = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search['orderEndDate'] = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    if (search.invoiceTimes) {
      search['invoiceBeginDate'] = search.invoiceTimes.length === 2 ? search.invoiceTimes[0].format('YYYY-MM-DD') : undefined
      search['invoiceEndDate'] = search.invoiceTimes.length === 2 ? search.invoiceTimes[1].format('YYYY-MM-DD') : undefined

      delete search.invoiceTimes
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getPurchaseOrderInvoiceUnOpenList()
    })
  }

  // 点击合并开票 & 查看详情
  showInvoiceModal = (selectedrows = []) => {
    const newDistributorIds = $lodash.uniqBy(selectedrows, 'distributorId')

    if (newDistributorIds.length > 1) {
      return message.warning('请勾选同一商户进行开票')
    }

    this.setState({
      checkedIds: $lodash.map(selectedrows, (o) => { return o.id }),
      checkedDistributorId: selectedrows[0].distributorId,
      invoiceModal: true
    })
  }

  // 选择
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedTableRows: rows
    })
  }

  closeModal = () => {
    this.setState({
      checkedIds: [],
      checkedDistributorId: null,
      invoiceModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchaseOrderInvoiceUnOpenList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchaseOrderInvoiceUnOpenList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], selectedTableRows = [], invoiceModal = false, checkedIds = [], checkedDistributorId = null } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const selectedRowKeys = $lodash.map(selectedTableRows, (o) => { return o.id })

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' disabled={selectedRowKeys.length === 0} onClick={() => this.showInvoiceModal(selectedTableRows)}>合并开票</Button>
          </div>

          <Table rowKey='id' bordered
            dataSource={tableData}
            columns={this.columns}
            rowSelection={rowSelection}
            rowClassName={(record, index) => record.invoiceSource === 'AFTERSALE_INVOICE' ? 'redColor' : null}
            pagination={pagination}
          />
        </Card>

        {
          invoiceModal
            ? <InvoiceModal
              ids={checkedIds}
              distributorId={checkedDistributorId}
              onConfirm={(params) => this.submitPurchaseOrderInvoiceOpening(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default PurchaseInvoiceOpening
