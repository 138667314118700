import React, { Component } from 'react'
import { Table } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import styles from '../index.module.scss'

class SyncStop extends Component {
  static propTypes = {}
  state = {
    tableData: []
  }
  columns = [
    {
      title: '最终传输时间',
      key: 'SYNC_WARNING_lastModifyTime',
      dataIndex: 'lastModifyTime',
      render: (text, record, index) => (
        <>{ record.lastModifyTime ? moment(record.lastModifyTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '传输对象',
      key: 'SYNC_WARNING_distributorId',
      dataIndex: 'distributorName'
    }
  ]

  componentDidMount () {
    this.getSyncStopWarningList()
  }

  // 获取第三方商品同步预警预警列表
  getSyncStopWarningList = async () => {
    const res = await Api.getSyncStopWarningList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  render () {
    const { tableData } = this.state

    return (
      <>
        <div className={`${styles.title}`}>同步停止预警(3天)</div>
        <Table rowKey={(record, index) => `SYNC_WARNING_RECORD-${record.distributorId}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />
      </>
    )
  }
}

export default SyncStop
