import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Form, Table, Row, Col, Button, Upload, message, Icon, Modal, Tag, Tooltip } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form'
import SkuBaseModal from '../component/skuBaseModal/index' // 编辑型号弹窗
import UploadSkuImageModal from '../component/uploadSkuImageModal' // 上传图片功能弹窗
import AddToModal from './addToModal/index' // 添加到分类、系列弹窗

import Api from '@/common/api'
import DownloadApi from '@/common/api/downloadApi'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'

const { downloadUploadProductTemplate } = DownloadApi
const { uploadSkuUrl } = UploadApi

const { pageSizeOptions, repLineBreak } = filter

const { confirm } = Modal
const selectionHasStatusMaps = [
  { id: 1, name: '是' },
  { id: 0, name: '否' }
]

class SpeciListForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    buttonMaps: PropTypes.object
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (val) => {
            this.getSeriesListByBrandId(val)
          }
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'seriesId',
        formType: 'Select',
        itemParams: {
          label: '系列'
        },
        cptParams: {
          placeholder: '请选择系列...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: []
      },
      {
        name: 'classificationIds',
        formType: 'Cascader',
        itemParams: {
          label: '所属分类'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          fieldNames: { value: 'id', label: 'name', children: 'classifications' }
        },
        options: []
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号'
        },
        cptParams: {
          placeholder: '请输入型号...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '订货号'
        },
        cptParams: {
          placeholder: '请输入订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号/订货号...',
          autoComplete: 'off',
          rows: 1
        }
      },
      {
        name: 'categoryStatus',
        formType: 'Select',
        itemParams: {
          label: '是否关联分类'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: -1, name: '全部' },
          ...selectionHasStatusMaps
        ]
      },
      {
        name: 'seriesStatus',
        formType: 'Select',
        itemParams: {
          label: '是否关联系列'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: -1, name: '全部' },
          ...selectionHasStatusMaps
        ]
      },
      {
        name: 'createTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '创建时间'
        },
        cptParams: {

        }
      },
      {
        name: 'createName',
        formType: 'Input',
        itemParams: {
          label: '创建人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '修改时间'
        },
        cptParams: {

        }
      },
      {
        name: 'modifyName',
        formType: 'Input',
        itemParams: {
          label: '修改人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'isUploadImage',
        formType: 'Select',
        itemParams: {
          label: '是否有产品主图'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: -1, name: '全部' },
          ...selectionHasStatusMaps
        ]
      },
      {
        name: 'isUploadDetailImage',
        formType: 'Select',
        itemParams: {
          label: '是否有产品详情图'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: -1, name: '全部' },
          ...selectionHasStatusMaps
        ]
      },
      {
        name: 'isUploadPDFImage',
        formType: 'Select',
        itemParams: {
          label: '是否有产品PDF'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: -1, name: '全部' },
          ...selectionHasStatusMaps
        ]
      }
    ],
    tableData: [],
    skuBaseModal: false, // 编辑型号基础信息弹窗
    addToModal: false, // 添加到分类、系列弹窗
    addToCode: null,
    uploadSkuImagemodal: false, // 上传图片功能弹窗
    type: 1, // 1-新增 2-修改
    selectedkeys: [], // 选中的数据
    rowDetail: {}, // 当前选择的行数据
    query: {} //  查询
  }

  columns = [
    {
      title: '序号',
      key: 'sku-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'sku-1',
      width: 140,
      render: (text, record, index) => (
        <>
          <div>{record.brandName}</div>
          <div>{record.englishBrandName}</div>
        </>
      )
    },
    {
      title: '系列',
      key: 'sku-2',
      width: 120,
      ellipsis: true,
      dataIndex: 'series'
    },
    {
      title: '所属分类',
      key: 'sku-3',
      width: 140,
      ellipsis: true,
      dataIndex: 'categoryName'
    },
    {
      title: '型号',
      key: 'sku-4',
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'sku-5',
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'sku-6',
      ellipsis: true,
      dataIndex: 'name',
      width: 180
    },
    {
      title: '表价',
      key: 'sku-7',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '最小包装',
      key: 'sku-8',
      dataIndex: 'minPackQuantity',
      width: 80
    },
    {
      title: '最小订货',
      key: 'sku-9',
      dataIndex: 'minOrder',
      width: 80
    },
    {
      title: '单位',
      key: 'sku-10',
      dataIndex: 'unit',
      width: 80
    },
    {
      title: '是否停产',
      key: 'sku-11',
      width: 80,
      dataIndex: 'stopFlag',
      render: (text, record, index) => (
        <>{ record.stopFlag === 'STOP_PRODUCTION' ? '是' : '否'}</>
      )
    },
    {
      title: '产品描述',
      key: 'sku-12',
      dataIndex: 'introduce',
      ellipsis: true
    },
    {
      title: '创建时间',
      key: 'sku-13',
      width: 100,
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '创建人',
      key: 'sku-14',
      width: 100,
      dataIndex: 'createName'
    },
    {
      title: '修改时间',
      key: 'sku-15',
      width: 100,
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '修改人',
      key: 'sku-16',
      width: 100,
      dataIndex: 'modifyName'
    },
    {
      title: '图片状态',
      key: 'sku-17',
      render: (text, record, index) => (
        <>
          <div><label className='label'>产品主图</label><span>{ +record.isUploadImage === 1 ? '已编辑' : '未编辑' }</span></div>

          <div><label className='label'>产品详情图</label><span>{ +record.isUploadDetailImage === 1 ? '已编辑' : '未编辑' }</span></div>

          <div><label className='label'>产品PDF</label><span>{ +record.isUploadPDFImage === 1 ? '已编辑' : '未编辑' }</span></div>
        </>
      )
    },
    {
      title: '关联状态',
      key: 'sku-18',
      width: 130,
      render: (text, record, index) => (
        <>
          <div>
            <label className={'label'} style={{ color: '#19be6b' }}>关联分类</label>
            <span>{+record.isEditClassification > 0 ? '已编辑' : '未编辑'}</span>
          </div>
          <div>
            <label className={'label'} style={{ color: '#2d8cf0' }}>关联系列</label>
            <span>{+record.isEditSeries > 0 ? '已编辑' : '未编辑'}</span>
          </div>
        </>
      )
    },
    {
      title: '操作',
      key: 'sku-19',
      width: 180,
      fixed: 'right',
      render: (text, record, index) => {
        const { buttonMaps } = this.props
        return (
          <>
            {
              buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <Button type='link' size='small' onClick={() => this.showSkuBaseModal(2, record)}>编辑型号</Button>
                : null
            }

            <Button type='link' size='small' onClick={() => this.showuplaodimgModal(record)} >上传图片</Button>
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getProductCategoryList()
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    this.getSkuList()
  }

  // 获取型号列表
  getSkuList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSkuList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取系列列表
  getSeriesListByBrandId = (val) => {
    Api.getSeriesList({ brandId: val, pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.list && data.list.length > 0) {
        let { searchData } = this.state
        searchData[1].options = $lodash.concat([{ id: '', name: '全部' }], data.list)

        this.setState({
          searchData: searchData
        }, () => {
          this.props.form.setFieldsValue({ seriesId: undefined })
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取分类列表
  getProductCategoryList = async () => {
    const res = await Api.getProductCategoryList({})
    const { code, data } = res
    if (+code === 10000) {
      const { searchData = [] } = this.state
      searchData[2].options = $lodash.concat([{ id: -1, name: '全部' }], data)

      this.setState({
        searchData: searchData
      })
    }
  }

  // 删除型号
  saveDeleteSku = (params) => {
    Api.saveDeleteSku(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getSkuList()
      }
    })
  }

  // 新增 保存型号
  saveAddSku = (params) => {
    Api.saveAddSku(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getSkuList()
        this.closeModal()
      }
    })
  }

  // 编辑 保存型号
  saveSku = (params) => {
    Api.saveSku(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getSkuList()
        this.closeModal()
      }
    })
  }

  // 保存关联系列
  saveBindSeries = (params) => {
    Api.saveSkuBindToSeries(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getSkuList()
        this.closeModal()
      }
    })
  }

  // 保存关联分类
  saveBindCategory = (params) => {
    Api.saveSkuBindToCategory(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getSkuList()
        this.closeModal()
      }
    })
  }

  // 获取SKU图片详情
  getProductSkuImg = (params) => {
    return Api.getProductSkuImg(params)
  }

  // 保存图片设置
  updateProductBatchSkuImgs = async (params) => {
    const res = await Api.updateProductBatchSkuImgs(params)
    const { code } = res
    if (+code === 10000) {
      message.success('保存成功')
      this.closeModal()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { itemNo, classificationIds, createTimes, modifyTimes } = search

    if (itemNo && $lodash.trim(itemNo) !== '') {
      let num = repLineBreak($lodash.trim(itemNo), '<br>')
      search['itemNo'] = num
    }

    if (classificationIds) {
      search.classificationId = classificationIds.length === 3 ? classificationIds[2] : -1
    }

    if (createTimes) {
      search.createdBeginDate = createTimes.length === 2 ? moment(createTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.createdEndDate = createTimes.length === 2 ? moment(createTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    if (modifyTimes) {
      search.modifyBeginDate = modifyTimes.length === 2 ? moment(modifyTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.modifyEndDate = modifyTimes.length === 2 ? moment(modifyTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    this.setState({
      query: {
        ...search
      },
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => this.getSkuList())
  }

  // 点击保存型号
  handleSaveSku = (params) => {
    const { type = 1 } = this.state
    if (type === 2) {
      this.saveSku(params)
    } else {
      this.saveAddSku(params)
    }
  }

  // 点击上传图片
  showuplaodimgModal =(record) => {
    this.setState({
      rowDetail: record || {},
      uploadSkuImagemodal: true
    })
  }

  // 编辑型号弹窗
  showSkuBaseModal = (type, row = {}) => {
    this.setState({
      type: type,
      rowDetail: row || {},
      skuBaseModal: true
    })
  }

  // 点击删除
  handleDeleteSku = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.saveDeleteSku({ id: record.id })
      }
    })
  }

  // 点击查看导入详情
  jumpToDisputeSkus = () => {
    this.props.history.push({ pathname: '/admin/disputeSkus' })
  }

  // 点击下载模板
  handleDownloadTpl = () => {
    downloadUploadProductTemplate({})
  }

  // 上传限制大小
  beforeUpload = (file) => {
    const isLt300K = file.size / 1024 < 300
    if (!isLt300K) {
      message.error('文件大小不可超过300K!')
    }

    return isLt300K
  }

  // 打开批量关联图片
  showImgModal = () => {
    this.props.history.push({ pathname: '/admin/bindSkuImages', state: { imageSource: 'NEW_IMAGES' } })
  }

  uploadChange = (info) => {
    if (info.file.status === 'done') {
      const { code } = info.file.response
      if (+code === 10000) {
        message.success(`${info.file.name}上传成功`)
        this.getSkuList()
      } else {
        message.error(info.file.response.message)
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击添加到系列&分类
  showAddToModal = (code) => {
    const { selectedkeys = [] } = this.state

    if (selectedkeys.length === 0) {
      return message.warning('请选择要添加的产品')
    }

    this.setState({
      addToCode: code,
      rowDetail: selectedkeys,
      addToModal: true
    })
  }

  // 确定添加至系列、分类
  handleConfirmAddTo = (params, addToCode) => {
    if (addToCode === 'TO_CATEGORY') {
      this.saveBindCategory(params)
    } else {
      this.saveBindSeries(params)
    }
  }

  // 勾选产品
  handleChangeSelectSku = (keys) => {
    this.setState({
      selectedkeys: keys
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSkuList()
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      skuBaseModal: false,
      addToModal: false,
      uploadSkuImagemodal: false,
      selectedkeys: [],
      type: 1,
      rowDetail: {}
    })
  }

  render () {
    const { buttonMaps } = this.props

    const { tableData, pages, searchData, type = 1, rowDetail = {}, selectedkeys = [], skuBaseModal = false,
      addToModal = false, addToCode = 'TO_SERIES', uploadSkuImagemodal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedkeys,
      onChange: this.handleChangeSelectSku
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row gutter={24} style={{ marginBottom: '12px' }}>
            <Col span={18}>
              {
                buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                  ? <Button type='primary' onClick={() => this.showSkuBaseModal(1, {})}><Icon type='plus' />添加型号</Button>
                  : null
              }

              <Button type='primary' onClick={() => { this.showImgModal() }} style={{ marginLeft: '10px' }}>批量关联图片</Button>

              {
                buttonMaps && buttonMaps['toseries'] && +buttonMaps['toseries'].auth === 1
                  ? <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.showAddToModal('TO_SERIES')}>添加到系列</Button>
                  : null
              }

              {
                buttonMaps && buttonMaps['tocate'] && +buttonMaps['tocate'].auth === 1
                  ? <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.showAddToModal('TO_CATEGORY')}>添加到分类</Button>
                  : null
              }

              {
                buttonMaps && buttonMaps['upload'] && +buttonMaps['upload'].auth === 1
                  ? <Upload name='file' showUploadList={false}
                    headers={{ Token: getStore('token') }}
                    action={uploadSkuUrl}
                    accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    onChange={this.uploadChange}
                  >
                    <Button type='primary' style={{ marginLeft: '10px' }}>
                      <Icon type='upload' />批量导入
                    </Button>
                  </Upload>
                  : null
              }

              {
                buttonMaps && buttonMaps['upload'] && +buttonMaps['upload'].auth === 1
                  ? <Button style={{ marginLeft: '10px' }} onClick={this.jumpToDisputeSkus}>导入详情</Button>
                  : null
              }

              <Button style={{ marginLeft: '10px' }} onClick={() => this.handleDownloadTpl()}>
                <Icon type='download' />下载模板
              </Button>
            </Col>

            <Col span={6}>
              <p style={{ paddingRight: '12px', lineHeight: '32px', textAlign: 'right' }}>共 {+pages.total} 条</p>
            </Col>
          </Row>

          <Table rowKey='commoditySkuId' bordered
            columns={this.columns}
            dataSource={tableData}
            rowSelection={rowSelection}
            pagination={pagination}
            scroll={{ x: 3000 }}
          />
        </Card>

        {
          skuBaseModal
            ? <SkuBaseModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleSaveSku(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          addToModal
            ? <AddToModal
              addToCode={addToCode}
              detailInfo={rowDetail}
              onConfirm={(params, code) => this.handleConfirmAddTo(params, code)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          uploadSkuImagemodal
            ? <UploadSkuImageModal
              history={this.props.history}
              detailInfo={rowDetail}
              getImageDetails={(params) => this.getProductSkuImg(params)}
              onConfirm={(params) => this.updateProductBatchSkuImgs(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

const SpeciList = Form.create()(SpeciListForm)

export default connect(mapStateToProps)(SpeciList)
