import React, { Component } from 'react'
import { Card, Button, Table, Modal, message, Icon } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import RecordDetailsModal from './recordDetailsModal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import SAASCourierJson from '@/assets/json/saasCourier.json'
import { renderAddressLabel, getCourierName } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const { confirm, warning } = Modal
const { pageSizeOptions } = filter

// 快递状态
const expressStatusOptions = [
  { id: 'HAS_SEND', name: '已发货' },
  { id: 'CANCEL_SEND', name: '已取消' },
  { id: 'SIGNED', name: '已签收' }
]

export default class ExpressRecords extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
  }
  state = {
    searchData: [
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '发货日期'
        },
        cptParams: {

        }
      },
      {
        name: 'senderName',
        formType: 'Input',
        itemParams: {
          label: '发货人'
        },
        cptParams: {
          placeholder: '请输入发货人...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...expressStatusOptions
        ]
      },
      {
        name: 'customerName',
        formType: 'Input',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        }
      },
      {
        name: 'courierCode',
        formType: 'Select',
        itemParams: {
          label: '快递公司'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { aliasCode: 'ALL', aliasName: '全部' },
          ...SAASCourierJson
        ],
        optionValue: ['aliasCode', 'aliasName']
      },
      {
        name: 'logisticsOrderNo',
        formType: 'Input',
        itemParams: {
          label: '运单号'
        },
        cptParams: {
          placeholder: '请输入运单号...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorOrderNo',
        formType: 'Input',
        itemParams: {
          label: '出库单号'
        },
        cptParams: {
          placeholder: '请输入出库单号...',
          autoComplete: 'off'
        }
      },
      {
        name: 'createUserId',
        formType: 'Select',
        itemParams: {
          label: '操作人'
        },
        cptParams: {
          placeholder: '请选择',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    detailModal: false,
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'expressRecordsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '出库单号',
      key: 'expressRecordsCol-1',
      dataIndex: 'distributorOrderNo'
    },
    {
      title: '发货日期',
      key: 'expressRecordsCol-2',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '快递公司',
      key: 'expressRecordsCol-3',
      width: 100,
      dataIndex: 'courierCode',
      render: (text, record, index) => (
        <>{ getCourierName(record.courierCode) }</>
      )
    },
    {
      title: '运单号',
      key: 'expressRecordsCol-4',
      width: 120,
      dataIndex: 'logisticsOrderNo'
    },
    {
      title: '发货人',
      key: 'expressRecordsCol-5',
      width: 100,
      dataIndex: 'senderName'
    },
    {
      title: '发货电话',
      key: 'expressRecordsCol-6',
      width: 120,
      dataIndex: 'senderMobile'
    },
    {
      title: '发货地址',
      key: 'expressRecordsCol-7',
      render: (text, record, index) => (
        <>{ renderAddressLabel(record.senderProvince, record.senderCity, record.senderArea) }{record.senderAddress}</>
      )
    },
    {
      title: '客户名称',
      key: 'expressRecordsCol-8',
      dataIndex: 'customerName'
    },
    {
      title: '收货人',
      key: 'expressRecordsCol-9',
      width: 100,
      dataIndex: 'receiverName'
    },
    {
      title: '收货电话',
      key: 'expressRecordsCol-10',
      width: 120,
      dataIndex: 'receiverMobile'
    },
    {
      title: '收货地址',
      key: 'expressRecordsCol-11',
      render: (text, record, index) => (
        <>{ renderAddressLabel(record.receiverProvince, record.receiverCity, record.receiverArea) }{record.receiverAddress}</>
      )
    },
    {
      title: '发货状态',
      key: 'expressRecordsCol-12',
      width: 100,
      render: (text, record, index) => (
        <>{ this.renderExpressStatusLabel(record.status) }</>
      )
    },
    {
      title: '操作人',
      key: 'expressRecordsCol-13',
      width: 100,
      dataIndex: 'createName'
    },
    {
      title: '操作',
      key: 'expressRecordsCol-14',
      width: 180,
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleShowDetailModal(record)}>查看详情</Button>

          {
            record.status === 'HAS_SEND'
              ? <Button type='link' size='small' onClick={() => this.handleCancelExpress(record)}>取消快递</Button>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getExpressRecordsList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取操作人全部成员
  getMemberList = (val = '') => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.list && data.list.length > 0) {
        let { searchData } = this.state

        searchData[7].options = $lodash.concat([{ id: -1, userName: '全部' }], data.list)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取快递记录列表
  getExpressRecordsList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getExpressRecordsList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 快递服务-查询快递是否可取消
  getServiceCanCancelExpress = async (params) => {
    const res = await Api.getServiceCanCancelExpress(params)
    const { code, data } = res
    if (+code === 10000) {
      if (!data) {
        warning({
          title: <><label className={`${styles.label}`}>揽件情况为</label><span>已揽件</span></>,
          content: <>很抱歉，此单快递不可以取消，请联系IIASaaS客服反馈问题</>
        })

        return false
      }

      confirm({
        title: <><label className={`${styles.label}`}>揽件情况为</label><span>未揽件</span></>,
        content: <>可以进行取消操作，是否要取消快递？</>,
        onOk: () => {
          this.cancelGoodsOutExpress(params)
        }
      })
    }
  }

  // 快递服务-取消快递
  cancelGoodsOutExpress = async (params) => {
    const res = await Api.cancelGoodsOutExpress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.getExpressRecordsList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times && search.times.length === 2) {
      search.beginDate = moment(search.times[0]).format('YYYY-MM-DD HH:mm:ss')
      search.endDate = moment(search.times[1]).format('YYYY-MM-DD HH:mm:ss')
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getExpressRecordsList()
    })
  }

  // 点击导出记录
  handleDownloadExpressRecords = () => {
    const { query = {} } = this.state

    if (!query.beginDate) {
      return message.warning('请选择要导出订单的时间段！')
    }

    DownloadApi.downloadExpressServiceRecords(query)
  }

  // 点击查看详情
  handleShowDetailModal = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record
    })
  }

  // 确定修改拣货单备注
  setRowDetailRemarks = (outboundRemarks) => {
    const { tableData = [], rowDetail = {} } = this.state
    const i = tableData.findIndex((row) => { return row.id === rowDetail.id })

    tableData[i].remarks = outboundRemarks

    this.setState({
      tableData: [
        ...tableData
      ],
      rowDetail: {
        ...rowDetail,
        remarks: outboundRemarks
      }
    })
  }

  // 点击取消快递
  handleCancelExpress = (record) => {
    const params = {
      id: record.id,
      courierCode: record.courierCode,
      logisticsOrderId: record.logisticsOrderId,
      logisticsOrderNo: record.logisticsOrderNo
    }

    this.getServiceCanCancelExpress(params)
  }

  // 渲染发货状态
  renderExpressStatusLabel = (status) => {
    const obj = expressStatusOptions.find((o) => { return o.id === status })
    const str = obj !== undefined ? obj.name : '----'

    return str
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getExpressRecordsList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getExpressRecordsList()
    })
  }

  render () {
    const { searchData, tableData = [], pages, detailModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <div style={{ marginTop: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <div style={{ marginBottom: '12px' }}>
              <Button type='primary' onClick={() => this.handleDownloadExpressRecords()}><Icon type='download' />导出记录</Button>
              <span style={{ marginLeft: '8px', color: '#666666', fontSize: '12px' }}>温馨提示：导出前请先选择想导出的订单日期，点击搜索后再点击导出订单。</span>
            </div>

            <Table rowKey='id' bordered
              dataSource={tableData}
              columns={this.columns}
              pagination={pagination}
              scroll={{ x: 1900 }}
            />
          </Card>
        </div>

        {
          detailModal
            ? <RecordDetailsModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirmOutboundRemarks={(outboundRemarks) => this.setRowDetailRemarks(outboundRemarks)}
            />
            : null
        }
      </>
    )
  }
}
