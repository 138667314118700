import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber } from 'antd'

const FormItem = Form.Item
const { confirm } = Modal

class CollectForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        values['billId'] = detailInfo.id
        values['oldPayAmount'] = detailInfo.payAmount

        confirm({
          title: '确认收款？',
          content: '',
          onOk: () => {
            this.props.onConfirm(values)
          }
        })
      }
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 12 }
    }

    return (
      <div>
        <Modal title='确认收款' visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <FormItem label='收款金额' {...formItemLayout}>
              {
                getFieldDecorator('addPayAmount', {
                  initialValue: ((detailInfo.totalAmount * 100 - detailInfo.payAmount * 100) / 100).toFixed(2),
                  rules: [
                    { required: true, message: '请填写金额' }
                  ]
                })(<InputNumber autoComplete='off' style={{ width: '120px' }} />)
              }
              <span style={{ marginLeft: '5px' }}>元</span>
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const CollectModal = Form.create()(CollectForm)

export default CollectModal
