import React, { Component } from 'react'

import { Modal, Row, Form, Input } from 'antd'
import PropTypes from 'prop-types'

const FormItem = Form.Item

class AddModel extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    form: PropTypes.object,
    addsuccess: PropTypes.func
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.addsuccess(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        <Modal title='添加联系人' visible
          onOk={() => { this.handleConfirm() }}
          onCancel={this.props.closeModal}
        >
          <Row>
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
              <FormItem label='联系人名称'>
                {
                  getFieldDecorator('name', {
                    rules: [
                      { required: true, message: '请输入联系人名称' }
                    ]
                  })(
                    <Input autoComplete='off' />
                  )
                }
              </FormItem>

              <FormItem label='联系人方式'>
                {
                  getFieldDecorator('phoneNumber', {
                    rules: [
                      { required: true, message: '请输入联系人方式' }
                    ]
                  })(
                    <Input autoComplete='off' />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const ModeModal = Form.create()(AddModel)

export default ModeModal
