import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Modal, message } from 'antd'
import $lodash from 'lodash'

import PreviewTable from './previewTable'
import Api from '@/common/api/index'

const matchingStatusMap = [
  { id: 'MATCH_SUCCESS', name: '成功', color: '#52C41A' },
  { id: 'MATCH_FAILED', name: '失败', color: '#F5222D' },
  { id: 'MATCHING', name: '待询价', color: '#FAAD14' }
]

class PrepareQuotedDetails extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    predictQuotationOrderId: null,
    detalisList: [],
    resultList: [],
    successNum: 0,
    failNum: 0,
    unMatchNum: 0,
    customerMobile: null,
    selectedRowKeys: [],
    selectedRows: []
  }
  columns = [
    {
      title: '序号',
      key: 'quotedTableCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'quotedTableCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号/订货号',
      key: 'quotedTableCol-2',
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'quotedTableCol-3',
      dataIndex: 'quantity'
    },
    {
      title: '价格',
      key: 'quotedTableCol-4',
      dataIndex: 'price'
    },
    {
      title: '报价结果',
      key: 'quotedTableCol-5',
      dataIndex: 'matchingStatus',
      render: (text, record, index) => (
        <>{ this.renderStatusLabel(record.matchingStatus) }</>
      )
    },
    {
      title: '备注',
      key: 'quotedTableCol-6',
      dataIndex: 'remarks'
    }
  ]

  componentDidMount () {
    const { state } = this.props.history.location
    this.setState({
      predictQuotationOrderId: state.predictQuotationOrderId
    }, () => {
      this.getPrepareQuotationsDetails()
    })
  }

  // 获取预报价单详情
  getPrepareQuotationsDetails = async () => {
    const { predictQuotationOrderId } = this.state
    const res = await Api.getPrepareQuotationsDetails({ predictQuotationOrderId: predictQuotationOrderId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        detailList: data.detailList || [],
        resultList: data.resultList || [],
        successNum: data.successNum || 0,
        failNum: data.failedNum || 0,
        unMatchNum: data.unMatchNum || 0,
        customerMobile: data.customerMobile || null
      })
    }
  }

  // 保存新增询价单
  saveAddEnquireOrder = async (params) => {
    const res = await Api.saveAddEnquireOrder(params)
    const { code } = res
    if (+code === 10000) {
      this.countDown()
    }
  }

  // 点击一键询价
  handleEnquiry = () => {
    const { predictQuotationOrderId, selectedRows = [] } = this.state
    if (selectedRows.length === 0) {
      return message.warning('请选择要询价的产品~~')
    }

    const newdatas = $lodash.map(selectedRows, (record) => {
      return {
        ...record,
        expectDeliveryPeriodDays: record.deliveryPeriodDays,
        expectPrice: record.price,
        matchingState: 'MATCHED',
        dataType: 'SEARCH'
      }
    })

    const params = {
      predictQuotationOrderId: predictQuotationOrderId,
      enquiryOrderDetails: newdatas,
      enquiryType: 'PLATFORM',
      enquiryState: 'NO',
      quotationNumber: 1
    }

    this.saveAddEnquireOrder(params)
  }

  // 监听列表选择
  handleSelectedChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys,
      selectedRows: rows
    })
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '操作成功',
      content: <>
        <div>请到我的询价中查看具体进度</div>
        <div>{secondsToGo}s后将返回列表页</div>
      </>,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.props.history.push({ pathname: '/admin/prepareQuotaions' })
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: <>
          <div>请到我的询价中查看具体进度</div>
          <div>{secondsToGo}s后将返回列表页</div>
        </>
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.props.history.push({ pathname: '/admin/prepareQuotaions' })
    }, secondsToGo * 1000)
  }

  renderStatusLabel = (status) => {
    const obj = matchingStatusMap.find((item) => { return item.id === status })
    const label = obj !== undefined ? <span style={{ color: obj.color }}>{ obj.name }</span> : null

    return label
  }

  render () {
    const { detailList = [], resultList = [] } = this.state

    // const rowSelection = {
    //   columnWidth: 44,
    //   selectedRowKeys: selectedRowKeys,
    //   onChange: this.handleSelectedChange,
    //   getCheckboxProps: record => ({
    //     disabled: record.matchingStatus === 'MATCH_FAILED'
    //   })
    // }

    return (
      <>
        <Card bodyStyle={{ padding: '0px 12px 12px 12px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Card title='预报价单预览' headStyle={{ paddingLeft: '0px', paddingRight: '0px', borderBottom: '0px' }} bodyStyle={{ padding: '0px' }} bordered={false}>
              <PreviewTable list={detailList} />
            </Card>
          </div>

          <Card title='报价结果' headStyle={{ paddingLeft: '0px', paddingRight: '0px', borderBottom: '0px' }} bodyStyle={{ padding: '0px' }} bordered={false}>
            {/* <div style={{ marginBottom: '15px' }}>
              <Alert type='info'
                message={<>总计为您报价成功&nbsp;100&nbsp;个商品，报价失败&nbsp;2&nbsp;个商品，请检查型号/订货号数据是否正确。待询价&nbsp;10&nbsp;个商品，请联系您的专属客服或拨打15921600298获取更多报价。</>}
              />
              <Button type='primary' style={{ marginTop: '15px' }} onClick={() => this.handleEnquiry()}>一键询价</Button>
            </div> */}

            <Table rowKey='id' bordered
              columns={this.columns}
              dataSource={resultList}
              // rowSelection={rowSelection}
              pagination={false}
            />
          </Card>
        </Card>
      </>
    )
  }
}

export default PrepareQuotedDetails
