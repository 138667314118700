import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Descriptions, Input } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import filter from '@/common/utils/filter'

const FormItem = Form.Item

const { paywordReg } = filter

class PaywordForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    info: {}
  }

  componentDidMount () {
    const { getDetail, detailInfo } = this.props

    let params = {
      purchaseOrderId: detailInfo.purchaseOrderId
    }

    getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  handleConfirm = () => {
    const { detailInfo } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { payPassword } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['payPassword'] = jsencrypt.encrypt(payPassword)

        values.purchaseOrderId = detailInfo.purchaseOrderId
        values.realPaymentAmount = detailInfo.realPaymentAmount

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { detailInfo } = this.props
    const { info } = this.state
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Modal title='信用支付' width={800} bodyStyle={{ padding: '12px' }}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Row>
            <Descriptions>
              <Descriptions.Item label='应付金额'>
                &yen;{(+detailInfo.realPaymentAmount * 100 / 100).toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label='RS供应商'>
                {detailInfo.merchantName}
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <Row>
            <Descriptions>
              <Descriptions.Item label='信用总额度'>
                &yen;{(+info.maxAmount * 100 / 100).toFixed(2) }
              </Descriptions.Item>
              <Descriptions.Item label='使用额度'>
               &yen;{(+info.usedAmount * 100 / 100).toFixed(2) }
              </Descriptions.Item>
              <Descriptions.Item label='剩余额度'>
               &yen;{(+info.remainAmount * 100 / 100).toFixed(2) }
              </Descriptions.Item>

              <Descriptions.Item label='账期'>
                {info.period}天
              </Descriptions.Item>

              <Descriptions.Item label='逾期状态'>
                {info.overdue === 'OVERDUE_NO' ? '未逾期' : '已逾期'}
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <Row>
            <Form>
              <FormItem label='支付密码'>
                {
                  getFieldDecorator('payPassword', {
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !paywordReg.test(value)) {
                                /* eslint-disable */
                                    callback('支付密码格式不正确')
                                  /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                      }
                    ]
                  })(
                    <Input.Password style={{ width: '200px' }}></Input.Password>
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const PaywordModal = Form.create()(PaywordForm)

export default PaywordModal
