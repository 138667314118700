import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class CategoryForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    data: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  componentDidMount () {

  }

  // 点击确认
  handleConfirm = () => {
    const { form, type, data, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        if (+type === 2) {
          values.id = data.id
        }

        values.parentId = data.parentId || ''

        onConfirm(values)
      }
    })
  }

  render () {
    const { form, type, data = {}, onCancel } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    return (
      <>
        <Modal title={+type === 1 ? '添加分类' : '编辑分类'}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={() => onCancel()}
        >
          <Form>
            {
              data.parentName && data.parentName !== ''
                ? <FormItem label='父类名称' {...formItemLayout}>
                  {
                    getFieldDecorator('parentName', {
                      initialValue: data.parentName || ''
                    })(
                      <Input disabled />
                    )
                  }
                </FormItem> : null
            }
            <FormItem label='分类名称' {...formItemLayout}>
              {
                getFieldDecorator('name', {
                  initialValue: data.name || '',
                  rules: [
                    {
                      required: true,
                      message: '请输入分类名称!'
                    }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='iiaCode' {...formItemLayout}>
              {
                getFieldDecorator('iiaCode', {
                  initialValue: data.iiaCode || '',
                  rules: [
                    { required: true, message: '请填写iiaCode' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const CategoryModal = Form.create()(CategoryForm)

export default CategoryModal
