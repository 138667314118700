import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import AddressParse from '@/common/addressParse/index'

import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const { Parse, Utils } = AddressParse

class DiscernAddressForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onConfirm: PropTypes.func
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const [result] = Parse.parse(values.keyword, true)

        // 传入第三个参数返回code父级
        const cityValues = Utils.getTargetAreaListByCode('province', result.code, true)
        const citys = [cityValues[0].code, cityValues[1].code, cityValues[2].code]

        const params = {
          name: result.name,
          mobile: result.mobile,
          address: result.details,
          province: citys[0],
          city: citys[1],
          county: citys[2]
        }

        this.props.onConfirm(params)
      }
    })
  }

  // 清空
  handleClear = () => {
    this.props.form.resetFields()
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div className={styles.main}>
        <Form>
          <h5 className={`${styles.title}`}>地址智能填写</h5>

          <div>
            <FormItem>
              {
                getFieldDecorator('keyword', {
                  rules: [
                    { required: true, message: '请填写要识别的地址' }
                  ]
                })(
                  <TextArea rows={4} autoComplete={'off'}
                    placeholder='粘贴整段地址，自动识别姓名、电话和地址 例如：IIASaaS，13100000000，上海市闵行区某某街道某某大厦001号'
                  />
                )
              }
            </FormItem>
            <div className={styles.btns}><a onClick={() => { this.handleClear() }}>清空</a><a onClick={() => { this.handleConfirm() }}>识别</a></div>
          </div>
        </Form>
      </div>
    )
  }
}

const DiscernAddress = Form.create()(DiscernAddressForm)

export default DiscernAddress
