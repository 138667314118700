/* 成员管理页面 */
import React, { Component } from 'react'
import { Card, Table, Button, message } from 'antd'

import DepartmentModal from '../departmentModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class MemberList extends Component {
  static propTypes = {

  }
  state = {
    searchData: [
      {
        name: 'account',
        formType: 'Input',
        itemParams: {
          label: '登录名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'realName',
        formType: 'Input',
        itemParams: {
          label: '员工姓名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    departmentModal: false,
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'memberCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '登录名',
      key: 'memberCol-1',
      dataIndex: 'loginName'
    },
    {
      title: '真实姓名',
      key: 'memberCol-2',
      dataIndex: 'realName'
    },
    {
      title: '统计所属部门',
      key: 'memberCol-4',
      dataIndex: 'departName'
    },
    {
      title: '操作',
      key: 'memberCol-5',
      width: 220,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showDepartmentModal(record)}>分配部门</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getPurposeMemberList()
  }

  // 获取统计员工列表
  getPurposeMemberList = async () => {
    const { pages } = this.state
    const res = await Api.getPurposeMemberList({ pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存统计员工部门分配
  savePurposeSetToDepartment = async (params) => {
    const res = await Api.savePurposeSetToDepartment(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getPurposeMemberList()
    }
  }

  showDepartmentModal = (record) => {
    this.setState({
      rowDetail: record,
      departmentModal: true
    })
  }

  closeModal = () => {
    this.setState({
      rowDetail: {},
      departmentModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurposeMemberList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurposeMemberList()
    })
  }

  render () {
    const { tableData = [], pages, departmentModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Table rowKey='id' bordered
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
            />
          </Card>
        </div>

        {
          departmentModal
            ? <DepartmentModal
              detailInfo={rowDetail}
              onConfirm={(values) => this.savePurposeSetToDepartment(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default MemberList
