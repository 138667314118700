import Ajax from '../utils/ajax'

export default {
  // 云采购售后- 获取云采购订单售后信息
  getPurchaseOrderSaledApplyInfo (params) {
    return Ajax.post('/platform/afterSale/order/applyDetail', params)
  },

  // 云采购售后- 保存云采购订单售后申请
  saveSaledApplyByPurchaseOrder (params) {
    return Ajax.post('/platform/afterSale/order/saveApply', params)
  },

  // 云采购售后- 获取云采购订单售后申请列表
  getPurchaseOrderSaledList (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/page/list', params)
  },

  // 云采购售后- 获取云采购订单售后详情
  getPurchaseOrderSaledDetails (params) {
    return Ajax.get('/platform/cloud/purchase/aftersale/detail', { params })
  },

  // 云采购售后- 云采购售后同意申请
  savePurchaseOrderSaledApplyApproval (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/accept/apply', params)
  },

  // 云采购售后- 云采购售后拒绝售后申请
  savePurchaseOrderSaledApplyRefuse (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/reject/apply', params)
  },

  // 云采购售后- 云采购售后订单上门取件
  savePurchaseOrderSaledPickUp (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/pick/up', params)
  },

  // 云采购售后- 云采购售后审核通过
  savePurchaseOrderSaledToExamineAudit (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/audit', params)
  },

  // 云采购售后- 云采购售后审批驳回
  savePurchaseOrderSaledToExamineFail (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/audit/fail', params)
  },

  // 云采购售后- 云采购售后换货、补寄发货
  savePurchaseOrderSaledSendingDeliver (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/deliver', params)
  },

  // 云采购售后- 云采购售后退款
  savePurchaseOrderSaledRefund (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/refund', params)
  },

  // 云采购售后- 保存云采购售后修改换货、补寄收货地址
  savePurchaseOrderSaledReceiveAddress (params) {
    return Ajax.post('/platform/cloud/purchase/aftersale/modify/address', params)
  },

  // 云采购售后- 获取云采购售后进度详情
  getPurchaseOrderSaledProgressDetails (params) {
    return Ajax.get('/platform/cloud/purchase/aftersale/get/schedule', { params })
  },

  // 云供应售后- 获取云供应售后申请列表
  getSupplyOrderSaledApplyList (params) {
    return Ajax.post('/platform/afterSale/order/listPage', params)
  },

  // 云供应售后- 获取云供应售后申请详情
  getSupplyOrderSaledDetails (params) {
    return Ajax.post('/platform/afterSale/order/getDetail', params)
  }
}
