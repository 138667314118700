import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import LogisticsTrace from '@/components/logisticsTrace/index'

import Api from '@/common/api/index'

import styles from './index.module.scss'

// 订单详情-物流轨迹详情
class OrderLogisticsTrail extends Component {
  static propTypes = {
    ident: PropTypes.string,
    detailInfo: PropTypes.object,
    getDetails: PropTypes.func
  }
  state = {
    courierNumberList: [], // 运单号列表
    activeCourierdatas: {}, // 选中的运单号信息
    logisticsTraces: [] // 物流轨迹信息
  }

  componentDidMount () {
    this.getOrderLogisticsTrackingNumbers()
  }

  // 获取物流运单号
  getOrderLogisticsTrackingNumbers = async () => {
    const { ident, detailInfo = {}, getDetails } = this.props
    let params = {
      pageNo: 1,
      pageSize: 999
    }

    if (ident === 'SUPPLY_ORDER') {
      params.orderId = detailInfo.orderId
    }
    if (ident === 'PURCHASE_ORDER') {
      params.purchaseOrderId = detailInfo.purchaseOrderId
      params.distributorId = detailInfo.distributorId
    }

    const res = await getDetails(params)
    const { code, data = {} } = res
    if (+code === 10000) {
      const { courierNumberInfo = [] } = data
      if (courierNumberInfo && courierNumberInfo.length > 0) {
        const defaultValues = courierNumberInfo[0]
        this.setState({
          courierNumberList: courierNumberInfo
        }, () => {
          this.handleSelectedCourierNumber(`${defaultValues.courierCode}-${defaultValues.courierNumber}`)
        })
      }
    }
  }

  // 获取物流轨迹
  getLogisticsTraceByMailNo = async (courierCode, courierNumber, mobile) => {
    const params = {
      ShipperCode: courierCode,
      LogisticCode: courierNumber,
      CustomerName: mobile && mobile !== '' ? mobile.substr(-4) : ''
    }
    const res = await Api.getLogisticsTraceByMailNo(params)
    const { code, data } = res
    if (+code === 10000) {
      const list = data.traces || []

      this.setState({
        logisticsTraces: list.length > 0 ? list.reverse() : []
      })
    }
  }

  // 点击选择运单号
  handleSelectedCourierNumber = (value) => {
    const { courierNumberList = [] } = this.state
    const courierArr = $lodash.split(value, '-')
    const obj = courierNumberList.find((o) => { return o.courierCode === courierArr[0] && o.courierNumber === courierArr[1] })

    this.setState({
      activeCourierdatas: {
        courierCode: courierArr[0],
        courierNumber: courierArr[1],
        customerName: obj !== undefined ? obj.customerName : null
      }
    }, () => {
      this.getLogisticsTraceByMailNo(courierArr[0], courierArr[1], obj !== undefined ? obj.customerName : null)
    })
  }

  render () {
    const { courierNumberList = [], activeCourierdatas = {}, logisticsTraces = [] } = this.state
    return (
      <>
        <div style={{ margiBottom: '16px' }}>
          <div className={`${styles.standardFormRow}`}>
            <div className={`label ${styles.standardFormRowLabel}`}>运单号</div>
            <div className={`${styles.standardFormRowContent}`}>
              <Radio.Group buttonStyle='solid' value={`${activeCourierdatas.courierCode}-${activeCourierdatas.courierNumber}`}
                onChange={(e) => this.handleSelectedCourierNumber(e.target.value)}
              >
                {
                  courierNumberList.map((item, i) => (
                    <Radio.Button value={`${item.courierCode}-${item.courierNumber}`} key={`${item.courierCode}-${item.courierNumber}`}>
                      {moment(item.logisticsSendTime).format('YYYY-MM-DD')}&nbsp;{item.courierNumber}
                    </Radio.Button>
                  ))
                }
              </Radio.Group>
            </div>
          </div>
        </div>

        <LogisticsTrace logisticsTraces={logisticsTraces} courierCode={activeCourierdatas.courierCode} />
      </>
    )
  }
}

export default OrderLogisticsTrail
