import React, { Component } from 'react'
import { Modal, Table } from 'antd'
import PropTypes from 'prop-types'

import Api from '@/common/api/index'

export default class GoodsNameModal extends Component {
  static propTypes = {
    onModalClose: PropTypes.func,
    rowDetails: PropTypes.object
  }
  state = {
    tableData: []
  }

  columns =[
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '产品描述',
      key: 'service-1',
      dataIndex: 'name'
    },
    {
      title: '型号',
      key: 'service-2',
      dataIndex: 'model'
    },

    {
      title: '订单号',
      key: 'service-3',
      dataIndex: 'commoditySkuId'
    },
    {
      title: '数量',
      key: 'service-4',
      dataIndex: 'batchQuantity'
    }
  ]

  componentDidMount () {
    this.getInsuranceGoodslist()
  }

  // 获取保险流水单货物明细
  getInsuranceGoodslist = () => {
    let params = {
      distributorId: this.props.rowDetails.distributorId,
      orderId: this.props.rowDetails.orderId,
      pickingId: this.props.rowDetails.pickingId,
      batchNum: this.props.rowDetails.batchNum,
      pageNo: 1,
      pageSize: 999
    }

    Api.getInsuranceGoodslist(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title='货物明细' width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'scroll' }}
          visible
          onOk={this.props.onModalClose}
          onCancel={this.props.onModalClose}
        >
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Modal>
      </>
    )
  }
}
