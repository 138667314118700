import React, { Component } from 'react'
import { Row, Card, Col, Statistic, Table, Divider, Progress, Tooltip } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api'

import filter from '@/common/utils/filter'

const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')

export default class index extends Component {
  state = {
    searchData: [
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { month: 0, monthLabel: '全年' },
          ...fullsMonthOptions
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['month', 'monthLabel']
      }
    ],
    tableData: [],
    query: {},
    departMentPurchaseUserAll: 0, // 部门采购商总数
    newPurchaseUser: 0, // 新增采购商数量
    cloudPurchaseAmount: 0, // 云采购合同额
    cloudPurchaseSentOut: 0, // 云采购销售额（发货）
    kaAverageGrossMargin: 0, // 大客户平均毛利率
    normalAverageGrossMargin: 0, // 常规客商平均毛利率
    percentForNum: 0, // 进度条：新增采购商数量
    percentForAmount: 0, // 云采购销售额
    cloudPurchaseSentOutSeries: [], // 云采购销售金额（发货）Echarts
    newPurchaseUsersSeries: []// 新增成交采购商Echarts数据
  }
  columns = [
    {
      title: '序号',
      key: 'infoListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '员工名称',
      key: 'infoListCol-1',
      dataIndex: 'userName'
    },
    {
      title: '采购商总数',
      key: 'infoListCol-2',
      dataIndex: 'totalPurchaser'
    },
    {
      title: '成交采购商数量',
      key: 'infoListCol-3',
      dataIndex: 'dealPurchaserNum'
    },
    {
      title: '云采购合同金额',
      key: 'infoListCol-4',
      dataIndex: 'cloudPurchaseAmout',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云采购发货金额',
      key: 'infoListCol-5',
      dataIndex: 'cloudSendOutAmount',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '平均毛利率',
      key: 'infoListCol-6',
      dataIndex: 'averageGrossMargin',
      render: (text, record, index) => {
        return (
          <span>{parseFloat(text).toFixed(2)}%</span>
        )
      }
    }
  ]

  // 获取报表数据
  getSalesStatistics = async () => {
    const { query = {} } = this.state
    const res = await Api.getPerformanceStatistics({ type: 'performance_overview_sales_department', user_id: 9 })

    let totalPurchaseObj = res.list_department_purchase_users_all
    let newPurchaseUserObj = res.list_department_purchase_users_newly // 新增采购商数量总数据
    let cloudPurchaseAmountObj = res.list_department_cloud_purchase_amount_newly // 云采购合同额总数据
    let cloudPurchaseSentOutObj = res.list_department_cloud_purchase_amount_send_out_newly // 云采购销售额
    let KAcloudSupplySentOutObj = res.KA_department_cloud_supply_amount_send_out_newly // KA云供应销售额
    let NORcloudSupplySentOutObj = res.NOR_department_cloud_supply_amount_send_out_newly // NOR云供应销售额
    let percentForNumObj = res.list_statistics_department_targets // 目标总数据
    let _tabledata = []

    // 部门采购商总数
    let totalPurchasersData = []
    $lodash.forEach(totalPurchaseObj, (value, key) => {
      if ((value.depart_id === 1 || value.depart_id === 7)) {
        const obj = {
          month: key,
          value: value.department_purchase_users_count
        }
        totalPurchasersData.push(obj)
      }
    })
    // 新增采购商数据
    let newPurchaserdatas = []
    $lodash.forEach(newPurchaseUserObj, (value, key) => {
      if (((value.first_month.includes(YEAR) && parseInt(value.first_month.split('-')[1]) === parseInt(query.month)) || (value.first_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 1 || value.depart_id === 7)) {
        const obj = {
          month: value.first_month.split('-')[1],
          value: value.distributor_id
        }
        newPurchaserdatas.push(obj)
      }
    })

    // 云采购合同额数据
    let cloudPurchaseAmountdatas = []
    $lodash.forEach(cloudPurchaseAmountObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 1 || value.depart_id === 7)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudPurchaseAmountdatas.push(obj)
      }
    })

    // 云采购销售额数据
    let cloudPurchaseSendOutdatas = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (((value.trans_month.includes(YEAR) && parseInt(value.trans_month.split('-')[1]) === parseInt(query.month)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 1 || value.depart_id === 7)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        cloudPurchaseSendOutdatas.push(obj)
      }
    })

    // 新增采购商数量进度条数值
    let percentForNumData = 0
    $lodash.forEach(percentForNumObj, (value, key) => {
      if ((value.target_name === '新增采购商数量') && (value.depart_id === 1)) {
        percentForNumData = parseFloat((newPurchaserdatas.length / value.target_value) * 100).toFixed(2)
      }
    })

    // 云采购销售额进度条数值
    let percentForAmountData = 0
    let targetValue = 0
    $lodash.forEach(percentForNumObj, (value, key) => {
      if ((value.target_name === '云采购销售额') && (value.depart_id === 1 || value.depart_id === 7)) {
        targetValue = targetValue + value.target_value
      }
    })
    percentForAmountData = parseFloat(($lodash.sumBy(cloudPurchaseSendOutdatas, 'value') / targetValue) * 100).toFixed(2)

    // 大客户平均毛利率
    let kaAverageGrossMarginData = 0
    // 大客户云采购合同额
    let kaCloudPurchaseAmountdatas = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (((value.trans_month.includes(YEAR)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 7)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        kaCloudPurchaseAmountdatas.push(obj)
      }
    })
    // 大客户云供应合同额
    let kaCloudSupplyAmountdatas = []
    $lodash.forEach(KAcloudSupplySentOutObj, (value, key) => {
      if (((value.trans_month.includes(YEAR)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0))) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        kaCloudSupplyAmountdatas.push(obj)
      }
    })
    // 计算大客户平均毛利率
    kaAverageGrossMarginData =
    ($lodash.sumBy(kaCloudPurchaseAmountdatas, 'value')) > 0 ? (($lodash.sumBy(kaCloudPurchaseAmountdatas, 'value')) - ($lodash.sumBy(kaCloudSupplyAmountdatas, 'value'))) / ($lodash.sumBy(kaCloudPurchaseAmountdatas, 'value')) * 100 : 0
    // 常规客户平均毛利率
    let normalAverageGrossMarginData = 0
    // 常规客户云采购合同额
    let normalCloudPurchaseAmountdatas = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (((value.trans_month.includes(YEAR)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0)) && (value.depart_id === 1)) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        normalCloudPurchaseAmountdatas.push(obj)
      }
    })
    // 常规客户云供应合同额
    let normalCloudSupplyAmountdatas = []
    $lodash.forEach(NORcloudSupplySentOutObj, (value, key) => {
      if (((value.trans_month.includes(YEAR)) || (value.trans_month.includes(YEAR) && parseInt(query.month) === 0))) {
        const obj = {
          month: value.trans_month.split('-')[1],
          value: value.amount_sum
        }
        normalCloudSupplyAmountdatas.push(obj)
      }
    })
    // 计算常规客户平均毛利率
    normalAverageGrossMarginData =
    ($lodash.sumBy(normalCloudPurchaseAmountdatas, 'value')) > 0 ? (($lodash.sumBy(normalCloudPurchaseAmountdatas, 'value')) - ($lodash.sumBy(normalCloudSupplyAmountdatas, 'value'))) / ($lodash.sumBy(normalCloudPurchaseAmountdatas, 'value')) * 100 : 0
    // 新增成交采购商Echarts数据
    const newObjs = $lodash.groupBy(newPurchaseUserObj, 'first_month')
    let differObj = []
    $lodash.forEach(newObjs, (valuesArr, key) => {
      if ((key.includes(YEAR))) {
        $lodash.map(valuesArr, (value, keyarr) => {
          if ((value.depart_id === 1 || value.depart_id === 7)) {
            const obj = { month: +(key.split('-')[1]), val: valuesArr.length }
            differObj.push(obj)
          }
        })
      }
    })
    const set = new Set(differObj.map(JSON.stringify))
    const uniqueArr = Array.from(set).map(JSON.parse)
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const newPurchaseUsersEchartsdata = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(uniqueArr, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 云采购销售金额（发货）Echarts
    const newObjsForAmount = $lodash.groupBy(cloudPurchaseSentOutObj, 'trans_month')
    let _differObj = []
    $lodash.forEach(newObjsForAmount, (valuesArr, key) => {
      if ((key.includes(YEAR))) {
        $lodash.map(valuesArr, (value, keyarr) => {
          if ((value.depart_id === 1 || value.depart_id === 7)) {
            const obj = { month: +(key.split('-')[1]), val: parseFloat($lodash.sumBy(valuesArr, 'amount_sum')).toFixed(2) }
            _differObj.push(obj)
          }
        })
      }
    })
    const _set = new Set(_differObj.map(JSON.stringify))
    const _uniqueArr = Array.from(_set).map(JSON.parse)
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const cloudPurchaseSentOutSeriesData = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(_uniqueArr, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    // 设置table列表数据
    if (res.list_purchase_users_details) {
      _tabledata = this.setTableData(res.list_purchase_users_details)
    }

    this.setState({
      departMentPurchaseUserAll: totalPurchasersData.length > 0 ? $lodash.sumBy(totalPurchasersData, 'value') : 0, // 部门采购商总数
      newPurchaseUser: newPurchaserdatas.length > 0 ? newPurchaserdatas.length : 0, // 新增采购商数量
      cloudPurchaseAmount: cloudPurchaseAmountdatas.length > 0 ? $lodash.sumBy(cloudPurchaseAmountdatas, 'value') : 0, // 云采购合同额
      cloudPurchaseSentOut: cloudPurchaseSendOutdatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutdatas, 'value') : 0, // 云采购销售额（发货）
      kaAverageGrossMargin: kaAverageGrossMarginData > 0 ? kaAverageGrossMarginData : 0, // 大客户平均毛利率
      normalAverageGrossMargin: normalAverageGrossMarginData > 0 ? normalAverageGrossMarginData : 0, // 常规客商平均毛利率
      percentForNum: percentForNumData, // 进度条：新增采购商数量
      percentForAmount: percentForAmountData, // 云采购销售额
      tableData: _tabledata, // 交易明细
      cloudPurchaseSentOutSeries: $lodash.sortBy((cloudPurchaseSentOutSeriesData), function (item) { return item[0] }), // 云采购销售金额（发货）Echarts
      newPurchaseUsersSeries: newPurchaseUsersEchartsdata// 新增成交采购商Echarts数据
    }, () => {
      this.initECharts()
    })
  }

  // 初始化图表
  initECharts = () => {
    const { cloudPurchaseSentOutSeries, newPurchaseUsersSeries } = this.state
    var myChart = echarts.init(document.getElementById('salesStatisticsEcharts'))
    // 绘制图表
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${YEAR}销售业绩总览折线图`
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['云采购销售金额（发货）', '新增成交采购商数量']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisLabel: {
          formatter: `{value}月`
        }
      },
      yAxis: [ {
        type: 'value',
        name: '金额',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} ￥'
        }
      },
      {
        type: 'value',
        name: '数量',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} 家'
        }
      }],
      series: [
        {
          name: '云采购销售金额（发货）',
          type: 'line',
          yAxisIndex: 0,
          itemStyle: { normal: { label: { show: true } } },
          data: cloudPurchaseSentOutSeries
        },
        {
          name: '新增成交采购商数量',
          type: 'line',
          yAxisIndex: 1,
          itemStyle: { normal: { label: { show: true } } },
          data: newPurchaseUsersSeries
        }
      ]
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search
    }, () => {
      this.getSalesStatistics()
    })
  }

  // 设置table列表数据结构
  setTableData = (origindatas) => {
    const { query = {} } = this.state
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云采购合同金额table数据
      let cloudPurchaseTabledatas = []
      // 云采购销售金额table数据
      let cloudPurchaseSendOutTabledatas = []
      // 成交采购商数量table数据
      let dealPurchaseNumData = []
      // 云供应金额table数据
      let cloudSupplyData = []
      // 平均毛利率
      let averageGrossMargindata = 0
      if (values.depart_id === 7 || values.depart_id === 1) {
        // 云采购合同金额table数据
        $lodash.forEach(values.cloud_purchase_amount_newly_year_chart, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudPurchaseTabledatas.push(obj)
          }
        })
        // 云采购销售金额table数据
        $lodash.forEach(values.cloud_purchase_amount_send_out_newly_year_chart, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudPurchaseSendOutTabledatas.push(obj)
          }
        })
        // 成交采购商数量table数据
        $lodash.forEach(values.deal_purchase_users_count, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            dealPurchaseNumData.push(obj)
          }
        })
        // 云供应金额table数据
        $lodash.forEach(values.cloud_supply_amount_send_out_newly_year_chart, (value, key) => {
          if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
            const obj = {
              month: key.split('-')[1],
              value: value
            }
            cloudSupplyData.push(obj)
          }
        })
        // 平均毛利率
        averageGrossMargindata = ($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value')) > 0 ? ((($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value')) - ($lodash.sumBy(cloudSupplyData, 'value'))) / ($lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value'))) * 100 : 0
      }
      return {
        userName: values.user_name,
        totalPurchaser: values.department_purchase_users_count,
        dealPurchaserNum: dealPurchaseNumData.length > 0 ? $lodash.sumBy(dealPurchaseNumData, 'value') : 0,
        cloudPurchaseAmout: cloudPurchaseTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseTabledatas, 'value') : 0, // 云采购合同额数据
        cloudSendOutAmount: cloudPurchaseSendOutTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') : 0, // 云采购销售额
        averageGrossMargin: averageGrossMargindata > 0 ? parseFloat(averageGrossMargindata).toFixed(2) : 0
      }
    }), function (item) {
      return -item.cloudPurchaseAmout
    })
    return list
  }

  render () {
    const { searchData, tableData, departMentPurchaseUserAll, newPurchaseUser, cloudPurchaseAmount, cloudPurchaseSentOut, kaAverageGrossMargin, normalAverageGrossMargin, percentForNum, percentForAmount } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px', paddingLeft: '30px' }}>
          <Col span={4} >
            <Statistic title='部门采购商总数' value={departMentPurchaseUserAll} />
          </Col>
          <Col span={4}>
            <Statistic title='新增采购商数量' value={newPurchaseUser} precision={0} />
          </Col>
          <Col span={4}>
            <Statistic title='云采购合同额' value={cloudPurchaseAmount} precision={2} prefix='￥' />
          </Col>
          <Col span={4}>
            <Statistic title='云采购销售额(发货)' value={cloudPurchaseSentOut} precision={2} prefix='￥' />
          </Col>
          <Col span={4}>
            <Statistic title='大客户平均毛利率' value={kaAverageGrossMargin} precision={2} suffix='%' />
          </Col>
          <Col span={4}>
            <Statistic title='常规客商平均毛利率' value={normalAverageGrossMargin} precision={2} suffix='%' />
          </Col>
        </Row>
        <Card bodyStyle={{ padding: '12px' }}>
          <Tooltip title='prompt text' style={{ width: '100px', display: 'inline-block' }}>
            <span>新增采购商数量:</span>
          </Tooltip>
          <Progress style={{ width: '700px', display: 'inline-block', paddingLeft: '30px', paddingRight: '60px' }}
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
            strokeWidth='20px'
            percent={percentForNum}
            status='active'
          />
          <Tooltip title='prompt text' style={{ width: '100px', display: 'inline-block' }}>
            <span>云采购销售额:</span>
          </Tooltip>
          <Progress style={{ width: '700px', display: 'inline-block', paddingLeft: '30px', paddingRight: '60px' }}
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
            strokeWidth='20px'
            percent={percentForAmount}
            status='active'
          />
        </Card>
        <Card bodyStyle={{ padding: '12px' }}>
          <div id='salesStatisticsEcharts' style={{ width: '100%', height: '500px' }} />
        </Card>
        <Divider orientation='left'>交易明细</Divider>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Card>
      </div>
    )
  }
}
