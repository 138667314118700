import React, { Component } from 'react'
import { Modal, Form, InputNumber, Input, message } from 'antd'
import PropTypes from 'prop-types'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

const FormItem = Form.Item
const Password = Input.Password

class paymoenymodal extends Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    form: PropTypes.object,
    detailInfo: PropTypes.object
  }
  state = {

  }

  // 点击确认
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (+values.paidAmount === 0) {
          return message.error('输入金额不能等于0')
        }

        let { paidPassword } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])

        values['paidPassword'] = jsencrypt.encrypt(paidPassword)
        values['orderId'] = this.props.detailInfo.orderId
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { form, detailInfo = {}, onCancel } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }

    return (
      <>
        <Modal title='确认收款' bodyStyle={{ padding: '15px' }}
          visible
          onOk={() => { this.handleConfirm() }}
          onCancel={() => onCancel()}
        >
          <Form>
            <FormItem label='此次确认收款' {...formItemLayout}>
              {getFieldDecorator('paidAmount', {
                initialValue: +detailInfo.receivableAmount,
                rules: [{ required: true, message: '请输入收款金额' }
                ]
              })(
                <InputNumber autoComplete='off' min={0} max={+detailInfo.receivableAmount} style={{ width: '120px' }} />
              )}
            </FormItem>

            <FormItem label='支付密码' {...formItemLayout}>
              {getFieldDecorator('paidPassword', {
                rules: [{ required: true, message: '请输入6位数字支付密码' }]
              })(
                <Password autoComplete='off' />
              )}
            </FormItem>
          </Form>
        </Modal>

      </>
    )
  }
}
const PayMoeny = Form.create()(paymoenymodal)

export default PayMoeny
