import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Input, Upload, Icon, message } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import styles from './invoice.module.scss'

const { TextArea } = Input
const { uploadOrdinaryImageUrl } = UploadApi

export default class VoucherModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    voucherImg: '',
    remark: ''
  }

  componentDidMount () {}

  // 点击确认
  handleConfirm = () => {
    const { detailInfo } = this.props
    const { remark = '', voucherImg = '' } = this.state

    if (voucherImg === '') {
      return message.warning('请上传打款凭证')
    }

    let params = {
      id: detailInfo.id,
      remarks: remark,
      voucherUrl: voucherImg || ''
    }

    this.props.onConfirm(params)
  }

  // 监听输入
  onChangeTextValue = (val) => {
    this.setState({
      remark: val
    })
  }

  // 点击上传
  onChangeVoucherFile = (info) => {
    if (info.file.status === 'done') {
      const { data } = info.file.response

      this.setState({
        voucherImg: data.imageUrl || ''
      })
    }
  }

  // 删除图片
  removeImg = () => {
    this.setState({
      voucherImg: ''
    })
  }

  render () {
    const { voucherImg = '' } = this.state

    return (
      <>
        <Modal title='凭证'
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Row style={{ marginBottom: '20px', textAlign: 'center', fontSize: '18px' }}>
            确认已经为该用户打款？
          </Row>
          <Row>
            <Row style={{ marginBottom: '20px' }}>
              <label className={`${styles.inline} ${styles.formLabel} ${styles.fill}`}>打款凭证</label>
              <div className={`${styles.inline}`}>
                {
                  voucherImg !== ''
                    ? <div className={styles.thumbWrap}>
                      <div className={styles.thumbInfo}>
                        <img src={voucherImg} />

                        <a className={styles.prewModal}>
                          <Icon type='delete' style={{ fontSize: '20px' }}
                            onClick={() => this.removeImg()}
                          />
                        </a>
                      </div>
                    </div>
                    : <Upload
                      name='file'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      listType='picture-card'
                      onChange={this.onChangeVoucherFile}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <Icon type='plus' />
                        <p>点击上传</p>
                      </div>
                    </Upload>

                }
              </div>
            </Row>

            <Row>
              <label className={`${styles.inline} ${styles.formLabel}`}
                style={{ width: '81px', textAlign: 'right' }}
              >
                备注
              </label>
              <div className={`${styles.inline}`}
                style={{ width: 'calc(100% - 85px)' }}
              >
                <TextArea rows={4} placeholder='请输入...'
                  onChange={(e) => this.onChangeTextValue(e.target.value)}
                />
              </div>

            </Row>
          </Row>
        </Modal>
      </>
    )
  }
}
