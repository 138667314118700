import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Switch, Button, Icon, message } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import SearchForm from '@/components/search-form/index'
import UnbindModal from './unbindModal'

const { pageSizeOptions } = filter

class BannerBind extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择客户...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['distributorId', 'merchantName']
      }
    ],
    advertBase: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    unbindModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'bindList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '公司名称',
      key: 'bindList-1',
      dataIndex: 'distributorName'
    },
    {
      title: '广告ID',
      key: 'bindList-2',
      dataIndex: 'advertisingId'
    },
    {
      title: '广告名称',
      key: 'bindList-3',
      dataIndex: 'advertisingName'
    },
    {
      title: '广告启用',
      key: 'bindList-4',
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.enableStatus === 'ENABLE'}
            checkedChildren='启用'
            unCheckedChildren='禁用'
            onChange={(checked) => this.handleChangeSwitch(checked, record)}
          />
        </div>
      )
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      advertBase: state.detailInfo || {}
    }, () => {
      this.getCustomerList()
    })
  }

  // 获取客户列表
  getCustomerList = () => {
    Api.getCustomerList({ pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        let { searchData } = this.state
        let _options = [
          { distributorId: '-1', merchantName: '全部' }
        ]

        if (data && data.list) {
          searchData[0].options = _options.concat(data.list)
        }

        this.setState({
          searchData
        }, () => {
          this.getBannerAdvertBindedMerchants()
        })
      }
    })
  }

  // 获取banner广告已关联的商户列表
  getBannerAdvertBindedMerchants = () => {
    const { pages, query = {}, advertBase = {} } = this.state
    let params = {
      ...query,
      advertisingId: advertBase.id,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getBannerAdvertBindedMerchants(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // banner广告关联商户
  saveBannerAdvertBindMerchants = (params) => {
    Api.saveBannerAdvertBindMerchants(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getBannerAdvertBindedMerchants()
      }
    })
  }

  // 广告启用禁用状态
  updateBannerAdvertStatusInMerchant = (params) => {
    Api.updateBannerAdvertStatusInMerchant(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getBannerAdvertBindedMerchants()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getBannerAdvertBindedMerchants()
    })
  }

  // 切换启用禁用状态
  handleChangeSwitch = (checked, record) => {
    let params = {
      enableStatus: checked ? 'ENABLE' : 'DISABLE',
      id: record.id
    }
    this.updateBannerAdvertStatusInMerchant(params)
  }

  // 点击关联商户
  showUnbindModal = () => {
    this.setState({
      unbindModal: true
    })
  }

  closeModal = () => {
    this.setState({
      unbindModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getBannerAdvertBindedMerchants()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getBannerAdvertBindedMerchants()
    })
  }

  render () {
    const { searchData, pages, tableData, unbindModal = false, advertBase = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Button type='primary' onClick={this.showUnbindModal}>
                <Icon type='retweet' />关联商户
              </Button>
            </Row>
            <Row>
              <Table rowKey='id' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          unbindModal
            ? <UnbindModal
              detailInfo={advertBase}
              onCancel={this.closeModal}
              onConfirm={(params) => this.saveBannerAdvertBindMerchants(params)}
            />
            : null
        }
      </div>
    )
  }
}

export default BannerBind
