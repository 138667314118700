import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Select, Cascader } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item
const Option = Select.Option

const { confirm } = Modal

class SelectAddToForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    addToCode: PropTypes.string,
    detailInfo: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    categoryList: [], // 分类列表
    brandList: [], // 品牌列表
    seriesList: [] // 系列列表
  }

  componentDidMount () {
    const { addToCode = 'TO_SERIES' } = this.props
    if (addToCode === 'TO_CATEGORY') {
      this.getProductCategoryList()
    }

    if (addToCode === 'TO_SERIES') {
      this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    }
  }

  // 获取分类
  getProductCategoryList =async () => {
    const res = await Api.getProductCategoryList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        categoryList: data || []
      })
    }
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          brandList: data || []
        })
      }
    })
  }

  // 获取系列列表
  getSeriesList = async (brandId) => {
    const res = await Api.getSeriesList({ brandId: brandId, pageNo: 1, pageSize: 999 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        seriesList: data.list || []
      }, () => {
        this.props.form.setFieldsValue({ seriesId: undefined })
      })
    }
  }

  // 点击确认
  handleConfirm = () => {
    const { form, addToCode, detailInfo = {}, onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        confirm({
          title: `确定添加？`,
          content: '',
          onOk: () => {
            values.skuIds = detailInfo.join(',')

            if (addToCode === 'TO_CATEGORY') {
              values.categoryId = values.categoryIds[2]
            }

            onConfirm(values, addToCode)
          }
        })
      }
    })
  }

  render () {
    const { form, addToCode } = this.props
    const { getFieldDecorator } = form
    const { categoryList = [], brandList = [], seriesList = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <>
        <Modal title={`添加到${addToCode === 'TO_CATEGORY' ? '分类' : '系列'}`}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form>
            {
              addToCode === 'TO_CATEGORY'
                ? <FormItem label='分类' {...formItemLayout}>
                  {
                    getFieldDecorator('categoryIds', {
                      rules: [
                        { required: true, message: '该项必选' }
                      ]
                    })(
                      <Cascader
                        placeholder='请选择...' showSearch
                        fieldNames={{ label: 'name', value: 'id', children: 'classifications' }}
                        options={categoryList}
                      />
                    )
                  }
                </FormItem>
                : null
            }

            {
              addToCode === 'TO_SERIES'
                ? <>
                  <FormItem label='品牌' {...formItemLayout}>
                    {
                      getFieldDecorator('brandId', {
                        rules: [
                          { required: true, message: '该项必选' }
                        ]
                      })(
                        <Select placeholder='请选择...' showSearch
                          optionFilterProp='children'
                          onChange={(value) => this.getSeriesList(value)}>
                          {
                            brandList.map((item, i) => (
                              <Option value={item.brandId} key={`brandOpt-${item.brandId}`}>{item.brandName}</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                  <FormItem label='系列' {...formItemLayout}>
                    {
                      getFieldDecorator('seriesId', {
                        rules: [
                          { required: true, message: '该项必选' }
                        ]
                      })(
                        <Select placeholder='请选择...' showSearch optionFilterProp='children'>
                          {
                            seriesList.map((series, i) => (
                              <Option value={series.id} key={`seriesOpt-${series.id}`}>{series.name}</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </>
                : null
            }
          </Form>
        </Modal>
      </>
    )
  }
}

const AddToModal = Form.create()(SelectAddToForm)

export default AddToModal
