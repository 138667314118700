import React, { Component } from 'react'
import { Card, Table, Button, Icon, message } from 'antd'

import SearchForm from '@/components/search-form/index'
import BindRelationModal from '../component/bindRelationModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class BrandRelations extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'erpBrandCode',
        formType: 'Input',
        itemParams: {
          label: 'ERP品牌编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'erpBrandName',
        formType: 'Input',
        itemParams: {
          label: 'ERP品牌全名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'erpBrandAlias',
        formType: 'Input',
        itemParams: {
          label: '品牌助记码'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandName',
        formType: 'Input',
        itemParams: {
          label: '品牌中/英名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }

    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    type: 'ADD',
    bindRelationModal: false,
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'column-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: 'ERP品牌编号',
      key: 'brandRelationsCol-erpBrandCode',
      dataIndex: 'erpBrandCode'
    },
    {
      title: 'ERP品牌全名',
      key: 'brandRelationsCol-erpBrandName',
      dataIndex: 'erpBrandName'
    },
    {
      title: '品牌助记码',
      key: 'brandRelationsCol-erpBrandAlias',
      dataIndex: 'erpBrandAlias'
    },
    {
      title: '品牌编号',
      key: 'brandRelationsCol-brandId',
      dataIndex: 'brandId'
    },
    {
      title: '品牌中文名称',
      key: 'brandRelationsCol-brandName',
      dataIndex: 'brandName'
    },
    {
      title: '品牌英文名称',
      key: 'brandRelationsCol-englishBrandName',
      dataIndex: 'englishBrandName'
    },
    {
      title: '操作',
      key: 'column-operations',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showBindRelationModal(record, 'EDIT')}>修改</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getERPBrandRelationsList()
  }

  // 获取ERP品牌关系映射列表
  getERPBrandRelationsList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getERPBrandRelationsList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存新增品牌关系映射
  saveAddERPBrandRelation = async (params) => {
    const res = await Api.saveAddERPBrandRelation(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getERPBrandRelationsList()
    }
  }

  // 保存编辑品牌关系
  saveEditERPBrandRelation = async (params) => {
    const res = await Api.saveEditERPBrandRelation(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getERPBrandRelationsList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      },
      query: search || {}
    }, () => {
      this.getERPBrandRelationsList()
    })
  }

  // 点击新增、编辑品牌关系
  showBindRelationModal = (record, type) => {
    this.setState({
      rowDetail: record,
      type: type,
      bindRelationModal: true
    })
  }

  // 点击确定保存ERP品牌关系
  handleConfirmBindRelation = (params) => {
    const { type = 'ADD' } = this.state

    if (type === 'EDIT') {
      this.saveEditERPBrandRelation(params)
    } else {
      this.saveAddERPBrandRelation(params)
    }
  }

  closeModal = () => {
    this.setState({
      bindRelationModal: false,
      type: 'ADD',
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getERPBrandRelationsList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getERPBrandRelationsList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], bindRelationModal = false, type = 'ADD', rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.showBindRelationModal({}, 'ADD')}><Icon type='plus' />新增关系</Button>
          </div>

          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        {
          bindRelationModal
            ? <BindRelationModal
              bindKey='BIND_BRAND'
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleConfirmBindRelation(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default BrandRelations
