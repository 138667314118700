import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Button, message, Modal } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import BrandModal from '../component/brandModal'
import SeriesModal from '../component/seriesModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderBrandTypeLabel } from '@/common/utils/mUtils'

const { confirm } = Modal
const { pageSizeOptions, brandTypeMaps } = filter

class BrandList extends Component {
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'brandName',
        formType: 'Input',
        itemParams: {
          label: '品牌名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '品牌类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...brandTypeMaps
        ]
      },
      {
        name: 'createTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '创建时间'
        },
        cptParams: {

        }
      },
      {
        name: 'createName',
        formType: 'Input',
        itemParams: {
          label: '创建人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '修改时间'
        },
        cptParams: {

        }
      },
      {
        name: 'modifyName',
        formType: 'Input',
        itemParams: {
          label: '修改人'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'isHasCover',
        formType: 'Select',
        itemParams: {
          label: '是否有封面'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: -1, name: '全部' },
          { id: 1, name: '有' },
          { id: 0, name: '否' }
        ]
      }
    ],
    type: 1, // 1-新增 2-编辑
    brandModal: false,
    seriesModal: false,
    query: {}, // 查询条件
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    selectedRowKeys: [],
    tempList: [] // 模板列表
  }

  columns = [
    {
      title: '序号',
      key: 'brand-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌名称',
      key: 'brand-1',
      render: (text, record, index) => (
        <div>
          <div className={`brandLabel nameCn`}>{record.name}</div>
          <div className={`brandLabel nameEn`}>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '品牌类型',
      key: 'brand-2',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ renderBrandTypeLabel(record.type) }</>
      )
    },
    {
      title: '系列',
      key: 'brand-3',
      render: (text, record, index) => (
        <a onClick={() => this.handleLinkToSeries({ brandId: record.id, brandName: record.name, englishBrandName: record.englishBrandName })}>查看系列</a>
      )
    },
    {
      title: '品牌描述',
      key: 'brand-4',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <div style={{ width: '160px' }} className={`ellipsis`} title={record.desc}>
          {record.desc}
        </div>
      )
    },
    {
      title: '创建时间',
      key: 'brand-5',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '创建人',
      key: 'brand-6',
      dataIndex: 'createName'
    },
    {
      title: '修改时间',
      key: 'brand-7',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '修改人',
      key: 'brand-8',
      dataIndex: 'modifyName'
    },
    {
      title: '是否有封面',
      key: 'brand-9',
      dataIndex: 'isHasCover',
      render: (text, record, index) => (
        <>{ +record.isHasCover === 1 ? '有' : '无' }</>
      )
    },
    {
      title: '操作',
      key: 'brand-10',
      width: 250,
      render: (text, record, index) => {
        const { buttonMaps = {} } = this.props

        return (
          <>
            {
              buttonMaps['add'] && +buttonMaps['add'].auth === 1
                ? <Button type='link' size='small' onClick={() => this.showSeriesModal(1, { brandId: record.id, brandName: record.name })}>添加系列</Button>
                : null
            }

            {
              buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <Button type='link' size='small' onClick={() => this.showBrandModal(2, record)}>编辑品牌</Button>
                : null
            }

            {
              buttonMaps['delete'] && +buttonMaps['delete'].auth === 1
                ? <Button type='link' size='small' onClick={() => this.handleDeleteRow(record)}>删除品牌</Button>
                : null
            }
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getBrandList()
  }

  // 获取品牌列表
  getBrandList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getBrandList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存品牌
  saveBrand = (params) => {
    Api.saveBrand(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getBrandList()
      }
    })
  }

  // 删除品牌
  deleteBrand = (params) => {
    Api.deleteBrand(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getBrandList()
      }
    })
  }

  // 保存系列
  saveSeries = async (params) => {
    const res = await Api.saveSeries(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getBrandList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.createTimes) {
      search.createdBeginDate = search.createTimes.length === 2 ? moment(search.createTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.createdEndDate = search.createTimes.length === 2 ? moment(search.createTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    if (search.modifyTimes) {
      search.modifyBeginDate = search.modifyTimes.length === 2 ? moment(search.modifyTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.modifyEndDate = search.modifyTimes.length === 2 ? moment(search.modifyTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getBrandList()
    })
  }

  // 点击删除品牌
  handleDeleteRow = (record) => {
    confirm({
      title: '确定删除该品牌？',
      content: '',
      onOk: () => {
        this.deleteBrand({ id: record.id })
      }
    })
  }

  // 新增、编辑品牌 弹窗
  showBrandModal = (type, row = {}) => {
    this.setState({
      brandModal: true,
      rowDetail: row || {},
      type: type
    })
  }

  // 点击添加系列
  showSeriesModal = (type, row = {}) => {
    this.setState({
      seriesModal: true,
      type: type,
      rowDetail: row
    })
  }

  // 点击查看系列
  handleLinkToSeries = (row = {}) => {
    this.props.history.push({ pathname: '/admin/series', state: { ...row } })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      brandModal: false,
      seriesModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getBrandList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getBrandList()
    })
  }

  render () {
    const { buttonMaps = {} } = this.props
    const { tableData, pages, searchData, type = 1, brandModal = false, seriesModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            {
              buttonMaps['add'] && +buttonMaps['add'].auth === 1
                ? <Button type='primary' icon='plus' onClick={() => this.showBrandModal(1, {})}>添加品牌</Button>
                : null
            }
          </div>

          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        {
          brandModal
            ? <BrandModal
              data={rowDetail}
              type={type}
              onConfirm={(values) => this.saveBrand(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        { // 添加、编辑系列弹窗
          seriesModal
            ? <SeriesModal
              type={type}
              detailInfo={{ ...rowDetail }}
              onConfirm={(values) => this.saveSeries(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(BrandList)
