import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Tabs } from 'antd'

import SendedInformation from './sendedInformation'
import ReceivedInformation from './receivedInformation'

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabMaps = [
  { id: 'RECEIVED', name: '已接收' },
  { id: 'SENDED', name: '已发送' }
]

class InformationList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    activeTab: 'RECEIVED'
  }

  componentDidMount () {}

  // 点击切换
  handleChangeTabPane = (key) => {
    this.setState({
      activeTab: key
    })
  }

  render () {
    const { activeTab = 'RECEIVED' } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <Row className={`${styles.pageTabLand}`}>
              <Tabs onChange={(key) => this.handleChangeTabPane(key)}>
                {
                  tabMaps.map((item, i) => (
                    <TabPane tab={item.name} key={item.id} />
                  ))
                }
              </Tabs>
            </Row>
          </Card>
        </Row>

        {
          activeTab === 'SENDED'
            ? <SendedInformation history={this.props.history} />
            : null
        }

        {
          activeTab === 'RECEIVED'
            ? <ReceivedInformation history={this.props.history} />
            : null
        }

      </div>
    )
  }
}

export default InformationList
