import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Descriptions, Button, Upload, Icon, message } from 'antd'

import Uploadthumbnail from '@/components/uploadThumbnail/index'
import UploadApi from '@/common/api/uploadApi'
import PayInfo from './payinfo'
import { getStore } from '@/common/utils/mUtils'

import styles from './child.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const MAXSIZE = 5

class PaybankModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    bankInfo: {}, // 银行信息
    fileList: []
  }

  componentDidMount () {
    const { getDetail, detailInfo } = this.props

    let params = {
      purchaseOrderId: detailInfo.purchaseOrderId
    }

    getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          bankInfo: data || {}
        })
      }
    })
  }

  // 点击确定付款
  handleConfirm = () => {
    const { fileList } = this.state
    const { detailInfo } = this.props
    if (fileList.length === 0 || fileList === []) {
      return message.error('请至少上传一张付款凭证')
    }

    let newlist = fileList.map(obj => { return obj.imageUrl })
    let paymentUrl = newlist.join(',')
    this.props.onConfirm({ purchaseOrderId: detailInfo.purchaseOrderId, paymentUrl: paymentUrl, realPaymentAmount: +detailInfo.realPaymentAmount })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < MAXSIZE
    if (!isLt1M) {
      message.warning(`图片大小不可超过${MAXSIZE}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        if (data && data.imageUrl) {
          let { fileList = [] } = this.state

          fileList.push({
            imageUrl: data.imageUrl
          })

          this.setState({
            fileList
          })
        }
      } else {
        message.error('上传失败')
      }
    }
  }

  // 上传的图片删除
  removeUploadImage = (i) => {
    let { fileList = [] } = this.state
    fileList.splice(i, 1)
    this.setState({
      fileList
    })
  }

  render () {
    const { detailInfo } = this.props
    const { bankInfo = {}, fileList = [] } = this.state

    return (
      <>
        <Modal title='转账信息' width={1000} bodyStyle={{ padding: '12px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={[
            <Button key={`paymationBtn-0`} onClick={this.props.onCancel}>
              关闭
            </Button>,
            <Button type='primary' key='paymation-1' onClick={this.handleConfirm}>
              确定付款
            </Button>
          ]}
        >
          <Row style={{ marginBottom: '10px' }}>
            <Descriptions>
              <Descriptions.Item label='订单金额'>
                &yen;{(+detailInfo.payableAmount * 100 / 100).toFixed(2)}
              </Descriptions.Item>

              <Descriptions.Item label='应付金额'>
                &yen;{(+detailInfo.realPaymentAmount * 100 / 100).toFixed(2)}
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <PayInfo bankInfo={bankInfo} detailInfo={detailInfo} />
          </Row>

          <Row className={`${styles.formLand}`} style={{ marginTop: '24px' }}>
            <div>
              <div>付款凭证: <span style={{ fontSize: '12px', color: '#ff4444' }}>(注意：上传图片或PDF文件限制大小为5M)</span></div>
              {
                fileList.length < 2
                  ? <div style={{ marginTop: '10px', display: 'flex' }}>
                    {
                      fileList.map((item, i) => (
                        <Uploadthumbnail imageUrl={item.imageUrl} key={`AA-${i}`} style={{ marginLeft: '20px' }}
                          onDelete={() => this.removeUploadImage(i)}
                        />
                      ))
                    }
                    <div style={{ display: 'flex', marginLeft: '20px' }} >

                      <Upload showUploadList={false} key={`upload-${0}`}
                        accept='image/png, image/jpg, image/jpeg, pdf'
                        headers={{ Token: getStore('token') }}
                        action={uploadOrdinaryImageUrl}
                        beforeUpload={this.beforeUpload}
                        listType='picture-card'
                        onPreview={this.handlePreview}
                        onChange={(info) => this.handleChangeUpload(info)}
                      >
                        <div>
                          <Icon type='plus' />
                          <div>点击上传</div>
                        </div>
                      </Upload>
                    </div>
                  </div>
                  : <div style={{ marginTop: '10px', display: 'flex' }}>
                    {
                      fileList.map((item, i) => (
                        <Uploadthumbnail imageUrl={item.imageUrl} key={`AA-${i}`} style={{ marginLeft: '20px' }}
                          onDelete={() => this.removeUploadImage(i)}
                        />
                      ))
                    }
                  </div>
              }

            </div>
          </Row>

        </Modal>
      </>
    )
  }
}

export default PaybankModal
