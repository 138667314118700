import React, { Component } from 'react'
import { Row, Card, Col, Statistic, Divider, Table } from 'antd'
import * as echarts from 'echarts'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import Api from '@/common/api'

import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { MAX_PAGESIZE } = consts
const { fullsMonthOptions, echartscolorinit } = filter

// 当前年
const YEAR = moment().get('year')

export default class index extends Component {
  constructor (props) {
    super(props)
    this.getPurposeMemberList = $lodash.debounce(this.getPurposeMemberList, 300)
  }
  state = {
    searchData: [
      {
        name: 'userId',
        formType: 'Select',
        itemParams: {
          label: '员工姓名'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getPurposeMemberList(val)
          }
        },
        options: [],
        optionValue: ['userId', 'realName']
      },
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { month: 0, monthLabel: '全年' },
          ...fullsMonthOptions
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['month', 'monthLabel']
      },
      {
        name: 'latitude',
        formType: 'Select',
        itemParams: {
          label: '查询维度'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { latitude: 0, latitudeLabel: '供应' },
          { latitude: 1, latitudeLabel: '采购' }
        ],
        rules: {
          initialValue: 0
        },
        optionValue: ['latitude', 'latitudeLabel']
      }
    ],
    query: {},
    newSupplyUser: 0, // 新增供应商数
    newPurchaseUser: 0, // 新增采购商数
    cloudPurchaseSendNum: 0, // 云采购订单数量(发货)
    cloudSupplyAmount: 0, // 云供应订单金额
    // ----供应纬度echarts----
    tableDataForSupply: [], // 供应商明细列表
    cloudSupplyAmountSeries: [], // echarts云供应订单金额
    newSupplyersSeries: [], // echarts新增供应商数量，单位“家”
    // ----采购纬度echarts
    tableDataForPurchase: [], // 采购商明细列表
    cloudPurchaseNumSeries: [], // 云采购订单数量（发货），单位“¥”
    newPurchasersSeries: [] // 新增采购商数量，单位“个”
  }
  columnsForSupply = [
    {
      title: '序号',
      key: 'infoListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '供应商名称',
      key: 'infoListCol-1',
      dataIndex: 'supplyUserName'
    },
    {
      title: '云供应合同金额',
      key: 'infoListCol-2',
      dataIndex: 'cloudSupplyAmout',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云供应发货金额',
      key: 'infoListCol-3',
      dataIndex: 'cloudSupplySendOutAmount',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云供应订单数量',
      key: 'infoListCol-4',
      dataIndex: 'cloudSupplyNum'
    }
  ]

  columnsForPurchase = [
    {
      title: '序号',
      key: 'cusListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '采购商名称',
      key: 'cusListCol-1',
      dataIndex: 'purchaseUserName'
    },
    {
      title: '云采购合同金额',
      key: 'cusListCol-2',
      dataIndex: 'cloudPurchaseAmout',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云采购发货金额',
      key: 'cusListCol-3',
      dataIndex: 'cloudSendOutAmount',
      render: (text, record, index) => {
        return (
          <span>￥{parseFloat(text).toFixed(2)}</span>
        )
      }
    },
    {
      title: '云采购订单数量',
      key: 'cusListCol-4',
      dataIndex: 'cloudPurchaseNum'
    }
  ]
  // 获取报表数据
  getImplementationStatistics = async () => {
    const { query = {} } = this.state
    const res = await Api.getPerformanceStatistics({ type: 'implementation_department', user_id: query.userId })
    let newPurchaseUserObj = res.list_purchase_users_newly // 采购商总数据
    let newSupplyerObj = res.list_supply_users_newly // 供应商数据
    let cloudPurchaseSentOutObj = res.cloud_purchase_amount_send_out_newly_year_chart // 云采购订单数量
    let cloudSupplyAmountObj = res.cloud_supply_amount_newly_year_chart // 云供应订单金额
    let purchaseTableDataObj = res.cloud_purchase_distributor_table // 两个table总数据
    let supplyTableDataObj = res.cloud_supply_distributor_table // 两个table总数据
    let _tabledataForSupply = [] // 供应表格数据
    let _tabledataForPurchase = [] // 采购表格数据

    // 设置table列表数据
    if (supplyTableDataObj) {
      _tabledataForSupply = this.setTableDataForSupply(supplyTableDataObj)
    }
    if (purchaseTableDataObj) {
      _tabledataForPurchase = this.setTableDataForPurchase(purchaseTableDataObj)
    }

    // 新增采购商数据
    let newPurchaserdatas = []
    $lodash.forEach(newPurchaseUserObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        newPurchaserdatas.push(obj)
      }
    })

    // 新增供应商数据
    let newSupplyerdatas = []
    $lodash.forEach(newSupplyerObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        newSupplyerdatas.push(obj)
      }
    })

    // 云采购订单数量
    let cloudPurchaseNumdatas = []
    $lodash.map(purchaseTableDataObj, (values, keys) => {
      $lodash.forEach(values.cloud_purchase_order_count_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseNumdatas.push(obj)
        }
      })
    })

    // 云供应订单金额
    let cloudSupplyAmountDatas = []
    $lodash.forEach(cloudSupplyAmountObj, (value, key) => {
      if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
        const obj = {
          month: key.split('-')[1],
          value: value
        }
        cloudSupplyAmountDatas.push(obj)
      }
    })

    // ----供应纬度echarts----
    // echarts云供应订单金额
    let cloudSupplyEcharts = []
    $lodash.forEach(cloudSupplyAmountObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: parseFloat(value).toFixed(2) }
        cloudSupplyEcharts.push(obj)
      }
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const cloudSupplyEchartsdatas = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(cloudSupplyEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })
    // echarts新增供应商数量，单位“家”
    let newSupplyerEcharts = []
    $lodash.forEach(newSupplyerObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: value }
        newSupplyerEcharts.push(obj)
      }
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const newSupplyerEchartsdatas = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(newSupplyerEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })
    // ----采购纬度echarts----
    // echarts云采购订单金额
    let cloudPurchaseEcharts = []
    $lodash.forEach(cloudPurchaseSentOutObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: parseFloat(value).toFixed(2) }
        cloudPurchaseEcharts.push(obj)
      }
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const cloudPurchaseEchartsdatas = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(cloudPurchaseEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })
    // echarts新增供应商数量，单位“家”
    let newPurchaserEcharts = []
    $lodash.forEach(newPurchaseUserObj, (value, key) => {
      if (key.includes(YEAR)) {
        const obj = { month: +(key.split('-')[1]), val: value }
        newPurchaserEcharts.push(obj)
      }
    })
    /**
     * 将月份填充满到12月 [{ maonth: 1 ,val: xxx }, { month: 2, val: xxx},  ..., { month: 12, val: xxx }]
    */
    const newPurchaserEchartsdatas = $lodash.map(fullsMonthOptions, (record) => {
      const obj = $lodash.find(newPurchaserEcharts, (item) => { return item.month === record.month })
      return {
        month: record.month,
        value: obj !== undefined ? obj.val : 0
      }
    })

    this.setState({
      newPurchaseUser: newPurchaserdatas.length > 0 ? $lodash.sumBy(newPurchaserdatas, 'value') : 0, // 新增采购商数量
      newSupplyUser: newSupplyerdatas.length > 0 ? $lodash.sumBy(newSupplyerdatas, 'value') : 0, // 新增供应商数量
      cloudPurchaseSendNum: cloudPurchaseNumdatas.length > 0 ? $lodash.sumBy(cloudPurchaseNumdatas, 'value') : 0, // 云采购订单数量
      cloudSupplyAmount: cloudSupplyAmountDatas.length > 0 ? $lodash.sumBy(cloudSupplyAmountDatas, 'value') : 0, // 云供应订单金额
      cloudSupplyAmountSeries: cloudSupplyEchartsdatas, // echarts云供应订单金额
      newSupplyersSeries: newSupplyerEchartsdatas, // echarts新增供应商数量，单位“家”
      cloudPurchaseNumSeries: cloudPurchaseEchartsdatas, // 云采购订单数量（发货），单位“¥”
      newPurchasersSeries: newPurchaserEchartsdatas, // 新增采购商数量，单位“个”
      tableDataForSupply: _tabledataForSupply,
      tableDataForPurchase: _tabledataForPurchase
    }, () => {
      // 根据查询纬度不同填充并显示不同的Echarts及表格数据
      if (query.latitude === 0) {
        this.initSupplyECharts()
      }
      if (query.latitude === 1) {
        this.initPurchaseECharts()
      }
    })
  }

  // 设置云采购table列表数据结构
  setTableDataForPurchase = (origindatas) => {
    const { query = {} } = this.state
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云采购合同金额table数据
      let cloudPurchaseTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseTabledatas.push(obj)
        }
      })
      // 云采购销售金额table数据
      let cloudPurchaseSendOutTabledatas = []
      $lodash.forEach(values.cloud_purchase_amount_send_out_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudPurchaseSendOutTabledatas.push(obj)
        }
      })
      // 云采购订单数量
      let cloudPurchaseNumdata = 0
      $lodash.forEach(values.cloud_purchase_order_count_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          cloudPurchaseNumdata = cloudPurchaseNumdata + value
        }
      })

      return {
        purchaseUserName: key,
        cloudPurchaseAmout: cloudPurchaseTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseTabledatas, 'value') : 0, // 云采购合同额数据
        cloudSendOutAmount: cloudPurchaseSendOutTabledatas.length > 0 ? $lodash.sumBy(cloudPurchaseSendOutTabledatas, 'value') : 0, // 云采购销售额
        cloudPurchaseNum: cloudPurchaseNumdata // 云采购订单数量
      }
    }), function (item) {
      return -item.cloudPurchaseAmout
    })
    return list
  }
  // 设置云供应table列表数据结构
  setTableDataForSupply = (origindatas) => {
    const { query = {} } = this.state
    const list = $lodash.sortBy($lodash.map(origindatas, (values, key) => {
      // 云供应合同金额table数据
      let cloudSupplyTabledatas = []
      $lodash.forEach(values.cloud_supply_amount_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudSupplyTabledatas.push(obj)
        }
      })
      // 云供应销售金额table数据
      let cloudSupplySendOutTabledatas = []
      $lodash.forEach(values.cloud_supply_amount_send_out_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          const obj = {
            month: key.split('-')[1],
            value: value
          }
          cloudSupplySendOutTabledatas.push(obj)
        }
      })
      // 云供应订单数量
      let cloudSupplyNumdata = 0
      $lodash.forEach(values.cloud_supply_order_count_newly, (value, key) => {
        if ((key.includes(YEAR) && parseInt(key.split('-')[1]) === parseInt(query.month)) || (key.includes(YEAR) && parseInt(query.month) === 0)) {
          cloudSupplyNumdata = cloudSupplyNumdata + value
        }
      })

      return {
        supplyUserName: key,
        cloudSupplyAmout: cloudSupplyTabledatas.length > 0 ? $lodash.sumBy(cloudSupplyTabledatas, 'value') : 0, // 云供应合同额数据
        cloudSupplySendOutAmount: cloudSupplySendOutTabledatas.length > 0 ? $lodash.sumBy(cloudSupplySendOutTabledatas, 'value') : 0, // 云供应销售额
        cloudSupplyNum: cloudSupplyNumdata // 云供应订单数量
      }
    }), function (item) {
      return -item.cloudSupplyAmout
    })
    return list
  }
  // 初始化图表
  initSupplyECharts = () => {
    const { cloudSupplyAmountSeries, newSupplyersSeries } = this.state
    var myChart = echarts.init(document.getElementById('implementationStatisticsForSupply'))
    // 绘制图表
    myChart.setOption({
      color: echartscolorinit,
      title: {
        text: `${YEAR}供应统计折线图`
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['云供应订单金额', '新增供应商数量']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisLabel: {
          formatter: `{value}月`
        }
      },
      yAxis: [ {
        type: 'value',
        name: '金额',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} ￥'
        }
      },
      {
        type: 'value',
        name: '数量',
        scale: true, /* 按比例显示 */
        axisLabel: {
          formatter: '{value} 家'
        }
      }],
      series: [
        {
          name: '云供应订单金额',
          type: 'line',
          itemStyle: { normal: { label: { show: true } } },
          yAxisIndex: 0,
          data: cloudSupplyAmountSeries
        },
        {
          name: '新增供应商数量',
          type: 'line',
          yAxisIndex: 1,
          itemStyle: { normal: { label: { show: true } } },
          data: newSupplyersSeries
        }
      ]
    })
  }

   // 初始化图表
   initPurchaseECharts = () => {
     const { cloudPurchaseNumSeries, newPurchasersSeries } = this.state
     var myChart = echarts.init(document.getElementById('implementationStatisticsForPurchase'))
     // 绘制图表
     myChart.setOption({
       color: echartscolorinit,
       title: {
         text: `${YEAR}实施统计折线图`
       },
       tooltip: {
         trigger: 'axis'
       },
       legend: {
         data: ['云采购销售金额（发货）', '新增成交采购商数量']
       },
       grid: {
         left: '3%',
         right: '4%',
         bottom: '3%',
         containLabel: true
       },
       toolbox: {
         feature: {
           saveAsImage: {}
         }
       },
       xAxis: {
         type: 'category',
         boundaryGap: false,
         data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
         axisLabel: {
           formatter: `{value}月`
         }
       },
       yAxis: [ {
         type: 'value',
         name: '金额',
         scale: true, /* 按比例显示 */
         axisLabel: {
           formatter: '{value} ￥'
         }
       },
       {
         type: 'value',
         name: '数量',
         scale: true, /* 按比例显示 */
         axisLabel: {
           formatter: '{value} 家'
         }
       }],
       series: [
         {
           name: '云采购销售金额（发货）',
           type: 'line',
           connectNulls: true,
           yAxisIndex: 0,
           data: cloudPurchaseNumSeries,
           itemStyle: { normal: { label: { show: true } } }
         },
         {
           name: '新增成交采购商数量',
           type: 'line',
           connectNulls: true,
           yAxisIndex: 1,
           itemStyle: { normal: { label: { show: true } } },
           data: newPurchasersSeries
         }
       ]
     })
   }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.userId == null) {
      alert('请选择员工！')
      return false
    }
    this.setState({
      query: search
    }, () => {
      this.getImplementationStatistics()
    })
  }

  // 获取全部成员
  getPurposeMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }

    Api.getPurposeMemberList({ realName: val, departId: 5, pageNo: 1, pageSize: MAX_PAGESIZE }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[0].options = data && data.list && data.list.length > 0 ? [...data.list] : []
        this.setState({
          searchData
        })
      }
    })
  }
  render () {
    const { searchData, newSupplyUser, newPurchaseUser, cloudPurchaseSendNum, cloudSupplyAmount, tableDataForSupply, tableDataForPurchase, query } = this.state
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px', paddingLeft: '30px' }}>
          <Col span={6} >
            <Statistic title='新增供应商数' value={newSupplyUser} style={{ color: 'black' }} />
          </Col>
          <Col span={6}>
            <Statistic title='新增采购商数量' value={newPurchaseUser} precision={0} />
          </Col>
          <Col span={6}>
            <Statistic title='云采购订单数量(发货)' value={cloudPurchaseSendNum} precision={0} />
          </Col>
          <Col span={6}>
            <Statistic title='云供应订单金额' value={cloudSupplyAmount} precision={2} prefix='￥' />
          </Col>
        </Row>
        {query.latitude === 0 ? <Card bodyStyle={{ padding: '12px' }}>
          <div id='implementationStatisticsForSupply' style={{ width: '100%', height: '500px' }} />
        </Card>
          : null }
        {query.latitude === 1 ? <Card bodyStyle={{ padding: '12px' }}>
          <div id='implementationStatisticsForPurchase' style={{ width: '100%', height: '500px' }} />
        </Card> : null}
        {query.latitude === 1 ? <Divider orientation='left'>采购商明细</Divider>
          : null }
        {query.latitude === 1 ? <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columnsForPurchase}
            dataSource={tableDataForPurchase}
            pagination={false}
          />
        </Card>
          : null }
        {query.latitude === 0 ? <Divider orientation='left'>供应商明细</Divider>
          : null }
        {query.latitude === 0 ? <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columnsForSupply}
            dataSource={tableDataForSupply}
            pagination={false}
          />
        </Card>
          : null }

      </div>
    )
  }
}
