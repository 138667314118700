import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Button, Icon } from 'antd'
import $lodash from 'lodash'

import filter from '@/common/utils/filter'
import { downloadExcel } from '@/common/utils/downloadFile'

const { pageSizeOptions } = filter

export default class MCOrderTable extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 }
  }

  columns = [
    {
      title: '序号',
      key: 'merchantOrderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '域名备案日期',
      key: 'merchantOrderCol-1',
      dataIndex: 'recordDate'
    },
    {
      title: '销售大区',
      key: 'merchantOrderCol-2',
      dataIndex: 'name'
    },
    {
      title: '商户名称',
      key: 'merchantOrderCol-3',
      dataIndex: 'merchantName'
    },
    {
      title: '累计总订单数',
      key: 'merchantOrderCol-4',
      dataIndex: 'totalNumbers'
    },
    {
      title: '年度累计',
      key: 'merchantOrderCol-5',
      dataIndex: 'yearNumbers'
    },
    {
      title: '一月',
      key: 'merchantOrderCol-6',
      dataIndex: 'Jan'
    },
    {
      title: '二月',
      key: 'merchantOrderCol-7',
      dataIndex: 'Feb'
    },
    {
      title: '三月',
      key: 'merchantOrderCol-8',
      dataIndex: 'Mar'
    },
    {
      title: '四月',
      key: 'merchantOrderCol-9',
      dataIndex: 'Apr'
    },
    {
      title: '五月',
      key: 'merchantOrderCol-10',
      dataIndex: 'May'
    },
    {
      title: '六月',
      key: 'merchantOrderCol-11',
      dataIndex: 'Jun'
    },
    {
      title: '七月',
      key: 'merchantOrderCol-12',
      dataIndex: 'Jul'
    },
    {
      title: '八月',
      key: 'merchantOrderCol-13',
      dataIndex: 'Aug'
    },
    {
      title: '九月',
      key: 'merchantOrderCol-14',
      dataIndex: 'Sep'
    },
    {
      title: '十月',
      key: 'merchantOrderCol-15',
      dataIndex: 'Oct'
    },
    {
      title: '十一月',
      key: 'merchantOrderCol-16',
      dataIndex: 'Nov'
    },
    {
      title: '十二月',
      key: 'merchantOrderCol-17',
      dataIndex: 'Dec'
    }
  ]

  componentDidMount () {}

  // 点击导出数据
  handleDownload = () => {
    const { tableData = [] } = this.props
    let titleArr = $lodash.map(this.columns, (item) => { return item.title })
    let result = [titleArr]
    tableData.forEach((item, i) => {
      let rowArr = []
      rowArr.push(i + 1, item.recordDate, item.name, item.merchantName, item.totalNumbers, item.yearNumbers,
        item.Jan, item.Feb, item.Mar, item.Apr, item.May, item.Jun, item.Jul, item.Aug, item.Sep, item.Oct, item.Nov, item.Dec)

      result.push(rowArr)
    })

    downloadExcel(result, '商户订单统计表.xlsx')
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    })
  }

  render () {
    const { tableData = [] } = this.props
    const { pages } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共${tableData.length}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <Row style={{ marginTop: '20px' }}>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Button type='primary' disabled={tableData.length === 0} onClick={() => this.handleDownload()}>
              <Icon type='download' />导出数据
            </Button>
          </Row>
          <Table rowKey={(record, index) => `merchantOrdersCol-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </Row>
    )
  }
}
