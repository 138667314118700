import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Table } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class SupplyModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'cloudtradeDetail-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '采购单号',
      key: 'cloudtradeDetail-1',
      dataIndex: 'purchaseOrderId'
    },
    {
      title: 'RS供应商',
      key: 'cloudtradeDetail-2',
      dataIndex: 'distributorName'
    },
    {
      title: '采购单价',
      key: 'cloudtradeDetail-3',
      dataIndex: 'purchasePrice',
      render: (text, record, index) => (
        <>&yen;{+record.purchasePrice}</>
      )
    },
    {
      title: '采购数量',
      key: 'cloudtradeDetail-4',
      dataIndex: 'goodsAmount'
    },
    {
      title: '采购总价',
      key: 'cloudtradeDetail-5',
      render: (text, record, index) => (
        <div>
          &yen;{(+record.purchaseTotalPrice * 100 / 100).toFixed(2)}
        </div>
      )
    },
    {
      title: '单品利润',
      key: 'cloudtradeDetail-6',
      render: (text, record, index) => (
        <div>
          {(+record.singleProfitRate * 100 * 100 / 100).toFixed(2)}%
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getSupplyDetailInOrderSku()
  }

  // 获取订单商品采购明细
  getSupplyDetailInOrderSku = () => {
    const { detailInfo } = this.props
    const { pages } = this.state

    let params = {
      commoditySkuId: detailInfo.commoditySkuId,
      orderId: detailInfo.orderId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSupplyDetailInOrderSku(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.purchaseOrderGoodsInfo.list || [],
          pages: {
            ...pages,
            total: data.purchaseOrderGoodsInfo.total || 0
          }
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyDetailInOrderSku()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyDetailInOrderSku()
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { pages, tableData = [] } = this.state

    const pagination = {
      size: 'small',
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Modal title='采购明细' width={1000} bodyStyle={{ padding: '15px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.props.onCancel}
        >
          <Row gutter={12} style={{ marginBottom: '10px' }}>
            <Col span={8}>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <label style={{ marginRight: '3px' }}>品牌:</label>
                <span>{detailInfo.brandName}</span>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <label style={{ marginRight: '3px' }}>型号:</label>
                <span>{detailInfo.model}</span>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <label style={{ marginRight: '3px' }}>订货号:</label>
                <span>{detailInfo.itemNumber}</span>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <label style={{ marginRight: '3px' }}>供应数量:</label>
                <span>{detailInfo.goodsAmount}</span>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <label style={{ marginRight: '3px' }}>供应单价:</label>
                <span>&yen;{+detailInfo.supplyPrice}</span>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <label style={{ marginRight: '3px' }}>供应总价:</label>
                <span>&yen;{(+detailInfo.supplyTotalPrice * 100 / 100).toFixed(2)}</span>
              </div>
            </Col>
          </Row>

          <Row>
            <Table rowKey='purchaseOrderId' bordered
              dataSource={tableData}
              columns={this.columns}
              pagination={pagination}
              scroll={{ y: 400 }}
            />
          </Row>
        </Modal>
      </>
    )
  }
}
