import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Row, Col, Card, Button, Icon, Divider, Table, message, Form, Input,
  Upload, Radio, Modal
} from 'antd'

import $lodash from 'lodash'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import SetproductModal from '../component/setprodcut-modal'

import styles from './cover.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

const FormItem = Form.Item

class BrandcoverForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    detailInfo: {},
    info: {},
    type: 1, // 1-新增封面 2-编辑封面
    setType: 1, // 1-普通产品 2-推荐产品
    coverImage: '', // 封面图片
    setproductModal: false,
    commoditySkuList: [], // 产品列表
    recommendSkuList: [] // 推荐产品
  }
  columns = [
    {
      title: '序号',
      key: 'selectedSku-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'selectedSku-1',
      width: 150,
      render: (text, record, index) => (
        <div>
          <div className={`brandLabel nameCn`}>{record.brandName}</div>
          <div className={`brandLabel nameEn`}>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '型号',
      key: 'selectedSku-2',
      width: 150,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'selectedSku-3',
      ellipsis: true,
      dataIndex: 'itemNumber',
      width: 150
    },
    {
      title: '产品',
      key: 'selectedSku-4',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '价格',
      key: 'selectedSku-5',
      render: (text, record, index) => (
        <div>
          <div>表价：{+record.price}</div>
          <div>默认价：{this.renderCalcDefaultPrice(record)}</div>
        </div>
      )
    },
    {
      title: '操作',
      key: 'selectedSku-6',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.removeSkuByList(index, 1)}>删除</a>
        </div>
      )
    }
  ]

  recomColumns = [
    {
      title: '序号',
      key: 'recommendSku-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'recommendSku-1',
      width: 150,
      render: (text, record, index) => (
        <div>
          <div className={`brandLabel nameCn`}>{record.brandName}</div>
          <div className={`brandLabel nameEn`}>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '型号',
      key: 'recommendSku-2',
      width: 150,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'recommendSku-3',
      ellipsis: true,
      dataIndex: 'itemNumber',
      width: 150
    },
    {
      title: '产品',
      key: 'recommendSku-4',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '价格',
      key: 'recommendSku-5',
      render: (text, record, index) => (
        <div>
          <div>表价：{+record.price}</div>
          <div>默认价：{this.renderCalcDefaultPrice(record)}</div>
        </div>
      )
    },
    {
      title: '操作',
      key: 'recommendSku-6',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.removeSkuByList(index, 2)}>删除</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    const { state } = this.props.history.location

    if (state && state.detailInfo) {
      this.setState({
        detailInfo: state.detailInfo,
        type: state.detailInfo.brandModelRegionExist ? 2 : 1
      }, () => {
        const { type = 1 } = this.state
        if (type === 2) {
          this.getBrandCoverDetail()
        }
      })
    }
  }

  // 获取品牌封面信息
  getBrandCoverDetail = () => {
    const { detailInfo = {} } = this.state
    Api.getBrandCoverDetail({ brandId: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {},
          coverImage: data.imageKey || '',
          commoditySkuList: data.commonSkuInfoList || [],
          recommendSkuList: data.recommendSkuInfoList || []
        })
      }
    })
  }

  // 保存品牌封面设置
  saveSetBrandCoverDetail = (params) => {
    Api.saveSetBrandCoverDetail(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.countDown()
      }
    })
  }

  // 点击保存设置
  handleSaveSet = () => {
    const { detailInfo = {}, type = 1, info = {}, commoditySkuList = [], recommendSkuList = [],
      coverImage = '' } = this.state

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let _arr1 = []
        let _arr2 = []

        if (coverImage === '') {
          return message.warning('请上传品牌封面图片')
        }

        if (commoditySkuList.length === 0) {
          return message.warning('请为该品牌封面设置产品！')
        }

        if (commoditySkuList.length > 0) {
          commoditySkuList.map(item => {
            _arr1.push({ commoditySkuId: item.commoditySkuId })
          })
        }

        if (recommendSkuList.length > 0) {
          recommendSkuList.map(ritem => {
            _arr2.push({ commoditySkuId: ritem.commoditySkuId })
          })
        }

        let params = {
          ...values,
          imageKey: coverImage,
          brandId: detailInfo.id,
          commonSkuList: _arr1,
          recommendSkuList: _arr2
        }

        if (type === 2) {
          params.id = info.id
        }

        this.saveSetBrandCoverDetail(params)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过 ${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  changeUploadBannerImage = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.setState({
          coverImage: data.imageUrl
        })
      } else {
        message.error('上传失败')
      }
    }
  }

  // 前端删处图片
  removeImg = () => {
    this.setState({
      coverImage: ''
    })
  }

  // 点击关联产品
  handleShowProductModal = (type) => {
    this.setState({
      setType: type,
      setproductModal: true
    })
  }

  // 点击确定产品
  handleSetProduct = (params) => {
    let { setType = 1, commoditySkuList = [], recommendSkuList = [] } = this.state

    if (setType === 1) {
      this.setState({
        commoditySkuList: $lodash.concat(commoditySkuList, params.selectedProdList),
        setType: 1,
        setproductModal: false
      })
    } else {
      this.setState({
        recommendSkuList: $lodash.concat(recommendSkuList, params.selectedProdList),
        setType: 1,
        setproductModal: false
      })
    }
  }

  // 前端删除产品
  removeSkuByList = (i, type) => {
    let { commoditySkuList = [], recommendSkuList = [] } = this.state
    if (type === 1) {
      commoditySkuList.splice(i, 1)
    } else {
      recommendSkuList.splice(i, 1)
    }

    this.setState({
      commoditySkuList,
      recommendSkuList
    })
  }

  // 点击返回
  handleBack = () => {
    this.props.history.push({ pathname: '/admin/brand' })
  }

  // 计算默认价格
  renderCalcDefaultPrice = (record = {}) => {
    const { price, defaultPrice } = record
    let num = 0
    let discount = +defaultPrice > 0 ? +defaultPrice : 1

    if (+price > 0) {
      num = (price * discount).toFixed(2)
    }

    return num
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '品牌封面设置成功！',
      content: `${secondsToGo} s后将返回列表页面`,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.handleBack()
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `${secondsToGo} s后将返回列表页面`
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.handleBack()
    }, secondsToGo * 1000)
  }

  closeModal = () => {
    this.setState({
      setType: 1,
      setproductModal: false
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const { detailInfo = {}, commoditySkuList = [], recommendSkuList = [],
      setproductModal = false, info = {}, coverImage = '' } = this.state

    if (commoditySkuList.length > 0) {
      commoditySkuList.map((item, i) => {
        item.rowkey = `commoditySku-${i}`
      })
    }

    if (recommendSkuList.length > 0) {
      recommendSkuList.map((item, i) => {
        item.rowkey = `recommendSku-${i}`
      })
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={this.handleBack}><Icon type='left' />返回列表</Button>
        </Row>

        <Row>
          <Card
            title={`品牌：${detailInfo.name}${detailInfo.englishBrandName &&
              detailInfo.englishBrandName !== '' ? `(${detailInfo.englishBrandName})` : ''}`}
            bodyStyle={{ padding: '12px' }}
          >
            <Row>
              <Form>
                <Row gutter={24}>
                  <Col span={8}>
                    <FormItem label='封面名称'>
                      {
                        getFieldDecorator('name', {
                          initialValue: info.name || detailInfo.name,
                          rules: [
                            { required: true, message: '请输入封面名称' }
                          ]
                        })(
                          <Input placeholder='请输入...' autoComplete='off' />
                        )
                      }
                    </FormItem>
                  </Col>

                  <Col span={8}>
                    <FormItem label='启用状态'>
                      {
                        getFieldDecorator('enable', {
                          initialValue: info.enable || 'ENABLE',
                          rules: [
                            { required: true, message: '请选择模板启用状态' }
                          ]
                        })(
                          <Radio.Group>
                            <Radio value={'ENABLE'}>启用</Radio>
                            <Radio value={'DISENABLE'}>禁用</Radio>
                          </Radio.Group>
                        )
                      }
                    </FormItem>
                  </Col>

                  <Col span={8}>
                    <label className={`formLabel required`}>图片</label>
                    <span>{`图片尺寸 400px * 400px, 大小不超过${IMG_LT2M}MB`}</span>
                    {

                      <Row>
                        <div className={`${styles.inline}`} key={`uploadBanner`}>
                          {
                            coverImage !== ''
                              ? (
                                <div className={styles.thumbWrap}>
                                  <div className={styles.thumbInfo}>
                                    <img src={coverImage} />

                                    <a className={styles.prewModal}>
                                      <Icon type='delete' style={{ fontSize: '20px' }}
                                        onClick={() => this.removeImg()}
                                      />
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <Upload
                                  showUploadList={false}
                                  listType='picture-card'
                                  accept='image/png, image/jpg, image/jpeg'
                                  headers={{ Token: getStore('token') }}
                                  action={uploadOrdinaryImageUrl}
                                  beforeUpload={this.beforeUpload}
                                  onChange={(info) => this.changeUploadBannerImage(info)}
                                >
                                  <Icon type='plus' />
                                </Upload>

                              )
                          }
                        </div>
                      </Row>
                    }
                  </Col>
                </Row>
              </Form>
            </Row>

            <Row>
              <Divider orientation='left' dashed>关联的产品</Divider>
              <Row>
                <Button type='primary' size='small'
                  onClick={() => this.handleShowProductModal(1)}
                >
                  添加产品
                </Button>
              </Row>

              <Row>
                <Table rowKey='rowkey'
                  dataSource={commoditySkuList}
                  columns={this.columns}
                  pagination={false}
                />
              </Row>
            </Row>

            <Row>
              <Button type='primary' onClick={this.handleSaveSet}>
                保存
              </Button>
            </Row>
          </Card>
        </Row>

        {
          setproductModal
            ? <SetproductModal
              detailInfo={detailInfo}
              disableList={$lodash.concat(commoditySkuList, recommendSkuList)}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleSetProduct(params)}
            />
            : null
        }
      </div>
    )
  }
}

const BrandCover = Form.create()(BrandcoverForm)

export default BrandCover
