import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Card, Input, DatePicker, Button, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const FormItem = Form.Item
const { TextArea } = Input
const { REMARKS_MAX_NUM } = consts

class CreateEnquireInformarionForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object,
    userInfo: PropTypes.object
  }
  state = {
    info: {},
    type: 'ADD'
  }

  componentDidMount () {
    const { state } = this.props.history.location
    if (!state || !state.type) {
      return message.warning('页面参数不合法')
    }

    this.setState({
      info: state.detailInfo || {},
      type: state.type
    })
  }

  // 保存新增发布询价信息
  saveCreateEnquireInformationPublish = async (params) => {
    const res = await Api.saveCreateEnquireInformationPublish(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.props.history.push({ pathname: '/admin/enquireInformationList' })
    }
  }

  // 保存编辑发布询价信息
  saveEditEnquireInformationPublish = async (params) => {
    const res = await Api.saveEditEnquireInformationPublish(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.props.history.push({ pathname: '/admin/enquireInformationList' })
    }
  }

  // 点击发布
  handleSubmit = () => {
    const { form } = this.props
    const { info = {}, type = 'ADD' } = this.state

    form.validateFields((err, values) => {
      if (!err) {
        const { deadline, contactsUserName, contactsMobile } = values
        if ($lodash.trim(contactsUserName).length === 0) { return message.warning(`请输入联系人`) }

        if ($lodash.trim(contactsMobile).length === 0) { return message.warning(`请输入联系方式`) }

        values.deadline = moment(deadline).format('YYYY-MM-DD HH:mm:ss')
        values.contactsUserName = $lodash.trim(contactsUserName)
        values.contactsMobile = $lodash.trim(contactsMobile)

        if (type === 'ADD') {
          this.saveCreateEnquireInformationPublish(values)
        } else {
          values.id = info.id
          this.saveEditEnquireInformationPublish(values)
        }
      }
    })
  }

  // 点击取消
  handleCancel = () => {
    this.props.history.push({ pathname: '/admin/enquireInformationList' })
  }

  range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days')
  }

  disabledDateTime = (dates) => {
    let hours = moment().hours() // 0~23
    let minutes = moment().minutes() // 0~59
    let seconds = moment().seconds() // 0~59

    if (dates && moment(dates).date() === moment().date()) {
      return {
        disabledHours: () => this.range(0, hours),
        disabledMinutes: () => this.range(0, minutes),
        disabledSeconds: () => this.range(0, seconds)
      }
    }
  }

  render () {
    const { form, userInfo = {} } = this.props
    const { getFieldDecorator } = form
    const { info = {}, type = 'ADD' } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <Form style={{ maxWidth: '600px', margin: '0px auto' }}>
            <FormItem label='询价内容'>
              {
                getFieldDecorator('enquiryContent', {
                  initialValue: type === 'EDIT' ? info.enquiryContent : null,
                  rules: [
                    { required: true, message: '请输入询价内容' }
                  ]
                })(<TextArea rows={6} maxLength={REMARKS_MAX_NUM} placeholder={`请输入内容，不超过${REMARKS_MAX_NUM}字...`} autoComplete='off' />)
              }
            </FormItem>

            <FormItem label='联系人'>
              {
                getFieldDecorator('contactsUserName', {
                  initialValue: type === 'EDIT' ? info.contactsUserName : (userInfo && userInfo.userName ? `${userInfo.userName.charAt(0)}经理` : null),
                  rules: [
                    { required: true, message: '请输入联系人' }
                  ],
                  getValueFromEvent: (e) => { return $lodash.trim(e.target.value) }
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='联系方式'>
              {
                getFieldDecorator('contactsMobile', {
                  initialValue: type === 'EDIT' ? info.contactsMobile : (userInfo && userInfo.mobile ? userInfo.mobile : null),
                  rules: [
                    { required: true, message: '请输入联系方式' }
                  ],
                  getValueFromEvent: (e) => { return $lodash.trim(e.target.value) }
                })(<Input placeholder='请输入...' autoComplete='off' />)
              }
            </FormItem>

            <FormItem label='截止时间'>
              {
                getFieldDecorator('deadline', {
                  initialValue: type === 'EDIT' ? moment(info.deadline) : null,
                  rules: [
                    { required: true, message: '请选择截止时间' }
                  ]
                })(
                  <DatePicker placeholder='请选择...'
                    disabledDate={this.disabledDate}
                    disabledTime={this.disabledDateTime}
                    showTime
                  />
                )
              }
            </FormItem>
            <FormItem>
              <Button type='primary' onClick={() => this.handleSubmit()}>发布</Button>
              <Button style={{ marginLeft: '24px' }} onClick={() => this.handleCancel()}>取消</Button>
            </FormItem>
          </Form>
        </Card>
      </>
    )
  }
}

const CreateEnquireInformarion = Form.create()(CreateEnquireInformarionForm)

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

export default connect(mapStateToProps)(CreateEnquireInformarion)
