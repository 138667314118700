import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Input, Button } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input

class PayMethodForm extends Component {
  static propTypes = {
    form: PropTypes.object
  }

  render () {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        <Card title='编辑支付方式'>
          <Form>
            <FormItem label='支付方式名称'>
              {
                getFieldDecorator('payMethodName', {
                  rules: [
                    { required: true, message: '该项必填' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='支付方式描述'>
              {
                getFieldDecorator('payMethodDesc', {
                })(
                  <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem>
              <Button type='primary'>提交</Button>
            </FormItem>
          </Form>
        </Card>
      </>
    )
  }
}

const SetPayMethod = Form.create()(PayMethodForm)

export default SetPayMethod
