import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Empty, message, Button, Icon } from 'antd'

import Api from '@/common/api/index'

import FloorTpl1 from './floor1Tpl'
import FloorTpl2 from './floor2Tpl'
import FloorTpl3 from './floor3Tpl'
import FloorTpl4 from './floor4Tpl'
import FloorTpl5 from './floor5Tpl'
import ModeModal from './modeModal'

export default class BannermallSet extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    advertBase: {}, // 广澳基本信息
    modeModal: false, // 模板方式弹窗
    type: 1,
    rowDetail: {},
    floorList: [] // 二级页面容器列表
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      advertBase: state.detailInfo || {}
    }, () => {
      this.getAdvertDetailInBanner()
    })
  }

  // 获取banner广告二级页面详情
  getAdvertDetailInBanner = () => {
    const { advertBase = {} } = this.state
    Api.getAdvertDetailInBanner({ advertisingId: advertBase.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          floorList: data || []
        })
      }
    })
  }

  // 创建banner广告页面容器
  saveAdvertContainerAddInBanner = (params) => {
    Api.saveAdvertContainerAddInBanner(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getAdvertDetailInBanner()
      }
    })
  }

  // 编辑banner广告页面容器名称
  editAdvertContainerInBanner = (params) => {
    Api.editAdvertContainerInBanner(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getAdvertDetailInBanner()
      }
    })
  }

  // 删除banner广告容器
  deleteAdvertContainerInBanner = (params) => {
    Api.deleteAdvertContainerInBanner(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getAdvertDetailInBanner()
      }
    })
  }

  // 点击创建容器、编辑容器名称
  showModeModal = (record, type) => {
    this.setState({
      rowDetail: record,
      type: type,
      modeModal: true
    })
  }

  // 确定容器
  handleConfirmContainer = (params) => {
    const { type = 1, advertBase = {} } = this.state
    if (+type === 1) {
      this.saveAdvertContainerAddInBanner({ ...params, advertisingId: advertBase.id })
    } else {
      this.editAdvertContainerInBanner(params)
    }
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      modeModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  render () {
    const { modeModal = false, floorList = [] } = this.state

    return (
      <div>
        {
          floorList.length > 0
            ? <Card bodyStyle={{ padding: '12px' }}>
              {
                floorList.map((item, i) => (
                  <Row key={`mallsetFloor-${i}`}>
                    {
                      +item.type === 1
                        ? <Row style={{ marginBottom: '15px' }}>
                          <FloorTpl1
                            floorInfo={item}
                            onRefush={() => this.getAdvertDetailInBanner()}
                            onDelete={(params) => this.deleteAdvertContainerInBanner(params)}
                          />
                        </Row>
                        : null
                    }

                    {
                      +item.type === 2
                        ? <Row style={{ marginBottom: '15px' }}>
                          <FloorTpl2
                            floorInfo={item}
                            onRefush={() => this.getAdvertDetailInBanner()}
                            onDelete={(params) => this.deleteAdvertContainerInBanner(params)}
                          />
                        </Row>
                        : null
                    }

                    {
                      +item.type === 3
                        ? <Row style={{ marginBottom: '15px' }}>
                          <FloorTpl3
                            floorInfo={item}
                            onRefush={() => this.getAdvertDetailInBanner()}
                            onDelete={(params) => this.deleteAdvertContainerInBanner(params)}
                          />
                        </Row>
                        : null
                    }

                    {
                      +item.type === 4
                        ? <Row style={{ marginBottom: '15px' }}>
                          <FloorTpl4
                            floorInfo={item}
                            onRefush={() => this.getAdvertDetailInBanner()}
                            onDelete={(params) => this.deleteAdvertContainerInBanner(params)}
                          />
                        </Row>
                        : null
                    }

                    {
                      +item.type === 5
                        ? <Row style={{ marginBottom: '15px' }}>
                          <FloorTpl5
                            floorInfo={item}
                            onRefush={() => this.getAdvertDetailInBanner()}
                            onDelete={(params) => this.deleteAdvertContainerInBanner(params)}
                          />
                        </Row>
                        : null
                    }

                  </Row>
                ))
              }

              <Row style={{ textAlign: 'center' }}>
                <Button type='dashed' style={{ width: '200px' }}
                  onClick={() => this.showModeModal({}, 1)}
                >
                  <Icon type='plus' />创建楼层
                </Button>
              </Row>
            </Card>
            : <Card bodyStyle={{ padding: '12px' }}>
              <Empty
                description={<span>Sorry~~ 暂无页面内容信息</span>}
              >
                <div>
                点击&nbsp;&nbsp;<a onClick={() => this.showModeModal({}, 1)}>创建楼层</a>&nbsp;&nbsp;来编辑你要装饰的内容吧~~
                </div>
              </Empty>
            </Card>
        }

        {
          modeModal
            ? <ModeModal
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleConfirmContainer(params)}
            />
            : null
        }
      </div>
    )
  }
}
