import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, InputNumber } from 'antd'

import consts from '@/common/utils/consts'

const FormItem = Form.Item
const { TextArea } = Input
const { REMARKS_MAX_NUM } = consts

class SeriesForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 类型: 1-新增 2-修改
    detailInfo: PropTypes.object,
    brandList: PropTypes.array,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    brandList: []
  }

  componentDidMount () {}

  // 点击确定
  handleConfirm = () => {
    const { type = 1, detailInfo = {}, form } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        if (+type > 1) {
          values.id = detailInfo.id
        }

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { form, type = 1, detailInfo = {}, onCancel } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <>
        <Modal title={type !== 2 ? '添加系列' : '编辑系列'}
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => onCancel()}
        >
          <Form layout='horizontal'>
            <FormItem style={{ display: 'none' }}>
              {
                getFieldDecorator('brandId', {
                  initialValue: detailInfo.brandId
                })(<InputNumber disabled autoComplete='off' />)
              }
            </FormItem>
            <FormItem style={{ display: 'none' }}>
              {
                getFieldDecorator('brandName', {
                  initialValue: detailInfo.brandName
                })(<Input disabled autoComplete='off' />)
              }
            </FormItem>
            <FormItem label='品牌名称' {...formItemLayout} style={{ marginBottom: '8px' }}>
              { detailInfo.brandName }
            </FormItem>

            <FormItem label='系列名称' {...formItemLayout}>
              {
                getFieldDecorator('name', {
                  initialValue: detailInfo.name || '',
                  rules: [
                    { required: true, message: '请输入系列名称!' }
                  ]
                })(<Input placeholder='请输入...' autoComplete='off' />)
              }
            </FormItem>
            <FormItem label='描述' {...formItemLayout}>
              {
                getFieldDecorator('desc', {
                  initialValue: detailInfo.desc || ''
                })(<TextArea rows={4} placeholder={`请输入内容，不超过${REMARKS_MAX_NUM}字...`} autoComplete='off' maxLength={200} />)
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const SeriesModal = Form.create()(SeriesForm)

export default SeriesModal
