import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Col, Button, Radio, Icon } from 'antd'
import $lodash from 'lodash'

import SkuBaseUploadImage from '../../component/skuBaseUploadImage/index'

const implementMaps = [
  { id: 'DATA_MODIFY', name: '覆盖' },
  { id: 'DATA_INSERT', name: '新增' }
]

class UploadSkuImageForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    getImageDetails: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    showPage: false,
    skuImageUrls: [],
    skuDetailImageUrls: [],
    skuDetailPdfUrls: []
  }

  componentDidMount () {
    this.getDefaultImageDetails()
  }

  // 获取sku图片详情
  getDefaultImageDetails = async () => {
    const { form, detailInfo = {}, getImageDetails } = this.props

    const res = await getImageDetails({ commoditySkuId: detailInfo.commoditySkuId })
    const { code, data = {} } = res
    if (+code === 10000) {
      const { skuImageList = [], skuDetailImageList = [], skuDetailPdfList = [] } = data
      let skuImgUrlStr = ''
      let defaultSetSkuImgStr = ''
      let skuDetailImgUrlStr = ''
      let defaultSetDetailImgStr = ''
      let skuPDFUrlStr = ''
      let defaultPDFStr = ''

      if (skuImageList && skuImageList.length > 0) {
        const skuImgUrlArr = []
        const defaultSetSkuImgArr = []

        $lodash.forEach(skuImageList, (record) => {
          skuImgUrlArr.push(record.imageUrl)

          if (record.mainType === 'MAIN') {
            defaultSetSkuImgArr.push(record.imageUrl)
          }
        })

        skuImgUrlStr = skuImgUrlArr.length > 0 ? skuImgUrlArr.join(',') : ''
        defaultSetSkuImgStr = defaultSetSkuImgArr.length > 0 ? defaultSetSkuImgArr.join(',') : ''
      }

      if (skuDetailImageList && skuDetailImageList.length > 0) {
        const skuDetailImgUrlArr = []
        const defaultSetDetailImgArr = []

        $lodash.forEach(skuDetailImageList, (record) => {
          skuDetailImgUrlArr.push(record.imageUrl)

          if (record.showStatus === 'SHOW') {
            defaultSetDetailImgArr.push(record.imageUrl)
          }
        })

        skuDetailImgUrlStr = skuDetailImgUrlArr.length > 0 ? skuDetailImgUrlArr.join(',') : ''
        defaultSetDetailImgStr = defaultSetDetailImgArr.length > 0 ? defaultSetDetailImgArr.join(',') : ''
      }

      if (skuDetailPdfList && skuDetailPdfList.length > 0) {
        const skuPDFImgUrlArr = []
        const defaultSetPDFImgArr = []

        $lodash.forEach(skuDetailPdfList, (record) => {
          skuPDFImgUrlArr.push(record.imageUrl)

          if (record.showStatus === 'SHOW') {
            defaultSetPDFImgArr.push(record.imageUrl)
          }
        })

        skuPDFUrlStr = skuPDFImgUrlArr.length > 0 ? skuPDFImgUrlArr.join(',') : ''
        defaultPDFStr = defaultSetPDFImgArr.length > 0 ? defaultSetPDFImgArr.join(',') : ''
      }

      this.setState({
        skuImageUrls: skuImgUrlStr.length > 0 ? skuImgUrlStr.split(',') : [],
        skuDetailImageUrls: skuDetailImgUrlStr.length > 0 ? skuDetailImgUrlStr.split(',') : [],
        skuDetailPdfUrls: skuPDFUrlStr.length > 0 ? skuPDFUrlStr.split(',') : [],
        showPage: true
      }, () => {
        form.setFieldsValue({
          skuImageUrls: skuImgUrlStr.length > 0 ? skuImgUrlStr : undefined,
          checkedskuImageUrls: defaultSetSkuImgStr.length > 0 ? defaultSetSkuImgStr : undefined,

          skuDetailImageUrls: skuDetailImgUrlStr.length > 0 ? skuDetailImgUrlStr : undefined,
          checkedskuDetailImageUrls: defaultSetDetailImgStr.length > 0 ? defaultSetDetailImgStr : undefined,

          skuDetailPdfUrls: skuPDFUrlStr.length > 0 ? skuPDFUrlStr : undefined,
          checkedskuDetailPdfUrls: defaultPDFStr.length > 0 ? defaultPDFStr : undefined
        })
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    const { form, detailInfo = {}, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const { operationType, skuImageUrls, checkedskuImageUrls, skuDetailImageUrls, checkedskuDetailImageUrls, skuDetailPdfUrls, checkedskuDetailPdfUrls } = values

        let params = {
          operationType: operationType,
          uploadImageModifyType: 'CURRENT_PAGE',
          skuIdList: [detailInfo.commoditySkuId],
          imageStatus: 'ENABLE',
          detailImageStatus: 'ENABLE',
          pdfImageStatus: 'ENABLE'
        }

        if (skuImageUrls && skuImageUrls !== '') {
          const _skuImageArr = skuImageUrls.split(',')
          const checkedskuImageArr = checkedskuImageUrls && checkedskuImageUrls !== '' ? checkedskuImageUrls.split(',') : []

          params.skuImageList = $lodash.map(_skuImageArr, (o, index) => {
            let obj = { imageUrl: o }

            if (checkedskuImageArr.length === 0) {
              obj.mainType = index === 0 ? 'MAIN' : 'NOT_MAIN'
            } else {
              const n = checkedskuImageArr.findIndex((item) => { return o === item })

              obj.mainType = n > -1 ? 'MAIN' : 'NOT_MAIN'
            }

            return obj
          })
        }

        if (skuDetailImageUrls && skuDetailImageUrls !== '') {
          const _skuDetailImageArr = skuDetailImageUrls.split(',')
          const checkedskuDetailImageArr = checkedskuDetailImageUrls && checkedskuDetailImageUrls !== '' ? checkedskuDetailImageUrls.split(',') : []

          params.skuDetailImageList = $lodash.map(_skuDetailImageArr, (o, index) => {
            let obj = { imageUrl: o }
            const j = checkedskuDetailImageArr.findIndex((item) => { return o === item })

            if (checkedskuDetailImageArr.length === 0) {
              obj.showStatus = index === 0 ? 'SHOW' : 'NOT_SHOW'
            } else {
              obj.showStatus = j > -1 ? 'SHOW' : 'NOT_SHOW'
            }

            return obj
          })
        }

        if (skuDetailPdfUrls && skuDetailPdfUrls !== '') {
          const _skuDetailPdfArr = skuDetailPdfUrls.split(',')
          const checkedDetailPdfArr = checkedskuDetailPdfUrls && checkedskuDetailPdfUrls !== '' ? checkedskuDetailPdfUrls.split(',') : []

          params.skuDetailPdfList = $lodash.map(_skuDetailPdfArr, (o, index) => {
            let obj = { imageUrl: o }
            const k = checkedDetailPdfArr.findIndex((item) => { return o === item })

            if (checkedDetailPdfArr.length === 0) {
              obj.showStatus = index === 0 ? 'SHOW' : 'NOT_SHOW'
            } else {
              obj.showStatus = k > -1 ? 'SHOW' : 'NOT_SHOW'
            }
            return obj
          })
        }

        onConfirm(params)
      }
    })
  }

  // 点击复用图片
  handleLinkToBindSkuImages = () => {
    const { skuImageUrls = [], skuDetailImageUrls = [], skuDetailPdfUrls = [] } = this.state
    this.props.history.push({ pathname: '/admin/bindSkuImages', state: { imageSource: 'COPY_IMAGES', skuImageUrls, skuDetailImageUrls, skuDetailPdfUrls } })
  }

  render () {
    const { form, onCancel } = this.props
    const { showPage = false, skuImageUrls = [], skuDetailImageUrls = [], skuDetailPdfUrls = [] } = this.state

    return (
      <>
        <Modal title='上传图片' width={1000} bodyStyle={{ padding: '16px', height: '560px', overflow: 'auto' }}
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => onCancel()}
        >
          <Form>
            {
              showPage
                ? <Row>
                  <Col span={21}>
                    <Form.Item style={{ marginBottom: '0px', display: 'none' }}>
                      {
                        form.getFieldDecorator('operationType', {
                          initialValue: 'DATA_MODIFY'
                        })(
                          <Radio.Group>
                            {
                              implementMaps.map((item, i) => (
                                <Radio value={item.id} key={`radio-${item.id}-${i}`}>{ item.name }</Radio>
                              ))
                            }
                          </Radio.Group>
                        )
                      }
                    </Form.Item>
                    <SkuBaseUploadImage form={form} hasSwitch={false}
                      defaultSkuImageList={skuImageUrls}
                      defaultDetailImageList={skuDetailImageUrls}
                      defaultPDFImageList={skuDetailPdfUrls}
                    />
                  </Col>
                  <Col span={3}>
                    <Form.Item style={{ marginBottom: '0px', textAlign: 'right' }}>
                      <Button type='link' size='small'
                        disabled={skuImageUrls.length === 0 && skuDetailImageUrls.length === 0 && skuDetailPdfUrls.length === 0}
                        onClick={() => this.handleLinkToBindSkuImages()}
                      >
                        复用图片<Icon style={{ marginLeft: '0px' }} type='double-right' />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                : null
            }
          </Form>
        </Modal>
      </>
    )
  }
}

const UploadSkuImageModal = Form.create()(UploadSkuImageForm)

export default UploadSkuImageModal
