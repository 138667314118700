import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Descriptions, Table, Select, Input, InputNumber, Row, Col, Upload, Button, Icon, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'
import ProductModal from '../productModal/index'
import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'

import filter from '@/common/utils/filter'
import { getStore, renderAddressLabel, renderSaledRefundTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const { uploadOrdinaryImageUrl } = UploadApi
const { saledTypeMaps, saledReasonTypeMaps } = filter
const { MAX_NUMBER, REMARKS_MAX_NUM, IMG_LT2M } = consts
const UPLOAD_MAX_NUM = 9

export default class DrawerContents extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onSelectedProduct: PropTypes.func, // 监听产品的选择
    onSelectedReplacementAddress: PropTypes.func // 收件地址选择
  }
  state = {
    orderInfo: {},
    receivingAddressList: [], // 平台收件地址列表
    tableData: [], // 选择的产品
    uploadImageUrls: [], // 上传的图片url
    addressModal: false, // 地址弹窗
    productModal: false // 产品弹窗
  }
  columns = [
    {
      title: '序号',
      key: 'productCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '型号',
      key: 'productCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'productCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'productCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'productCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'productCol-6',
      editable: true,
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'productCol-7',
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'productCol-8',
      dataIndex: '_totalPrice',
      render: (text, record, index) => (
        <>&yen;{($lodash.round(+record.quantity * +record.taxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    this.getAddressList()
    this.getPurchaseOrderSaledApplyInfo()
  }

  // 获取平台收货\发货地址列表
  getAddressList = async () => {
    const res = await Api.getAddressList({ adressType: 'PLATFORM_SENDER_ADDRESS', bindData: MAX_NUMBER })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        receivingAddressList: data || []
      })
    }
  }

  // 保存新增地址
  saveAddAddress = async (values) => {
    const res = await Api.saveAddAddress({ ...values, addressType: 'PLATFORM_SENDER_ADDRESS', bindData: MAX_NUMBER })
    const { code } = res
    if (+code === 10000) {
      this.closeModal()
      this.getAddressList()
    }
  }

  // 获取订单售后信息
  getPurchaseOrderSaledApplyInfo = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getPurchaseOrderSaledApplyInfo({ purchaseOrderId: detailInfo.purchaseOrderId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        orderInfo: data
      })
    }
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info = {}) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        const { uploadImageUrls = [] } = this.state

        uploadImageUrls.push(data.imageUrl)

        this.setState({
          uploadImageUrls: [...uploadImageUrls]
        }, () => {
          this.props.form.setFieldsValue({ uploadImageUrls: uploadImageUrls.join(',') })
        })
      } else {
        message.warning('上传失败')
      }
    }
  }

  // 删除图片
  handleRemoveUpload = (n) => {
    const { uploadImageUrls = [] } = this.state

    uploadImageUrls.splice(n, 1)

    this.setState({
      uploadImageUrls: [...uploadImageUrls]
    }, () => {
      this.props.form.setFieldsValue({ uploadImageUrls: uploadImageUrls.join(',') })
    })
  }

  // 点击选择售后商品
  showSaledProductModal = (bool = false) => {
    this.setState({
      productModal: bool
    })
  }

  // 确定售后产品
  confirmSelectedProduct = (list = []) => {
    this.setState({
      tableData: list,
      productModal: false
    }, () => {
      this.props.onSelectedProduct([...list])
    })
  }

  // 选择地址
  handleChangeReplacementAddress = (value) => {
    const { receivingAddressList = [] } = this.state
    const obj = receivingAddressList.find((item) => { return +value === +item.id })

    this.props.onSelectedReplacementAddress(obj)
  }

  // 新增地址
  showAddressModal = () => {
    this.setState({
      addressModal: true
    })
  }

  closeModal = () => {
    this.setState({
      addressModal: false
    })
  }

  render () {
    const { form, detailInfo } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { orderInfo = {}, receivingAddressList = [], tableData = [], uploadImageUrls = [], productModal = false, addressModal = false } = this.state

    return (
      <>
        <Descriptions>
          <Descriptions.Item label='订单编号'>{ orderInfo.orderId }</Descriptions.Item>
          <Descriptions.Item label='订单日期'>{ orderInfo.orderDate ? moment(orderInfo.orderDate).format('YYYY-MM-DD HH:mm:ss') : null }</Descriptions.Item>
          <Descriptions.Item label='供应商名称'>{ orderInfo.supplierName }</Descriptions.Item>
          <Descriptions.Item label='管理员姓名'>{ orderInfo.adminUserName }</Descriptions.Item>
          <Descriptions.Item label='管理员联系方式'>{ orderInfo.adminUserContact }</Descriptions.Item>
        </Descriptions>

        <div>
          <Button type='primary' style={{ marginBottom: '15px' }} onClick={() => this.showSaledProductModal(true)}>请选择售后商品</Button>

          <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${record._index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <Row>
            <Col span={12}>
              <div className={`${styles.formitemRow}`}>
                <FormItem label='售后类型'>
                  {
                    getFieldDecorator('afterSaleOrderType', {
                      rules: [
                        { required: true, message: '请选择售后类型' }
                      ]
                    })(
                      <Select placeholder='请选择...' >
                        {
                          saledTypeMaps.map((item, i) => (
                            <Option value={item.id} key={`saledTypeOption-${i}`}>{ item.name }</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
              </div>

              <div className={`${styles.formitemRow}`}>
                <FormItem label='售后原因'>
                  {
                    getFieldDecorator('reasonType', {
                      rules: [
                        { required: true, message: '请选择售后原因' }
                      ]
                    })(
                      <Select placeholder='请选择...'>
                        {
                          saledReasonTypeMaps.map((item, i) => (
                            <Option value={item.id} key={`reasonTypeOption-${i}`}>{ item.name }</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
              </div>

              <div className={`${styles.formitemRow}`}>
                <FormItem label='详细描述'>
                  {
                    getFieldDecorator('backRemark', {

                    })(
                      <TextArea rows={4}
                        placeholder={`请输入内容，不超过${REMARKS_MAX_NUM}字...`}
                        autoComplete='off'
                        maxLength={REMARKS_MAX_NUM}
                      />
                    )
                  }
                </FormItem>
              </div>

              {
                ['REFUND_RETURN_PRODUCTS', 'REFUND'].includes(getFieldValue('afterSaleOrderType'))
                  ? <>
                    <div className={`${styles.formitemRow}`}>
                      <FormItem style={{ display: 'none' }}>
                        {
                          getFieldDecorator('refundType', {
                            initialValue: 'ORIGINAL_PAYMENT_TYPE'
                          })(<Input disabled autoComplete='off' />)
                        }
                      </FormItem>
                      <FormItem label='退款方式'>
                        { renderSaledRefundTypeLabel(getFieldValue('refundType')) }
                      </FormItem>
                    </div>

                    <div className={`${styles.formitemRow}`}>
                      <FormItem label='预估退款金额' style={{ display: 'none' }}>
                        {
                          getFieldDecorator('backAmount', {
                            initialValue: $lodash.sumBy(tableData, (o) => { return $lodash.round(+o.quantity * o.taxUnitPrice, 2) })
                          })(<InputNumber disabled autoComplete='off' />)
                        }
                      </FormItem>
                      <FormItem label='预估退款金额'>
                        &yen;{ ($lodash.sumBy(tableData, (o) => { return $lodash.round(o.quantity * o.taxUnitPrice, 2) }) * 100 / 100).toFixed(2) }
                      </FormItem>
                    </div>
                  </>
                  : null
              }
            </Col>
          </Row>

          <Row>
            <FormItem label='可添加图片来帮助售后人员判断(最多9张)' style={{ marginBottom: '0px' }}>
              <FormItem style={{ display: 'none' }}>
                {
                  getFieldDecorator('uploadImageUrls', {

                  })(<Input disabled autoComplete='off' />)
                }
              </FormItem>
              {
                uploadImageUrls.length > 0
                  ? <>
                    {
                      uploadImageUrls.map((item, n) => (
                        <div className={`${styles.pictureWrap}`} key={`pictureCol-${n}`}>
                          <UploadThumbnail imageUrl={item} onDelete={() => this.handleRemoveUpload(n)} />
                        </div>
                      ))
                    }
                  </>
                  : null
              }

              {
                uploadImageUrls.length < UPLOAD_MAX_NUM
                  ? <div className={`${styles.pictureWrap}`}>
                    <Upload name='file'
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      showUploadList={false}
                      action={uploadOrdinaryImageUrl}
                      headers={{ Token: getStore('token') }}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <div>
                        <Icon type='plus' />
                        <div>点击上传</div>
                      </div>
                    </Upload>
                  </div>
                  : null
              }
            </FormItem>
          </Row>

          {
            ['CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(getFieldValue('afterSaleOrderType'))
              ? <Row>
                <Col span={16}>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='收件地址'>
                      {
                        getFieldDecorator('replacementAddressId', {
                          rules: [
                            { required: true, message: '请选择收件地址' }
                          ]
                        })(
                          <Select onChange={(value) => this.handleChangeReplacementAddress(value)}>
                            {
                              receivingAddressList.map((item, i) => (
                                <Option value={item.id} key={`replacementAddressOpt-${item.id}`}>
                                  { renderAddressLabel(item.province, item.city, item.county, item.address) } { item.name } { item.mobile }
                                </Option>
                              ))
                            }
                          </Select>
                        )
                      }
                    </FormItem>
                  </div>
                </Col>
                <Col span={4}>
                  <FormItem>
                    <Button type='link' size='small' onClick={() => this.showAddressModal()}>新增地址</Button>
                  </FormItem>
                </Col>
              </Row>
              : null
          }
        </div>

        {
          productModal
            ? <ProductModal
              detailInfo={detailInfo}
              onCancel={() => this.showSaledProductModal(false)}
              onConfirm={(list) => this.confirmSelectedProduct(list)}
            />
            : null
        }

        {
          addressModal
            ? <AddressModal
              type={1}
              onConfirm={(values) => this.saveAddAddress(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
