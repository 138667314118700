import Ajax from '../utils/ajax'

export default {
  // 获取采购入库单列表
  getWarehouseEntryBillList (params) {
    return Ajax.post('/oms/platform/cloud/purchase/order/page', params)
  },

  // 获取销售出库单列表
  getWarehouseOutBillList (params) {
    return Ajax.post('/oms/platform/cloud/sale/order/page', params)
  }
}
