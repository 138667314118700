import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './express.module.scss'

export default class InvoiceTitle extends Component {
  static propTypes = {
    info: PropTypes.object
  }

  render () {
    const { info = {} } = this.props

    return (
      <table className={`${styles.titleTable}`}>
        <tbody>
          <tr>
            <td className={`${styles.titleLabel}`}>发票抬头</td>
            <td className={`${styles.titleContainer}`}>
              { info.title }
            </td>
          </tr>

          <tr>
            <td className={`${styles.titleLabel}`}>纳税人识别号</td>

            <td className={`${styles.titleContainer}`}>
              { info.taxIdentity }
            </td>
          </tr>

          <tr>
            <td className={`${styles.titleLabel}`}>单位地址、电话</td>

            <td className={`${styles.titleContainer}`}>
              <div>{ info.companyAddress }</div>

              <div>{ info.companyPhoneNumber }</div>
            </td>
          </tr>

          <tr>
            <td className={`${styles.titleLabel}`}>开户行及账号</td>

            <td className={`${styles.titleContainer}`}>
              <div>{ info.bank }</div>
              <div>
                {
                  info && info.bankAccount
                    ? (info.bankAccount).replace(/[ \f\t\v]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
                    : null
                }
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
