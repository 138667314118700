import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Table, Upload, Button, Icon, Modal, InputNumber, message, Popconfirm, Tooltip } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import ProductModal from './productModal/index'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { getStore } from '@/common/utils/mUtils'

const EditableContext = React.createContext()
const { uploadEnquireSetProductExcel } = UploadApi
const { downloadEnquireProductSetTemplate } = DownloadApi
const { confirm } = Modal

const { pageSizeOptions, repLineBreak, joinedCloudSupplyStatusMaps } = filter
const { MAX_NUMBER } = consts

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderEditCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: '0px' }}>
            {
              getFieldDecorator(dataIndex, {
                rules: [
                  { required: true, message: `请填写${title}!` }
                ],
                initialValue: dataIndex === 'discountRate' ? +record[dataIndex] * 100 : +record[dataIndex]
              })(
                <InputNumber min={0} max={dataIndex === 'discountRate' ? 100 : MAX_NUMBER} />
              )
            }
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderEditCell}</EditableContext.Consumer>
  }
}

class EnquireProductForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'itemModel',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号...'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    query: {},
    tableData: [],
    selectedRowKeys: [],
    editingKey: '',
    productModal: false,
    distributorId: null
  }

  columns = [
    {
      title: '序号',
      key: 'enquireProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'enquireProductCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'enquireProductCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'enquireProductCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品',
      key: 'enquireProductCol-4',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>{ record.name }</Tooltip>
        </>
      )
    },
    {
      title: '供应状态',
      key: 'enquireProductCol-5',
      width: 80,
      render: (text, record, index) => (
        <>{ this.renderCloudSupplyStatusLabel(record.cloudSupplyStatus) }</>
      )
    },
    {
      title: '供应价格',
      key: 'enquireProductCol-6',
      width: 120,
      dataIndex: 'cloudSupplyPrice',
      render: (text, record, index) => (
        <>&yen;{ +record.cloudSupplyPrice }</>
      )
    },
    {
      title: '最低价格',
      key: 'enquireProductCol-7',
      width: 120,
      editable: true,
      dataIndex: 'lowestPrice',
      render: (text, record, index) => (
        <>&yen;{ +record.lowestPrice }</>
      )
    },
    {
      title: '折扣',
      key: 'enquireProductCol-8',
      width: 120,
      editable: true,
      dataIndex: 'discountRate',
      render: (text, record, index) => (
        <>{ (+record.discountRate * 100 * 100 / 100).toFixed(2) }%</>
      )
    },
    {
      title: '操作',
      key: 'enquireProductCol-9',
      width: 120,
      render: (text, record, index) => {
        const { editingKey } = this.state
        const editable = this.isEditing(record)

        return editable ? (
          <>
            {
              <EditableContext.Consumer>
                {
                  form => (
                    <Button type='link' size='small' onClick={() => this.handleSave(form, record)}>保存</Button>
                  )
                }
              </EditableContext.Consumer>
            }

            <Popconfirm title='确定放弃编辑?' onConfirm={() => this.handleCancelEdit(index)}>
              <Button type='link' size='small'>取消</Button>
            </Popconfirm>
          </>
        ) : (
          <>
            <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleDelete([record.id])}>删除</Button>

            <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleEdit(record.id)}>编辑</Button>
          </>
        )
      }
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      distributorId: state.distributorId
    }, () => {
      this.getBrandListInSearch()
      this.getMerchantBindedSpecialProductList()
    })
  }

  // 获取品牌
  getBrandListInSearch = () => {
    Api.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: -1, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取商户绑定的特价产品列表
  getMerchantBindedSpecialProductList = async () => {
    const { pages, distributorId, query = {} } = this.state
    const res = await Api.getMerchantBindedSpecialProductList({ ...query, distributorId: distributorId, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存新增绑定产品
  saveBindedProductsToMerchant = async (params) => {
    const res = await Api.saveBindedProductsToMerchant(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      setTimeout(() => {
        this.closeModal()
        this.getMerchantBindedSpecialProductList()
      }, 1000)
    }
  }

  // 更新绑定的产品
  updateBindedProductsToMerchant = async (params) => {
    const res = await Api.updateBindedProductsToMerchant(params)
    const { code } = res

    if (+code === 10000) {
      message.success('操作成功')

      this.setState({
        editingKey: ''
      }, () => {
        this.getMerchantBindedSpecialProductList()
      })
    }
  }

  // 保存删除绑定的产品
  deleteBindedProductsToMerchant = async (params) => {
    const res = await Api.deleteBindedProductsToMerchant(params)
    const { code } = res

    if (+code === 10000) {
      message.success('操作成功')

      setTimeout(() => {
        this.setState({
          selectedRowKeys: []
        }, () => {
          this.getMerchantBindedSpecialProductList()
        })
      }, 1000)
    }
  }

  // 导入型号-监听上传EXCEL
  handleChangeUploadFile = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        const { distributorId } = this.state
        this.props.history.push({ pathname: '/admin/matchSheet', state: { importId: data, sourceType: 'ENQUIRE_SPECIAL_PRODUCTS', defaultParams: { distributorId: distributorId } } })
      }
    } else if (info.file.status === 'error') {
      message.error('上传失败')
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.itemNo) {
      search['itemNo'] = repLineBreak(search.itemNo, '<br>')
    }

    this.setState({
      query: search || {},
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getMerchantBindedSpecialProductList()
    })
  }

  // 点击选择
  handleSelectedRowKeys = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击批量删除
  handleDelete = (keys) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        const { distributorId } = this.state

        this.deleteBindedProductsToMerchant({ distributorId: distributorId, idList: keys })
      }
    })
  }

  // 点击添加型号
  showProductModal = () => {
    this.setState({
      productModal: true
    })
  }

  // 点击编辑价格、折扣
  handleEdit = (key) => {
    this.setState({
      editingKey: key
    })
  }

  // 点击取消编辑
  handleCancelEdit = () => {
    this.setState({
      editingKey: ''
    })
  }

  // 点击保存编辑
  handleSave = (form, record) => {
    form.validateFields((err, values) => {
      if (err) { return false }
      const { distributorId } = this.state
      const { discountRate } = values

      values.id = record.id
      values.distributorId = distributorId
      values.discountRate = discountRate * 100 / 100 / 100

      this.updateBindedProductsToMerchant(values)
    })
  }

  // 编辑中
  isEditing = (record) => record.id === this.state.editingKey

  // 渲染云仓供应状态
  renderCloudSupplyStatusLabel = (status) => {
    const obj = joinedCloudSupplyStatusMaps.find((o) => { return o.id === status })
    const label = obj !== undefined ? obj.name : ''

    return label
  }

  closeModal = () => {
    this.setState({
      productModal: false
    })
  }

  // 点击返回
  handleBack = () => {
    let url = sessionStorage.getItem('backPath') || '/admin/customerList'
    this.props.history.push({ pathname: url })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMerchantBindedSpecialProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMerchantBindedSpecialProductList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], selectedRowKeys = [], productModal = false, distributorId } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map(col => {
      if (!col.editable) { return col }

      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      }
    })

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: this.handleSelectedRowKeys
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}><Icon type='arrow-left' />返回</Button>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)} />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '15px' }}>
            <Button type='primary' disabled={selectedRowKeys.length === 0} onClick={() => this.handleDelete(selectedRowKeys)}><Icon type='delete' />批量删除</Button>

            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => downloadEnquireProductSetTemplate({})}><Icon type='download' />下载模板</Button>

            <Upload name='file' showUploadList={false}
              headers={{ Token: getStore('token') }}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              action={uploadEnquireSetProductExcel}
              data={{ distributorId: distributorId }}
              onChange={this.handleChangeUploadFile}
            >
              <Button type='primary' style={{ marginLeft: '10px' }}><Icon type='upload' />导入模板</Button>
            </Upload>

            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.showProductModal()}><Icon type='plus' />添加型号</Button>
          </div>

          <EditableContext.Provider value={this.props.form}>
            <Table rowKey='id' bordered
              components={components}
              columns={columns}
              dataSource={tableData}
              pagination={pagination}
              rowSelection={rowSelection}
            />
          </EditableContext.Provider>

        </Card>

        {
          productModal
            ? <ProductModal
              distributorId={distributorId}
              onConfirm={(params) => this.saveBindedProductsToMerchant(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const EnquireProductSet = Form.create()(EnquireProductForm)

export default EnquireProductSet
