import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Button, Icon, message } from 'antd'
import * as echarts from 'echarts'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import styles from './index.module.scss'

const descriptTextMaps = {
  'ENQUIRE_SPECIAL_PRODUCTS': { // 特价产品导入
    successText: '个型号设置成功',
    failText: '个型号设置失败',
    excelTemplateType: null,
    backButtonText: '返回特价产品设置',
    backLinkUrl: '/admin/enquireProductSet'
  },
  'ENQUIRE_PRODUCTS_UPLOAD': { // 询价产品导入搜索
    successText: '个型号匹配成功',
    failText: '个型号匹配成功',
    excelTemplateType: null,
    backButtonText: '返回新建询价单',
    backLinkUrl: '/admin/createEnquireOrder'
  },
  'UPLOAD_WORK_ORDER': { // 导入工单
    successText: '个工单设置成功',
    failText: '个工单设置失败',
    excelTemplateType: 'ENQUIRY_WORK_ORDER_IMPORT_RESULT',
    backButtonText: '返回工单中心',
    backLinkUrl: '/admin/workOrders'
  },
  'UPLOAD_SERIES': { // 导入系列
    successText: '个系列设置成功',
    failText: '个系列设置失败',
    excelTemplateType: 'BRAND_SERIES_IMPORT',
    backButtonText: '返回系列管理',
    backLinkUrl: '/admin/series'
  },
  'AUTO_QUOTATION_IMPORT': { // 询价导入价格
    successText: '个型号设置成功',
    failText: '个型号设置失败',
    excelTemplateType: 'ENQUIRY_WORK_ORDER_IMPORT_RESULT',
    backButtonText: '返回询价单列表',
    backLinkUrl: '/admin/enquireOrderList'
  }
}

export default class MatchResult extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    importId: null,
    sourceType: '',
    defaultParams: {},
    completeNum: 0,
    failNum: 0,
    successNum: 0,
    totalNum: 0
  }

  componentDidMount () {
    const { importId, sourceType = '', defaultParams = {} } = this.props.history.location.state

    this.setState({
      importId: importId,
      sourceType: sourceType,
      defaultParams: defaultParams
    }, () => {
      this.getExcelAnalysisMatchResult()
    })
  }

  // 获取EXCEL解析数据匹配结果
  getExcelAnalysisMatchResult = async () => {
    const { importId, defaultParams = {} } = this.state
    const res = await Api.getExcelAnalysisMatchResult({ importId: importId, matchType: 'MATCH', pageNo: 1, pageSize: 99999, ...defaultParams })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        completeNum: data.completeNum || 0,
        failNum: data.failNum || 0,
        successNum: data.successNum || 0,
        totalNum: data.totalNum || 0
      }, () => {
        if (+data.completeNum < +data.totalNum) {
          setTimeout(() => {
            this.getExcelAnalysisMatchResult()
          }, 3000)
        } else {
          this.initMatchProgressChart()
        }
      })
    }
  }

  // 匹配进度
  initMatchProgressChart = () => {
    const { successNum = 0, failNum = 0 } = this.state
    let progressChart = echarts.init(document.getElementById('progressEchart'))

    let options = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      color: ['#52c41a', '#f5222d'], // 环形颜色
      series: [
        {
          name: '完全匹配率',
          type: 'pie',
          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: +successNum, name: '匹配正确' },
            { value: +failNum, name: '匹配错误' }
          ]
        }
      ]
    }

    // 绘制图表
    progressChart.setOption(options)
  }

  // 导出方法集合
  downloadResultForNameFuncs = [
    [
      (name) => name === 'ENQUIRE_SPECIAL_PRODUCTS', // 特价产品导入
      (params) => DownloadApi.downloadEnquireSpecialMatchProducts(params)
    ],
    [
      (name) => name === 'ENQUIRE_PRODUCTS_UPLOAD', // 询价产品导入搜索
      (params) => DownloadApi.downloadUploadEnquirySearchProductsResult(params)
    ],
    [
      (name) => name === 'UPLOAD_WORK_ORDER', // 导入工单
      (params) => DownloadApi.downloadWorkOrderMatchResult(params)
    ],
    [
      (name) => name === 'UPLOAD_SERIES', // 导入系列
      (params) => DownloadApi.downloadSeriesUploadMatchResultExcel(params)
    ],
    [
      (name) => name === 'AUTO_QUOTATION_IMPORT', // 询价导入价格
      (params) => DownloadApi.downloadWorkOrderMatchResult(params)
    ]
  ]

  // 点击导出
  handleDownloadResult = () => {
    const { importId, sourceType } = this.state

    const getDownloadFunc = this.downloadResultForNameFuncs.find((item) => item[0](sourceType))

    const params = {
      importId: importId,
      excelTemplateType: descriptTextMaps[sourceType].excelTemplateType
    }

    getDownloadFunc ? getDownloadFunc[1](params) : message.warning('sourceType参数错误')
  }

  // 点击返回
  handleBack = () => {
    const { defaultParams = {}, sourceType } = this.state

    this.props.history.replace({ pathname: descriptTextMaps[sourceType].backLinkUrl, state: { ...defaultParams } })
  }

  render () {
    const { successNum = 0, failNum = 0, completeNum = 0, sourceType } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ textAlign: 'right' }}>
            <Button type='primary' onClick={() => this.handleDownloadResult()}>
              <Icon type='download' />导出结果
            </Button>

            <Button type='primary' onClick={() => this.handleBack()} style={{ marginLeft: '10px' }}>
              { sourceType && sourceType !== '' ? descriptTextMaps[sourceType].backButtonText : '返回' }<Icon type='arrow-right' />
            </Button>
          </div>

          <div style={{ display: 'flex' }}>
            {/* 匹配图表 */}
            <div className={`${styles.progressWrap}`}>
              <div id='progressEchart' className={`${styles.progressChart}`} style={{ width: '320px', height: '320px' }} />
              <div className={`${styles.progressText}`}>
                <div className={`${styles.progressLabel}`}>完全匹配率</div>
                <div className={`${styles.progressValue}`}>
                  {(+successNum / +completeNum * 100).toFixed(2)}%
                </div>
              </div>
            </div>

            {/* 匹配数据说明 */}
            <div className={`${styles.matchInfo}`}>
              <Row gutter={12} style={{ marginTop: '70px' }}>
                <Col span={6}>
                  <div className={`${styles.num}`}>{successNum}</div>
                  <div className={`${styles.partLabel}`}>
                    { sourceType && sourceType !== '' ? descriptTextMaps[sourceType]['successText'] : null }
                  </div>
                </Col>

                <Col span={8}>
                  <div className={`${styles.num} ${styles.redColor}`}>{failNum}</div>
                  <div className={`${styles.partLabel}`}>
                    { sourceType && sourceType !== '' ? descriptTextMaps[sourceType]['failText'] : null }
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </>
    )
  }
}
