/* 商户标签弹窗 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form } from 'antd'

import FormRemarks from '@/components/formRemarks/index'

class MCLabelForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        values.distributorId = detailInfo.distributorId
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { form, detailInfo = {} } = this.props

    return (
      <>
        <Modal title='商户标签' width={520} bodyStyle={{ paddingBottom: '0px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={() => this.handleConfirm()}
        >
          <FormRemarks form={form} label={'标签'} dataIndex='label' defaultValue={detailInfo.label} />
        </Modal>
      </>
    )
  }
}

const MCLabelModal = Form.create()(MCLabelForm)

export default MCLabelModal
