import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Input, Button, message, Radio } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item

class AlipayForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object
  }
  state = {
    info: {}
  }

  componentDidMount () {
    this.getMerchantAlipaySet()
  }

  // 获取配置信息
  getMerchantAlipaySet = () => {
    const { detailInfo } = this.props
    let params = {
      merchantId: detailInfo.distributorId
    }

    Api.getMerchantAlipaySet(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  // 提交配置信息
  saveAlipaySet = (params) => {
    Api.saveMerchantAlipaySet(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 点击确定
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { detailInfo } = this.props
        const { info = {} } = this.state

        if (info.id) {
          values.id = info.id
        }
        values.merchantId = detailInfo.distributorId

        this.saveAlipaySet(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { info } = this.state
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        span: 22,
        offset: 4
      }
    }

    return (
      <div style={{ width: '800px' }}>
        <Row>
          <Form>
            <FormItem label='appId' {...formItemLayout}>
              {
                getFieldDecorator('appId', {
                  initialValue: info.appId,
                  rules: [
                    { required: true, message: '请填写支付宝appId' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='私钥' {...formItemLayout}>
              {
                getFieldDecorator('privateKey', {
                  initialValue: info.privateKey,
                  rules: [
                    { required: true, message: '请填写支付宝私钥' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='公钥' {...formItemLayout}>
              {
                getFieldDecorator('publicKey', {
                  initialValue: info.publicKey,
                  rules: [
                    { required: true, message: '请填写支付宝公钥' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='安全密钥' {...formItemLayout}>
              {
                getFieldDecorator('securityKey', {
                  initialValue: info.securityKey,
                  rules: [
                    { required: true, message: '请填写支付宝公钥' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='支付宝请求URL' {...formItemLayout}>
              {
                getFieldDecorator('requestUrl', {
                  initialValue: info.requestUrl,
                  rules: [
                    { required: true, message: '请填写支付宝请求URL' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='支付成功跳转URL' {...formItemLayout}>
              {
                getFieldDecorator('returnUrl', {
                  initialValue: info.returnUrl,
                  rules: [
                    { required: true, message: '请填写支付成功后跳转URL' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='异步通知URL' {...formItemLayout}>
              {
                getFieldDecorator('notifyUrl', {
                  initialValue: info.notifyUrl,
                  rules: [
                    { required: true, message: '请填写异步通知URL' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='启用状态' {...formItemLayout}>
              {
                getFieldDecorator('status', {
                  initialValue: info.status || 1,
                  rules: [
                    { required: true, message: '请选择是否启用/禁用状态' }
                  ]
                })(
                  <Radio.Group>
                    <Radio value={1}>启用</Radio>
                    <Radio value={0}>禁用</Radio>
                  </Radio.Group>
                )
              }
            </FormItem>

            <FormItem {...tailFormItemLayout}>
              <Button type='primary' onClick={this.handleSubmit}>
                确定
              </Button>
            </FormItem>
          </Form>
        </Row>
      </div>
    )
  }
}

const AlipaySet = Form.create()(AlipayForm)

export default AlipaySet
