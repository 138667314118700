import React, { Component } from 'react'
import { Row, Card, Table, Switch, Modal, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

export default class RecommendList extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '状态'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ONLINE', name: '上架' },
          { id: 'OFFLINE', name: '下架' }
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'recommendprod-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'recommendprod-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'recommendprod-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'recommendprod-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '分类',
      key: 'recommendprod-4',
      dataIndex: 'classificationName'
    },
    {
      title: '推荐商户',
      key: 'recommendprod-5',
      dataIndex: 'distributorName'
    },
    {
      title: '上架状态',
      key: 'recommendprod-6',
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.status === 'ONLINE'}
            checkedChildren='上架'
            unCheckedChildren='下架'
            onChange={(checked) => this.handleChangeSwitch(checked, record)}
          />
        </div>
      )
    },
    {
      title: '操作',
      key: 'recommendprod-7',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.handleDeleteRecommend(record)}>删除</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getRecommendProductList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取推荐产品列表
  getRecommendProductList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getRecommendProductList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 删除推荐产品
  deleteProductInRecommend = (params) => {
    Api.deleteProductInRecommend(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getRecommendProductList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 更新推荐上下架状态
  updateRecommendStatus = (params) => {
    Api.updateRecommendStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getRecommendProductList()
      }
    })
  }

  // 点击删除推荐产品
  handleDeleteRecommend = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.deleteProductInRecommend({ id: record.id })
      }
    })
  }

  // 切换启用禁用状态
  handleChangeSwitch = (checked, record) => {
    let params = {
      status: checked ? 'ONLINE' : 'OFFLINE',
      id: record.id
    }
    this.updateRecommendStatus(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getRecommendProductList()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getRecommendProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getRecommendProductList()
    })
  }

  render () {
    const { pages, tableData = [], searchData } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey={(record, index) => `${record.id}-${index}`} bordered
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}
