import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions, repLineBreak } = filter

export default class ProductModal extends Component {
  static propTypes = {
    distributorId: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'itemModel',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    query: {},
    tableData: [],
    selectedRowKeys: []
  }
  columns = [
    {
      title: '序号',
      key: 'productCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'productCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'productCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'productCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品',
      key: 'productCol-4',
      dataIndex: 'name'
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch()
    this.getCanBindProductList()
  }

  // 获取品牌
  getBrandListInSearch = () => {
    Api.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: -1, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取可添加的产品列表
  getCanBindProductList = async () => {
    const { distributorId } = this.props
    const { query = {}, pages } = this.state
    const res = await Api.getCanBindProductList({ ...query, distributorId: distributorId, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.itemNo) {
      search['itemNo'] = repLineBreak(search.itemNo, '<br>')
    }

    this.setState({
      query: search || {},
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getCanBindProductList()
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { distributorId } = this.props
    const { selectedRowKeys = [] } = this.state

    this.props.onConfirm({ distributorId: distributorId, commoditySkuIdList: selectedRowKeys })
  }

  // 点击选择产品
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCanBindProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCanBindProductList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], selectedRowKeys = [] } = this.state

    // 分页配置
    const pagination = {
      size: 'small',
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <Modal title='添加型号' width={1200} bodyStyle={{ padding: '16px', height: '500px', overflowY: 'auto' }}
        visible
        maskClosable={false}
        onCancel={() => this.props.onCancel()}
        onOk={() => this.handleConfirm()}
      >
        <div style={{ marginBottom: '12px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Table rowKey='commoditySkuId' bordered
          columns={this.columns}
          dataSource={tableData}
          rowSelection={rowSelection}
          pagination={pagination}
        />
      </Modal>
    )
  }
}
