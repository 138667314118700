import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Button, Icon, Row, Col, Badge, message, Modal, Drawer } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import WorkOrderDrawer from './workOrderDrawer'
import AssginPriceContents from '../enquireHandle/assginPriceContents'
import { setEditEnquireDetails } from '@/reducers/action'
import Api from '@/common/api/index'

import filter from '@/common/utils/filter'
import { renderEnquireOrderStatusLabel, renderCollaborateStateLabel, renderMatchingLabel, renderAnswerStatusLabel, renderWorkOrderDueStatusLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import { manualItem } from '../createEnquireOrder/localdatas'
import styles from './index.module.scss'

const { warning } = Modal
const { pageSizeOptions, enquireOrderStateMaps, answerEnquiredStatusMaps, enquireOverdueStatusMaps } = filter
const { MAX_PAGESIZE } = consts

const enquiryTypeOptions = $lodash.filter(enquireOrderStateMaps, (o) => { return o.id !== 'INTERNAL_DRAFT' })

class EnquireOrderList extends Component {
  constructor (props) {
    super(props)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
  }
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'enquiryOrderId',
        formType: 'Input',
        itemParams: {
          label: '询价单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'enquiryTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '询价日期'
        },
        cptParams: {}
      },
      {
        name: 'replyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '回复日期'
        },
        cptParams: {}
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '询价商户'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '负责人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'dueStatus',
        formType: 'Select',
        itemParams: {
          label: '是否逾期'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          ...enquireOverdueStatusMaps
        ]
      },
      {
        name: 'enquiryType',
        formType: 'Select',
        itemParams: {
          label: '询价单类型'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          ...enquiryTypeOptions
        ]
      },
      {
        name: 'enquiryState',
        formType: 'Select',
        itemParams: {
          label: '报价状态'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          ...answerEnquiredStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    expandedRowKeys: [], // 展开的列表key
    rowDetail: {},
    assginPriceModal: false // 标定售价弹窗
  }

  columns = [
    {
      title: '序号',
      key: 'enquireOrderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '询价单编号',
      key: 'enquireOrderCol-1',
      dataIndex: 'enquiryOrderId'
    },
    {
      title: '询价日期',
      key: 'enquireOrderCol-2',
      render: (text, record, index) => (
        <>{ record.submitTime ? moment(record.submitTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '回复日期',
      key: 'enquireOrderCol-3',
      render: (text, record, index) => (
        <>{ record.replyTime ? moment(record.replyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '询价商户',
      key: 'enquireOrderCol-4',
      dataIndex: 'distributorName'
    },
    {
      title: '负责人',
      key: 'enquireOrderCol-5',
      dataIndex: 'platformUserName'
    },
    {
      title: '询价单类型',
      key: 'enquireOrderCol-6',
      dataIndex: 'enquiryType',
      render: (text, record, index) => (
        <>{ renderEnquireOrderStatusLabel(record.enquiryType) }</>
      )
    },
    {
      title: '是否逾期',
      key: 'enquireOrderCol-7',
      dataIndex: 'dueStatus',
      render: (text, record, index) => (
        <>{ renderWorkOrderDueStatusLabel(record.dueStatus) }</>
      )
    },
    {
      title: '报价状态',
      key: 'enquireOrderCol-8',
      dataIndex: 'enquiryState',
      render: (text, record, index) => (
        <>{ renderAnswerStatusLabel(record.enquiryState) }</>
      )
    },
    {
      title: '查看报价单',
      key: 'enquireOrderCol-9',
      render: (text, record, index) => (
        <>{ record.enquiryState === 'NO' ? null : <Button type='link' size='small' onClick={() => this.handleShowPreview(record)}>点击查看</Button> }</>
      )
    },
    {
      title: '操作',
      key: 'enquireOrderCol-10',
      render: (text, record, index) => (
        <>
          {
            ['PLATFORM_DRAFT', 'INTERNAL_DRAFT'].includes(record.enquiryType)
              ? <Button type='link' size='small' onClick={() => this.handleLinkToCreateEnquire(record, 'EDIT', record.enquiryType)}>编辑询价</Button>
              : <>
                <Button type='link' size='small' onClick={() => this.handleLinkToEnquireHandle(record)}>处理询价</Button>
                <Button type='link' size='small' onClick={() => this.showWorkDetailModal(record)}>查看工单</Button>
              </>
          }

          {
            record.enquiryState === 'NO'
              ? null
              : <Button type='link' size='small' onClick={() => this.handleLinkToEnquireProcess(record)}>查看过程</Button>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getEnquireOrderList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取全部成员
  getMemberList = (val = '') => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[4].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取询价单列表
  getEnquireOrderList = () => {
    const { pages, query = {} } = this.state
    Api.getEnquireOrderList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取询价单详情
  getCorrectEnquireOrderDetails = async (record) => {
    let { tableData = [] } = this.state
    const i = $lodash.findIndex(tableData, (item) => { return item.enquiryOrderId === record.enquiryOrderId })

    const res = await Api.getCorrectEnquireOrderDetails({ orderId: record.orderId })
    const { code, data } = res
    if (+code === 10000) {
      tableData[i].detailCorrections = data.detailCorrections || []

      this.setState({
        tableData: [...tableData]
      })
    }
  }

  // 获取报价单PDF url
  getPdfUrlInEnquireOrderList = async (params) => {
    const res = await Api.getPdfUrlInEnquireOrderList(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data !== '') {
        window.open(data, '_blank')
      } else {
        message.warning('暂无数据')
      }
    } else {
      message.warning(res.message)
    }
  }

  /* 修正产品更新
   * 恢复报价、停止报价
  */
  updateCorrectEnquireOrderDetails = async (params, row) => {
    const res = await Api.updateCorrectEnquireOrderDetails(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getCorrectEnquireOrderDetails(row)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.enquiryTimes) {
      search.enquiryBeginDate = search.enquiryTimes.length === 2 ? moment(search.enquiryTimes[0]).format('YYYY-MM-DD') : null
      search.enquiryEndDate = search.enquiryTimes.length === 2 ? moment(search.enquiryTimes[1]).format('YYYY-MM-DD') : null

      delete search.enquiryTimes
    }

    if (search.replyTimes) {
      search.replyBeginDate = search.replyTimes.length === 2 ? moment(search.replyTimes[0]).format('YYYY-MM-DD') : null
      search.replyEndDate = search.replyTimes.length === 2 ? moment(search.replyTimes[1]).format('YYYY-MM-DD') : null

      delete search.replyTimes
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getEnquireOrderList()
    })
  }

  // 点击新建、编辑询价单
  handleLinkToCreateEnquire = (record, type, enquiryType) => {
    let autodatas = []
    let manualdatas = []
    const { enquiryOrderDetails = [], enquiryRemarks = [] } = record

    if (type === 'EDIT') {
      autodatas = $lodash.filter(enquiryOrderDetails, (o) => { return o.dataType === 'SEARCH' })
      manualdatas = $lodash.filter(enquiryOrderDetails, (o) => { return o.dataType === 'WRITE' })
    }

    const actiondatas = {
      type: type,
      enquiryType: enquiryType,
      autoEnquireProducts: autodatas.length > 0 ? $lodash.map(autodatas, (row, i) => { return { rowKey: `SEARCH-${i}`, ...row } }) : autodatas,
      manualEnquireProducts: manualdatas.length > 0 ? $lodash.map(manualdatas, (row, i) => { return { rowKey: `WRITE-${i}`, ...row } }) : [{ rowKey: `WRITE-0`, ...manualItem }],
      remarks: type === 'EDIT' ? (enquiryRemarks.length > 0 ? enquiryRemarks[0].remarks : '') : '',
      distributorId: type === 'EDIT' ? record.distributorId : null,
      distributorName: type === 'EDIT' ? record.distributorName : null,
      orderId: type === 'EDIT' ? record.orderId : null,
      enquiryOrderId: type === 'EDIT' ? record.enquiryOrderId : null
    }

    this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: actiondatas }))

    setTimeout(() => {
      this.props.history.push({ pathname: '/admin/createEnquireOrder' })
    }, 20)
  }

  /* 点击显示全部
   * 执行负责人 platformUserId 为全部的搜索逻辑
  */
  handlePreviewAll = () => {
    const { query = {}, pages = {} } = this.state

    this.setState({
      query: {
        ...query,
        platformUserId: -1
      },
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      }
    }, () => {
      this.getEnquireOrderList()
    })
  }

  // 点击查看询价过程
  handleLinkToEnquireProcess = (record) => {
    this.props.history.push({ pathname: '/admin/enquireProcess', state: { orderId: record.orderId } })
  }

  // 点击处理询价
  handleLinkToEnquireHandle = (record) => {
    this.props.history.push({ pathname: '/admin/enquireHandle', state: { detailInfo: record } })
  }

  // 点击查看工单
  showWorkDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      workModal: true
    })
  }

  // 点击查看报价单
  handleShowPreview = (record) => {
    this.getPdfUrlInEnquireOrderList({ orderId: record.orderId })
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    let { expandedRowKeys = [] } = this.state

    this.setState({
      expandedRowKeys: expanded ? [...expandedRowKeys, record.orderId] : $lodash.pull(expandedRowKeys, record.orderId)
    }, () => {
      if (expanded) {
        this.getCorrectEnquireOrderDetails(record)
      }
    })
  }

  // 渲染列表展开的内容
  renderExpandedRow = (row) => {
    const rowColumns = [
      {
        title: '行号',
        key: `expandRow-${row.id}-rownum`,
        width: 44,
        align: 'center',
        dataIndex: 'rownum'
      },
      {
        title: '品牌',
        key: `expandRow-${row.enquiryOrderId}-brandName`,
        dataIndex: 'brandName'
      },
      {
        title: '型号',
        key: `expandRow-${row.enquiryOrderId}-model`,
        dataIndex: 'model'
      },
      {
        title: '订货号',
        key: `expandRow-${row.enquiryOrderId}-itemNumber`,
        dataIndex: 'itemNumber'
      },
      {
        title: '期望价格',
        key: `expandRow-${row.enquiryOrderId}-expectPrice`,
        dataIndex: 'expectPrice',
        render: (text, record, index) => (
          <>&yen;{+record.expectPrice}</>
        )
      },
      {
        title: '标定售价',
        key: `expandRow-${row.enquiryOrderId}-assignPrice`,
        dataIndex: 'assignPrice',
        render: (text, record, index) => (
          <>
            <Button type='link' size='small' disabled={record.collaborateState === 'STOP_QUOTATION'}
              onClick={() => this.handleShowAssginPriceModal({ ...record, enquiryOrderId: row.enquiryOrderId })}
            >
              &yen;{+record.assignPrice}
            </Button>
          </>
        )
      },
      {
        title: '最近成交价格',
        key: `expandRow-${row.enquiryOrderId}-recentTransactionPrice`,
        dataIndex: 'recentTransactionPrice',
        render: (text, record, index) => (
          <>{ record.recentTransactionPrice ? <>&yen;{ +record.recentTransactionPrice }</> : <>----</> }</>
        )
      },
      {
        title: '数量',
        key: `expandRow-${row.enquiryOrderId}-quantity`,
        dataIndex: 'quantity'
      },
      {
        title: '协作状态',
        key: `expandRow-${row.enquiryOrderId}-collaborateState`,
        dataIndex: 'collaborateState',
        render: (text, record, index) => (
          <>{ renderCollaborateStateLabel(record.collaborateState) }</>
        )
      },
      {
        title: '数据类型',
        key: `expandRow-${row.enquiryOrderId}-matchingState`,
        width: 100,
        dataIndex: 'matchingState',
        render: (text, record, index) => (
          <>{ renderMatchingLabel(record.matchingState, record.dataType) }</>
        )
      }
    ]

    return (
      <div style={{ padding: '2px 5px' }}>
        <Table rowKey='id'
          columns={rowColumns}
          dataSource={row.detailCorrections || []}
          pagination={false}
        />
      </div>
    )
  }

  // 点击查看工单详情
  handleLinkToWorkDetails = (record) => {
    this.props.history.push({ pathname: '/admin/workDetails', state: { id: record.id, workOrderType: record.workOrderType } })
  }

  // 点击标定售价按钮
    // 点击标定售价按钮
    handleShowAssginPriceModal = (record) => {
      if (+record.commoditySkuId === 0) {
        warning({
          title: '产品数据不正确',
          content: '请修正产品数据，或者停止该产品报价'
        })

        return false
      }

      this.setState({
        rowDetail: record,
        assginPriceModal: true
      })
    }

  closeModal = () => {
    this.setState({
      workModal: false,
      assginPriceModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEnquireOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEnquireOrderList()
    })
  }

  render () {
    const { buttonMaps = {}, history } = this.props
    const { searchData, pages, tableData = [], workModal = false, rowDetail = {}, assginPriceModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)} />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Col span={8}>
              {
                buttonMaps && buttonMaps.ENQUIRY_LIST_INNERADD && +buttonMaps.ENQUIRY_LIST_INNERADD.auth === 1
                  ? <Button type='primary' onClick={() => this.handleLinkToCreateEnquire({}, 'ADD', 'INTERNAL')}><Icon type='plus' />新建内部询价单</Button>
                  : null
              }

              {
                buttonMaps && buttonMaps.ENQUIRY_LIST_ADD && +buttonMaps.ENQUIRY_LIST_ADD.auth === 1
                  ? <Button type='primary' onClick={() => this.handleLinkToCreateEnquire({}, 'ADD', 'PLATFORM')} style={{ marginLeft: '10px' }}><Icon type='plus' />新建运营询价单</Button>
                  : null
              }

              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handlePreviewAll()}>显示全部</Button>
            </Col>

            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <span className={`${styles.tag}`}>
                <Badge color='#FFA39E' text='已逾期' />
              </span>
            </Col>
          </Row>

          <Table rowKey='enquiryOrderId' bordered
            columns={this.columns}
            dataSource={tableData}
            onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
            expandedRowRender={(record) => this.renderExpandedRow(record)}
            rowClassName={(record, index) => { return record.dueStatus === 'OVERDUE' ? styles.errorColor : null }}
            pagination={pagination}
          />
        </Card>

        <WorkOrderDrawer
          history={history}
          visible={workModal}
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />

        { /* 标定售价 */ }
        <Drawer title='选择售价' width={1300} bodyStyle={{ paddingBottom: '80px' }}
          visible={assginPriceModal}
          placement='right'
          closable
          onClose={() => this.closeModal()}
        >
          {
            assginPriceModal
              ? <AssginPriceContents
                detailInfo={rowDetail}
                onConfirm={(params) => this.updateCorrectEnquireOrderDetails(params, { enquiryOrderId: rowDetail.enquiryOrderId, orderId: rowDetail.orderId })}
                onCancel={() => this.closeModal()}
              />
              : null
          }
        </Drawer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(EnquireOrderList)
