import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Col, Form, Modal, Button, Input, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import { setUserInfo } from '@/reducers/action'
import { removeStore } from '@/common/utils/mUtils'
import filter from '@/common/utils/filter'

import Api from '@/common/api/index'

import styles from './account.module.scss'

const FormItem = Form.Item
const { passReg } = filter

class SetAccountForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    userInfo: PropTypes.object,
    dispatch: PropTypes.func
  }

  // 提交
  saveUserModifyPassword = (params) => {
    Api.saveUserModifyPassword(params).then(res => {
      if (+res.code === 10000) {
        this.countDown()
      }
    })
  }

  // 删除token 跳转到登录页
  jumpToLogin = () => {
    removeStore('token')
    this.props.dispatch(setUserInfo({ userInfo: {} }))
    this.props.history.push('/login')
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '修改密码成功！',
      content: `${secondsToGo} s后将前往登录页面`,
      onOk: () => {
        this.jumpToLogin()
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `${secondsToGo} s后将前往登录页面`
      })
    }, 1000)

    setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.jumpToLogin()
    }, secondsToGo * 1000)
  }

  // 点击提交
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { userInfo } = this.props
        if (values.confirmPassword !== values.password) {
          return message.warning('两次输入密码不一致！')
        }
        let { oldPassword, password, confirmPassword } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['oldPassword'] = jsencrypt.encrypt(oldPassword)
        values['password'] = jsencrypt.encrypt(password)
        values['confirmPassword'] = jsencrypt.encrypt(confirmPassword)

        values.id = userInfo.id
        this.saveUserModifyPassword(values)
      }
    })
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = this.props.form
    const { userInfo = {} } = this.props

    return (
      <div>
        <Card title='账户设置'>
          <Row style={{ margin: '0px auto', width: '600px' }}>
            <Col>
              <Row>
                <Form>
                  <FormItem label='登录名' {...formItemLayout}>
                    {
                      getFieldDecorator('loginName', {
                        initialValue: userInfo.loginName || '',
                        rules: [
                          {
                            required: true
                          }
                        ]
                      })(<Input disabled />)
                    }
                  </FormItem>
                  <FormItem label='Email' {...formItemLayout}>
                    {
                      getFieldDecorator('email', {
                        initialValue: userInfo.email || '',
                        rules: [
                          {
                            required: false
                          }
                        ]
                      })(<Input disabled />)
                    }
                  </FormItem>
                  <FormItem label='原登录密码' {...formItemLayout}>
                    {
                      getFieldDecorator('oldPassword', {
                        rules: [
                          { required: true, message: '请填写原登录密码' }
                        ]
                      })(<Input.Password placeholder='请输入原登录密码...' autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='新登录密码' {...formItemLayout}
                    extra={<span className={`${styles.formExtra}`}>密码长度为6-12位字符，可包含数字,字母(区分大小写)</span>}
                  >
                    {
                      getFieldDecorator('password', {
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !passReg.test(value)) {
                                    /* eslint-disable */
                                      callback('登录密码格式不正确')
                                    /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请您填写新登录密码' }]
                          }
                        ]
                      })(<Input.Password placeholder='请输入新登录密码...' autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='确认登录密码' {...formItemLayout}>
                    {
                      getFieldDecorator('confirmPassword', {
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        rules: [
                          { required: true, message: '请填写确认密码' }
                        ]
                      })(<Input.Password placeholder='请确认密码...' autoComplete='off' />)
                    }
                  </FormItem>
                </Form>
              </Row>
              <Row style={{ textAlign: 'center' }}>
                <Button type='primary' onClick={this.handleSubmit}>提交</Button>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const SetAccount = Form.create()(SetAccountForm)

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

export default connect(mapStateToProps)(SetAccount)
