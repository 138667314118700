import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Modal, Radio } from 'antd'

import filter from '@/common/utils/filter'

const FormItem = Form.Item
const { confirm } = Modal
const { insuranceTypeMaps } = filter

class InsurancetypeForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    detailInfo: PropTypes.object
  }
  state = {

  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props

        confirm({
          title: `确定将服务类型改为${values.type === 'YEAR' ? '年费' : '普通'}？`,
          onOk: () => {
            this.props.onConfirm({ distributorId: detailInfo.distributorId, ...values })
          }
        })
      }
    })
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }

    return (
      <>
        <Modal title='服务类型更改'
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={this.props.onCancel}
        >
          <Form>
            <Row>
              <FormItem label='服务类型' {...formItemLayout}>
                {
                  getFieldDecorator('type', {
                    initialValue: detailInfo.type || 'YEAR',
                    rules: [
                      { required: true, message: '请选择服务类型' }
                    ]
                  })(
                    <Radio.Group>
                      {
                        insuranceTypeMaps.map((item, index) => (
                          <Radio value={item.id} key={`${item.id}-${index}`}>{ item.name }</Radio>
                        ))
                      }
                    </Radio.Group>
                  )
                }
              </FormItem>
            </Row>
          </Form>
        </Modal>
      </>
    )
  }
}

const InsurancetypeModal = Form.create()(InsurancetypeForm)

export default InsurancetypeModal
