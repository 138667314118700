import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MCInformation from '../component/MCInformationForm/index'

import Api from '@/common/api/index'

export default class PurchaserEdit extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    type: 'ADD',
    info: {}
  }

  componentDidMount () {
    const { state } = this.props.history.location

    this.setState({
      info: state.detailInfo,
      type: state.type
    })
  }

  // 获取平台域名
  getPurchaserDomain = (params) => {
    return Api.getPurchaserDomain(params)
  }

  // 保存新增、编辑采购商信息
  savePurchaserInformation = (params) => {
    return Api.savePurchaserInformation(params)
  }

  // 校验采购商登录名是否重复
  purchaseSaveUsernameValidate = (params) => {
    return Api.purchaseSaveUsernameValidate(params)
  }

  handleBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { type = 'ADD', info = {} } = this.state

    return (
      <>
        <MCInformation
          author={'JUST_PURCHASE'}
          type={type}
          detailInfo={info}
          getDomain={(params) => this.getPurchaserDomain(params)}
          onCheckLoginname={(params) => this.purchaseSaveUsernameValidate(params)}
          onConfirm={(params) => this.savePurchaserInformation(params)}
          onCancel={() => this.handleBack()}
        />
      </>
    )
  }
}
