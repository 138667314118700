import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card } from 'antd'

import NavTab from './navtab'
import BannerMaterial from './bannerMaterial'
import RecommendMaterial from './recommendMaterial'

class Material extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    selectedNavBar: 'BANNER'
  }

  componentDidMount () {

  }

  // 点击选择导航
  handleSelectedNavBar = (params) => {
    this.setState({
      selectedNavBar: params.type
    })
  }

  render () {
    const { selectedNavBar = 'BANNER' } = this.state

    return (
      <>
        <Row>
          <Card bodyStyle={{ padding: '0px' }}>
            <NavTab onSelect={(params) => this.handleSelectedNavBar(params)}
              history={this.props.history}
            />
          </Card>
        </Row>

        <Row>
          {
            selectedNavBar === 'BANNER'
              ? <BannerMaterial
                history={this.props.history}
              />
              : null
          }

          {
            selectedNavBar === 'RECOMMEND'
              ? <RecommendMaterial />
              : null
          }

        </Row>
      </>
    )
  }
}

export default Material
