import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Button, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form'
import InvoiceModal from './invoiceModal'

import Api from '@/common/api'
import filter from '@/common/utils/filter'
import { renderInvoiceOpenedStatusLabel, renderOrderDeliveryStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, orderDeliveryStatusMaps } = filter

class SupplyInvoice extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'supplierId',
        formType: 'Select',
        itemParams: {
          label: '供应商'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedTableRows: [],
    checkedIds: [],
    checkedDistributorIds: [],
    invoiceModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'supplyInvoiceList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '供应商名称',
      key: 'supplyInvoiceList-1',
      width: 180,
      ellipsis: true,
      dataIndex: 'supplierName'
    },
    {
      title: '订单编号',
      key: 'supplyInvoiceList-2',
      dataIndex: 'purchaseOrderId'
    },
    {
      title: '发货状态',
      key: 'supplyInvoiceList-3',
      dataIndex: 'deliveryStatus',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '订单日期',
      key: 'supplyInvoiceList-4',
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '开票金额',
      key: 'supplyInvoiceList-5',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '开票状态',
      key: 'supplyInvoiceList-6',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{renderInvoiceOpenedStatusLabel(record.status)}</>
      )
    }
  ]

  componentDidMount () {
    this.getSupplyOrderInvoiceUnopenList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取待申请开票列表
  getSupplyOrderInvoiceUnopenList = () => {
    const { pages, query = {} } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query
    }

    Api.getSupplyOrderInvoiceUnopenList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 云供应发票- 保存开票申请
  saveSupplyOrderInvoiceApplyOpen = async (params) => {
    const res = await Api.saveSupplyOrderInvoiceApplyOpen(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      this.getSupplyOrderInvoiceUnopenList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search['beginDate'] = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search['endDate'] = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getSupplyOrderInvoiceUnopenList()
    })
  }

  // 点击申请开票
  showInvoiceModal = (selectedrows = []) => {
    this.setState({
      checkedIds: $lodash.map(selectedrows, (o) => { return o.id }),
      checkedDistributorIds: $lodash.map(selectedrows, (o) => { return o.supplierId }),
      invoiceModal: true
    })
  }

  // 点击选择
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedTableRows: rows
    })
  }

  closeModal = () => {
    this.setState({
      checkedIds: [],
      checkedDistributorIds: [],
      invoiceModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyOrderInvoiceUnopenList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyOrderInvoiceUnopenList()
    })
  }

  render () {
    const { searchData, tableData = [], pages, selectedTableRows = [], checkedIds = [], checkedDistributorIds = [], invoiceModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const selectedRowKeys = $lodash.map(selectedTableRows, (o) => { return o.id })

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' disabled={selectedRowKeys.length === 0} onClick={() => this.showInvoiceModal(selectedTableRows)}>申请开票</Button>
          </div>

          <Table rowKey={'id'} bordered
            dataSource={tableData}
            columns={this.columns}
            rowSelection={rowSelection}
            rowClassName={(record, index) => record.invoiceSource === 'AFTERSALE_INVOICE' ? 'redColor' : null}
            pagination={pagination}
          />
        </Card>

        {
          invoiceModal
            ? <InvoiceModal
              ids={checkedIds}
              distributorIds={checkedDistributorIds}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.saveSupplyOrderInvoiceApplyOpen(params)}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(SupplyInvoice)
