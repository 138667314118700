import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, InputNumber, Switch, Select, DatePicker, Button, message } from 'antd'
import moment from 'moment'

import FormRemarks from '@/components/formRemarks/index'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const FormItem = Form.Item
const Option = Select.Option
const { MAX_NUMBER } = consts

class AssginPriceForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    commoditySkuId: PropTypes.number,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    customerList: []
  }

  componentDidMount () {
    this.getMerchantListBySelectType()
  }

  // 根据工单编号精确查找产品数据
  getEnquireProductInfoByWorkOrderId = async (params) => {
    const res = await Api.getEnquireProductInfoByWorkOrderId(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data) {
        const fieldValues = {
          sellingPrice: data.sellingPrice,
          quantity: data.quantity,
          deliveryPeriodDays: data.deliveryPeriodDays,
          advancePaymentRate: data.advancePaymentRate,
          isUndertakeFreight: data.isUndertakeFreight === 'UNDERTAKE',
          expiryDateTime: data.expiryDateTime ? moment(data.expiryDateTime) : null,
          distributorId: data.distributorId,
          remarks: data.remarks
        }

        setTimeout(() => {
          this.props.form.setFieldsValue(fieldValues)
        }, 20)
      }
    }
  }

  // 获取商家列表
  getMerchantListBySelectType = async () => {
    const res = await Api.getMerchantListBySelectType({ type: 'JUST_SUPPLY', pageNo: 1, pageSize: 9999 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        customerList: data || []
      })
    }
  }

  // 点进确定
  handleConfirm = () => {
    const { form } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const { isUndertakeFreight = false, expiryDateTime } = values
        values.isUndertakeFreight = isUndertakeFreight ? 'UNDERTAKE' : 'UN_UNDERTAKE'
        values.expiryDateTime = moment(expiryDateTime).format('YYYY-MM-DD HH:mm:ss')

        this.props.onConfirm(values)
      }
    })
  }

  // 点击引入数据
  handleQuickCopy = () => {
    const { form, commoditySkuId } = this.props
    const workOrderId = form.getFieldValue('workOrderId')

    if (workOrderId === undefined) { return message.warning('请输入正确的工单编号') }

    this.getEnquireProductInfoByWorkOrderId({ workOrderId: workOrderId, commoditySkuId: commoditySkuId })
  }

  render () {
    const { form, onCancel } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { customerList = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title='新增价格'
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <FormItem label='工单编号' {...formItemLayout}>
              {
                getFieldDecorator('workOrderId', {

                })(
                  <Input style={{ width: 'calc(100% - 94px)' }} placeholder='请输入...' autoComplete='off' />
                )
              }
              <Button type='primary' style={{ marginLeft: '5px' }} onClick={() => this.handleQuickCopy()}>引入数据</Button>
            </FormItem>
            <FormItem label='云仓价格' {...formItemLayout}>
              <span style={{ display: 'inline-block', wdith: '16', marginRight: '5px' }}>￥</span>
              {
                getFieldDecorator('sellingPrice', {
                  rules: [
                    { required: true, message: '请填写价格' }
                  ]
                })(<InputNumber style={{ width: 'calc(100% - 24px)' }} min={0} max={MAX_NUMBER} />)
              }
            </FormItem>

            <FormItem label='数量' {...formItemLayout}>
              {
                getFieldDecorator('quantity', {
                  rules: [
                    { required: true, message: '请填写数量' }
                  ]
                })(<InputNumber style={{ width: '100%' }} min={0} max={MAX_NUMBER} />)
              }
            </FormItem>

            <FormItem label='货期' {...formItemLayout}>
              {
                getFieldDecorator('deliveryPeriodDays', {
                  rules: [
                    { required: true, message: '请填写货期' }
                  ]
                })(<InputNumber style={{ width: 'calc(100% - 50px)' }} min={0} precision={0} max={MAX_NUMBER} />)
              }
              <span style={{ display: 'inline-block', marginLeft: '5px' }}>工作日</span>
            </FormItem>

            {
              getFieldValue('deliveryPeriodDays') !== undefined && getFieldValue('deliveryPeriodDays') > 0
                ? <FormItem label='预付比例' {...formItemLayout}>
                  {
                    getFieldDecorator('advancePaymentRate', {
                      rules: [
                        { required: true, message: '请填写期货预付比例' }
                      ]
                    })(<InputNumber style={{ width: 'calc(100% - 50px)' }} min={0} max={100} />)
                  }
                  <span style={{ display: 'inline-block', marginLeft: '5px' }}>%</span>
                </FormItem>
                : null
            }

            <FormItem label='是否承担运费' {...formItemLayout}>
              {
                getFieldDecorator('isUndertakeFreight', {
                  initialValue: false,
                  rules: [
                    { required: true, message: '请选择是否承担运费' }
                  ],
                  valuePropName: 'checked'
                })(<Switch checkedChildren='是' unCheckedChildren='否' />)
              }
            </FormItem>

            <FormItem label='报价有效期' {...formItemLayout}>
              {
                getFieldDecorator('expiryDateTime', {
                  rules: [
                    { required: true, message: '请选择报价有效期' }
                  ]
                })(<DatePicker showTime placeholder='请选择...' style={{ width: '100%' }} />)
              }
            </FormItem>

            <FormItem label='供应商户' {...formItemLayout}>
              {
                getFieldDecorator('distributorId', {
                  rules: [
                    { required: true, message: '请选择供应商户' }
                  ]
                })(
                  <Select showSearch optionFilterProp='children'>
                    {
                      customerList.map((item) => (
                        <Option value={item.distributorId} key={item.distributorId}>{ item.name }</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>

            <FormRemarks form={form} formItemLayout={formItemLayout} />
          </Form>
        </Modal>
      </>
    )
  }
}

const AssginPriceFormModal = Form.create()(AssginPriceForm)

export default AssginPriceFormModal
