import $lodash from 'lodash'

const DatainitPurchase = (res) => {
  let a = $lodash.map(res.cloud_purchase_thisyear, (item) => {
    let obj = {
      name: item[3].split(',')[0],
      regionCode: item[3].split(',')[1],
      year: item[2].split('-')[0],
      month: +item[2].split('-')[1],
      value: item[1],
      quantity: item[0],
      keyword: 'purchase'
    }
    return obj
  })
  return a
}
const DatainitSupply = (res) => {
  let supplyArr = $lodash.map(res.cloud_supply_thisyear, (item) => {
    let obj = {
      name: item[3].split(',')[0],
      regionCode: item[3].split(',')[1],
      year: item[2].split('-')[0],
      month: +item[2].split('-')[1],
      value: item[1],
      quantity: item[0],
      keyword: 'supply'
    }
    return obj
  })
  return supplyArr
}

const DatainitEu = (res) => {
  let a = $lodash.map(res.eu_order_thisyear, (item) => {
    let obj = {
      name: item[3].split(',')[0],
      regionCode: item[3].split(',')[1],
      year: item[2].split('-')[0],
      month: +item[2].split('-')[1],
      value: item[1],
      quantity: item[0],
      keyword: 'self'
    }
    return obj
  })
  return a
}

/// /////////////

export default {
  DatainitPurchase,
  DatainitSupply,
  DatainitEu
}
