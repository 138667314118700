import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Descriptions, Button, message } from 'antd'

import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'

// 订单详情-收货信息
export default function OrderDetailsReceiving (props) {
  const { ident, detailInfo = {} } = props
  const [addressInfo, setAddressInfo] = useState({})
  const [ addressObj, setAddressObj ] = useState({ type: 1, visiable: false, detailInfo: {}, endUserId: null })

  // 根据订单Id获取客户收货地址
  const getReceiveAddressByOrderId = async () => {
    const res = await Api.getReceiveAddressByOrderId({ orderId: detailInfo.orderId })
    const { code, data } = res
    if (+code === 10000) {
      setAddressInfo(data || {})
    }
  }

  // 保存修改客户收货地址
  const saveModifyReceivingAddressByOrderId = async (params) => {
    const res = await Api.saveModifyReceivingAddressByOrderId(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      setAddressObj({ type: 1, visiable: false, detailInfo: {} })
      if (ident === 'SUPPLY_ORDER') {
        getReceiveAddressByOrderId({ orderId: detailInfo.orderId })
      }
    }
  }

  useEffect(() => {
    getReceiveAddressByOrderId()
  }, [])

  return (
    <>
      <Descriptions>
        <Descriptions.Item label='收货人'>
          { ident !== 'EUORDER' ? addressInfo.receiver : detailInfo.realName }
        </Descriptions.Item>
        <Descriptions.Item label='联系方式' span={2}>
          <>{ ident !== 'EUORDER' ? addressInfo.phoneNumber : detailInfo.receiverPhoneNum }</>
          {
            ident === 'SUPPLY_ORDER' && ['PAYING', 'WAIT_ORDER_GOODS', 'TAIL_PAYING', 'WAIT_TO_SHIP'].includes(detailInfo.orderStatus)
              ? <Button type='link' onClick={() => setAddressObj({ type: 2, visiable: true, detailInfo: { ...addressInfo, name: addressInfo.receiver, mobile: addressInfo.phoneNumber } })}>修改收货信息</Button>
              : null
          }
        </Descriptions.Item>
        <Descriptions.Item label='收货地址'>
          {
            ident !== 'EUORDER'
              ? <>{ renderAddressLabel(addressInfo.province, addressInfo.city, addressInfo.county, addressInfo.address)}</>
              : <>{ renderAddressLabel(detailInfo.province, detailInfo.city, detailInfo.county, detailInfo.receiverAddress) }</>
          }
        </Descriptions.Item>
      </Descriptions>

      {
        addressObj.visiable
          ? <AddressModal
            type={addressObj.type}
            detailInfo={addressObj.detailInfo}
            onConfirm={(params) => saveModifyReceivingAddressByOrderId({ ...params, orderId: detailInfo.orderId })}
            onCancel={() => setAddressObj({ type: 1, visiable: false, detailInfo: {} })}
          />
          : null
      }
    </>
  )
}

OrderDetailsReceiving.propTypes = {
  ident: PropTypes.string,
  detailInfo: PropTypes.object
}
