import React, { Component } from 'react'
import { Row, Card, Tabs } from 'antd'

import ExpressCard from './expressCard'

import styles from './express.module.scss'

const { TabPane } = Tabs
const tabMaps = [
  { key: 'SAAS-DBL', name: '德邦快递' }
  // { key: 'SAAS-SF', name: '顺丰快递' }
]

export default class ExpressInvoice extends Component {
  static propTypes = {}
  state = {
    activeKey: 'SAAS-DBL'
  }

  componentDidMount () {}

  // 点击切换tab
  handleChangeTabPane = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'SAAS-DBL' } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <Row className={`${styles.tabWrap}`}>
              <Tabs onChange={(key) => this.handleChangeTabPane(key)}>
                {
                  tabMaps.map((item, i) => (
                    <TabPane tab={item.name} key={item.key} />
                  ))
                }
              </Tabs>
            </Row>
          </Card>
        </Row>

        {
          activeKey === 'SAAS-DBL'
            ? <ExpressCard />
            : null
        }
      </div>
    )
  }
}
