import React, { Component } from 'react'
import { Tabs } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import CloudStoreReport from './cloudStoreReport'
import CustomerReport from './customerReport'

import Request from '@/common/api/dashboardApi'
import { cloudTableRows } from './localdatas'
import styles from './index.module.scss'

const { TabPane } = Tabs
const tabMaps = [
  { id: 'CLOUDSTORE', name: '云仓' },
  { id: 'CUSTOMER', name: '服务商/最终用户' }
]
// 当前年
const YEAR = moment().get('year')
const yLen = YEAR - 2019 + 1
const colLen = (YEAR - 2019 + 1) * 17 + 1

// 综合统计
export default class ComprehensiveReport extends Component {
  static propTypes = {}
  state = {
    activeKey: 'CLOUDSTORE' // 当前选择的tab
  }

  componentDidMount () {

  }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const res = await Request.getDashboardAnalyse({ type: 'big_table' })
    let resObj = this.setCamelCaseKey(res)
    let result = {}

    $lodash.forEach(resObj, (values, key) => {
      let arr = $lodash.map(values, (row) => {
        let len = row.length
        let obj = {}
        switch (len) {
          case 1:
            obj = {
              year: null,
              quarter: null,
              month: null,
              value: row[0],
              keyword: key
            }
            break
          case 2:
            obj = {
              year: $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? row[0].split('-')[0] : `${row[0]}`,
              quarter: null,
              month: $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? +row[0].split('-')[1] : null,
              value: row[1],
              keyword: key
            }
            break
          case 3:
            obj = {
              year: $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? row[0].split('-')[0] : `${row[0]}`,
              quarter: row[1],
              month: $lodash.isString(row[0]) && row[0].indexOf('-') > -1 ? +row[0].split('-')[1] : null,
              value: row[2],
              keyword: key
            }
            break
        }
        return obj
      })

      result[key] = arr
    })

    this.setState({
      originData: result
    }, () => {
      this.setCloudStoreTableData()
    })
  }

  // 点击切换tab
  handleChangeTab = (key) => {
    this.setState({
      activeKey: key
    })
  }

  // 设置云仓表格数据
  setCloudStoreTableData = () => {
    const { originData } = this.state
    let orgData = {}
    let tableData = []

    $lodash.forEach(originData, (values, key) => {
      orgData[key] = $lodash.groupBy(values, 'year')
    })

    cloudTableRows.map((row) => {
      let obj = {}
      for (let i = 0; i < colLen; i++) {
        obj['name'] = row.label
        obj['key'] = row.key

        for (let n = 0; n < yLen; n++) {
          let values = orgData[row.key]
          obj[`value-${YEAR - n}`] = values !== undefined ? $lodash.sumBy(values[`${YEAR - n}`], 'value') : 0
        }
      }

      tableData.push(obj)
    })

    this.setState({
      tableData
    })
  }

  // 设置对象key为驼峰
  setCamelCaseKey = (maps) => {
    let result = {}
    $lodash.forEach(maps, (values, key) => {
      result[$lodash.camelCase(key)] = values
    })

    return result
  }

  render () {
    const { activeKey = 'CLOUDSTORE', tableData = [] } = this.state

    return (
      <>
        <div className={`${styles.tabLand}`}>
          <Tabs onChange={(key) => this.handleChangeTab(key)}>
            {
              tabMaps.map((item, index) => (
                <TabPane tab={item.name} key={item.id} />
              ))
            }
          </Tabs>
        </div>

        {
          activeKey === 'CLOUDSTORE'
            ? <CloudStoreReport tableData={tableData} />
            : null
        }

        {
          activeKey === 'CUSTOMER'
            ? <CustomerReport />
            : null
        }
      </>
    )
  }
}
