import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input, Upload, Icon, message } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './setfloor.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts
const FormItem = Form.Item
const { TextArea } = Input

const imgSizeTips = [
  { type: 1, tipText: `图片宽为400px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 2, tipText: `图片宽为1200px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 3, tipText: `图片尺寸为 240px * 620px, 图片大小不超过${IMG_LT2M}MB` },
  { type: 4, tipText: `图片尺寸为 400px * 400px, 图片大小不超过${IMG_LT2M}MB` }
]

class SetfloorForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-编辑
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    rowDetail: {}
  }

  componentDidMount () {
    const { detailInfo = {} } = this.props
    let { rowDetail = {} } = this.state
    if (detailInfo.elementList && detailInfo.elementList.length > 0) {
      rowDetail = detailInfo.elementList[0]
    }

    this.setState({
      rowDetail
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {}, type = 1 } = this.props
        let { rowDetail = {} } = this.state
        if (!rowDetail.imageUrl || rowDetail.imageUrl === '') {
          return message.warning('请上传图片！')
        }

        let params = {
          containerId: detailInfo.id
        }

        if (type === 2) {
          values.id = rowDetail.id
        }

        values.imageUrl = rowDetail.imageUrl

        params.elementList = []
        params.elementList.push(values)

        this.props.onConfirm(params)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info, i) => {
    if (info.file.status === 'done') {
      let { rowDetail } = this.state
      const { data } = info.file.response

      rowDetail.imageUrl = data.imageUrl

      this.setState({
        rowDetail
      })
    }
  }

  // 删除图片
  handleRemoveImage = () => {
    let { rowDetail } = this.state

    rowDetail.imageUrl = ''

    this.setState({
      rowDetail
    })
  }

  // 上传图片文字提示
  renderImgSizeTips = (type) => {
    let text = ''
    let i = imgSizeTips.findIndex(item => {
      return item.type === type
    })

    if (i > -1) {
      text = imgSizeTips[i].tipText
    }

    return text
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { rowDetail = {} } = this.state

    return (
      <div>
        <Modal title='编辑楼层' bodyStyle={{ padding: '12px' }}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row style={{ marginBottom: '10px', color: '#FF3434' }}>
            <span>建议：{this.renderImgSizeTips(1)}</span>
          </Row>
          <Row className={`${styles.editRow}`}>
            <div className={styles.uploadArea}>
              {
                rowDetail.imageUrl && rowDetail.imageUrl !== ''
                  ? (
                    <div className={styles.thumbWrap}>
                      <div className={styles.thumbInfo}>
                        <img src={rowDetail.imageUrl} />

                        <a className={styles.prewModal}>
                          <Icon type='delete' style={{ fontSize: '20px' }}
                            onClick={() => this.handleRemoveImage()}
                          />
                        </a>
                      </div>
                    </div>
                  )
                  : (
                    <Upload
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <div>
                        <Icon type='plus' />
                        <div style={{ fontSize: '12px' }}>点击上传</div>
                      </div>

                    </Upload>
                  )
              }
            </div>

            <div className={styles.inputArea}>
              <Form>
                <FormItem label='文案'>
                  {
                    getFieldDecorator('remark', {
                      initialValue: rowDetail.remark || '',
                      rules: [
                        { required: true, message: '请填写文案' }
                      ]
                    })(
                      <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Form>
            </div>

          </Row>
        </Modal>
      </div>
    )
  }
}

const SetfloorModal = Form.create()(SetfloorForm)

export default SetfloorModal
