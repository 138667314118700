import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Table, Tabs, Row, Col, Button, Icon, Badge, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import UploadWorkOrderModal from './uploadWorkOrderModal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderWorkOrderDueStatusLabel, renderWorkStatusLabel, renderWorkTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './index.module.scss'

const { downloadCommonTemplate } = DownloadApi
const { pageSizeOptions, workOrderStateMaps, workOrderTypeMaps, enquireOverdueStatusMaps } = filter
const { MAX_PAGESIZE } = consts
const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'ALL', name: '全部工单' },
  { id: 'DISTRIBUTED_OF_MINE', name: '我派发的工单' },
  { id: 'DONE_OF_MINE', name: '已处理工单' },
  { id: 'UNDO_OF_MINE', name: '待处理工单' }
]

class WorkOrderForm extends Component {
  constructor (props) {
    super(props)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'workOrderId',
        formType: 'Input',
        itemParams: {
          label: '工单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributedTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '派发日期'
        },
        cptParams: {}
      },
      {
        name: 'fromPlatformUserId',
        formType: 'Select',
        itemParams: {
          label: '发布人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 'FROMPLAT')
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'toPlatformUserId',
        formType: 'Select',
        itemParams: {
          label: '处理人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 'TOPLAT')
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'handlingTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '处理日期'
        },
        cptParams: {}
      },
      {
        name: 'dueStatus',
        formType: 'Select',
        itemParams: {
          label: '是否逾期'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...enquireOverdueStatusMaps
        ]
      },
      {
        name: 'workOrderState',
        formType: 'Select',
        itemParams: {
          label: '工单状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...workOrderStateMaps
        ]
      },
      {
        name: 'workOrderType',
        formType: 'Select',
        itemParams: {
          label: '工单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...workOrderTypeMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    activeKey: 'ALL',
    query: {},
    tableData: [],
    uploadWorkOrderModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'workorderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '协作工单编号',
      key: 'workorderCol-1',
      dataIndex: 'workOrderId'
    },
    {
      title: '派发日期',
      key: 'workorderCol-2',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '发布人',
      key: 'workorderCol-3',
      dataIndex: 'fromPlatformUserName'
    },
    {
      title: '工单时限',
      key: 'workorderCol-4',
      render: (text, record, index) => (
        <>{ record.limitTime ? moment(record.limitTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '处理时间',
      key: 'workorderCol-5',
      render: (text, record, index) => (
        <>{ record.handlingTime ? moment(record.handlingTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '处理人',
      key: 'workorderCol-6',
      dataIndex: 'toPlatformUserName'
    },
    {
      title: '是否逾期',
      key: 'workorderCol-7',
      dataIndex: 'dueStatus',
      render: (text, record, index) => (
        <>{ renderWorkOrderDueStatusLabel(record.dueStatus) }</>
      )
    },
    {
      title: '工单状态',
      key: 'workorderCol-8',
      dataIndex: 'workOrderState',
      render: (text, record, index) => (
        <>{ renderWorkStatusLabel(record.workOrderState) }</>
      )
    },
    {
      title: '工单类型',
      key: 'workorderCol-9',
      dataIndex: 'workOrderType',
      render: (text, record, index) => (
        <>{ renderWorkTypeLabel(record.workOrderType) }</>
      )
    },
    {
      title: '操作',
      key: 'workorderCol-10',
      render: (text, record, index) => (
        <>
          {
            record.operateAuthority
              ? <Button type='link' size='small' onClick={() => this.handleLinkToWorkHandle(record)}>处理工单</Button>
              : <Button type='link' size='small' onClick={() => this.handleLinkToWorkDetails(record)}>查看详情</Button>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getWorkOrdersList()
  }

  // 获取全部成员
  getMemberList = (val = '', type) => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        if (type === 'FROMPLAT') {
          searchData[2].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []
        }

        if (type === 'TOPLAT') {
          searchData[3].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []
        }

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取工单列表
  getWorkOrdersList = () => {
    const { pages, query = {}, activeKey = 'ALL' } = this.state
    const params = {
      ...query,
      queryType: activeKey,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getWorkOrdersList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.distributedTimes) {
      search['distributedDateBegin'] = moment(search.distributedTimes[0]).format('YYYY-MM-DD')
      search['distributedDateEnd'] = moment(search.distributedTimes[1]).format('YYYY-MM-DD')
      delete search.distributedTimes
    }

    if (search.handlingTimes) {
      search['handlingDateBegin'] = moment(search.handlingTimes[0]).format('YYYY-MM-DD')
      search['handlingDateEnd'] = moment(search.handlingTimes[1]).format('YYYY-MM-DD')
      delete search.handlingTimes
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getWorkOrdersList()
    })
  }

  // 点击切换tab
  handleChangeTabPane = (key) => {
    const { activeKey = 'ALL', pages } = this.state

    if (key === activeKey) { return false }

    this.props.form.resetFields()

    this.setState({
      query: {},
      activeKey: key,
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20,
        total: 0
      }
    }, () => {
      this.getWorkOrdersList()
    })
  }

  // 点击跳转处理工单
  handleLinkToWorkHandle = (record) => {
    this.props.history.push({ pathname: '/admin/workHandle', state: { id: record.id, workOrderType: record.workOrderType } })
  }

  // 点击跳转查看详情
  handleLinkToWorkDetails = (record) => {
    this.props.history.push({ pathname: '/admin/workDetails', state: { id: record.id, workOrderType: record.workOrderType } })
  }

  // 点击下载模板
  handleDownloadTemplate = () => {
    downloadCommonTemplate({ excelTemplateType: 'ENQUIRY_WORK_ORDER_IMPORT' })
  }

  // 点击导入工单按钮
  handleShowUploadWorkOrderModal = () => {
    this.setState({
      uploadWorkOrderModal: true
    })
  }

  // 导入型号-监听上传EXCEL
  handleChangeUploadFile = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        const { distributorId } = this.state
        this.props.history.push({ pathname: '/admin/matchSheet', state: { importId: data, sourceType: 'UPLOAD_WORK_ORDER', defaultParams: { distributorId: distributorId } } })
      }
    } else if (info.file.status === 'error') {
      message.error('上传失败')
    }
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getWorkOrdersList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getWorkOrdersList()
    })
  }

  closeModal = () => {
    this.setState({
      uploadWorkOrderModal: false
    })
  }

  render () {
    const { searchData, pages, tableData, uploadWorkOrderModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div className={`${styles.tabBarLand}`}>
          <div className={`${styles.tabBar}`}>
            <Tabs onChange={(key) => this.handleChangeTabPane(key)}>
              {
                tabPaneMaps.map((item, i) => (
                  <TabPane tab={item.name} key={item.id} />
                ))
              }
            </Tabs>
          </div>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Col span={16}>
              <Button type='primary' onClick={() => this.handleDownloadTemplate()}><Icon type='download' />下载模板</Button>

              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleShowUploadWorkOrderModal()}><Icon type='upload' />导入工单</Button>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <span className={`${styles.tag}`}>
                <Badge color='#FFA39E' text='已逾期' />
              </span>
            </Col>
          </Row>

          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            rowClassName={(record, index) => { return record.dueStatus === 'OVERDUE' ? styles.errorColor : null }}
          />
        </Card>

        {
          uploadWorkOrderModal
            ? <UploadWorkOrderModal history={this.props.history}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const WorkOrder = Form.create()(WorkOrderForm)

export default WorkOrder
