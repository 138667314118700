import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, Col, Alert, Statistic } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderCustomerAuthorLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, authorMaps } = filter

export default class CloudSuppos extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  static propTypes = {
    history: PropTypes.object
  }
  state = {
    detailModal: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorType',
        formType: 'Select',
        itemParams: {
          label: '商户权限'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...authorMaps
        ]
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      }
    ],
    brandTotal: 0,
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    selectedRowKeys: [],
    query: {}
  }

  columns = [
    {
      title: '序号',
      key: 'supplist-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '权限',
      key: 'supplist-1',
      render: (text, record, index) => (
        renderCustomerAuthorLabel(record.type)
      )
    },
    {
      title: '公司名称',
      key: 'supplist-2',
      dataIndex: 'merchantName'
    },
    {
      title: '地址',
      key: 'supplist-3',
      dataIndex: 'address'
    },
    {
      title: '云供应品牌数',
      key: 'supplist-4',
      dataIndex: 'cloudBrandTotal'
    },
    {
      title: '云供应商品数',
      key: 'supplist-5',
      dataIndex: 'cloudSkuTotal'
    },
    {
      title: '云库库存价值',
      key: 'supplist-6',
      dataIndex: 'totalAmount',
      render: (text, record, index) => (
        <>&yen;{+record.totalAmount}</>
      )
    },
    {
      title: '云仓库存价值',
      key: 'supplist-7',
      dataIndex: 'cloudTotalAmount',
      render: (text, record, index) => (
        <>&yen;{+record.cloudTotalAmount}</>
      )
    },
    {
      title: '操作',
      key: 'supplist-8',
      width: 250,
      render: (text, record, index) => (
        <>
          <a onClick={() => this.handleJumpToDetail(record)}>
            查看虚拟库
          </a>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getCloudInventoryList()
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[1].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[2].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取虚拟库列表
  getCloudInventoryList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudInventoryList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          brandTotal: data.brandTotal || 0,
          brandTotalForCloud: data.brandTotalForCloud || 0,
          tableData: data && data.skuStatisticsResult && data.skuStatisticsResult.list ? data.skuStatisticsResult.list : [],
          pages: {
            ...pages,
            total: data && data.skuStatisticsResult ? data.skuStatisticsResult.total : 0
          }
        })
      }
    })
  }

  // 获取虚拟库明细
  getMerchantInfo = (params) => {
    return Api.getMerchantCloudSkuList(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => this.getCloudInventoryList())
  }

  // 跳转
  handleJumpToDetail = (record) => {
    this.props.history.push({ pathname: '/admin/inventDetail', state: record })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudInventoryList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudInventoryList()
    })
  }

  render () {
    const { tableData, brandTotal = 0, brandTotalForCloud = 0, pages, searchData } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Alert type='info' message={
                <Row gutter={24}>
                  <Col span={4}>
                    <Statistic title='云库品牌合计' value={+brandTotal}
                      valueStyle={{ color: '#52C41A' }}
                    />
                  </Col>
                  <Col span={4}>
                    <Statistic title='云仓品牌合计' value={+brandTotalForCloud}
                      valueStyle={{ color: '#52C41A' }}
                    />
                  </Col>
                </Row>
              } />
            </Row>

            <Row>
              <Table rowKey='distributorId' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
              />
            </Row>
          </Card>
        </>
      </>
    )
  }
}
