import React, { Component } from 'react'
import { Button, Card, InputNumber, message } from 'antd'
import Api from '@/common/api/index'

export default class ShowNumber extends Component {
  state = {
    value: ''
  }

  componentDidMount () {
    this.getPartnersTotal()
  }

  onChange = (value) => {
    this.setState({
      value: value
    })
  }

  getPartnersTotal = () => {
    Api.getPartnersTotal({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          value: data
        })
      }
    })
  }

  // 保存合作伙伴数量
  savePartnersTotal=(param) => {
    Api.savePartnersTotal(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('保存成功')
        this.getPartnersTotal()
      }
    })
  }

  // 保存
  handleSave = () => {
    const { value } = this.state
    let param = {
      partnersTotal: value
    }
    this.savePartnersTotal(param)
  }

  render () {
    const { value } = this.state
    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <label>合作伙伴数量(位):</label> <InputNumber value={value} min={0} style={{ width: '200px' }} onChange={(e) => { this.onChange(e) }} />
          <div style={{ width: '100px', margin: '0 auto', marginTop: '200px' }}>
            <Button type='primary' onClick={() => { this.handleSave() }}>保存</Button>
          </div>
        </Card>
      </>
    )
  }
}
