import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Radio } from 'antd'

const FormItem = Form.Item
const downloadLayoutMaps = [
  { id: 'PDF', name: 'PDF' },
  { id: 'WORD', name: 'Word' }
]

// 支付信息下载弹窗
class PayBankDownForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  componentDidMount () {}

  // 点击确定
  handleConfirm = () => {
    const { form, detailInfo = {}, onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        onConfirm({ distributorId: detailInfo.distributorId }, values.type)
      }
    })
  }

  render () {
    const { form, onCancel } = this.props
    const { getFieldDecorator } = form
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    }

    return (
      <>
        <Modal title='支付信息导出'
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <FormItem label='导出格式' {...formItemLayout}>
              {
                getFieldDecorator('type', {
                  initialValue: 'PDF',
                  rules: [
                    { required: true, message: '请选择要导出的格式' }
                  ]
                })(
                  <Radio.Group>
                    {
                      downloadLayoutMaps.map((item) => (
                        <Radio value={item.id} key={item.id}>{ item.name }</Radio>
                      ))
                    }
                  </Radio.Group>
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const PayBankDownFormModal = Form.create()(PayBankDownForm)

export default PayBankDownFormModal
