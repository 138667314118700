import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button } from 'antd'
import $lodash from 'lodash'

import InvoiceExpressForm from '@/components/invoiceExpressForm/index' // 开票物流&凭证信息
import InvoiceAccount from '@/components/invoiceAccount/index' // 开票企业信息
import InvoiceProduct from '@/components/invoiceProduct/index' // 开票物资信息
import InvoiceReceiving from '@/components/invoiceReceiving/index' // 发票收件地址
import AddressModal from '@/components/address-modal/index' // 地址弹窗

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import consts from '@/common/utils/consts'

const addressType = 'PLATFORM_SENDER_ADDRESS'
const { MAX_NUMBER } = consts

class InvoiceApplyForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    ids: PropTypes.array,
    distributorId: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    invoiceInfo: {},
    count: 0,
    invoiceCodeKeys: [{ id: 0, value: undefined }],
    tableData: [],
    receivingAddressList: [],
    sendAddressList: [], // 发货地址列表
    totalAmount: 0,
    addressModal: false
  }

  componentDidMount () {
    this.getPurchaseOrderInvoiceUnOpenDetails()
    this.getMerchantReceivingInvoiceAddressList()
    this.getAddressList()
  }

  // 云采购发票- 获取待开票详情
  getPurchaseOrderInvoiceUnOpenDetails = async () => {
    const { ids = [], distributorId = null } = this.props
    const res = await Api.getPurchaseOrderInvoiceUnOpenDetails({ ids: ids, distributorId: distributorId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        invoiceInfo: data.invoiceInfo || {},
        tableData: data.orderGoodsListMap || [],
        totalAmount: data.invoiceAmount || 0
      })
    }
  }

  // 获取商户收票地址列表
  getMerchantReceivingInvoiceAddressList = async () => {
    const { distributorId = null } = this.props
    const res = await Api.getMerchantReceivingInvoiceAddressList({ distributorId: distributorId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        receivingAddressList: data || []
      })
    }
  }

  // 获取IIASaaS发货地址列表
  getAddressList = async () => {
    const res = await Api.getAddressList({ addressType: addressType, bindData: MAX_NUMBER })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        sendAddressList: data || []
      })
    }
  }

  // 新增商户收票地址
  saveMerchantReceivingInvoiceAddress = async (params) => {
    const { distributorId } = this.props
    const res = await Api.saveMerchantReceivingInvoiceAddress({ ...params, status: params.defaultType, distributorId: distributorId })
    const { code } = res
    if (+code === 10000) {
      this.closeModal()
      this.getMerchantReceivingInvoiceAddressList()
    }
  }

  // 点击提交
  handleSubmit = () => {
    const { form, ids = [], distributorId = null, onConfirm } = this.props
    const { totalAmount = 0 } = this.state

    form.validateFields((err, values) => {
      if (!err) {
        const { courierCode, invoiceCodeKeys = [] } = values
        const _invoiceCodeKeys = $lodash.filter(invoiceCodeKeys, (o) => { return o !== undefined })

        values.courierCode = $lodash.includes(courierCode, 'SAAS') ? $lodash.split(courierCode, '-')[1] : courierCode
        values.invoiceCode = _invoiceCodeKeys.length > 0 ? _invoiceCodeKeys.join(',') : undefined
        values.ids = ids
        values.distributorId = distributorId
        values.amount = totalAmount

        onConfirm(values)
      }
    })
  }

  // 点击导出
  handleDownload = () => {
    const { ids = [], distributorId = null } = this.props

    DownloadApi.downloadPurchaseOrderInvoiceUnOpenProducts({ ids: ids, distributorId: distributorId })
  }

  // 点击导出电子发票表格
  handleDownloadElectronic = () => {
    const { ids = [], distributorId = null } = this.props

    DownloadApi.downloadPurchaseOrderElectronicInvoiceUnOpenProducts({ ids: ids, distributorId: distributorId })
  }

  // 新增地址
  showAddressModal = () => {
    this.setState({
      addressModal: true
    })
  }

  // 新增invoiceCode FormItem
  handleAddInvoiceCodeFormItem = (index) => {
    const { count = 0, invoiceCodeKeys = [{ id: 0, value: undefined }] } = this.state

    invoiceCodeKeys.splice(index + 1, 0, { id: count + 1, value: undefined })

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys,
      count: count + 1
    })
  }

  // 新增invoiceCode FormItem
  handleRemoveInvoiceCodeFormItem = (index) => {
    const { invoiceCodeKeys = [0] } = this.state

    if (invoiceCodeKeys.length === 1) { return false }

    invoiceCodeKeys.splice(index, 1)

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys
    })
  }

  closeModal = () => {
    this.setState({
      addressModal: false
    })
  }

  render () {
    const { form, onCancel } = this.props
    const { invoiceCodeKeys = [], invoiceInfo = {}, tableData = [], receivingAddressList = [], sendAddressList = [], totalAmount = 0, addressModal = false } = this.state

    return (
      <>
        <Modal title='发票信息' width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'auto' }}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={<div key='btns' style={{ textAlign: 'center' }}>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleDownloadElectronic()}>导出电子发票表格</Button>
            <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleDownload()}>导出纸质发票表格</Button>
            <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleSubmit()}>确认开票</Button>
          </div>}
        >
          <Form>
            <div>
              <InvoiceExpressForm form={form}
                invoiceCodeKeys={invoiceCodeKeys}
                addInvoiceCodeFormItem={(index) => this.handleAddInvoiceCodeFormItem(index)}
                removeInvoiceCodeFormItem={(index) => this.handleRemoveInvoiceCodeFormItem(index)}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InvoiceAccount invoiceInfo={invoiceInfo} />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceProduct list={tableData} totalAmount={totalAmount} />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceReceiving form={form}
                addressList={receivingAddressList}
                sendAddressList={sendAddressList}
                handleAddAddress={() => this.showAddressModal()}
              />
            </div>
          </Form>
        </Modal>

        {
          addressModal
            ? <AddressModal type={1}
              onConfirm={(params) => this.saveMerchantReceivingInvoiceAddress(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const InvoiceApplyModal = Form.create()(InvoiceApplyForm)

export default InvoiceApplyModal
