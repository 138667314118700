import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Card, Table, Button, Tag, Icon, Tooltip, message } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import ColumnsConfigModal from '@/components/columnsConfigModal' // 列设置弹窗

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { setTableColumnKeys } from '@/reducers/action'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, repLineBreak } = filter
const { downCloudTransactionNotRateRecords } = DownloadApi

const columnConfigKey = 'CLOUDTRANSACTIONRECORDSNORATE_SPOTLIST_TABLE'

class CloudSpotRecordsNotRate extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    dispatch: PropTypes.func,
    userInfo: PropTypes.object,
    columnsMap: PropTypes.object
  }

  state={
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '交易时间'
        },
        cptParams: {

        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '采购商'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val, 1)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'supplierId',
        formType: 'Select',
        itemParams: {
          label: '供应商'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val, 2)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    columnsConfigModal: false // 列设置弹窗
  }

  baseColumns = [
    {
      title: '序号',
      key: 'column-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'cloudTransactionRecordsNotRateCol-orderId',
      dataIndex: 'orderId'

    },
    {
      title: '品牌',
      key: 'cloudTransactionRecordsNotRateCol-brandName',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'cloudTransactionRecordsNotRateCol-model',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'cloudTransactionRecordsNotRateCol-itemNumber',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'cloudTransactionRecordsNotRateCol-name',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>
            <span>{ record.name }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品类型',
      key: 'cloudTransactionRecordsNotRateCol-skuGoodsType',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '成交价格',
      key: 'cloudTransactionRecordsNotRateCol-tradingAmount',
      dataIndex: 'tradingAmount',
      render: (text, record, index) => (
        <div style={{ color: record.specialPriceFlag && record.specialPriceFlag === 'SHOW_FLAG' ? '#F5222D' : '' }}>
          {
            record.specialPriceFlag && record.specialPriceFlag === 'SHOW_FLAG'
              ? <span>特</span>
              : null
          }
          <span>{ record.tradingAmount ? <>&yen;{+record.tradingAmount}</> : '----' }</span>
        </div>
      )
    },
    {
      title: '成交数量',
      key: 'cloudTransactionRecordsNotRateCol-quantity',
      dataIndex: 'quantity'
    },
    {
      title: '交易时间',
      key: 'cloudTransactionRecordsNotRateCol-orderTime',
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>
          { record.orderTime && record.orderTime !== '' ? record.orderTime : '----' }
        </>
      )
    },
    {
      title: '采购商',
      key: 'cloudTransactionRecordsNotRateCol-distributor',
      ellipsis: true,
      dataIndex: 'distributor',
      render: (text, record, index) => (
        <>{ record.distributor || '----' }</>
      )
    },
    {
      title: '供应商',
      key: 'cloudTransactionRecordsNotRateCol-supplier',
      ellipsis: true,
      dataIndex: 'supplier',
      render: (text, record, index) => (
        <>{ record.supplier || '----' }</>
      )
    },
    {
      title: '操作人',
      key: 'cloudTransactionRecordsNotRateCol-operator',
      dataIndex: 'operator',
      render: (text, record, index) => (
        <>{ record.operator || '----' }</>
      )
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    this.getCloudTransactionRecordsNotRateList()
  }

  // 获取云交易列表
  getCloudTransactionRecordsNotRateList = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      skuGoodsType: 'CLOUD_SPOT',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudTransactionRecordsNotRateList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val, key) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state
        key === 1
          ? searchData[3].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)
          : searchData[4].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 保存列表项配置
  saveUserTableColumnSetting = async (params, newcolumnsMap) => {
    const res = await Api.saveUserTableColumnSetting(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.props.dispatch(setTableColumnKeys({ columnsMap: newcolumnsMap }))

      this.closeModal()
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search['beginDate'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.times[1]).format('YYYY-MM-DD')
      delete search.times
    }
    if (search.itemNo) {
      search['itemNo'] = repLineBreak(search.itemNo, '<br>')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => this.getCloudTransactionRecordsNotRateList())
  }

  // 导出当月信息
  exportInfo = () => {
    downCloudTransactionNotRateRecords({ skuGoodsType: 'CLOUD_SPOT' })
  }

  // 点击列设置
  showColumnsConfigModal = () => {
    this.setState({
      columnsConfigModal: true
    })
  }

  // 确定保存列表项配置
  handleConfirmTableColumnSetting = (selectedKeys, newcolumns) => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: {
        selectedColumnKeys: [...selectedKeys],
        sortColumnKeys: [...newcolumns]
      }
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  // 确定重置列表项配置
  handleResetTableColumnSetting = () => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: null
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  closeModal = () => {
    this.setState({
      columnsConfigModal: false
    })
  }

   // 翻页
   pageChange = (page, pageSize) => {
     const { pages } = this.state
     this.setState({
       pages: {
         ...pages,
         pageNo: page
       }
     }, () => {
       this.getCloudTransactionRecordsNotRateList()
     })
   }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudTransactionRecordsNotRateList()
    })
  }

  render () {
    const { columnsMap = {} } = this.props
    const { searchData, tableData, pages, columnsConfigModal = false } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    /**
     * 获取redux缓存里面的相关页面的列展示信息
     *   sortColumnKeys: 排序后的所有列的key selectedColumnKeys:要展示的列的key
     * 1- 首先将baseColumns列顺序按 sortColumnKeys 里面的顺序排序
     * 2- 将baseColumns过滤 保留包含在 selectedColumnKeys中的key
     */
    const sortColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].sortColumnKeys ? columnsMap[columnConfigKey].sortColumnKeys : []
    const selectedColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys ? columnsMap[columnConfigKey].selectedColumnKeys : []

    const newArr = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : this.baseColumns

    // 列表展示的项
    const tableColumns = selectedColumnKeys.length > 0 ? $lodash.filter(newArr, (record) => { return $lodash.includes(selectedColumnKeys, record.key) }) : newArr

    const _baseColumns = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : $lodash.map(this.baseColumns, (record) => { return { key: record.key, title: record.title } })

    const columnsConfig = $lodash.filter(_baseColumns, (o) => { return !$lodash.includes(['column-0', 'column-operations'], o.key) })

    const checkedColKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys
      ? columnsMap[columnConfigKey].selectedColumnKeys
      : $lodash.map(columnsConfig, (o) => { return o.key })

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Col span={16}>
              <Button type='primary' onClick={() => { this.exportInfo() }}><Icon type='download' />导出当月数据</Button>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Tooltip title='列设置'>
                <a style={{ display: 'inline-block', padding: '0px 6px', lineHeight: '32px' }} onClick={() => this.showColumnsConfigModal()}>
                  <Icon type='setting' style={{ fontSize: '20px' }} />
                </a>
              </Tooltip>
            </Col>
          </Row>

          <Table bordered rowKey={(record, i) => `${record.orderId}-${i}`}
            columns={tableColumns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        { // 列设置弹窗
          columnsConfigModal
            ? <ColumnsConfigModal
              configKey={columnConfigKey}
              selectedColumnKeys={checkedColKeys}
              disabledBeforeColumns={[{ key: 'column-0', title: '序号' }]}
              allcolumns={columnsConfig}
              onConfirm={(checkedKeys, columns) => this.handleConfirmTableColumnSetting(checkedKeys, columns)}
              onReset={() => this.handleResetTableColumnSetting()}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo || {},
    columnsMap: state.columnsMap || {}
  }
}

export default connect(mapStateToProps)(CloudSpotRecordsNotRate)
