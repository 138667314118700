import Ajax from '../utils/ajax'

export default {
  /* 信用 */
  // 获取云平台信用列表
  getPlatCreditList (params) {
    return Ajax.post('/api/credit/list/page/credit', params)
  },

  // 获取云平台信用流水
  getPlatCreditFlow (params) {
    return Ajax.post('/api/credit/list/page/credit/flow', params)
  },

  // 获取云平台信用账单列表
  getPlatCreditBillList (params) {
    return Ajax.post('/api/credit/list/page/credit/bill', params)
  },

  /* 余额 */
  // 获取商户余额列表
  getMerchantBalanceList (params) {
    return Ajax.post('/platform/finance/distributorBalanceList', params)
  },

  // 商户余额充值
  saveBalanceRecharge (params) {
    return Ajax.post('/platform/finance/distributorBalanceRecharge', params)
  },

  // 获取商户余额流水
  getMerchantBalanceFlow (params) {
    return Ajax.post('/platform/finance/distributorBalanceFlowList', params)
  },

  // 获取商户余额提现申请列表
  getCashoutApplyList (params) {
    return Ajax.post('/platform/finance/listPageForBalanceWithdrawApply', params)
  },

  // 商户余额核销
  setWriteOffBabalanceInMerchant (params) {
    return Ajax.post('/platform/finance/chargeOffSaaSFeeFromBalance', params)
  },

  /* 付款 */
  // 更新打款状态
  updateCashoutStatus (params) {
    return Ajax.post('/platform/finance/distributorBalanceWithdraw', params)
  },

  /* 发票 */
  // 获取IIASAAS发票信息
  getInvoiceInfoByIIASAAS (params) {
    return Ajax.post('/platform/platOpenInvoice/getMerchantOpenInvoiceInfo', params)
  },

  // 采购发票-待开票订单产品集合
  getInvoiceProductsByPurchOrder (params) {
    return Ajax.post('/platform/platOpenInvoice/listInvoiceOrderSkuInfo', params)
  },

  // IIASaaS保险发票-申请发票
  saveApplyInsuranceInvoice (params) {
    return Ajax.post('/logistics/pingAnInsureBill/invoice/makeOutInvoice', params)
  },

  // IIASaaS保险发票-保险发票列表
  getInsuranceInvoiceList (params) {
    return Ajax.post('/logistics/pingAnInsureBill/invoice/listPageForInvoice', params)
  },

  // IIASaaS保险发票-获取保险发票详情
  getInsuranceInvoiceDetail (params) {
    return Ajax.post('/logistics/pingAnInsureBill/invoice/insureInvoiceDetail', params)
  },

  // IIASaaS保险发票-开具保险发票
  saveOpenedinsuranceInvoice (params) {
    return Ajax.post('/logistics/pingAnInsureBill/invoice/insureInvoiceSendOut', params)
  },

  // IIASaaS快递发票-快递服务申请开票
  saveApplyExpressInvoice (params) {
    return Ajax.post('/api/logistics/deppon/billInvoice/makeOutInvoice', params)
  },

  // IIASaaS快递发票-快递发票列表
  getExpressInvoiceList (params) {
    return Ajax.post('/api/logistics/deppon/billInvoice/listPage', params)
  },

  // IIASaaS快递发票-获取快递发票详情
  getExpressInvoiceDetail (params) {
    return Ajax.post('/api/logistics/deppon/billInvoice/detail', params)
  },

  // IIASaaS快递发票-开具快递发票
  saveOpenedExpressInvoice (params) {
    return Ajax.post('/api/logistics/deppon/billInvoice/sendOut', params)
  },

  /* 快递 */
  // 获取快递记录列表
  getRecordList (params) {
    return Ajax.post('/api/logistics/record/listPageSaasServiceFlow', params)
  },

  // 获取初步快递核算单列表
  getOneExpressList (params) {
    return Ajax.post('/api/logistics/deppon/platform/fee/verify/list/page', params)
  },

  // 获取初步快递核算单任务进度信息
  getOneExpressTaskList (params) {
    return Ajax.post('/api/excel/upload/task/log/list/page', params)
  },

  // 获取初步快递核算单任务进度失败信息
  getOneFailTaskList (params) {
    return Ajax.post('/api/excel/upload/task/log/list/page/details', params)
  },

  // 初步快递核算单确认提交接口
  getOneAddInfo (params) {
    return Ajax.post('/api/logistics/deppon/platform/fee/verify/confirmSubmitToMerchant', params)
  },

  // 客户快递核算单确认发布接口
  getCusAddInfo (params) {
    return Ajax.post('/api/logistics/deppon/merchant/fee/verify/release', params)
  },

  // 获取初步/客户快递核算单增加备注接口
  setOneAddRemarks (params) {
    return Ajax.post('/api/logistics/deppon/platform/fee/verify/addRemarks', params)
  },

  // 获取初步/客户快递核算单更改信息接口
  setOneUpdateInfo (params) {
    return Ajax.post('/api/logistics/deppon/platform/fee/verify/updatePlatformVerifyFeeInfo', params)
  },

  // 获取初步快递核算单备注列表接口
  getOneAddRemarksList (params) {
    return Ajax.post('/api/logistics/deppon/platform/fee/verify/listRemarks', params)
  },

  // 获取初步快递核算单编辑总金额字段接口
  getUpdateTotalPrice (params) {
    return Ajax.post('/api/logistics/deppon/platform/fee/verify/updatePlatformTotalPrice', params)
  },

  // 获取客户快递核算单列表
  getCusExpressList (params) {
    return Ajax.post('/api/logistics/deppon/merchant/fee/verify/list/page', params)
  },

  // 客户快递核算单一键生成账单
  getCusZdList (params) {
    return Ajax.post('/api/logistics/deppon/bill/generate', params)
  },

  // 编辑客户快递运算总金额字段
  getUpdataCusTotalPrice (params) {
    return Ajax.post('/api/logistics/deppon/merchant/fee/verify/updateMerchantTotalPrice', params)
  },

  // 快递对账单确认收款
  saveExpresBillCollect (params) {
    return Ajax.post('/api/logistics/deppon/bill/pay', params)
  },

  /* 保险 */
  // 获取保险单流水记录列表
  setInsuranceslipList (params) {
    return Ajax.post('/insurance/listPageFlow', params)
  },

  // 获取保险单流水单货物明细列表
  getInsuranceGoodslist (params) {
    return Ajax.post('/insurance/listPagePickingBatchSkuInfo', params)
  },

  // 获取保险对账单列表
  getInsuranceBillList (params) {
    return Ajax.post('/insurance/billPageQuery', params)
  },

  // 获取保险对账单列表
  getlogisticsBillList (params) {
    return Ajax.post('/api/logistics/deppon/bill/list/page', params)
  },

  // 保险对账单确认收款
  saveInsuranceBillCollect (params) {
    return Ajax.post('/insurance/billAddPayAmount', params)
  },

  /* 其他 */
  // 获取EU订单导出路径
  getDownloadEuorder (params) {
    return Ajax.post('/platform/finance/euOrderListByParamExport', params)
  },

  // 获取物流对账单导出路径
  getLogisticsBillDownloadUrl (params) {
    return Ajax.post('/platform/finance/disSaasFeeListExport', params)
  },

  // 获取商户开票信息
  getDistributorInvoiceTitleDetails (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/getDistributorInvoiceInfo', params)
  },

  // 保存编辑商户开票信息
  saveEditDistributorInvoiceTitle (params) {
    return Ajax.post('/api/platform/enquiry/quotationOrder/modifyDistributorInvoiceInfo', params)
  }
}
