import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Table, Button, Icon, message } from 'antd'
import $lodash from 'lodash'

import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderAddressLabel, renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

const { downSupplyOrderDetail } = DownloadApi
const { pageSizeOptions } = filter

export default class DetailModal extends Component {
  static propTypes = {
    ident: PropTypes.string, // 组件标识
    buttonMaps: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    getDetail: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    addressInfo: {} // 收货地址
  }
  columns = [
    {
      title: '序号',
      key: 'euorderProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'euorderProductCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'euorderProductCol-2',
      width: 140,
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'euorderProductCol-3',
      width: 130,
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'euorderProductCol-4',
      width: 150,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'euorderProductCol-5',
      width: 80,
      dataIndex: 'unit'
    },
    {
      title: '产品类型',
      key: 'euorderProductCol-6',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '数量',
      key: 'euorderProductCol-7',
      dataIndex: 'quantity'
    },
    {
      title: '货期',
      key: 'euorderProductCol-8',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>
          {
            ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType)
              ? <>{ record.deliveryPeriodDays ? `${record.deliveryPeriodDays}个工作日` : '当天发货' }</>
              : '当天发货'
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'euorderProductCol-9',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => (
        <>
          {
            ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType)
              ? <>{ (+record.prepaymentRatio * 100 / 100).toFixed(2) }%</>
              : '100%'
          }
        </>
      )
    },
    {
      title: '未税单价',
      key: 'euorderProductCol-10',
      width: 100,
      dataIndex: 'excludingTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.excludingTaxUnitPrice}</>
      )
    },
    {
      title: '含税单价',
      key: 'euorderProductCol-11',
      width: 100,
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'euorderProductCol-12',
      width: 100,
      render: (text, record, index) => (
        <>&yen;{($lodash.round(+record.totalTaxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    const { ident = '', detailInfo = {} } = this.props
    if (ident === 'SUPPLYORDER' || ident === 'PURCHORDER') {
      this.getReceiveAddressByOrderId({ orderId: detailInfo.orderId })
    }

    this.getOrderDetailById()
  }

  // 获取订单详情
  getOrderDetailById = () => {
    const { ident = '', detailInfo = {} } = this.props
    const { pages } = this.state

    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    if (ident === 'EUORDER') {
      params.orderId = detailInfo.orderId
      params.skuGoodsType = detailInfo.skuGoodsType
    }

    if (ident === 'SUPPLYORDER') {
      params.orderId = detailInfo.orderId
    }

    if (ident === 'PURCHORDER') {
      params.purchaseOrderId = detailInfo.purchaseOrderId
      params.distributorId = detailInfo.distributorId
    }

    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.orderDetail.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 根据订单Id获取客户收货地址
  getReceiveAddressByOrderId = (params) => {
    Api.getReceiveAddressByOrderId(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          addressInfo: data || {}
        })
      }
    })
  }

  // 修改客户收货地址
  saveModifyReceivingAddressByOrderId = async (params) => {
    const res = await Api.saveModifyReceivingAddressByOrderId(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()

      const { ident = '', detailInfo = {} } = this.props
      if (ident === 'SUPPLYORDER') {
        this.getReceiveAddressByOrderId({ orderId: detailInfo.orderId })
      }
    }
  }

  // 点击导出明细
  downloadOrderDetail = () => {
    const { detailInfo } = this.props
    let param = {
      orderId: detailInfo.orderId
    }
    downSupplyOrderDetail(param)
  }

  // 点击修改地址
  handleShowAddressModal = () => {
    this.setState({
      addressModal: true
    })
  }

  // 确定保存修改地址
  handleConfirmReceivingAddress = (values) => {
    const { detailInfo } = this.props
    values['orderId'] = detailInfo.orderId

    this.saveModifyReceivingAddressByOrderId(values)
  }

  // 渲染底部按钮
  renderFooterOperatior = () => {
    const { ident = '', buttonMaps = {} } = this.props
    let arr = [
      <Button key='back' onClick={this.props.onCancel}>
        取消
      </Button>,
      <Button key='submit' type='primary' onClick={this.props.onCancel}>
        确定
      </Button>
    ]
    if (ident === 'SUPPLYORDER' && buttonMaps['dlorderDetail'] && +buttonMaps['dlorderDetail'].auth === 1) {
      let obj = (
        <Button key='download' type='primary' onClick={() => this.downloadOrderDetail()}>
          <Icon type='download' />导出明细
        </Button>
      )

      arr.splice(0, 0, obj)
    }

    return arr
  }

  closeModal = () => {
    this.setState({
      addressModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getOrderDetailById()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getOrderDetailById()
    })
  }

  render () {
    const { ident = '', detailInfo = {} } = this.props
    const { pages, tableData = [], addressInfo = {}, addressModal = false } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Modal title='明细' bodyStyle={{ padding: '16px' }} width={1000}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.props.onCancel}
          footer={this.renderFooterOperatior()}
        >
          {
            ident !== 'EUORDER'
              ? <Row style={{ marginBottom: '10px' }}>
                <Row gutter={12}>
                  <Col span={20}>
                    <div style={{ marginBottom: '5px' }}>
                      <div style={{ display: 'inline-block', marginRight: '30px' }}>
                        <label style={{ marginRight: '5px' }}>收货人:</label>
                        <span>{ident !== 'EUORDER' ? addressInfo.receiver : detailInfo.realName}</span>
                      </div>
                      <div style={{ display: 'inline-block' }}>
                        <label style={{ marginRight: '5px' }}>联系电话:</label>
                        <span>{ident !== 'EUORDER' ? addressInfo.phoneNumber : detailInfo.receiverPhoneNum}</span>
                      </div>
                    </div>
                    <div>
                      <label style={{ marginRight: '5px' }}>客户收货地址:</label>
                      <span>
                        {
                          ident !== 'EUORDER'
                            ? <>{renderAddressLabel(addressInfo.province, addressInfo.city, addressInfo.county, addressInfo.address)}</>
                            : <>{ renderAddressLabel(detailInfo.province, detailInfo.city, detailInfo.county, detailInfo.receiverAddress) }</>
                        }
                      </span>
                    </div>
                  </Col>
                  <Col span={4}>
                    {
                      ident === 'SUPPLYORDER' && detailInfo.orderStatus === 'WAIT_TO_SHIP'
                        ? <a onClick={this.handleShowAddressModal}>修改收货信息</a>
                        : null
                    }
                  </Col>
                </Row>
              </Row>
              : null
          }

          <Row>
            <Table bordered rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType || index}`}
              dataSource={tableData}
              columns={this.columns}
              pagination={pagination}
              scroll={{ x: 1300, y: 400 }}
            />
          </Row>

        </Modal>

        {
          addressModal
            ? <AddressModal
              type={2}
              data={{ ...addressInfo, province: addressInfo.province }}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.handleConfirmReceivingAddress(params)}
            />
            : null
        }
      </>
    )
  }
}
