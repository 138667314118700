import React, { Component } from 'react'
import { Row, Button, Icon, Badge, Pagination, message, Empty } from 'antd'

import EditbannerModal from '../component/editbanner-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import styles from './material.module.scss'

const { pageSizeOptions } = filter
const bannerSizeMaps = [
  { type: 0, label: '740px*460px' },
  { type: 1, label: '1920px*450px' }
]

export default class MaterialContainer extends Component {
  static propTypes = {

  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    type: 1, // 编辑类型 1-新增 2-编辑
    bannerModal: false,
    rowDetail: {},
    bannerSize: 0,
    pageCode: 'MATERIAL'
  }

  componentDidMount () {
    this.getBannerMaterialList()
  }

  // 获取banner模板列表
  getBannerMaterialList = () => {
    const { pages, bannerSize = 0 } = this.state
    let params = {
      type: 'BANNER',
      modelType: bannerSize,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getBannerMaterialList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.resultList || [],
          pages: {
            ...pages,
            total: data.totalNum
          }
        })
      }
    })
  }

  // 保存新增banner模板
  saveAddBannerMaterial = (params) => {
    Api.saveAddBannerMaterial(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getBannerMaterialList()
      }
    })
  }

  // 更新banner模板
  saveUpdateBannerMaterial = (params) => {
    Api.saveUpdateBannerMaterial(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getBannerMaterialList()
      }
    })
  }

  // 点击切换banner尺寸
  handleChangeSize = (record) => {
    this.setState({
      bannerSize: +record.type
    }, () => {
      this.getBannerMaterialList()
    })
  }

  // 点击新增/编辑banner模板
  handleShowEditBannerModal = (record, type) => {
    this.setState({
      bannerModal: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击确定按钮
  handleSaveBannerMaterial = (params) => {
    const { type } = this.state
    if (type === 1) {
      this.saveAddBannerMaterial(params)
    } else {
      this.saveUpdateBannerMaterial(params)
    }
  }

  closeModal = () => {
    this.setState({
      bannerModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getBannerMaterialList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getBannerMaterialList()
    })
  }

  render () {
    const { pages, tableData = [], bannerModal = false, rowDetail = {}, type = 1, bannerSize = 0 } = this.state

    return (
      <>
        <Row style={{ marginBottom: '15px' }} className={`${styles.subnavLand}`}>
          {
            bannerSizeMaps.map((row, n) => (
              <a className={`${styles.categoryItem} ${bannerSize === row.type ? styles.cur : ''}`} key={`bannersize-${n}`}
                onClick={() => this.handleChangeSize(row)}
              >
                {row.label}
              </a>
            ))
          }
        </Row>
        <Row style={{ marginBottom: '15px' }}>
          <Button type='primary' onClick={() => this.handleShowEditBannerModal({}, 1)}>
            <Icon type='plus' />新增
          </Button>
        </Row>

        {
          tableData.length > 0
            ? <Row>
              <ul className={`${styles.materialBody}`}>
                {
                  tableData.map((item, i) => (
                    <li className={`${styles.materialItem}`} key={`material-${i}`}>
                      <div className={`${styles.picLand}`}>
                        <a href={item.imageKey} target='_blank'>
                          <img src={item.imageKey} />
                        </a>
                      </div>
                      <div className={`${styles.txtLand}`}>{item.name}</div>
                      <div className={`${styles.otherLand}`}>
                        <div className={`${styles.flexItem}`}>
                          <Badge status={item.enable === 'ENABLE' ? 'success' : 'error'}
                            text={item.enable === 'ENABLE' ? '启用中' : '禁用中'}
                          />
                        </div>
                        <div className={`${styles.flexItem} ${styles.operate}`}>
                          <a onClick={() => this.handleShowEditBannerModal(item, 2)}>编辑</a>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>

              <Row style={{ paddingTop: '30px', textAlign: 'center' }}>
                <Pagination
                  showSizeChanger
                  total={pages.total}
                  showTotal={(total) => { return `共 ${total} 条` }}
                  current={pages.pageNo}
                  pageSize={pages.pageSize}
                  pageSizeOptions={pageSizeOptions}
                  onChange={this.pageChange}
                  onShowSizeChange={this.pageSizeChange}
                />
              </Row>
            </Row>
            : <Row>
              <Empty />
            </Row>
        }

        {
          bannerModal
            ? <EditbannerModal
              modelType={bannerSize}
              type={type}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleSaveBannerMaterial(params)}
            />
            : null
        }
      </>
    )
  }
}
