import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Table, Button, Switch, message, Alert, Divider, Modal } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

class RoleList extends Component {
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    info: {}, // 部门信息
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'role-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (
        `${index + 1}`
      )
    },
    {
      title: '角色职务',
      key: 'role-1',
      dataIndex: 'roleName'
    },
    {
      title: '职能描述',
      key: 'role-3',
      dataIndex: 'description'
    },
    {
      title: '添加时间',
      key: 'role-4',
      dataIndex: 'createTime'
    },
    {
      title: '是否启用',
      key: 'role-5',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            {
              buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1
                ? <Switch checkedChildren='启用' unCheckedChildren='禁用'
                  checked={+record.status === 1}
                  onChange={(checked) => this.changeRoleStatus(checked, record)}
                />
                : <div>
                  {
                    +record.status === 1 ? '启用' : '禁用'
                  }
                </div>
            }
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'role-6',
      width: 200,
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            {
              buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <a onClick={() => this.handleSetRole(record, 2)}>编辑</a>
                  <Divider type='vertical' />
                </div>
                : null
            }

            {
              buttonMaps && buttonMaps['delete'] && +buttonMaps['delete'].auth === 1
                ? <a onClick={() => this.handleDeleteRole(record)}>删除</a>
                : null
            }

          </div>
        )
      }
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location

    if (!state || !state.detailInfo) {
      return message.warning('参数不合法')
    }

    this.setState({
      info: state.detailInfo || {}
    }, () => {
      this.getDepartRoleList()
    })
  }

  // 获取角色列表
  getDepartRoleList = () => {
    const { pages, info = {} } = this.state
    let params = {
      departId: info.id,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getDepartRoleList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 删除角色
  deleteDepartRole = (params) => {
    Api.deleteDepartRole(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.getDepartRoleList()
      }
    })
  }

  // 保存角色启用/禁用
  updateDepartRoleStatus = (params) => {
    Api.updateDepartRoleStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.getDepartRoleList()
      }
    })
  }

  // 点击切换启用/禁用
  changeRoleStatus = (checked, record) => {
    let params = {
      id: record.roleId,
      status: checked ? 1 : 0
    }

    this.updateDepartRoleStatus(params)
  }

  // 点击新增/编辑角色
  handleSetRole = (record, type = 1) => {
    this.props.history.push({ pathname: '/admin/setAuthor', state: { detailInfo: record, type: type } })
  }

  // 点击删除角色
  handleDeleteRole = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.deleteDepartRole({ id: record.roleId })
      }
    })
  }

  // 点击返回部门列表
  handleJumpToDepart = () => {
    this.props.history.push({ pathname: '/admin/departList' })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getDepartRoleList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getDepartRoleList()
    })
  }

  render () {
    const { buttonMaps } = this.props
    const { pages, tableData, info = {} } = this.state

    const pagination = {
      showSizeChanger: false,
      total: pages.total,
      showTotal: (total) => { return `共${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Card>
          <Row style={{ marginBottom: '20px' }}>
            <Button icon='left' style={{ marginRight: '30px' }}
              onClick={this.handleJumpToDepart}
            >
              返回部门列表
            </Button>

            {
              buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                ? <Button type='primary' icon='plus'
                  onClick={() => this.handleSetRole({ departName: info.departName, departId: info.id }, 1)}
                >
                  新增角色
                </Button>
                : null
            }

          </Row>
          <Row>
            <div style={{ marginBottom: '10px' }}>
              <Alert type='success' message={`部门：${info.departName}`} />
            </div>
            <div>
              <Table rowKey='roleId' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
              />
            </div>

          </Row>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(RoleList)
