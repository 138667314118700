import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Button, message, Modal } from 'antd'
import $lodash from 'lodash'

import WorkHeader from '../components/workHeader/index'
import WorkContents from '../components/workContents/index'
import WorkEditTable from '../components/workEditTable/index'
import TeamWorkContents from '../components/TeamWorkContents/index'
import TeamWorkEditTable from '../components/TeamWorkEditTable/index'

import Api from '@/common/api/index'

const { confirm, success } = Modal

const customizeRowItem = {
  sellingPrice: null, // 售价
  supplyPrice: null, // 供应价
  quantity: null, // 数量
  deliveryPeriodDays: null, // 是货期
  advancePaymentRate: null, // 预付比例
  isUndertakeFreight: 'UN_UNDERTAKE',
  freightPrice: null,
  remarks: null,
  editable: true
}

class WorkHandleForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    id: null,
    workOrderType: '',
    detailInfo: {},
    enquiryWorkOrderSkuInfoList: [],
    count: 0,
    tableData: [],
    isFirstHandle: true // 第一次处理工单标识
  }

  componentDidMount () {
    const { id, workOrderType } = this.props.history.location.state

    this.setState({
      id: id,
      workOrderType: workOrderType
    }, () => {
      this.getWorkOrderDetail()
    })
  }

  // 获取工单详情
  getWorkOrderDetail = async () => {
    let { id, workOrderType, isFirstHandle = true } = this.state
    const res = await Api.getWorkOrderDetail({ id: id, workOrderType })
    const { code, data } = res
    if (+code === 10000) {
      let newArr = []
      if (data && data.quotationDetails && data.quotationDetails.length > 0) {
        newArr = $lodash.map(data.quotationDetails, (row) => { return { rowKey: row.id, ...row } })
        isFirstHandle = false
      } else {
        newArr = this.initDefaultTableData(workOrderType, data.workOrderSkuInfoList)
      }

      this.setState({
        detailInfo: data || {},
        enquiryWorkOrderSkuInfoList: data.workOrderSkuInfoList || [],
        tableData: newArr,
        isFirstHandle: isFirstHandle
      })
    }
  }

  // 保存工单处理
  saveHandleWorkOrderDetail = async (params) => {
    const res = await Api.saveHandleWorkOrderDetail(params)
    const { code } = res
    if (+code === 10000) {
      success({
        title: '保存成功',
        onOk: () => {
          this.props.history.push({ pathname: '/admin/workOrders' })
        }
      })
    }
  }

  // 点击保存
  handleSave = () => {
    const { tableData = [], detailInfo = {}, enquiryWorkOrderSkuInfoList = [], workOrderType, isFirstHandle = true } = this.state
    const newdatas = $lodash.filter(tableData, (o) => { return +o.supplyPrice > 0 })

    let quotationDetailList = []

    if (newdatas.length === 0) {
      return message.warning('请至少输入供应价格')
    }

    if (['HELP_QUOTATION', 'AUTO_GENERATE'].includes(workOrderType)) { // 协作报价 || 自动报价
      const enquiryWorkOrderSkuInfo = enquiryWorkOrderSkuInfoList[0]

      quotationDetailList = $lodash.map(newdatas, (row) => {
        const obj = {
          ...row,
          advancePaymentRate: +row.deliveryPeriodDays === 0 ? null : row.advancePaymentRate,
          distributorId: enquiryWorkOrderSkuInfo.distributorId,
          commoditySkuId: enquiryWorkOrderSkuInfo.commoditySkuId,
          workOrderId: enquiryWorkOrderSkuInfo.workOrderId,
          orderId: detailInfo.orderId
        }

        return obj
      })
    }

    if (workOrderType === 'BATCH_GENERATE') { // 批量协作报价
      const enquiryWorkOrderSkuInfo = enquiryWorkOrderSkuInfoList[0]

      let distributorId = isFirstHandle ? this.props.form.getFieldValue('distributorId') : enquiryWorkOrderSkuInfo.distributorId

      if (distributorId === undefined) { return message.warning('请选择询价商户') }

      quotationDetailList = $lodash.map(newdatas, (row) => {
        const obj = {
          ...row,
          advancePaymentRate: +row.deliveryPeriodDays === 0 ? null : row.advancePaymentRate,
          distributorId: distributorId,
          commoditySkuId: enquiryWorkOrderSkuInfo.commoditySkuId,
          workOrderId: enquiryWorkOrderSkuInfo.workOrderId,
          orderId: detailInfo.orderId
        }

        return obj
      })
    }

    if (['HELP_LIST_QUOTATION', 'EXCEL_IMPORT_GENERATED'].includes(workOrderType)) { // 协作列表报价
      quotationDetailList = $lodash.map(newdatas, (row) => {
        const helpListObj = {
          ...row,
          advancePaymentRate: +row.deliveryPeriodDays === 0 ? null : row.advancePaymentRate,
          orderId: detailInfo.orderId
        }

        return helpListObj
      })
    }

    this.saveHandleWorkOrderDetail({ id: detailInfo.id, quotationDetailList: quotationDetailList })
  }

  // 点击添加自定义项
  handleAddRow = (index) => {
    const { tableData, count, workOrderType } = this.state

    if (['HELP_LIST_QUOTATION', 'EXCEL_IMPORT_GENERATED'].includes(workOrderType)) {
      const obj = tableData[index]
      const newobj = {
        rowKey: `customize-${count + 1}`,
        commoditySkuId: obj.commoditySkuId,
        brandName: obj.brandName,
        model: obj.model,
        itemNumber: obj.itemNumber,
        name: obj.name,
        workOrderId: obj.workOrderId
      }

      tableData.splice(index + 1, 0, { ...newobj, ...customizeRowItem })
    } else {
      tableData.splice(index + 1, 0, { ...customizeRowItem, rowKey: `customize-${count + 1}` })
    }

    this.setState({
      tableData: tableData,
      count: count + 1
    })
  }

  // 点击删除自定义项
  handleDeleteRow = (keys) => {
    const { tableData } = this.state
    const newdatas = $lodash.pullAllBy(tableData, keys, 'rowKey')

    this.setState({
      tableData: newdatas
    })
  }

  // 监听输入框变化
  handleChangeInput = (value, dataIndex, index) => {
    let { tableData } = this.state
    tableData[index][dataIndex] = value

    this.setState({
      tableData: [...tableData]
    })
  }

  // 初始化基础数据
  initDefaultTableData = (workOrderType, sourceList) => {
    const { count = 0 } = this.state
    let newdatas = []

    if (workOrderType === 'HELP_LIST_QUOTATION' || workOrderType === 'EXCEL_IMPORT_GENERATED') {
      if (sourceList && sourceList.length > 0) {
        newdatas = $lodash.map(sourceList, (row) => {
          return {
            rowKey: row.id,
            brandName: row.brandName,
            commoditySkuId: row.commoditySkuId,
            model: row.model,
            itemNumber: row.itemNumber,
            name: row.name,
            workOrderId: row.workOrderId,
            ...customizeRowItem
          }
        })
      }
    } else {
      newdatas = [{ rowKey: `customize-${count}`, ...customizeRowItem }]
    }

    return newdatas
  }

  // 点击取消
  handleGoBack = () => {
    confirm({
      title: '确定取消？',
      onOk: () => {
        this.props.history.push({ pathname: '/admin/workOrders' })
      }
    })
  }

  render () {
    const { detailInfo = {}, enquiryWorkOrderSkuInfoList = [], tableData = [], isFirstHandle = true } = this.state
    const enquiryWorkOrderSkuInfo = enquiryWorkOrderSkuInfoList[0] || {}

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <WorkHeader detailInfo={detailInfo} />
          {
            ['HELP_LIST_QUOTATION', 'EXCEL_IMPORT_GENERATED'].includes(detailInfo.workOrderType)
              ? <>
                <TeamWorkContents
                  list={enquiryWorkOrderSkuInfoList}
                />

                <TeamWorkEditTable
                  list={tableData}
                  onChangeInput={(value, dataIndex, index) => this.handleChangeInput(value, dataIndex, index)}
                  onAddRow={(index) => this.handleAddRow(index)}
                  onDeleteRow={(keys) => this.handleDeleteRow(keys)}
                />
              </>
              : <>
                <WorkContents form={this.props.form}
                  isPreview={!isFirstHandle}
                  workOrderType={detailInfo.workOrderType}
                  info={enquiryWorkOrderSkuInfo}
                  distributorName={detailInfo.distributorName}
                />

                <WorkEditTable
                  list={tableData}
                  onChangeInput={(value, dataIndex, index) => this.handleChangeInput(value, dataIndex, index)}
                  onAddRow={(index) => this.handleAddRow(index)}
                  onDeleteRow={(keys) => this.handleDeleteRow(keys)}
                />
              </>
          }

          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <Button size='large' onClick={() => this.handleGoBack()}>取消</Button>

            <Button size='large' type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleSave()}>保存</Button>
          </div>
        </Card>
      </>
    )
  }
}

const WorkHandle = Form.create()(WorkHandleForm)

export default WorkHandle
