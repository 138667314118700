import React, { Component } from 'react'
import { Modal, Form, DatePicker, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

const FormItem = Form.Item

class InsuranceForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    form: PropTypes.object,
    detailInfo: PropTypes.object
  }
  state={
  }

  handleConfirm = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props

        let params = ({
          distributorId: detailInfo.distributorId,
          type: detailInfo.type,
          useAmount: detailInfo.useAmount,
          endTime: moment(values.datetime).format('YYYY-MM-DD'),
          rate: values.servicerate / 1000,
          totalAmount: values.totalAmount
        })

        this.props.onConfirm(params)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo = {} } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }

    return (
      <>
        <Modal title='调整服务内容'
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={this.props.onCancel}
        >
          <Form {...formItemLayout}>
            <FormItem label='服务截止日期' >
              {
                getFieldDecorator('datetime', {
                  initialValue: moment(detailInfo.endTime),
                  rules: [{ required: true, message: '选择截止日期' }]
                })(
                  <DatePicker disabled={detailInfo.type === 'COMMON'} />
                )
              }
            </FormItem>
            <FormItem label='服务费率'>
              {
                getFieldDecorator('servicerate', {
                  initialValue: detailInfo.rate * 1000,
                  rules: [
                    { required: true, message: '请填写服务费率' }
                  ]
                })(
                  <InputNumber min={0} style={{ width: '174px' }} />
                )
              }
            </FormItem>
            <FormItem label='服务金额上限'>
              {
                getFieldDecorator('totalAmount', {
                  initialValue: detailInfo.totalAmount,
                  rules: [{ required: true, message: '请填写服务金额上限' }]
                })(
                  <InputNumber disabled={detailInfo.type === 'COMMON'} min={0} style={{ width: '174px' }} />
                )
              }
            </FormItem>
          </Form>

        </Modal>

      </>
    )
  }
}
const InsuranceModal = Form.create()(InsuranceForm)

export default InsuranceModal
