import React, { Component } from 'react'

import AddressList from '@/components/addressList/index'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const { MAX_NUMBER } = consts

class SendAddress extends Component {
  componentDidMount () {}

  // 获取发货地址
  getAddressList = (params) => {
    return Api.getAddressList(params)
  }

  // 新增发货地址
  saveAddAddress = (params) => {
    return Api.saveAddAddress(params)
  }

  // 编辑保存发货地址
  saveEditAddress = (params) => {
    return Api.saveEditAddress(params)
  }

  // 删除地址
  saveDeleteAddress = (params) => {
    return Api.saveDeleteAddress(params)
  }

  render () {
    return (
      <>
        <AddressList
          componentType='SEND'
          id={MAX_NUMBER}
          addressType={'PLATFORM_SENDER_ADDRESS'}
          getList={(params) => this.getAddressList(params)}
          onSaveAddress={(params) => this.saveAddAddress(params)}
          onEditAddress={(params) => this.saveEditAddress(params)}
          onDeleteAddress={(params) => this.saveDeleteAddress(params)}
        />
      </>
    )
  }
}

export default SendAddress
