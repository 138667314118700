import React, { Component } from 'react'
import { Card, Tabs } from 'antd'

import CloudSpotRecords from './cloudSpotRecords/index'
import CloudFuturesRecords from './cloudFuturesRecords/index'

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { key: 'CLOUD_SPOT', name: '云现货' },
  { key: 'CLOUD_FUTURES', name: '云期货' }
]

class CloudTransactionRecordsNotRate extends Component {
  state = {
    activeKey: 'CLOUD_SPOT'
  }

  componentDidMount () {}

  handleChangeTabPane = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'CLOUD_SPOT' } = this.state

    return (
      <>
        <div style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <div className={`${styles.tabWrap}`}>
              <Tabs activeKey={activeKey} onChange={(key) => this.handleChangeTabPane(key)}>
                {
                  tabPaneMaps.map((item) => (
                    <TabPane tab={item.name} key={item.key} />
                  ))
                }
              </Tabs>
            </div>
          </Card>
        </div>

        {
          activeKey === 'CLOUD_SPOT'
            ? <CloudSpotRecords />
            : null
        }

        {
          activeKey === 'CLOUD_FUTURES'
            ? <CloudFuturesRecords />
            : null
        }
      </>
    )
  }
}

export default CloudTransactionRecordsNotRate
