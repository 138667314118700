import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Modal, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions, repLineBreak } = filter

const searchData = [
  {
    name: 'itemNo',
    formType: 'TextArea',
    itemParams: {
      label: '型号/订货号'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off',
      rows: 4
    }
  },
  {
    name: 'model',
    formType: 'Input',
    itemParams: {
      label: '型号'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off'
    }
  },
  {
    name: 'itemNumber',
    formType: 'Input',
    itemParams: {
      label: '订货号'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off'
    }
  }
]

export default function BindSkuTable (props) {
  const { detailInfo = {}, updateBindCount = 0, onRelieveSuccss } = props
  const [query, setQuery] = useState({})
  const [pages, setPages] = useState({ total: 0, pageNo: 1, pageSize: 20 })
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  // 获取已绑定系列的产品列表
  const getSkuListInBindedSeries = async () => {
    const params = {
      ...query,
      brandId: detailInfo.brandId,
      seriesId: detailInfo.id,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    const res = await Api.getSkuListInBindedSeries(params)
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data.list || [])
      setPages({ ...pages, total: data.total || 0 })
    }
  }

  // 取消产品与系列的关联
  const cancelBindSkuToSeries = async (params) => {
    const res = await Api.cancelBindSkuToSeries(params)
    const { code } = res
    if (+code === 10000) {
      message.success('解除绑定成功！')
      setSelectedRowKeys([])

      getSkuListInBindedSeries()

      onRelieveSuccss()
    }
  }

  // 点击搜索
  const handleSearch = (search = {}) => {
    const { itemNo } = search
    if (itemNo && $lodash.trim(itemNo) !== '') {
      search.itemNo = repLineBreak($lodash.trim(itemNo), '<br>')
    }

    setPages({ ...pages, pageNo: 1 })

    setQuery(search)
  }

  // 点击取消关联
  const handleCancelBindSku = (selectedRowKeys = []) => {
    Modal.confirm({
      title: '确定取消关联该产品？',
      onOk: () => {
        cancelBindSkuToSeries({ skuIdList: selectedRowKeys })
      }
    })
  }

  // 翻页
  const pageChange = (page, pageSize) => {
    setPages({ ...pages, pageNo: page })
  }

  const pageSizeChange = (current, size) => {
    setPages({ ...pages, pageNo: 1, pageSize: size })
  }

  const columns = [
    {
      title: '序号',
      key: 'bindedSkuCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'bindedSkuCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'bindedSkuCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'bindedSkuCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'bindedSkuCol-4',
      dataIndex: 'name'
    },
    {
      title: '操作',
      key: 'bindSkuCol-5',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => handleCancelBindSku([record.id])}>取消关联</Button>
        </>
      )
    }
  ]

  const pagination = {
    showSizeChanger: true,
    total: pages.total,
    showTotal: (total) => { return `共${total}` },
    current: pages.pageNo,
    pageSize: pages.pageSize,
    pageSizeOptions: pageSizeOptions,
    onChange: pageChange,
    onShowSizeChange: pageSizeChange
  }

  const rowSelection = {
    columnWidth: 44,
    selectedRowKeys: selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys)
  }

  useEffect(() => {
    getSkuListInBindedSeries()
  }, [query, pages.pageNo, pages.pageSize, updateBindCount])

  return (
    <div style={{ marginBottom: '16px' }}>
      <Card title='已关联的产品' bodyStyle={{ padding: '12px' }}>
        <div style={{ marginBottom: '20px' }}>
          <SearchForm
            dataSource={searchData}
            handleSubmit={(obj) => handleSearch(obj)}
          />
        </div>

        <div style={{ marginBottom: '12px' }}>
          <Button type='primary' disabled={selectedRowKeys.length === 0} onClick={() => handleCancelBindSku(selectedRowKeys)}>取消关联</Button>
        </div>

        <Table rowKey='id' bordered
          columns={columns}
          dataSource={tableData}
          rowSelection={rowSelection}
          pagination={pagination}
        />
      </Card>
    </div>
  )
}

BindSkuTable.propTypes = {
  detailInfo: PropTypes.object,
  updateBindCount: PropTypes.number,
  onRelieveSuccss: PropTypes.func
}
