import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Table, message, Input, Button } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { TextArea } = Input
const { pageSizeOptions, repLineBreak } = filter

class SetprodcutModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    disableList: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchValues: '',
    query: {},
    tableData: [],
    selectedRows: [],
    selectedRowKeys: []
  }
  columns = [
    {
      title: '序号',
      key: 'sku-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'sku-1',
      width: 150,
      render: (text, record, index) => (
        <div>
          <div className={`brandLabel nameCn`}>{record.brandName}</div>
          <div className={`brandLabel nameEn`}>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '型号',
      key: 'sku-2',
      width: 150,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'sku-3',
      ellipsis: true,
      dataIndex: 'itemNumber',
      width: 150
    },
    {
      title: '产品',
      key: 'sku-4',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '价格',
      key: 'sku-5',
      render: (text, record, index) => (
        <div>
          <div>表价：{+record.price}</div>
          <div>默认价：{this.renderCalcDefaultPrice(record)}</div>
        </div>
      )
    }
  ]

  componentDidMount () {
    const { detailInfo } = this.props
    this.setState({
      query: {
        brandId: detailInfo.id
      }
    }, () => {
      this.getSkuList()
    })
  }

  // 获取SKU列表
  getSkuList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSkuList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data && data.list) {
          data.list.map(item => {
            item.commoditySkuId = item.id
          })
        }
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { selectedRows = [] } = this.state

    if (selectedRows.length === 0) {
      return message.warning('请选择要添加的产品')
    }

    this.props.onConfirm({ selectedProdList: selectedRows })
  }

  // 点击搜索
  handleSearch = () => {
    const { pages, searchValues = '', query = {} } = this.state
    this.setState({
      query: {
        ...query,
        itemNo: repLineBreak(searchValues, '<br>')
      },
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getSkuList()
    })
  }

  // 点击选择产品
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys,
      selectedRows: rows
    })
  }

  // 计算默认价格
  renderCalcDefaultPrice = (record = {}) => {
    const { price, defaultPrice } = record
    let num = 0
    let discount = +defaultPrice > 0 ? +defaultPrice : 1

    if (+price > 0) {
      num = (price * discount).toFixed(2)
    }

    return num
  }

  // 监听搜索输入
  handleChangeSearchValues = (value) => {
    this.setState({
      searchValues: value
    })
  }

  // table是否可选
  renderDisabledCheckboxProps = (record) => {
    const { disableList = [] } = this.props

    const i = disableList.findIndex(item => { return +item.commoditySkuId === +record.commoditySkuId })
    const disabled = i > -1

    return disabled
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSkuList()
    })
  }

  render () {
    const { pages, tableData = [], selectedRowKeys = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      size: 'small',
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    // table选中配置
    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: this.renderDisabledCheckboxProps(record)
      })
    }

    return (
      <div>
        <Modal title='设置产品'
          bodyStyle={{ padding: '10px' }}
          width={1000}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row style={{ marginBottom: '10px' }} gutter={12}>
            <Col span={8}>
              <TextArea rows={4} placeholder='请输入订货号或型号...' autoComplete='off'
                onChange={(e) => this.handleChangeSearchValues(e.target.value)}
              />
            </Col>
            <Col span={8}>
              <Button type='primary'
                onClick={this.handleSearch}
              >
                搜索
              </Button>
            </Col>
          </Row>
          <Row>
            <Table rowKey='id'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
              rowSelection={rowSelection}
              scroll={{ y: 300 }}
            />
          </Row>
        </Modal>
      </div>
    )
  }
}

export default SetprodcutModal
