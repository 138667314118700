import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Form, Button, Modal } from 'antd'
import moment from 'moment'

import DrawerContents from '../drawerContents/index'

import styles from './index.module.scss'

const { confirm } = Modal

class PurchaseFormDrawer extends Component {
  static propTypes = {
    form: PropTypes.object,
    visible: PropTypes.bool,
    buttonMaps: PropTypes.PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onRefuse: PropTypes.func
  }
  state = {
    receiveAddress: {} // 收件地址
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {}, onConfirm } = this.props
        const { receiveAddress = {} } = this.state
        const { uploadImageUrls, logisticsWayEnum, pickUpTimes } = values

        if (uploadImageUrls && uploadImageUrls.length > 0) {
          values.imageUrls = uploadImageUrls.split(',')
        }

        if (['WAITING_BACK'].includes(detailInfo.status)) { // 待退货
          values.receiveAddress = receiveAddress
        }

        if (['WAITING_DELIVER'].includes(detailInfo.status)) { // 待发货
          values.sendAddress = receiveAddress
        }

        if (logisticsWayEnum) {
          values.logisticsWayEnum = logisticsWayEnum.split('-')[0]
        }

        values.id = detailInfo.id
        values.type = detailInfo.type
        values.sendStartTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
        values.sendEndTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined

        onConfirm(values, detailInfo.status)
      }
    })
  }

  // 点击拒绝
  handleRefuse = () => {
    const { form, detailInfo = {}, onRefuse } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        confirm({
          title: <>{ detailInfo.status === 'WAITING_AUDIT' ? '确定驳回申请？' : '确定拒绝申请？' }</>,
          content: <>{ detailInfo.status === 'WAITING_AUDIT' ? <span className='redColor'>此操作会将此张售后单整体驳回，请售后人员确认此申请不合规</span> : '' }</>,
          onOk: () => {
            const { uploadImageUrls } = values

            if (uploadImageUrls && uploadImageUrls.length > 0) {
              values.imageUrls = uploadImageUrls.split(',')
            }

            values.id = detailInfo.id
            values.type = detailInfo.type

            onRefuse(values, detailInfo.status)
          }
        })
      }
    })
  }

  // 监听收件地址选择
  handleSelectedReplacementAddress = (values) => {
    this.setState({
      receiveAddress: values
    })
  }

  render () {
    const { visible = false, buttonMaps = {}, detailInfo = {}, onCancel } = this.props

    return (
      <Drawer title={<><span>售后申请</span><span style={{ marginLeft: '16px' }}>售后单号：{ detailInfo.id }</span></>} width={1300} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        destroyOnClose
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        <Form>
          {
            visible
              ? <DrawerContents form={this.props.form}
                detailInfo={detailInfo}
                onSelectedReplacementAddress={(values) => this.handleSelectedReplacementAddress(values)}
              />
              : null
          }
        </Form>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>

          {
            detailInfo.status === 'WAITING'
              ? <>
                {
                  buttonMaps && buttonMaps.REFUSEAPPLY && +buttonMaps.REFUSEAPPLY.auth === 1
                    ? <Button type='danger' style={{ marginLeft: '15px' }} onClick={() => this.handleRefuse()}>拒绝申请</Button>
                    : null
                }

                {
                  buttonMaps && buttonMaps.AGREEAPPLY && +buttonMaps.AGREEAPPLY.auth === 1
                    ? <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.handleConfirm()}>同意申请</Button>
                    : null
                }

              </>
              : null
          }

          {
            detailInfo.status === 'WAITING_AUDIT'
              ? <>
                {
                  buttonMaps && buttonMaps.REFUSE && +buttonMaps.REFUSE.auth === 1
                    ? <Button type='danger' style={{ marginLeft: '15px' }} onClick={() => this.handleRefuse()}>驳回</Button>
                    : null
                }

                {
                  buttonMaps && buttonMaps.AGREE && +buttonMaps.AGREE.auth === 1
                    ? <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.handleConfirm()}>通过</Button>
                    : null
                }
              </>
              : null
          }

          {
            detailInfo.status === 'WAITING_BACK'
              ? <>
                {
                  buttonMaps && buttonMaps.PICKUP && +buttonMaps.PICKUP.auth === 1
                    ? <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.handleConfirm()}>上门取件</Button>
                    : null
                }
              </>
              : null
          }

          { // 待发货
            detailInfo.status === 'WAITING_DELIVER'
              ? <>
                {
                  buttonMaps && buttonMaps.SEND && +buttonMaps.SEND.auth === 1
                    ? <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.handleConfirm()}>确认发货</Button>
                    : null
                }
              </>
              : null
          }

          { // 待退款
            detailInfo.status === 'WAITING_REFUND'
              ? <>
                {
                  buttonMaps && buttonMaps.REFUND && +buttonMaps.REFUND.auth === 1
                    ? <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.handleConfirm()}>解决售后</Button>
                    : null
                }
              </>
              : null
          }

        </div>
      </Drawer>
    )
  }
}

const PurchaseDrawer = Form.create()(PurchaseFormDrawer)

export default PurchaseDrawer
