
import homeRouter from './homeRouter'
import prodRouter from './prodRouter'
import cloudRouter from './cloudRouter'
import cusRouter from './cusRouter'
import personRouter from './personRouter'
import systemRouter from './systemRouter'
import orderRouter from './orderRouter'
import financeRouter from './financeRouter'
import contractRouter from './contractRouter'
import businessRouter from './businessRouter'
import notifyRouter from './notifyRouter'
import dashboardRouter from './dashboardRouter'
import operate from './operate'
import expressRouter from './expressRouter'
import enquireRouter from './enquireRouter'
import workOrdersRouter from './workOrdersRouter'
import templatesRouter from './templatesRouter'
import warehouseRouter from './warehouseRouter'
import saledRouter from './saledRouter'
import domainRouter from './domainRouter'
import purposeRouter from './purposeRouter'
import erpRouter from './erpRouter'

export const routerMap = [
  ...homeRouter,
  ...prodRouter,
  ...cloudRouter,
  ...cusRouter,
  ...personRouter,
  ...systemRouter,
  ...orderRouter,
  ...financeRouter,
  ...contractRouter,
  ...businessRouter,
  ...notifyRouter,
  ...dashboardRouter,
  ...operate,
  ...expressRouter,
  ...enquireRouter,
  ...workOrdersRouter,
  ...templatesRouter,
  ...warehouseRouter,
  ...saledRouter,
  ...domainRouter,
  ...purposeRouter,
  ...erpRouter
]
