import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MCInformation from '../component/MCInformationForm/index'

import Api from '@/common/api/index'

export default class PurchaserEdit extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    type: 'ADD',
    info: {}
  }

  componentDidMount () {
    const { state } = this.props.history.location

    this.setState({
      info: state.detailInfo,
      type: state.type
    })
  }

  // 获取平台域名
  getToolBoxCustomerDomain = (params) => {
    return Api.getToolBoxCustomerDomain(params)
  }

  // 保存新增、编辑客户信息
  saveToolBoxCustomerInfomation = (params) => {
    return Api.saveToolBoxCustomerInfomation(params)
  }

  // 校验采购商登录名是否重复
  toolBoxCustomerSaveUsernameValidate = (params) => {
    return Api.toolBoxCustomerSaveUsernameValidate(params)
  }

  handleBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { type = 'ADD', info = {} } = this.state

    return (
      <>
        <MCInformation
          author={'SUPPLY_PURCHASE'}
          type={type}
          detailInfo={info}
          getDomain={(params) => this.getToolBoxCustomerDomain(params)}
          onCheckLoginname={(params) => this.toolBoxCustomerSaveUsernameValidate(params)}
          onConfirm={(params) => this.saveToolBoxCustomerInfomation(params)}
          onCancel={() => this.handleBack()}
        />
      </>
    )
  }
}
