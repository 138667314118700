import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Tabs, Icon, Button, Badge } from 'antd'
import $lodash from 'lodash'

import MatchExplain from './matchExplain/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { tableColumnsMaps } from './localdatas'
import { setEditEnquireDetails } from '@/reducers/action'
import styles from './index.module.scss'

const { TabPane } = Tabs
const { pageSizeOptions } = filter
const tabMaps = [
  { type: 'ALL', label: '所有型号', icon: '', color: '' },
  { type: 'SUCCESS', label: '匹配成功', icon: 'check-circle', color: '#52C41A' },
  { type: 'FAIL', label: '需要修正', icon: 'exclamation-circle', color: '#F5222D' }
]
const matchTypeMaps = {
  'ENQUIRE_SPECIAL_PRODUCTS': 'IMPORT',
  'ENQUIRE_PRODUCTS_UPLOAD': 'MATCH',
  'UPLOAD_SERIES': 'IMPORT',
  'UPLOAD_WORK_ORDER': 'MATCH',
  'AUTO_QUOTATION_IMPORT': 'MATCH'
}

// 需要调用保存api的导入类型
const needRequestSaveSourceTypeKeys = [
  'ENQUIRE_SPECIAL_PRODUCTS', 'UPLOAD_WORK_ORDER', 'AUTO_QUOTATION_IMPORT', 'UPLOAD_SERIES'
]

class MatchDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    userInfo: PropTypes.object,
    editEnquireDetails: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    activeKey: 'ALL',
    totalNum: 0,
    successNum: 0,
    failNum: 0,
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'matchDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'matchDetailsCol-1',
      width: 130,
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '匹配编号',
      key: 'matchDetailsCol-2',
      width: 130,
      dataIndex: 'matchNo',
      ellipsis: true
    },
    {
      title: '状态',
      key: 'matchDetailsCol-3',
      width: 100,
      render: (text, record, index) => (
        <Badge status={record.matchResult === 'SUCCESS' ? 'success' : 'error'} text={record.matchResult === 'SUCCESS' ? '成功' : '失败'} />
      )
    },
    {
      title: '原因',
      key: 'matchDetailsCol-4',
      render: (text, record, index) => (
        <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
          {record.failReason}
        </div>
      )
    },
    {
      title: '型号',
      key: 'matchDetailsCol-5',
      width: 120,
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'matchDetailsCol-6',
      width: 120,
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'matchDetailsCol-7',
      width: 120,
      ellipsis: true,
      dataIndex: 'quantity'
    },
    {
      title: '产品名称',
      key: 'matchDetailsCol-8',
      ellipsis: true,
      dataIndex: 'name'
    }
  ]

  componentDidMount () {
    const { importId, sourceType = '', defaultParams = {}, totalNum, successNum, failNum } = this.props.history.location.state

    this.setState({
      importId: importId,
      sourceType: sourceType,
      defaultParams: defaultParams,
      totalNum: totalNum,
      successNum: successNum,
      failNum: failNum
    }, () => {
      this.getExcelAnalysisMatchDetails('ALL')
    })
  }

  // 获取匹配详情信息
  getExcelAnalysisMatchDetails = async (activeKey, type) => {
    const { importId, pages, sourceType = '', defaultParams = {} } = this.state
    const params = {
      importId: importId,
      matchType: matchTypeMaps[sourceType],
      pageNo: type === 'LINKTO' ? 1 : pages.pageNo,
      pageSize: type === 'LINKTO' ? 99999 : pages.pageSize,
      result: activeKey,
      ...defaultParams
    }

    const res = await Api.getExcelAnalysisMatchDetails(params)
    const { code, data } = res
    if (+code === 10000) {
      if (type && type === 'LINKTO') {
        if (sourceType === 'ENQUIRE_PRODUCTS_UPLOAD') {
          this.carryoutEnquireProductsUpload(data.list || [])
        }
      } else {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    }
  }

  // 保存特价产品导入 || 工单导入 || 系列导入 || 询价导入价格
  saveExcelEnquireSpecialMatchProducts = async (params) => {
    const res = await Api.saveExcelEnquireSpecialMatchProducts(params)
    const { code } = res
    if (+code === 10000) {
      const { importId, sourceType, defaultParams = {} } = this.state
      this.props.history.push({ pathname: '/admin/matchSettingSuccess', state: { importId: importId, sourceType: sourceType, defaultParams: defaultParams } })
    }
  }

  // 点击切换tabPane
  handleChangeTabPane = (key) => {
    const { activeKey = 'ALL', pages } = this.state
    if (key === activeKey) { return false }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      activeKey: key
    }, () => {
      this.getExcelAnalysisMatchDetails(key)
    })
  }

  // 点击下一步
  handleNext = () => {
    const { userInfo = {} } = this.props
    const { importId, sourceType = '', defaultParams = {} } = this.state
    if (sourceType === 'ENQUIRE_PRODUCTS_UPLOAD') {
      this.getExcelAnalysisMatchDetails('ALL', 'LINKTO')
    }

    if (needRequestSaveSourceTypeKeys.includes(sourceType)) {
      const params = {
        importId: importId,
        matchType: matchTypeMaps[sourceType],
        operationName: userInfo.userName,
        ...defaultParams
      }
      this.saveExcelEnquireSpecialMatchProducts(params)
    }
  }

  // 设置action缓存数据
  setEnquireDetailsActionData = (list = []) => {
    const { editEnquireDetails = {} } = this.props
    const { autoEnquireProducts = [] } = editEnquireDetails

    list.map((item, k) => {
      if (item.matchResult === 'SUCCESS') {
        const n = $lodash.findIndex(autoEnquireProducts, (o) => { return o.searchResultEnum === 'SUCCESS' && o.commoditySkuId === item.commoditySkuId })

        if (n > -1) {
          autoEnquireProducts[n].quantity += 1
        } else {
          autoEnquireProducts.push({
            dataType: 'SEARCH',
            searchResultEnum: 'SUCCESS',
            matchingState: 'MATCHED',
            rowKey: `SEARCH-${item.commoditySkuId}`,
            commoditySkuId: item.commoditySkuId,
            name: item.name,
            brandId: item.brandId,
            brandName: item.brandName,
            model: item.model,
            itemNumber: item.itemNumber,
            unit: item.unit,
            quantity: +item.importQuantity
          })
        }
      } else {
        autoEnquireProducts.push({
          dataType: 'SEARCH',
          searchResultEnum: 'ERROR',
          matchingState: 'UNMATCHED',
          rowKey: `SEARCH-${item.importModel}-${new Date().getTime() + k}`,
          commoditySkuId: null,
          name: null,
          brandId: null,
          brandName: item.importBrandName,
          model: item.importModel,
          itemNumber: item.importItemNo,
          unit: null,
          quantity: +item.importQuantity
        })
      }
    })

    return autoEnquireProducts
  }

  // 询价产品导入搜索
  carryoutEnquireProductsUpload = (list) => {
    const { editEnquireDetails, dispatch, history } = this.props
    const { importId, sourceType = '', defaultParams = {} } = this.state

    const productList = this.setEnquireDetailsActionData(list)
    dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, autoEnquireProducts: [...productList] } }))

    setTimeout(() => {
      history.push({ pathname: '/admin/matchSettingSuccess', state: { importId: importId, sourceType: sourceType, defaultParams: defaultParams } })
    }, 30)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages, activeKey = 'ALL' } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getExcelAnalysisMatchDetails(activeKey)
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages, activeKey = 'ALL' } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getExcelAnalysisMatchDetails(activeKey)
    })
  }

  render () {
    const { pages, totalNum, successNum, failNum, tableData = [], activeKey = 'ALL', sourceType } = this.state

    const columns = tableColumnsMaps[sourceType]

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <MatchExplain
              totalNum={totalNum}
              successNum={successNum}
              failNum={failNum}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div className={`${styles.tabsLand}`}>
            <Tabs onChange={this.handleChangeTabPane} tabBarExtraContent={<div>
              <Button type='primary' onClick={() => this.handleNext()}>
                下一步<Icon type='arrow-right' />
              </Button>
            </div>}>
              {
                tabMaps.map((titem, n) => (
                  <TabPane tab={<span>{titem.icon !== '' ? <Icon type={titem.icon} style={{ color: titem.color !== '' ? titem.color : '' }} /> : null}{titem.label}</span>}
                    key={titem.type}
                  />
                ))
              }
            </Tabs>
          </div>

          <Table rowKey={(record, index) => `${activeKey}-row-${index}`} bordered
            columns={columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    editEnquireDetails: state.editEnquireDetails
  }
}

export default connect(mapStateToProps)(MatchDetails)
