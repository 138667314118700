import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Col, Switch, Table, Button, message, Modal, Icon, Tooltip } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import MCLabelModal from '../component/MCLabelModal/index' // 商户标签
import AddressModal from '@/components/address-modal/index'
import DistributionModal from '../customer-list/distributionModal/index' // 分配运营弹窗
import PayBankDownFormModal from '@/pages/order/component/payBankDownModal/index' // 支付信息下载弹窗
import ColumnsConfigModal from '@/components/columnsConfigModal' // 列设置弹窗

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { setTableColumnKeys } from '@/reducers/action'
import RegionJson from '@/assets/json/region.json'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderCustomerAuthorLabel, renderRegionName } from '@/common/utils/mUtils'

const { pageSizeOptions, disableStatusMaps } = filter
const { MAX_PAGESIZE } = consts
const columnConfigKey = 'PURCHASER_List_TABLE'

class PurchaserList extends Component {
  constructor (props) {
    super(props)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
  }
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    userInfo: PropTypes.object,
    buttonMaps: PropTypes.object,
    columnsMap: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorName',
        formType: 'Input',
        itemParams: {
          label: '公司名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'label',
        formType: 'Input',
        itemParams: {
          label: '标签'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'regionCode',
        formType: 'Select',
        itemParams: {
          label: '所属销售大区'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { code: '', name: '全部' },
          ...RegionJson
        ],
        optionValue: ['code', 'name']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '是否启用'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...disableStatusMaps
        ]
      },
      {
        name: 'username',
        formType: 'Input',
        itemParams: {
          label: '公司账号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '所属运营'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 5)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'belongUserId',
        formType: 'Select',
        itemParams: {
          label: '采购归属'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 6)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      }
    ],
    query: {},
    tableData: [],
    labelModal: false,
    addressModal: false,
    distributionModal: false, // 分配运营弹窗
    payBankDownModal: false,
    columnsConfigModal: false, // 列设置弹窗
    rowDetail: {}
  }

  baseColumns = [
    {
      title: '序号',
      key: 'column-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户编号',
      key: 'purchaserCol-1',
      dataIndex: 'distributorId'
    },
    {
      title: '客户权限',
      key: 'purchaserCol-2',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ renderCustomerAuthorLabel(record.type) }</>

      )
    },
    {
      title: '公司名称',
      key: 'purchaserCol-3',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '统计名称',
      key: 'purchaserCol-4',
      dataIndex: 'statisticsMerchantName'
    },
    {
      title: '支付信息',
      key: 'tradeOrder-payBankDetails',
      render: (text, record, index) => (
        <><a onClick={() => this.handleShowPayBankDownModal(record)}>点击下载</a></>
      )
    },
    {
      title: '公司账号',
      key: 'purchaserCol-5',
      dataIndex: 'loginName'
    },
    {
      title: '标签',
      key: 'purchaserCol-6',
      ellipsis: true,
      width: 200,
      dataIndex: 'label'
    },
    {
      title: '特价产品数',
      key: 'purchaserCol-7',
      dataIndex: 'bargainsTotal',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.handleLinkToProductSet(record)}>{+record.bargainsTotal > 0 ? +record.bargainsTotal : '-----'}</a>
        </>
      )
    },
    {
      title: '是否启用',
      key: 'purchaserCol-8',
      render: (text, record, index) => (
        <>
          <Switch checked={record.supplierAccountStatus === 'ENABLED'}
            checkedChildren='启用'
            unCheckedChildren='禁用'
            onChange={(checked) => this.handleSwitchEnableStatus(checked, record)}
          />
        </>
      )
    },
    {
      title: '所属销售大区',
      key: 'purchaserCol-9',
      dataIndex: 'regionCode',
      render: (text, record, index) => (
        <>{ renderRegionName(record.regionCode) }</>
      )
    },
    {
      title: '所属运营',
      key: 'purchaserCol-10',
      dataIndex: 'operationMemberName'
    },
    {
      title: '采购归属',
      key: 'purchaserCol-11',
      dataIndex: 'belongUserRealName'
    },
    {
      title: '创建人',
      key: 'purchaserCol-12',
      dataIndex: 'createName'
    },
    {
      title: '创建时间',
      key: 'purchaserCol-13',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD') : '----' }</>
      )
    },
    {
      title: '操作人',
      key: 'purchaserCol-14',
      dataIndex: 'modifyName'
    },
    {
      title: '操作时间',
      key: 'purchaserCol-15',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '操作',
      key: 'column-operations',
      width: 240,
      fixed: 'right',
      render: (text, record, index) => {
        return (
          <>
            <Button type='link' size='small' onClick={() => this.handleLinkToEdit(record, 'EDIT')}>编辑</Button>

            <Button type='link' size='small' onClick={() => this.showDistributorlabelModal(record)}>标签</Button>

            <Button type='link' size='small' onClick={() => this.showDistributionModal(record, 'platformUser')}>分配运营</Button>

            <Button type='link' size='small' onClick={() => this.showAddressModal(record)}>新增地址</Button>

            <Button type='link' size='small' onClick={() => this.linkToAddressList(record)}>查看地址</Button>

            <Button type='link' size='small' onClick={() => this.showDistributionModal(record, 'belongUser')}>设置采购归属</Button>
          </>
        )
      }
    }
  ]

  // 保存分配人员方法集合
  bindPersonnelForNameMaps = [
    [ // 分配运营
      (name) => name === 'platformUser',
      (values) => this.saveBindBusinessPersonnel(values)
    ],
    [ // 分配客户采购归属
      (name) => name === 'belongUser',
      (values) => this.saveBindBelongPersonnel(values)
    ],
    [ // 分配客户供应归属
      (name) => name === 'supplyBelongUser',
      (values) => this.saveBindSupplyBelongPersonnel(values)
    ]
  ]

  componentDidMount () {
    this.getPurchaserList()
  }

  // 获取全部成员
  getMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[searchIndex].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list, { id: 0, userName: '无' }] : []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取采购商列表
  getPurchaserList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getPurchaserList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存采购商启用禁用状态
  savePurchaserEnableStatus = async (params) => {
    const res = await Api.savePurchaserEnableStatus(params)
    const { code } = res
    if (+code === 10000) {
      this.getPurchaserList()
    }
  }

  // 保存商户标签
  setDistributorLabel = async (params) => {
    const res = await Api.setDistributorLabel(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaserList()
    }
  }

  // 保存新增地址
  saveAddAddress = async (params) => {
    const res = await Api.saveAddAddress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
    }
  }

  // 保存分配运营成员
  saveBindBusinessPersonnel = async (params) => {
    const res = await Api.saveBindBusinessPersonnel(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaserList()
    }
  }

  // 保存分配客户归属
  saveBindBelongPersonnel = async (params) => {
    const res = await Api.saveBindBelongPersonnel(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getPurchaserList()
    }
  }

  // 保存列表项配置
  saveUserTableColumnSetting = async (params, newcolumnsMap) => {
    const res = await Api.saveUserTableColumnSetting(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.props.dispatch(setTableColumnKeys({ columnsMap: newcolumnsMap }))

      this.closeModal()
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      }
    }, () => {
      this.getPurchaserList()
    })
  }

  // 点击新增、编辑客户信息
  handleLinkToEdit = (record, type) => {
    this.props.history.push({ pathname: '/admin/purchaserEdit', state: { detailInfo: record, type: type } })
  }

  // 点击查看地址
  linkToAddressList = (record) => {
    this.props.history.push({ pathname: '/admin/customerAddressList', state: { distributorId: record.distributorId, addressType: 'MERCHANT_RECEIVER_ADDRESS' } })
  }

  // 点击新增特价产品
  handleLinkToProductSet = (record) => {
    sessionStorage.setItem('backPath', this.props.history.location.pathname)

    this.props.history.push({ pathname: '/admin/enquireProductSet', state: { distributorId: record.distributorId } })
  }

  // 点击启用禁用
  handleSwitchEnableStatus = (checked, record) => {
    const params = {
      distributorId: record.distributorId,
      supplierAccountStatus: checked ? 'ENABLED' : 'FREEZE',
      username: record.loginName || ''
    }

    if (!checked) {
      Modal.confirm({
        title: '确定要禁用此商户？',
        content: '(注意：此操作将造成商户无法登陆)',
        onOk: () => {
          this.savePurchaserEnableStatus(params)
        }
      })

      return false
    }

    this.savePurchaserEnableStatus(params)
  }

  // 标签
  showDistributorlabelModal = (record) => {
    this.setState({
      labelModal: true,
      rowDetail: record
    })
  }

  // 点击新增地址
  showAddressModal = (record) => {
    this.setState({
      rowDetail: record,
      addressModal: true
    })
  }

  // 点击保存地址
  handleSaveAddress = (values) => {
    const { rowDetail } = this.state
    this.saveAddAddress({ ...values, bindData: rowDetail.distributorId, addressType: 'MERCHANT_RECEIVER_ADDRESS' })
  }

  // 点击分配运营
  showDistributionModal = (record, modalKey) => {
    this.setState({
      rowDetail: { ...record, modalKey: modalKey },
      distributionModal: true
    })
  }

  /* 确定保存分配人员
   * 分配运营 || 分配客户采购归属 || 分配客户供应归属
  */
  confirmBindDistributionPersonnel = (params, modalKey) => {
    if (!modalKey || modalKey === '') { return message.warning('modalKey参数不能为空') }

    const getDescribe = this.bindPersonnelForNameMaps.find((item) => { return item[0](modalKey) })

    getDescribe ? getDescribe[1](params) : message.warning('modalKey参数错误')
  }

  // 点击下载支付信息
  handleShowPayBankDownModal = (record) => {
    this.setState({
      rowDetail: record,
      payBankDownModal: true
    })
  }

  // 下载支付信息
  downloadPayBankDetail = (params, type) => {
    if (type === 'PDF') {
      DownloadApi.downloadPaymentInfoByPdf(params)
    }

    if (type === 'WORD') {
      DownloadApi.downloadPaymentInfoByWord(params)
    }
  }

  // 点击列设置
  showColumnsConfigModal = () => {
    this.setState({
      columnsConfigModal: true
    })
  }

  // 确定保存列表项配置
  handleConfirmTableColumnSetting = (selectedKeys, newcolumns) => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: {
        selectedColumnKeys: [...selectedKeys],
        sortColumnKeys: [...newcolumns]
      }
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  // 确定重置列表项配置
  handleResetTableColumnSetting = () => {
    const { userInfo = {}, columnsMap = {} } = this.props

    const newcolumnsMap = {
      ...columnsMap,
      [columnConfigKey]: null
    }

    this.saveUserTableColumnSetting({ userId: userInfo.id, systemType: 'PLATFORM', content: JSON.stringify(newcolumnsMap) }, newcolumnsMap)
  }

  // 取消
  closeModal = () => {
    this.setState({
      labelModal: false,
      addressModal: false,
      distributionModal: false,
      payBankDownModal: false,
      columnsConfigModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchaserList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchaserList()
    })
  }

  render () {
    const { columnsMap = {} } = this.props
    const { searchData, pages, tableData = [], labelModal = false, addressModal = false, distributionModal = false, payBankDownModal = false, columnsConfigModal = false,
      rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    /**
     * 获取redux缓存里面的相关页面的列展示信息
     *   sortColumnKeys: 排序后的所有列的key selectedColumnKeys:要展示的列的key
     * 1- 首先将baseColumns列顺序按 sortColumnKeys 里面的顺序排序
     * 2- 将baseColumns过滤 保留包含在 selectedColumnKeys中的key
     */
    const sortColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].sortColumnKeys ? columnsMap[columnConfigKey].sortColumnKeys : []
    const selectedColumnKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys ? columnsMap[columnConfigKey].selectedColumnKeys : []

    const newArr = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : this.baseColumns

    // 列表展示的项
    const tableColumns = selectedColumnKeys.length > 0 ? $lodash.filter(newArr, (record) => { return $lodash.includes(selectedColumnKeys, record.key) }) : newArr

    const _baseColumns = sortColumnKeys.length > 0
      ? $lodash.sortBy(this.baseColumns, (record) => { return $lodash.findIndex(sortColumnKeys, (o) => { return record.key === o }) })
      : $lodash.map(this.baseColumns, (record) => { return { key: record.key, title: record.title } })

    const columnsConfig = $lodash.filter(_baseColumns, (o) => { return !$lodash.includes(['column-0', 'column-operations'], o.key) })

    const checkedColKeys = columnsMap[columnConfigKey] && columnsMap[columnConfigKey].selectedColumnKeys
      ? columnsMap[columnConfigKey].selectedColumnKeys
      : $lodash.map(columnsConfig, (o) => { return o.key })

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Col span={16}>
              <Button type='primary' onClick={() => this.handleLinkToEdit({}, 'ADD')}><Icon type='plus' />新增客户</Button>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Tooltip title='列设置'>
                <a style={{ display: 'inline-block', padding: '0px 6px', lineHeight: '32px' }} onClick={() => this.showColumnsConfigModal()}>
                  <Icon type='setting' style={{ fontSize: '20px' }} />
                </a>
              </Tooltip>
            </Col>
          </Row>

          <Table rowKey='distributorId' bordered
            columns={tableColumns}
            dataSource={tableData}
            pagination={pagination}
            scroll={{ x: true }}
          />
        </Card>

        {
          labelModal
            ? <MCLabelModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.setDistributorLabel(params)}
            />
            : null
        }

        {
          addressModal
            ? <AddressModal
              type={1}
              detailInfo={{}}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.handleSaveAddress(values)}
            />
            : null
        }

        { // 分配运营弹窗
          distributionModal
            ? <DistributionModal
              detailInfo={rowDetail}
              modalKey={rowDetail.modalKey}
              onConfirm={(values, modalKey) => this.confirmBindDistributionPersonnel(values, modalKey)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          payBankDownModal
            ? <PayBankDownFormModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(params, type) => this.downloadPayBankDetail(params, type)}
            />
            : null
        }

        { // 列设置弹窗
          columnsConfigModal
            ? <ColumnsConfigModal
              configKey={columnConfigKey}
              selectedColumnKeys={checkedColKeys}
              disabledBeforeColumns={[{ key: 'column-0', title: '序号' }]}
              disabledAfterColumns={[{ key: 'column-operations', title: '操作' }]}
              allcolumns={columnsConfig}
              onConfirm={(checkedKeys, columns) => this.handleConfirmTableColumnSetting(checkedKeys, columns)}
              onReset={() => this.handleResetTableColumnSetting()}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo || {},
    buttonMaps: state.buttonMaps,
    columnsMap: state.columnsMap || {}
  }
}

export default connect(mapStateToProps)(PurchaserList)
