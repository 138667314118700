import React, { Component } from 'react'
import { Card, Table } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class ToolxboxBusiness extends Component {
  static propTypes = {}
  state = {
    pages: { pageNo: 1, pageSize: 20, total: 0 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'toolsboxCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '姓名',
      key: 'toolsboxCol-1',
      dataIndex: 'name'
    },
    {
      title: '公司名称',
      key: 'toolsboxCol-2',
      dataIndex: 'companyName'
    },
    {
      title: '手机号码',
      key: 'toolsboxCol-3',
      dataIndex: 'mobile'
    },
    {
      title: '下载时间',
      key: 'toolsboxCol-4',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    }
  ]

  componentDidMount () {
    this.getToolsBoxBusinessDownloadRecords()
  }

  // 获取工聚宝下载信息列表
  getToolsBoxBusinessDownloadRecords = async () => {
    const { pages } = this.state
    const res = await Api.getToolsBoxBusinessDownloadRecords({ pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getToolsBoxBusinessDownloadRecords()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getToolsBoxBusinessDownloadRecords()
    })
  }

  render () {
    const { tableData = [], pages } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey={(record, index) => `TOOLSBOX_ROW-${record.id}-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default ToolxboxBusiness
