import DomainList from '../pages/domainManage/domainList' // 域名列表
import DomainAlert from '../pages/domainManage/domainAlert' // 域名预警7天
import DomainAlertFifteen from '../pages/domainManage/domainAlertFifteen' // 域名预警15天
export default [
  {
    path: '/admin/domainList',
    name: '域名列表',
    component: DomainList
  },
  {
    path: '/admin/domainAlert',
    name: '域名预警(7天)',
    component: DomainAlert
  },
  {
    path: '/admin/alertFifteen',
    name: '域名预警(15天)',
    component: DomainAlertFifteen
  }
]
