import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Select } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item
const Option = Select.Option

class DepartmentForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    activeKey: PropTypes.string,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    tableData: []
  }

  componentDidMount () {
    this.getPurposeDepartmentList()
  }

  // 获取统计部门列表
  getPurposeDepartmentList = async () => {
    const res = await Api.getPurposeDepartmentList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data && data.length > 0 ? [{ id: 0, departName: '无' }, ...data] : []
      })
    }
  }

  handleConfirm = () => {
    const { form, detailInfo = {}, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        values.id = detailInfo.id

        onConfirm(values)
      }
    })
  }

  render () {
    const { form, detailInfo = {}, onCancel } = this.props
    const { getFieldDecorator } = form

    const { tableData = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    return (
      <Modal title='分配部门'
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        onOk={() => this.handleConfirm()}
      >
        <Form>
          <FormItem label='分配部门' {...formItemLayout}>
            {
              getFieldDecorator('departId', {
                initialValue: detailInfo.departId,
                rules: [
                  { required: true, message: '请选择部门' }
                ]
              })(
                <Select>
                  {
                    tableData.map((item, index) => (
                      <Option value={item.id} key={`departOption-${item.id}`}>{ item.departName }</Option>
                    ))
                  }
                </Select>
              )
            }
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const DepartmentModal = Form.create()(DepartmentForm)

export default DepartmentModal
