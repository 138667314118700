import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Button, Icon, message, Modal } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import ParameterModal from './parameter-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

class ParameterList extends Component {
  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'queryParam',
        formType: 'Input',
        itemParams: {
          label: '参数名'
        },
        cptParams: {
          placeholder: '请输入参数名...',
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 10 },
    tableData: [],
    parameterModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'parameter-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '参数名',
      key: 'parameter-1',
      dataIndex: 'attributeName'
    },
    {
      title: '参数别名',
      key: 'parameter-2',
      dataIndex: 'attributeField'
    },
    {
      title: '操作时间',
      key: 'parameter-3',
      width: 120,
      render: (text, record, index) => (
        <div>
          {moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      )
    },
    {
      title: '操作人',
      key: 'parameter-4',
      width: 120,
      dataIndex: 'modifyName'
    },
    {
      title: '操作',
      key: 'parameter-5',
      width: 120,
      render: (text, record, index) => {
        const { buttonMaps = {} } = this.props

        return (
          <div>
            {
              buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
                ? <a style={{ marginRight: '6px' }}
                  onClick={() => this.handleShowParamterModal(record, 2)}
                >
                编辑
                </a>
                : null
            }

            {
              buttonMaps && buttonMaps['delete'] && +buttonMaps['delete'].auth === 1
                ? <a onClick={() => this.handleDeleteParamterItem(record)}>
                  删除
                </a>
                : null
            }
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getAddAttributeParamterList()
  }

  // 获取产品参数列表
  getAddAttributeParamterList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getAddAttributeParamterList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 新增产品参数
  saveAddAttributeParamterItem = (params) => {
    Api.saveAddAttributeParamterItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getAddAttributeParamterList()
      }
    })
  }

  // 编辑产品参数
  saveEditAttributeParamterItem = (params) => {
    Api.saveEditAttributeParamterItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getAddAttributeParamterList()
      }
    })
  }

  // 删除产品参数
  deleteAttributeParamterItem = (params) => {
    Api.deleteAttributeParamterItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getAddAttributeParamterList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      query: search || {},
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getAddAttributeParamterList()
    })
  }

  // 点击新增/编辑按钮
  handleShowParamterModal = (record, type) => {
    this.setState({
      type: type,
      rowDetail: record,
      parameterModal: true
    })
  }

  // 点击确认保存参数
  handleConfirmParamter = (params) => {
    const { type = 1 } = this.state
    if (+type === 1) {
      this.saveAddAttributeParamterItem(params)
    } else {
      this.saveEditAttributeParamterItem(params)
    }
  }

  // 点击删除参数
  handleDeleteParamterItem = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.deleteAttributeParamterItem({ id: record.id })
      }
    })
  }

  closeModal = () => {
    this.setState({
      type: 1,
      rowDetail: {},
      parameterModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getAddAttributeParamterList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getAddAttributeParamterList()
    })
  }

  render () {
    const { buttonMaps = {} } = this.props
    const { pages, searchData = [], tableData = [], type = 1, rowDetail = {}, parameterModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              {
                buttonMaps && buttonMaps['add'] && +buttonMaps['add'].auth === 1
                  ? <Button type='primary'
                    onClick={() => this.handleShowParamterModal({}, 1)}
                  >
                    <Icon type='plus' />新增
                  </Button>
                  : null
              }

            </Row>
            <Row>
              <Table rowKey='id' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          parameterModal
            ? <ParameterModal
              type={type}
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.handleConfirmParamter(params)}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(ParameterList)
