import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import consts from '@/common/utils/consts'
import filter from '@/common/utils/filter'

const { MAX_PAGESIZE } = consts
const { pageSizeOptions } = filter

export default class QuotationList extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
  }
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'quotationOrderId',
        formType: 'Input',
        itemParams: {
          label: '报价单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '报价日期'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '报价商户'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '报价人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'quotationCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '报价单编号',
      key: 'quotationCol-1',
      dataIndex: 'quotationOrderId'
    },
    {
      title: '报价日期',
      key: 'quotationCol-2',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '报价商户',
      key: 'quotationCol-3',
      dataIndex: 'distributorName'
    },
    {
      title: '报价人',
      key: 'quotationCol-4',
      dataIndex: 'platformUserName'
    },
    {
      title: '查看报价单',
      key: 'quotationCol-5',
      render: (text, record, index) => (
        <><Button type='link' size='small' onClick={() => this.handlePreviewQuotation(record)}>点击查看</Button></>
      )
    },
    {
      title: '操作',
      key: 'quotationCol-6',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleLinkToDetails(record)}>查看详情</Button>

          <Button type='link' size='small' onClick={() => this.handleDownloadQuotation(record)}>导出文件</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getQuotationOrderList()
  }

  // 获取报价单列表
  getQuotationOrderList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getQuotationOrderList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 获取报价单PDF url
  getQuotationOrderDetailsPdfUrl = async (params) => {
    const res = await Api.getQuotationOrderDetailsPdfUrl(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data !== '') {
        window.open(data, '_blank')
      } else {
        message.warning('暂无数据')
      }
    } else {
      message.warning(res.message)
    }
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        const { searchData = [] } = this.state
        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取全部成员
  getMemberList = (val = '') => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.list && data.list.length > 0) {
        const { searchData = [] } = this.state

        searchData[3].options = $lodash.concat([{ id: -1, userName: '全部' }], data.list)

        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search['beginDate'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.times[1]).format('YYYY-MM-DD')
      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      },
      query: search || {}
    }, () => {
      this.getQuotationOrderList()
    })
  }

  /* 点击显示全部
   * 执行负责人 platformUserId 为全部的搜索逻辑
  */
  handlePreviewAll = () => {
    const { query = {}, pages = {} } = this.state

    this.setState({
      query: {
        ...query,
        platformUserId: -1
      },
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      }
    }, () => {
      this.getQuotationOrderList()
    })
  }

  // 点击查看详情
  handleLinkToDetails = (record) => {
    this.props.history.push({ pathname: '/admin/quotationDetails',
      state: {
        quotationOrderId: record.quotationOrderId,
        distributorId: record.distributorId,
        distributorName: record.distributorName
      }
    })
  }

  // 点击查看报价单
  handlePreviewQuotation = (record) => {
    this.getQuotationOrderDetailsPdfUrl({ id: record.id })
  }

  // 点击导出文件按钮
  handleDownloadQuotation = (record) => {
    DownloadApi.downloadQuotationDetails({ id: record.id, quotationOrderId: record.quotationOrderId })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getQuotationOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getQuotationOrderList()
    })
  }

  render () {
    const { pages, searchData, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handlePreviewAll()}>显示全部</Button>
          </div>

          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}
