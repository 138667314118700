import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Popconfirm, message, Button } from 'antd'
import { connect } from 'react-redux'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { pageSizeOptions, dispenseStatusMaps } = filter
const { MAX_PAGESIZE } = consts

class UndispenseOrder extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {
          placeholder: '请选择...'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '采购商户'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '采购状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...dispenseStatusMaps
        ]
      },
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '操作人'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['id', 'userName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'undispenseOrder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单编号',
      key: 'undispenseOrder-1',
      dataIndex: 'orderId'
    },
    {
      title: '订单日期',
      key: 'undispenseOrder-2',
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>{ record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '采购商户',
      key: 'undispenseOrder-3',
      dataIndex: 'merchantName'
    },
    {
      title: '订单金额',
      key: 'undispenseOrder-4',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'undispenseOrder-5',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'undispenseOrder-6',
      render: (text, record, index) => (
        <>&yen;{(((+record.payableAmount * 100) - (+record.paidAmount * 100)) / 100).toFixed(2)}</>
      )
    },
    {
      title: '采购状态',
      key: 'undispenseOrder-7',
      dataIndex: 'orderStatus',
      render: (text, record, index) => (
        <>{this.renderDispenseStatusLabel(record.orderStatus)}</>
      )
    },
    {
      title: '操作时间',
      key: 'undispenseOrder-8',
      dataIndex: 'allocateTime',
      render: (text, record, index) => (
        <>{ record.allocateTime ? moment(record.allocateTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '操作人',
      key: 'undispenseOrder-9',
      dataIndex: 'platformUserName'
    },
    {
      title: '操作',
      key: 'undispenseOrder-10',
      width: 160,
      render: (text, record, index) => {
        const { buttonMaps = {} } = this.props

        return (
          <>
            {
              buttonMaps && buttonMaps['dispense'] && +buttonMaps['dispense'].auth === 1 && record.orderStatus === 'Initial'
                ? <Button type='link' size='small' onClick={() => this.handleJumpToDispense(record)}>分发订单</Button>
                : null
            }

            {
              buttonMaps && buttonMaps['cancelOrder'] && +buttonMaps['cancelOrder'].auth === 1 && record.orderStatus === 'End'
                ? <Popconfirm title='确定取消?' onConfirm={() => this.getcloudOrdercancel(record.orderId)}>
                  <Button type='link' size='small'>取消订单</Button>
                </Popconfirm>
                : null
            }
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getMemberList()
    this.getDispensePurchOrderList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取全部成员
  getMemberList = async () => {
    const res = await Api.getMemberList({ pageNo: 1, pageSize: MAX_PAGESIZE })
    const { code, data = {} } = res
    if (+code === 10000) {
      let { searchData = [] } = this.state

      searchData[4].options = data.list && data.list.length > 0 ? $lodash.concat([{ id: -1, userName: '全部' }], data.list) : []

      this.setState({
        searchData
      })
    }
  }

  // 获取待分配订单列表
  getDispensePurchOrderList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getDispensePurchOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取订单产品
  getSupplyOrderGoodsList = (params) => {
    return Api.getSupplyOrderGoodsList(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times && search.times.length === 2) {
      search['beginDate'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.times[1]).format('YYYY-MM-DD')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getDispensePurchOrderList()
    })
  }

  // 点击分发订单
  handleJumpToDispense = (record = {}) => {
    this.props.history.push({ pathname: '/admin/dispenseOrder', state: record })
  }

  // 取消订单
  getcloudOrdercancel = (orderId) => {
    Api.getcloudOrdercancel({ orderId: orderId }).then((res) => {
      const { code } = res
      if (+code === 10000) {
        message.success('取消成功')
        this.getDispensePurchOrderList()
      } else {
        message.error(res.message)
      }
    })
  }
  // 渲染订单处理状态
  renderDispenseStatusLabel = (status) => {
    const obj = dispenseStatusMaps.find(item => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getDispensePurchOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getDispensePurchOrderList()
    })
  }

  render () {
    const { searchData, tableData, pages } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='orderId' bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(UndispenseOrder)
