import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Modal } from 'antd'

import MatchSteps from '../matchSteps/index'
import SheetZone from './sheetZone/index'
import SheetTable from './sheetTable/index'

import Api from '@/common/api/index'
import { submitMatchkeysMaps } from './localdatas'

const { confirm } = Modal

export default class MatchSheet extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 100, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    sourceType: '',
    defaultParams: {},
    importId: null,
    excelName: '', // EXCEL名称
    tableData: [] // 源数据
  }

  componentDidMount () {
    const { importId, sourceType = '', defaultParams = {} } = this.props.history.location.state

    this.setState({
      importId: importId,
      sourceType: sourceType,
      defaultParams: defaultParams
    }, () => {
      this.getUploadExcelAnalysisdatas()
    })
  }

  // 获取上传的EXCEL数据
  getUploadExcelAnalysisdatas = async () => {
    const { importId, pages } = this.state
    const res = await Api.getUploadExcelAnalysisdatas({ importId: importId, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        excelName: data.data.excelName || '',
        sheetName: data.data.sheetDTOList && data.data.sheetDTOList.length > 0 ? data.data.sheetDTOList[0].sheetName : null,
        tableData: data.data.sheetDTOList[0].columnDTOList || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // EXCEL解析数据匹配
  saveUploadExcelAnalysisMatch = async (params) => {
    const res = await Api.saveUploadExcelAnalysisMatch(params)
    const { code } = res
    if (+code === 10000) {
      const { importId, sourceType = '', defaultParams = {} } = this.state
      this.props.history.push({ pathname: '/admin/matchReport', state: { importId: importId, sourceType: sourceType, defaultParams: defaultParams } })
    }
  }

  // 点击开始匹配按钮
  handleSubmit = () => {
    const { importId, sourceType, defaultParams = {} } = this.state
    const params = {
      importId: importId,
      ...defaultParams,
      ...submitMatchkeysMaps[sourceType]
    }

    this.saveUploadExcelAnalysisMatch(params)
  }

  // 点击取消匹配按钮
  handleGoBack = () => {
    confirm({
      title: '确定取消？',
      content: '',
      onOk: () => {
        this.props.history.go(-1)
      }
    })
  }

  render () {
    const { excelName = '', sheetName, tableData = [] } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <MatchSteps
            activeStep={0}
            onCancel={() => this.handleGoBack()}
            onConfirm={() => this.handleSubmit()}
          />

          <SheetZone
            name={excelName}
            sheetName={sheetName}
          />

          <SheetTable list={tableData} />
        </Card>
      </>
    )
  }
}
