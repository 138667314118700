import React, { Component } from 'react'
import { Button, Card, Row, Table, Badge, Modal, message, Icon } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import GetMoenyModal from './getMoenyModal'
import GetDetails from './detailsModal'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { downDeDzDetail } = DownloadApi // 下载模板
const { pageSizeOptions, billStatusMaps, dzMap } = filter

export default class Depponindex extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  state={
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'switchStatus',
        formType: 'Select',
        itemParams: {
          label: '账单状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          ...billStatusMaps
        ]
      },
      {
        name: 'dueMonth',
        formType: 'MonthPicker',
        itemParams: {
          label: '账单日期'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowInfo: {},
    MoenyModal: false, // 确认收款弹出框
    DetailsModal: false, // 详情弹窗
    selectedRowKeys: []
  }
  columns= [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'service-1',
      render: (test, record, index) => (
        <div>
          {
            record.distributorName ? record.distributorName : '----'
          }
        </div>
      )
    },
    {
      title: '真实姓名',
      key: 'service-2',
      render: (test, record, index) => (
        <div>
          {
            record.adminUser ? record.adminUser : '----'
          }
        </div>
      )
    },
    {
      title: '手机号码',
      key: 'service-3',
      render: (test, record, index) => (
        <div>
          {
            record.adminMobile ? record.adminMobile : '----'
          }
        </div>
      )
    },
    {
      title: '账单状态',
      key: 'service-4',
      dataIndex: 'bu',
      render: (text, record, index) => (
        <div>{ this.renderBillStatusLabel(record.status) }</div>
      )
    },
    {
      title: '账单日期',
      key: 'service-5',
      dataIndex: 'dueMonth'
    },
    {
      title: '当月运费应付金额',
      key: 'service-6',
      dataIndex: 'totalAmount',
      render: (text, record, index) => (
        <>&yen;{((+record.totalAmount) * 1000 / 1000).toFixed(2)}</>
      )
    },
    {
      title: '当月运费已付金额',
      key: 'service-7',
      dataIndex: 'payAmount',
      render: (text, record, index) => (
        <>{((+record.payAmount) * 1000 / 1000).toFixed(2)}</>
      )
    },
    {
      title: '当月运费未付金额',
      key: 'service-8',
      render: (text, record, index) => (
        <>
          &yen;{((+record.totalAmount) - (+record.payAmount) * 1000 / 1000).toFixed(2)}
        </>
      )
    },
    {
      title: '申请开票状态',
      key: 'service-9',
      render: (text, record, index) => (
        <div>
          { this.renderOpenedInvoiceStatusLabel(record.invoiceSubmitStatus) }
        </div>
      )
    },
    {
      title: '操作',
      key: 'service-10',
      render: (text, record, index) => (
        <div>
          {
            (+record.totalAmount * 100) - (+record.payAmount * 100) > 0
              ? <a onClick={() => { this.onModalOpen(record) }}>确认收款</a>
              : null
          }
          <a style={{ marginLeft: '20px' }} onClick={() => this.onModalDetails(record)}>查看账单详情</a>
        </div>

      )
    }
  ]

  componentDidMount () {
    this.getlogisticsBillList()
  }

  // 获取快递对账单列表
  getlogisticsBillList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getlogisticsBillList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }
    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 一键生成账单
  getCusZdList=() => {
    Api.getCusZdList({}).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('生成成功')
      } else {
        message.warning(res.message)
      }
    })
  }

  // 确认收款
  getPayMoeny = (params) => {
    Api.saveExpresBillCollect(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      } else {
        message.warning(res.message)
      }
    })
  }

  // 申请快递发票
  saveApplyExpressInvoice = (params) => {
    Api.saveApplyExpressInvoice(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getlogisticsBillList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    search['dueMonth'] = search.dueMonth ? moment(search.dueMonth).format('YYYYMM') : undefined

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getlogisticsBillList()
    })
  }

  // 导出明细
  handExportlist=() => {
    const { query = {} } = this.state

    if (!query.distributorId || +query.distributorId === 0) {
      Modal.warning({
        icon: <Icon type='exclamation-circle' style={{ color: '#FF3434' }} />,
        title: <span style={{ color: '#FF3434' }}>请选择您想导出的商户</span>,
        content: <span>请在搜索栏——商户名称中<br />选择您想导出的商户<br />然后点击搜索</span>
      })

      return false
    }

    downDeDzDetail({ distributorId: query.distributorId })
  }

  // 点击申请快票
  handleApplyExpressInvoice = () => {
    const { selectedRowKeys = [] } = this.state

    this.saveApplyExpressInvoice({ billIdList: selectedRowKeys })
  }

  // 监听表单选择
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 打开收款弹窗
  onModalOpen = (record) => {
    this.setState({
      MoenyModal: true,
      rowInfo: record
    })
  }

  // 打开详情弹框
  onModalDetails = (record) => {
    this.setState({
      rowInfo: record,
      DetailsModal: true
    })
  }

  // 一键生成账单
  handPrintList = () => {
    confirm({
      title: '确认要生成账单？',
      onOk: () => { this.getCusZdList() }
    })
  }

  // 渲染账单账单状态
  renderBillStatusLabel = (code) => {
    let label = ''
    let obj = billStatusMaps.find((item) => { return item.id === code })
    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{ obj.name }</span>} />
    }

    return label
  }

  // 渲染开票状态
  renderOpenedInvoiceStatusLabel = (status) => {
    let label = ''
    let obj = dzMap.find((item) => { return item.id === status })
    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{ obj.name }</span>} />
    }
    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getlogisticsBillList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getlogisticsBillList()
    })
  }

  // 关闭弹框
  oncloseModal = () => {
    this.setState({
      MoenyModal: false,
      DetailsModal: false,
      rowInfo: {}
    })
  }

  render () {
    const { searchData = [], pages, tableData = [], MoenyModal = false, DetailsModal = false, rowInfo = {}, selectedRowKeys = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    // table选中配置
    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.invoiceSubmitStatus === 'YES_SUBMIT'
      })
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Button style={{ marginRight: '10px' }} disabled={selectedRowKeys.length === 0}
                onClick={() => this.handleApplyExpressInvoice()}>申请开票</Button>
              <Button onClick={() => { this.handExportlist() }} type='primary'>
                <Icon type='download' />导出明细
              </Button>
              <Button onClick={() => { this.handPrintList() }} style={{ marginLeft: '10px' }} type='primary'>一键生成账单</Button>
            </Row>
            <Row>
              <Table rowKey='id' bordered
                columns={this.columns}
                dataSource={tableData}
                rowSelection={rowSelection}
                pagination={pagination}
                scroll={{ x: 1200 }}
              />
            </Row>
          </Card>
        </Row>

        {
          MoenyModal
            ? <GetMoenyModal
              rowInfo={rowInfo}
              onConfirm={(params) => this.getPayMoeny(params)}
              oncloseModal={this.oncloseModal}
            />
            : null
        }

        {
          DetailsModal
            ? <GetDetails
              detailInfo={rowInfo}
              oncloseModal={this.oncloseModal} />
            : null
        }
      </div>
    )
  }
}
