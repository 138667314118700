import PurposeSetting from '@/pages/purposeManage/purposeSetting/index'
import SalesDepartmentStatistics from '@/pages/purposeManage/salesDepartmentStatistics/index'
import KASalesStatistics from '@/pages/purposeManage/kaSalesStatistics/index'
import OperationStatistics from '@/pages/purposeManage/operationStatistics/index'
import ImplementationStatistics from '@/pages/purposeManage/implementationStatistics/index'
import DataDepartmentStatistics from '@/pages/purposeManage/dataDepartmentStatistics/index'
import SalesPerformanceTotal from '@/pages/purposeManage/salesPerformanceTotal/index'
import ImplePerformanceTotal from '@/pages/purposeManage/implePerformanceTotal/index'
import OperationPerformanceTotal from '@/pages/purposeManage/operationPerformanceTotal/index'

export default [
  {
    path: '/admin/purposeSetting',
    name: '统计配置',
    component: PurposeSetting
  },
  {
    path: '/admin/salesDepartmentStatistics',
    name: '销售部门统计',
    component: SalesDepartmentStatistics
  },
  {
    path: '/admin/kaSalesStatistics',
    name: '大客户销售统计',
    component: KASalesStatistics
  },
  {
    path: '/admin/operationStatistics',
    name: '客户成功部门统计',
    component: OperationStatistics
  },
  {
    path: '/admin/implementationStatistics',
    name: '实施部门统计',
    component: ImplementationStatistics
  },
  {
    path: '/admin/dataDepartmentStatistics',
    name: '数据部门统计',
    component: DataDepartmentStatistics
  },
  {
    path: '/admin/salesPerformanceTotal',
    name: '销售业绩总览',
    component: SalesPerformanceTotal
  },
  {
    path: '/admin/implementationPerformanceTotal',
    name: '实施业绩总览',
    component: ImplePerformanceTotal
  },
  {
    path: '/admin/operationPerformanceTotal',
    name: '客户成功业绩总览',
    component: OperationPerformanceTotal
  }
]
