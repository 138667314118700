import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Select, Input, message, Row } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import filter from '@/common/utils/filter'
import Api from '@/common/api/index'

import styles from './child.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const Option = Select.Option

const { loginnameReg, phoneReg, passReg, emailReg } = filter

class MemberForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    type: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    detailInfo: {},
    departList: [], // 部门列表
    roleList: [] // 部门角色列表
  }

  componentDidMount () {
    const { type = 1, detailInfo = {} } = this.props
    this.getDepartmentList()
    if (type === 2) {
      this.getDepartRoleList(detailInfo.departId)
    }
  }

  // 获取部门列表
  getDepartmentList = () => {
    let params = {
      pageNo: 1,
      pageSize: 99999
    }

    Api.getDepartmentList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          departList: data.list || []
        })
      }
    })
  }

  // 获取部门角色列表
  getDepartRoleList = (departId) => {
    let params = {
      departId: departId,
      pageNo: 1,
      pageSize: 99999
    }

    Api.getDepartRoleList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          roleList: data.list || []
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type } = this.props

        if (values.loginName === 'admin') {
          return message.warning('不能使用该登录名！')
        }

        if (values.password !== values.confirmPassword) {
          return message.warning('两次输入密码不一致')
        }

        let { password, confirmPassword } = values

        if (type === 2) {
          values.id = detailInfo.id
        } else {
          let jsencrypt = new JSEncrypt()
          jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
          values['password'] = jsencrypt.encrypt(password)
          values['confirmPassword'] = jsencrypt.encrypt(confirmPassword)
        }

        values.roleId = values.roleIds.join(',')

        delete values.roleIds

        this.props.onConfirm(values)
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  render () {
    const { type, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { departList = [], roleList = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <div>
        <Modal title={type === 1 ? '添加成员' : '编辑成员'}
          visible
          width={800}
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.handleCancel}
        >
          <Row style={{ paddingRight: '15px', maxHeight: '300px', overflow: 'auto' }}>
            <Form {...formItemLayout}>
              <FormItem label='员工名称'>
                {
                  getFieldDecorator('userName', {
                    initialValue: detailInfo && detailInfo.userName ? detailInfo.userName : '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='手机号码'>
                {
                  getFieldDecorator('mobile', {
                    initialValue: detailInfo && detailInfo.mobile ? detailInfo.mobile : '',
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !phoneReg.test(value)) {
                                /* eslint-disable */
                                callback('手机号码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写手机号码' }]
                      }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='邮箱'>
                {
                  getFieldDecorator('email', {
                    initialValue: detailInfo && detailInfo.email ? detailInfo.email : '',
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !emailReg.test(value)) {
                                /* eslint-disable */
                                callback('邮箱格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写邮箱地址' }]
                      }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>

              <FormItem label='部门'>
                {
                  getFieldDecorator('departId', {
                    initialValue: detailInfo && detailInfo.departId ? detailInfo.departId : '',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Select placeholder='请输入...'
                      onChange={this.getDepartRoleList}
                    >
                      {
                        departList.map((item, i) => (
                          <Option value={item.id} key={`mDepartList-${i}`}>
                            {item.departName}
                          </Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
              <FormItem label='角色'>
                {
                  getFieldDecorator('roleIds', {
                    initialValue: detailInfo.roleId && detailInfo.roleId !== '' ? detailInfo.roleId.split(',') : [],
                    rules: [
                      { required: true, message: '请设置角色,可多选' }
                    ]
                  })(
                    <Select placeholder='请输入...' mode='multiple'>
                      {
                        roleList.map((role, i) => (
                          <Option value={`${role.roleId}`} key={`mRoleList-${i}`}>
                            {role.roleName}
                          </Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>

              <FormItem label='登录名'
                extra={<span className={`${styles.formExtra}`}>登录名长度为4-16位字符，只能包含数字,大小写字母,下划线</span>}
              >
                {
                  getFieldDecorator('loginName', {
                    initialValue: detailInfo && detailInfo.loginName ? detailInfo.loginName : '',
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !loginnameReg.test(value)) {
                              /* eslint-disable */
                              callback('登录名格式不正确')
                              /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写登录名' }]
                      }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>

              {
                type === 1
                  ? <FormItem label='登录密码'
                    extra={<span className={`${styles.formExtra}`}>密码长度为6-12位字符，可包含数字,字母(区分大小写)</span>}
                  >
                    {
                      getFieldDecorator('password', {
                        initialValue: detailInfo && detailInfo.password ? detailInfo.password : '',
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !passReg.test(value)) {
                                    /* eslint-disable */
                                      callback('登录密码格式不正确')
                                    /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请您填写登录密码' }]
                          }
                        ]
                      })(
                        <Input.Password placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

              {
                type === 1
                  ? <FormItem label='确认密码'>
                    {
                      getFieldDecorator('confirmPassword', {
                        initialValue: detailInfo && detailInfo.confirmPassword ? detailInfo.confirmPassword : '',
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input.Password placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

              <FormItem label='备注信息'>
                {
                  getFieldDecorator('remark', {
                    initialValue: detailInfo && detailInfo.remark ? detailInfo.remark : ''
                  })(
                    <TextArea rows={6} placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const MemberModal = Form.create()(MemberForm)

export default MemberModal
