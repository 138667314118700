import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'antd'
import AddressList from '@/components/addressList/index'

import Api from '@/common/api/index'

export default class CustomerAddressList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {

  }

  componentDidMount () {

  }

  getAddressList = (params) => {
    return Api.getAddressList(params)
  }

  saveAddAddress = (params) => {
    return Api.saveAddAddress(params)
  }

  saveEditAddress = (params) => {
    return Api.saveEditAddress(params)
  }

  saveDeleteAddress = (params) => {
    return Api.saveDeleteAddress(params)
  }

  render () {
    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.props.history.go(-1)}><Icon type='left' />返回</Button>
        </div>
        <AddressList
          id={this.props.history.location.state.distributorId}
          addressType={this.props.history.location.state.addressType}
          getList={(values) => this.getAddressList(values)}
          onSaveAddress={(values) => this.saveAddAddress(values)}
          onEditAddress={(values) => this.saveEditAddress(values)}
          onDeleteAddress={(values) => this.saveDeleteAddress(values)}
        />
      </>
    )
  }
}
