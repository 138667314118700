import Ajax from '../utils/ajax'

export default {
  // ERP管理- 获取ERP客户关系映射列表
  getERPCustomerRelationsList (params) {
    return Ajax.post('/saas/api/listPageByDistributorRelation', params)
  },

  // ERP管理- 保存新增客户关系映射
  saveAddERPCustomerRelation (params) {
    return Ajax.post('/saas/api/distributorRelationAdd', params)
  },

  // ERP管理- 保存编辑客户关系映射
  saveEditERPCustomerRelation (params) {
    return Ajax.post('/saas/api/distributorRelationModify', params)
  },

  // ERP管理- 获取品牌关系映射列表
  getERPBrandRelationsList (params) {
    return Ajax.post('/saas/api/listPageByBrandRelation', params)
  },

  // ERP管理- 保存新增品牌关系映射
  saveAddERPBrandRelation (params) {
    return Ajax.post('/saas/api/brandRelationAdd', params)
  },

  // ERP管理- 保存编辑品牌关系
  saveEditERPBrandRelation (params) {
    return Ajax.post('/saas/api/brandRelationModify', params)
  }
}
