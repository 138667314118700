import React, { Component } from 'react'
import { Row, Card, Table, Badge, message, Button, Icon, Modal } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import CollectModal from './collectModal'
import BilldetailModal from './billdetailModal'
import filter from '@/common/utils/filter'

import Api from '@/common/api/index'

const { pageSizeOptions, dzMap, billStatusMaps } = filter
const { warning } = Modal

export default class InsuranceBill extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '账单状态'
        },
        rules: {
          initialValue: 'ALL'
        },
        cptParams: {},
        options: billStatusMaps
      },
      {
        name: 'dueMonth',
        formType: 'MonthPicker',
        itemParams: {
          label: '账单日期'
        },
        cptParams: {
          placeholder: '选择...'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    rowDetail: {},
    collectModal: false, // 确认收款弹窗
    selectedRowKeys: []
  }
  columns = [
    {
      title: '序号',
      key: 'insuranceBillList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'insuranceBillList-1',
      dataIndex: 'distributorName'
    },
    {
      title: '真实姓名',
      key: 'insuranceBillList-2',
      dataIndex: 'adminUser'
    },
    {
      title: '手机号码',
      key: 'insuranceBillList-3',
      dataIndex: 'adminMobile'
    },
    {
      title: '账单日期',
      key: 'insuranceBillList-4',
      dataIndex: 'dueMonth'
    },
    {
      title: '账单总金额',
      key: 'insuranceBillList-5',
      render: (text, record, index) => (
        <div>&yen;{ (+record.totalAmount * 100 / 100).toFixed(2) }</div>
      )
    },
    {
      title: '账单应付金额',
      key: 'insuranceBillList-6',
      render: (text, record, index) => (
        <div>&yen;{ ((record.totalAmount - record.payAmount) * 100 / 100).toFixed(2) }</div>
      )
    },
    {
      title: '账单已付金额',
      key: 'insuranceBillList-7',
      render: (text, record, index) => (
        <div>&yen;{ (+record.payAmount * 100 / 100).toFixed(2) }</div>
      )
    },
    {
      title: '账单未付金额',
      key: 'insuranceBillList-8',
      render: (text, record, index) => (
        <div>&yen;{ ((record.totalAmount - record.payAmount) * 100 / 100).toFixed(2) }</div>
      )
    },
    {
      title: '申请开票状态',
      key: 'insuranceBillList-9',
      render: (text, record, index) => (
        <div>{ this.renderOpenedInvoiceStatusLabel(record.invoiceSubmitStatus) }</div>
      )
    },
    {
      title: '账单状态',
      key: 'insuranceBillList-10',
      render: (text, record, index) => (
        <div>{ this.renderBillStatusLabel(record.status) }</div>
      )
    },
    {
      title: '操作',
      key: 'insuranceBillList-11',
      width: 150,
      render: (text, record, index) => (
        <div>
          {
            (+record.totalAmount * 100) - (+record.payAmount * 100) > 0
              ? <a style={{ marginRight: '8px' }} onClick={() => this.showCollectionModal(record)}>确认收款</a>
              : null
          }

          <a onClick={() => this.showBillDetailModal(record)}>查看明细</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getInsuranceBillList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取保险对账单列表
  getInsuranceBillList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getInsuranceBillList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保险对账单确认收款
  saveInsuranceBillCollect = (params) => {
    Api.saveInsuranceBillCollect(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getInsuranceBillList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 申请保险发票
  saveApplyInsuranceInvoice = (params) => {
    Api.saveApplyInsuranceInvoice(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getInsuranceBillList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    search['dueMonth'] = search.dueMonth ? moment(search.dueMonth).format('YYYYMM') : undefined

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      },
      query: search
    }, () => {
      this.getInsuranceBillList()
    })
  }

  // 确认收款弹窗
  showCollectionModal = (record) => {
    this.setState({
      rowDetail: record,
      collectModal: true
    })
  }

  // 查看明细弹窗
  showBillDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      billdetailModal: true
    })
  }

  // 点击申请开票
  handleApplyInvoice = () => {
    const { selectedRowKeys = [] } = this.state
    this.saveApplyInsuranceInvoice({ billIdList: selectedRowKeys })
  }

  // 点击导出明细
  handleDownloadBilldetail = () => {
    const { query = {} } = this.state

    if (!query.distributorId || +query.distributorId === 0) {
      warning({
        icon: <Icon type='exclamation-circle' style={{ color: '#FF3434' }} />,
        title: <span style={{ color: '#FF3434' }}>请选择您想导出的商户</span>,
        content: <span>请在搜索栏——商户名称中<br />选择您想导出的商户<br />然后点击搜索</span>
      })

      return false
    }
  }

  // 监听表单选择
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 渲染申请开票状态
  renderOpenedInvoiceStatusLabel = (status) => {
    let label = ''
    let obj = dzMap.find((item) => { return item.id === status })
    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{ obj.name }</span>} />
    }
    return label
  }

  // 渲染账单状态
  renderBillStatusLabel = (status) => {
    let label = ''
    let obj = billStatusMaps.find((item) => { return item.id === status })

    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{obj.name}</span>} />
    }

    return label
  }

  closeModal = () => {
    this.setState({
      collectModal: false,
      billdetailModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getInsuranceBillList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getInsuranceBillList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], collectModal = false, billdetailModal = false, rowDetail = {}, selectedRowKeys = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    // table选中配置
    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.invoiceSubmitStatus === 'YES_SUBMIT'
      })
    }

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <Button type='primary' disabled={selectedRowKeys.length === 0}
                onClick={() => this.handleApplyInvoice()}
              >
                申请开票
              </Button>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleDownloadBilldetail()}>
                <Icon type='download' />导出明细
              </Button>
            </Row>

            <Row>
              <Table rowKey='id' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
                rowSelection={rowSelection}
                scroll={{ x: 1200 }}
              />
            </Row>
          </Card>
        </Row>

        {
          collectModal
            ? <CollectModal
              detailInfo={rowDetail}
              onConfirm={(values) => this.saveInsuranceBillCollect(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          billdetailModal
            ? <BilldetailModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}
