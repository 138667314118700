import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Menu, Icon, Row } from 'antd'

import { setAuthorButtonMaps } from '@/reducers/action'

import logo from '@/assets/images/logo-05.png'

import styles from './aside.module.scss'

const { SubMenu } = Menu

class SideMenu extends Component {
  static propTypes = {
    history: PropTypes.object,
    menus: PropTypes.array,
    dispatch: PropTypes.func
  }

  state = {
    openKeys: [], // 当前展开的数组
    selectedKeys: [] // 当前选中的
  }

  componentDidMount () {
    const { history, menus } = this.props
    let curPath = history.location.pathname

    menus.forEach((item) => {
      item.menuList.forEach(sitem => {
        if (sitem.url === curPath) {
          this.setState({
            openKeys: [`${item.menuId}`],
            selectedKeys: [curPath]
          })
        }
      })
    })
  }

  // 点击跳转路由
  handleJumpLink = (item) => {
    this.setState({
      selectedKeys: [item.key]
    }, () => {
      const { menus = [] } = this.props
      let _maps = {}

      let i = menus.findIndex(menu => {
        return +menu.menuId === +item.keyPath[1]
      })

      if (i > -1) {
        let _menuList = menus[i].menuList

        let j = _menuList.findIndex(rt => {
          return rt.url === item.key
        })

        if (j > -1) {
          _maps = _menuList[j].buttonMaps
        }
      }

      this.props.dispatch(setAuthorButtonMaps({ buttonMaps: _maps }))
      this.props.history.push(item.key)
    })
  }

  // 展开/关闭 SubMenu
  onOpenChange = (keys) => {
    const { menus } = this.props
    const { openKeys } = this.state

    let lastkeys = keys.find(key => {
      return openKeys.indexOf(key) === -1
    })

    let i = menus.findIndex(item => {
      return +item.menuId === +lastkeys
    })

    if (i === -1) {
      this.setState({
        openKeys: keys
      })
    } else {
      this.setState({
        openKeys: lastkeys ? [lastkeys] : []
      })
    }
  }

  render () {
    const { menus = [] } = this.props
    const { openKeys, selectedKeys } = this.state

    return (
      <div className={`${styles.asideWrap}`}>
        <Row className={`${styles.asideLogo}`}>
          <img className={`${styles.logoPic}`} src={logo} />
          <h1>IIASaaS</h1>
        </Row>
        <Row className={`${styles.asideContent}`}>
          <Menu mode='inline' theme='dark'
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenChange={this.onOpenChange}
            onClick={this.handleJumpLink}
          >
            {
              menus.map((menu, index) => (
                <SubMenu key={`${menu.menuId}`}
                  title={<span><Icon type={menu.icon} /><span>{menu.name}</span></span>}
                >
                  {
                    menu.menuList.map((item, i) => (
                      <Menu.Item key={item.url}>
                        {item.name}
                      </Menu.Item>
                    ))
                  }
                </SubMenu>
              ))
            }
          </Menu>
        </Row>
      </div>
    )
  }
}

export default connect()(withRouter(SideMenu))
