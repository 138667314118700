import React, { Component } from 'react'
import { Row, Card, Table, Button, Icon } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'
import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import styles from '../index.module.scss'
import filter from '@/common/utils/filter'
import DownloadApi from '@/common/api/downloadApi'

const { pageSizeOptions } = filter
const skuGoodsTypeMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'CLOUD_SPOT', name: '云现货' },
  { id: 'CLOUD_FUTURES', name: '云期货' }
]
const purchaseTypes = ['AGENT', 'MERCHANT', 'JUST_PURCHASE', 'SUPPLY_PURCHASE']
const supplyTypes = ['AGENT', 'SUPPLY', 'JUST_SUPPLY', 'SUPPLY_PURCHASE']
const warningDaysMaps = [
  { id: 7, name: '7天' },
  { id: 10, name: '10天' },
  { id: 14, name: '14天' },
  { id: 30, name: '30天' },
  { id: 36500, name: '30天以上' }
]

class OrderDelivery extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}

  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '采购方'
        },
        cptParams: {
          placeholder: '请选择商户名称...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val, purchaseTypes, 1)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'supplierId',
        formType: 'Select',
        itemParams: {
          label: '供应方'
        },
        cptParams: {
          placeholder: '请选择商户名称...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val, supplyTypes, 2)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'deliveryPeriodTime',
        formType: 'RangePicker',
        itemParams: {
          label: '预计交期'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          ...skuGoodsTypeMaps
        ]
      },
      {
        name: 'warningDays',
        formType: 'Select',
        itemParams: {
          label: '预警期限'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          ...warningDaysMaps
        ],
        rules: {
          initialValue: 7
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }
  columns = [
    {
      title: '订单编号',
      key: 'ORDER_WARNING_orderId',
      align: 'center',
      dataIndex: 'orderId'
    },
    {
      title: '采购方',
      key: 'ORDER_WARNING_distributorId',
      dataIndex: 'distributorName'
    },
    {
      title: '供应方',
      key: 'ORDER_WARNING_supplierId',
      dataIndex: 'supplierName'
    },
    {
      title: '订单日期',
      key: 'ORDER_WARNING_orderTime',
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>{ record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '预计交期',
      key: 'ORDER_WARNING_deliveryPeriodTime',
      dataIndex: 'deliveryPeriodTime',
      render: (text, record, index) => (
        <>{ record.deliveryPeriodTime ? moment(record.deliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '产品信息',
      key: 'ORDER_WARNING_commoditySkuId',
      dataIndex: 'commoditySkuId',
      render: (text, record, index) => (
        <>
          <div><label className='label'>品牌</label>{ record.brandName }{ record.englishBrandName ? <>/{ record.englishBrandName }</> : null }</div>
          <div><label className='label'>型号</label>{ record.model }</div>
          <div><label className='label'>订货号</label>{ record.itemNumber }</div>
          <div><label className='label'>产品名称</label>{ record.name }</div>
        </>
      )
    },
    {
      title: '产品类型',
      key: 'ORDER_WARNING_skuGoodsType',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '订单数量',
      key: 'ORDER_WARNING_quantity',
      dataIndex: 'quantity'
    },
    {
      title: '未发货数量',
      key: 'ORDER_WARNING_didNotSendNum',
      dataIndex: 'didNotSendNum'
    }
  ]

  componentDidMount () {
    this.getOrderDeliveryWarningList()
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val, types, index) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val, typeList: types }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[index].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[5].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getOrderDeliveryWarningList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getOrderDeliveryWarningList()
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.orderTime && search.orderTime.length === 2) {
      let orderTime = search.orderTime
      search.orderBeginDate = orderTime[0].format('YYYY-MM-DD')
      search.orderEndDate = orderTime[1].format('YYYY-MM-DD')

      delete search.orderTime
    }
    if (search.deliveryPeriodTime && search.deliveryPeriodTime.length === 2) {
      let deliveryPeriodTime = search.deliveryPeriodTime
      search.deliveryBeginDate = deliveryPeriodTime[0].format('YYYY-MM-DD')
      search.deliveryEndDate = deliveryPeriodTime[1].format('YYYY-MM-DD')

      delete search.deliveryPeriodTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getOrderDeliveryWarningList()
    })
  }

  // 点击下载
  handleDownload = () => {
    const { query } = this.state
    let params = {
      ...query,
      pageNo: 1,
      pageSize: 99999
    }
    DownloadApi.downloadOrderDeliveryWarningList(params)
  }

  // 获取主订单交期预警列表
  getOrderDeliveryWarningList = async () => {
    const { query, pages } = this.state
    const res = await Api.getOrderDeliveryWarningList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  render () {
    const { tableData = [], searchData, pages } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }
    return (
      <>
        <div className={`${styles.title}`}>主订单交期预警(7天)</div>

        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Row style={{ marginBottom: '12px' }}>
          <Button type='primary' disabled={tableData.length === 0} onClick={() => this.handleDownload()}>
            <Icon type='download' />导出
          </Button>
        </Row>
        <Table rowKey={(record, index) => `ORDER_WARNING_RECORD-${record.orderId}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </>
    )
  }
}

export default OrderDelivery
