import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Row, Table, Button, message, InputNumber, Pagination, Descriptions, Statistic, Tooltip } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import ProfitModal from '@/pages/cloud-store/brandprofitSetting/profitModal'
import Piecharts from './pieCharts/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderCustomerAuthorLabel, renderSkuGoodsTypeLabel, renderCloudStoreLevelLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, repLineBreak, skuGoodsTypeMaps, cloudStoreLevelMaps } = filter
const skuGoodsTypeOptions = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderEditCell = (form) => {
    const { getFieldDecorator } = form
    const { editing, dataIndex, title, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: '0px' }}>
            <Form.Item style={{ display: 'inline-block', margin: '0px' }}>
              {
                getFieldDecorator(dataIndex, {
                  rules: [
                    { required: true, message: `请填写${title}!` }
                  ],
                  initialValue: +record[dataIndex]
                })(
                  <InputNumber />
                )
              }
            </Form.Item>
            <Form.Item style={{ display: 'inline-block', margin: '0px 0px 0px 4px' }}>%</Form.Item>

          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderEditCell}</EditableContext.Consumer>
  }
}

class InventformTable extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          rows: 4
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...skuGoodsTypeOptions
        ]
      },
      {
        name: 'outDateLevel',
        formType: 'Select',
        itemParams: {
          label: '云仓等级'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudStoreLevelMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    info: {},
    query: {},
    selectedRowKeys: [],
    type: 1, // 1-批量设置 2-单个设置
    profitModal: false,
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'inventSkuCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '分类',
      key: 'inventSkuCol-1',
      ellipsis: true,
      dataIndex: 'classificationName',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.classificationName}>{ record.classificationName }</Tooltip>
        </>
      )
    },
    {
      title: '品牌',
      key: 'inventSkuCol-2',
      dataIndex: 'brandName',
      render: (text, record, index) => (
        <>
          <p><label className='label'>中</label>{ record.brandName }</p>
          <p><label className='label'>英</label>{ record.englishBrandName }</p>
        </>
      )
    },
    {
      title: '系列',
      key: 'inventSkuCol-3',
      ellipsis: true,
      dataIndex: 'series',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.series}>
            <span>{ record.series }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '型号',
      key: 'inventSkuCol-4',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}>
            <span>{record.model}</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'inventSkuCol-5',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}>
            <span>{record.itemNumber}</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'inventSkuCol-6',
      dataIndex: 'name',
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>
            <span>{record.name}</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品类型',
      key: 'inventSkuCol-7',
      width: 80,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '供应价格',
      key: 'inventSkuCol-8',
      dataIndex: 'supplyPrice',
      render: (text, record, index) => (
        <>&yen;{+record.supplyPrice}</>
      )
    },
    {
      title: '云仓价格',
      key: 'inventSkuCol-9',
      dataIndex: 'cloudSupplyPrice',
      render: (text, record, index) => (
        <>&yen;{+record.cloudSupplyPrice}</>
      )
    },
    {
      title: '利润率',
      key: 'inventSkuCol-10',
      editable: true,
      dataIndex: 'skuProfitMargin',
      render: (text, record, index) => (
        <>{ (+record.skuProfitMargin * 100 / 100).toFixed(2) }%</>
      )
    },
    {
      title: '库存数量',
      key: 'inventSkuCol-11',
      dataIndex: 'stock',
      render: (text, record, index) => (
        <>{ record.skuGoodsType === 'CLOUD_FUTURES' ? <span style={{ fontSize: '16px' }}>&infin;</span> : record.stock }</>
      )
    },
    {
      title: '云仓等级',
      key: 'inventSkuCol-12',
      dataIndex: 'outDateLevel',
      render: (text, record, index) => (
        <>{ renderCloudStoreLevelLabel(record.outDateLevel) }</>
      )
    },
    {
      title: '操作',
      key: 'inventSkuCol-13',
      render: (text, record, index) => {
        const { editingKey = null } = this.state
        const editable = this.isEditing(record)

        return editable ? (
          <Row>
            <EditableContext.Consumer>
              {
                form => (
                  <a onClick={() => { this.handleSaveSingleProfitSet(form, record) }}>保存</a>
                )
              }
            </EditableContext.Consumer>
            <a style={{ marginLeft: '8px' }} onClick={() => this.handleCancelProfitSet()}>取消</a>
          </Row>
        ) : (<Row>
          <a disabled={editingKey} onClick={() => this.handleEditSingleProfit(record, 2)}>利润设置</a>
        </Row>)
      }
    }
  ]

  componentDidMount () {
    const { state } = this.props.history.location

    if (state) {
      this.setState({
        info: state
      }, () => {
        this.getMerchantCloudSkuList()
        this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
      })
    }
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([ { brandId: 0, showName: '全部' } ], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取列表
  getMerchantCloudSkuList = () => {
    const { pages, query, info } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      distributorId: info.distributorId,
      ...query
    }

    Api.getMerchantCloudSkuList(params).then(res => {
      const { code, data } = res
      const { pages } = this.state
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存利润设置
  saveProfitInventSkuBatchSetting = (params) => {
    Api.saveProfitInventSkuBatchSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getMerchantCloudSkuList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages } = this.state

    if (search.itemNo) {
      let _paramStr = repLineBreak(search.itemNo, '<br>')
      search['itemNo'] = _paramStr
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => this.getMerchantCloudSkuList())
  }

  // 点击勾选
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击单个利润设置
  handleEditSingleProfit = (record) => {
    this.setState({
      editingKey: `${record.commoditySkuId}-${record.skuGoodsType}`
    })
  }

  // 点击保存单个利润设置
  handleSaveSingleProfitSet = (form, record) => {
    form.validateFields((err, values) => {
      if (!err) {
        const { info } = this.state
        const { skuProfitMargin } = values

        let params = {
          profitMarginDTOList: [{ commoditySkuId: record.commoditySkuId, skuGoodsType: record.skuGoodsType, distributorId: info.distributorId }],
          profitMargin: skuProfitMargin
        }

        this.saveProfitInventSkuBatchSetting(params)
      }
    })
  }

  // 点击批量设置利润
  showProfitModal = (record, type) => {
    this.setState({
      profitModal: true,
      rowDetail: record,
      type: type
    })
  }

  // 点击保存利润设置
  handleSaveProfitSet = (params) => {
    const { info } = this.state
    const { idList } = params

    params['profitMarginDTOList'] = $lodash.map(idList, (item) => {
      return {
        commoditySkuId: item.split('-')[0],
        skuGoodsType: item.split('-')[1],
        distributorId: info.distributorId
      }
    })

    delete params.idList

    this.saveProfitInventSkuBatchSetting(params)
  }

  // 点击取消利润设置
  handleCancelProfitSet = () => {
    this.setState({
      editingKey: null
    })
  }

  isEditing = (record) => `${record.commoditySkuId}-${record.skuGoodsType}` === this.state.editingKey

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  closeModal = () => {
    this.setState({
      profitModal: false,
      type: 1,
      rowDetail: {},
      editingKey: null
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMerchantCloudSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMerchantCloudSkuList()
    })
  }

  render () {
    const { tableData = [], pages, searchData, info = {}, profitModal = false, type = 1, rowDetail = {}, selectedRowKeys = [] } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      }
    })

    // 分页配置
    const pagination = {
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>

        <div style={{ marginBottom: '15px' }}>
          <Card title={<>{info.merchantName}  {`(${renderCustomerAuthorLabel(info.type)})`}</>} bodyStyle={{ padding: '12px' }}
            extra={<><Button type='primary' onClick={() => this.handleBack()}>返回列表</Button></>}
          >
            <Descriptions>
              <Descriptions.Item label='总库存价值'><Statistic prefix={<>&yen;</>} value={info.totalAmount} /></Descriptions.Item>
              <Descriptions.Item label='云供应商品种类'><Statistic value={info.cloudSkuTotal} /></Descriptions.Item>
            </Descriptions>

            {
              info && info.distributorId
                ? <Piecharts distributorId={info.distributorId} />
                : null
            }
          </Card>
        </div>

        <Row>
          <Card title={`虚拟库明细`} bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <SearchForm {...this.props} dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>

            <Row style={{ marginBottom: '10px' }}>
              <Button type='primary' disabled={selectedRowKeys.length === 0}
                onClick={() => this.showProfitModal({}, 1)}
              >勾选批量设置</Button>
            </Row>
            <Row>
              <EditableContext.Provider value={this.props.form}>
                <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}`} bordered
                  components={components}
                  columns={columns}
                  dataSource={tableData}
                  rowSelection={rowSelection}
                  pagination={false}
                />

                <div style={{ margin: '16px 0px', textAlign: 'right' }}>
                  <Pagination {...pagination} />
                </div>
              </EditableContext.Provider>

            </Row>
          </Card>
        </Row>

        {
          profitModal
            ? <ProfitModal
              type={type}
              detailInfo={{ ...rowDetail, profitMargin: rowDetail.skuProfitMargin || 0 }}
              selectedkeys={type === 1 ? selectedRowKeys : [`${rowDetail.commoditySkuId}-${rowDetail.skuGoodsType}`]}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.handleSaveProfitSet(params)}
            />
            : null
        }

      </>
    )
  }
}

const InventDetail = Form.create()(InventformTable)

export default InventDetail
