import React, { Component } from 'react'
import { Button, Table, Card, TimePicker, Input, Col, Row, Modal, message } from 'antd'
import moment from 'moment'

import AddModel from './addModel'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import styles from './index.module.scss'

const { confirm } = Modal
const format = 'HH:mm'
const { TextArea } = Input
const { pageSizeOptions } = filter

export default class NotificationTool extends Component {
  state = {
    addmodel: false,
    delete: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    info: '【IIASaaS】您有一笔云订单未付款，订单号为：{变量}，请尽快处理。',
    time: ''

  }
  columns = [
    {
      title: '序号',
      key: 'miniApply-0',
      width: 100,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '联系人姓名',
      key: 'miniApply-1',
      width: 280,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '联系方式',
      key: 'miniApply-2',
      dataIndex: 'phoneNumber',
      width: 280
    },
    {
      title: '操作',
      key: 'miniApply-3',
      render: (text, record, index) => {
        return (
          <div>
            <a onClick={() => { this.btndelete(record) }}>删除</a>
          </div>

        )
      }
    }
  ]

  componentDidMount () {
    this.notificationList()
    this.notificationSelectime()
  }

  // 获取联系人列表
  notificationList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.notificationList(params).then(res => {
      const { code, data = {} } = res
      if (+code === 10000) {
        this.setState({
          tableData: data || [],
          pages: {
            ...pages,
            total: data.totalNum || 0
          }
        })
      }
    })
  }

  // 删除联系人
  btndelete = (record) => {
    confirm({
      title: '确定删除该联系人吗？',
      onOk: () => {
        this.notificationDelete(record)
      }
    })
  }

  // 删除联系人方法
  notificationDelete =(record) => {
    let param = {
      noticeId: record.id
    }
    Api.notificationDelete(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('删除成功')
        this.notificationList()
      }
    })
  }

  // 点击添加联系人
  onClickAdd = () => {
    this.setState({
      addmodel: true
    })
  }

  // 添加联系人确认按钮
  addsuccess = (param) => {
    Api.notificationAdd(param).then((res) => {
      const { code } = res
      if (+code === 10000) {
        message.success('添加成功')
        this.closeModal()
        this.notificationList()
      }
    })
  }

  // 取消添加联系人弹出框
  closeModal = () => {
    this.setState({
      addmodel: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.notificationList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.notificationList()
    })
  }

  // 时间设置
  onChangeTime = (e, time) => {
    this.setState({
      time
    })
  }

  // 时间设置保存保存
  clickOk = () => {
    const { time } = this.state
    if (time !== '') {
      Api.notificationTime({ time: time }).then((res) => {
        const { code } = res
        if (+code === 10000) {
          message.success('设置成功')
          this.notificationSelectime(0)
        }
      })
    } else {
      message.error('请先设置时间')
    }
  }

  // 时间设置查询
  notificationSelectime =() => {
    Api.notificationSelectime({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          time: data && data.time ? data.time : ''
        })
      }
    })
  }

  render () {
    const { tableData, info, addmodel = false, pages, time } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card>
          <Button type='primary' onClick={() => { this.onClickAdd() }} className={styles.btnadd}>添加联系人</Button>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />

          <Row>
            <Col span={8}>
              <span className={styles.label}>设置提醒时间：</span><TimePicker format={format} value={time && time !== '' ? moment(time, format) : null} onChange={(e, time) => { this.onChangeTime(e, time) }} />
              <h2 className={styles.label}>短信模板：</h2>
              <TextArea disabled rows={4} defaultValue={info} />
              <Button type='primary' className={styles.btnok} onClick={() => { this.clickOk() }}>保存</Button>
            </Col>
          </Row>
        </Card>

        {
          addmodel
            ? <AddModel closeModal={() => { this.closeModal() }} addsuccess={(param) => { this.addsuccess(param) }} />
            : null
        }
      </>
    )
  }
}
