/* 其他页面路由 */
import MatchSheet from '@/pages/templates/matchSheet/index'
import MatchReport from '@/pages/templates/matchReport/index'
import MatchDetails from '@/pages/templates/matchDetails/index'
import MatchSettingSuccess from '@/pages/templates/matchSettingSuccess/index'

export default [
  {
    path: '/admin/matchSheet',
    name: 'EXCEL数据确认',
    component: MatchSheet
  },
  {
    path: '/admin/matchReport',
    name: '匹配结果简报',
    component: MatchReport
  },
  {
    path: '/admin/matchDetails',
    name: '匹配结果详情',
    component: MatchDetails
  },
  {
    path: '/admin/matchSettingSuccess',
    name: '设置结果',
    component: MatchSettingSuccess
  }
]
