import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Table, Button } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import styles from '../index.module.scss'

class BrandAuthOver extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    tableData: []
  }
  columns = [
    {
      title: '品牌',
      key: 'BRANDAUTH_WARNING_brandId',
      dataIndex: 'brandName'
    },
    {
      title: '供应商',
      key: 'BRANDAUTH_WARNING_distributorId',
      dataIndex: 'distributorName'
    },
    {
      title: '代理授权时间',
      key: 'BRANDAUTH_WARNING_agencyStartTime',
      render: (text, record, index) => (
        <>
          {
            record.agencyStartTime && record.agencyEndTime
              ? <>{ moment(record.agencyStartTime).format('YYYY-MM-DD')}&nbsp;至&nbsp;{moment(record.agencyEndTime).format('YYYY-MM-DD')}</>
              : null
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'ORDER_WARNING_operation',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleLinkTo(record)}>去授权</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getBrandAuthOverWarningList()
  }

  // 获取代理证逾期预警列表
  getBrandAuthOverWarningList = async () => {
    const res = await Api.getBrandAuthOverWarningList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 点击去授权
  handleLinkTo = () => {
    this.props.history.push({ pathname: '/admin/cloudExamine' })
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <>
        <div className={`${styles.title}`}>代理证到期预警(15天)</div>
        <Table rowKey={(text, record, index) => `BRANDAUTH-${record.brandId}-${record.distributorId}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />
      </>
    )
  }
}

export default withRouter(BrandAuthOver)
