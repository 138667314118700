import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table, Button, message, Divider } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class DisputeSkus extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'disputeSku-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '类型',
      key: 'disputeSku-8',
      render: (text, record, index) => (`导入数据`)
    },
    {
      title: '品牌',
      key: 'disputeSku-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号/订货号',
      key: 'disputeSku-2',
      render: (text, record, index) => (
        <div>
          <div>产品名称：{record.name}</div>
          <div>型号：{record.model}</div>
          <div>订货号：{record.itemNumber}</div>
        </div>
      )
    },
    {
      title: '价格',
      key: 'disputeSku-3',
      render: (text, record, index) => (
        <div>
          <div>表价：{+record.price}</div>
          <div>默认价：{this.renderCalcDefaultPrice(record)}</div>
        </div>
      )
    },
    {
      title: '最小包装',
      key: 'disputeSku-4',
      width: 100,
      dataIndex: 'minPackQuantity'
    },
    {
      title: '单位',
      key: 'disputeSku-5',
      dataIndex: 'unit',
      width: 80
    },
    {
      title: '失败原因',
      key: 'disputeSku-6',
      dataIndex: 'failedReason'
    },
    {
      title: '操作',
      key: 'disputeSku-7',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.handleCoverSku(record)}>覆盖数据</a>
          <Divider type='vertical' />
          <a onClick={() => this.handleRevoveSku(record)}>不覆盖</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getUploadSkuErrorList()
  }

  // 获取导入失败详情
  getUploadSkuErrorList = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getUploadSkuErrorList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 单个数据覆盖
  sycUpdateSkuToSku = (params) => {
    Api.sycUpdateSkuToSku(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getUploadSkuErrorList()
      }
    })
  }

  // 单个数据不覆盖(删除)
  sysRemoveDisputeSku = (params) => {
    Api.sysRemoveDisputeSku(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getUploadSkuErrorList()
      }
    })
  }

  // 批量数据覆盖
  batchSycToCommoditySku = (params) => {
    Api.batchSycToCommoditySku(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getUploadSkuErrorList()
      }
    })
  }

  // 计算默认价格
  renderCalcDefaultPrice = (record = {}) => {
    const { price, defaultPrice } = record

    let discount = +defaultPrice > 0 ? +defaultPrice : 1
    const num = +price > 0 ? (price * discount * 100 / 100).toFixed(4) : (0 * 100 / 100).toFixed(4)

    return num
  }

  // 点击展开
  handleExpandedOrder = (expanded, record) => {
    let { tableData } = this.state
    let i = tableData.findIndex(item => { return +item.id === +record.id })

    if (expanded && !tableData[i].skuList) {
      Api.getSkuInfoByDisputeId({ id: record.id }).then(res => {
        const { code, data } = res
        if (+code === 10000) {
          let arr = []
          if (data && data.length > 0) {
            arr = data
          }

          tableData[i].skuList = arr || []

          this.setState({
            tableData
          })
        }
      })
    }
  }

  // 渲染对比数据
  renderExpandRow = (record) => {
    const rowColumns = [
      {
        title: '类型',
        key: `expandRow-${record.id}-5`,
        render: (text, record, index) => (`当前数据`)
      },
      {
        title: '品牌',
        key: `expandRow-${record.id}-0`,
        dataIndex: 'brandName'
      },
      {
        title: '型号/订货号',
        key: `expandRow-${record.id}-1`,
        render: (text, record, index) => (
          <div>
            <div>产品名称：{record.name}</div>
            <div>型号：{record.model}</div>
            <div>订货号：{record.itemNumber}</div>
          </div>
        )
      },
      {
        title: '价格',
        key: `expandRow-${record.id}-2`,
        render: (text, record, index) => (
          <div>
            <div>表价：{+record.price}</div>
            <div>默认价：{this.renderCalcDefaultPrice(record)}</div>
          </div>
        )
      },
      {
        title: '最小包装',
        key: `expandRow-${record.id}-3`,
        width: 100,
        dataIndex: 'minPackQuantity'
      },
      {
        title: '单位',
        key: `expandRow-${record.id}-4`,
        dataIndex: 'unit',
        width: 80
      }
    ]

    let _selectedkeys = []

    if (record.selectedRows && record.selectedRows.length > 0) {
      record.selectedRows.map(item => {
        _selectedkeys.push(item.id)
      })
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: _selectedkeys,
      onChange: (keys, rows) => this.onSelectChangekeys(keys, rows, record),
      getCheckboxProps: (row) => ({
        disabled: _selectedkeys.length > 0 ? +row.id !== _selectedkeys[0] : false
      })
    }

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey='id' columns={rowColumns}
          dataSource={record.skuList}
          pagination={false}
          rowSelection={rowSelection}
        />
      </Row>
    )
  }

  // 单个点击覆盖
  handleCoverSku = (record) => {
    if (!record.skuList || record.skuList.length === 0) {
      return message.warning('暂无可覆盖的数据，请检查！')
    }

    if (!record.selectedRows || record.selectedRows.length === 0) {
      return message.warning('请选择要覆盖的当前数据！')
    }

    let params = {
      id: record.id,
      commoditySkuId: record.selectedRows[0].id
    }
    this.sycUpdateSkuToSku(params)
  }

  // 单个点击不覆盖数据
  handleRevoveSku = (record) => {
    this.sysRemoveDisputeSku({ id: record.id })
  }

  // 点击批量数据覆盖
  handleBatchCoverSkus = () => {
    let { selectedRows = [] } = this.state
    if (selectedRows.length === 0) {
      return message.warning('请选择要批量覆盖的数据')
    }

    let keys = []

    let i = selectedRows.findIndex(item => {
      return !item.skuList || item.skuList.length === 0
    })

    if (i > -1) {
      return message.warning('暂无可覆盖的数据，请检查！')
    }

    selectedRows.map(item => {
      if (item.skuList && item.skuList.length > 0) {
        keys.push(item.id)
      }
    })

    let ids = keys.join(',')
    this.batchSycToCommoditySku({ ids: ids })
  }

  // 选择keys
  onSelectChangekeys = (keys, rows, record) => {
    let { tableData = [] } = this.state

    let i = tableData.findIndex(item => { return +item.id === +record.id })

    if (i > -1) {
      tableData[i].selectedRows = rows
    }

    this.setState({
      tableData
    })
  }

  // 点击返回型号列表
  handleBack = () => {
    this.props.history.push({ pathname: '/admin/specification' })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getUploadSkuErrorList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getUploadSkuErrorList()
    })
  }

  render () {
    const { tableData, pages } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Button icon='left' onClick={this.handleBack}>
            返回型号列表
          </Button>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
            onExpand={(expanded, record) => this.handleExpandedOrder(expanded, record)}
            expandedRowRender={(record) => this.renderExpandRow(record)}
          />
        </Card>
      </>
    )
  }
}
