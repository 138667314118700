import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Table, Modal, message, Button, Input } from 'antd'

import ClassficTree from '../component/classficTree/index'
import CategoryModal from './categoryModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { Search } = Input
const { pageSizeOptions } = filter

class ClassifiCation extends Component {
  static propTypes = {

  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 10 },
    modaltype: 1,
    categoryModal: false,
    cateData: [], // 分类列表
    tableData: [],
    searchValue: '', // 搜索关键字
    resultData: [], // 搜索结果
    curClassfic: {}, // 当前分类
    floorData: {}
  }

  resultColumns = [
    {
      title: 'iiaCode',
      key: 'resultCol-0',
      dataIndex: 'iiaCode'
    },
    {
      title: '名称',
      key: 'resultCol-1',
      dataIndex: 'name'
    }
  ]

  componentDidMount () {
    this.getProductCategoryList()
  }

  // 获取产品列表
  getProdList = (params) => {
    return Api.getProductList(params)
  }

  // 获取分类
  getProductCategoryList = () => {
    Api.getProductCategoryList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          cateData: data || []
        })
      }
    })
  }

  // 新增/编辑 保存分类
  saveClassification = (params) => {
    Api.saveClassification(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getProductCategoryList()
      }
    })
  }

  // 删除分类
  deleteClassification = (params) => {
    Api.deleteClassification(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getProductCategoryList()
      }
    })
  }

  // 同步分类信息至商家
  updateMerchantCategorys = () => {
    Api.updateMerchantCategorys({}).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 根据关键字搜索分类
  getSearchCategoryBykeyword = () => {
    const { pages, searchValue = '' } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      name: searchValue
    }

    Api.getSearchCategoryBykeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          resultData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 选中菜单
  selectDropdownMenu = (params) => {
    const { type } = params
    let _row = {}

    if (+type === 3) {
      confirm({
        title: '确定删除该分类？',
        content: '',
        onOk: () => {
          this.deleteClassification({ id: params.id })
        }
      })
    } else {
      +type === 2 ? _row = params : _row = { parentName: params.parentName, parentId: params.parentId }
      this.showClassficModal(type, _row)
    }
  }

  // 设置校验选中的treeNode
  selectTreeNode = (selectedKeys) => {
    let arr = selectedKeys[0].split('-')
    let len = arr.length
    let _row = JSON.parse(arr[len - 1])

    if (_row.type && +_row.type === 1) {
      this.showClassficModal(1, _row)
      return false
    }

    if (+arr[0] < 4) {
      return false
    }

    this.setState({
      floorData: {
        floor: 4,
        rows: {
          ..._row,
          id: arr[4]
        }
      }
    })
  }

  // 点击关键字搜索
  handleSearchKeyword = (value) => {
    if (value === '') {
      return message.warning('请输入搜索关键字')
    }
    let { pages } = this.state

    this.setState({
      searchValue: value,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getSearchCategoryBykeyword()
    })
  }

  // 点击新增一级分类
  handleAddTreeNode = () => {
    this.showClassficModal(1, {})
  }

  // 展示分类弹窗
  showClassficModal = (type, row = {}) => {
    this.setState({
      categoryModal: true,
      modaltype: type,
      rowDetail: row
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      categoryModal: false,
      modaltype: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSearchCategoryBykeyword()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSearchCategoryBykeyword()
    })
  }

  render () {
    const { cateData = [], resultData = [], categoryModal = false, modaltype, rowDetail = {}, pages } = this.state

    const pagination = {
      showSizeChanger: true,
      size: 'small',
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row gutter={12}>
          <Col span={12}>
            <Card title='分类管理' bodyStyle={{ padding: '12px', height: '350px', overflow: 'auto' }}
              extra={<Button type='primary' size='small' onClick={() => this.updateMerchantCategorys()}>同步到代理商</Button>}
            >
              <ClassficTree
                data={cateData}
                onSelect={(params) => { this.selectTreeNode(params) }}
                onSelectMenu={(params) => { this.selectDropdownMenu(params) }}
                onAddTreeNode={(params) => this.handleAddTreeNode(params)}
              />
            </Card>
          </Col>

          <Col span={12}>
            <Card bodyStyle={{ padding: '12px', height: '406px', overflow: 'auto' }}>
              <Row style={{ marginBottom: '15px' }}>
                <Search
                  placeholder='请输入...'
                  enterButton='搜索'
                  onSearch={(value) => this.handleSearchKeyword(value)}
                />
              </Row>
              <Table rowKey='iiaCode'
                columns={this.resultColumns}
                dataSource={resultData}
                pagination={pagination}
              />
            </Card>
          </Col>
        </Row>

        {
          categoryModal
            ? <CategoryModal
              data={rowDetail}
              type={+modaltype}
              onConfirm={(values) => this.saveClassification(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(ClassifiCation)
