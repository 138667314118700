import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Button } from 'antd'

import BatchDetail from './batchDetail'
import LogisticsLand from './logistics'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'DETAIL', name: '产品明细' },
  { id: 'LOGISTICS', name: '物流跟踪' }
]

export default class BatchModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: [],
    activekey: 'DETAIL'
  }

  componentDidMount () {}

  // 点击切换tab
  handleChangeTab = (key) => {
    this.setState({
      activekey: key
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { activekey } = this.state

    return (
      <>
        <Modal title='订单详情' bodyStyle={{ padding: '0px 16px 16px 16px', maxHeight: '500px', overflowY: 'auto' }}
          width={1000}
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          footer={[
            <Button key={`cancel-1`} onClick={() => this.props.onCancel()}>关闭</Button>
          ]}
        >
          <div>
            <Tabs onChange={this.handleChangeTab}>
              {
                tabPaneMaps.map((item) => (
                  <TabPane tab={item.name} key={item.id} />
                ))
              }
            </Tabs>
          </div>
          <>
            {
              activekey === 'DETAIL'
                ? <BatchDetail detailInfo={detailInfo} />
                : null
            }

            {
              activekey === 'LOGISTICS'
                ? <LogisticsLand
                  detailInfo={detailInfo}
                />
                : null
            }

          </>
        </Modal>
      </>
    )
  }
}
