
// 正整数
const num = {
  validator: (rule, value, cb) => {
    if (value && !/^[0-9]*[1-9][0-9]*$/.test(value)) {
      // eslint-disable-next-line standard/no-callback-literal
      cb('请输入正整数')
    } else {
      cb()
    }
  }
}
// 手机号校验
const phone = {
  validator: (rule, value, cb) => {
    if (value && !/^1[3456789]\d{9}$/.test(value)) {
      // eslint-disable-next-line standard/no-callback-literal
      cb('请输入正确的手机号')
    } else {
      cb()
    }
  }
}

/* 密码规则
*  6-12位字符 可包含数字，大小写字母
*/
const passReg = /^[a-zA-Z0-9]{6,12}$/

/* 支付密码规则
*  6位数字
*/
const paywordReg = /^\d{6}$/

// 手机号码规则
const phoneReg = /^1[3456789]\d{9}$/

/* 登录名规则
*  4-16位字符 可包含数字、大小写字母、下划线
*/
const loginnameReg = /^[a-zA-Z0-9_]{4,16}$/

/* 邮箱规则
*/
const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/

// 去掉字符串首尾空格
const trim = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

// 去掉字符串所有空格
const trimAll = (str) => {
  return str.replace(/\s+/g, '')
}

// 去掉中间空格
const CTrim = (str) => {
  return str.replace(/\s/g, '')
}

// 去掉字符串所有中横线
const trimLine = (str) => {
  return str.replace(/-/g, '')
}

// 替换字符串中的换行
const repLineBreak = (str, sym) => {
  return str.replace(/\n/g, sym)
}

/**
 * 分页配置
 */
const pageSizeOptions = ['10', '20', '50', '100', '200']

// 商家权限
const authorMaps = [
  { id: 'AGENT', name: '代理商' },
  { id: 'SUPPLY', name: '供应商' },
  { id: 'MERCHANT', name: '商户' },
  { id: 'JUST_SUPPLY', name: '纯供应商-只供应' },
  { id: 'JUST_PURCHASE', name: '纯采购商' },
  { id: 'SUPPLY_PURCHASE', name: '工聚宝' }
]

// 品牌类型
const brandTypeMaps = [
  { id: 'INLAND', name: '国内品牌' },
  { id: 'INTERNATIONAL', name: '国际品牌' }
]

// sku是否停产枚举
const skuStopFlagMaps = [
  { id: 'ON_SALE', name: '否' },
  { id: 'STOP_PRODUCTION', name: '是' }
]

// 云供应审核状态
const cloudSupplyExamineMaps = [
  { id: 'AUDIT_ACCEPTED', name: '待审核', result: 'processing' },
  { id: 'AUDIT_SUCCESS', name: '已通过', result: 'success' },
  { id: 'AUDIT_FAIL', name: '已拒绝', result: 'error' }
]

// 代理证逾期状态枚举
const agencyStatusMaps = [
  { id: 'UN_OT', name: '未逾期', status: 'success' },
  { id: 'OT', name: '已逾期', status: 'error' }
]

// 云仓等级枚举
const cloudStoreLevelMaps = [
  { id: 'FIRST_LEVEL', name: '一级' },
  { id: 'SECOND_LEVEL', name: '二级' },
  { id: 'LAST_LEVEL', name: '三级' }
]

// 订单类型枚举
const orderTypeMaps = [
  { id: 'MIXED', name: '混合订单' },
  { id: 'SPOT', name: '自营现货订单' },
  { id: 'FUTURES', name: '自营期货订单' },
  { id: 'CLOUD_SPOT', name: '云现货订单' },
  { id: 'CLOUD_FUTURES', name: '云期货订单' }
]

// 订单状态
const orderStatusMaps = [
  { id: 'INITIAL', code: '0', name: '待EU用户付款' },
  { id: 'PAYING', code: '10', name: '待付款' },
  { id: 'CANCEL_IN_PAYING', code: '11', name: '未支付取消' },
  { id: 'CANCEL_OVERDUE', code: '12', name: '超时取消' },
  { id: 'CANCEL_MANUAL', code: '13', name: '手动取消' },
  { id: 'CANCEL_AFTER_PAYING', code: '90', name: '已付首款取消' },
  { id: 'TAIL_PAYING', code: '99', name: '待付尾款' },
  { id: 'WAIT_TO_SHIP', code: '100', name: '待发货' },
  { id: 'WAIT_ORDER_GOODS', code: '20', name: '待订货' },
  { id: 'WAIT_TO_RECEIVE', code: '110', name: '已发货' },
  { id: 'ORDER_COMPLETE', code: '1000', name: '订单完成' }
]

// 订单取消 状态集合
const cancelOrderStatusMaps = [
  { id: 'CANCEL_IN_PAYING', code: '11', name: '未支付取消' },
  { id: 'CANCEL_OVERDUE', code: '12', name: '超时取消' },
  { id: 'CANCEL_MANUAL', code: '13', name: '手动取消' },
  { id: 'CANCEL_AFTER_PAYING', code: '90', name: '已付首款取消' }
]

// 订单发货状态
const orderDeliveryStatusMaps = [
  { id: 'NO', name: '待发货' },
  { id: 'PART', name: '部分发货' },
  { id: 'COMPLETE', name: '已发货' }
]

// 订单发票开票状态枚举
const invoiceOpenedMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'OPENING', name: '未开票', badge: 'warning' },
  { id: 'OPENED', name: '已开票', badge: 'success' },
  { id: 'DISCARDED', name: '已废弃', badge: 'error' }
]

// 待采购订单分配处理状态
const dispenseStatusMaps = [
  { id: 'Initial', name: '未处理' },
  { id: 'End', name: '已处理' },
  { id: 'CANCEL_IN_PAYING', name: '取消订单(未支付取消)' },
  { id: 'CANCEL_OVERDUE', name: '取消订单(超时过期自动取消)' },
  { id: 'CancelByHand', name: '取消订单(手动取消)' }
]

// 名片审核状态
const cardApplyStatusMaps = [
  { id: 'APPLY_IN', code: 0, name: '待审核', result: 'processing' },
  { id: 'RE_APPLY', code: 2, name: '待审核(重新提交)', result: 'processing' },
  { id: 'APPROVED', code: 4, name: '已通过', result: 'success' },
  { id: 'REJECT_BACK', code: 1, name: '已驳回', result: 'error' },
  { id: 'EXPAND_APPLY', code: 5, name: '申请扩容', result: 'processing' }
]

// 名片- 团队规模
const cardLevelMaps = [
  { id: 20, name: '0-20人（免费版）' },
  { id: 100, name: '20-100人（￥99）' },
  { id: 9999, name: '100人以上（￥299）' }
]

// 消息类型总览
const notifyTypeMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'SYSTEM_MSG', name: '系统通知' },
  { id: 'SYSTEM_NOTIFY', name: '活动通知' },
  { id: 'SYSTEM_ANNOUNCEMENT', name: '系统公告' },
  { id: 'TASK_DISTRIBUTE', name: '任务分发' },
  { id: 'PLATFORM_ANNOUNCEMENT', name: 'IIASaaS公告' }
]

// 消息阅读状态
const notifyReadMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'UNREAD', name: '未读' },
  { id: 'READ', name: '已读' }
]

// 物流服务枚举
const logisticsWayMaps = [
  { id: 'SELF', name: '自营服务' },
  { id: 'SAAS', name: 'IIASaaS服务' },
  { id: 'TAKE_THEIR', name: '客户自提' }
]

// IIASaaS保险服务发票&IIASaaS快递服务发票状态
const serverInvoiceOpenedMaps = [
  { id: 'UN_INVOICED', name: '未开票', badge: 'error' },
  { id: 'INVOICED', name: '已开票', badge: 'success' }
]

// 账单状态(德邦 顺丰 保险)
const billStatusMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'INIT', name: '初始化', badge: 'processing' },
  { id: 'OVERDUE', name: '已逾期', badge: 'error' },
  { id: 'SETTLED', name: '已结算', badge: 'success' },
  { id: 'NOT_OVERDUE', name: '未逾期', badge: 'warning' }
]

// 保险服务类型枚举
const insuranceTypeMaps = [
  { id: 'COMMON', name: '普通' },
  { id: 'YEAR', name: '年费' }
]

// IIASaaS快递服务公司
const saasCouierCodeMaps = [
  {
    id: 'SAAS-DBL',
    courierCode: 'SAAS-DBL',
    courierName: 'IIASaaS服务（德邦）',
    aliasCode: 'DBL',
    aliasName: '德邦快递'
  },
  {
    id: 'SAAS-SF',
    courierCode: 'SAAS-SF',
    courierName: 'IIASaaS服务（顺丰）',
    aliasCode: 'SF',
    aliasName: '顺丰速运'
  },
  // {
  //   id: 'SAAS-JD',
  //   courierCode: 'SAAS-JD',
  //   courierName: 'IIASaaS服务（京东）',
  //   aliasCode: 'JD',
  //   aliasName: '京东快递'
  // },
  {
    id: 'SAAS-KYSY',
    courierCode: 'SAAS-KYSY',
    courierName: 'IIASaaS服务（跨越速运）',
    aliasCode: 'KYSY',
    aliasName: '跨越速运'
  }
]

// IIASaaS快递服务类型
const expressTypeMaps = {
  'SAAS-DBL': [
    { id: 'PACKAGE', code: 'DEPPON_PACKAGE', name: '标准快递', keysort: 'courierDiscount' },
    { id: 'RCP', code: 'DEPPON_RCP', name: '大件快递360', keysort: 'courierDiscount' },
    { id: 'JZKH', code: 'DEPPON_JZKH', name: '精准卡航', keysort: '--' },
    { id: 'JZQY_LONG', code: 'DEPPON_JZQY_LONG', name: '精准汽运', keysort: '--' },
    { id: 'NZBRH', code: 'DEPPON_NZBRH', name: '重包入户', keysort: 'logisticsDiscount' },
    { id: 'ZBTH', code: 'DEPPON_ZBTH', name: '重包特惠', keysort: 'logisticsDiscount' }
  ],
  'SAAS-SF': [
    { id: '1', name: '顺丰特快（空运）' },
    { id: '2', name: '顺丰标快（陆运）' },
    { id: '255', name: '顺丰卡航（物流）' },
    { id: '111', name: '顺丰干配' },
    { id: '231', name: '陆运包裹' },
    { id: '263', name: '同城半日达（快递）' }
  ],
  'SAAS-JD': [
    { id: '1', code: 'JD_1', name: '特惠送' },
    { id: '6', code: 'JD_6', name: '特快重货' },
    { id: '25', code: 'JD_25', name: '特快零担' }
  ],
  'SAAS-KYSY': [
    { id: '20', code: 'KYSY_20', name: '次日达' },
    { id: '30', code: 'KYSY_30', name: '隔日达' },
    { id: '40', code: 'KYSY_40', name: '陆运件' },
    { id: '50', code: 'KYSY_50', name: '同城次日' },
    { id: '160', code: 'KYSY_160', name: '省内次日' }
  ]
}

// IIASaaS快递服务签单返还枚举类
const expressReceiptFlags = {
  'SAAS-DBL': [
    { id: 'DEPPON_0', name: '无需返单' },
    { id: 'DEPPON_1', name: '签收单原件返回' },
    { id: 'DEPPON_2', name: '电子签收单' }
  ],
  'SAAS-SF': [
    { id: 'SF_NO', name: '无需返单' },
    { id: 'SF_IN03', name: '纸质回单' },
    { id: 'SF_IN149', name: '电子回单' }
  ],
  'SAAS-JD': [
    { id: 'JD_0', name: '无签单' },
    { id: 'JD_1', name: '纸质签单' },
    { id: 'JD_3', name: '电子签单' },
    { id: 'JD_4', name: '纸质签单和电子签单' }
  ],
  'SAAS-KYSY': [
    { id: 'KY_20', name: '无需回单', key: '20' },
    { id: 'KY_10', name: '回单原件(含回单照片)', key: '10' },
    { id: 'KY_30', name: '回单照片', key: '30' },
    { id: 'KY_40', name: '电子回单', key: '40' }
  ]
}

// 快递服务- 签收单返回类型枚举类
const expressReceiptReturnRequirements = {
  'SAAS-DBL': [
    { id: 'R1', name: '签名' },
    { id: 'R2', name: '盖章' },
    { id: 'R3', name: '签身份证号' },
    { id: 'R4', name: '身份证复印件' },
    { id: 'R5', name: '仓库收货回执单' }
  ]
}

// 快递服务木架包装方式枚举
const expressPackWoodenFrames = {
  'SAAS-KYSY': [
    { id: '0', name: '不打木架' },
    { id: '10', name: '打卡板' },
    { id: '20', name: '打木架' },
    { id: '30', name: '打木箱' }
  ]
}

// 物流价格对账状态
const dzMap = [
  { id: 'UNKNOWN', name: '未知', badge: 'error' },
  { id: 'NO_SUBMIT', name: '未申请', badge: 'warning' },
  { id: 'YES_SUBMIT', name: '已申请', badge: 'success' }
]

// 对账单提交状态
const expressmoenyMap = [
  { id: 'SUCCESS', name: '是', badge: 'success' },
  { id: 'FAIL', name: '否', badge: 'warning' },
  { id: 'UNABLE_PROCESS', name: '无法计算', badge: 'error' }
]

// 客户初始化
const cusMap = [
  { id: 'ALL', name: '全部', color: 'error' },
  { id: 'UN_INITED', name: '未完成', color: 'red' },
  { id: 'INITED', name: '已完成', badcolorge: 'green' }
]

// 启用/禁用状态检索
const disableStatusMaps = [
  { id: 'ENABLE', name: '启用' },
  { id: 'DISABLE', name: '禁用' }
]

// ERP启用状态
const erpEnableStatusMaps = [
  { id: 'ENABLE', name: '启用' },
  { id: 'DISABLE', name: '禁用' },
  { id: 'UNDERWAY', name: '进行中' },
  { id: 'UNKNOWN', name: '未知' }
]

// 报表月份options
const fullsMonthOptions = [
  { month: 1, quarter: 1, monthLabel: '一月', monthTitle: 'Jan', value: 0 },
  { month: 2, quarter: 1, monthLabel: '二月', monthTitle: 'Feb', value: 0 },
  { month: 3, quarter: 1, monthLabel: '三月', monthTitle: 'Mar', value: 0 },
  { month: 4, quarter: 2, monthLabel: '四月', monthTitle: 'Apr', value: 0 },
  { month: 5, quarter: 2, monthLabel: '五月', monthTitle: 'May', value: 0 },
  { month: 6, quarter: 2, monthLabel: '六月', monthTitle: 'Jun', value: 0 },
  { month: 7, quarter: 3, monthLabel: '七月', monthTitle: 'Jul', value: 0 },
  { month: 8, quarter: 3, monthLabel: '八月', monthTitle: 'Aug', value: 0 },
  { month: 9, quarter: 3, monthLabel: '九月', monthTitle: 'Sep', value: 0 },
  { month: 10, quarter: 4, monthLabel: '十月', monthTitle: 'Oct', value: 0 },
  { month: 11, quarter: 4, monthLabel: '十一月', monthTitle: 'Nov', value: 0 },
  { month: 12, quarter: 4, monthLabel: '十二月', monthTitle: 'Dec', value: 0 }
]

// 图表基本颜色设置
const echartscolorinit = [
  '#818AF8', '#4FA8F9', '#FC8B40 '
]

// 短信模板发送类型
const SMSTypeMaps = [
  { id: 'CAPTCHA', name: '验证码' },
  { id: 'NOTICE', name: '短信通知' },
  { id: 'BROADCAST', name: '推广短信' },
  { id: 'OTHER', name: '国际/港澳台消息' }
]
// 短信模板发送状态
const SMSSendTypeMaps = [
  { id: 'SEND_IND', name: '发送中', color: '#CC9900' },
  { id: 'SEND_SUCCESS', name: '发送成功', color: '#74DC51' },
  { id: 'SEND_FAILED', name: '发送失败', color: '#FF6969' }
]

// 短信模板审核状态
const SMSExamineStatusMaps = [
  { id: 'AUDIT_ING', name: '审核中', color: '#CC9900' },
  { id: 'AUDIT_SUCCESS', name: '审核通过', color: '#74DC51' },
  { id: 'AUDIT_FAILED', name: '审核失败', color: '#FF6969' },
  { id: 'DISABLED', name: '删除', color: '#F5222D' }
]

// 是否为平台发送
const SMSSendByPlatformMaps = [
  { id: 'DEFAULT_YES', name: '是' },
  { id: 'DEFAULT_NO', name: '否' }
]

// 询价单-数据类型枚举
const enquireMatchStatusMaps = [
  { id: 'MATCHED', name: '已收录数据' },
  { id: 'UNMATCHED', name: '未收录数据' }
]

// 询价单-询价单提交类型
const enquireOrderStateMaps = [
  { id: 'PLATFORM_DRAFT', name: '草稿' },
  { id: 'INTERNAL_DRAFT', name: '草稿' },
  { id: 'PLATFORM', name: '运营提交' },
  { id: 'MERCHANT', name: '商户提交' },
  { id: 'INTERNAL', name: '内部提交' },
  { id: 'MERCHANT_PRE_QUOTATION', name: '预报价询价' },
  { id: 'MERCHANT_QUICK_ORDER', name: '快速下单询价' }
]

// 询价单-报价类型
const answerEnquiredStatusMaps = [
  { id: 'NO', name: '暂未报价' },
  { id: 'RECEIVE', name: '同意报价' },
  { id: 'REFUSE', name: '拒绝报价' },
  { id: 'COMMUNICATE', name: '沟通报价' }
]

// 询价单-询价单逾期状态
const enquireOverdueStatusMaps = [
  { id: 'OVERDUE', name: '已逾期' },
  { id: 'UN_OVERDUE', name: '未逾期' }
]

// 询价单-协作状态
const collaborateStateMaps = [
  { id: 'UNKNOWN', name: '未知' },
  { id: 'NO_QUOTATION', name: '未报价' },
  { id: 'HAS_QUOTATION', name: '已报价' },
  { id: 'STOP_QUOTATION', name: '停止报价' }
]

// 产品供应云仓状态枚举
const joinedCloudSupplyStatusMaps = [
  { id: 'UN_JOINED', name: '未供应云仓' },
  { id: 'JOINED', name: '已供应云仓' }
]

// 工单-工单处理状态枚举
const workOrderStateMaps = [
  { id: 'PROCESS', name: '已处理' },
  { id: 'UN_PROCESS', name: '未处理' }
]

// 工单-工单类型枚举
const workOrderTypeMaps = [
  { id: 'HELP_QUOTATION', name: '协作报价' },
  { id: 'AUTO_GENERATE', name: '自动发布' },
  { id: 'BATCH_GENERATE', name: '批量报价' },
  { id: 'HELP_LIST_QUOTATION', name: '协作列表报价' },
  { id: 'EXCEL_IMPORT_GENERATED', name: '工单导入' },
  { id: 'AUTO_QUOTATION', name: '自动报价' }
]

// 工单-是否承担运费
const isUndertakeFreightMaps = [
  { id: 'UNDERTAKE', name: '承担运费' },
  { id: 'UN_UNDERTAKE', name: '不承担运费' }
]

// 商品类型
const skuGoodsTypeMaps = [
  { id: 'SPOT', name: '现货', aliasName: '现货' },
  { id: 'FUTURES', name: '期货', aliasName: '期货' },
  { id: 'CLOUD_SPOT', name: '云现货', aliasName: '现货调货' },
  { id: 'CLOUD_FUTURES', name: '云期货', aliasName: '期货调货' }
]

// 成交毛利率
const grossRateMaps = [
  { id: '0-1', name: '0-1' },
  { id: '1-2', name: '1-2' },
  { id: '2-3', name: '2-3' },
  { id: '3-4', name: '3-4' },
  { id: '4-5', name: '4-5' },
  { id: '5', name: '≥5' }
]

// 发票- 发票类型枚举
const invoiceTypeMaps = [
  { id: 'ORDINARY_INVOICE', name: '增值税普通发票' },
  { id: 'SPECIAL_INVOICE', name: '增值税专用发票' }
]

// 发票- 开票状态枚举
const invoiceOpenedStatusMaps = [
  { id: 'WAIT_TO_APPLY', name: '待申请' },
  { id: 'OPENING', name: '待开票' },
  { id: 'OPENED', name: '已开票' },
  { id: 'DISCARDED', name: '已废弃' }
]

// 售后- 售后类型
const saledTypeMaps = [
  { id: 'REFUND_RETURN_PRODUCTS', name: '退货退款' },
  { id: 'REFUND', name: '退款' },
  { id: 'CHANGE_PRODUCTS', name: '换货' },
  { id: 'ADD_DELIVER', name: '补寄' }
]

// 售后- 售后状态
const saledStatusMaps = [
  { id: 'WAITING', name: '待受理' },
  { id: 'WAITING_AUDIT', name: '待审核' },
  { id: 'AUDIT_FAILED', name: '审核驳回' },
  { id: 'REJECT', name: '拒绝申请' },
  { id: 'WAITING_BACK', name: '待退货' },
  { id: 'WAITING_REFUND', name: '待退款' },
  { id: 'WAITING_DELIVER', name: '待发货' },
  { id: 'COMPLETE', name: '已完成' }
]

// 售后- 云供应售后状态枚举
const supplySaledStatusMaps = [
  { id: 'WAITING_AUDIT', name: '待审核' },
  { id: 'AUDIT_SUCCESS', name: '审核通过' },
  { id: 'AUDIT_FAILED', name: '审核驳回' }
]

// 售后- 售后原因枚举
const saledReasonTypeMaps = [
  { id: 'PRODUCT_FOR_PAGE_NOT_SAME', name: '商品与页面描述不符' },
  { id: 'SKU_CHOOSE_ERROR', name: '型号订货号选择错误' },
  { id: 'GOODS_MISSED', name: '少件/发错货/未收到货' },
  { id: 'GOODS_WAS_BAD', name: '商品破损/包装破损/序列号模糊' },
  { id: 'PRODUCTS_QUALITY_PROBLEM', name: '商品质量问题/存在故障' },
  { id: 'FAKE_OR_SECOND_HAND_GOODS', name: '商品疑似假货/二手商品' }
]

// 售后- 退款方式枚举
const saledRefundTypeMaps = [
  { id: 'ORIGINAL_PAYMENT_TYPE', name: '原支付返还' }
]

// 售后- 退货方式
const saledBackGoodsTypeMaps = [
  { id: 'IIASAAS', name: 'IIASaaS服务上门取件' },
  { id: 'SELF', name: '自行退货至IIASaaS' }
]

export default {
  SMSTypeMaps,
  SMSSendTypeMaps,
  SMSExamineStatusMaps,
  SMSSendByPlatformMaps,
  num,
  phone,
  loginnameReg,
  passReg,
  paywordReg,
  phoneReg,
  emailReg,
  pageSizeOptions,
  skuStopFlagMaps,
  cusMap,
  disableStatusMaps,
  erpEnableStatusMaps,

  authorMaps,
  brandTypeMaps,
  cloudSupplyExamineMaps,
  cloudStoreLevelMaps,
  agencyStatusMaps,

  trim,
  trimAll,
  CTrim,
  trimLine,
  repLineBreak,
  dzMap,

  orderTypeMaps,
  orderStatusMaps,
  cancelOrderStatusMaps,
  orderDeliveryStatusMaps,
  invoiceOpenedMaps,
  dispenseStatusMaps,

  cardApplyStatusMaps,
  cardLevelMaps,

  notifyTypeMaps,
  notifyReadMaps,

  logisticsWayMaps,
  serverInvoiceOpenedMaps,
  billStatusMaps,
  insuranceTypeMaps,
  saasCouierCodeMaps,
  expressTypeMaps,
  expressmoenyMap,
  expressReceiptFlags,
  expressReceiptReturnRequirements,
  expressPackWoodenFrames,

  fullsMonthOptions,
  echartscolorinit,
  enquireMatchStatusMaps,
  enquireOrderStateMaps,
  answerEnquiredStatusMaps,
  enquireOverdueStatusMaps,
  collaborateStateMaps,
  joinedCloudSupplyStatusMaps,
  workOrderStateMaps,
  workOrderTypeMaps,
  isUndertakeFreightMaps,
  skuGoodsTypeMaps,
  grossRateMaps,

  invoiceTypeMaps,
  invoiceOpenedStatusMaps,

  saledTypeMaps,
  saledStatusMaps,
  supplySaledStatusMaps,
  saledReasonTypeMaps,
  saledRefundTypeMaps,
  saledBackGoodsTypeMaps
}
