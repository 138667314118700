import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Button, message, Modal } from 'antd'
import $lodash from 'lodash'

import EnquirePreviewTable from '../../components/enquirePreviewTable/index'
import QuotationPreviewTable from '../../components/quotationPreviewTable/index'
import Remarks from '../../components/remarks/index'

import styles from './index.module.scss'

const { confirm } = Modal

export default class EnquireDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    list: PropTypes.array,
    quotationList: PropTypes.array,
    enquireRemarks: PropTypes.array,
    quotationRemarks: PropTypes.array,
    quotationDesc: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () {

  }

  /* 点击确定
   * 重复数据提示- 仅从commoditySkuId > 0的数据里面选取是否重复
  */
  handleConfirm = (quotationList = []) => {
    if (quotationList.length === 0) { return message.warning('暂无数据~~') }

    const datas = $lodash.groupBy(quotationList, 'commoditySkuId')
    const repeatdatas = [] // 重复的数据项

    $lodash.forEach(datas, (values, key) => {
      if (+key > 0 && values.length > 1) {
        repeatdatas.push(values[0])
      }
    })

    if (repeatdatas.length > 0) {
      confirm({
        title: '数据重复，请确认',
        content: (<>
          {
            repeatdatas.map((item, index) => (
              <p key={`repeatItem-${index}`}>订货号{item.itemNumber}存在相同数据</p>
            ))
          }
        </>),
        onOk: () => {
          this.props.onConfirm(quotationList)
        }
      })
    } else {
      this.props.onConfirm(quotationList)
    }
  }

  render () {
    const { visible = false, list = [], quotationList = [], enquireRemarks = [], quotationRemarks = [], quotationDesc = '', onCancel } = this.props

    return (
      <Drawer width={1300} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        <div style={{ marginBottom: '24px' }}>
          <div style={{ marginBottom: '15px' }}>
            <EnquirePreviewTable list={list} />
          </div>
          <Remarks title='商户备注' remarks={enquireRemarks} />
        </div>

        <div>
          <div style={{ marginBottom: '15px' }}>
            <QuotationPreviewTable
              descriptions={quotationDesc}
              list={quotationList}
            />
          </div>
          <Remarks title='客服备注' remarks={quotationRemarks} />
        </div>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>

          <Button type='primary' disabled={list.length === 0} style={{ marginLeft: '15px' }} onClick={() => this.handleConfirm(quotationList)}>生成报价单</Button>
        </div>
      </Drawer>
    )
  }
}
