
import { downloadFileByGet, downloadFileByPost } from '../utils/downloadFile'

export default {
  // 下载-下载模板
  downloadCommonTemplate (params) {
    return downloadFileByGet('/api/excel/template/download/common', params)
  },

  // 下载-sku导入模板
  downloadUploadProductTemplate (params) {
    return downloadFileByGet('/commodity/sku/skuTemplateExport', params)
  },

  // 下载- 下载系列导入模板
  downloadSeriesUploadTemplate (params) {
    return downloadFileByGet('/api/excel/template/download/seriesTemplate', params)
  },

  // 下载- 自动报价价格导入模版
  downloadAutoQuotationTemplate (params) {
    return downloadFileByGet('/api/excel/template/download/autoQuotationTemplate', params)
  },

  // 下载-供应订单明细
  downSupplyOrderDetail (params) {
    return downloadFileByGet('/platform/supplyOrder/orderGoodsExport', params)
  },

  // 下载-供应订单发票产品
  downProductBySupplyInvoice (params) {
    return downloadFileByGet('/platform/disInvoiceRecord/invoiceInfoExport', params)
  },

  // 下载- 云采购发票-待开票- 导出云采购发票产品
  downloadPurchaseOrderInvoiceUnOpenProducts (params) {
    return downloadFileByPost('/dis/invoice/invoiceInfoExport', params)
  },

  // 下载- 云采购发票-待开票- 导出云采购电子发票产品
  downloadPurchaseOrderElectronicInvoiceUnOpenProducts (params) {
    return downloadFileByPost('/dis/invoice/invoiceElectronicInfoExport', params)
  },

  // 下载- 云采购发票-开票完成- 导出文件
  downloadPurchaseOrderInvoiceOpenedFile (params) {
    return downloadFileByGet('/dis/invoice/record/invoiceInfoExport', params)
  },

  // 下载- 云采购发票-开票完成- 导出电子发票表格
  downloadPurchaseOrderElectronicInvoiceOpenedFile (params) {
    return downloadFileByGet('/dis/invoice/record/invoiceElectronicInfosExport', params)
  },

  // 下载-云特价设置模板
  downSpecialPriceTemplate (params) {
    return downloadFileByGet('/platform/disCloudSku/specialPrice/templateDownload', params)
  },

  // 下载-外部批次产品
  downUnSyncSkusInOutside (params) {
    return downloadFileByGet('/commodity/sku/unSyncCommoditySku', params)
  },

  // 下载-保险流水单明细
  downInsuranceFlowDetail (params) {
    return downloadFileByGet('/insurance/insuranceFlowExport', params)
  },

  // 下载-德邦附加费用导入模板(德邦客户初步核算单)
  downOneFlowDetail (params) {
    return downloadFileByGet('/api/excel/template/download/logistics/deppon/append/fee', params)
  },

  // 下载- 财务管理-EU订单导出
  downloadEUTradeOrder (params) {
    return downloadFileByPost('/platform/finance/euOrderListByParamExport', params)
  },

  // 德邦物流模板下载(德邦客户初步核算单)
  downloadWi (params) {
    return downloadFileByGet('/api/excel/template/download/logistics/deppon/add/info', params)
  },

  // 物流-德邦导出明细(德邦客户初步核算单)
  downDeOneDetail (params) {
    return downloadFileByGet('/api/excel/data/download/logistics/deppon/platform/fee', params)
  },

  // 物流-德邦导出明细(德邦客户核算单)
  downDeCusDetail (params) {
    return downloadFileByGet('/api/excel/data/download/logistics/deppon/merchant/fee', params)
  },

  // 物流-德邦导出明细(德邦快递对账单)
  downDeDzDetail (params) {
    return downloadFileByGet('/api/excel/data/download/logistics/deppon/bill', params)
  },

  // 物流-德邦快递记录导出明细
  downDeppenExpressRecord (params) {
    return downloadFileByPost('/api/excel/data/download/logistics/flow', params)
  },

  // 云交易记录
  downDeppenRecord (params) {
    return downloadFileByGet('/oms/cloudSku/tradingInfoByMonthExport', params)
  },

  // 云交易记录（无成交利率）导出
  downCloudTransactionNotRateRecords (params) {
    return downloadFileByGet('/oms/cloudSku/tradingInfoForNoRateByMonthExport', params)
  },

  // 短信记录导出
  downsmsSendRecord (params) {
    return downloadFileByPost('/sms/smsSendRecord/export', params)
  },

  // 快递记录导出
  downloadExpressServiceRecords (params) {
    return downloadFileByPost('/api/warehouse/get/distributorPickingInfo/export', params)
  },

  // 商户列表-特价产品设置模板
  downloadEnquireProductSetTemplate (params) {
    return downloadFileByGet('/platform/merchant/bargainsProduct/downloadTemplate', params)
  },

  // 报价单- 报价单详情导出
  downloadQuotationDetails (params) {
    return downloadFileByPost('/api/excel/data/download/enquiry/quotationOrder/exportDetailData', params)
  },

  // 工单- 工单统计导出
  downloadWorkOrderStatistics (params) {
    return downloadFileByPost('/api/excel/data/download/enquiry/workOrder/data', params)
  },

  // 询价统计- 导出
  downloadEnquiryBoardStatistics (params) {
    return downloadFileByPost('/enquiry/order/manage/center/statistics/export', params)
  },

  // 报价统计- 导出
  downloadQuotationsBoardStatistics (params) {
    return downloadFileByPost('/api/platform/enquiry/quotationOrder/statistics/export', params)
  },

  // 未成单报价统计- 导出
  downloadUnplaceOrderQuoteBoardStatistics (params) {
    return downloadFileByPost('/api/platform/enquiry/quotationOrder/statistics/unOrderExport', params)
  },

  // 特价产品设置-导出结果
  downloadEnquireSpecialMatchProducts (params) {
    return downloadFileByGet('/api/product/info/management/bargainsProduct/import/result', params)
  },

  // 工单- 工单匹配结果导出
  downloadWorkOrderMatchResult (params) {
    return downloadFileByGet('/api/product/info/management/export', params)
  },

  // 工单- 处理工单 > 协作列表报价基础数据导出
  downloadHelpListQuotationBaseDetails (params) {
    return downloadFileByPost('/api/file/manage/download', params)
  },

  // 工单报价统计- 导出
  downloadWorkOrderQuoteBoardStatistics (params) {
    return downloadFileByPost('/api/platform/enquiry/workOrder/statisticsExport', params)
  },

  // 仓库- 采购入库单 > 下载入库单明细
  downloadWarehouseEntryBillDetails (params) {
    return downloadFileByGet('/oms/platform/cloud/purchase/order/download', params)
  },

  // 仓库- 销售出库单 > 下载出库单明细
  downloadWarehouseOutBillDetails (params) {
    return downloadFileByGet('/oms/platform/cloud/sale/order/download', params)
  },

  // 下载支付信息Word格式
  downloadPaymentInfoByWord (params) {
    return downloadFileByPost('/easy/word/example/api/paymentInfoByWordDownload', params)
  },

  // 下载支付信息PDF格式
  downloadPaymentInfoByPdf (params) {
    return downloadFileByPost('/easy/word/example/api/paymentInfoByPDFDownload', params)
  },

  // 下载系列导入结果
  downloadSeriesUploadMatchResultExcel (params) {
    return downloadFileByGet('/api/product/info/management/export', params)
  },

  // 域名列表导出7
  downloadDomainAlertSevenList (params) {
    return downloadFileByPost('/domain/excelExportListSeven', params)
  },

  // 域名列表导出15
  downloadDomainAlertFifteenList (params) {
    return downloadFileByPost('/domain/excelExportListFifteen', params)
  },

  // 询价单-预报价单- 下载预报价单EXCEL
  downloadPrepareQuotionsExcel (params) {
    return downloadFileByPost('/enquiry/predict/quotation/order/exportDetailData', params)
  },

  // 预报价统计- 导出
  downloadPrepareQuotedBoardStatistics (params) {
    return downloadFileByPost('/enquiry/predict/quotation/order/statisticsDataExport', params)
  },

  // 订单预警列表导出
  downloadOrderDeliveryWarningList (params) {
    return downloadFileByPost('/oms/warning/deliveryDays/orderDetailExport', params)
  },

  // 预警管理-相同询价预警- 导出EXCEL
  downloadALikeProductEnquiryWarningList (params) {
    return downloadFileByPost('/oms/warning/enquiryOrder/sameOrderGoodsDetailExport', params)
  },

  // 下载- 订单管理- 下载拣货单
  downloadSupplyOrderPickingOrder (params) {
    return downloadFileByGet('/platform/supplyOrder/pickingOrder/export', params)
  },

  // 下载-采购订单列表- 下载拣货单
  downloadPurchaseOrderPickingOrder (params) {
    return downloadFileByGet('/platform/purchaseOrder/pickingOrder/export', params)
  },

  // 下载-询价产品搜索导入- 下载导入匹配结果
  downloadUploadEnquirySearchProductsResult (params) {
    return downloadFileByGet('/api/excel/export/product/import/result', params)
  },

  // 型号信息列表-关联图片-任务进度- 失败结果导出
  downloadBindSkuTaskFailResult (params) {
    return downloadFileByPost('/platform/commoditySku/uploadImageTaskErrorSkuInfoExport', params)
  }
}
