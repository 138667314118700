import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import filter from '@/common/utils/filter'
import { getCourierName } from '@/common/utils/mUtils'
import Api from '@/common/api/index'

const { insuranceTypeMaps } = filter

export default class BilldetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '保险时间'
        },
        cptParams: {
          placeholder: '选择...'
        }
      }
    ],
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      width: 44,
      key: 'billDetail-0',
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '商户名称',
      key: 'billDetail-1',
      dataIndex: 'distributorName'
    },
    {
      title: '发货日期',
      key: 'billDetail-2',
      width: 100,
      render: (text, record, index) => (
        <>{ record.senderTime ? moment(record.senderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '运单号',
      key: 'billDetail-3',
      render: (text, record, index) => (
        <div>{ record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----' }</div>
      )
    },
    {
      title: '产品类型',
      key: 'billDetail-4',
      dataIndex: 'cargoName'
    },
    {
      title: '快递公司',
      key: 'billDetail-5',
      render: (text, record, index) => (
        <>{ getCourierName(record.courierCode) }</>
      )
    },
    {
      title: '保险时间',
      key: 'billDetail-6',
      width: 100,
      render: (text, record, index) => (
        <>{ record.insureTime ? moment(record.insureTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '服务类型',
      key: 'billDetail-7',
      render: (text, record, index) => (
        <div>{ this.renderInsuranceServerTypeLabel(record.type) }</div>
      )
    },
    {
      title: '费率(‰)',
      key: 'billDetail-8',
      render: (text, record, index) => (
        <div>{ (+record.rate * 1000 * 1000 / 1000).toFixed(2) }&#8240;</div>
      )
    }
  ]

  componentDidMount () {
    this.setInsuranceslipList()
  }

  // 获取保险账单明细
  setInsuranceslipList = () => {
    const { detailInfo = {} } = this.props
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      distributorId: detailInfo.distributorId,
      pageNo: 1,
      pageSize: 999
    }

    Api.setInsuranceslipList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    search['beginDate'] = search.times ? moment(search.times[0]).format('YYYY-MM-DD') : undefined
    search['endDate'] = search.times ? moment(search.times[1]).format('YYYY-MM-DD') : undefined

    delete search['times']

    this.setState({
      query: search
    }, () => {
      this.setInsuranceslipList()
    })
  }

  // 渲染保险服务类型名称
  renderInsuranceServerTypeLabel = (status) => {
    let label = ''
    let obj = insuranceTypeMaps.find((item) => { return item.id === status })

    if (obj) {
      label = obj.name
    }

    return label
  }

  render () {
    const { searchData, tableData = [] } = this.state

    return (
      <div>
        <Modal title='商户账单明细' width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'auto' }}
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
        >
          <Row style={{ marginBottom: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>
          <Row>
            <Table rowKey={(record, index) => `insurancebillDetail-${record.orderId}-${index}`}
              bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={false}
            />
          </Row>
        </Modal>
      </div>
    )
  }
}
