import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Radio, message, Upload, Button, Icon } from 'antd'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const { brandTypeMaps } = filter
const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

class BrandModal extends Component {
  static propTypes = {
    form: PropTypes.object,
    data: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    type: PropTypes.number
  }
  state = {

  }

  componentDidMount () {
    const { form, type = 1, data = {} } = this.props

    if (type === 2) {
      let brandLogo = ''
      let brandDesImages = []
      let brandPdfImages = []

      if (data.brandLogo && data.brandLogo !== '') {
        brandLogo = data.brandLogo
      }

      if (data.brandDesImages && data.brandDesImages.length > 0) {
        brandDesImages = $lodash.map(data.brandDesImages, 'imageUrl')
      }

      if (data.brandPdfImages && data.brandPdfImages.length > 0) {
        brandPdfImages = $lodash.map(data.brandPdfImages, 'imageUrl')
      }

      form.setFieldsValue({
        brandLogo: brandLogo !== '' ? brandLogo : undefined,
        brandDesImages: brandDesImages.length > 0 ? brandDesImages.join(',') : undefined,
        brandPdfImages: brandPdfImages.length > 0 ? brandPdfImages.join(',') : undefined
      })
    }
  }

  handleConfirm = () => {
    const { form, type, data = {}, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const { brandDesImages, brandPdfImages } = values

        if (+type === 2) {
          values.id = data.id
        }

        if (brandDesImages && brandDesImages !== '') {
          values.brandDesImages = $lodash.map(brandDesImages.split(','), (o) => {
            return {
              imageUrl: o
            }
          })
        }

        if (brandPdfImages && brandPdfImages !== '') {
          values.brandPdfImages = $lodash.map(brandPdfImages.split(','), (o) => {
            return {
              imageUrl: o
            }
          })
        }

        onConfirm(values)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 图片上传
  handleChangeUploadImage = (info, key) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        const { getFieldValue, setFieldsValue } = this.props.form

        let newdatas = getFieldValue(key) !== undefined ? getFieldValue(key).split(',') : []

        newdatas.push(data.imageUrl)

        setFieldsValue({ [key]: newdatas.join(',') })
      } else {
        message.error('上传失败')
      }
    }
  }

  // 前端删除品牌LOGO图片
  handleRemoveImage = (key, index) => {
    const { getFieldValue, setFieldsValue } = this.props.form
    let newdatas = getFieldValue(key).split(',')

    if (key === 'brandDesImages' || key === 'brandPdfImages') {
      newdatas.splice(index, 1)
    }

    setFieldsValue({ [key]: key === 'brandDesImages' || key === 'brandPdfImages' ? (newdatas.length > 0 ? newdatas.join(',') : undefined) : undefined })
  }

  render () {
    const { form, type = 1, data = {}, onCancel } = this.props
    const { getFieldDecorator, getFieldValue } = form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    }

    return (
      <>
        <Modal title={type === 1 ? '添加品牌' : '编辑品牌'} bodyStyle={{ height: '500px', overflowY: 'auto' }}
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => onCancel()}
        >
          <Form layout='horizontal'>
            <FormItem label='类型' {...formItemLayout}>
              {
                getFieldDecorator('type', {
                  initialValue: data.type || 'INLAND',
                  rules: [
                    { required: true, message: '请选择品牌类型' }
                  ]
                })(
                  <Radio.Group>
                    {
                      brandTypeMaps.map((item, i) => (
                        <Radio value={item.id} key={`brandType-${i}`}>{item.name}</Radio>
                      ))
                    }
                  </Radio.Group>
                )
              }
            </FormItem>
            <FormItem label='中文名称' {...formItemLayout}>
              {
                getFieldDecorator('name', {
                  initialValue: data.name || '',
                  rules: [
                    { required: true, message: '请输入品牌中文名称!' }
                  ]
                })(<Input placeholder='请输入...' autoComplete='off' />)
              }
            </FormItem>
            <FormItem label='英文名称' {...formItemLayout}>
              {
                getFieldDecorator('englishBrandName', {
                  initialValue: data.englishBrandName || '',
                  rules: [
                    { required: true, message: '请填写品牌英文名称' }
                  ]
                })(<Input placeholder='请输入...' autoComplete='off' />)
              }
            </FormItem>

            <FormItem label='描述' {...formItemLayout}>
              {
                getFieldDecorator('desc', {
                  initialValue: data.desc || ''
                })(<TextArea rows={4} placeholder='请输入...' autoComplete='off' />)
              }
            </FormItem>

            <FormItem label='品牌LOGO' {...formItemLayout} style={{ marginBottom: '0px' }}>
              <FormItem style={{ display: 'none' }}>
                {
                  getFieldDecorator('brandLogo', {

                  })(<Input autoComplete='off' disabled />)
                }
              </FormItem>

              {
                getFieldValue('brandLogo') !== undefined && getFieldValue('brandLogo') !== ''
                  ? <div className={`${styles.thumbnailLine}`}>
                    <UploadThumbnail
                      imageUrl={getFieldValue('brandLogo')}
                      onDelete={() => this.handleRemoveImage('brandLogo')}
                    />
                  </div>
                  : <div className={`${styles.thumbnailLine}`}>
                    <Upload
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUploadImage(info, 'brandLogo')}
                    >
                      <div>
                        <Icon type='plus' />
                        <div>点击上传</div>
                      </div>
                    </Upload>
                  </div>
              }
            </FormItem>

            <FormItem label='品牌介绍图片' {...formItemLayout} style={{ marginBottom: '0px' }}>
              <FormItem style={{ display: 'none' }}>
                {
                  getFieldDecorator('brandDesImages', {

                  })(<Input autoComplete='off' disabled />)
                }
              </FormItem>

              {
                getFieldValue('brandDesImages') !== undefined && getFieldValue('brandDesImages') !== ''
                  ? <>
                    {
                      getFieldValue('brandDesImages').split(',').map((item, index) => (
                        <div className={`${styles.thumbnailLine}`} key={`brandDetailCol-${index}`}>
                          <UploadThumbnail imageUrl={item} onDelete={() => this.handleRemoveImage('brandDesImages', index)} />
                        </div>
                      ))
                    }
                  </>
                  : null
              }

              <div className={`${styles.thumbnailLine}`}>
                <Upload
                  showUploadList={false}
                  listType='picture-card'
                  accept='image/png, image/jpg, image/jpeg'
                  headers={{ Token: getStore('token') }}
                  action={uploadOrdinaryImageUrl}
                  beforeUpload={this.beforeUpload}
                  onChange={(info) => this.handleChangeUploadImage(info, 'brandDesImages')}
                >
                  <div>
                    <Icon type='plus' />
                    <div>点击上传</div>
                  </div>
                </Upload>
              </div>
            </FormItem>

            <FormItem label='品牌介绍PDF' {...formItemLayout}>
              <FormItem style={{ display: 'none' }}>
                {
                  getFieldDecorator('brandPdfImages', {

                  })(<Input autoComplete='off' disabled />)
                }
              </FormItem>

              {
                getFieldValue('brandPdfImages') !== undefined && getFieldValue('brandPdfImages') !== ''
                  ? <ul className={`${styles.uploadedList}`}>
                    {
                      getFieldValue('brandPdfImages').split(',').map((item, n) => (
                        <li className={`${styles.uploadedpdfItem}`} style={{ lineHeight: '32px' }} key={`brandPDFRow-${n}`}>
                          <div className={`${styles.pdfContainer}`}>
                            <div className={`${styles.pdfIcon}`}>
                              <Icon type='file-pdf' />
                            </div>
                            <div className={`${styles.pdfLink}`}>
                              <a href={item} target='_blank' title={item} rel='noopener noreferrer'>{item}</a>
                            </div>
                            <div className={`${styles.pdfOperate}`}>
                              <Icon type='delete' onClick={() => this.handleRemoveImage('brandPdfImages', n)} />
                            </div>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  : <Upload
                    showUploadList={false}
                    accept='pdf'
                    headers={{ Token: getStore('token') }}
                    action={uploadOrdinaryImageUrl}
                    onChange={(info) => this.handleChangeUploadImage(info, 'brandPdfImages')}
                  >
                    <Button icon='upload'>点击上传</Button>
                  </Upload>
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const BrandModalForm = Form.create()(BrandModal)

export default BrandModalForm
