import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class PrepareQuotations extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'predictQuotationOrderId',
        formType: 'Input',
        itemParams: {
          label: '预报价单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '询价时间'
        },
        cptParams: {}
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '询价商户'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'predictQuotationStatus',
        formType: 'Select',
        itemParams: {
          label: '报价状态'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'PRE_SUCCESS', name: '已报价' },
          { id: 'PRE_FAILED', name: '报价中' }
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'preQuoteOrderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '预报价单编号',
      key: 'preQuoteOrderCol-1',
      dataIndex: 'predictQuotationOrderId'
    },
    {
      title: '询价时间',
      key: 'preQuoteOrderCol-2',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '询价商户',
      key: 'preQuoteOrderCol-3',
      dataIndex: 'distributorName'
    },
    {
      title: '报价状态',
      key: 'preQuoteOrderCol-4',
      dataIndex: 'predictQuotationStatus',
      render: (text, record, index) => (
        <>
          {
            record.predictQuotationStatus === 'PRE_SUCCESS'
              ? <span className='greenColor'>已报价</span> : <span className='redColor'>报价中</span>
          }
        </>
      )
    },
    {
      title: '查看预报价',
      key: 'preQuoteOrderCol-5',
      render: (text, record, index) => (
        <>
          { record.predictQuotationStatus === 'PRE_SUCCESS'
            ? <Button type='link' size='small' onClick={() => this.getPrepareQuotationOrderPdfUrl({ predictQuotationOrderId: record.predictQuotationOrderId })}>点击查看</Button>
            : null
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'preQuoteOrderCol-6',
      render: (text, record, index) => (
        <>
          {
            record.predictQuotationStatus === 'PRE_SUCCESS'
              ? <>
                <Button type='link' size='small' onClick={() => this.handleLinkToDetail(record)}>查看报价</Button>
                <Button type='link' size='small' onClick={() => this.handleDownloadQuoteExcel(record)}>下载报价</Button>
              </>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getPrepareQuotationsOrderList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取预报价列表
  getPrepareQuotationsOrderList = async () => {
    const { query = {}, pages } = this.state
    const res = await Api.getPrepareQuotationsOrderList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 获取预报加单PDF路径
  getPrepareQuotationOrderPdfUrl = async (params) => {
    const res = await Api.getPrepareQuotationOrderPdfUrl(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data !== '') {
        window.open(data, '_blank')
      } else {
        message.warning('暂无数据')
      }
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times && search.times.length === 2) {
      search.beginDate = moment(search.times[0]).format('YYYY-MM-DD')
      search.endDate = moment(search.times[1]).format('YYYY-MM-DD')

      delete search.times
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getPrepareQuotationsOrderList()
    })
  }

  // 点击查看报价
  handleLinkToDetail = (record) => {
    this.props.history.push({ pathname: '/admin/prepareQuotedDetails', state: { ...record } })
  }

  // 点击下载报价
  handleDownloadQuoteExcel = (record) => {
    DownloadApi.downloadPrepareQuotionsExcel({ predictQuotationOrderId: record.predictQuotationOrderId })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPrepareQuotationsOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPrepareQuotationsOrderList()
    })
  }

  render () {
    const { searchData, tableData, pages } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)} />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='predictQuotationOrderId' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default PrepareQuotations
