import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Tabs } from 'antd'

import OrderProdctDetails from '../orderProductDetails/index' // 订单产品明细
import OrderSendOutDetails from '../orderSendOutDetails/index' // 发货批次详情
import OrderLogisticsTrail from '../orderLogisticsTrail/index' // 物流轨迹详情

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'DETAILS', name: '订单明细' },
  { id: 'SEND', name: '发货批次' },
  { id: 'LOGISTICS', name: '物流轨迹' }
]

class OrderDetailsDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    ident: PropTypes.string, // SUPPLY_ORDER-供应订单 PURCHASE_ORDER-采购订单
    detailInfo: PropTypes.object,
    getProductDetail: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    activeKey: 'DETAILS'
  }

  handleChangeTabPane = (value) => {
    this.setState({
      activeKey: value
    })
  }

  render () {
    const { visible = false, ident, detailInfo = {}, getProductDetail, onCancel } = this.props
    const { activeKey = 'DETAILS' } = this.state

    return (
      <>
        <Drawer title='订单明细' width={1300} visible={visible} bodyStyle={{ padding: '0px 16px 16px 16px' }}
          destroyOnClose
          onClose={() => onCancel()}
        >
          <div className={`${styles.tabsLand}`}>
            <Tabs onChange={(value) => this.handleChangeTabPane(value)}>
              {
                tabPaneMaps.map((item) => (
                  <TabPane tab={item.name} key={item.id} />
                ))
              }
            </Tabs>
          </div>

          {
            activeKey === 'DETAILS'
              ? <OrderProdctDetails
                ident={ident}
                detailInfo={detailInfo}
                getProductDetail={(params) => getProductDetail(params)}
              />
              : null
          }

          {
            activeKey === 'SEND'
              ? <OrderSendOutDetails
                ident={ident}
                detailInfo={detailInfo}
                getDetails={(params) => getProductDetail(params)}
              />
              : null
          }

          {
            activeKey === 'LOGISTICS'
              ? <OrderLogisticsTrail
                ident={ident}
                detailInfo={detailInfo}
                getDetails={(params) => getProductDetail(params)}
              />
              : null
          }
        </Drawer>
      </>
    )
  }
}

export default OrderDetailsDrawer
