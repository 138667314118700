import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Table, Divider, message, Modal, Badge } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import RefuseapplyModal from '../component/refuseapplyModal'
import AgencyModal from './agencyModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderCloudSupplyExamineStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, cloudSupplyExamineMaps, agencyStatusMaps } = filter
const { confirm } = Modal

class cloudExamine extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商家名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '代理品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'auditStatus',
        formType: 'Select',
        itemParams: {
          label: '审核状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudSupplyExamineMaps
        ]
      },
      {
        name: 'agencyDate',
        formType: 'RangePicker',
        itemParams: {
          label: '代理授权时间'
        },
        cptParams: {
          placeholder: '请选择...'
        }
      },
      {
        name: 'agencyStatus',
        formType: 'Select',
        itemParams: {
          label: '代理逾期状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...agencyStatusMaps
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {
          placeholder: '请选择...'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    info: {},
    query: {},
    refuseModal: false,
    agencyModal: false,
    agencymodelType: 1, // 1-代理证查看 2-代理证设置
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'examine-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'examine-1',
      dataIndex: 'brandName'
    },
    {
      title: '供应商',
      key: 'examine-2',
      dataIndex: 'distributorName'
    },
    {
      title: '代理授权时间',
      key: 'examine-3',
      render: (text, record, index) => (
        <div>
          {
            record.agencyStartTime && record.agencyEndTime
              ? <div>{`${moment(record.agencyStartTime).format('YYYY-MM-DD')} 至 ${moment(record.agencyEndTime).format('YYYY-MM-DD')}`}</div>
              : <div>----</div>
          }
        </div>
      )
    },
    {
      title: '代理逾期状态',
      key: 'examine-4',
      render: (text, record, index) => (
        <>{ this.renderAgencyStatusLabel(record.agencyStatus) }</>
      )
    },
    {
      title: '审核状态',
      key: 'examine-5',
      render: (text, record, index) => (
        <>{ renderCloudSupplyExamineStatusLabel(record.auditStatus) }</>
      )
    },
    {
      title: '申请时间',
      key: 'examine-8',
      render: (text, record, index) => (
        <div>
          {
            record.applyTime
              ? moment(record.applyTime).format('YYYY-MM-DD HH:mm:ss') : '----'
          }
        </div>
      )
    },
    {
      title: '审核时间',
      key: 'examine-9',
      render: (text, record, index) => (
        <div>
          {
            record.auditTime
              ? moment(record.auditTime).format('YYYY-MM-DD HH:mm:ss') : '----'
          }
        </div>
      )
    },
    {
      title: '备注',
      key: 'examine-6',
      dataIndex: 'remarks'
    },
    {
      title: '操作',
      key: 'examine-7',
      render: (text, record, index) => {
        return (
          <div>
            {
              record.auditStatus === 'AUDIT_ACCEPTED'
                ? <>
                  <a onClick={() => this.handlePassApply(record)}>通过</a>
                  <Divider type='vertical' />
                  <a onClick={() => this.showRefuseApplyModal(record)}>拒绝</a>
                </>
                : null
            }

            {
              record.auditStatus === 'AUDIT_ACCEPTED' || record.auditStatus === 'AUDIT_FAIL'
                ? <a style={{ marginLeft: '8px' }} onClick={() => this.showAgencyModal(record, 1)}>代理证查看</a>
                : null
            }

            {
              record.auditStatus === 'AUDIT_SUCCESS' && record.agencyStatus === 'UN_OT'
                ? <a style={{ marginLeft: '8px' }} onClick={() => this.showAgencyModal(record, 2)}>代理日期设置</a>
                : null
            }
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getCloudAuditList()
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取云供应审核列表
  getCloudAuditList = () => {
    const { pages, query } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query
    }

    Api.getCloudAuditList(params).then(res => {
      const { code, data } = res
      const { pages } = this.state
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 审核云供应
  saveMerchantSkuAudit = (params) => {
    Api.saveMerchantSkuAudit(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getCloudAuditList()
      }
    })
  }

  // 设置代理状态
  setAgencyInCloudSupply = (params) => {
    Api.setAgencyInCloudSupply(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getCloudAuditList()
      }
    })
  }

  // 点击拒绝
  showRefuseApplyModal = (record) => {
    this.setState({
      refuseModal: true,
      rowDetail: record
    })
  }

  // 点击通过
  handlePassApply = (record) => {
    confirm({
      title: '确定通过该申请？',
      content: '',
      onOk: () => {
        this.saveMerchantSkuAudit({ id: record.id, auditStatus: 'AUDIT_SUCCESS' })
      }
    })
  }

  // 渲染代理证逾期状态
  renderAgencyStatusLabel = (status) => {
    const obj = agencyStatusMaps.find((o) => { return o.id === status })
    const el = obj !== undefined ? <Badge status={obj.status} text={obj.name} /> : null

    return el
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages } = this.state

    if (search.agencyDate && search.agencyDate.length === 2) {
      search['startTime'] = moment(search.agencyDate[0]).format('YYYY-MM-DD')
      search['endTime'] = moment(search.agencyDate[1]).format('YYYY-MM-DD')
    }

    if (search.times) {
      search['applyBeginTimeStr'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['applyEndTimeStr'] = moment(search.times[1]).format('YYYY-MM-DD')
      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => this.getCloudAuditList())
  }

  // 点击代理证查看&代理证设置
  showAgencyModal = (record, agencymodelType) => {
    this.setState({
      agencyModal: true,
      agencymodelType: agencymodelType,
      rowDetail: record
    })
  }

  closeModal = () => {
    this.setState({
      refuseModal: false,
      agencyModal: false,
      agencymodelType: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudAuditList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudAuditList()
    })
  }

  render () {
    const { tableData = [], pages, searchData, refuseModal = false, agencyModal = false, agencymodelType = 1, rowDetail = {} } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>

        {
          refuseModal
            ? <RefuseapplyModal
              status='auditStatus'
              detailInfo={rowDetail}
              onConfirm={(params) => this.saveMerchantSkuAudit(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          agencyModal
            ? <AgencyModal
              type={agencymodelType}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.setAgencyInCloudSupply(params)}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(cloudExamine)
