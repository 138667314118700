import React, { Component } from 'react'
import { Card, Row, Tabs } from 'antd'

import UninvoicedList from './uninvoiced-list'
import PurInvRecord from './invoice-record'

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'OPEN', name: '待开票' },
  { id: 'OPEND', name: '开票完成' }
]

class FinancepurchInvoice extends Component {
  static propTypes = {

  }
  state = {
    activeKey: 'OPEN'
  }

  componentDidMount () { }

  // 点击选择tab类型
  handleChangeTabPane = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'OPEN' } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <Row className={`${styles.pageTabLand}`}>
              <Tabs onChange={this.handleChangeTabPane}>
                {
                  tabPaneMaps.map(item => (
                    <TabPane tab={item.name} key={item.id} />
                  ))
                }
              </Tabs>
            </Row>
          </Card>
        </div>

        {
          activeKey === 'OPEN'
            ? <UninvoicedList />
            : null
        }

        {
          activeKey === 'OPEND'
            ? <PurInvRecord />
            : null
        }
      </>
    )
  }
}

export default FinancepurchInvoice
