import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Row, Button, Switch, message, InputNumber, Popconfirm } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import SAASCourierJson from '@/assets/json/saasCourier.json'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class Sfervice extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    history: PropTypes.object,
    buttonMaps: PropTypes.object,
    activeKey: PropTypes.string
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '公司名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '启用状态'
        },
        cptParams: {
          placeholder: '请选择启用状态...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLE', name: '启用' },
          { id: 'DISABLE', name: '禁用' }
        ]
      }
    ],
    tableData: [],
    editingKey: '', // 快递费率编辑中
    editingKey1: '', // 服务费率编辑中
    rowDetail: {}, // 当前选择的行数据
    codeType: ''

  }

  columns = [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户编号',
      key: 'service-1',
      dataIndex: 'distributorId'
    },
    {
      title: '公司名称',
      key: 'service-2',
      dataIndex: 'distributorName'
    },
    {
      title: () => { return this.renderColumnTitle() },
      key: 'service-3',
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            {
              PATH_ENV === 'prod' && (+record.distributorId === 100005 || +record.distributorId === 100006)
                ? <div>IIASaaS禁用快递</div>
                : <div>
                  {
                    buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1
                      ? <Switch
                        checked={record.status === 'ENABLE'}
                        checkedChildren='启用'
                        unCheckedChildren='禁用'
                        onChange={(checked) => this.handleChangeSwitch(checked, record)}
                      />
                      : <div>
                        {
                          record.status === 'ENABLE' ? '启用' : '禁用'
                        }
                      </div>
                  }
                </div>
            }
          </div>

        )
      }
    },
    {
      title: '快递费率',
      key: 'service-4',
      render: (text, record, index) => (
        <div>
          {this.renderServiceRateCell(record, index)}
        </div>
      )
    },
    {
      title: '物流费率',
      key: 'service-6',
      render: (text, record, index) => (
        <div>
          {this.renderServiceRateCell1(record, index)}
        </div>
      )
    },
    {
      title: '操作',
      key: 'service-5',
      width: 360,
      render: (text, record, index) => {
        const { buttonMaps } = this.props
        const { editingKey } = this.state
        const editable = this.isEditing(record)
        const editable1 = this.isEditing1(record)
        return editable || editable1 ? (
          <div>
            <Button type='primary' size='small' onClick={() => this.handleSave(record, editable)} style={{ marginRight: '8px' }}>保存</Button>
            <Popconfirm title='确定放弃编辑?' onConfirm={() => this.handleCancel(index)}>
              <Button type='primary' size='small'>取消</Button>
            </Popconfirm>
          </div>
        ) : (<div>
          {
            buttonMaps && buttonMaps['edit'] && +buttonMaps['edit'].auth === 1
              ? <div>
                <Button type='link' size='small' disabled={editingKey !== ''}
                  onClick={() => this.handleEditServiceRate(record.id, 1)}>编辑快递费率</Button>
                <Button type='link' size='small' disabled={editingKey !== ''}
                  onClick={() => this.handleEditServiceRate(record.id, 2)}>编辑物流费率</Button>
              </div>
              : null
          }
        </div>
        )
      }
    }
  ]

  componentDidMount () {
    const { activeKey } = this.props

    this.setState({
      codeType: activeKey
    }, () => this.getCustomerServiceList())
  }

  // 渲染快递名称
  renderColumnTitle = () => {
    const { activeKey } = this.props
    const obj = SAASCourierJson.find((item, i) => { return item.id === activeKey })
    const label = obj !== undefined ? obj.courierName : 'IIASaaS服务'

    return label
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取客户服务配置
  getCustomerServiceList = () => {
    const { pages, query, codeType } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      courierCode: codeType
    }
    Api.getCustomerServiceList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = data.list || []

        if (_list.length > 0) {
          _list.map((item, i) => {
            item.copycourierDiscount = item.courierDiscount || 1
            item.copylogisticsDiscount = item.logisticsDiscount || 1
          })
        }

        this.setState({
          tableData: _list,
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // IIASaas服务启用/禁用
  updateServiceStatus = (params) => {
    Api.updateServiceStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getCustomerServiceList()
      }
    })
  }

  // 保存快递费率
  saveSeriveRate = (params) => {
    Api.saveSeriveRate(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.setState({
          editingKey: ''
        }, () => {
          this.getCustomerServiceList()
        })
      }
    })
  }

  // 保存服务费率
  saveSeriveLogistics = (params) => {
    Api.saveSeriveLogistics(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.setState({
          editingKey1: ''
        }, () => {
          this.getCustomerServiceList()
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getCustomerServiceList()
    })
  }

  // 切换启用禁用状态
  handleChangeSwitch = (checked, record) => {
    const { codeType } = this.state
    let params = {
      status: checked ? 'ENABLE' : 'DISABLE',
      distributorId: record.distributorId,
      courierCode: codeType
    }

    this.updateServiceStatus(params)
  }

  // 点击编辑快递费率
  handleEditServiceRate = (key, a) => {
    if (+a === 1) {
      this.setState({
        editingKey: key
      })
    } else {
      this.setState({
        editingKey1: key
      })
    }
  }

  // 取消编辑
  handleCancel = (i) => {
    let { tableData } = this.state
    tableData[i].courierDiscount = tableData[i].copycourierDiscount
    tableData[i].logisticsDiscount = tableData[i].copylogisticsDiscount
    this.setState({
      tableData,
      editingKey: '',
      editingKey1: ''
    })
  }

  // 点击保存快递编辑费率
  handleSave = (record, faly) => {
    const { codeType } = this.state
    let params = {
      distributorId: record.distributorId,
      courierDiscount: record.courierDiscount,
      courierCode: codeType
    }
    let params2 = {
      distributorId: record.distributorId,
      logisticsDiscount: record.logisticsDiscount,
      courierCode: codeType
    }
    faly ? this.saveSeriveRate(params) : this.saveSeriveLogistics(params2)
  }

  // 监听快递/服务费率的改变
  onChangeServiceRate = (val, i, flay) => {
    let { tableData } = this.state
    flay === 1 ? tableData[i].courierDiscount = val / 100 : tableData[i].logisticsDiscount = val / 100
    this.setState({
      tableData
    })
  }

  // 渲染快递费率单元格
  renderServiceRateCell = (record, index) => {
    const editable = this.isEditing(record)
    return editable ? (
      <Row>
        <InputNumber
          min={0}
          max={100}
          value={(+record.courierDiscount * 100).toFixed(2)}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
          onChange={(value) => this.onChangeServiceRate(value, index, 1)}
        />
      </Row>
    ) : (<Row>{((+record.courierDiscount) * 100).toFixed(2)}%</Row>)
  }

  // 渲染服务费率单元格
  renderServiceRateCell1 = (record, index) => {
    const editable1 = this.isEditing1(record)
    return editable1 ? (
      <Row>
        <InputNumber
          min={0}
          max={100}
          value={(+record.logisticsDiscount * 100).toFixed(2)}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
          onChange={(value) => this.onChangeServiceRate(value, index, 2)}
        />
      </Row>
    ) : (<Row>{((+record.logisticsDiscount) * 100).toFixed(2)}%</Row>)
  }

  // 点击快递费率编辑中
  isEditing = (record) => record.id === this.state.editingKey

  // 点击服务费率编辑中
  isEditing1 = (record) => record.id === this.state.editingKey1

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCustomerServiceList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCustomerServiceList()
    })
  }

  render () {
    const { tableData = [], pages, searchData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <Table rowKey='id' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
              />
            </Row>
          </Card>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(Sfervice)
