import Ajax from '../utils/ajax'

export default {
  // 获取合同列表
  getContractList (params) {
    return Ajax.post('/platform/contract/listPage', params)
  },

  // 合同模板 - 获取合同模板列表
  getContractTemplList (params) {
    return Ajax.post('/platform/agreement/template/listPage', params)
  },

  // 合同模板 - 新增合同模板
  saveAddContractTemp (params) {
    return Ajax.post('/platform/agreement/template/add', params)
  },

  // 合同模板 - 编辑合同模板
  saveEditContractTemp (params) {
    return Ajax.post('/platform/agreement/template/edit', params)
  },

  // 合同模板 - 更新合同模板状态
  updateContractTempStatus (params) {
    return Ajax.post('/platform/agreement/template/disableStatusChange', params)
  },

  // 合同模板 - 删除合同模板
  deleteContractTemp (params) {
    return Ajax.post('/platform/agreement/template/delete', params)
  },

  // 订单管理- 重新生成合同
  createSupplyOrderContractAgain (params) {
    return Ajax.get('/platform/supplyOrder/regenerateContract', { params })
  },

  // 订单管理- 获取合同链接
  getSupplyOrderContractDownloadLink (params) {
    return Ajax.get('/platform/supplyOrder/generateContract', { params })
  },

  // 采购订单列表- 重新生成合同
  createPurchaseOrderContractAgain (params) {
    return Ajax.post('/platform/contract/purchase/regeneratePurchaseOrderContract', params)
  },

  // 云采购订单- 获取云采购订单附件列表
  getPurchaseContractEnclosureList (params) {
    return Ajax.get('/platformAndPurchaseOrder/order/file/findContractByUserAndType', { params })
  },

  // 云采购订单- 云采购订单附件删除
  saveDeletePurchaseContractEnclosure (params) {
    return Ajax.get('/platformAndPurchaseOrder/file/delete', { params })
  }
}
