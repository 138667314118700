import React, { Component } from 'react'
import styles from './child.module.scss'
import PropTypes from 'prop-types'

export default class payinfo extends Component {
      static propTypes = {
        bankInfo: PropTypes.object,
        detailInfo: PropTypes.object
      }

      render () {
        return (
          <div className={`${styles.tableView}`}>
            <table style={{ width: '100%' }}>
              <thead />
              <tbody>
                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>收款方</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>{this.props.bankInfo.accountUser}</div>
                  </td>
                </tr>

                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>收款账号</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    {
                      this.props.bankInfo.account
                        ? <div>
                          {this.props.bankInfo.account.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')}
                        </div>
                        : null
                    }

                  </td>
                </tr>

                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>银行</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>{this.props.bankInfo.accountBank}</div>
                  </td>
                </tr>

                <tr className={`${styles.tableRow}`}>
                  <td className={`${styles.rowLabel}`}>
                    <div>订单编号</div>
                  </td>
                  <td className={`${styles.rowCon}`}>
                    <div>{this.props.detailInfo.purchaseOrderId}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      }
}
