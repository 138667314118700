import React, { Component } from 'react'
import { Card, Button, Table, Modal, Badge } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import ProgressModal from './progressModal/index'
import SmsInfomodal from './smsInfo-modal' // 发送内容

import Api from '@/common/api/index'
import downloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderSMSTypeLabel } from '@/common/utils/mUtils'

const { downsmsSendRecord } = downloadApi
const { pageSizeOptions, SMSTypeMaps, SMSSendTypeMaps, SMSSendByPlatformMaps } = filter

export default class SMSrecord extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  state={
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    detail: {},
    query: {}, // 检索参数
    smsInfomodal: false,
    searchData: [
      {
        name: 'telephone',
        formType: 'Input',
        itemParams: {
          label: '手机号',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'sender',
        formType: 'Input',
        itemParams: {
          label: '发送人',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'snedTime',
        formType: 'RangePicker',
        itemParams: {
          label: '发送时间'
        },
        cptParams: {

        }
      },
      {
        name: 'receiver',
        formType: 'Input',
        itemParams: {
          label: '接收人',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          placeholder: '请选择商户名称...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [
          { id: '', distributorName: '全部' }
        ],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'receiveTime',
        formType: 'RangePicker',
        itemParams: {
          label: '接收时间'
        },
        cptParams: {

        }
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '模板类型'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...SMSTypeMaps
        ]
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '发送状态'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...SMSSendTypeMaps
        ]
      },
      {
        name: 'defaultStatus',
        formType: 'Select',
        itemParams: {
          label: '是否为平台发送'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...SMSSendByPlatformMaps
        ]
      }
    ]
  }

  columns = [
    {
      title: '序号',
      key: 'specialFlow-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '手机号码',
      key: 'specialFlow-1',
      width: 150,
      dataIndex: 'telephone'
    },

    {
      title: '发送人',
      key: 'specialFlow-2',
      width: 150,
      dataIndex: 'sender'
    },
    {
      title: '发送时间',
      width: 150,
      key: 'specialFlow-3',
      render: (text, record, index) => (
        <>{ record.sendTime ? moment(record.sendTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '商户编号',
      key: 'specialFlow-4',
      width: 150,
      dataIndex: 'distributorId'
    },
    {
      title: '商户名称',
      key: 'specialFlow-5',
      dataIndex: 'distributorName',
      width: 150
    },
    {
      title: '接收人',
      key: 'specialFlow-6',
      dataIndex: 'receiver',
      width: 150
    },
    {
      title: '接收时间',
      key: 'specialFlow-7',
      width: 150,
      render: (text, record, index) => (
        <>
          { record.receiveTime ? moment(record.receiveTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </>
      )
    },
    {
      title: '模板类型',
      key: 'specialFlow-8',
      dataIndex: 'name',
      width: 150,
      render: (text, record, index) => (
        <>{ renderSMSTypeLabel(record.type) }</>
      )
    },
    {
      title: '模板名称',
      key: 'specialFlow-9',
      dataIndex: 'name',
      width: 150

    },
    {
      title: '模板CODE',
      key: 'specialFlow-10',
      dataIndex: 'code',
      width: 150
    },
    {
      title: '发送内容',
      key: 'specialFlow-11',
      dataIndex: 'name',
      width: 70,
      render: (text, record, index) => {
        return (
          <a onClick={() => { this.handleSmsinfo(record) }}>点击查看</a>
        )
      }

    },
    {
      title: '发送状态',
      key: 'specialFlow-12',
      dataIndex: 'status',
      width: 150,
      render: (text, record, index) => (
        <>{ this.renderSMSSendTypeLabel(record.status) }</>
      )
    },
    {
      title: '失败原因',
      key: 'specialFlow-13',
      dataIndex: 'name',
      width: 150,
      render: (text, record, index) => {
        return (
          <div>{record.reason}</div>
        )
      }

    }
  ]
  componentDidMount () {
    this.getsmsSendRecordlist()
  }

  // 获取处理特价记录
  getsmsSendRecordlist = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getsmsSendRecordlist(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  getSynSmsSendRecordStatus = async (params) => {
    const res = await Api.getSynSmsSendRecordStatus(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        progressModal: true
      })
    }
  }

  // 内容查看
  handleSmsinfo =(record) => {
    this.setState({
      smsInfomodal: true,
      detail: record
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state
        searchData[4].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)
        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.snedTime) {
      let _times = search.snedTime
      search['sendStartDate'] = _times[0].format('YYYY-MM-DD')
      search['sendEndDate'] = _times[1].format('YYYY-MM-DD')
      delete search.snedTime
    }

    if (search.receiveTime) {
      let _times = search.receiveTime
      search['receiveStartDate'] = _times[0].format('YYYY-MM-DD')
      search['receiveEndDate'] = _times[1].format('YYYY-MM-DD')
      delete search.receiveTime
    }
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getsmsSendRecordlist()
    })
  }

  // 导出记录
  handledown = () => {
    const { query, pages } = this.state
    if (query.sendStartDate) {
      let param = {
        pageNo: pages.pageNo,
        pageSize: pages.pageSize,
        sendStartDate: query.sendStartDate,
        sendEndDate: query.sendEndDate
      }
      downsmsSendRecord(param)
    } else {
      Modal.error({
        title: '请选择您想导出的时间段',
        content: '（请在搜索栏中选择发送时间，点击搜索，再点击导出记录）'
      })
    }
  }

  // 渲染短信发送状态
  renderSMSSendTypeLabel = (status) => {
    const obj = SMSSendTypeMaps.find(item => { return item.id === status })
    const el = obj !== undefined ? <Badge text={obj.name} color={obj.color} /> : null

    return el
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getsmsSendRecordlist()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getsmsSendRecordlist()
    })
  }

  onCancel = () => {
    this.setState({
      smsInfomodal: false,
      progressModal: false,
      detail: {}
    })
  }

  render () {
    const { searchData, pages, tableData = [], smsInfomodal = false, progressModal = false, detail = {} } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <div style={{ marginBottom: '15px' }}>
            <Button type='primary' onClick={() => { this.handledown() }}>导出记录</Button>
            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.getSynSmsSendRecordStatus({})}>查询进度</Button>
          </div>
          <Table rowKey='id' bodyStyle={{ overflow: 'scroll' }}
            bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}

          />
        </Card>
        {
          smsInfomodal
            ? <SmsInfomodal
              detail={detail}
              onCancel={() => { this.onCancel() }}
            />
            : null
        }

        {
          progressModal
            ? <ProgressModal
              getDetail={(params) => this.getSynSmsSendRecordStatus(params)}
              onCancel={() => this.onCancel()}
            />
            : null
        }
      </>
    )
  }
}
