import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Drawer, Row, Col, Table, Modal, Button, Badge, Icon, Upload, message, Tooltip } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import RemarksForm from '../../components/formRemarks/index'
import ProductModal from '../productModal/index' // 修正产品弹窗
import CoordinationModal from '../coordinationModal/index' // 协作报价弹窗
import AssginPriceContents from '../assginPriceContents/index' // 标定售价内容
import AutoQuotationProgressModal from '../autoQuotationProgressModal/index' // 任务进度弹窗
import UploadSheetModal from '@/components/uploadSheetModal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import UploadApi from '@/common/api/uploadApi'
import { getStore, renderCollaborateStateLabel, renderMatchingLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const { confirm, warning, success } = Modal
const { uploadMatchExcelUrl } = UploadApi

class EnquireProductForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object,
    orderId: PropTypes.number,
    enquiryOrderId: PropTypes.string,
    list: PropTypes.array,
    onChangeCollaborateState: PropTypes.func,
    onRef: PropTypes.func
  }
  state = {
    selectedRows: [], // 选中的数据
    productModal: false,
    coordinationType: 'HELP_QUOTATION', // 协作报价弹窗类型 BATCH_GENERATE-批量 HELP_QUOTATION-单个 HELP_LIST_QUOTATION-发布协作列表 AUTO_QUOTATION-自动报价
    coordinationModal: false, // 协作报价弹窗
    coordinationDetails: [], // 协作报价内容
    assginPriceModal: false, // 标定售价弹窗
    autoQuotationProgressModal: false, // 任务进度弹窗
    uploadSheetModal: false,
    uploadSheetDetails: {},
    rowDetail: {}
  }
  columns = [
    {
      title: '行号',
      key: 'enquireHandleCol-0',
      width: 44,
      align: 'center',
      dataIndex: 'rownum'
    },
    {
      title: '品牌',
      key: 'enquireHandleCol-1',
      width: 100,
      ellipsis: true,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'enquireHandleCol-2',
      width: 150,
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}>
            <span>{ record.model }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'enquireHandleCol-3',
      width: 150,
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}>
            <span>{ record.itemNumber }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'enquireHandleCol-4',
      width: 150,
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>
            <span>{ record.name }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '单位',
      key: 'enquireHandleCol-5',
      width: 60,
      dataIndex: 'unit'
    },
    {
      title: '期望价格',
      key: 'enquireHandleCol-6',
      width: 150,
      dataIndex: 'expectPrice',
      render: (text, record, index) => (
        <>&yen;{+record.expectPrice}</>
      )
    },
    {
      title: '标定售价',
      key: 'enquireHandleCol-7',
      dataIndex: 'assignPrice',
      width: 150,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' disabled={record.collaborateState === 'STOP_QUOTATION'} onClick={() => this.handleShowAssginPriceModal(record)}>
            &yen;{+record.assignPrice}
          </Button>
        </>
      )
    },
    {
      title: '最近成交价格',
      key: 'enquireHandleCol-8',
      dataIndex: 'recentTransactionPrice',
      render: (text, record, index) => (
        <>{ record.recentTransactionPrice ? <>&yen;{ +record.recentTransactionPrice }</> : <>----</> }</>
      )
    },
    {
      title: '数量',
      key: 'enquireHandleCol-9',
      width: 150,
      dataIndex: 'quantity'
    },
    {
      title: '期望货期',
      key: 'enquireHandleCol-10',
      width: 80,
      render: (text, record, index) => (
        <>{+record.expectDeliveryPeriodDays === 0 ? '现货' : `${+record.expectDeliveryPeriodDays}工作日`}</>
      )
    },
    {
      title: '是否承担运费',
      key: 'enquireHandleCol-11',
      width: 100,
      render: (text, record, index) => (
        <>{ record.isUndertakeFreight === 'UNDERTAKE' ? '是' : '否' }</>
      )
    },
    {
      title: '运费',
      key: 'enquireHandleCol-12',
      width: 150,
      render: (text, record, index) => (
        <>&yen;{(+record.freightPrice * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '报价有效期',
      key: 'enquireHandleCol-13',
      width: 100,
      render: (text, record, index) => (
        <>{ record.expiryDateTime ? moment(record.expiryDateTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '数据类型',
      key: 'enquireHandleCol-14',
      width: 100,
      dataIndex: '',
      render: (text, record, index) => (
        <>{ renderMatchingLabel(record.matchingState, record.dataType) }</>
      )
    },
    {
      title: '协作状态',
      key: 'enquireHandleCol-15',
      width: 80,
      render: (text, record, index) => (
        <>{ renderCollaborateStateLabel(record.collaborateState) }</>
      )
    },
    {
      title: '操作',
      key: 'enquireHandleCol-16',
      width: 240,
      fixed: 'right',
      render: (text, record, index) => (
        <>
          {
            record.collaborateState === 'STOP_QUOTATION'
              ? <Button type='link' size='small' onClick={() => this.handleChangeCollaborateState(record, 'NO_QUOTATION')}>恢复报价</Button>
              : <>
                <Button type='link' size='small'
                  disabled={record.collaborateState === 'STOP_QUOTATION'}
                  onClick={() => this.handleShowProductModal(record)}
                >
                  修正产品
                </Button>

                <Button type='link' size='small'
                  onClick={() => this.handleChangeCollaborateState(record, 'STOP_QUOTATION')}
                >停止报价</Button>

                {
                  record.matchingState === 'MATCHED'
                    ? <Button type='link' size='small' disabled={record.collaborateState === 'STOP_QUOTATION'}
                      onClick={() => this.handleCoordination([record], 'HELP_QUOTATION')}>协作报价</Button>
                    : null
                }
              </>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.props.onRef(this)
  }

  // 发布生成工单
  saveCreateWorkOrder = async (params) => {
    const { orderId } = this.props

    const res = await Api.saveCreateWorkOrder({ orderId: orderId, ...params })
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.setState({
        coordinationDetails: [],
        coordinationModal: false,
        coordinationType: 'HELP_QUOTATION'
      })
    }
  }

  // 批量发布协作报价
  saveBatchCreateWorkOrders = async (params) => {
    const { orderId } = this.props
    const res = await Api.saveBatchCreateWorkOrders({ orderId: orderId, ...params })
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.setState({
        coordinationDetails: [],
        coordinationModal: false,
        coordinationType: 'HELP_QUOTATION'
      })
    }
  }

  // 发布协作列表
  saveTeamWorkOrders = async (params) => {
    const { orderId } = this.props
    const res = await Api.saveTeamWorkOrders({ orderId: orderId, ...params })
    const { code } = res

    if (+code === 10000) {
      message.success('操作成功')

      this.setState({
        coordinationDetails: [],
        coordinationModal: false,
        coordinationType: 'HELP_QUOTATION'
      })
    }
  }

  // 自动报价生成工单
  saveAutoIssueQuotation = async (params) => {
    const res = await Api.saveAutoIssueQuotation(params)
    const { code } = res
    if (+code === 10000) {
      success({
        title: '报价成功',
        content: '请到任务进度里查看具体进度',
        onOk: () => {
          this.setState({
            coordinationDetails: [],
            coordinationModal: false,
            coordinationType: 'HELP_QUOTATION'
          })
        }
      })
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.closeModal()
      this.changeUploadSuccess(data)
    }
  }

  // 选择产品
  handleSelectedChange = (keys, rows) => {
    this.setState({
      selectedRows: rows
    })
  }

  // 点击自动发布工单
  handlePublishWorkOrder = () => {
    confirm({
      title: '确定发布？',
      onOk: () => {
        const { selectedRows = [] } = this.state
        this.saveCreateWorkOrder({ enquiryOrderDetails: selectedRows, platformUserIdList: [] })
      }
    })
  }

  // 点击标定售价按钮
  handleShowAssginPriceModal = (record) => {
    if (+record.commoditySkuId === 0) {
      warning({
        title: '产品数据不正确',
        content: '请修正产品数据，或者停止该产品报价'
      })

      return false
    }
    this.setState({
      rowDetail: record,
      assginPriceModal: true
    })
  }

  // 点击修正产品按钮
  handleShowProductModal = (record) => {
    this.setState({
      rowDetail: record,
      productModal: true
    })
  }

  // 点击停止报价、恢复报价
  handleChangeCollaborateState = (record, state) => {
    const params = { ...record, collaborateState: state }

    if (state === 'STOP_QUOTATION') {
      confirm({
        title: '确定停止报价？',
        content: '',
        onOk: () => {
          this.props.onChangeCollaborateState(params)
        }
      })
      return false
    }

    this.props.onChangeCollaborateState(params)
  }

  // 点击协作报价 、批量发布询价、发布协作列表 按钮
  handleCoordination = (details, type) => {
    if (type === 'AUTO_QUOTATION') {
      const { orderId } = this.props
      this.saveAutoIssueQuotation({ orderId: orderId, enquiryOrderDetails: details })

      return false
    }

    this.setState({
      coordinationDetails: details,
      coordinationType: type,
      coordinationModal: true
    })
  }

  // 确定发布协作报价、批量协作报价、协作列表
  confirmCoordination = (params) => {
    const { coordinationType } = this.state

    if (coordinationType === 'HELP_QUOTATION') {
      this.saveCreateWorkOrder(params)

      return false
    }

    if (coordinationType === 'BATCH_GENERATE') {
      this.saveBatchCreateWorkOrders(params)

      return false
    }

    if (coordinationType === 'HELP_LIST_QUOTATION') {
      this.saveTeamWorkOrders(params)
    }
  }

  // 查看进度
  showAutoQuotationProgressModal = () => {
    this.setState({
      autoQuotationProgressModal: true
    })
  }

  // 点击下载模版
  handleDownloadExcel = () => {
    DownloadApi.downloadAutoQuotationTemplate({ excelTemplateType: 'AUTO_QUOTATION_IMPORT' })
  }

  // 监听EXCEL上传
  handleChangeUploadFile = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.setState({
          uploadSheetDetails: {
            name: data.tableName || '',
            list: data.sheetNames || [],
            file: info.file.originFileObj
          },
          uploadSheetModal: true
        })
      } else {
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      message.error('上传失败')
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    const { history, enquiryOrderId } = this.props

    history.push({ pathname: '/admin/matchSheet', state: { importId: params, sourceType: 'AUTO_QUOTATION_IMPORT', defaultParams: { enquiryOrderId: enquiryOrderId } } })
  }

  closeModal = () => {
    this.setState({
      rowDetail: {},
      uploadSheetDetails: {},
      productModal: false,
      coordinationModal: false,
      assginPriceModal: false,
      autoQuotationProgressModal: false,
      uploadSheetModal: false
    })
  }

  render () {
    const { list = [], enquiryOrderId } = this.props
    const { selectedRows = [], productModal = false, coordinationModal = false, coordinationType = 'HELP_QUOTATION', coordinationDetails = [],
      assginPriceModal = false, autoQuotationProgressModal = false, uploadSheetModal = false, uploadSheetDetails = {}, rowDetail = {} } = this.state

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: $lodash.map(selectedRows, 'id'),
      onChange: this.handleSelectedChange,
      getCheckboxProps: record => ({
        disabled: +record.commoditySkuId === 0 || record.collaborateState === 'STOP_QUOTATION'
      })
    }

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row gutter={24} style={{ marginBottom: '12px' }}>
            <Col span={16}>
              <Button type='primary' disabled={selectedRows.length === 0}
                onClick={() => this.handlePublishWorkOrder()}
              >勾选自动发布</Button>

              <Button type='primary' disabled={selectedRows.length === 0} style={{ marginLeft: '10px' }}
                onClick={() => this.handleCoordination(selectedRows, 'BATCH_GENERATE')}
              >批量协作报价</Button>

              <Button type='primary' disabled={selectedRows.length === 0} style={{ marginLeft: '10px' }}
                onClick={() => this.handleCoordination(selectedRows, 'HELP_LIST_QUOTATION')}
              >发布协作列表</Button>

              <Button type='primary' disabled={selectedRows.length === 0} style={{ marginLeft: '10px' }}
                onClick={() => this.handleCoordination(selectedRows, 'AUTO_QUOTATION')}
              >自动报价</Button>

              <Button type='primary' style={{ marginLeft: '10px' }}
                onClick={() => this.showAutoQuotationProgressModal()}
              >查看进度</Button>

              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleDownloadExcel()}>
                <Icon type='download' />下载模版
              </Button>

              <Upload name='file' showUploadList={false}
                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                headers={{ Token: getStore('token') }}
                action={uploadMatchExcelUrl}
                onChange={(info) => this.handleChangeUploadFile(info)}
              >
                <Button type='primary' style={{ marginLeft: '10px' }}>
                  <Icon type='upload' />导入价格
                </Button>
              </Upload>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <span className={`${styles.tag}`}>
                <Badge color='#FFA39E' text='超出报价有效期' />
              </span>
            </Col>
          </Row>

          <div style={{ marginBottom: '12px' }}>
            <Table rowKey='id' bordered
              columns={this.columns}
              dataSource={list}
              rowSelection={rowSelection}
              rowClassName={(record, index) => { return record.collaborateState === 'STOP_QUOTATION' ? styles.disabledColor : (record.overdue ? styles.errorColor : null) }}
              pagination={false}
              scroll={{ x: 2200 }}
            />
          </div>

          <RemarksForm form={this.props.form} />
        </Card>

        { // 修正产品弹窗
          productModal
            ? <ProductModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.props.onChangeCollaborateState(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        { // 协作报价弹窗
          coordinationModal
            ? <CoordinationModal
              coordinationDetails={coordinationDetails}
              type={coordinationType}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.confirmCoordination(params)}
            />
            : null
        }

        { /* 标定售价 */ }
        <Drawer title='选择售价' width={1300} bodyStyle={{ paddingBottom: '80px' }}
          visible={assginPriceModal}
          placement='right'
          closable
          onClose={() => this.closeModal()}
        >
          {
            assginPriceModal
              ? <AssginPriceContents
                detailInfo={rowDetail}
                onConfirm={(params) => this.props.onChangeCollaborateState(params)}
                onCancel={() => this.closeModal()}
              />
              : null
          }
        </Drawer>

        { /* 任务进度 */
          autoQuotationProgressModal
            ? <AutoQuotationProgressModal
              enquiryOrderId={enquiryOrderId}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        { // EXCEL智能解析-选择sheet
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const EnquireProductHandle = Form.create()(EnquireProductForm)

export default EnquireProductHandle
