import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Form, Button, message } from 'antd'

import DrawerContents from '../drawerContents/index'

import styles from './index.module.scss'

class SaledFormDrawer extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    replacementAddress: {},
    selectedProducts: []
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {}, onConfirm } = this.props
        const { selectedProducts = [], replacementAddress = {} } = this.state
        const { afterSaleOrderType, uploadImageUrls } = values

        if (selectedProducts.length === 0) {
          return message.warning('请选择售后产品')
        }

        if (uploadImageUrls && uploadImageUrls.length > 0) {
          values.imageUrls = uploadImageUrls.split(',')
        }

        if (['CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(afterSaleOrderType)) {
          values.replacementAddress = replacementAddress
        }

        values.aftersaleGoods = selectedProducts
        values.purchaseOrderId = detailInfo.purchaseOrderId
        values.orderId = detailInfo.orderId

        onConfirm(values)
      }
    })
  }

  // 监听收件地址选择
  handleSelectedReplacementAddress = (values) => {
    this.setState({
      replacementAddress: values
    })
  }

  // 监听售后产品
  handleSelectedSaledProduct = (list = []) => {
    this.setState({
      selectedProducts: list
    })
  }

  render () {
    const { form, detailInfo = {}, visible = false, onCancel } = this.props

    return (
      <Drawer title='售后申请' width={1300} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        destroyOnClose
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        <Form>
          {
            visible
              ? <DrawerContents form={form}
                detailInfo={detailInfo}
                onSelectedProduct={(list) => this.handleSelectedSaledProduct(list)}
                onSelectedReplacementAddress={(values) => this.handleSelectedReplacementAddress(values)}
              />
              : null
          }
        </Form>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>

          <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => this.handleConfirm()}>申请售后</Button>
        </div>
      </Drawer>
    )
  }
}

const SaledDrawer = Form.create()(SaledFormDrawer)

export default SaledDrawer
