import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Switch, Modal, Button, Icon } from 'antd'

import SearchForm from '@/components/search-form/index'
import filter from '@/common/utils/filter'

import Api from '@/common/api/index'

const { confirm } = Modal
const { pageSizeOptions, repLineBreak } = filter

export default class CusbrandProduct extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'itemModel',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号...'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    basicQuery: {},
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'cusproduct-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '型号',
      key: 'cusproduct-1',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'cusproduct-2',
      dataIndex: 'itemNumber'
    },
    {
      title: '分类',
      key: 'cusproduct-3',
      dataIndex: 'classificationName'
    },
    {
      title: '产品',
      key: 'cusproduct-4',
      dataIndex: 'name'
    },
    {
      title: '价格',
      key: 'cusproduct-5',
      dataIndex: 'price',
      render: (text, record, index) => (
        <span>&yen;{+record.price}</span>
      )
    },
    {
      title: '最小包装',
      key: 'cusproduct-6',
      dataIndex: 'minPackQuantity'
    },
    {
      title: '最小订货',
      key: 'cusproduct-7',
      dataIndex: 'minOrder'
    },
    {
      title: '单位',
      key: 'cusproduct-8',
      dataIndex: 'unit'
    },
    {
      title: '云上架',
      key: 'cusproduct-9',
      render: (text, record, index) => (
        <div>
          <Switch checkedChildren={'启用'} unCheckedChildren='禁用'
            checked={record.enableStatus === 'ENABLE'}
            onChange={(checked) => this.handleChangeProductStatus(checked, record)}
          />
        </div>
      )
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location
    this.setState({
      basicQuery: state
    }, () => {
      this.getCustomerPutWayProductInBrand()
    })
  }

  // 获取产品
  getCustomerPutWayProductInBrand = () => {
    const { query = {}, basicQuery = {}, pages } = this.state
    let params = {
      ...query,
      ...basicQuery,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCustomerPutWayProductInBrand(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 商户管理-推广的产品上下架状态
  saveCustomerPutWayProductStatus = (params) => {
    Api.saveCustomerPutWayProductStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getCustomerPutWayProductInBrand()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.itemModel && search.itemModel !== '') {
      search['itemModel'] = repLineBreak(search.itemModel, '<br>')
    }

    this.setState({
      query: search
    }, () => {
      this.getCustomerPutWayProductInBrand()
    })
  }

  // 点击切换云上架启用/禁用状态
  handleChangeProductStatus = (checked, record) => {
    const { basicQuery = {} } = this.state
    let params = {
      distributorId: basicQuery.distributorId,
      commoditySkuId: record.commoditySkuId,
      enableStatus: checked ? 'ENABLE' : 'DISABLE'
    }
    if (!checked) {
      confirm({
        title: '确定禁用该产品云上架?',
        content: '',
        onOk: () => {
          this.saveCustomerPutWayProductStatus(params)
        }
      })

      return false
    }

    this.saveCustomerPutWayProductStatus(params)
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCustomerPutWayProductInBrand()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCustomerPutWayProductInBrand()
    })
  }

  render () {
    const { searchData, tableData, pages } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}><Icon type='left' />返回</Button>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <SearchForm {...this.porps}
                dataSource={searchData}
                handleSubmit={(search) => this.handleSearch(search)}
              />
            </Row>
            <Row>
              <Table rowKey='commoditySkuId' bordered
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>
      </>
    )
  }
}
