import React, { Component } from 'react'
import { Row, Card, Table, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import styles from './index.module.scss'

const { pageSizeOptions, repLineBreak } = filter

export default class index extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  state={
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    detailList: [],
    query: {},
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ]
  }

  columns=[
    {
      title: '序号',
      key: 'cloudprod-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '产品信息',
      key: 'cloudprod-2',
      ellipsis: true,
      render: (text, record, index) => (
        <Row>
          <div className={`${styles.ellipsis}`}>
            <label className={`label`}>产品</label>
            <span title={record.skuName}>{record.name ? record.name : '----'}</span>
          </div>
          <div className={`${styles.ellipsis}`}>
            <label className={`label`}>品牌</label>
            <span title={record.brandName}>{record.brandName ? record.brandName : '----'}</span>
          </div>
          <div className={`${styles.ellipsis}`}>
            <label className={`label`}>系列</label>
            <span title={record.series}>{record.series ? record.series : '----'}</span>
          </div>
        </Row>
      )
    },
    {
      title: '型号/订货号',
      key: 'cloudprod-3',
      ellipsis: true,
      render: (text, record, index) => (
        <Row>
          <div className={`${styles.ellipsis}`}>
            <label className={`${styles.rowLabel}`}>型<em style={{ visibility: 'hidden' }}>型</em>号</label>
            <span title={record.model}>{record.model}</span>
          </div>
          <div className={`${styles.ellipsis}`}>
            <label className={`${styles.rowLabel}`}>订货号</label>
            <span title={record.itemNumber}>{record.itemNumber}</span>
          </div>
        </Row>
      )
    },
    {
      title: '表价',
      key: 'cloudprod-8',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '商户价格',
      key: 'cloudprod-9',
      dataIndex: 'defaultPrice',
      render: (text, record, index) => {
        const { commoditySkuId, detailList = [] } = record

        return (
          <>
            {
              detailList.map((item, n) => (
                <p className={`${styles.ellipsisCellContainer}`} key={`defaultPrice-${commoditySkuId}-${item.distributorId}-${index}-${n}`}>
                  { +item.defaultPrice > 0 ? <>&yen;{ item.defaultPrice }</> : '面议' }
                </p>
              ))
            }
          </>
        )
      }
    },
    {
      title: '商户库存',
      key: 'cloudprod-10',
      dataIndex: 'stock',
      render: (text, record, index) => {
        const { commoditySkuId, detailList = [] } = record

        return (
          <>
            {
              detailList.map((item, n) => (
                <p className={`${styles.ellipsisCellContainer}`} key={`stock-${commoditySkuId}-${item.distributorId}-${index}-${n}`}>{ item.stock }</p>
              ))
            }
          </>
        )
      }
    },
    {
      title: '商户更新时间',
      key: 'cloudprod-11',
      dataIndex: 'modifyTime',
      render: (text, record, index) => {
        const { commoditySkuId, detailList } = record

        return (
          <>
            {
              detailList.map((item, n) => (
                <p className={`${styles.ellipsisCellContainer}`} key={`modifyTime-${commoditySkuId}-${item.distributorId}-${index}-${n}`}>
                  { item.modifyTime ? item.modifyTime : '----' }
                </p>
              ))
            }
          </>
        )
      }
    },
    {
      title: '商户名称',
      key: 'cloudprod-12',
      width: 240,
      dataIndex: 'distributorName',
      render: (text, record, index) => {
        const { commoditySkuId, detailList } = record

        return (
          <>
            {
              detailList.map((item, n) => (
                <p className={`${styles.ellipsisCellContainer}`} key={`distributorName-${commoditySkuId}-${item.distributorId}-${index}-${n}`}>
                  {item.distributorName }
                </p>
              ))
            }
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getCloudLibrard()
  }

  // 获取虚拟云仓列表
  getCloudLibrard = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudLibrard(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          totalValue: data.totalValue || 0,
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: '', distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.itemNo) {
      search['itemNo'] = repLineBreak(search.itemNo, '<br>')
    }
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getCloudLibrard()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudLibrard()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudLibrard()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey={(record, i) => `${record.brandId}-${i}`} bordered
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>
      </>
    )
  }
}
