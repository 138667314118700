import Ajax from '../utils/ajax'

export default {
// 系统消息

  // 获取系统通知列表
  getSystemNotifysList (params) {
    return Ajax.post('/platform/api/systemMessage/listPage', params)
  },

  // 获取系统通知发送历史
  getSystemNotifysSendedFlow (params) {
    return Ajax.post('/oms/api/message/system/listPageForSend', params)
  },

  // 编辑系统消息
  saveSystemNotifyContent (params) {
    return Ajax.post('/platform/api/systemMessage/edit', params)
  },

  // 获取已接收的信息列表
  getReceivedInformationList (params) {
    return Ajax.post('/oms/api/message/system/listPageForReceive', params)
  },

  // 获取IIASaaS公告列表
  getIIASaaSSystemNoticeList (params) {
    return Ajax.post('/platform/msgManager/api/listPage', params)
  },

  // 保存IIASaaS公告
  saveIIASaaSSystemNotice (params) {
    return Ajax.post('/platform/msgManager/api/save', params)
  },

  // 发布IIASaaS公告
  sendpublishIIASaaSSystemNotice (params) {
    return Ajax.post('/platform/msgManager/api/send', params)
  },

  // 删除IIASaaS公告
  deleteIIASaaSSystemNotice (params) {
    return Ajax.post('/platform/msgManager/api/remove', params)
  },

  // 获取未读消息数
  getUnReadNotifyCount (params) {
    return Ajax.get('/oms/api/message/system/countByUnreadMessage', { params })
  },

  // 标记消息已读
  updateNoticeReadStatus (params) {
    return Ajax.post('/oms/api/message/system/markRead', params)
  }

}
