import React, { Component } from 'react'
import { Card, Table, Button, Modal, message } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form'
import InvoiceModal from './invoiceModal'
import LogisticsModal from '@/components/logisticsModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderInvoiceOpenedStatusLabel } from '@/common/utils/mUtils'

const { confirm } = Modal

const { pageSizeOptions, invoiceOpenedStatusMaps } = filter
const _invoiceOpenedStatusMaps = $lodash.filter(invoiceOpenedStatusMaps, (o) => { return o.id !== 'WAIT_TO_APPLY' })

class OpenedInvoiceList extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'id',
        formType: 'Input',
        itemParams: {
          label: '开票申请编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'applyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {

        }
      },
      {
        name: 'invoiceRecordStatus',
        formType: 'Select',
        itemParams: {
          label: '开票状态'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ..._invoiceOpenedStatusMaps
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '开票时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {},
    invoiceModal: false,
    logisticsModal: false // 物流查看弹窗
  }
  columns = [
    {
      title: '序号',
      key: 'invoiceOpeningCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '开票申请编号',
      key: 'invoiceOpeningCol-1',
      dataIndex: 'id'
    },
    {
      title: '商户名称',
      key: 'invoiceOpeningCol-2',
      width: 180,
      ellipsis: true,
      dataIndex: 'distributorName'
    },
    {
      title: '开票金额',
      key: 'invoiceOpeningCol-3',
      dataIndex: 'invoiceAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.invoiceAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '申请时间',
      key: 'invoiceOpeningCol-4',
      dataIndex: 'invoiceApplyTime',
      render: (text, record, index) => (
        <>{ record.invoiceApplyTime ? moment(record.invoiceApplyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '开票状态',
      key: 'invoiceOpeningCol-5',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.status) }</>
      )
    },
    {
      title: '开票时间',
      key: 'invoiceOpeningCol-6',
      dataIndex: 'openedTime',
      render: (text, record, index) => (
        <>{ record.openedTime ? moment(record.openedTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '开票人',
      key: 'invoiceOpeningCol-7',
      dataIndex: 'adminUserName'
    },
    {
      title: '修改时间',
      key: 'invoiceOpeningCol-8',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '修改人',
      key: 'invoiceOpeningCol-9',
      dataIndex: 'modifyName'
    },
    {
      title: '操作',
      key: 'invoiceOpeningCol-10',
      width: 180,
      render: (text, record, index) => (
        <>
          {
            record.status === 'DISCARDED'
              ? null
              : <>
                <Button type='link' size='small' onClick={() => this.showInvoiceModal(record)}>查看详情</Button>
                {
                  record.status === 'OPENED'
                    ? <Button type='link' size='small' onClick={() => this.handleShowLogisticsModal(record)}>查看物流</Button>
                    : <Button type='link' size='small' onClick={() => this.handleDiscardApplyInvoice(record)}>重新开票</Button>
                }
              </>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getSupplyOrderInvoiceApplyRecords()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[1].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 云供应发票- 获取申请历史
  getSupplyOrderInvoiceApplyRecords = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getSupplyOrderInvoiceApplyRecords({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 云供应发票- 重新申请开票
  submitSupplyOrderInvoiceRepeatOpening = async (params) => {
    const res = await Api.submitSupplyOrderInvoiceRepeatOpening(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.getSupplyOrderInvoiceApplyRecords()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.applyTimes) {
      search['applyBeginTime'] = search.applyTimes.length === 2 ? search.applyTimes[0].format('YYYY-MM-DD') : undefined
      search['applyEndTime'] = search.applyTimes.length === 2 ? search.applyTimes[1].format('YYYY-MM-DD') : undefined

      delete search.applyTimes
    }

    if (search.times) {
      search['openedBeginTime'] = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search['openedEndTime'] = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getSupplyOrderInvoiceApplyRecords()
    })
  }

  // 点击查看详情
  showInvoiceModal = (record) => {
    this.setState({
      rowDetail: record,
      invoiceModal: true
    })
  }

  // 点击重新开票
  handleDiscardApplyInvoice = (record) => {
    confirm({
      title: '确定重新申请开票？',
      onOk: () => {
        this.submitSupplyOrderInvoiceRepeatOpening({ invoiceRecordId: record.id })
      }
    })
  }

  // 点击查看物流
  handleShowLogisticsModal = (record) => {
    this.setState({
      rowDetail: { courierCode: record.courierCode, courierNumber: record.courierNumber, mobile: record.phoneNumber },
      logisticsModal: true
    })
  }

  closeModal = () => {
    this.setState({
      invoiceModal: false,
      logisticsModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyOrderInvoiceApplyRecords()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyOrderInvoiceApplyRecords()
    })
  }

  render () {
    const { searchData, pages, tableData = [], invoiceModal = false, logisticsModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          invoiceModal
            ? <InvoiceModal
              ids={[rowDetail.id]}
              openStatus={rowDetail.status}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          logisticsModal
            ? <LogisticsModal
              courierNumberList={[rowDetail]}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default OpenedInvoiceList
