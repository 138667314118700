import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Table } from 'antd'
import moment from 'moment'

export default class InvoiceContent extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }
  state = {}
  columns = [
    {
      title: '货物及应税劳资名称',
      key: 'invoiceCol-0',
      dataIndex: 'cargoName'
    },
    {
      title: '订单编号',
      key: 'invoiceCol-1',
      dataIndex: 'orderId'
    },
    {
      title: '收货日期',
      key: 'invoiceCol-2',
      render: (text, record, index) => (
        <>{ record.receiverTime ? moment(record.receiverTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '运单号',
      key: 'invoiceCol-3',
      render: (text, record, index) => (
        <div>{ record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----' }</div>
      )
    },
    {
      title: '发货人',
      key: 'invoiceCol-4',
      dataIndex: 'senderName'
    },
    {
      title: '运费金额',
      key: 'invoiceCol-5',
      render: (text, record, index) => (
        <div>&yen;{ ((+record.totalPrice - (+record.transportPrice) + (+record.merchantTransportPrice)) * 100 / 100).toFixed(2) }</div>
      )
    }
  ]

  render () {
    const { tableData = [] } = this.props

    return (
      <div>
        <Row>
          <Table rowKey='feeFlowId' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Row>
      </div>
    )
  }
}
