
console.log(PATH_ENV)

const BASEURL = {
  prod: 'http://cloud-java.china-iia.com', // 生产环境
  pre: 'http://cloud-java.china-iia.com', // 预生产
  test: 'http://cjava.nanjingsaas.cn:8080', // 测试环境
  dev: 'http://cjava.nanjingsaas.cn:8080' // 开发
}

// 测试环境: http://cloud-java.mall.com
// 袁：http://192.168.1.102:8200
// 郑: http://192.168.1.104:8200
// 测试穿透: http://cjava.nanjingsaas.cn:8080

// websocket 链接配置
const SOCKETWS = {
  prod: `wss://ws.iiasaas.com`,
  test: `ws://192.168.1.11:7999`,
  dev: `ws://192.168.1.11:7999`
}

// 数据分析 dashboardApi baseUrl
const DASHBOARDURL = {
  prod: 'http://report_charts.iiasaas.com',
  test: 'http://report_charts.nanjingsaas.cn:8080',
  dev: 'http://report_charts.nanjingsaas.cn:8080'
}

// 图片链接
const IMGhost = 'http://picture.iiasaas.com/'
const host = BASEURL[PATH_ENV]
const socketWs = SOCKETWS[PATH_ENV]
const dashboardhost = DASHBOARDURL[PATH_ENV]

export default {
  host,
  IMGhost,
  socketWs,
  dashboardhost
}
