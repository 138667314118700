import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Switch, Icon, message } from 'antd'

import SearchForm from '@/components/search-form'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const notifyToMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'EU', name: 'EU' },
  { id: 'MERCHANT', name: '商户' },
  { id: 'SUPPLIER', name: '供应商' },
  { id: 'IIASAAS', name: 'IIASaaS' }
]
const { pageSizeOptions } = filter

class NotifyList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '启用状态'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLE', name: '启用' },
          { id: 'DISABLE', name: '禁用' }
        ],
        rules: {
          initialValue: 'ALL'
        }
      },
      {
        name: 'notifyObject',
        formType: 'Select',
        itemParams: {
          label: '通知对象'
        },
        cptParams: {

        },
        options: notifyToMaps,
        rules: {
          initialValue: 'ALL'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      type: 'ALL',
      notifyObject: 'ALL'
    }
  }

  columns = [
    {
      title: '序号',
      key: 'notifyList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: 'msg_code',
      key: 'notifyList-1',
      dataIndex: 'msgCode'
    },
    {
      title: '通知标题',
      key: 'notifyList-2',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '通知内容',
      key: 'notifyList-3',
      ellipsis: true,
      dataIndex: 'content'
    },
    {
      title: '通知条件',
      key: 'notifyList-4',
      dataIndex: 'remarks'
    },
    {
      title: '通知对象',
      key: 'notifyList-5',
      width: 80,
      render: (text, record, index) => (
        <div>
          { this.renderNotifyToObjectLabel(record.notifyObject) }
        </div>
      )
    },
    {
      title: '适用对象',
      key: 'notifyList-6',
      width: 300,
      render: (text, record, index) => (
        <div>
          {
            record.sendObjectList && record.sendObjectList.length > 0
              ? <div>
                {
                  record.sendObjectList.map((sendItem, n) => (
                    <span key={`sendObjectItem-${n}-record.id`} style={{ marginLeft: '6px' }}>{this.renderNotifyToObjectLabel(sendItem)}</span>
                  ))
                }
              </div>
              : '----'
          }

        </div>
      )
    },
    {
      title: '是否启用',
      key: 'notifyList-7',
      width: 80,
      render: (text, record, index) => (
        <div>
          <Switch checked={record.type === 'ENABLE'}
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            onChange={(checked) => this.handleSwitchNotifyStatus(checked, record)}
          />
        </div>
      )
    },
    {
      title: '操作',
      key: 'notifyList-8',
      width: 120,
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.handleLinkToEditNotifys(record, 2)}>编辑</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getSystemNotifysList()
  }

  // 获取系统消息列表
  getSystemNotifysList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSystemNotifysList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存系统通知
  saveSystemNotifyContent = (params) => {
    Api.saveSystemNotifyContent(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getSystemNotifysList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getSystemNotifysList()
    })
  }

  // 切换启用/禁用
  handleSwitchNotifyStatus = (checked, record) => {
    let params = {
      title: record.title,
      content: record.content,
      type: checked ? 'ENABLE' : 'DISABLE',
      id: record.id
    }

    this.saveSystemNotifyContent(params)
  }

  // 点击编辑系统通知
  handleLinkToEditNotifys = (record, type) => {
    this.props.history.push({ pathname: '/admin/editsysNotify', state: { detailInfo: record, type: type } })
  }

  // 渲染通知对象名称
  renderNotifyToObjectLabel = (status) => {
    let str = ''
    let obj = notifyToMaps.find(item => {
      return item.id === status
    })

    if (obj) {
      str = obj.name
    }

    return str
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSystemNotifysList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSystemNotifysList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
            <Row>
              <Table rowKey='id' bordered
                dataSource={tableData}
                columns={this.columns}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>
      </div>
    )
  }
}

export default NotifyList
