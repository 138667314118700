import React, { Component } from 'react'
import { Modal, Form, Input } from 'antd'
import PropTypes from 'prop-types'

const { TextArea } = Input

const FormItem = Form.Item
class remarksmodal extends Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    detailInfo: PropTypes.object,
    form: PropTypes.object
  }

// 确定
handleOk = () => {
  const { detailInfo } = this.props
  this.props.form.validateFields((er, values) => {
    if (!er) {
      let param = {
        orderId: detailInfo.orderId,
        remarks: values.remarks
      }
      this.props.onConfirm(param)
    }
  })
}
render () {
  const { detailInfo } = this.props
  const { getFieldDecorator } = this.props.form
  return (
    <Modal
      title='备注'
      visible
      onOk={this.handleOk}
      onCancel={this.props.onCancel}
    >
      <Form>
        <FormItem>
          {
            getFieldDecorator('remarks', {
              rules: [
                { required: true, message: '请填写备注' }
              ],
              initialValue: detailInfo.remarks || ''
            })(
              <TextArea rows={4} placeholder='请输入备注' autoComplete='off' />
            )
          }
        </FormItem>
      </Form>
    </Modal>
  )
}
}

const AddressModal = Form.create()(remarksmodal)

export default AddressModal
