import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Select, Input, InputNumber, Upload, Button, Icon, message, Tooltip } from 'antd'
import $lodash from 'lodash'

import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'
import SAASCourierJson from '@/assets/json/saasCourier.json'
import CourierJson from '@/assets/json/courier.json'
import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option

const { invoiceTypeMaps } = filter
const { IMG_LT2M } = consts
const { uploadOrdinaryImageUrl } = UploadApi
/**
 * 发票快递服务过略掉SAAS-KYSY
 */
const newSAASCourierJson = $lodash.filter(SAASCourierJson, (item) => { return item.id !== 'SAAS-KYSY' })

class InvoiceExpressForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    openStatus: PropTypes.string,
    info: PropTypes.object,
    invoiceCodeKeys: PropTypes.array,
    addInvoiceCodeFormItem: PropTypes.func,
    removeInvoiceCodeFormItem: PropTypes.func
  }

  state = {}

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过 ${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        message.success('上传成功')
        setTimeout(() => {
          this.props.form.setFieldsValue({
            invoiceImageUrl: data.imageUrl
          })
        }, 20)
      } else {
        message.error('上传文件失败')
      }
    }
  }

  // 点击删除图片
  handleRemoveInvoiceImage = () => {
    setTimeout(() => {
      this.props.form.setFieldsValue({ invoiceImageUrl: '' })
    }, 20)
  }

  // 选择物流公司
  handleChangeLogistics = (code) => {
    const logisticsWayEnum = $lodash.includes(code, 'SAAS') ? 'SAAS' : 'SELF'

    setTimeout(() => {
      this.props.form.setFieldsValue({ logisticsWayEnum: logisticsWayEnum })
    }, 20)
  }

  render () {
    const { form, openStatus, info = {}, invoiceCodeKeys = [], addInvoiceCodeFormItem, removeInvoiceCodeFormItem } = this.props
    const { getFieldDecorator, getFieldValue } = form

    // 物流列表
    const courierList = openStatus === 'OPENED' ? [...CourierJson] : [...newSAASCourierJson, ...CourierJson]

    return (
      <>
        <Row gutter={24}>
          <Col span={12}>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='发票类型'>
                {
                  getFieldDecorator('invoiceType', {
                    initialValue: info.invoiceType || 'SPECIAL_INVOICE',
                    rules: [
                      { required: false, message: '请选择发票类型' }
                    ]
                  })(
                    <Select>
                      {
                        invoiceTypeMaps.map((item, index) => (
                          <Option value={item.id} key={`${item.id}-${index}`}>{ item.name }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <FormItem style={{ display: 'none' }}>
              {
                getFieldDecorator('logisticsWayEnum', {
                  initialValue: openStatus === 'OPENED' ? 'SELF' : null
                })(<Input disabled autoComplete='off' />)
              }
            </FormItem>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='物流公司'>
                {
                  getFieldDecorator('courierCode', {
                    initialValue: info.courierCode || null,
                    rules: [
                      { required: false, message: '请选择物流公司' }
                    ]
                  })(
                    <Select placeholder='请选择...'
                      showSearch
                      optionFilterProp='children'
                      onChange={(value) => this.handleChangeLogistics(value)}
                    >
                      {
                        courierList.map((item, i) => (
                          <Option value={item.courierCode} key={item.courierCode}>{ item.courierName }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>
          </Col>
          <Col span={12}>
            {
              <div className={`${styles.formitemRow}`}>
                {
                  getFieldValue('logisticsWayEnum') === 'SAAS'
                    ? <FormItem label='快递单号'><div>IIASaaS服务将为您生成快递单号</div></FormItem>
                    : <FormItem label='快递单号'>
                      {
                        getFieldDecorator('courierNumber', {
                          initialValue: info.courierNumber,
                          rules: [
                            { required: false, message: '该项必填' }
                          ]
                        })(
                          <Input placeholder='请输入...' autoComplete='off' />
                        )
                      }
                    </FormItem>
                }
              </div>
            }
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='实际开票金额'>
                {
                  getFieldDecorator('realAmount', {
                    initialValue: info.realAmount,
                    rules: [
                      { required: false, message: '请填写实际开票金额' }
                    ]
                  })(
                    <InputNumber placeholder='请输入...' autoComplete='off' style={{ width: '100%' }} />
                  )
                }
              </FormItem>
            </div>
          </Col>
          <Col span={12}>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='备注'>
                {
                  getFieldDecorator('remarks', {
                    initialValue: info.remarks
                  })(<Input autoComplete='off' />)
                }
              </FormItem>
            </div>
          </Col>
        </Row>

        <Row gutter={24}>
          {
            invoiceCodeKeys.map((item, index) => (
              <Col span={12} key={`invoiceCodeInput-${index}`}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票号码'>
                    {
                      getFieldDecorator(`invoiceCodeKeys[${item.id}]`, {
                        initialValue: item.value
                      })(
                        <Input placeholder='请输入...' autoComplete='off' style={{ width: 'calc(100% - 60px)' }} maxLength={16} />
                      )
                    }

                    <div style={{ display: 'inline-block' }}>
                      <a className={`${styles.formitemBtn}`} onClick={() => addInvoiceCodeFormItem(index)}><Icon type='plus' /></a>
                      <a className={`${styles.formitemBtn}`} onClick={() => removeInvoiceCodeFormItem(index)}><Icon type='minus' /></a>
                    </div>
                  </FormItem>
                </div>
              </Col>
            ))
          }

          <Col span={12}>
            <FormItem style={{ display: 'none' }}>
              {
                getFieldDecorator('invoiceImageUrl', {
                  initialValue: info.invoiceImageUrl
                })(<Input disabled autoComplete='off' />)
              }
            </FormItem>
            <div className={`${styles.formitemRow}`}>
              {
                getFieldValue('invoiceImageUrl') === undefined || getFieldValue('invoiceImageUrl') === ''
                  ? <FormItem label='发票图片'>
                    <Upload name='file' showUploadList={false}
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <Button>
                        <Icon type='upload' />点击上传
                      </Button>
                    </Upload>
                  </FormItem>
                  : <div className={`${styles.row}`}>
                    <label className={`label`}>发票图片</label>
                    <div className={`${styles.rowInner}`}>
                      <Tooltip placement='topLeft' title='点击可查看详情'>
                        <a href={getFieldValue('invoiceImageUrl')} target='_blank' rel='noopener noreferer'>{getFieldValue('invoiceImageUrl')}</a>
                      </Tooltip>
                    </div>
                    <a className={`${styles.formitemBtn}`} onClick={() => this.handleRemoveInvoiceImage()}><Icon type='delete' style={{ fontSize: '16px' }} /></a>
                  </div>
              }
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const InvoiceExpress = Form.create()(InvoiceExpressForm)

export default InvoiceExpress
