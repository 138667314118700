import React, { Component } from 'react'
import { Card, Row, Button, Icon, Menu, Dropdown, Modal, message } from 'antd'

import SetmenuModal from '../component/setmenu-modal'

import Api from '@/common/api/index'

import styles from './menuset.module.scss'

const { confirm } = Modal

export default class MenuSetting extends Component {
  static propTypes = {}
  state = {
    setmenuModal: false,
    type: 1, // 1-新增 2-编辑
    parentId: 0, // 0-表示目录
    floor: 'CATALOG', // 层级类型 CATALOG-目录 MENU-菜单 BTN-按钮
    rowDetail: {},
    tableData: []
  }

  componentDidMount () {
    this.getAllMenuList()
  }

  // 获取所有菜单
  getAllMenuList = () => {
    Api.getAllMenuList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data || []
        })
      }
    })
  }

  // 保存 新增菜单
  saveAddMenu = (params) => {
    Api.saveAddMenu(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getAllMenuList()
      }
    })
  }

  // 保存 编辑菜单
  saveEditMenu = (params) => {
    Api.saveEditMenu(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getAllMenuList()
      }
    })
  }

  // 删除菜单
  deleteMenu = (params) => {
    Api.deleteMenu(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getAllMenuList()
      }
    })
  }

  // 点击新增/编辑目录
  showSetMenuModal = (record, type, parentId = 0, floor) => {
    this.setState({
      setmenuModal: true,
      rowDetail: record || {},
      type: type,
      parentId: parentId,
      floor: floor
    })
  }

  // 点击编辑菜单功能
  handleMenuItem = (record) => {
    if (+record.editType === 2) {
      this.setState({
        setmenuModal: true,
        type: 2,
        parentId: +record.parentId,
        rowDetail: record,
        floor: record.floor
      })
    } else {
      confirm({
        title: '确定删除？',
        content: '',
        onOk: () => {
          this.deleteMenu({ menuId: record.menuId })
        }
      })
    }
  }

  // 点击保存菜单
  handleSaveMenu = (params) => {
    const { type = 1 } = this.state
    if (+type === 1) {
      this.saveAddMenu(params)
    } else {
      this.saveEditMenu(params)
    }
  }

  // 点击展开、收起
  handleCollapseOpen = (i) => {
    let { tableData = [] } = this.state

    tableData[i].isOpen = !tableData[i].isOpen

    this.setState({
      tableData
    })
  }

  // 点击子集展开、收起
  handleChildCollapseOpen = (i, idx) => {
    let { tableData = [] } = this.state

    tableData[i].menuList[idx].isOpen = !tableData[i].menuList[idx].isOpen

    this.setState({
      tableData
    })
  }

  renderDropdownMenu = (params, floor) => {
    return (
      <Menu style={{ width: '100px', textAlign: 'center' }}
        onClick={(item) => this.handleMenuItem({ ...params, editType: item.key, floor: floor })}
      >
        <Menu.Item key='2'>编辑</Menu.Item>
        <Menu.Item key='3'>删除</Menu.Item>
      </Menu>
    )
  }

  // 渲染新增按钮
  renderAddIcon = () => {
    return (
      <Icon type='plus' />
    )
  }

  closeModal = () => {
    this.setState({
      setmenuModal: false,
      rowDetail: {},
      type: 1
    })
  }

  render () {
    const { setmenuModal = false, type = 1, parentId = 0, floor, tableData = [], rowDetail = {} } = this.state

    return (
      <>
        <Card title='系统菜单配置'>
          <Row style={{ marginBottom: '20px' }}>
            <Button size='small' type='dashed' onClick={() => this.showSetMenuModal({}, 1, 0, 'CATALOG')}>
              <Icon type='plus' className={`${styles.switcherIcon}`} />新增目录
            </Button>
          </Row>
          <Row>
            {
              tableData.length > 0
                ? <ul className={`${styles.menuTree}`}>
                  {
                    tableData.map((item, i) => (
                      <li className={`${styles.treeNode} ${styles.inline}`} key={`${item.parentId}-${item.menuId}`}>
                        <div>
                          <span className={`${styles.treeSwitcher}`} onClick={() => this.handleCollapseOpen(i)}>
                            <Icon type={!item.isOpen ? 'caret-right' : 'caret-down'}
                              style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)' }}
                            />
                          </span>
                          <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(item, 'CATALOG')}>
                            <span className={`${styles.treeIndeter}`}>
                              <Icon type='form' />
                            </span>
                          </Dropdown>
                          <span className={`${styles.treeCon}`}>
                            <span>{item.name}</span>
                          </span>
                        </div>

                        {
                          <ul className={`${styles.menuChildtree} ${item.isOpen ? styles.opend : styles.none}`}>
                            {
                              item.menuList && item.menuList.length > 0 && item.menuList.map((menu, idx) => (
                                <li className={`${styles.treeNode}`} key={`${menu.parentId}-${menu.menuId}`}>
                                  <div>
                                    <span className={`${styles.treeSwitcher}`} onClick={() => this.handleChildCollapseOpen(i, idx)}>
                                      <Icon type={!menu.isOpen ? 'caret-right' : 'caret-down'}
                                        style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)' }}
                                      />
                                    </span>

                                    <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(menu, 'MENU')}>
                                      <span className={`${styles.treeIndeter}`}>
                                        <Icon type='form' />
                                      </span>
                                    </Dropdown>

                                    <span className={`${styles.treeCon}`}>
                                      <span>{menu.name}</span>
                                    </span>
                                  </div>

                                  <ul className={`${styles.menuChildtree} ${menu.isOpen ? styles.opend : styles.none}`}>
                                    {
                                      menu.buttonList && menu.buttonList.map((btn, bix) => (
                                        <li className={`${styles.treeNode}`} key={`${menu.parentId}-${btn.menuId}`}>
                                          <div>
                                            <span className={`${styles.treeSwitcher}`} />

                                            <Dropdown trigger={['click']} overlay={this.renderDropdownMenu(btn, 'BTN')}>
                                              <span className={`${styles.treeIndeter}`}>
                                                <Icon type='form' />
                                              </span>
                                            </Dropdown>

                                            <span className={`${styles.treeCon}`}>
                                              <span>{btn.name}</span>
                                            </span>
                                          </div>
                                        </li>
                                      ))
                                    }

                                    <li className={`${styles.treeNode}`}>
                                      <div>
                                        <Button type='dashed' size='small' onClick={() => this.showSetMenuModal({}, 1, menu.menuId, 'BTN')}>
                                          <Icon type='plus' />新增按钮
                                        </Button>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              ))
                            }

                            <li className={`${styles.treeNode}`}>
                              <Button type='dashed' size='small' onClick={() => this.showSetMenuModal({}, 1, item.menuId, 'MENU')}>
                                <Icon type='plus' />新增菜单
                              </Button>
                            </li>
                          </ul>
                        }
                      </li>
                    ))
                  }
                </ul>
                : null
            }
          </Row>
        </Card>

        {
          setmenuModal
            ? <SetmenuModal
              type={type}
              parentId={parentId}
              floor={floor}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleSaveMenu(params)}
            />
            : null
        }
      </>
    )
  }
}
