import React, { Component } from 'react'
import { Card, Divider, Button, Row } from 'antd'
import * as echarts from 'echarts'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import NumbersTable from './numbersTable'
import Request from '@/common/api/dashboardApi'

import filter from '@/common/utils/filter'
import { getYearOptions } from '@/common/utils/mUtils'

import styles from './index.module.scss'
import { Icon } from './../js/Icon' // 伸缩展开icon

// 当前年
const YEAR = moment().get('year')
const { fullsMonthOptions, echartscolorinit } = filter

export default class MCNumbersReport extends Component {
  static propTypes = {}
  state = {
    faly: 0,
    searchData: [
      {
        name: 'year',
        formType: 'Select',
        itemParams: {
          label: '查询年份'
        },
        cptParams: {
          placeholder: '请选择查询年份...'
        },
        rules: {
          initialValue: YEAR
        },
        options: getYearOptions(YEAR - 2019 + 1)
      },
      {
        name: 'month',
        formType: 'Select',
        itemParams: {
          label: '查询月份'
        },
        cptParams: {
          placeholder: '请选择查询月份...'
        },
        rules: {
          initialValue: '0'
        },
        options: [
          { month: 0, monthLabel: '全部' },
          ...fullsMonthOptions
        ],
        optionValue: ['month', 'monthLabel']
      }
    ],
    query: {
      year: YEAR,
      month: '0'
    },
    orderData: [], // 订单源数据
    recordData: [], // 商户数量源数据
    series: [],
    tableData: []
  }

  componentDidMount () {
    this.getDashboardAnalyse()
  }

   // 收起和展开
   getfaly =() => {
     const { faly = 0 } = this.state
     this.setState({
       faly: faly === 0 ? 1 : 0
     })
     let id = document.getElementById('rowid')
     id.style.height = faly === 0 ? '25px' : '400px'
   }

  // 获取商户数量数据
  getDashboardAnalyse = async () => {
    const res = await Request.getDashboardAnalyse({ type: 'customer_view' })
    let orderData = this.setOriginData(res.customer_ordering)
    let recordData = this.setOriginData(res.customer_recording)

    let lineObj = $lodash.groupBy(orderData[YEAR], 'name')

    let lineSeries = this.setEChartData(lineObj, 'line')

    let barObj = $lodash.groupBy(recordData[YEAR], 'name')
    let barSeries = this.setEChartData(barObj, 'bar')

    this.setState({
      orderData: this.setOriginData(res.customer_ordering),
      recordData: this.setOriginData(res.customer_recording),
      series: [...barSeries, ...lineSeries]
    }, () => {
      this.initECharts()
      this.setTableData()
    })
  }

  // 初始化ECharts
  initECharts = () => {
    var myChart
    if (document.getElementById('merchantNumbersChart') == null) {
      return
    }
    echarts.dispose(document.getElementById('merchantNumbersChart'))
    myChart = echarts.init(document.getElementById('merchantNumbersChart'))
    let { series = [] } = this.state
    this.getdatainit(series)
    myChart.setOption({
      color: echartscolorinit,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      tooltip: {
        borderColor: '#fff',
        borderWidth: '1',
        formatter: (e) => {
          return this.gettitle(e)
        },
        borderRadius: 4
      },
      title: {
        text: `${this.getYearinit()}趋势图`

      },
      legend: {
        orient: 'horizontal',
        width: '400px', // 图例组件的宽度
        height: '300px',
        right: '13%',
        selectedMode: true, // 图例选择的模式，
        formatter: (name) => {
          return `${name}`
        }
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '新增域名备案商户数',
          yAxisIndex: 0,

          axisLabel: {
            show: true,
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: '新增下单商户数',
          yAxisIndex: 1,
          min: 0,
          interval: 5,
          axisLabel: {
            formatter: '{value}'
          }
        }
      ],
      series: series
    })
  }

  // 设置源数据
  setOriginData = (data) => {
    let arr = $lodash.map(data, (item) => {
      let obj = {
        name: item[2].split(',')[0],
        regionCode: item[2].split(',')[1],
        year: item[1].split('-')[0],
        month: +item[1].split('-')[1],
        value: item[0]
      }
      return obj
    })

    let result = $lodash.groupBy(arr, 'year')
    return result
  }

  // 设置EChart图表数据格式
  setEChartData = (maps, type) => {
    let arr = []
    $lodash.forEach(maps, (values, key) => {
      values = $lodash.unionBy(values, fullsMonthOptions, 'month')
      values = $lodash.orderBy(values, ['month'], 'asc')

      let obj = {
        name: key,
        type: type,
        data: $lodash.map(values, 'value'),
        yAxisIndex: type === 'bar' ? 0 : 1
      }

      arr.push(obj)
    })

    return arr
  }

  // 设置table数据格式
  setTableData = () => {
    const { query = {}, recordData = {}, orderData = {} } = this.state
    let orderObj = $lodash.groupBy(orderData[query.year], 'name')
    let recordObj = $lodash.groupBy(recordData[query.year], 'name')
    let arr = []

    $lodash.forEach(orderObj, (values, key) => {
      let obj = {
        name: key,
        recordNumber: 0
      }

      if (+query.month === 0) {
        obj['orderNumber'] = $lodash.sumBy(values, 'value')
      } else {
        let findObj = $lodash.find(values, (o) => { return +o.month === +query.month })
        obj['orderNumber'] = findObj !== undefined ? findObj['value'] : 0
      }
      arr.push(obj)
    })

    $lodash.forEach(recordObj, (values, key) => {
      let i = $lodash.findIndex(arr, (item) => { return item.name === key })

      if (i > -1) {
        if (+query.month === 0) {
          arr[i]['recordNumber'] = $lodash.sumBy(values, 'value')
        } else {
          let findObj = $lodash.find(values, (o) => { return +o.month === +query.month })
          arr[i]['recordNumber'] = findObj !== undefined ? findObj.value : 0
        }
      } else {
        let obj = {
          name: key,
          orderNumber: 0
        }

        if (+query.month === 0) {
          obj['recordNumber'] = $lodash.sumBy(values, 'value')
        } else {
          let findObj = $lodash.find(values, (o) => { return +o.month === +query.month })
          obj['recordNumber'] = findObj !== undefined ? findObj['value'] : 0
        }

        arr.push(obj)
      }
    })

    const result = [...arr, { name: '合计', orderNumber: $lodash.sumBy(arr, 'orderNumber'), recordNumber: $lodash.sumBy(arr, 'recordNumber') }]
    this.setState({
      tableData: result
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.year === undefined) {
      search.year = YEAR
      search.month = '0'
    }

    const { orderData, recordData } = this.state
    let lineObj = $lodash.groupBy(orderData[search.year], 'name')
    let lineSeries = this.setEChartData(lineObj, 'line')
    let barObj = $lodash.groupBy(recordData[search.year], 'name')
    let barSeries = this.setEChartData(barObj, 'bar')

    this.setState({
      query: search,
      series: [...barSeries, ...lineSeries]
    }, () => {
      this.initECharts()
      this.setTableData()
    })
  }

    // 显示年份
    getYearinit=() => {
      const { query } = this.state
      return query.year
    }

    // 设置地区
    getareainit=(seriesName) => {
      let name = seriesName.substring(0, 2)
      return `${name}地区`
    }

    // 设置series数据
    getdatainit =(series) => {
      $lodash.forEach($lodash.groupBy(series, `name`), (values, key) => {
        if (values.length === 2) {
          values[0].data1 = values[1].data
          values[1].data1 = values[0].data
        }
      })

      series.map((item) => {
        if (item.type === 'bar') {
          item.name = `${(item.name).substring(0, 2)}(域名数)`
        }
        if (item.type === 'line') {
          item.name = `${(item.name).substring(0, 2)}(下单数)`
        }
        if ((item.name).substring(0, 2)) {

        }
      })
    }
    // 设置悬浮
    gettitle = (e) => {
      // bar
      let formatterbar = `<span style="display:inline-block;margin-right:4px;border-radius:0px;width:20px;height:10px;background-color:${e.color};"></span>`
      // line
      let formatteline = `<div style="display:flex;">
            <div style="color:${e.color};">-</div>
            <div style="width:12px;height:12px; border:1px solid ${e.color}; border-radius:500px;}"></div>
            <div style="color:${e.color};">-</div></div>`

      let i = e.dataIndex
      let type = e.componentSubType
      const { series } = this.state
      let obj = $lodash.filter(series, (item) => { return item.name === e.seriesName })
      return (
        `<div class=${styles.formatter}>
        <div><span>${this.getareainit(e.seriesName)}</span> <span class=${styles.formatterspan}> ${this.getYearinit()}-${e.name}</span></div>
        <div><span class=${styles.spanicon}>${formatterbar}</span> <span>新增域名</span> <span class=${styles.formatterspan}>${type === 'bar' ? obj[0].data ? obj[0].data[i] : 0 : obj[0].data1 ? obj[0].data1[i] : 0}</span></div>
        <div><span class=${styles.spanicon}>${formatteline}</span> <span>新增下单</span> <span class=${styles.formatterspan}>${type === 'bar' ? obj[0].data1 ? obj[0].data1[i] : 0 : obj[0].data ? obj[0].data[i] : 0}</span></div>
        </div>`
      )
    }
    render () {
      const { searchData, tableData = [], faly = 0 } = this.state

      return (
        <>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>

          <div style={{ display: 'block', marginTop: '20px', whiteSpace: 'nowrap' }}>
            <Card>
              <Row id='rowid' className={styles.mianrow}>
                <div id='merchantNumbersChart' style={{ width: `${window.innerWidth - 300}px`, height: '400px' }} />
              </Row>
              <div className={styles.line}> <Divider />
                <Button onClick={() => { this.getfaly() }} className={styles.aicon}> <img src={Icon[faly].src} /></Button>
              </div>
            </Card>

          </div>

          <NumbersTable tableData={tableData} />
        </>
      )
    }
}
