import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Tooltip, Empty } from 'antd'

import { getCourierName } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item

class InvoiceExpressForm extends Component {
  static propTypes = {
    openStatus: PropTypes.string,
    info: PropTypes.object
  }
  state = {}

  render () {
    const { openStatus = 'OPENING', info = {} } = this.props

    return (
      <>
        {
          openStatus === 'OPENED'
            ? <>
              <Row gutter={12}>
                <Col span={12}>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='物流公司' style={{ marginBottom: '0px' }}>{ getCourierName(info.courierCode) }</FormItem>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='快递单号' style={{ marginBottom: '0px' }}>{ info.courierNumber }</FormItem>
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='发票号码' style={{ marginBottom: '0px' }}>{info.invoiceCode}</FormItem>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={`${styles.row}`}>
                    <label className='label'>发票图片</label>
                    <div className={`${styles.rowInner}`}>
                      {
                        info && info.invoiceImageUrl && info.invoiceImageUrl !== ''
                          ? <Tooltip placement='topLeft' title='点击可查看详情'>
                            <a href={info.invoiceImageUrl} target='_blank' rel='noopener noreferer'>{info.invoiceImageUrl}</a>
                          </Tooltip>
                          : '----'
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </>
            : <Empty description={'开票中，请耐心等待...'} />
        }

      </>
    )
  }
}

const InvoiceExpress = Form.create()(InvoiceExpressForm)

export default InvoiceExpress
