import React, { Component } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
export default class smsInfomodal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    detail: PropTypes.object
  }

  render () {
    const { detail } = this.props
    return (
      <Modal
        title='发送内容'
        visible
        onOk={this.props.onCancel}
        onCancel={this.props.onCancel}
      >
        {detail.content}
      </Modal>
    )
  }
}
