import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Button, Icon, Card, Table } from 'antd'
import { downloadExcel } from '@/common/utils/downloadFile'

export default class ActiveTable extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }
  state = {}
  columns = [
    {
      title: '销售大区',
      key: 'merchantActiveCol-0',
      dataIndex: 'name'
    },
    {
      title: '累计商户数量',
      key: 'merchantActiveCol-1',
      dataIndex: 'merchantTotalNum'
    },
    {
      title: '老商户数量',
      key: 'merchantActiveCol-2',
      render: (text, record, index) => (
        <div>{ record.merchantTotalNum - record.yearAddActiveNum }</div>
      )
    },
    {
      title: '年度新增商户数量',
      key: 'merchantActiveCol-3',
      dataIndex: 'yearAddActiveNum'
    },
    {
      title: '当天保持活跃商户数量',
      key: 'merchantActiveCol-4',
      dataIndex: 'active30DaysNum'
    },
    {
      title: '当月新增活跃商户数量',
      key: 'merchantActiveCol-5',
      dataIndex: 'monthAddActiveNum'
    },
    {
      title: '30天不活跃数',
      key: 'merchantActiveCol-6',
      dataIndex: 'firstMonthNotActiveNum'
    },
    {
      title: '60天不活跃数',
      key: 'merchantActiveCol-7',
      dataIndex: 'secondMonthNotActiveNum'
    },
    {
      title: '90天不活跃数',
      key: 'merchantActiveCol-8',
      dataIndex: 'thirdMonthNotActiveNum'
    },
    {
      title: '120天不活跃数',
      key: 'merchantActiveCol-9',
      dataIndex: 'fourMonthNotActiveNum'
    },
    {
      title: '150天不活跃数',
      key: 'merchantActiveCol-10',
      dataIndex: 'fiveMonthNotActiveNum'
    }
  ]

  componentDidMount () {}

  handleDownload = () => {
    const { tableData = [] } = this.props
    let arr = [
      ['销售大区', '累计商户数量', '老商户数量', '年度新增商户数量', '当天保持活跃商户数量', '当月新增活跃商户数量', '30天不活跃数', '60天不活跃数', '90天不活跃数', '150天不活跃数']
    ]

    tableData.forEach((item, i) => {
      let rowArr = []
      let oldVal = item.quantity - item.yearAddNum

      rowArr.push(item.name, item.quantity, oldVal, item.yearAddNum, item.activeNum, item.monthAddActiveNum,
        item.firstMonthNotActiveNum, item.secondMonthNotActiveNum, item.thirdMonthNotActiveNum, item.fourMonthNotActiveNum, item.fiveMonthNotActiveNum)

      arr.push(rowArr)
    })

    downloadExcel(arr, '商户活跃统计表.xlsx')
  }

  render () {
    const { tableData = [] } = this.props

    return (
      <Row style={{ marginTop: '20px' }}>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Button type='primary' disabled={tableData.length === 0} onClick={() => this.handleDownload()}>
              <Icon type='download' />导出数据
            </Button>
          </Row>
          <Table rowKey={(record, index) => `merchantActiveCol-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Card>
      </Row>
    )
  }
}
