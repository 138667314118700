import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Form, Modal, Row, Col, Input, Select, Radio, Cascader, InputNumber, Divider } from 'antd'
import $lodash from 'lodash'

import UploadImageItem from '../skuBaseUploadImage/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const FormItem = Form.Item
const Option = Select.Option
const RadioGroup = Radio.Group
const { TextArea } = Input

const { skuStopFlagMaps } = filter

class SkuBaseForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 编辑类型: 1-新增 2-修改 3-新增 部分不可编辑
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    categoryList: [], // 分类列表
    brandList: [], // 品牌列表
    seriesList: [], // 系列列表
    info: {} // SKU详情信息
  }

  componentDidMount () {
    const { type = 1 } = this.props

    axios.all([Api.getProductCategoryList({}), Api.getBrandListInSearch({ pageNo: 1, pageSize: 999 })]).then(axios.spread((categorysRes, brandsRes) => {
      let categoryList = categorysRes.data || []
      let brandList = []

      if (brandsRes.data && brandsRes.data.length > 0) {
        brandList = $lodash.map(brandsRes.data, (record) => {
          return {
            showName: record.englishBrandName && record.englishBrandName !== '' ? `${record.brandName}/${record.englishBrandName}` : record.brandIdName,
            ...record
          }
        })
      }

      this.setState({
        categoryList: categoryList,
        brandList: brandList
      }, () => {
        if (type === 2) {
          this.getCommoditySkuInfo()
        }
      })
    }))
  }

  // 获取SKU详情
  getCommoditySkuInfo = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getCommoditySkuInfo({ commoditySkuId: detailInfo.commoditySkuId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data || {}

      }, () => {
        const { info } = this.state
        if (info.classificationId) {
          this.handleChangeCagetoryItem([0, 0, info.classificationId])
        }

        if (info.brandId) {
          this.getSeriesList({ brandId: info.brandId, pageNo: 1, pageSize: 999 })
        }
      })
    }
  }

  // 获取系列列表
  getSeriesList = (params) => {
    Api.getSeriesList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          seriesList: data.list || []
        })
      }
    })
  }

  // 点击提交
  handleConfirm = () => {
    const { form, type = 1, detailInfo = {}, onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const { skuImageUrls = '', checkedskuImageUrls = '', skuDetailImageUrls = '', checkedskuDetailImageUrls = '', skuDetailPdfUrls = '', checkedskuDetailPdfUrls = '' } = values

        if (+type === 2) {
          values.id = detailInfo.commoditySkuId
        }

        values.classificationId = values.classificationIdList[2]

        if (skuImageUrls && skuImageUrls !== '') {
          const _skuImageArr = skuImageUrls.split(',')
          const checkedskuImageArr = checkedskuImageUrls !== '' ? checkedskuImageUrls.split(',') : []

          const _skuImageList = $lodash.map(_skuImageArr, (o, index) => {
            let obj = { imageUrl: o }
            const n = checkedskuImageArr.findIndex((item) => { return o === item })

            if (checkedskuImageArr.length === 0) {
              obj.mainType = index === 0 ? 'MAIN' : 'NOT_MAIN'
            } else {
              obj.mainType = n > -1 ? 'MAIN' : 'NOT_MAIN'
            }

            return obj
          })

          values.skuImageList = _skuImageList
        }

        if (skuDetailImageUrls && skuDetailImageUrls !== '') {
          const _skuDetailImageArr = skuDetailImageUrls.split(',')
          const checkedskuDetailImageArr = checkedskuDetailImageUrls !== '' ? checkedskuDetailImageUrls.split(',') : []

          const _skuDetailImageList = $lodash.map(_skuDetailImageArr, (o, index) => {
            let obj = { imageUrl: o }
            const j = checkedskuDetailImageArr.findIndex((item) => { return o === item })

            if (checkedskuDetailImageArr.length === 0) {
              obj.showStatus = index === 0 ? 'SHOW' : 'NOT_SHOW'
            } else {
              obj.showStatus = j > -1 ? 'SHOW' : 'NOT_SHOW'
            }

            return obj
          })

          values.skuDetailImageList = _skuDetailImageList
        }

        if (skuDetailPdfUrls && skuDetailPdfUrls !== '') {
          const _skuDetailPdfArr = skuDetailPdfUrls.split(',')
          const checkedDetailPdfArr = checkedskuDetailPdfUrls !== '' ? checkedskuDetailPdfUrls.split(',') : []

          const _skuDetailPdfList = $lodash.map(_skuDetailPdfArr, (o, index) => {
            let obj = { imageUrl: o }
            const k = checkedDetailPdfArr.findIndex((item) => { return o === item })

            if (checkedDetailPdfArr.length === 0) {
              obj.showStatus = index === 0 ? 'SHOW' : 'NOT_SHOW'
            } else {
              obj.showStatus = k > -1 ? 'SHOW' : 'NOT_SHOW'
            }

            return obj
          })

          values.skuDetailPdfList = _skuDetailPdfList
        }

        onConfirm(values)
      }
    })
  }

  /* 选择分类
   *
   */
  handleChangeCagetoryItem = (categoryIds = []) => {
    this.props.form.setFieldsValue({ classificationId: categoryIds[2] })
  }

  /* 选择品牌
   * 请求系列列表api 品牌系列联动
  */
  handleChangeBrand = (val) => {
    this.props.form.setFieldsValue({ seriesId: undefined })

    this.getSeriesList({ brandId: val, pageNo: 1, pageSize: 999 })
  }

  render () {
    const { form, type = 1, detailInfo = {}, onCancel } = this.props
    const { getFieldDecorator } = form
    const { categoryList = [], brandList = [], seriesList = [], info = {} } = this.state

    return (
      <>
        <Modal title={+type !== 2 ? '添加型号基础信息' : '编辑型号基础信息'} width={1000}
          bodyStyle={{ padding: '12px 16px', height: '500px', overflow: 'auto' }}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form layout='vertical'>
            <Divider>基础信息</Divider>
            <Row gutter={24}>
              {
                type === 3
                  ? <>
                    <Col span={8}>
                      <FormItem label='品牌' style={{ display: 'none' }}>
                        {
                          getFieldDecorator('brandId', {
                            initialValue: detailInfo.brandId
                          })(<InputNumber disabled autoComplete='off' />)
                        }
                      </FormItem>
                      <FormItem label='品牌'><div style={{ lineHeight: '32px' }}>{ detailInfo.brandName }</div></FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label='系列' style={{ display: 'none' }}>
                        {
                          getFieldDecorator('seriesId', {
                            initialValue: detailInfo.seriesId
                          })(<InputNumber disabled autoComplete='off' />)
                        }
                      </FormItem>
                      <FormItem label='系列'><div style={{ lineHeight: '32px' }}>{ detailInfo.seriesName }</div></FormItem>
                    </Col>
                  </>
                  : <>
                    <Col span={8}>
                      <FormItem label='品牌'>
                        {
                          getFieldDecorator('brandId', {
                            initialValue: info.brandId || '',
                            rules: [
                              { required: true, message: '请选择品牌' }
                            ]
                          })(
                            <Select placeholder='请选择...' showSearch optionFilterProp='children'
                              disabled={+type === 3}
                              onChange={this.handleChangeBrand}
                            >
                              {
                                brandList.map((item, i) => (
                                  <Option value={item.brandId} key={`brandOpt-${i}`}>
                                    {item.showName}
                                  </Option>
                                ))
                              }
                            </Select>
                          )
                        }
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label='系列'>
                        {
                          getFieldDecorator('seriesId', {
                            initialValue: info.seriesId || '',
                            rules: [
                              { required: false, message: '' }
                            ]
                          })(
                            <Select placeholder='请选择...' disabled={+type === 3}
                              showSearch
                              optionFilterProp='children'
                            >
                              {
                                seriesList.length > 0 && seriesList.map((sitem, idx) => (
                                  <Option value={sitem.id} key={`seriesOpt-${idx}`}>
                                    {sitem.name}
                                  </Option>
                                ))
                              }
                            </Select>
                          )
                        }
                      </FormItem>
                    </Col>
                  </>
              }

              <Col span={8}>
                <FormItem label='分类' style={{ display: 'none' }}>
                  {
                    getFieldDecorator('classificationId', {

                    })(<InputNumber disabled autoComplete='off' />)
                  }
                </FormItem>
                <FormItem label='分类'>
                  {
                    getFieldDecorator('classificationIdList', {
                      initialValue: info.classificationIdList || [],
                      rules: [
                        { required: true, message: '请选择分类' }
                      ]
                    })(
                      <Cascader placeholder='请选择...' showSearch
                        fieldNames={{ label: 'name', value: 'id', children: 'classifications' }}
                        options={categoryList}
                        onChange={(vals) => this.handleChangeCagetoryItem(vals)}
                      />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='型号'>
                  {
                    getFieldDecorator('model', {
                      initialValue: info.model || '',
                      rules: [
                        { required: true, message: '请输入型号' }
                      ]
                    })(
                      <Input autoComplete='off' placeholder='请输入...' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='订货号'>
                  {
                    getFieldDecorator('itemNumber', {
                      initialValue: info.itemNumber || '',
                      rules: [
                        { required: true, message: '请输入订货号' }
                      ]
                    })(
                      <Input autoComplete='off' placeholder='请输入...' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='产品名称'>
                  {
                    getFieldDecorator('name', {
                      initialValue: info.name || '',
                      rules: [
                        { required: true, message: '请输入产品名称' }
                      ]
                    })(
                      <Input autoComplete='off' placeholder='请输入...' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='表价'>
                  {
                    getFieldDecorator('price', {
                      initialValue: info.price || '',
                      rules: [
                        { required: false, message: '请输入表价' }
                      ]
                    })(
                      <Input autoComplete='off' placeholder='请输入...' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='最小包装'>
                  {
                    getFieldDecorator('minPackQuantity', {
                      initialValue: info.minPackQuantity || '',
                      rules: [
                        { required: false, message: '请输入最小包装' }
                      ]
                    })(
                      <Input autoComplete='off' placeholder='请输入...' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='最小订货量'>
                  {
                    getFieldDecorator('minOrder', {
                      initialValue: info.minOrder || '',
                      rules: [
                        { required: false, message: '请输入最小订货量' }
                      ]
                    })(
                      <InputNumber min={1}
                        style={{ width: '100%' }}
                        autoComplete='off' placeholder='请输入最小订货量'
                      />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='单位'>
                  {
                    getFieldDecorator('unit', {
                      initialValue: info.unit || '',
                      rules: [
                        { required: false, message: '请输入单位' }
                      ]
                    })(
                      <Input autoComplete='off' placeholder='请输入...' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={8}>
                <FormItem label='是否停产'>
                  {
                    getFieldDecorator('stopFlag', {
                      initialValue: info.stopFlag || 'ON_SALE'
                    })(
                      <RadioGroup>
                        {
                          skuStopFlagMaps.map((item, i) => (
                            <Radio value={item.id} key={`${item.id}-${i}`}>{ item.name }</Radio>
                          ))
                        }
                      </RadioGroup>
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <FormItem label='商品描述'>
              {
                getFieldDecorator('introduce', {
                  initialValue: info.introduce || ''
                })(
                  <TextArea rows={4} placeholder={`请输入内容...`} autoComplete='off' />
                )
              }
            </FormItem>

            {
              type !== 2
                ? <UploadImageItem form={form} hasSwitch={false} />
                : null
            }

          </Form>
        </Modal>
      </>
    )
  }
}

const SkuBaseModal = Form.create()(SkuBaseForm)

export default SkuBaseModal
