import React, { Component } from 'react'
import { Row, Card, Table, Button, Icon } from 'antd'
import Api from '@/common/api/index'
import SearchForm from '@/components/search-form/index'
import filter from '@/common/utils/filter'
import DownloadApi from '@/common/api/downloadApi'

const { pageSizeOptions } = filter
// 状态类型
const statusMaps = [
  { id: 0, name: '已过期' },
  { id: 1, name: '正常' },
  { id: 2, name: '过期不续费' }
]

export default class index extends Component {
  state = {
    searchData: [
      {
        name: 'name',
        formType: 'Input',
        itemParams: {
          label: '公司名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'domainName',
        formType: 'Input',
        itemParams: {
          label: '域名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '域名状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: '', name: '全部' },
          ...statusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'domainListCol-0',
      align: 'center',
      width: 44,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '公司名称',
      key: 'domainListCol-1',
      dataIndex: 'name'
    },
    {
      title: '域名',
      key: 'domainListCol-2',
      dataIndex: 'domainName'
    },
    {
      title: '证书剩余时间(天)',
      key: 'domainListCol-3',
      dataIndex: 'remainDays',
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: record.remainDays <= 3 ? '#FF0000' : '#008000'
            }}
          >
            {record.remainDays}
          </div>
        )
      }
    },
    {
      title: '状态',
      key: 'domainListCol-4',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{this.renderStatusLabel(record.status)}</>
      )
    }
  ];

  componentDidMount () {
    this.getAlertList()
  }

  // 获取域名列表
  getAlertList = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getAlertList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 渲染状态名称
  renderStatusLabel = (status) => {
    const obj = statusMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? obj.name : ''

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getAlertList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getAlertList()
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getAlertList()
    })
  }

   // 点击下载
   handleDownload = () => {
     const { query } = this.state
     let params = {
       ...query,
       pageNo: 1,
       pageSize: 99999
     }
     DownloadApi.downloadDomainAlertSevenList(params)
   }

   render () {
     const { searchData, pages, tableData = [] } = this.state

     const pagination = {
       showSizeChanger: true,
       total: pages.total,
       showTotal: (total) => { return `共 ${total} 条` },
       current: pages.pageNo,
       pageSize: pages.pageSize,
       pageSizeOptions: pageSizeOptions,
       onChange: this.pageChange,
       onShowSizeChange: this.pageSizeChange
     }

     return (
       <div>
         <Row style={{ marginBottom: '20px' }}>
           <Card bodyStyle={{ padding: '12px' }}>
             <SearchForm
               {...this.props}
               dataSource={searchData}
               handleSubmit={(obj) => this.handleSearch(obj)}
             />
           </Card>
         </Row>
         <Card bodyStyle={{ padding: '12px' }}>
           <Row style={{ marginBottom: '12px' }}>
             <Button type='primary' disabled={tableData.length === 0} onClick={() => this.handleDownload()}>
               <Icon type='download' />导出域名列表
             </Button>
           </Row>
           <Table rowKey='id' bordered
             columns={this.columns}
             dataSource={tableData}
             pagination={pagination}
           />
         </Card>
       </div>
     )
   }
}
