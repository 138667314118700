import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderBrandTypeLabel } from '@/common/utils/mUtils'

import styles from './museum.module.scss'

const { pageSizeOptions } = filter

class BrandMuseum extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'brand-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌LOGO',
      key: 'brand-1',
      width: 80,
      render: (text, record, index) => (
        <div className={`${styles.avatar}`}>
          {
            record.brandLogo && record.brandLogo !== ''
              ? <img className={`${styles.pic}`} src={record.brandLogo} />
              : <div>
              暂无图片
              </div>
          }

        </div>
      )
    },
    {
      title: '品牌名称',
      key: 'brand-2',
      render: (text, record, index) => (
        <div>
          <div className={`brandLabel nameCn`}>{record.name}</div>
          <div className={`brandLabel nameEn`}>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '品牌类型',
      key: 'brand-3',
      width: 70,
      render: (text, record, index) => (
        <>{ renderBrandTypeLabel(record.type) }</>
      )
    },
    {
      title: '品牌描述',
      key: 'brand-4',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <div style={{ width: '160px' }} className={`ellipsis`}
          title={record.desc}
        >
          {record.desc}
        </div>
      )
    },
    {
      title: '操作',
      key: 'brand-5',
      width: 250,
      render: (text, record, index) => {
        return (
          <div>
            <a onClick={() => this.handleJumpToBrandmuseDetail(record)}>品牌馆</a>
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getBrandList()
  }

  // 获取品牌列表
  getBrandList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getBrandList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 跳转到品牌馆详情
  handleJumpToBrandmuseDetail = (record) => {
    this.props.history.push({ pathname: '/admin/brandmuseDetail', state: { detailInfo: record } })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getBrandList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getBrandList()
    })
  }

  render () {
    const { pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row>
            <Table
              rowKey='id' bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Row>
        </Card>
      </div>
    )
  }
}

export default BrandMuseum
