import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Form, Input, Button, message, Modal } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item
const { TextArea } = Input
const { confirm } = Modal
const notifyToMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'EU', name: 'EU' },
  { id: 'MERCHANT', name: '商户' },
  { id: 'SUPPLIER', name: '供应商' },
  { id: 'IIASAAS', name: 'IIASaaS' }
]

class EditsysForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    type: 1,
    detailInfo: {}
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    this.setState({
      type: state.type || 1,
      detailInfo: state.detailInfo || {}
    })
  }

  // 保存系统通知
  saveSystemNotifyContent = (params) => {
    Api.saveSystemNotifyContent(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.props.history.push({ pathname: '/admin/systemNotifys' })
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击保存
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.state
        values['type'] = detailInfo.type
        values['id'] = detailInfo.id

        this.saveSystemNotifyContent(values)
      }
    })
  }

  // 点击取消
  handleCancel = () => {
    confirm({
      title: '是否保存已经编辑的信息',
      okText: '是',
      cancelText: '否',
      onOk: () => {
        this.handleSubmit()
      },
      onCancel: () => {
        this.props.history.push({ pathname: '/admin/systemNotifys' })
      }
    })
  }

  // 渲染通知对象名称
  renderNotifyToObjectLabel = (status) => {
    let str = ''
    let obj = notifyToMaps.find(item => {
      return item.id === status
    })

    if (obj) {
      str = obj.name
    }

    return str
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo = {} } = this.state

    const formItemLayout = {
      labelCol: { xs: { span: 24 }, sm: { span: 7 } },
      wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 10 } }
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '12px' }}>
          <Row>
            <Form>
              <Row>
                <FormItem label='通知标题' {...formItemLayout}>
                  {
                    getFieldDecorator('title', {
                      initialValue: detailInfo.title || '',
                      rules: [
                        { required: true, message: '请填写标题' }
                      ]
                    })(
                      <Input autoComplete='off' />
                    )
                  }
                </FormItem>
              </Row>
              <Row>
                <FormItem label='通知内容' {...formItemLayout}>
                  {
                    getFieldDecorator('content', {
                      initialValue: detailInfo.content || '',
                      rules: [
                        { required: true, message: '请填写内容' }
                      ]
                    })(
                      <TextArea autoComplete='off' rows={4} />
                    )
                  }
                </FormItem>
              </Row>
              <Row>
                <FormItem label='通知条件' {...formItemLayout}>
                  <p style={{ marginBottom: '0px' }}>{detailInfo.remarks}</p>
                </FormItem>
              </Row>
              <Row>
                <FormItem label='通知对象' {...formItemLayout}>
                  {
                    detailInfo.notifyObject && detailInfo.notifyObject !== ''
                      ? <div>{this.renderNotifyToObjectLabel(detailInfo.notifyObject)}</div>
                      : null
                  }
                </FormItem>
              </Row>
              <FormItem>
                <Row style={{ textAlign: 'center' }}>
                  <Button onClick={() => this.handleCancel()}>取消</Button>
                  <Button type='primary' style={{ marginLeft: '20px' }}
                    onClick={() => this.handleSubmit()}
                  >保存</Button>
                </Row>
              </FormItem>
            </Form>
          </Row>
        </Card>
      </div>
    )
  }
}

const EditsystemNotify = Form.create()(EditsysForm)

export default EditsystemNotify
