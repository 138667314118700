import React, { Component } from 'react'
import { Card, Row, Table, Button, Icon, Modal } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import GoodsNameGoods from './goodsNameModal'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { getCourierName } from '@/common/utils/mUtils'
import SAASCourierJson from '@/assets/json/saasCourier.json'

const { downInsuranceFlowDetail } = DownloadApi
const { insuranceTypeMaps, pageSizeOptions } = filter
const { warning } = Modal

export default class InsuranceSlip extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '保险时间'
        }
      },
      {
        name: 'mailNo',
        formType: 'Input',
        itemParams: {
          label: '运单号'
        },
        cptParams: {
          placeholder: '请输入运单号...'
        }
      },
      {
        name: 'courierCode',
        formType: 'Select',
        itemParams: {
          label: '快递公司'
        },
        cptParams: {
          placeholder: '请选择快递公司...'
        },
        options: [
          { aliasCode: 'ALL', aliasName: '全部' },
          ...SAASCourierJson
        ],
        optionValue: ['aliasCode', 'aliasName']
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '服务类型'
        },
        cptParams: {
          placeholder: '请选择服务类型...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...insuranceTypeMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {}, // 检索条件
    tableData: [],
    ModelFaly: false,
    rowDetails: {}
  }
  columns= [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '商户名称',
      key: 'service-1',
      width: 200,
      dataIndex: 'distributorName'
    },
    {
      title: '真实姓名',
      key: 'service-2',
      width: 100,
      dataIndex: 'adminUser'
    },
    {
      title: '发货日期',
      key: 'service-3',
      width: 180,
      render: (text, record, index) => {
        return (
          record.signedStatus === 'SIGNED' && record.receiverTime
            ? <> {moment(record.senderTime).format('YYYY-MM-DD HH:mm:ss')}</>
            : '----'

        )
      }
    },
    {
      title: '收货日期',
      key: 'service-4',
      width: 180,
      render: (text, record, index) => (
        <>{ record.receiverTime ? moment(record.receiverTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '运单号',
      key: 'service-5',
      width: 150,
      render: (text, record, index) => (
        <div>
          { record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----' }
        </div>
      )
    },
    {
      title: '发货地址',
      key: 'service-6',
      width: 200,
      render: (text, record, index) => {
        return (
          <div>
            {record.senderProvince} {record.senderCity} {record.senderCounty} {record.senderAddress}
          </div>
        )
      }
    },
    {
      title: '收货地址',
      key: 'service-7',
      width: 200,
      render: (text, record, index) => {
        return (
          <div>
            {record.receiverProvince} {record.receiverCity} {record.receiverCounty} {record.receiverAddress}
          </div>
        )
      }
    },
    {
      title: '产品类型',
      key: 'service-8',
      width: 100,
      // dataIndex: 'cargoName',
      render: (text, record, index) => (
        <>工业品</>
      )
    },
    {
      title: '货物名称',
      key: 'service-9',
      width: 100,
      render: (text, record, index) => (
        <a onClick={() => { this.onModalOpen(record) }}>点击查看</a>
      )
    },
    {
      title: '发货人',
      key: 'service-10',
      width: 100,
      dataIndex: 'senderName'
    },
    {
      title: '收货人',
      key: 'service-11',
      width: 100,
      dataIndex: 'receiverName'
    },
    {
      title: '件数',
      key: 'service-12',
      width: 50,
      dataIndex: 'totalNumber'
    },
    {
      title: '包装方式',
      key: 'service-13',
      width: 100,
      dataIndex: 'packageService'
    },
    {
      title: '重量(KG)',
      key: 'service-14',
      width: 90,
      dataIndex: 'totalWeight'
    },
    {
      title: '体积',
      key: 'service-15',
      width: 50,
      dataIndex: 'totalVolume'
    },
    {
      title: '快递公司',
      key: 'service-16',
      width: 80,
      dataIndex: 'courierCode',
      render: (text, record, index) => (
        <>{ getCourierName(record.courierCode) }</>
      )
    },
    {
      title: '货物金额',
      key: 'service-17',
      width: 80,
      render: (text, record, index) => {
        return (
          <div>&yen;{(+record.goodsAmount * 100 / 100).toFixed(2)}</div>
        )
      }
    },
    {
      title: '保险金额',
      key: 'service-18',
      width: 80,
      dataIndex: 'insureAmount'
    },
    {
      title: '服务类型',
      key: 'service-19',
      width: 80,
      render: (text, record, index) => {
        return (
          <div>{ this.renderInsuranceServerTypeLabel(record.type) }</div>
        )
      }
    },
    {
      title: '保险费率(‰)',
      key: 'service-20',
      width: 110,
      render: (text, record, index) => {
        return (
          <div>{ (+record.rate * 1000 * 1000 / 1000).toFixed(2) }</div>
        )
      }
    },
    {
      title: '保险时间',
      key: 'service-21',
      width: 180,
      render: (text, record, index) => {
        return (
          <>
            { record.insureTime ? moment(record.insureTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.setInsuranceslipList()
  }

  // 获取保险单流水记录
  setInsuranceslipList=() => {
    const { pages, query = {} } = this.state

    let parame = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.setInsuranceslipList(parame).then(res => {
      const { data, code } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }
    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)
        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search['beginDate'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.times[1]).format('YYYY-MM-DD')
      delete search.times
    }
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.setInsuranceslipList()
    })
  }

  onModalOpen = (record) => {
    this.setState({
      ModelFaly: true,
      rowDetails: record
    })
  }

  // 关闭物品详情页面
  onModalClose = () => {
    this.setState({
      ModelFaly: false,
      rowDetails: {}
    })
  }

  // 渲染保险服务类型名称
  renderInsuranceServerTypeLabel = (status) => {
    const obj = insuranceTypeMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? obj.name : ''

    return label
  }

  // 保险流水单单导出明细
  handleDownloadFlowDetail = (id) => {
    const { query } = this.state
    if (query.query) {
      query.beginDate = query.query[0].format('YYYY-MM-DD')
      query.endDate = query.query[1].format('YYYY-MM-DD')
    }

    if (!query.beginDate) {
      warning({
        icon: <Icon type='exclamation-circle' style={{ color: '#FF3434' }} />,
        title: <span style={{ color: '#FF3434' }}>请选择您想导出的时间段</span>,
        content: <span>请先选择要导出的时间段<br />点击搜索</span>
      })

      return false
    }

    downInsuranceFlowDetail({ beginDate: query.beginDate, endDate: query.endDate })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.setInsuranceslipList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.setInsuranceslipList()
    })
  }

  render () {
    const { searchData = [], pages, tableData = [], ModelFaly, rowDetails = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '12px' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Button type='primary' onClick={() => this.handleDownloadFlowDetail()}><Icon type='download' />导出明细</Button>
          </Row>
          <Row>
            <Table rowKey={(record, i) => `${record.pickingId}-${i}`} bordered
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
              scroll={{ x: 1270 }}
            />
          </Row>
        </Card>

        {
          ModelFaly
            ? <GoodsNameGoods
              onModalClose={this.onModalClose}
              rowDetails={rowDetails}
            />
            : null
        }
      </>
    )
  }
}
