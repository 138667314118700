import React, { Component } from 'react'
import { Card, Table, message, Tooltip, Button, Pagination } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import CloudStatistic from '../component/cloudStatistic/index'
import DetailsModal from './detailsModal/index'
import OccupyModal from '../component/occupyModal'
import RecommendModal from './recommendModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

const { repLineBreak, skuGoodsTypeMaps } = filter
const { pageSizeOptions } = consts
const skuGoodsTypeOptions = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

// 云仓产品列表
export default class CloudProd extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (value) => this.handleChangeBrandId(value)
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...skuGoodsTypeOptions
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    query: {},
    selectedBrandId: 0,
    detailModal: false,
    occupyModal: false, // 订单库存明细弹窗
    recommendModal: false // 推荐设置弹窗
  }

  columns = [
    {
      title: '序号',
      key: 'onlineProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '分类',
      key: 'onlineProductCol-1',
      ellipsis: true,
      dataIndex: 'classificationName',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.classificationName}>
            <span>{ record.classificationName }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '品牌',
      key: 'onlineProductCol-2',
      dataIndex: 'brandName',
      render: (text, record, index) => (
        <>
          <p><label className='label'>中</label>{ record.brandName }</p>
          <p><label className='label'>英</label>{ record.englishBrandName }</p>
        </>
      )
    },
    {
      title: '系列',
      key: 'onlineProductCol-3',
      width: 120,
      ellipsis: true,
      dataIndex: 'series',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.series}>
            <span>{ record.series }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '型号',
      key: 'onlineProductCol-4',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}>
            <span>{ record.model }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'onlineProductCol-5',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}>
            <span>{ record.itemNumber }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'onlineProductCol-6',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}>
            <span>{ record.name }</span>
          </Tooltip>
        </>
      )
    },
    {
      title: '产品类型',
      key: 'onlineProductCol-7',
      width: 80,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '表价',
      key: 'onlineProductCol-8',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '云仓价格',
      key: 'onlineProductCol-9',
      dataIndex: 'cloudSupplyPrice',
      render: (text, record, index) => (
        <>&yen;{+record.cloudSupplyPrice}</>
      )
    },
    {
      title: '库存数量',
      key: 'onlineProductCol-10',
      dataIndex: 'cloudStockTotal',
      render: (text, record, index) => (
        <>{ record.skuGoodsType === 'CLOUD_FUTURES' ? <span style={{ fontSize: '16px' }}>&infin;</span> : +record.cloudStockTotal }</>
      )
    },
    {
      title: '订单库存',
      key: 'onlineProductCol-11',
      dataIndex: 'occupyStockTotal',
      render: (text, record, index) => (
        <>
          {
            +record.occupyStockTotal > 0
              ? <Tooltip title='点击可查看订单库存明细' placement='top'>
                <a onClick={() => this.handleShowOccupyModal(record)}>{+record.occupyStockTotal}</a>
              </Tooltip>
              : <>----</>
          }
        </>
      )
    },
    {
      title: '参考价格',
      children: [
        {
          title: '参考价格',
          key: 'onlineProductCol-12',
          render: (text, record, index) => {
            const { commoditySkuId, gbbMap = {}, gkwMap = {}, gphMap = {} } = record
            const newdatas = [gbbMap, gkwMap, gphMap]

            return <>
              {
                newdatas.map((item, i) => (
                  <p style={{ fontSize: '14px', color: '#333333' }} key={`onlineProductCol-12-${commoditySkuId}-${index}-${i}`}>
                    &yen;{+item.price}
                  </p>
                ))
              }

            </>
          }
        },
        {
          title: '参考库存',
          key: 'onlineProductCol-13',
          render: (text, record, index) => {
            const { commoditySkuId, gbbMap = {}, gkwMap = {}, gphMap = {} } = record
            const newdatas = [gbbMap, gkwMap, gphMap]

            return <>
              {
                newdatas.map((item, i) => (
                  <p style={{ fontSize: '14px', color: '#333333' }} key={`onlineProductCol-13-${commoditySkuId}-${index}-${i}`}>
                    {+item.stock}
                  </p>
                ))
              }

            </>
          }
        }
      ]
    },
    {
      title: '操作',
      key: 'onlineProductCol-14',
      width: 140,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showDetailModal(record)}>
            查看详情
          </Button>
          {/* <Button type='link' size='small' onClick={() => this.showRecommendModal(record)}>
            推荐设置
          </Button> */}
        </>
      )
    }
  ]

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
    this.getCloudOnlineSkuList()
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 获取云仓产品列表
  getCloudOnlineSkuList = () => {
    const { pages, query } = this.state

    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query
    }

    Api.getCloudOnlineSkuList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 获取云仓产品明细
  getMerchantInfoByOnlineSkudId = (params) => {
    return Api.getMerchantInfoByOnlineSkudId(params)
  }

  // 保存推荐设置
  saveRecommendProductSetting = (params) => {
    Api.saveRecommendProductSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.itemNo) {
      search['itemNo'] = repLineBreak(search.itemNo, '<br>')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}

    }, () => {
      this.getCloudOnlineSkuList()
    })
  }

  handleChangeBrandId = (brandId) => {
    this.setState({
      selectedBrandId: brandId
    })
  }

  // 展示弹窗
  showDetailModal = (row = {}) => {
    this.setState({
      detailModal: true,
      rowDetail: row || {}
    })
  }

  // 推荐设置弹窗
  showRecommendModal = (record = {}) => {
    this.setState({
      recommendModal: true,
      rowDetail: record
    })
  }

  // 点击查看占用库存明细
  handleShowOccupyModal = (record) => {
    this.setState({
      rowDetail: record || {},
      occupyModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      detailModal: false,
      occupyModal: false,
      recommendModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudOnlineSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudOnlineSkuList()
    })
  }

  render () {
    const { pages, searchData, tableData, detailModal = false, occupyModal = false, recommendModal = false, rowDetail = {}, selectedBrandId = 0 } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <CloudStatistic brandId={selectedBrandId} cloudType={'CLOUD_SKU'} />
          </div>

          <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
          <div style={{ margin: '16px 0px', textAlign: 'right' }}>
            <Pagination {...pagination} />
          </div>
        </Card>

        {
          detailModal
            ? <DetailsModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getMerchantInfoByOnlineSkudId(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          occupyModal
            ? <OccupyModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          recommendModal
            ? <RecommendModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.saveRecommendProductSetting(params)}
            />
            : null
        }
      </>
    )
  }
}
