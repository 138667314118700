import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Select } from 'antd'

import Api from '@/common/api/index'

import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option

export default function WorkContents (props) {
  const { form, isPreview, workOrderType, info = {}, distributorName = '' } = props

  const [customerList, setCustomerList] = useState([])

  // 模糊搜索-获取商家列表
  const getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        setCustomerList(data)
      }
    })
  }

  return (
    <>
      <Row className={`${styles.box}`}>
        <Col span={12}>
          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='品牌' style={{ marginBottom: '0px' }}>{ info.brandName }</FormItem>
          </div>
          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='型号' style={{ marginBottom: '0px' }}>{ info.model }</FormItem>
          </div>
          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='订货号' style={{ marginBottom: '0px' }}>{ info.itemNumber }</FormItem>
          </div>
          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='产品名称' style={{ marginBottom: '0px' }}>{ info.name }</FormItem>
          </div>
          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='单位' style={{ marginBottom: '0px' }}>{ info.unit }</FormItem>
          </div>
        </Col>
        <Col span={12}>
          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='期望价格' style={{ marginBottom: '0px' }}><span>&yen;{ +info.expectPrice }</span></FormItem>
          </div>

          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='期望折扣' style={{ marginBottom: '0px' }}><span>{(+info.expectDiscount * 100 / 100)}%</span></FormItem>
          </div>

          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='数量' style={{ marginBottom: '0px' }}><span>{ info.quantity }</span></FormItem>
          </div>

          <div className={`${styles.formitemRow} ${styles.row}`}>
            <FormItem label='期望货期' style={{ marginBottom: '0px' }}>
              {
                +info.expectDeliveryPeriodDays > 0 ? <span>{info.expectDeliveryPeriodDays}天</span> : <span>现货</span>
              }
            </FormItem>
          </div>

          <div className={`${styles.formitemRow} ${styles.row}`}>
            {
              !isPreview && workOrderType === 'BATCH_GENERATE'
                ? <FormItem label='供应商户' style={{ marginBottom: '0px' }}>
                  {
                    form.getFieldDecorator('distributorId', {
                      rules: [
                        { required: true }
                      ]
                    })(
                      <Select showSearch filterOption={false} style={{ width: '100%' }}
                        onSearch={(val) => getMerchantListByKeyword(val)}
                      >
                        {
                          customerList.map((item, index) => (
                            <Option value={item.distributorId} key={`customerOption-${item.id}`}>{item.distributorName}</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
                : <FormItem label='供应商户' style={{ marginBottom: '0px' }}><span>{ distributorName }</span></FormItem>
            }
          </div>
        </Col>
      </Row>
    </>
  )
}

WorkContents.propTypes = {
  form: PropTypes.object,
  workOrderType: PropTypes.string,
  info: PropTypes.object,
  distributorName: PropTypes.string,
  isPreview: PropTypes.bool
}
