import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class ErpoperationForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title='关联ERP' visible width={520} bodyStyle={{ paddingBottom: '0px' }}
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <FormItem label='ERP账套名称' {...formItemLayout}>
              {
                getFieldDecorator('erpUsername', {
                  initialValue: detailInfo.erpUsername,
                  rules: [
                    { required: true, message: '请输入ERP账套名称' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem style={{ display: 'none' }}>
              {
                getFieldDecorator('distributorId', {
                  initialValue: detailInfo.distributorId,
                  rules: []
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const ErpoperationModal = Form.create()(ErpoperationForm)

export default ErpoperationModal
