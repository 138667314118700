import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Input, Row, Col, Divider, Button, Radio, Select, Cascader, Upload, Icon, message, Modal, DatePicker } from 'antd'
import moment from 'moment'
import JSEncrypt from 'jsencrypt'
import $lodash from 'lodash'
import PublickeyJson from '@/assets/json/publicKey.json'
import RegionJson from '@/assets/json/region.json'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'

import { getStore, removeStore, domainname, addressToTree } from '@/common/utils/mUtils'

import styles from './customer.module.scss'

const FormItem = Form.Item
const { TextArea, Password } = Input
const RadioGroup = Radio.Group
const Option = Select.Option

const { uploadOrdinaryImageUrl } = UploadApi
const { confirm } = Modal

const authorMap = [
  { type: 'AGENT', label: '代理商' },
  { type: 'SUPPLY', label: '供应商' },
  { type: 'MERCHANT', label: '商户' }
]
const cityOptions = addressToTree()

class CustomerForm extends Component {
  constructor (props) {
    super(props)
    this.getCompanyInfoByKeywords = $lodash.debounce(this.getCompanyInfoByKeywords, 300)
  }

  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    type: 1,
    detailInfo: {},
    removeMap: {
      'licenseFile': false,
      'legalCardInfo': false,
      'legalCardEmblem': false
    },
    setAdmin: 0,
    legalinfo: {},
    businessLicenseUrl: '', // 营业执照
    merchantList: [],
    statisticsMerchantList: [] // 统计名称待选项
  }

  componentDidMount () {
    const { location } = this.props.history
    const { state } = location

    if (state && state.pageType && state.pageType === 2) {
      this.setState({
        detailInfo: state,
        type: state.pageType,
        setAdmin: state.isAdmin || 0,
        businessLicenseUrl: state.businessLicenseUrl || '' // 营业执照
      })
    }

    document.addEventListener('click', (e) => {
      this.setState({
        merchantList: []
      })
    })
  }

  // 保存客户信息
  saveCustomer = (params) => {
    Api.saveCustomer(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '操作成功',
          onOk: () => { this.handleBack() }
        })
      }
    })
  }

  // 编辑-重置密码
  resetPassword = (params) => {
    Api.resetPassword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 点击保存
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { type = 1, detailInfo = {}, setAdmin = 0, businessLicenseUrl = '' } = this.state
        const { statisticsMerchantName } = values

        if (type === 2) {
          values.distributorId = detailInfo.distributorId
        } else {
          let { password } = values
          let jsencrypt = new JSEncrypt()
          jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
          values['password'] = jsencrypt.encrypt(password)
        }

        if (values.citys && values.citys.length > 0) {
          let { citys } = values
          if (citys.length === 2) {
            values.province = citys[0]
            values.city = citys[0]
            values.county = citys[1]
          }

          if (citys.length === 3) {
            values.province = citys[0]
            values.city = citys[1]
            values.county = citys[2]
          }
        }

        if (businessLicenseUrl === '') {
          return message.warning('请上传营业执照图片')
        }

        if (values.times) {
          values['recordDate'] = moment(values.times).format('YYYY-MM-DD')
          delete values.times
        }

        values.businessLicenseUrl = businessLicenseUrl || ''
        values.isAdmin = setAdmin
        values.statisticsMerchantName = statisticsMerchantName.split('-')[0]
        delete values.citys

        this.saveCustomer(values)
      }
    })
  }

  // 点击重置密码
  handleResetPassword = () => {
    confirm({
      title: '确定重置密码？',
      content: '',
      onOk: () => {
        const { detailInfo } = this.state
        let params = {
          distributorId: detailInfo.distributorId
        }

        this.resetPassword(params)
      }
    })
  }

  // 点击重置
  handleReset = () => {
    this.props.form.resetFields()
    this.setState({
      licenseFile: '',
      LegalIdCardInfo: [],
      LegalIdCardEmblem: [],
      setAdmin: 0
    })
    this.props.history.goBack()
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  // 切换设置管理员身份
  onChangeSetAdmin = (e) => {
    let legalinfo = {}
    if (+e.target.value === 1) {
      this.props.form.validateFields(['legalUser', 'legalIdCard'], (err, values) => {
        if (!err) {
          legalinfo = values
        }
      })
    }
    this.setState({
      setAdmin: e.target.value,
      legalinfo
    })
  }

  // 上传营业执照
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data, message } = info.file.response
      if (+code === 10000) {
        this.setState({
          businessLicenseUrl: data.imageUrl || ''
        })
      } else if (code === 'PLATFORM_99060') {
        removeStore('token')
        this.props.history.push('/login')
      } else {
        message.error(message)
      }
    }
  }

  // 删除营业执照
  removeUploadLicense = (file) => {
    this.setState({
      businessLicenseUrl: ''
    })
  }

  // 设置省市区级联回显
  renderCascaderDefaultValue = () => {
    const { detailInfo } = this.state
    let arr = []

    if (+detailInfo.province > 0 && +detailInfo.city > 0) {
      if (+detailInfo.province === +detailInfo.city) {
        arr.push(`${detailInfo.province}`, `${detailInfo.county}`)
      } else {
        arr.push(`${detailInfo.province}`, `${detailInfo.city}`, `${detailInfo.county}`)
      }
    }

    return arr
  }

  // 文本框获取焦点
  getonFocus = (value) => {
    this.getCompanyInfoByKeywords(value)
  }

  // 商户名称查询
  getCompanyInfoByKeywords = (value, key) => {
    Api.getCompanyInfoByKeywords({ keyword: value, pageNo: 1, pageSize: 10 }).then((res) => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          [`${key}List`]: data || []
        })
      } else {
        return message.error(res.message)
      }
    })
  }

  // 获取企业信息
  getMerchantInfo = (record) => {
    Api.getCompanyInfoByName({ keyword: record.name, pageNo: 1, pageSize: 10 }).then((res) => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          merchantList: []
        })

        if (data && data.econKindCode) {
          setTimeout(() => {
            this.props.form.setFieldsValue({
              merchantName: data.name,
              merchantCreditNo: data.creditNo,
              address: data.address,
              citys: [`${data.province}`, `${data.city}`, `${data.county}`]
            })
          }, 20)
        }
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { setAdmin = 0, legalinfo = {}, businessLicenseUrl = '', detailInfo = {}, type = 1, merchantList = [] } = this.state
    // statisticsMerchantList = []

    return (
      <>
        <Card title='添加客户'>
          <Row style={{ marginBottom: '20px' }}>
            <Button onClick={() => this.handleBack()}>
              <Icon type='left' />返回列表
            </Button>
          </Row>
          <Form>
            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户基本信息</span>
            </Divider>
            <Row gutter={24} >
              <Col span={10}>
                <Row className={styles.merchant}>
                  <FormItem label='商户名称'>
                    {
                      getFieldDecorator('merchantName', {
                        initialValue: detailInfo.name || '',
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input placeholder='营业执照上的公司名称' autoComplete='off'
                          onChange={(e) => this.getCompanyInfoByKeywords(e.target.value, 'merchant')}
                          onFocus={(e) => { this.getonFocus(e.target.value) }}
                        />
                      )
                    }
                  </FormItem>

                  {
                    merchantList.length > 0
                      ? <Row className={styles.infolist}>
                        <ul >
                          {
                            merchantList.map((value, index) => (
                              <li key={index} onClick={() => { this.getMerchantInfo(value) }}>{value.name}</li>
                            ))
                          }
                        </ul>
                      </Row> : null
                  }
                </Row>
              </Col>

              <Col span={10}>
                <FormItem label='商户简称'>
                  {
                    getFieldDecorator('shortName', {
                      initialValue: detailInfo.shortName || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入商户简称' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='管理员登录名'>
                  {
                    getFieldDecorator('loginName', {
                      initialValue: detailInfo.loginName || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='以字母开头,2-20位字母或数字' autoComplete='off'
                        disabled={+type === 2}
                      />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户所属区域'>
                  {
                    getFieldDecorator('regionCode', {
                      initialValue: detailInfo.regionCode,
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Select placeholder='请选择商户所属区域'>
                        {
                          RegionJson.map((region, i) => (
                            <Option value={region.code} key={region.code}>{ region.name }</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                {
                  type === 1
                    ? <FormItem label='管理员登录密码'>
                      {
                        getFieldDecorator('password', {
                          initialValue: detailInfo.password || '',
                          rules: [
                            { required: true, message: '该项必填' }
                          ]
                        })(
                          <Password placeholder='6-18位大小写字母或数字' autoComplete='off' />
                        )
                      }
                    </FormItem>
                    : <FormItem label='管理员登录密码'>
                      <Button type='primary' onClick={() => this.handleResetPassword()}>重置密码</Button>
                    </FormItem>
                }
              </Col>

              <Col span={10}>
                <FormItem label='统计名称'>
                  {
                    getFieldDecorator('statisticsMerchantName', {
                      initialValue: detailInfo.statisticsMerchantName,
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='营业执照上的公司名称' />
                      // <Select placeholder='营业执照上的公司名称' showSearch filterOption={false}
                      //   defaultActiveFirstOption={false}
                      //   showArrow={false}
                      //   notFoundContent={null}
                      //   onSearch={(value) => this.getCompanyInfoByKeywords(value, 'statisticsMerchant')}
                      // >
                      //   {
                      //     statisticsMerchantList.length > 0 && statisticsMerchantList.map((item, index) => (
                      //       <Option value={`${item.name}-${item.creditNo}`} key={`${item.name}-${item.creditNo}`}>{ item.name }</Option>
                      //     ))
                      //   }
                      // </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户工商注册信息</span>
            </Divider>
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='工商营业执照注册号/统一社会信用代码'>
                  {
                    getFieldDecorator('merchantCreditNo', {
                      initialValue: detailInfo.creditNo || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='15位营业执照注册号或18位统一社会信用代码' />
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='商户所在地区'>
                  {
                    getFieldDecorator('citys', {
                      initialValue: type === 2 ? this.renderCascaderDefaultValue() : [],
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Cascader placeholder='请选择省市区'
                        fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                        options={cityOptions}
                      />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label='商户详细地址'>
                  {
                    getFieldDecorator('address', {
                      initialValue: detailInfo.address || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <TextArea rows={4} placeholder='公司注册详细地址' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户开票电话'>
                  {
                    getFieldDecorator('mobile', {
                      initialValue: detailInfo.mobile || '',
                      rules: [
                        { required: true, message: '请填写商户开票电话' }
                      ]
                    })(
                      <Input placeholder='请输入商户电话' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <div style={{ lineHeight: '40px' }}>
                  <label className={`${styles.formLabel} ${styles.required}`}>营业执照电子版</label>
                </div>
                {
                  businessLicenseUrl && businessLicenseUrl !== ''
                    ? <div className={styles.thumbWrap}>
                      <div className={styles.thumbInfo}>
                        <img src={`${businessLicenseUrl}`} />

                        <a className={styles.prewModal}>
                          <Icon type='delete' style={{ fontSize: '20px' }}
                            onClick={() => this.removeUploadLicense()}
                          />
                        </a>
                      </div>
                    </div>
                    : <Upload
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      onChange={(info) => this.handleChangeUpload(info)}
                      onRemove={this.removeUploadLicense}
                    >
                      <div>
                        <Icon type='plus' />
                      </div>
                    </Upload>
                }

              </Col>
            </Row>

            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户开户信息</span>
            </Divider>
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='发票抬头(公司名称)'>
                  {
                    getFieldDecorator('invoiceTitle', {
                      initialValue: detailInfo.invoiceTitle || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='基本存款账户企业名称' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='开户银行'>
                  {
                    getFieldDecorator('accountBank', {
                      initialValue: detailInfo.accountBank || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='基本存款账户开户银行' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label='银行账号'>
                  {
                    getFieldDecorator('account', {
                      initialValue: detailInfo.account || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='基本存款账户账号' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label='银行联行号'>
                  {
                    getFieldDecorator('bankNo', {
                      initialValue: detailInfo.bankNo || ''
                    })(
                      <Input placeholder='基本银行联行号' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户法人信息</span>
            </Divider>
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='法人身份证姓名'>
                  {
                    getFieldDecorator('legalUser', {
                      initialValue: detailInfo.legalUser || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>

              </Col>
            </Row>

            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户管理员信息</span>
            </Divider>

            <Row style={{ marginBottom: '24px' }}>
              <div style={{ lineHeight: '40px' }}>
                <label style={{ color: 'rgba(0, 0, 0, 0.85)' }}>管理员身份：</label>
              </div>

              <div>
                <RadioGroup
                  value={setAdmin}
                  onChange={(e) => this.onChangeSetAdmin(e)}
                >
                  <Radio value={1}>设置法人为管理员</Radio>
                  <Radio value={0}>设置其他人为管理员</Radio>
                </RadioGroup>
              </div>
            </Row>
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='管理员身份证姓名'>
                  {
                    getFieldDecorator('adminUser', {
                      initialValue: type === 1 ? (setAdmin === 1 ? legalinfo.legalUser || '' : '') : detailInfo.adminUser || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off'
                        disabled={setAdmin === 1}
                      />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='管理员手机号'>
                  {
                    getFieldDecorator('adminMobile', {
                      initialValue: detailInfo.adminMobile || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ],
                      getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Divider dashed orientation='left'>
              <span style={{ color: '#1890ff' }}>商户其他信息</span>
            </Divider>
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label='商户域名'>
                  {
                    getFieldDecorator('domainAddress', {
                      initialValue: detailInfo.domainName || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户备案号'>
                  {
                    getFieldDecorator('recordNum', {
                      initialValue: detailInfo.recordNum || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户权限'>
                  {
                    getFieldDecorator('type', {
                      initialValue: detailInfo.type || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Select placeholder='请选择'>
                        {
                          authorMap.map((item, i) => (
                            <Option value={item.type} key={`author-${i}`}>{item.label}</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户公安备案号'>
                  {
                    getFieldDecorator('securityNum', {
                      initialValue: detailInfo.securityNum || ''
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户短信签名'>
                  {
                    getFieldDecorator('smsSignname', {
                      initialValue: detailInfo.smsSignname || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户百度商桥'>
                  {
                    getFieldDecorator('baiduQiao', {
                      initialValue: detailInfo.baiduQiao || '',
                      rules: [
                        {
                          validator (rule, value, callback) {
                            if (value && !domainname.test(value)) {
                            /* eslint-disable */
                            callback('请输入正确的链接格式(http/https开头)')
                            /* eslint-enable */
                            } else {
                              callback()
                            }
                          }
                        }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='网上工商亮照标识'>
                  {
                    getFieldDecorator('recordMark', {
                      initialValue: detailInfo.recordMark || '',
                      rules: [
                        { required: false, message: '该项必填' }
                      ]
                    })(
                      <TextArea rows={2} placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='商户标签'>
                  {
                    getFieldDecorator('label', {
                      initialValue: detailInfo.label || '',
                      rules: [
                        { required: false, message: '该项必填' }
                      ]
                    })(
                      <TextArea rows={2} placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Col>

              <Col span={10}>
                <FormItem label='备案完成时间'>
                  {
                    getFieldDecorator('times', {
                      initialValue: detailInfo.recordTime ? moment(moment(detailInfo.recordTime).format('YYYY-MM-DD')) : null,
                      rules: [
                        { required: false, message: '该项必填' }
                      ]
                    })(
                      <DatePicker />
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} style={{ paddingTop: '24px', textAlign: 'center' }}>
              <Button style={{ marginRight: '24px', width: '100px' }} onClick={() => this.handleReset()}>取消</Button>

              <Button type='primary' style={{ width: '100px' }} onClick={() => this.handleSubmit()}>
                保存
              </Button>
            </Row>
          </Form>
        </Card>
      </>
    )
  }
}

const SetCustomer = Form.create()(CustomerForm)

export default SetCustomer
