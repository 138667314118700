import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'

import styles from './index.module.scss'

export default function UploadPDFItem (props) {
  const { imageUrl, onDelete } = props

  return (
    <div className={`${styles.uploadPDFItem}`}>
      <span>
        <Icon type='file-pdf' className={`${styles.pdfIcon}`} />
        <a className={`${styles.uploadItemName}`} title={imageUrl} href={imageUrl} target='_blank' rel='noopener noreferrer'>{ imageUrl }</a>
        <span className={`${styles.uploadItemActions}`}>
          {
            onDelete
              ? <a title='删除文件' onClick={() => onDelete()}><Icon type='delete' /></a>
              : null
          }
        </span>
      </span>
    </div>
  )
}

UploadPDFItem.propTypes = {
  imageUrl: PropTypes.string,
  onDelete: PropTypes.func
}
