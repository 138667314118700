import Ajax from '../utils/ajax'

export default {

  // 获取支付方式
  getPaymethodListToMerchant (params) {
    return Ajax.post('/platform/finance/cloudPayTypeForMerchantDetail', params)
  },

  // 获取支付信息(银行卡转账)
  getPaidInfoByBankCard (params) {
    return Ajax.post('/platform/finance/purchaseOrderSupplierPaidDetail', params)
  },

  // 获取支付信息(记账宝支付)
  getPaidInfoByJzb (params) {
    return Ajax.post('/platform/finance/purchaseOrderPayingDetail', params)
  },

  // 获取支付信息(信用支付)
  getPaidInfoByCredit (params) {
    return Ajax.post('/platform/finance/purchaseOrderCreditPayingDetail', params)
  },

  // 银行转账-确认付款
  confirmPaidToCard (params) {
    return Ajax.post('/platform/finance/purchaseOrderVerifyPaidToCard', params)
  },

  // 云采购单信用支付
  payPurchOrderByCredit (params) {
    return Ajax.post('/platform/finance/purchaseOrderPayingByCredit', params)
  }

}
