import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

import Api from '@/common/api/index'

export default class BatchDetail extends Component {
  static propTypes = {
    detailInfo: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'batchProduct-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'batchProduct-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'batchProduct-2',
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'batchProduct-3',
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '产品',
      key: 'batchProduct-4',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '数量',
      key: 'batchProduct-5',
      dataIndex: 'purchaseQuantity'
    },
    {
      title: '当前发货数',
      key: 'batchProduct-6',
      dataIndex: 'deliveryQuantity'
    },
    {
      title: '单位',
      key: 'batchProduct-7',
      dataIndex: 'unit'
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderSendOutBatchDetail()
  }

  // 获取发货明细
  getPurchaseOrderSendOutBatchDetail = () => {
    const { detailInfo = {} } = this.props

    let params = {
      pickingId: detailInfo.pickingId,
      batchNum: detailInfo.batchNum
    }

    Api.getPurchaseOrderSendOutBatchDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data || []
        })
      }
    })
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <>
        <Table rowKey='commoditySkuId' bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />
      </>
    )
  }
}
