import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Select, Radio, Divider, Upload, Button, Icon, message, Tooltip } from 'antd'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts

const statusMaps = [ // 上下架状态
  { id: 'ONLINE', name: '上架' },
  { id: 'OFFLINE', name: '下架' }
]

class RecommendForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    customerList: [],
    imageUrl: ''
  }

  componentDidMount () {
    this.getCustomerList()
  }

  // 获取商户列表
  getCustomerList = () => {
    Api.getCustomerList({ pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          customerList: data.list || []
        })
      }
    })
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        const { imageUrl = '' } = this.state

        values['commoditySkuId'] = detailInfo.commoditySkuId

        if (imageUrl !== '') {
          values['imageUrl'] = imageUrl
        }

        this.props.onConfirm(values)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  changeUploadProductImage = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.setState({
          imageUrl: data.imageUrl
        })
      } else {
        message.error('上传失败')
      }
    }
  }

  render () {
    const { form, detailInfo = {}, onCancel } = this.props
    const { getFieldDecorator } = form
    const { customerList = [], imageUrl = '' } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title='推荐设置' width={800}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Row className={`${styles.introLand}`}>
            <Row className={`${styles.descRow} clearfix`}>
              <div className={`${styles.descItem}`}>
                <p className={`${styles.descLabel}`}>品牌</p>
                <p className={`${styles.descContent}`}>{detailInfo.brandName}</p>
              </div>
              <div className={`${styles.descItem}`}>
                <p className={`${styles.descLabel}`}>型号</p>
                <p className={`${styles.descContent}`}>{detailInfo.model}</p>
              </div>
              <div className={`${styles.descItem}`}>
                <p className={`${styles.descLabel}`}>订货号</p>
                <p className={`${styles.descContent}`}>{detailInfo.itemNumber}</p>
              </div>
            </Row>
          </Row>

          <Divider />

          <Row>
            <Form>
              <FormItem label='图片' {...formItemLayout}>
                <Row style={{ display: 'flex' }}>
                  <div style={{ width: '120px' }}>
                    <Upload name='file'
                      showUploadList={false}
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.changeUploadProductImage(info)}
                    >
                      <Button><Icon type='upload' />点击上传</Button>
                    </Upload>
                  </div>
                  <div style={{ flex: '1', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={imageUrl}>
                      <a href={imageUrl || detailInfo.imageUrl} target='_blank'>{imageUrl || detailInfo.imageUrl}</a>
                    </Tooltip>
                  </div>
                </Row>
              </FormItem>
              <FormItem label='商户' {...formItemLayout}>
                {
                  getFieldDecorator('disIdList', {

                  })(
                    <Select mode='multiple'>
                      {
                        customerList.map((item, i) => (
                          <Option value={item.distributorId} key={item.distributorId}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
              <FormItem label='上架状态' {...formItemLayout}>
                {
                  getFieldDecorator('status', {
                    initialValue: detailInfo.status || 'ONLINE'
                  })(
                    <Radio.Group>
                      {
                        statusMaps.map((row, n) => (
                          <Radio value={row.id} key={`${row.id}-${n}`}>{row.name}</Radio>
                        ))
                      }
                    </Radio.Group>
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const RecommendModal = Form.create()(RecommendForm)

export default RecommendModal
