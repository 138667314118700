import React, { Component } from 'react'
import { Row, Card, Table, Button, Modal, Popconfirm, InputNumber, Badge, message, Icon, Divider } from 'antd'
import SearchForm from '@/components/search-form/index'
import moment from 'moment'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { getCourierName } from '@/common/utils/mUtils'

import AddRemarksModal from './addRemarksModal' // 增加备注弹框
import OnSelectInfoModal from './onSelectInfoModal' // 查看详情弹框
import UpdateModal from './updateModal' // 更改快递信息弹出框

const { confirm } = Modal
const { pageSizeOptions, expressTypeMaps, expressmoenyMap } = filter
const { downDeCusDetail } = DownloadApi // 下载模板

export default class Depponindex extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {

  }

  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...'

        },
        options: []
      },
      {
        name: 'mailNo',
        formType: 'Input',
        itemParams: {
          label: '运单号'
        },
        cptParams: {
          placeholder: '请输入...'

        },
        options: []
      },
      {
        name: 'transportType',
        formType: 'Select',
        itemParams: {
          label: '快递类型'
        },
        cptParams: {
          placeholder: '请选择快递类型...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...expressTypeMaps['SAAS-DBL']
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '发货日期'
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '商户名称',
          help: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        rules: [
          { required: true, message: '该项必填' }
        ],
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'releaseStatus',
        formType: 'Select',
        itemParams: {
          label: '发布状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'RELEASE', name: '已发布' },
          { id: 'NOT_RELEASE', name: '未发布' }

        ]
      }

    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    editingKey: '',
    RemarksModal: false,
    SelectModal: false,
    SelectJdModal: false,
    UpdateInfoModal: false,
    rowDetail: {},
    orderNoList: []

  }
  columns = [
    {
      title: '序号',
      key: 'service-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'service-1',
      width: 100,
      dataIndex: 'orderId'
    },
    {
      title: '发货日期',
      key: 'service-2',
      width: 100,
      render: (text, record, index) => (
        <>{ record.senderTime ? moment(record.senderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },

    {
      title: '收货日期',
      key: 'service-3',
      width: 100,
      render: (text, record, index) => (
        <div>
          {
            record.signedStatus === 'SIGNED' && record.receiverTime
              ? moment(record.receiverTime).format('YYYY-MM-DD HH:mm:ss')
              : '----'
          }
        </div>

      )
    },
    {
      title: '运单号',
      key: 'service-4',
      width: 150,
      render: (text, record, index) => (
        <div>{ record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----' }</div>
      )
    },
    {
      title: '发货地址',
      key: 'service-5',
      width: 150,
      render: (text, record, index) => (
        <div>
          {record.senderProvince} {record.senderCity} {record.senderCounty} {record.senderAddress}
        </div>
      )
    },
    {
      title: '收货地址',
      key: 'service-6',
      width: 150,
      render: (text, record, index) => (
        <div>
          {record.receiverProvince} {record.receiverCity} {record.receiverCounty} {record.receiverAddress}
        </div>
      )
    },
    {
      title: '出发简称',
      key: 'service-7',
      width: 100,
      dataIndex: 'senderShortCity'
    },
    {
      title: '到达简称',
      key: 'service-8',
      width: 100,
      dataIndex: 'receiverShortCity'
    },
    {
      title: '快递类型',
      key: 'service-9',
      width: 100,
      render: (text, record, index) => (
        <div>{ this.renderExpressTypeLabel(record.transportType) }</div>
      )
    },
    {
      title: '快递公司',
      key: 'service-10',
      width: 100,
      render: (text, record, index) => (
        <>{ getCourierName(record.courierCode) }</>
      )
    },
    {
      title: '商户名称',
      key: 'service-11',
      width: 100,
      dataIndex: 'distributorName'
    },
    {
      title: '发货人',
      key: 'service-12',
      width: 100,
      dataIndex: 'senderName'
    },
    {
      title: '收货人',
      key: 'service-13',
      width: 100,
      dataIndex: 'receiverName'
    },
    {
      title: '货物名称',
      key: 'service-14',
      width: 100,
      dataIndex: 'cargoName'
    },
    {
      title: '件数',
      key: 'service-15',
      width: 100,
      dataIndex: 'totalNumber'
    },
    {
      title: '重量(kg)',
      key: 'service-16',
      width: 100,
      dataIndex: 'totalWeight'
    },
    {
      title: '体积(m³)',
      key: 'service-17',
      width: 100,
      dataIndex: 'totalVolume'
    },
    {
      title: '首重表价',
      key: 'service-18',
      width: 100,
      render: (text, record, index) => (
        <div>
          {
            record.transportType === 'PACKAGE' || record.transportType === 'RCP'
              ? <span>{ (+record.firstWeightPrice * 100 / 100).toFixed(2) }</span>
              : '----'
          }
        </div>
      )
    },
    {
      title: '续重表价',
      key: 'service-19',
      width: 100,
      render: (text, record, index) => (
        <div>
          {
            record.transportType === 'PACKAGE' || record.transportType === 'RCP'
              ? <span>{ (+record.addWeightPrice * 100 / 100).toFixed(2) }</span>
              : '----'
          }
        </div>
      )
    },
    {
      title: '最低一票',
      key: 'service-20',
      width: 100,
      dataIndex: 'totalVolume',
      render: (text, record, index) => (
        <div>
          {
            record.transportType === 'JZKH' || record.transportType === 'JZQY_LONG'
              ? <span>{ (+record.lowestPrice * 100 / 100).toFixed(2) }</span>
              : '----'
          }
        </div>
      )
    },
    {
      title: '重货价格',
      key: 'service-21',
      width: 100,
      dataIndex: 'totalVolume',
      render: (text, record, index) => (
        <div>
          {
            record.transportType === 'JZKH' || record.transportType === 'JZQY_LONG'
              ? <span>{ (+record.weightPrice * 100 / 100).toFixed(2) }</span>
              : '----'
          }
        </div>
      )
    },
    {
      title: '轻货价格',
      key: 'service-22',
      width: 100,
      dataIndex: 'totalVolume',
      render: (text, record, index) => (
        <div>
          {
            record.transportType === 'JZKH' || record.transportType === 'JZQY_LONG'
              ? <span>{ (+record.lightPrice * 100 / 100).toFixed(2) }</span>
              : '----'
          }
        </div>
      )
    },
    {
      title: '客户费率',
      key: 'service-23',
      width: 100,
      render: (text, record, index) => (
        <div>
          <div>{ this.renderGetRate(record, index) }</div>
        </div>
      )
    },
    {
      title: '计算运费总金额',
      key: 'service-25',
      width: 120,
      render: (text, record, index) => (
        <div>
          {
            +record.totalPrice && +record.totalPrice > 0
              ? <div>{ (+record.totalPrice * 100 / 100).toFixed(2) }</div>
              : '--'
          }
        </div>
      )
    },
    {
      title: '客户运费总金额',
      key: 'service-24',
      width: 120,
      render: (text, record, index) => (
        <div>{ this.renderServiceRateCell(record, index) }</div>
      )
    },
    {
      title: '初步核算是否准确',
      key: 'service-26',
      width: 120,
      render: (text, record, indx) => (
        <div>{ this.renderStasueBadge(record.verifyStatus) }</div>
      )
    },
    {
      title: '发布状态',
      key: 'service-27',
      width: 100,
      render: (text, record, indx) => {
        return (
          record.releaseStatus === 'RELEASE' ? '已发布' : '未发布'
        )
      }
    },
    {
      title: '备注',
      key: 'service-28',
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <a onClick={() => { this.SelectModal(record) }}>点击查看</a>
          </div>
        )
      }
    },
    {
      title: '操作',
      width: 200,
      key: 'service-29',
      render: (text, record, index) => {
        const editable = this.isEditing(record)

        return editable ? (
          <div>
            <Button type='primary' size='small'
              onClick={() => this.handleSave(record)}
              style={{ marginRight: '8px' }}
            >保存</Button>
            <Popconfirm title='确定放弃编辑?'
              onConfirm={() => this.handleCancel(record, index)}
            >
              <Button type='primary' size='small'>取消</Button>
            </Popconfirm>
          </div>
        ) : (<div>
          <a onClick={() => { this.editRowMoeny(record.orderId) }}>编辑计算运费金额</a><Divider type='vertical' />
          <a onClick={() => { this.openRemarksModal(record) }}>增加备注</a>
        </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getCusExpressList()
  }

  // 获取列表信息
  getCusExpressList=() => {
    const { pages, query = {} } = this.state
    let params = {
      releaseStatus: 'NOT_RELEASE',
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCusExpressList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let list = data.list || []
        if (list.length > 0) {
          list.map((item, i) => {
            item['expressPrice'] = ((+item.totalPrice - (+item.transportPrice) + (item.merchantTransportPrice)) * 100 / 100).toFixed(2)
            item['copyexpressPrice'] = item['expressPrice']
          })
        }

        this.setState({
          tableData: list,
          pages: ({
            ...pages,
            total: data.total || 0
          })
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[4].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search['beginDate'] = moment(search.times[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.times[1]).format('YYYY-MM-DD')
      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCusExpressList()
    })
  }

 // 编辑总金额保存
 handleSave = (record) => {
   let param = {
     orderNo: record.orderNo,
     totalPrice: record.expressPrice
   }

   Api.getUpdataCusTotalPrice(param).then(res => {
     const { code } = res
     if (+code === 10000) {
       message.success('保存成功')
       this.setState({
         editingKey: ''
       })
       this.getCusExpressList()
     }
   })
 }

  // 渲染快递类型名称
  renderExpressTypeLabel = (code) => {
    let label = ''
    let obj = expressTypeMaps['SAAS-DBL'].find((item) => { return item.id === code })
    if (obj) {
      label = obj.name
    } else {
      label = code
    }

    return label
  }

  // 渲是否准确名称
  renderStasueBadge = (code) => {
    let label = {}
    let obj = expressmoenyMap.find((item) => { return item.id === code })

    if (obj) {
      label = <Badge status={obj.badge} text={obj.name} />
    }

    return label
  }

  // 确认发布
  onSubmit=() => {
    confirm({
      title: '确认发布？',
      onOk: () => { this.getCusAddInfo() }
    })
  }

  // 确认发布
  getCusAddInfo = () => {
    const { orderNoList } = this.state
    Api.getCusAddInfo({ orderNoList: orderNoList }).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('发布成功')
        this.setState({
          orderNoList: []
        })
        setTimeout(() => {
          this.getCusExpressList()
        }, 2000)
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击编辑运算总金额事件
  editRowMoeny=(key) => {
    this.setState({
      editingKey: key
    })
  }

  // 取消编辑
  handleCancel = (record, i) => {
    const { tableData } = this.state
    tableData[i]['expressPrice'] = tableData[i]['copyexpressPrice']
    this.setState({
      editingKey: '',
      tableData
    })
  }

  // 点击编辑运算总金额编辑中
  isEditing = (record) => record.orderId === this.state.editingKey

  // 渲染计算运费单元格
  renderServiceRateCell=(record, index) => {
    const editable = this.isEditing(record)

    return editable ? (
      <Row>
        <InputNumber min={0} step={1}
          value={record['expressPrice']}
          onChange={(value) => this.onChangeServiceRate(value, index)}
        />
      </Row>
    ) : (<Row>{record['expressPrice']}</Row>)
  }

  // 获取II德邦费率信息
  renderGetRate=(record, index) => {
    const { transportType } = record
    let rate = ''
    let obj = expressTypeMaps['SAAS-DBL'].find((item) => { return item.id === transportType })
    if (obj) {
      let sort = obj.keysort
      if (sort === '--') {
        rate = '--'
      } else {
        rate = <div>{obj.id === 'PACKAGE' || obj.id === 'RCP'
          ? `${(record.courierDiscount) * 100}%`
          : `${(record.logisticsDiscount) * 100}%`
        }</div>
      }
    }
    return rate
  }

  // 监听计算运费的金额的改变
  onChangeServiceRate = (val, i) => {
    let { tableData } = this.state
    tableData[i]['expressPrice'] = val

    this.setState({
      tableData
    })
  }

  // 打开添加备注弹出框
  openRemarksModal = (record) => {
    this.setState({
      RemarksModal: true,
      rowDetail: record
    })
  }

  // 打开查看详情备注弹出框
  SelectModal = (record) => {
    this.setState({
      SelectModal: true,
      rowDetail: record
    })
  }

  // 导出明细
  handExportlist = () => {
    const { query = {} } = this.state

    if (!query.distributorId || +query.distributorId === 0) {
      Modal.warning({
        icon: <Icon type='exclamation-circle' style={{ color: '#FF3434' }} />,
        title: '请选择想要导出的数据',
        content: '（请先选择要导出的商家、点击搜索）'
      })

      return false
    }

    downDeCusDetail({ distributorId: query.distributorId })
  }

  // 取消弹框
  oncloseModal = () => {
    this.setState({
      RemarksModal: false,
      SelectModal: false,
      SelectJdModal: false,
      UpdateInfoModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCusExpressList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCusExpressList()
    })
  }

  // 选择行复选框标识
  onSelectChange =(keys, rows) => {
    this.setState({
      orderNoList: keys
    })
  }

  render () {
    const { searchData, pages, tableData = [], rowDetail = {}, SelectModal = false, UpdateInfoModal = false, RemarksModal = false, orderNoList = [] } = this.state
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }
    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: orderNoList,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.releaseStatus === 'RELEASE'
      })
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Button disabled={orderNoList.length === 0} onClick={() => { this.onSubmit() }}>确定发布</Button>
              <Button type='primary' onClick={() => { this.handExportlist() }} style={{ marginLeft: '10px' }}>导出明细</Button>

            </Row>
            <Row>
              <Table rowKey='orderNo' bordered
                pagination={pagination}
                columns={this.columns}
                dataSource={tableData}
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
              />
            </Row>
          </Card>
        </Row>
        {
          RemarksModal
            ? <AddRemarksModal
              oncloseModal={this.oncloseModal}
              okRemarksModal={(param) => { this.okRemarksModal(param) }}
              rowDetail={rowDetail}
            />
            : null
        }
        {
          SelectModal
            ? <OnSelectInfoModal
              oncloseModal={this.oncloseModal}
              rowDetail={rowDetail} />
            : null
        }

        {
          UpdateInfoModal
            ? <UpdateModal
              oncloseModal={this.oncloseModal}
              rowDetail={rowDetail} />
            : null
        }
      </div>
    )
  }
}
