import React, { Component } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
export default class smsInfomodal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    type: PropTypes.number,
    datail: PropTypes.object
  }
  render () {
    const { type, datail } = this.props
    return (
      <Modal
        title={type === 0 ? '模板内容' : '申请说明'}
        visible
        onOk={this.props.onCancel}
        onCancel={this.props.onCancel}
      >
        {
          type === 0
            ? <div>{datail.content}</div>
            : <div>{datail.remark}</div>
        }

      </Modal>
    )
  }
}
