import React, { Component } from 'react'
import { Modal, Input, message } from 'antd'
import PropTypes from 'prop-types'

import Api from '@/common/api/index'
const { TextArea } = Input
export default class addRemarksModal extends Component {
  static propTypes={
    oncloseModal: PropTypes.func,
    rowDetail: PropTypes.object
  }
  state={
    info: ''
  }

  // 添加备注确认
  handleOk=() => {
    const { info } = this.state
    let parme = {
      orderNo: this.props.rowDetail.orderNo,
      remarks: info,
      logisticsRemarksType: 'MERCHANT'
    }
    Api.setOneAddRemarks(parme).then(res => {
      const { code } = res
      let Messages = res.message
      if (+code === 10000) {
        message.success('添加成功')
      } else {
        message.error(Messages)
      }
    })
    this.props.oncloseModal()
  }
  onChange=(e) => {
    this.setState({
      info: e.target.value
    })
  }
  render () {
    return (
      <>
        <Modal title='增加备注'
          visible
          onOk={this.handleOk}
          onCancel={this.props.oncloseModal}
        >
          <TextArea rows={4} placeholder='请输入...' onChange={(e) => { this.onChange(e) }} />
        </Modal>
      </>
    )
  }
}
