import React, { Component } from 'react'
import { Modal, Form, Col, Select, Row, Input, message } from 'antd'

import PropTypes from 'prop-types'
import filter from '@/common/utils/filter'

import Api from '@/common/api/index'
const FormItem = Form.Item
const { Option } = Select

const { expressTypeMaps } = filter

class UpdateForm extends Component {
  static propTypes={
    oncloseModal: PropTypes.func,
    form: PropTypes.object,
    detailInfo: PropTypes.object
  }

  state = {
    cusList: [],
    showPage: false
  }

  componentDidMount () {
    this.getCusList()
  }

  // 获取商家列表
  getCusList = () => {
    Api.getCustomerList({ pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          cusList: data.list || [],
          showPage: true
        })
      }
    })
  }

  // 点击保存事件
  handleOk = (e) => {
    const { detailInfo = {} } = this.props
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let param = {
          orderNo: detailInfo.orderNo,
          senderShortCity: values.sendCity,
          receiverShortCity: values.recCity,
          transportType: values.tansType,
          distributorId: values.cusName
        }
        Api.setOneUpdateInfo(param).then(res => {
          const { code } = res
          let Messages = res.message
          if (+code === 10000) {
            message.success('更改成功')
            this.props.oncloseModal(1)
          } else {
            message.success(Messages)
          }
        })
      }
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { cusList = [], showPage = false } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }

    return (
      <div>
        <Modal title='更改快递信息' bodyStyle={{ minHeight: '176px' }}
          visible
          maskClosable={false}
          onOk={this.handleOk}
          onCancel={this.props.oncloseModal}
        >
          {
            showPage
              ? <Row gutter={8}>
                <Col span={12}>
                  <FormItem label='出发简称' {...formItemLayout}>
                    {
                      getFieldDecorator('sendCity', {
                        initialValue: detailInfo.senderShortCity,
                        rules: [
                          { required: true, message: '必填' }
                        ]
                      })(<Input />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label='到达简称' {...formItemLayout}>
                    {
                      getFieldDecorator('recCity', {
                        initialValue: detailInfo.receiverShortCity,
                        rules: [
                          { required: true, message: '必填' }
                        ]
                      })(<Input />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label='快递类型' {...formItemLayout}>
                    {
                      getFieldDecorator('tansType', {
                        initialValue: detailInfo.transportType,
                        rules: [
                          {
                            required: true,
                            message: '必填'
                          }
                        ]
                      })(<Select style={{ width: 155 }}>
                        {
                          expressTypeMaps['SAAS-DBL'].map((item, i) => (
                            <Option value={item.id} key={i}>{item.name}</Option>
                          ))
                        }

                      </Select>)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label='商户名称' {...formItemLayout}>
                    {
                      getFieldDecorator('cusName', {
                        initialValue: detailInfo.distributorId,
                        rules: [
                          { required: true, message: '必填' }
                        ]
                      })(
                        <Select style={{ width: 155 }}>
                          {
                            cusList.length > 0 && cusList.map((item, i) => (
                              <Option value={item.id} key={i}>{ item.merchantName }</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </Col>
              </Row>
              : null
          }

        </Modal>
      </div>
    )
  }
}

const UpdateModal = Form.create()(UpdateForm)

export default UpdateModal
