import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Col, Input, Button } from 'antd'
import $lodash from 'lodash'

import InvoiceAccount from '@/components/invoiceAccount/index' // 开票企业信息
import InvoiceProduct from '@/components/invoiceProduct/index' // 开票物资信息
import InvoiceReceiving from '@/components/invoiceReceiving/index' // 发票收件地址

import Api from '@/common/api/index'
import { renderInvoiceTypeLabel } from '@/common/utils/mUtils'
import styles from '../index.module.scss'

const FormItem = Form.Item

class InvoiceApplyForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    ids: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    receivingAddress: {}, // 收件地址信息
    invoiceInfo: {}, // 发票抬头信息
    tableData: [], // 发票物料信息
    totalAmount: 0 // 发票总金额信息
  }

  componentDidMount () {
    this.getSupplyOrderInvoiceUnopenDetails()
  }

  // 云供应发票- 获取开票申请详情信息
  getSupplyOrderInvoiceUnopenDetails = async () => {
    const { ids = [] } = this.props
    const res = await Api.getSupplyOrderInvoiceUnopenDetails({ idList: ids })
    const { code, data } = res
    if (+code === 10000) {
      if (data.invoiceSkuInfoList && data.invoiceSkuInfoList.length > 0) {
        const { invoiceSkuInfoList = [] } = data
        data.invoiceSkuInfoList = $lodash.map(invoiceSkuInfoList, (record) => {
          return {
            ...record,
            price: record.sellingPrice,
            orderId: record.purchaseOrderId
          }
        })
      }

      this.setState({
        invoiceInfo: data.invoiceInfo || {},
        tableData: data.invoiceSkuInfoList || [],
        totalAmount: data.invoiceTotalAmount || 0,
        receivingAddress: data.addressInfo && data.addressInfo.length > 0 ? data.addressInfo[0] : {}
      })
    }
  }

  // 点击申请开票
  handleSubmit = () => {
    const { form, ids = [], onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const { totalAmount = 0, invoiceInfo = {}, tableData = [] } = this.state
        const needConfirmArr = $lodash.filter(tableData, (o) => { return !o.deliveryFlag })

        values.idList = ids
        values.invoiceInfo = invoiceInfo
        values.invoiceSkuInfoList = tableData
        values.invoiceTotalAmount = totalAmount

        if (needConfirmArr.length > 0) {
          Modal.confirm({
            title: '以下订单存在未发货的商品，请确认后开票',
            content: <>
              {
                needConfirmArr.map((item, i) => (
                  <span key={`needConfirmSpan-${i}`} style={{ marginLeft: '5px' }}>{ item.purchaseOrderId }</span>
                ))
              }
            </>,
            onOk: () => {
              onConfirm(values)
            }
          })
        } else {
          onConfirm(values)
        }
      }
    })
  }

  render () {
    const { form, onCancel } = this.props
    const { getFieldDecorator } = form

    const { invoiceInfo = {}, tableData = [], totalAmount = 0, receivingAddress = {} } = this.state

    return (
      <>
        <Modal title='发票详情' bodyStyle={{ padding: '12px' }}
          width={1000}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={<div style={{ textAlign: 'center' }} key={`supplyInvoiceBtns`}>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleSubmit()}>申请开票</Button>
          </div>}
        >
          <Form>
            <Row gutter={24}>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票类型' style={{ display: 'none' }}>
                    {
                      getFieldDecorator('invoiceType', {
                        initialValue: 'SPECIAL_INVOICE',
                        rules: [
                          { required: true, message: '请选择发票类型' }
                        ]
                      })(
                        <Input autoComplete='off' disabled />
                      )
                    }
                  </FormItem>

                  <FormItem label='发票类型'>
                    { renderInvoiceTypeLabel('SPECIAL_INVOICE') }
                  </FormItem>
                </div>
              </Col>
            </Row>

            <div style={{ marginTop: '16px' }}>
              <InvoiceAccount invoiceInfo={invoiceInfo} />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceProduct list={tableData} totalAmount={totalAmount} />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceReceiving form={form} isEdit={false} receivingAddress={receivingAddress} />
            </div>
          </Form>
        </Modal>
      </>
    )
  }
}

const InvoiceModal = Form.create()(InvoiceApplyForm)

export default InvoiceModal
