import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Icon, message, Descriptions } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'
import DetailModal from '../component/euorder-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderOrderTypeLabel, renderOrderStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, orderTypeMaps } = filter

export default class EUOrderList extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '订单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderTypeMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    info: {}, // 客户信息
    tableData: [],
    expandedRowKeys: [], // 展开的列表ID
    detailModal: false,
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'euorder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'euorder-1',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '订单编号',
      key: 'euorder-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单类型',
      key: 'euorder-3',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.skuGoodsType) }</>
      )

    },
    {
      title: '订单金额',
      key: 'euorder-4',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{ (+record.payableAmount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '订单状态',
      key: 'euorder-6',
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location

    if (!state.euId) {
      return message.warning('参数不合法！')
    }

    this.setState({
      info: state,
      query: {
        endUserId: state.euId || ''
      }
    }, () => {
      this.getEuOrderListByEndUserId()
    })
  }

  // 获取EU订单列表
  getEuOrderListByEndUserId = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEuOrderListByEndUserId(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取EU订单-子订单列表
  getEUTradeChildOrderList = async (expanded, record) => {
    if (!expanded) { return false }
    const params = {
      orderId: record.orderId,
      skuGoodsType: record.skuGoodsType
    }
    const res = await Api.getEUTradeChildOrderList(params)
    const { code, data = [] } = res
    if (+code === 10000) {
      let { tableData = [] } = this.state
      const n = tableData.findIndex((item) => { return item.orderId === record.orderId })

      tableData[n].childOrderList = data

      this.setState({
        tableData: [...tableData]
      })
    }
  }

  // 获取订单明细
  getEuOrderDetail = (params) => {
    return Api.getEuOrderDetail(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages, info } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: {
        endUserId: info.euId,
        ...search
      }
    }, () => {
      this.getEuOrderListByEndUserId()
    })
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    let { expandedRowKeys = [] } = this.state

    this.setState({
      expandedRowKeys: expanded ? [...expandedRowKeys, record.orderId] : $lodash.pull(expandedRowKeys, record.orderId)
    }, () => {
      this.getEUTradeChildOrderList(expanded, record)
    })
  }

  // 点击查看订单明细
  showEuOrderDetail = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record || {}
    })
  }

  // 返回
  handleBack = () => {
    this.props.history.push({ pathname: '/admin/euCustomer' })
  }

  // 子列表columns配置
  renderExpandRow = (row) => {
    const rowColumns = [
      {
        title: '子订单',
        key: `expandRow-${row.orderId}-0`,
        dataIndex: 'skuGoodsType',
        render: (text, record, index) => `${row.orderId}-(${renderOrderTypeLabel(record.skuGoodsType)})`
      },
      {
        title: '订单金额',
        key: `expandRow-${row.orderId}-1`,
        dataIndex: 'orderAmount',
        render: (text, record, index) => (
          <>&yen;{ (+record.orderAmount * 100 / 100).toFixed(2) }</>
        )
      },
      {
        title: '操作',
        key: `expandRow-${row.orderId}-2`,
        render: (text, record, index) => (
          <>
            <Button type='link' size='small' style={{ fontSize: '12px' }}
              onClick={() => this.showEuOrderDetail(record)}>查看明细</Button>
          </>
        )
      }
    ]

    return (
      <div style={{ padding: '2px 5px' }}>
        <Table rowKey={(record, index) => `${record.orderId}-${record.skuGoodsType}-${index}`} bordered
          columns={rowColumns}
          dataSource={row.childOrderList || []}
          pagination={false}
        />
      </div>
    )
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEuOrderListByEndUserId()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEuOrderListByEndUserId()
    })
  }

  render () {
    const { tableData, pages, searchData, detailModal = false, rowDetail = {}, info = {}, expandedRowKeys = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px 12px 0px 12px' }}>
            <div style={{ marginBottom: '15px' }}>
              <Button onClick={this.handleBack}><Icon type='left' />返回列表</Button>
            </div>

            <Descriptions>
              <Descriptions.Item label='真实姓名'>{info.realname}</Descriptions.Item>
              <Descriptions.Item label='所属商家'>{info.distributorName}</Descriptions.Item>
            </Descriptions>
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '20px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </div>

          <Table rowKey='orderId' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={(record) => this.renderExpandRow(record)}
            onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
          />
        </Card>

        {
          detailModal
            ? <DetailModal
              ident='EUORDER'
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              getDetail={(params) => this.getEuOrderDetail(params)}
            />
            : null
        }
      </>
    )
  }
}
