import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Card, Radio, Icon, message } from 'antd'
import $lodash from 'lodash'

import CanBindSkuTable from './canbindSkuTable/index'
import SkuBaseUploadImage from '../component/skuBaseUploadImage/index'

import Api from '@/common/api/index'
import styles from './index.module.scss'

const FormItem = Form.Item
const implementMaps = [
  { id: 'DATA_MODIFY', name: '覆盖' },
  { id: 'DATA_INSERT', name: '新增' }
]

class BindSkuImagesForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    showPage: false,
    skuImageList: [],
    skuDetailImageList: [],
    skuDetailPdfList: []
  }

  componentDidMount () {
    const { imageSource, skuImageUrls = [], skuDetailImageUrls = [], skuDetailPdfUrls = [] } = this.props.history.location.state

    if (!imageSource || imageSource === '') {
      return message.warning('页面参数错误')
    }

    if (imageSource === 'COPY_IMAGES') {
      this.setState({
        skuImageList: skuImageUrls || [],
        skuDetailImageList: skuDetailImageUrls || [],
        skuDetailPdfList: skuDetailPdfUrls || [],
        showPage: true
      }, () => {
        this.props.form.setFieldsValue({
          skuImageUrls: skuImageUrls.length > 0 ? skuImageUrls.join(',') : undefined,

          skuDetailImageUrls: skuDetailImageUrls.length > 0 ? skuDetailImageUrls.join(',') : undefined,

          skuDetailPdfUrls: skuDetailPdfUrls.length > 0 ? skuDetailPdfUrls.join(',') : undefined

        })
      })
    } else {
      this.setState({
        showPage: true
      })
    }
  }

  // 批量关联图片
  updateProductBatchSkuImgs = async (params) => {
    const res = await Api.updateProductBatchSkuImgs(params)
    const { code } = res
    if (+code === 10000) {
      message.success('关联成功')
    }
  }

  // 确定关联图片
  handleConfirm = (selectedType, selectedRowKeys = [], query = {}) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { operationType, skuImageUrls, checkedskuImageUrls, skuImageDisabledStatus, skuDetailImageUrls, checkedskuDetailImageUrls, skuDetailDisabledStatus,
          skuDetailPdfUrls, checkedskuDetailPdfUrls, skuPDFDisabledStatus } = values

        let params = {
          operationType: operationType,
          uploadImageModifyType: selectedType,
          skuIdList: selectedRowKeys,
          imageStatus: skuImageDisabledStatus ? 'ENABLE' : 'DISABLE',
          detailImageStatus: skuDetailDisabledStatus ? 'ENABLE' : 'DISABLE',
          pdfImageStatus: skuPDFDisabledStatus ? 'ENABLE' : 'DISABLE',
          ...query
        }

        if (skuImageUrls && skuImageUrls !== '') {
          const _skuImageArr = skuImageUrls.split(',')
          const checkedskuImageArr = checkedskuImageUrls && checkedskuImageUrls !== '' ? checkedskuImageUrls.split(',') : []

          params.skuImageList = $lodash.map(_skuImageArr, (o, index) => {
            let obj = { imageUrl: o }
            const n = checkedskuImageArr.findIndex((item) => { return o === item })

            if (checkedskuImageArr.length === 0) {
              obj.mainType = index === 0 ? 'MAIN' : 'NOT_MAIN'
            } else {
              obj.mainType = n > -1 ? 'MAIN' : 'NOT_MAIN'
            }

            return obj
          })
        }

        if (skuDetailImageUrls && skuDetailImageUrls !== '') {
          const _skuDetailImageArr = skuDetailImageUrls.split(',')
          const checkedskuDetailImageArr = checkedskuDetailImageUrls && checkedskuDetailImageUrls !== '' ? checkedskuDetailImageUrls.split(',') : []

          params.skuDetailImageList = $lodash.map(_skuDetailImageArr, (o, index) => {
            let obj = { imageUrl: o }
            const j = checkedskuDetailImageArr.findIndex((item) => { return o === item })

            if (checkedskuDetailImageArr.length === 0) {
              obj.showStatus = index === 0 ? 'SHOW' : 'NOT_SHOW'
            } else {
              obj.showStatus = j > -1 ? 'SHOW' : 'NOT_SHOW'
            }

            return obj
          })
        }

        if (skuDetailPdfUrls && skuDetailPdfUrls !== '') {
          const _skuDetailPdfArr = skuDetailPdfUrls.split(',')
          const checkedDetailPdfArr = checkedskuDetailPdfUrls && checkedskuDetailPdfUrls !== '' ? checkedskuDetailPdfUrls.split(',') : []

          params.skuDetailPdfList = $lodash.map(_skuDetailPdfArr, (o, index) => {
            let obj = { imageUrl: o }
            const k = checkedDetailPdfArr.findIndex((item) => { return o === item })

            if (checkedDetailPdfArr.length === 0) {
              obj.showStatus = index === 0 ? 'SHOW' : 'NOT_SHOW'
            } else {
              obj.showStatus = k > -1 ? 'SHOW' : 'NOT_SHOW'
            }

            return obj
          })
        }

        this.updateProductBatchSkuImgs(params)
      }
    })
  }

  // 返回列表
  handleJumpToLink = () => {
    this.props.history.goBack()
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form

    const { showPage = false, skuImageList = [], skuDetailImageList = [], skuDetailPdfList = [] } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }} bordered={false}>
            <Button onClick={this.handleJumpToLink}><Icon type='arrow-left' />返回</Button>
          </Card>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Form>
              <div className={`${styles.formitemRow}`}>
                <FormItem label='执行逻辑'>
                  {
                    getFieldDecorator('operationType', {
                      rules: [
                        { required: true, message: '请选择执行逻辑' }
                      ]
                    })(
                      <Radio.Group>
                        {
                          implementMaps.map((item, i) => (
                            <Radio value={item.id} key={`radio-${item.id}-${i}`}>{ item.name }</Radio>
                          ))
                        }
                      </Radio.Group>
                    )
                  }
                </FormItem>
              </div>

              {
                showPage
                  ? <SkuBaseUploadImage form={form} hasSwitch
                    defaultSkuImageList={skuImageList}
                    defaultDetailImageList={skuDetailImageList}
                    defaultPDFImageList={skuDetailPdfList}
                  />
                  : null
              }
            </Form>
          </Card>
        </div>

        <CanBindSkuTable form={form}
          onConfirmBind={(selectedType, selectedRowKeys, query) => this.handleConfirm(selectedType, selectedRowKeys, query)}
        />
      </>
    )
  }
}

const BindSkuImages = Form.create()(BindSkuImagesForm)

export default BindSkuImages
