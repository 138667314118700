import Shownumber from '@/pages/operate/shownumber/index'
import Apply from '@/pages/operate/applyFor/index'
import BrandImg from '@/pages/operate/brandImage/index'
import ToolsboxBusiness from '@/pages/operate/toolsboxBusiness'

export default [
  {
    path: '/admin/shownumber',
    name: '显示数量调整',
    component: Shownumber
  },
  {
    path: '/admin/applyFor',
    name: '合作申请',
    component: Apply
  },
  {
    path: '/admin/brandImage',
    name: '品牌图片管理',
    component: BrandImg
  },
  {
    path: '/admin/toolsboxBusiness',
    name: '工聚宝商机管理',
    component: ToolsboxBusiness
  }
]
