import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Col, InputNumber } from 'antd'

const FormItem = Form.Item

class ProfitForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-批量设置 2-单个设置
    detailInfo: PropTypes.object,
    selectedkeys: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { selectedkeys } = this.props
        values['idList'] = selectedkeys

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { type = 1, detailInfo } = this.props
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    }

    return (
      <>
        <Modal title='利润设置' width={520} bodyStyle={{ paddingBottom: '0px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleSubmit}
        >
          <Form>
            <Row gutter={6}>
              <Col span={16}>
                <FormItem label='利润率' {...formItemLayout}>
                  {
                    getFieldDecorator('profitMargin', {
                      initialValue: type === 2 ? (+detailInfo.profitMargin * 100 / 100).toFixed(2) : null,
                      rules: [
                        { required: true, message: '请设置利润率' }
                      ]
                    })(
                      <InputNumber autoComplete='off' style={{ width: '100%' }} />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem>
                  <span>%</span>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    )
  }
}

const ProfitModal = Form.create()(ProfitForm)

export default ProfitModal
