import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, InputNumber, Input, Switch, Modal, DatePicker } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import consts from '@/common/utils/consts'

const { confirm } = Modal
const { MAX_NUMBER } = consts

class WorkEditable extends Component {
  static propTypes = {
    isPreview: PropTypes.bool,
    list: PropTypes.array,
    onChangeInput: PropTypes.func,
    onAddRow: PropTypes.func,
    onDeleteRow: PropTypes.func
  }
  state = {}
  columns = [
    {
      title: '序号',
      key: 'workEditTableCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '售价',
      key: 'workEditTableCol-1',
      width: 120,
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{record.sellingPrice}</>
      )
    },
    {
      title: '供应价格',
      key: 'workEditTableCol-2',
      width: 120,
      dataIndex: 'supplyPrice',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>&yen;{record.supplyPrice}</>
              : <>
                <InputNumber min={0} max={MAX_NUMBER} onChange={(value) => this.props.onChangeInput(value, 'supplyPrice', index)} />
              </>
          }
        </>
      )
    },
    {
      title: '数量',
      key: 'workEditTableCol-3',
      width: 120,
      dataIndex: 'quantity',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.quantity }</>
              : <><InputNumber min={0} max={MAX_NUMBER} onChange={(value) => this.props.onChangeInput(value, 'quantity', index)} /></>
          }
        </>
      )
    },
    {
      title: '实际货期',
      key: 'workEditTableCol-4',
      width: 120,
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ +record.deliveryPeriodDays > 0 ? `${+record.deliveryPeriodDays}工作日` : '现货' }</>
              : <><InputNumber min={0} max={MAX_NUMBER} onChange={(value) => this.props.onChangeInput(value, 'deliveryPeriodDays', index)} /></>
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'workEditTableCol-5',
      width: 120,
      dataIndex: 'advancePaymentRate',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.advancePaymentRate ? `${(+record.advancePaymentRate * 100 / 100).toFixed(2)}%` : null }</>
              : <><InputNumber min={0} max={MAX_NUMBER} onChange={(value) => this.props.onChangeInput(value, 'advancePaymentRate', index)} /></>
          }
        </>
      )
    },
    {
      title: '是否承担运费',
      key: 'workEditTableCol-6',
      width: 100,
      dataIndex: 'isUndertakeFreight',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.isUndertakeFreight === 'UNDERTAKE' ? '是' : '否' }</>
              : <Switch checked={record.isUndertakeFreight === 'UNDERTAKE'} onChange={(value) => this.props.onChangeInput(value ? 'UNDERTAKE' : 'UN_UNDERTAKE', 'isUndertakeFreight', index)} />
          }
        </>
      )
    },
    {
      title: '运费金额',
      key: 'workEditTableCol-7',
      width: 120,
      dataIndex: 'freightPrice',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>&yen;{ (+record.freightPrice * 100 / 100).toFixed(2) }</>
              : <>
                <InputNumber min={0} max={MAX_NUMBER}
                  disabled={record.isUndertakeFreight === 'UNDERTAKE'}
                  onChange={(value) => this.props.onChangeInput(value, 'freightPrice', index)}
                />
              </>
          }
        </>
      )
    },
    {
      title: '报价有效期',
      key: 'workEditTableCol-8',
      width: 220,
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.expiryDateTime ? moment(record.expiryDateTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
              : <>
                <DatePicker showTime onOk={(value) => this.props.onChangeInput(moment(value).format('YYYY-MM-DD HH:mm:ss'), 'expiryDateTime', index)} />
              </>
          }
        </>
      )
    },
    {
      title: '备注',
      key: 'workEditTableCol-9',
      render: (text, record, index) => (
        <>
          {
            !record.editable
              ? <>{ record.remarks }</>
              : <Input autoComplete='off' max={200} onChange={(e) => this.props.onChangeInput(e.target.value, 'remarks', index)} />
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'workEditTableCol-10',
      width: 120,
      fixed: 'right',
      render: (text, record, index) => {
        const { list } = this.props

        return (
          <>
            <Button type='link' size='small' onClick={() => this.props.onAddRow(index)}>添加</Button>

            {
              !record.editable
                ? null
                : <Button type='link' size='small' disabled={list.length < 2} onClick={() => this.handleDelete(record)}>删除</Button>
            }
          </>
        )
      }
    }
  ]

  componentDidMount () {}

  // 点击删除
  handleDelete = (record) => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        this.props.onDeleteRow([record])
      }
    })
  }

  render () {
    const { isPreview = false, list = [] } = this.props

    return (
      <>
        <Table bordered rowKey={(record, index) => record.id || record.rowKey}
          columns={isPreview ? $lodash.pullAllBy(this.columns, [{ key: 'workEditTableCol-10' }], 'key') : this.columns}
          dataSource={list}
          pagination={false}
          scroll={{ x: 1400 }}
        />
      </>
    )
  }
}

export default WorkEditable
