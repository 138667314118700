import React, { Component } from 'react'
import { Card, Tabs } from 'antd'

import PurchaseOrderContract from './purchaseOrderContract/index' // 云采购订单合同
import SupplyOrderContract from './supplyOrderContract/index' // 云供应订单合同
import EUOrderContract from './euOrderContract/index' // EU订单合同

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { key: 'JUST_PURCHASE_ORDER', name: '云采购订单合同' },
  { key: 'JUST_SUPPLY_ORDER', name: '云供应订单合同' },
  { key: 'EU_ORDER', name: 'EU订单合同' }
]

class ContractList extends Component {
  static propTypes = {}
  state = {
    activeKey: 'JUST_PURCHASE_ORDER'
  }

  handleChangeTabPane = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'JUST_PURCHASE_ORDER' } = this.state

    return (
      <>
        <div style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <div className={`${styles.tabWrap}`}>
              <Tabs activeKey={activeKey} onChange={(key) => this.handleChangeTabPane(key)}>
                {
                  tabPaneMaps.map((item) => (
                    <TabPane tab={item.name} key={item.key} />
                  ))
                }
              </Tabs>
            </div>
          </Card>
        </div>

        {
          activeKey === 'JUST_PURCHASE_ORDER'
            ? <PurchaseOrderContract contractOrderType={activeKey} />
            : null
        }

        {
          activeKey === 'JUST_SUPPLY_ORDER'
            ? <SupplyOrderContract contractOrderType={activeKey} />
            : null
        }

        {
          activeKey === 'EU_ORDER'
            ? <EUOrderContract contractOrderType={activeKey} />
            : null
        }
      </>
    )
  }
}

export default ContractList
