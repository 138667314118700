import Ajax from '../utils/ajax'

export default {
  // 获取优势品牌
  getHotBrandList (params) {
    return Ajax.get('/oms/official/website/hot/brand/query', { params })
  },

  // 获取销售出库单列表
  getBrandDetail (params) {
    return Ajax.get('/oms/official/website/hot/brand/trade/info/query', { params })
  }
}
