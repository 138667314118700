import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Icon, Button } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import { setUnnoticeCount, setNoticeDetail } from '@/reducers/action'

import styles from './notify.module.scss'

class NotifyDetail extends Component {
  static propTypes = {
    history: PropTypes.object,
    noticeDetail: PropTypes.object,
    dispatch: PropTypes.func
  }

  componentDidMount () {
    this.updateNoticeReadStatus()
  }

  componentDidUpdate () {
    this.updateNoticeReadStatus()
  }

  componentWillUnmount () {
    this.props.dispatch(setNoticeDetail({ noticeDetail: {} }))
  }

  // 标记消息已读
  updateNoticeReadStatus = () => {
    const { noticeDetail = {} } = this.props

    Api.updateNoticeReadStatus({ id: noticeDetail.id }).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getUnReadNotifyCount()
      }
    })
  }

  // 获取未读消息总数
  getUnReadNotifyCount = () => {
    Api.getUnReadNotifyCount({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.props.dispatch(setUnnoticeCount({ unnoticeCount: +data || 0 }))
      }
    })
  }

  // 点击返回
  handleLinkToInformationList = () => {
    this.props.history.push({ pathname: '/admin/informationList' })
  }

  render () {
    const { noticeDetail = {} } = this.props

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleLinkToInformationList()}>
            <Icon type='left' />返回列表
          </Button>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row className={`${styles.noticeWrap}`}>
              <h4 className={`${styles.noticeTitle}`}>{noticeDetail.title}</h4>
              <div className={`${styles.noticeProps}`}>{moment(noticeDetail.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              <div className={`${styles.noticeContent}`} dangerouslySetInnerHTML={{ __html: noticeDetail.content }} />
            </Row>
          </Card>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    noticeDetail: state.noticeDetail
  }
}

export default connect(mapStateToProps)(NotifyDetail)
