/**
*  消息通知模块
*/
import NotifyList from '@/pages/system-notifys/systemnotifyList/index' // 系统通知列表
import InformationList from '@/pages/system-notifys/informationList/index' // 信息列表
import EditsysNotify from '@/pages/system-notifys/editsysNotify/index' // 通知内容编辑
import InformationManage from '@/pages/system-notifys/informationManage/index' // 信息管理
import EditsysNotice from '@/pages/system-notifys/editsysNotice/index' // 编辑IIASaaS公告
import NotifyDetail from '@/pages/system-notifys/notifyDetail/index' // 查看消息内容

export default [
  {
    path: '/admin/systemNotifys',
    name: '系统通知',
    component: NotifyList
  },
  {
    path: '/admin/informationList',
    name: '系统通知',
    component: InformationList
  },
  {
    path: '/admin/editsysNotify',
    name: '通知内容编辑',
    component: EditsysNotify
  },
  {
    path: '/admin/informationManage',
    name: '信息管理',
    component: InformationManage
  },
  {
    path: '/admin/editsysNotice',
    name: '公告内容编辑',
    component: EditsysNotice
  },
  {
    path: '/admin/notifyDetail',
    name: '消息详情',
    component: NotifyDetail
  }
]
