import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Button } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class SupplyOrderContract extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    contractOrderType: PropTypes.string,
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '订单时间'
        },
        cptParams: {

        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '创建时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      width: 44,
      key: 'contractListCol-0',
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '合同名称',
      key: 'contractListCol-1',
      dataIndex: 'contractName'
    },
    {
      title: '订单编号',
      key: 'contractListCol-2',
      dataIndex: 'purchaseOrderId'
    },
    {
      title: '订单日期',
      key: 'contractListCol-3',
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>{ record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '供应商',
      key: 'contractListCol-4',
      dataIndex: 'distributorName',
      ellipsis: true
    },
    {
      title: '创建时间',
      key: 'contractListCol-5',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '操作',
      key: 'contractListCol-6',
      render: (text, record, index) => {
        const { buttonMaps = {} } = this.props

        return (
          <div>
            {
              buttonMaps['download'] && +buttonMaps['download'].auth === 1 && record.fileKey && record.fileKey !== ''
                ? <Button size='small' type='primary' onClick={() => this.handleDownLoadContract(record)}>下载</Button>
                : null
            }
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getContractList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[2].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取列表
  getContractList = () => {
    const { contractOrderType } = this.props
    const { pages, query } = this.state

    let params = {
      ...query,
      contractOrderType: contractOrderType,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getContractList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { orderTimes = [], times = [] } = search

    if (orderTimes.length === 2) {
      search.orderBeginDate = orderTimes[0].format('YYYY-MM-DD')
      search.orderEndDate = orderTimes[1].format('YYYY-MM-DD')
    }

    if (times.length === 2) {
      search.beginDate = times[0].format('YYYY-MM-DD')
      search.endDate = times[1].format('YYYY-MM-DD')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search || {}
    }, () => {
      this.getContractList()
    })
  }

  // 点击下载合同
  handleDownLoadContract = (record) => {
    const w = window.open('about:blank')
    w.location.href = `${record.fileKey}`
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getContractList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getContractList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>
        <Card bodyStyle={{ padding: '12px' }}>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(SupplyOrderContract)
