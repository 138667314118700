import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table, Descriptions, Popover } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import ResourcesIcon from '@/assets/images/icon/icon_resources.png'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel, renderCloudStoreLevelLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const { pageSizeOptions } = filter

class DetailsModal extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  static propTypes = {
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '云供应商'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    info: {},
    tableData: [], // 供应商列表
    merchantList: [], // 商户列表
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'supplyDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '供应商',
      key: 'supplyDetailsCol-1',
      width: 150,
      ellipsis: true,
      dataIndex: 'distributorName'
    },
    {
      title: '库存',
      key: 'supplyDetailsCol-4',
      width: 100,
      dataIndex: 'skuStock',
      render: (text, record, index) => {
        const { detailInfo = {} } = this.props

        return (
          <>{ detailInfo.skuGoodsType === 'CLOUD_FUTURES' ? <span style={{ fontSize: '16px' }}>&infin;</span> : <span>{ record.skuStock }</span>}</>
        )
      }
    },
    {
      title: '供应商价格',
      key: 'supplyDetailsCol-5',
      dataIndex: 'realSupplyPrice',
      render: (text, record, index) => (
        <>&yen;{ +record.realSupplyPrice }</>
      )
    },
    {
      title: '云仓价格',
      key: 'supplyDetailsCol-6',
      width: 120,
      render: (text, record, index) => (
        <>
          {
            +record.brandProfitMargin === 0 && +record.skuProfitMargin === 0
              ? <>&yen;{+record.supplyPrice}</>
              : <Row>
                <Popover trigger='click' content={this.renderPopoverContent(record)}>
                  <a>&yen;{+record.supplyPrice}<span style={{ marginLeft: '3px' }}><img src={ResourcesIcon} width={14} height={14} /></span></a>
                </Popover>
              </Row>
          }

        </>
      )
    },
    {
      title: '货期',
      key: 'supplyDetailsCol-7',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => {
        const { detailInfo = {} } = this.props

        return (
          <>
            {
              detailInfo.skuGoodsType === 'CLOUD_FUTURES'
                ? <>{ record.deliveryPeriodDays ? `${record.deliveryPeriodDays}工作日` : '----' }</>
                : '当天发货'
            }
          </>
        )
      }
    },
    {
      title: '预付比例',
      key: 'supplyDetailsCol-8',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => {
        const { detailInfo = {} } = this.props

        return (
          <>
            {
              detailInfo.skuGoodsType === 'CLOUD_FUTURES' && record.prepaymentRatio
                ? <>{(+record.prepaymentRatio * 100 / 100).toFixed(2)}%</>
                : '----'
            }
          </>
        )
      }
    },
    {
      title: '云仓等级',
      key: 'supplyDetailsCol-9',
      dataIndex: 'outDateLevel',
      render: (text, record, index) => (
        <>{ renderCloudStoreLevelLabel(record.outDateLevel) }</>
      )
    },
    {
      title: '更新时间',
      key: 'supplyDetailsCol-10',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    }
  ]

  componentDidMount () {
    this.getMerchantInfoByCloudSkuId()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取云供应商明细列表
  getMerchantInfoByCloudSkuId = () => {
    const { pages, query } = this.state
    const { detailInfo } = this.props

    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      commoditySkuId: detailInfo.commoditySkuId,
      skuGoodsType: detailInfo.skuGoodsType,
      ...query
    }

    if (detailInfo.distributorIds) {
      params.distributorIds = detailInfo.distributorIds
    }

    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = []
        let _total = 0

        if (data && data.supplierSkuInfoList) {
          _list = data.supplierSkuInfoList.list || []
          _total = data.supplierSkuInfoList.total || 0
        }

        this.setState({
          info: data || {},
          tableData: _list,
          pages: {
            ...pages,
            total: _total
          }
        })
      }
    })
  }

  handleSearch = (search) => {
    const { pages, query } = this.state

    this.setState({
      query: {
        ...query,
        ...search
      },
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getMerchantInfoByCloudSkuId()
    })
  }

  // 渲染popover 内容
  renderPopoverContent = (record) => {
    return <Row className={`${styles.popContent}`}>
      <Row className={`${styles.prodRow}`} style={{ marginBottom: '5px' }}>
        <label className={`${styles.rowLabel}`}>品牌利润率</label>
        <span>{ (+record.brandProfitMargin * 100 / 100).toFixed(2) }%</span>
      </Row>
      <Row className={`${styles.prodRow}`}>
        <label className={`${styles.rowLabel}`}>产品利润率</label>
        <span>{ (+record.skuProfitMargin * 100 / 100).toFixed(2) }%</span>
      </Row>
    </Row>
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMerchantInfoByCloudSkuId()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMerchantInfoByCloudSkuId()
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { searchData, tableData = [], pages } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total}条 ` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Modal title='供应商明细' bodyStyle={{ padding: '12px' }}
          width={1000}
          visible
          maskClosable={false}
          onOk={this.props.onCancel}
          onCancel={this.props.onCancel}
        >
          <Row style={{ marginBottom: '15px' }}>
            <Descriptions>
              <Descriptions.Item label='品牌'>
                {detailInfo.brandName}
              </Descriptions.Item>

              <Descriptions.Item label='型号'>
                {detailInfo.model}
              </Descriptions.Item>

              <Descriptions.Item label='订货号'>
                {detailInfo.itemNumber}
              </Descriptions.Item>

              <Descriptions.Item label='供应价格'>
                &yen;{+detailInfo.cloudSupplyPrice}
              </Descriptions.Item>

              <Descriptions.Item label='产品类型'>
                { renderSkuGoodsTypeLabel(detailInfo.skuGoodsType) }
              </Descriptions.Item>

              <Descriptions.Item label='产品名称'>
                {detailInfo.name}
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <Row style={{ marginBottom: '15px' }}>
            <SearchForm {...this.props} dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>

          <Row>
            <Table rowKey='distributorId' bordered
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
              scroll={{ x: 1200, y: 350 }}
            />
          </Row>
        </Modal>
      </>
    )
  }
}

export default DetailsModal
