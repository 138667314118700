import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Button, Upload, Icon, Select, message, Modal } from 'antd'
import $lodash from 'lodash'

import HandleModal from '../component/handleModal'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import DownloadApi from '@/common/api/downloadApi'
import { downloadFileByGet } from '@/common/utils/downloadFile'
import { getStore } from '@/common/utils/mUtils'

const { uploadSpecialPriceUrl } = UploadApi
const { downSpecialPriceTemplate } = DownloadApi
const Option = Select.Option

export default class ToolsLand extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {
    onSelect: PropTypes.func
  }
  state = {
    merchantList: [],
    selectedDistributorIds: [], // 选择的商户ID集合
    batchNo: '', // 失败的导入批次号
    handleModal: false
  }

  componentDidMount () {

  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    let params = {
      distributorName: val
    }
    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        this.setState({
          merchantList: data || []
        })
      }
    })
  }

  // 选择商户
  handleSelectedDistributorIds = (keys) => {
    this.setState({
      selectedDistributorIds: keys
    }, () => {
      this.props.onSelect(keys)
    })
  }

  // 点击下载失败详情
  getSetSpecialPriceUploadFailDownloadLink = () => {
    const { batchNo = '' } = this.state

    if (batchNo === '') {
      return message.warning('无导入批次，不可下载')
    }

    Api.getSetSpecialPriceUploadFailDownloadLink({ batchNo: batchNo }).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        if (data && data !== '') {
          // window.open(`${host}${data}`)
          downloadFileByGet(data, {})
        }
      }
    })
  }

  // 点击下载模板
  handleDownloadTempelete = () => {
    downSpecialPriceTemplate({})
  }

  // 监听EXCEL上传
  changeUploadFile = (info) => {
    if (info.file.status === 'done') {
      this.setState({
        handleModal: true
      }, () => {
        const { code, data } = info.file.response
        let msg = info.file.response.message

        if (+code === 10000) {
          if (data && +data.result > 0) {
            setTimeout(() => {
              this.showUploadResultModal('SUCCESS')
            }, 2000)
          } else {
            this.showUploadResultModal('FAIL', { msg: msg, data: data })
          }
        } else {
          message.error(msg)
          this.closeModal()
        }
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name}上传失败`)
      this.closeModal()
    }
  }

  // 反馈结果弹窗
  showUploadResultModal = (type = 'SUCCESS', result = {}) => {
    this.setState({
      handleModal: false
    }, () => {
      if (type === 'SUCCESS') {
        this.countDown()
      }

      if (type === 'FAIL') {
        this.showUploadFailModal(result)
      }
    })
  }

  // 导入失败反馈提示
  showUploadFailModal = (result = {}) => {
    Modal.confirm({
      icon: <Icon type='close-circle' style={{ color: '#f5222d' }} />,
      title: '特价设置失败',
      content: result.msg,
      okText: '失败详情',
      onOk: () => {
        this.setState({
          batchNo: result.data.batchNo
        }, () => {
          this.getSetSpecialPriceUploadFailDownloadLink()
        })
      }
    })
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5
    const modal = Modal.success({
      title: '特价设置成功！',
      content: <div>
        <p>
          你可在<span style={{ display: 'inline-block', padding: '0px 5px', color: '#1890ff' }}>云特价设置记录</span>查看此次设置
        </p>
        <p>{secondsToGo} s后将关闭该窗口</p>
      </div>,
      onOk: () => {}
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: <div>
          <p>
            你可在<span style={{ display: 'inline-block', padding: '0px 5px', color: '#1890ff' }}>云特价设置记录</span>查看此次设置
          </p>
          <p>{secondsToGo} s后将关闭该窗口</p>

        </div>
      })
    }, 1000)

    setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
    }, secondsToGo * 1000)
  }

  closeModal = () => {
    this.setState({
      handleModal: false
    })
  }

  render () {
    const { merchantList = [], selectedDistributorIds = [], batchNo = '', handleModal = false } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '10px' }}>
          <label className={`formLabel required`}>商户名称</label>
          <Select style={{ width: '100%' }} placeholder='请选择商户(可多选)...'
            mode='multiple'
            showSearch
            optionFilterProp='children'
            onSearch={(val) => this.getMerchantListByKeyword(val)}
            onChange={this.handleSelectedDistributorIds}
          >
            {
              merchantList.map((mitem, n) => (
                <Option value={`${mitem.id}`} key={mitem.id}>{mitem.distributorName}</Option>
              ))
            }
          </Select>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Upload name='importFile' showUploadList={false}
            headers={{ Token: getStore('token') }}
            accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            data={{ disIdStr: selectedDistributorIds.join(',') }}
            action={uploadSpecialPriceUrl}
            onChange={(info) => this.changeUploadFile(info)}
          >
            <Button size='small' type='primary' disabled={selectedDistributorIds.length === 0}>
              <Icon type='upload' />导入文件
            </Button>
          </Upload>

          <Button size='small' type='primary' style={{ marginLeft: '15px' }}
            onClick={this.handleDownloadTempelete}
          >
            <Icon type='download' />下载模板
          </Button>

          {
            batchNo !== ''
              ? <Button size='small' type='primary' style={{ marginLeft: '15px' }}
                onClick={() => this.getSetSpecialPriceUploadFailDownloadLink()}
              >
                <Icon type='download' />失败详情
              </Button>
              : null
          }

        </Row>

        {
          handleModal
            ? <HandleModal
              letterArr={['文', '件', '分', '析', '中', '...']}
            />
            : null
        }
      </div>
    )
  }
}
