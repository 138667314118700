import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Table, Button } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import styles from '../index.module.scss'

class EnquiryOver extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    tableData: []
  }
  columns = [
    {
      title: '询价单编号',
      key: 'ENQUIRY_WARNING_enquiryOrderId',
      dataIndex: 'enquiryOrderId'
    },
    {
      title: '询价日期',
      key: 'ENQUIRY_WARNING_submitTime',
      dataIndex: 'submitTime',
      render: (text, record, index) => (
        <>{ record.submitTime ? moment(record.submitTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '询价商户',
      key: 'ENQUIRY_WARNING_distributorIdId',
      dataIndex: 'distributorName'
    },
    {
      title: '操作',
      key: 'ENQUIRY_WARNING_OPERATION',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleLinkTo(record)}>去回复</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getEnquiryOverWarningList()
  }

  // 获取询价单逾期预警列表
  getEnquiryOverWarningList = async () => {
    const res = await Api.getEnquiryOverWarningList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 点击去回复
  handleLinkTo = () => {
    this.props.history.push({ pathname: '/admin/enquireOrderList' })
  }

  render () {
    const { tableData } = this.state

    return (
      <>
        <div className={`${styles.title}`}>询价单逾期预警(4小时)</div>
        <Table rowKey={(record, index) => `ENQUIRY_WARNING_RECORD-${record.enquiryOrderId}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />
      </>
    )
  }
}

export default withRouter(EnquiryOver)
