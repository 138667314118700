import React, { useState } from 'react'
import { Card, Menu } from 'antd'

import OrderDelivery from './orderDelivery/index'
import BrandAuthOver from './brandAuthOver/index'
import EnquiryOver from './enquiryOver/index'
import SyncOver from './syncStop/index'
import AlikeEnquiry from './alikeEnquiry' // 相同询价

import styles from './index.module.scss'

const menuNodes = [
  { id: 'ORDER_DELIVERY', name: '主订单交期预警(7天)' },
  { id: 'BRANDAUTH_OVER', name: '代理证到期预警(15天)' },
  { id: 'ENQUIRY_OVER', name: '询价单逾期预警(4小时)' },
  { id: 'SYNC_STOP', name: '同步停止预警(3天)' },
  { id: 'ALIKE_ENQUIRY', name: '相同询价预警' }
]

// 预警系统
export default function EarlyWarning () {
  const [activeKey, setActiveKey] = useState('ORDER_DELIVERY')
  const [selectedKeys, setSelectedKeys] = useState(['ORDER_DELIVERY'])

  const handleChangeMenu = (record) => {
    setActiveKey(record.key)
    setSelectedKeys([record.key])
  }

  return (
    <>
      <Card bodyStyle={{ padding: '12px 0px' }}>
        <div className={`${styles.content}`}>
          <div className={`${styles.leftMenu}`}>
            <Menu mode='inline' width={200} selectedKeys={selectedKeys} onClick={(key) => handleChangeMenu(key)}>
              {
                menuNodes.map((item) => (
                  <Menu.Item key={item.id}>{ item.name }</Menu.Item>
                ))
              }
            </Menu>
          </div>

          <div className={`${styles.rightContent}`}>
            {
              (
                () => {
                  switch (activeKey) {
                    case 'ORDER_DELIVERY':
                      return <OrderDelivery />
                    case 'BRANDAUTH_OVER':
                      return <BrandAuthOver />
                    case 'ENQUIRY_OVER':
                      return <EnquiryOver />
                    case 'SYNC_STOP':
                      return <SyncOver />
                    case 'ALIKE_ENQUIRY':
                      return <AlikeEnquiry />
                    default:
                      return null
                  }
                }
              )()
            }
          </div>
        </div>
      </Card>
    </>
  )
}
