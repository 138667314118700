import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Upload, Input, Radio, Checkbox, Switch, Button, Icon, message } from 'antd'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'
import UploadPDFItem from '@/components/uploadPDFItem'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item
const { uploadNeedLogoImageUrl, uploadOrdinaryImageUrl } = UploadApi

class UploadImageItem extends Component {
  static propTypes = {
    form: PropTypes.object,
    hasSwitch: PropTypes.bool,
    defaultSkuImageList: PropTypes.array,
    defaultDetailImageList: PropTypes.array,
    defaultPDFImageList: PropTypes.array
  }
  state = {
    skuImageUrls: [],
    skuDetailImageUrls: [],
    skuDetailPdfUrls: []
  }

  componentDidMount () {
    const { defaultSkuImageList = [], defaultDetailImageList = [], defaultPDFImageList = [] } = this.props

    this.setState({
      skuImageUrls: defaultSkuImageList,
      skuDetailImageUrls: defaultDetailImageList,
      skuDetailPdfUrls: defaultPDFImageList
    })
  }

  // 上传产品图片 & 详情图片 & 产品PDF
  handleChangeUploadImages = (info, key) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        let activeImages = this.state[key]

        activeImages.push(data.imageUrl)

        this.setState({
          [key]: [...activeImages]
        }, () => {
          this.props.form.setFieldsValue({ [key]: activeImages.join(',') })
        })
      } else {
        message.error('上传失败')
      }
    } else if (info.file.status === 'error') {
      message.error('上传失败')
    }
  }

  // 删除图片
  handleRemoveUpload = (index, value, key) => {
    const { setFieldsValue, getFieldValue } = this.props.form
    let activeImages = this.state[key]
    let checkedImages = getFieldValue(`checked${key}`) !== undefined ? getFieldValue(`checked${key}`).split(',') : []

    const newCheckdatas = $lodash.filter(checkedImages, (item) => { return item !== value })

    activeImages.splice(index, 1)

    this.setState({
      [key]: [...activeImages]
    }, () => {
      setFieldsValue({ [key]: activeImages.join(','), [`checked${key}`]: newCheckdatas.length > 0 ? newCheckdatas.join(',') : undefined })
    })
  }

  // 单选产品图片设置
  handleRadioSetImage = (value, key) => {
    this.props.form.setFieldsValue({ [key]: value })
  }

  // 多选详情图片设置
  handleCheckboxSetImages = (checked, value, key) => {
    const { getFieldValue, setFieldsValue } = this.props.form

    let arr = getFieldValue(key) !== undefined ? getFieldValue(key).split(',') : []

    if (checked) {
      arr.push(value)
    } else {
      const i = arr.findIndex((o) => { return o === value })
      arr.splice(i, 1)
    }

    setFieldsValue({ [key]: arr.join(',') })
  }

  render () {
    const { form, hasSwitch = false } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { skuImageUrls = [], skuDetailImageUrls = [], skuDetailPdfUrls = [] } = this.state

    return (
      <>
        <FormItem label={'产品图片'} style={{ display: 'none' }}>
          <FormItem>
            {
              getFieldDecorator('skuImageUrls', {

              })(<Input disabled autoComplete='off' />)
            }
          </FormItem>
          <FormItem>
            {
              getFieldDecorator('checkedskuImageUrls', {

              })(<Input disabled autoComplete='off' />)
            }
          </FormItem>
        </FormItem>

        <FormItem style={{ marginBottom: '0px' }}>
          <div>
            <FormItem style={{ display: 'inline-block', marginBottom: '0px' }}>
              <label className='label'>产品图片</label><span style={{ display: 'inline-block', minWidth: '200px', marginLeft: '8px', color: '#F5222D' }}>(建议上传600*600尺寸的图片)</span>
            </FormItem>

            {
              hasSwitch
                ? <FormItem style={{ display: 'inline-block', marginBottom: '0px', marginLeft: '24px' }}>
                  {
                    getFieldDecorator('skuImageDisabledStatus', {
                      initialValue: true,
                      valuePropName: 'checked'
                    })(
                      <Switch checkedChildren='启用' unCheckedChildren='禁用' />
                    )
                  }
                </FormItem>
                : null
            }

          </div>

          <div>
            {
              skuImageUrls.length > 0
                ? <>
                  {
                    skuImageUrls.map((item, index) => (
                      <div className={`${styles.thumbnailLine}`} key={`skuBaseImages-${index}`}>
                        <UploadThumbnail imageUrl={item} onDelete={() => this.handleRemoveUpload(index, item, 'skuImageUrls')} />
                        <Radio value={item} checked={getFieldValue('checkedskuImageUrls') === item}
                          onChange={(e) => this.handleRadioSetImage(e.target.value, 'checkedskuImageUrls')}>设置主要</Radio>
                      </div>
                    ))
                  }
                </>
                : null
            }

            <div className={`${styles.thumbnailLine}`}>
              <Upload showUploadList={false}
                accept='image/png, image/jpg, image/jpeg'
                headers={{ Token: getStore('token') }}
                action={uploadNeedLogoImageUrl}
                data={{ isMainImage: true }}
                listType='picture-card'
                onChange={(info) => this.handleChangeUploadImages(info, 'skuImageUrls')}
              >
                <div>
                  <Icon type='plus' />
                  <div>点击上传</div>
                </div>
              </Upload>
            </div>
          </div>
        </FormItem>

        <FormItem label='详情图片' style={{ display: 'none' }}>
          <FormItem>
            {
              getFieldDecorator('skuDetailImageUrls', {

              })(<Input disabled autoComplete='off' />)
            }
          </FormItem>
          <FormItem>
            {
              getFieldDecorator('checkedskuDetailImageUrls', {

              })(<Input disabled autoComplete='off' />)
            }
          </FormItem>
        </FormItem>
        <FormItem style={{ marginBottom: '0px' }}>
          <div>
            <FormItem style={{ display: 'inline-block', marginBottom: '0px' }}>
              <label className='label'>详情图片</label><span style={{ display: 'inline-block', minWidth: '200px', marginLeft: '8px', color: '#F5222D' }}>(建议上传图片的宽度为1000px~1500px)</span>
            </FormItem>

            {
              hasSwitch
                ? <FormItem style={{ display: 'inline-block', marginBottom: '0px', marginLeft: '24px' }}>
                  {
                    getFieldDecorator('skuDetailDisabledStatus', {
                      initialValue: true,
                      valuePropName: 'checked'
                    })(
                      <Switch checkedChildren='启用' unCheckedChildren='禁用' />
                    )
                  }
                </FormItem>
                : null
            }

          </div>

          <div>
            {
              skuDetailImageUrls.length > 0
                ? <>
                  {
                    skuDetailImageUrls.map((item, index) => (
                      <div className={`${styles.thumbnailLine}`} key={`skuBaseDetailsImages-${index}`}>
                        <UploadThumbnail imageUrl={item} onDelete={() => this.handleRemoveUpload(index, item, 'skuDetailImageUrls')} />
                        <Checkbox checked={$lodash.includes(getFieldValue('checkedskuDetailImageUrls'), item)}
                          onChange={(e) => this.handleCheckboxSetImages(e.target.checked, item, 'checkedskuDetailImageUrls')}
                        >
                          设置显示
                        </Checkbox>
                      </div>
                    ))
                  }
                </>
                : null
            }

            <div className={`${styles.thumbnailLine}`}>
              <Upload showUploadList={false}
                accept='image/png, image/jpg, image/jpeg'
                headers={{ Token: getStore('token') }}
                action={uploadNeedLogoImageUrl}
                listType='picture-card'
                onChange={(info) => this.handleChangeUploadImages(info, 'skuDetailImageUrls')}
              >
                <div>
                  <Icon type='plus' />
                  <div>点击上传</div>
                </div>
              </Upload>
            </div>
          </div>
        </FormItem>

        <FormItem label='产品PDF' style={{ display: 'none' }}>
          <FormItem>
            {
              getFieldDecorator('skuDetailPdfUrls', {

              })(<Input disabled autoComplete='off' />)
            }
          </FormItem>
          <FormItem>
            {
              getFieldDecorator('checkedskuDetailPdfUrls', {

              })(<Input disabled autoComplete='off' />)
            }
          </FormItem>
        </FormItem>
        <FormItem style={{ marginBottom: '0px' }}>
          <div>
            <FormItem style={{ display: 'inline-block', marginBottom: '0px' }}>
              <label className='label'>产品PDF</label><span style={{ display: 'inline-block', minWidth: '200px', marginLeft: '8px', color: '#F5222D' }}>(建议上传PDF大小10M以下)</span>
            </FormItem>

            {
              hasSwitch
                ? <FormItem style={{ display: 'inline-block', marginBottom: '0px', marginLeft: '24px' }}>
                  {
                    getFieldDecorator('skuPDFDisabledStatus', {
                      initialValue: true,
                      valuePropName: 'checked'
                    })(
                      <Switch checkedChildren='启用' unCheckedChildren='禁用' />
                    )
                  }
                </FormItem>
                : null
            }
          </div>

          <div>
            <div>
              <Upload showUploadList={false}
                accept='pdf'
                headers={{ Token: getStore('token') }}
                action={uploadOrdinaryImageUrl}
                onChange={(info) => this.handleChangeUploadImages(info, 'skuDetailPdfUrls')}
              >
                <Button>
                  <Icon type='upload' />
                  <span>点击上传</span>
                </Button>
              </Upload>
            </div>
          </div>
        </FormItem>

        {
          skuDetailPdfUrls.length > 0
            ? <>
              {
                skuDetailPdfUrls.map((item, index) => (
                  <div className={`${styles.previewUploadItem}`} key={`skuBasePDFImages-${index}`}>
                    <div>
                      <UploadPDFItem imageUrl={item} onDelete={() => this.handleRemoveUpload(index, item, 'skuDetailPdfUrls')} />
                    </div>
                    <div className={`${styles.previewUploadItemAction}`}>
                      <Radio
                        checked={getFieldValue('checkedskuDetailPdfUrls') === item}
                        onChange={() => this.handleRadioSetImage(item, 'checkedskuDetailPdfUrls')}
                      >设置唯一显示</Radio>
                    </div>
                  </div>
                ))
              }
            </>
            : null
        }
      </>
    )
  }
}

export default UploadImageItem
