import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Form, InputNumber, Button, message, Modal } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'

const FormItem = Form.Item
const { confirm } = Modal

class CloudRuleForm extends Component {
  static propTypes = {
    form: PropTypes.object
  }
  state = {
    spotRuledatas: {},
    futuresRuledatas: {},
    editing: false
  }

  componentDidMount () {
    this.getCloudRuleDetail()
  }

  // 获取规则详情
  getCloudRuleDetail = async () => {
    const res = await Api.getCloudRuleDetail({ pageNo: 1, pageSize: 20 })
    const { code, data } = res
    if (+code === 10000) {
      let _datas = []
      if (data && data.list && data.list.length > 0) {
        _datas = data.list
      }

      this.setState({
        spotRuledatas: _datas.length > 0 ? $lodash.find(_datas, (o) => { return o.ruleType === 'CLOUD_SPOT_PROFIT' }) : {},
        futuresRuledatas: _datas.length > 0 ? $lodash.find(_datas, (o) => { return o.ruleType === 'CLOUD_FUTURE_PROFIT' }) : {}
      })
    }
  }

  // 提交
  submitSetRule = async (params) => {
    const res = await Api.saveCloudRule(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.setState({
        editing: false
      }, () => { this.getCloudRuleDetail() })
    }
  }

  // 点击编辑/取消编辑
  handleEdit = (type) => {
    this.setState({
      editing: type === 1
    })
  }

  // 点击提交
  handleSubmit = () => {
    confirm({
      title: '确定提交修改？',
      content: '',
      onOk: () => {
        this.props.form.validateFields((err, values) => {
          if (!err) {
            const { spotRuledatas = {}, futuresRuledatas = {} } = this.state
            let spotObj = { id: spotRuledatas.id, ruleParam: values.spotRuleParam }
            let futuresObj = { id: futuresRuledatas.id, ruleParam: values.futuresRuleParam }

            this.submitSetRule({ ruleSettingsList: [spotObj, futuresObj] })
          }
        })
      }
    })
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    }

    const { getFieldDecorator } = this.props.form

    const { spotRuledatas = {}, futuresRuledatas = {}, editing = false } = this.state

    return (
      <>
        <Card>
          <Row style={{ width: '500px', margin: '0px auto' }}>
            <Form>
              <FormItem label='云现货权限价利润' {...formItemLayout}>
                {
                  getFieldDecorator('spotRuleParam', {
                    initialValue: spotRuledatas.ruleParam ? +spotRuledatas.ruleParam : null,
                    rules: [
                      { required: true, message: '请输入利润率' }
                    ]
                  })(
                    <InputNumber autoComplete='off' min={0} max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      disabled={!editing}
                      style={{ width: '160px' }}
                    />
                  )
                }
              </FormItem>
              <FormItem label='云期货权限价利润' {...formItemLayout}>
                {
                  getFieldDecorator('futuresRuleParam', {
                    initialValue: futuresRuledatas.ruleParam ? futuresRuledatas.ruleParam : null,
                    rules: [
                      { required: true, message: '请输入利润率' }
                    ]
                  })(
                    <InputNumber autoComplete='off' min={0} max={100}s
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      disabled={!editing}
                      style={{ width: '160px' }}
                    />
                  )
                }
              </FormItem>
              <FormItem wrapperCol={{ span: 16, offset: 8 }}>
                {
                  !editing
                    ? <Button type='primary' onClick={() => this.handleEdit(1)}>编辑</Button>

                    : <>
                      <Button type='primary' onClick={() => this.handleSubmit()}>提交</Button>

                      <Button style={{ marginLeft: '16px' }}onClick={() => this.handleEdit(2)}>取消</Button>
                    </>
                }
              </FormItem>
            </Form>
          </Row>
        </Card>
      </>
    )
  }
}

const CloudRule = Form.create()(CloudRuleForm)

export default CloudRule
