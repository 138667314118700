import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Switch, Button, Icon } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import EnquiryDetailModal from './enquiryDetailModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { pageSizeOptions, disableStatusMaps, repLineBreak } = filter
const { MAX_PAGESIZE } = consts

class EnquireInformationList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'platformUserId',
        formType: 'Select',
        itemParams: {
          label: '发布人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '发布时间'
        },
        cptParams: {}
      },
      {
        name: 'enableStatus',
        formType: 'Select',
        itemParams: {
          label: '是否启用'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          ...disableStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    enquiryDetailModal: false,
    detailInfo: {}
  }
  columns = [
    {
      title: '序号',
      key: 'enquireInformationCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '询价内容',
      key: 'enquireInformationCol-1',
      ellipsis: true,
      dataIndex: 'enquiryContent',
      render: (text, record, index) => (
        <>
          <a onClick={() => this.showEnquiryDetailModal(record)}>{ record.enquiryContent }</a>
        </>
      )
    },
    {
      title: '联系人',
      key: 'enquireInformationCol-2',
      width: 120,
      dataIndex: 'contactsUserName'
    },
    {
      title: '联系方式',
      key: 'enquireInformationCol-3',
      width: 140,
      dataIndex: 'contactsMobile'
    },
    {
      title: '发布人',
      key: 'enquireInformationCol-4',
      width: 120,
      dataIndex: 'platformUserName'
    },
    {
      title: '发布时间',
      key: 'enquireInformationCol-5',
      width: 140,
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '截止时间',
      key: 'enquireInformationCol-6',
      width: 140,
      dataIndex: 'deadline',
      render: (text, record, index) => (
        <>{ record.deadline ? moment(record.deadline).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '是否启用',
      key: 'enquireInformationCol-7',
      dataIndex: 'enableStatus',
      width: 120,
      render: (text, record, index) => (
        <>
          <Switch
            checked={record.enableStatus === 'ENABLE'}
            checkedChildren='启用'
            unCheckedChildren='禁用'
            onChange={(checked) => this.handleChangeSwitchStatus(checked, record)}
          />
        </>
      )
    },
    {
      title: '操作',
      key: 'enquireInformationCol-8',
      width: 140,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleLinkToCreate(record, 'EDIT')}>编辑</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getEnquireInformationPublishList()
  }

  // 获取全部成员
  getMemberList = (val = '') => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[0].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取信息列表
  getEnquireInformationPublishList = async () => {
    const { query = {}, pages } = this.state
    const res = await Api.getEnquireInformationPublishList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存编辑询价信息
  saveEditEnquireInformationPublish = async (params) => {
    const res = await Api.saveEditEnquireInformationPublish(params)
    const { code } = res
    if (+code === 10000) {
      this.getEnquireInformationPublishList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search.beginDate = search.times.length === 2 ? moment(search.times[0]).format('YYYY-MM-DD') : undefined
      search.endDate = search.times.length === 2 ? moment(search.times[1]).format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getEnquireInformationPublishList()
    })
  }

  // 切换是否启用状态
  handleChangeSwitchStatus = (checked, record) => {
    const params = {
      enableStatus: checked ? 'ENABLE' : 'DISABLE',
      id: record.id
    }

    this.saveEditEnquireInformationPublish(params)
  }

  // 点击新增信息、编辑信息
  handleLinkToCreate = (record, type) => {
    this.props.history.push({ pathname: '/admin/createEnquireInformation', state: { detailInfo: record, type: type } })
  }

  // 点击查看详情
  showEnquiryDetailModal = (record) => {
    this.setState({
      detailInfo: record,
      enquiryDetailModal: true
    })
  }

  closeModal = () => {
    this.setState({
      detailInfo: {},
      enquiryDetailModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEnquireInformationPublishList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEnquireInformationPublishList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], enquiryDetailModal = false, detailInfo = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)} />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handleLinkToCreate({}, 'ADD')}><Icon type='plus' />新建信息</Button>
          </div>
          <Table rowKey='id' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        {
          enquiryDetailModal
            ? <EnquiryDetailModal
              content={repLineBreak(detailInfo.enquiryContent, '<br/>')}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default EnquireInformationList
