import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button, Icon } from 'antd'
import $lodash from 'lodash'

import InvoiceExpressForm from '@/components/invoiceExpressForm/index' // 开票物流&凭证信息
import InvoiceAccount from '@/components/invoiceAccount/index' // 开票企业信息
import InvoiceProduct from '@/components/invoiceProduct/index' // 开票物资信息
import InvoiceReceiving from '@/components/invoiceReceiving/index' // 发票收件地址
import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

class InvoiceRecordForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    count: 0,
    invoiceVoucherInfo: {}, // 发票物流& 发票凭证信息
    invoiceCodeKeys: [], // 发票号码集合
    tableData: [], // 发票物料信息
    invoiceInfo: {}, // 发票抬头信息
    receivingAddress: {}, // 收件信息
    sendAddressInfo: {}, // 发货地址信息
    totalAmount: 0, // 发票金额
    addressModal: false
  }

  componentDidMount () {
    this.getPurchaseOrderInvoiceOpenedDetails()
  }

  // 获取云采购发票- 开票完成详情
  getPurchaseOrderInvoiceOpenedDetails = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getPurchaseOrderInvoiceOpenedDetails({ id: detailInfo.id })
    const { code, data } = res
    if (+code === 10000) {
      const { merchantInvoiceRecord = {} } = data
      let _invoiceCodeKeys = [{ id: 0, value: undefined }]

      if (merchantInvoiceRecord.invoiceCode && merchantInvoiceRecord.invoiceCode !== '') {
        const arr = $lodash.split(merchantInvoiceRecord.invoiceCode, ',')
        _invoiceCodeKeys = $lodash.map(arr, (o, i) => { return { id: i, value: o } })
      }

      this.setState({
        invoiceVoucherInfo: data.merchantInvoiceRecord || {},
        invoiceCodeKeys: _invoiceCodeKeys,
        count: _invoiceCodeKeys.length - 1,
        invoiceInfo: data.invoiceRecordInfo || {},
        tableData: data.orderGoodsListMap || [],
        receivingAddress: data.invoiceRecordReceiverAddress || {},
        totalAmount: data.orderGoodsListMap && data.orderGoodsListMap.length > 0 ? $lodash.sumBy(data.orderGoodsListMap, 'totalPrice') : 0
      })
    }
  }

  // 点击确认修改
  handleSubmit = () => {
    const { form, detailInfo = {}, onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const { courierCode, invoiceCodeKeys = [] } = values
        const { receivingAddress = {} } = this.state
        const _invoiceCodeKeys = $lodash.filter(invoiceCodeKeys, (o) => { return o !== undefined })

        values.courierCode = $lodash.includes(courierCode, 'SAAS') ? $lodash.split(courierCode, '-')[1] : courierCode
        values.invoiceCode = _invoiceCodeKeys.length > 0 ? _invoiceCodeKeys.join(',') : undefined
        values.id = detailInfo.id
        values.addressInfo = receivingAddress

        onConfirm(values)
      }
    })
  }

  // 点击导出表格
  handleDownloadFile = () => {
    const { detailInfo = {} } = this.props
    DownloadApi.downloadPurchaseOrderInvoiceOpenedFile({ id: detailInfo.id })
  }

  // 点击导出电子发票表格
  handleDownloadElectronicFile = () => {
    const { detailInfo = {} } = this.props
    DownloadApi.downloadPurchaseOrderElectronicInvoiceOpenedFile({ id: detailInfo.id })
  }

  // 新增invoiceCode FormItem
  handleAddInvoiceCodeFormItem = (index) => {
    const { count = 0, invoiceCodeKeys = [{ id: 0, value: undefined }] } = this.state

    invoiceCodeKeys.splice(index + 1, 0, { id: count + 1, value: undefined })

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys,
      count: count + 1
    })
  }

  // 删除invoiceCode FormItem
  handleRemoveInvoiceCodeFormItem = (index) => {
    const { invoiceCodeKeys = [0] } = this.state

    if (invoiceCodeKeys.length === 1) { return false }

    invoiceCodeKeys.splice(index, 1)

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys
    })
  }

  showAddressModal = () => {
    this.setState({
      addressModal: true
    })
  }

  // 确定修改地址
  handleEditReceivingAddress = (values) => {
    this.setState({
      receivingAddress: values,
      addressModal: false
    })
  }

  closeModal = () => {
    this.setState({
      addressModal: false
    })
  }

  render () {
    const { form, detailInfo = {}, onCancel } = this.props
    const { invoiceVoucherInfo = {}, invoiceCodeKeys = [], tableData = [], invoiceInfo = {}, totalAmount = 0, receivingAddress = {}, addressModal = false } = this.state

    return (
      <>
        <Modal title='查看详情' width={1000} bodyStyle={{ padding: '12px', height: '500px', overflowY: 'auto' }}
          maskClosable={false}
          visible
          onCancel={() => onCancel()}
          footer={<div style={{ textAlign: 'center' }} key={`recordfooterBtns`}>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleDownloadElectronicFile()}><Icon type='download' />导出电子发票表格</Button>
            <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleDownloadFile()}><Icon type='download' />导出纸质发票表格</Button>
            <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleSubmit()}>确认修改</Button>
          </div>}
        >
          <Form>
            <div>
              <InvoiceExpressForm
                form={form}
                openStatus={detailInfo.status}
                info={invoiceVoucherInfo}
                invoiceCodeKeys={invoiceCodeKeys}
                addInvoiceCodeFormItem={(index) => this.handleAddInvoiceCodeFormItem(index)}
                removeInvoiceCodeFormItem={(index) => this.handleRemoveInvoiceCodeFormItem(index)}
              />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceAccount invoiceInfo={invoiceInfo} />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceProduct list={tableData} totalAmount={totalAmount} />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceReceiving form={form}
                isEdit={false}
                receivingAddressUpdate
                receivingAddress={receivingAddress}
                sendAddressInfo={invoiceVoucherInfo}
                handleAddAddress={() => this.showAddressModal()}
              />
            </div>
          </Form>
        </Modal>

        {
          addressModal
            ? <AddressModal
              detailInfo={receivingAddress}
              onConfirm={(values) => this.handleEditReceivingAddress(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const InvoiceRecordModal = Form.create()(InvoiceRecordForm)

export default InvoiceRecordModal
