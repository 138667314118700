import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Cascader } from 'antd'
import $lodash from 'lodash'

import { addressToTree } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item
const cityOptions = addressToTree()

class InvoiceAccountForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  handleConfirm = () => {
    const { form } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const { citys } = values
        $lodash.forEach(values, (value, key) => {
          if (key !== 'citys') {
            values[key] = $lodash.trim(value)
          }
        })

        values.province = citys[0]
        values.city = citys[1]
        values.county = citys[2]

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { form, detailInfo = {} } = this.props
    const { getFieldDecorator } = form

    return (
      <>
        <Modal title='发票信息'
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='公司名称'>
                {
                  getFieldDecorator('invoiceTitle', {
                    initialValue: detailInfo.invoiceTitle,
                    rules: [
                      { required: true, whitespace: true, message: '请填写公司名称' }
                    ]
                  })(<Input placeholder='请输入...' autoComplete='off' />)
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='统一社会信用代码'>
                {
                  getFieldDecorator('creditNo', {
                    initialValue: detailInfo.creditNo,
                    rules: [
                      { required: true, whitespace: true, message: '请填写统一社会信用代码' }
                    ]
                  })(<Input placeholder='请输入...' autoComplete='off' />)
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='地区'>
                {
                  getFieldDecorator('citys', {
                    initialValue: [`${detailInfo.province}`, `${detailInfo.city}`, `${detailInfo.county}`],
                    rules: [
                      { required: true, message: '请填写公司注册省市区' }
                    ]
                  })(<Cascader placeholder='请选择省市区'
                    fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                    options={cityOptions}
                  />)
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='注册地址'>
                {
                  getFieldDecorator('address', {
                    initialValue: detailInfo.address,
                    rules: [
                      { required: true, whitespace: true, message: '请填写公司注册详细地址' }
                    ]
                  })(<Input placeholder='请输入...' autoComplete='off' />)
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='开票电话'>
                {
                  getFieldDecorator('tel', {
                    initialValue: detailInfo.tel,
                    rules: [
                      { required: true, whitespace: true, message: '请填写开票电话' }
                    ]
                  })(<Input placeholder='请输入...' autoComplete='off' />)
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='开户银行'>
                {
                  getFieldDecorator('bankName', {
                    initialValue: detailInfo.bankName,
                    rules: [
                      { required: true, whitespace: true, message: '请填写开户银行' }
                    ]
                  })(<Input placeholder='请输入...' autoComplete='off' />)
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='银行账号'>
                {
                  getFieldDecorator('bankCard', {
                    initialValue: detailInfo.bankCard,
                    rules: [
                      { required: true, whitespace: true, message: '请填写银行账号' }
                    ]
                  })(<Input placeholder='请输入...' autoComplete='off' />)
                }
              </FormItem>
            </div>
          </Form>
        </Modal>
      </>
    )
  }
}

const InvoiceAccountFormModal = Form.create()(InvoiceAccountForm)

export default InvoiceAccountFormModal
