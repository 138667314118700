import React, { Component } from 'react'
import { Card, Table, Button, Icon, Tooltip, message } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderWorkTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { pageSizeOptions, repLineBreak, workOrderStateMaps, workOrderTypeMaps } = filter
const { MAX_PAGESIZE } = consts

class WorkQuoteBoard extends Component {
  constructor (props) {
    super(props)
    this.getMemberList = $lodash.debounce(this.getMemberList, 300)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'workOrderId',
        formType: 'Input',
        itemParams: {
          label: '工单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '派发日期'
        },
        cptParams: {}
      },
      {
        name: 'fromPlatformUserId',
        formType: 'Select',
        itemParams: {
          label: '发布人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 2)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'handlingTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '处理日期'
        },
        cptParams: {}
      },
      {
        name: 'toPlatformUserId',
        formType: 'Select',
        itemParams: {
          label: '处理人'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMemberList(val, 4)
          }
        },
        options: [],
        optionValue: ['id', 'userName']
      },
      {
        name: 'workOrderState',
        formType: 'Select',
        itemParams: {
          label: '工单状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...workOrderStateMaps
        ]
      },
      {
        name: 'workOrderType',
        formType: 'Select',
        itemParams: {
          label: '工单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...workOrderTypeMaps
        ]
      },
      {
        name: 'brandName',
        formType: 'Input',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumber',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNo',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      },
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '供应商户'
        },
        cptParams: {
          placeholder: '请选择供应商户...',
          showSearch: true,
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'workQuoteBoardCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '工单编号',
      key: 'workQuoteBoardCol-workOrderId',
      dataIndex: 'workOrderId'
    },
    {
      title: '派发日期',
      key: 'workQuoteBoardCol-createTime',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '发布人',
      key: 'workQuoteBoardCol-fromPlatformUserName',
      dataIndex: 'fromPlatformUserName'
    },
    {
      title: '处理时间',
      key: 'workQuoteBoardCol-handlingTime',
      dataIndex: 'handlingTime',
      render: (text, record, index) => (
        <>{ record.handlingTime ? moment(record.handlingTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '处理人',
      key: 'workQuoteBoardCol-toPlatformUserName',
      dataIndex: 'toPlatformUserName'
    },
    {
      title: '工单类型',
      key: 'workQuoteBoardCol-workOrderType',
      dataIndex: 'workOrderType',
      render: (text, record, index) => (
        <>{ renderWorkTypeLabel(record.workOrderType) }</>
      )
    },
    {
      title: '品牌',
      key: 'workQuoteBoardCol-brandName',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'workQuoteBoardCol-model',
      ellipsis: true,
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.model}><span>{ record.model }</span></Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'workQuoteBoardCol-itemNumber',
      ellipsis: true,
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.itemNumber}><span>{ record.itemNumber }</span></Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'workQuoteBoardCol-name',
      ellipsis: true,
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={record.name}><span>{ record.name }</span></Tooltip>
        </>
      )
    },
    {
      title: '供应商户',
      key: 'workQuoteBoardCol-distributorName',
      dataIndex: 'distributorName'
    },
    {
      title: '售价',
      key: 'workQuoteBoardCol-sellingPrice',
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{+record.sellingPrice}</>
      )
    },
    {
      title: '供应价格',
      key: 'workQuoteBoardCol-supplyPrice',
      dataIndex: 'supplyPrice',
      render: (text, record, index) => (
        <>&yen;{ +record.supplyPrice }</>
      )
    },
    {
      title: '数量',
      key: 'workQuoteBoardCol-quantity',
      dataIndex: 'quantity'
    },
    {
      title: '实际货期',
      key: 'workQuoteBoardCol-deliveryPeriodDays',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>{ +record.deliveryPeriodDays > 0 ? `${+record.deliveryPeriodDays}工作日` : '现货' }</>
      )
    },
    {
      title: '预付比例',
      key: 'workQuoteBoardCol-advancePaymentRate',
      dataIndex: 'advancePaymentRate',
      render: (text, record, index) => (
        <>{ record.advancePaymentRate ? `${(+record.advancePaymentRate * 100 / 100).toFixed(2)}%` : null }</>
      )
    },
    {
      title: '是否承担运费',
      key: 'workQuoteBoardCol-isUndertakeFreight',
      dataIndex: 'isUndertakeFreight',
      render: (text, record, index) => (
        <>{ record.isUndertakeFreight === 'UNDERTAKE' ? '是' : '否' }</>
      )
    },
    {
      title: '运费金额',
      key: 'workQuoteBoardCol-freightPrice',
      dataIndex: 'freightPrice',
      render: (text, record, index) => (
        <>&yen;{ (+record.freightPrice * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '报价有效期',
      key: 'workQuoteBoardCol-expiryDateTime',
      dataIndex: 'expiryDateTime',
      render: (text, record, index) => (
        <>{ record.expiryDateTime ? moment(record.expiryDateTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    }
  ]

  componentDidMount () {
    this.getWorkOrderQuoteBoardList()
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[7].options = $lodash.concat([{ brandId: 0, showName: '全部' }], data)

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') { return false }

    Api.getMerchantListByKeyword({ distributorName: val }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state
        searchData[10].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 获取全部成员
  getMemberList = (val = '', searchIndex) => {
    if (val === '') { return false }

    Api.getMemberList({ realName: val, pageNo: 1, pageSize: MAX_PAGESIZE }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[searchIndex].options = data && data.list && data.list.length > 0 ? [{ id: -1, userName: '全部' }, ...data.list] : []

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 获取工单报价统计列表
  getWorkOrderQuoteBoardList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getWorkOrderQuoteBoardList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.orderTimes) {
      search['orderBeginDate'] = search.orderTimes.length === 2 ? moment(search.orderTimes[0]).format('YYYY-MM-DD') : undefined
      search['orderEndDate'] = search.orderTimes.length === 2 ? moment(search.orderTimes[1]).format('YYYY-MM-DD') : undefined
      delete search.orderTimes
    }

    if (search.handlingTimes) {
      search['handingBeginDate'] = search.handlingTimes.length === 2 ? moment(search.handlingTimes[0]).format('YYYY-MM-DD') : undefined
      search['handingEndDate'] = search.handlingTimes.length === 2 ? moment(search.handlingTimes[1]).format('YYYY-MM-DD') : undefined
      delete search.handlingTimes
    }

    if (search.itemNo) {
      search['itemNo'] = repLineBreak(search.itemNo, '<br>')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getWorkOrderQuoteBoardList()
    })
  }

  // 点击导出
  handleDownload = () => {
    const { query = {} } = this.state
    if (!query.orderBeginDate || !query.orderEndDate) {
      return message.warning('请选择要导出的派发时间段！')
    }
    DownloadApi.downloadWorkOrderQuoteBoardStatistics({ ...query })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getWorkOrderQuoteBoardList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getWorkOrderQuoteBoardList()
    })
  }

  render () {
    const { searchData, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '12px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.handleDownload()}><Icon type='download' />导出</Button>
          </div>

          <Table rowKey={(record, index) => `${record.workOrderId}-${index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            scroll={{ x: 2400 }}
          />
        </Card>
      </>
    )
  }
}

export default WorkQuoteBoard
