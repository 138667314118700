import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as echarts from 'echarts'
import $lodash from 'lodash'

import Api from '@/common/api/index'

import styles from './index.module.scss'

let option = {
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)'
  },
  series: [
    {
      name: '商品总数',
      type: 'pie',
      selectedMode: 'single',
      radius: [0, '30%'],
      label: {
        show: false,
        position: 'inner',
        fontSize: 14
      },
      labelLine: {
        show: false
      },
      data: []
    },
    {
      name: '商品总价值',
      type: 'pie',
      radius: ['45%', '60%'],
      labelLine: {
        length: 30
      },
      label: {
        alignTo: 'edge',
        formatter: '{c} ({d}%)\n {b}',
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15
      },
      data: []
    }
  ]
}

class Echarts extends Component {
  static propTypes = {
    distributorId: PropTypes.number
  }
  state = {
    skuAmountdatas: [],
    skuNodatas: []
  }

  componentDidMount () {
    this.getInventDetailDashboardAnalyse()
  }

  // 获取统计数据
  getInventDetailDashboardAnalyse = async () => {
    const { distributorId } = this.props
    const res = await Api.getInventDetailDashboardAnalyse({ distributorId: distributorId })
    const { code, data = [] } = res
    if (+code === 10000) {
      if (data && data.length > 0) {
        let totaldatas = []
        let amountdatas = []

        const skuTotals = $lodash.sumBy(data, 'skuTotal') // sku总数和
        const skuAmountTotals = $lodash.sumBy(data, 'skuTotalAmount') // sku总价格和

        data.forEach((record) => {
          totaldatas.push({
            value: record.skuTotal,
            name: record.englishBrandName ? `${record.brandName}/${record.englishBrandName}` : record.brandName,
            ratio: ((+record.skuTotal / skuTotals * 100).toFixed(2))
          })

          amountdatas.push({
            value: record.skuTotalAmount,
            name: record.englishBrandName ? `${record.brandName}/${record.englishBrandName}` : record.brandName,
            ratio: ((+record.skuTotalAmount / skuAmountTotals * 100).toFixed(2))
          })
        })

        option.series[0].data = totaldatas
        option.series[1].data = amountdatas

        this.setState({
          skuNodatas: totaldatas,
          skuAmountdatas: amountdatas
        }, () => {
          this.initEcharts(option)
        })
      }
    }
  }

  initEcharts = (option) => {
    let myChart = echarts.init(document.getElementById('pieCharts'))

    myChart.setOption(option)
  }

  render () {
    const { skuNodatas = [], skuAmountdatas = [] } = this.state

    return (
      <div className={`${styles.wrap}`}>
        <div className={`${styles.slideWrap} ${styles.leftSlide}`}>
          <h3>商品总价值</h3>
          <ul className={`${styles.list}`}>
            {
              skuAmountdatas.map((item, index) => (
                <li key={`amountItem-${index}`}>
                  <p className={`${styles.value}`}><span>{ item.value }</span><span style={{ marginLeft: '6px' }}>({ item.ratio }%)</span></p>
                  <p className={`${styles.name}`}>{ item.name }</p>
                </li>
              ))
            }
          </ul>
        </div>
        <div id='pieCharts' style={{ margin: '0px auto ', width: `600px`, height: '400px' }} />

        <div className={`${styles.slideWrap} ${styles.rightSlide}`}>
          <h3>商品总数</h3>
          <ul className={`${styles.list}`}>
            {
              skuNodatas.map((item, index) => (
                <li key={`amountItem-${index}`}>
                  <p className={`${styles.value}`}><span>{ item.value }</span><span style={{ marginLeft: '6px' }}>({ (+item.ratio * 100 / 100).toFixed(2) }%)</span></p>
                  <p className={`${styles.name}`}>{ item.name }</p>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default Echarts
