import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

class PreviewTable extends Component {
  static propTypes = {
    list: PropTypes.array
  }
  state = {

  }

  columns = [
    {
      title: '序号',
      key: 'previewTableCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'previewTableColCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号/订货号',
      key: 'previewTableColCol-2',
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'previewTableColCol-3',
      dataIndex: 'quantity'
    }
  ]

  render () {
    const { list = [] } = this.props

    return (
      <>
        <Table rowKey='id' bordered
          columns={this.columns}
          dataSource={list}
          pagination={false}
        />
      </>
    )
  }
}

export default PreviewTable
