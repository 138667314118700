import React, { Component } from 'react'
import { Modal, Radio } from 'antd'
import PropTypes from 'prop-types'

export default class handlemodal extends Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    rowDetail: PropTypes.object
  }
  state={
    value: this.props.rowDetail.processResult
  }
  handleOk = () => {
    const { value } = this.state
    const { rowDetail } = this.props
    let param = {
      id: rowDetail.id,
      processResult: value
    }
    this.props.onConfirm(param)
  }
  onChange = (e) => {
    this.setState({
      value: e.target.value
    })
  }
  render () {
    const { rowDetail } = this.props
    return (
      <div>
        <Modal
          title='处理结果选择'
          visible
          maskClosable={false}
          onOk={() => { this.handleOk() }}
          onCancel={this.props.onCancel}
        >
          <Radio.Group onChange={this.onChange} defaultValue={rowDetail.processResult}>
            <Radio value='SUCCESS'>成功</Radio>
            <Radio value='PROCESSING'>推进中</Radio>
            <Radio value='FAILED'>失败</Radio>

          </Radio.Group>
        </Modal>
      </div>
    )
  }
}
