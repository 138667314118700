import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'

import styles from './navtab.module.scss'

export default class NavTab extends Component {
  static propTypes = {
    history: PropTypes.object,
    onSelect: PropTypes.func
  }
  state = {
    navData: [
      { id: 'BANNER', name: '首页Banner' },
      { id: 'RECOMMEND', name: '推荐广告' }
    ],
    selectedKey: 'BANNER',
    selectedSize: 0
  }

  componentDidMount () {
    let { pathname } = this.props.history.location
    let { navData } = this.state

    if (pathname === '/admin/bannerAdvert') {
      navData = [
        { id: 'BANNER', name: '首页Banner' }
      ]
    }

    this.setState({
      navData
    })
  }

  handleSelect = (record) => {
    this.setState({
      selectedKey: record.id
    }, () => {
      this.props.onSelect({ type: record.id })
    })
  }

  render () {
    const { navData, selectedKey = 'BANNER' } = this.state

    return (
      <div>
        <Row className={`${styles.parentRow}`}>
          {
            navData.map((item, i) => (
              <a className={`${styles.navItem} ${selectedKey === item.id ? styles.cur : ''}`} key={item.id}
                onClick={() => this.handleSelect(item)}
              >
                {item.name}
              </a>
            ))
          }
        </Row>
      </div>
    )
  }
}
