import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Table, Button, message, Checkbox, InputNumber } from 'antd'

import SearchLand from './searchLand'
import ToolsLand from './toolsLand'
import ErrList from './errlist'
import SpecialsetFlow from './specialsetFlow'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './setspecial.module.scss'

const { repLineBreak } = filter

class SetspecialPrice extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    brandList: [], // 品牌列表,
    selectedDistributorIds: [], // 选择的商户
    tableData: [],
    errList: false, // 错误列表模拟框状态
    errInfo: [] // 错误列表数据
  }

  columns = [
    {
      title: '序号',
      key: 'specialCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'specialCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号/订货号',
      key: 'specialCol-2',
      dataIndex: 'model',
      render: (text, record, index) => (
        <div>
          <Row className={`${styles.tableRow}`}>
            <label className={`${styles.rowLabel}`}>型<em style={{ visibility: 'hidden' }}>型</em>号</label>
            <span title={record.model}>{record.model}</span>
          </Row>
          <Row className={`${styles.tableRow}`}>
            <label className={`${styles.rowLabel}`}>订货号</label>
            <span title={record.itemNumber}>{record.itemNumber}</span>
          </Row>
        </div>
      )
    },
    {
      title: '产品类型',
      key: 'specialCol-3',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '表价',
      key: 'specialCol-4',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '库存',
      key: 'specialCol-5',
      render: (text, record, index) => {
        const { supplierInfo = [] } = record
        return (
          <div>
            {
              supplierInfo.map((item, i) => (
                <Row key={`specialCol-3-${record.commoditySkuId}-${i}`} style={{ minHeight: '26px' }}>{ item.inventory }</Row>
              ))
            }
          </div>
        )
      }
    },
    {
      title: '供应商价格',
      key: 'specialCol-6',
      render: (text, record, index) => {
        const { supplierInfo = [] } = record

        return (
          <div>
            {
              supplierInfo.map((item, i) => (
                <Row key={`specialCol-3-${record.commoditySkuId}-${i}`} style={{ minHeight: '26px' }}>{ item.supplyPrice }</Row>
              ))
            }
          </div>
        )
      }
    },
    {
      title: '云仓价格',
      key: 'specialCol-7',
      dataIndex: 'cloudPrice',
      render: (text, record, index) => {
        const { supplierInfo = [] } = record

        return (
          <div>
            {
              supplierInfo.map((item, n) => (
                <Row key={`specialCol-4-${record.commoditySkuId}-${n}`} style={{ minHeight: '26px', display: 'flex' }}>
                  <span style={{ width: '100px' }}>
                    <Checkbox checked={item.checked} onChange={(e) => { this.onChangeCk(e.target.checked, n, index) }} style={{ marginRight: '4px' }} />
                    { item.supplyTradePrice }
                  </span>

                  <span style={{ marginLeft: '40px' }}>{item.supplierName }</span>
                </Row>
              ))
            }
          </div>
        )
      }
    },
    {
      title: '特价设置',
      key: 'specialCol-8',
      dataIndex: 'supplyList',
      editable: true,
      render: (text, record, index) => {
        const { supplierInfo = [] } = record

        return (
          <div>
            {
              supplierInfo.map((item, n) => (
                <Row key={`specialCol-5-${record.commoditySkuId}-${n}`} style={{ minHeight: '25px' }}>
                  {
                    item.checked
                      ? <Row className={styles.tableRow}>
                        <label className={`${styles.rowLabel}`}>云特价</label>
                        <InputNumber min={item.supplyPrice} value={item.supplySpecialPrice} className={styles.InputNumber} size='small' onChange={(value) => { this.onChangeSpecicalPrice(value, index, n) }} />
                        <label className={`${styles.rowLabel}`} style={{ marginLeft: '10px' }}>折扣</label>
                        <InputNumber min={0} value={item.Discount} className={styles.InputNumber} size='small' onChange={(value) => { this.onChangeSpecicalDiscount(value, index, n) }} />
                        <label className={`${styles.rowLabelBefore}`}>%</label>
                      </Row>
                      : null
                  }
                </Row>
              ))
            }
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'specialCol-9',
      render: (text, record, index) => {
        return (
          <a onClick={() => { this.handDel(index) }}>删除</a>
        )
      }

    }
  ]

  componentDidMount () {
    this.getBrandListInSearch({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 })
  }

  // 获取品牌列表
  getBrandListInSearch = (params) => {
    Api.getBrandListInSearch(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        this.setState({
          brandList: data || []
        })
      }
    })
  }

  // 获取云库所有产品
  getALLSerCloudSkuList = (params) => {
    Api.getALLSerCloudSkuList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data && data.length > 0) {
          data.map((item) => {
            const { supplierInfo = [] } = item

            supplierInfo.map((row, n) => {
              if (supplierInfo.length - 1 === n) {
                row['checked'] = true
              }
            })
          })
        }

        this.setState({
          tableData: data || []
        })
      }
    })
  }

  // 保存特价设置
  getspecialPriceByMerchant = (params) => {
    Api.getspecialPriceByMerchant(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (!data) {
          message.success('操作成功')
          this.setState({
            tableData: [],
            query: {}
          })
        } else {
          this.setState({
            errList: true,
            errInfo: data
          })
          message.error(res.message)
        }
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (search.paramStr) {
      search['paramStr'] = repLineBreak(search.paramStr, '<br>')
    }

    this.getALLSerCloudSkuList({ ...search, pageNo: 1, pageSize: 99999 })
  }

  // 选择商户
  changeSelectedDistributorIds = (keys) => {
    this.setState({
      selectedDistributorIds: keys
    })
  }

  // 点击提交
  handleSubmit = () => {
    let { tableData = [], selectedDistributorIds = [] } = this.state
    if (selectedDistributorIds.length === 0) {
      return message.warning('请选择商户')
    }

    let _list = []

    tableData.map((item, i) => {
      const { supplierInfo = [], skuGoodsType } = item
      supplierInfo.map((row, i) => {
        if (+row.supplySpecialPrice > 0) {
          _list.push({
            skuGoodsType: skuGoodsType,
            supplierId: row.supplierId,
            commoditySkuId: row.commoditySkuId,
            supplierName: row.supplierName,
            supplyPrice: row.supplyPrice,
            inventory: row.inventory,
            supplyTradePrice: row.supplyTradePrice,
            supplySpecialPrice: row.supplySpecialPrice
          })
        }
      })
    })

    if (_list.length === 0) {
      return message.warning('请给SKU设置特价')
    }

    let params = {
      distributorIdList: selectedDistributorIds,
      supplierInfo: _list
    }
    this.getspecialPriceByMerchant(params)
  }

  // 复选框变化
  onChangeCk=(checked, n, i) => {
    let { tableData = [] } = this.state

    tableData[i].supplierInfo[n]['checked'] = checked

    this.setState({
      tableData
    })
  }

  // 监听特价输入
  onChangeSpecicalPrice = (val, index, i) => {
    let { tableData = [] } = this.state
    let arr = tableData[index].supplierInfo
    if (+arr[i].supplyTradePrice > 0) {
      arr[i]['Discount'] = (((val * 100) / (+arr[i].supplyTradePrice)) * 100 / 100).toFixed(2)
    }
    arr[i].supplySpecialPrice = val
    this.setState({
      tableData
    })
  }

  // 监听折扣输入
  onChangeSpecicalDiscount = (val, index, i) => {
    let { tableData = [] } = this.state
    let arr = tableData[index].supplierInfo
    if (+arr[i].supplyTradePrice > 0) {
      arr[i].supplySpecialPrice = val / 100 * (+arr[i].supplyTradePrice * 100 / 100)
    }
    arr[i]['Discount'] = val
    this.setState({
      tableData
    })
  }

  // 监听有效期输入
  onChangeSpecialPriceExp = (val, i) => {
    let { tableData = [] } = this.state
    tableData[i].validity = val
    this.setState({
      tableData
    })
  }

  // 删除
  handDel = (index) => {
    let { tableData } = this.state
    tableData.splice(index, 1)

    this.setState({
      tableData: [...tableData]
    })
  }

  //  弹出框重复列表框取消操作
  closeModal = () => {
    this.setState({
      errList: false
    })
  }

  render () {
    const { brandList = [], tableData = [], errList = false, errInfo = [] } = this.state

    return (

      <div>
        <Row gutter={10} style={{ marginBottom: '100px' }}>
          <Col span={6}>
            <SearchLand {...this.props} brandList={brandList}
              handleSubmit={(search) => this.handleSearch(search)}
            />
          </Col>
          <Col span={18}>
            <Card bodyStyle={{ padding: '12px' }}>
              <Row>
                <ToolsLand
                  onSelect={(keys) => this.changeSelectedDistributorIds(keys)} />
              </Row>
              <Row style={{ marginBottom: '15px' }}>
                <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}`} bordered
                  columns={this.columns}
                  dataSource={tableData}
                  pagination={false}
                />
              </Row>
              <Row>
                <Button type='primary' onClick={() => this.handleSubmit()} style={{ marginLeft: '50%' }}>
                提交
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>

        {
          errList
            ? <ErrList errInfoList={errInfo}
              onCancel={() => this.closeModal()}
              onOk={() => this.closeModal()} />
            : null
        }
        <SpecialsetFlow />
      </div>
    )
  }
}

export default SetspecialPrice
