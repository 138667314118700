import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table, message } from 'antd'
import { connect } from 'react-redux'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/search-form/index'
import VoucherModal from '../component/voucher-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const cashOutMap = [
  { type: '-1', label: '全部' },
  { type: '0', label: '待打款' },
  { type: '1', label: '已打款' }
]

class CashoutList extends Component {
  constructor (props) {
    super(props)
    this.getMerchantListByKeyword = $lodash.debounce(this.getMerchantListByKeyword, 300)
  }

  static propTypes = {
    buttonMaps: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'distributorId',
        formType: 'Select',
        itemParams: {
          label: '客户名称',
          extra: ''
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          filterOption: false,
          onSearch: (val) => {
            this.getMerchantListByKeyword(val)
          }
        },
        options: [],
        optionValue: ['id', 'distributorName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '打款状态'
        },
        cptParams: {
          placeholder: '选择...',
          autoComplete: 'off'
        },
        options: cashOutMap,
        optionValue: ['type', 'label']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'cashoutList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      key: 'cashoutList-1',
      dataIndex: 'distributorInfo'
    },
    {
      title: '提现金额',
      key: 'cashoutList-2',
      dataIndex: 'amount',
      render: (text, record, index) => (
        <>&yen;{(+record.amount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '银行账号信息',
      key: 'cashoutList-3',
      render: (text, record, index) => (
        <div>
          <div>
            <span style={{ marginRight: '8px' }}>账户名称:</span>
            <span>{record.accountName}</span>
          </div>
          <div>
            <span style={{ marginRight: '8px' }}>银行名称:</span>
            <span>{record.bank}</span>
          </div>
          <div>
            <span style={{ marginRight: '8px' }}>银行卡号:</span>
            <span>{record.account.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')} </span>
          </div>
        </div>
      )
    },
    {
      title: '申请时间',
      key: 'cashoutList-4',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '状态',
      key: 'cashoutList-5',
      render: (text, record, index) => (
        <div>
          {
            +record.status === 1
              ? <span className={`green-color`}>已打款</span>
              : <span className={`red-color`}>待打款</span>
          }
        </div>
      )
    },
    {
      title: '操作',
      key: 'cashoutList-6',
      width: 200,
      render: (text, record, index) => {
        const { buttonMaps } = this.props

        return (
          <div>
            {
              buttonMaps && buttonMaps['update'] && +buttonMaps['update'].auth === 1 && +record.status === 0
                ? <a onClick={() => this.showVoucherModal(record)}>
                  确认打款
                </a>
                : null
            }
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getCashoutApplyList()
  }

  // 模糊搜索-获取商家列表
  getMerchantListByKeyword = (val) => {
    if (val === '') {
      return false
    }

    let params = {
      distributorName: val
    }

    Api.getMerchantListByKeyword(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        searchData[0].options = $lodash.concat([{ id: -1, distributorName: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取提现申请列表
  getCashoutApplyList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCashoutApplyList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 提现打款确认
  updateCashoutStatus = (params) => {
    Api.updateCashoutStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getCashoutApplyList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20
      },
      query: search
    }, () => {
      this.getCashoutApplyList()
    })
  }

  // 点击确认付款
  showVoucherModal = (record) => {
    this.setState({
      rowDetail: record || {},
      voucherModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      voucherModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCashoutApplyList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCashoutApplyList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], voucherModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '12px' }}>
            <Row>
              <Table rowKey='id' bordered
                dataSource={tableData}
                columns={this.columns}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          voucherModal
            ? <VoucherModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.updateCashoutStatus(params)}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonMaps: state.buttonMaps
  }
}

export default connect(mapStateToProps)(CashoutList)
